import { NgModule } from '@angular/core';
import { AppSharedModule } from '@app/shared/app-shared.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { RequestTableComponent } from './request-table.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { SharedModule } from '@app/shared/shared.module';
import { RequestVerificationStatusGridModule } from '../request-verification-status-grid/request-verification-status-grid.module';
import { RequestTableLegendComponent } from './request-table-legend/request-table-legend.component';
import { RequestStatusPillComponent } from './request-status-pill/request-status-pill.component';

@NgModule({
    imports: [
        AppSharedModule, 
        UtilsModule,
        AgGridModule.withComponents(null),
        SharedModule,
        RequestVerificationStatusGridModule
    ],
    declarations: [
        RequestTableComponent,
        RequestTableLegendComponent,
        RequestStatusPillComponent
    ],
    exports: [RequestTableComponent]
})
export class RequestTableModule {}
