import { Component, OnInit, ViewChild, Output, EventEmitter, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { FaqService } from '@shared/services/faq.service';
import { finalize } from 'rxjs/operators';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CkeditorService } from '@shared/services/ckeditor.service';
import { FAQCategoryOutput } from '@shared/models/faq/faqCategoryOutput';
import { FAQCategoryInput } from '@shared/models/faq/faqCategoryInput';
import { Languages } from '@app/languages/languages.enum';

@Component({
    selector: 'app-create-or-edit-faq-category-modal',
    templateUrl: './faq-category-create-edit-modal.component.html',
    styleUrls: ['./faq-category-create-edit-modal.component.scss']
})
export class FaqCategoryCreateEditModalComponent extends AppComponentBase implements OnInit {
    @ViewChild('FaqCategoryCreateEditModalComponent') FAQCategoryModal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    public Editor = Editor;
    formGroup: FormGroup;
    editorConfig: any;
    saving = false;
    active = false;
    existingFaqCategory: FAQCategoryInput;
    allLanguagesFaqCategories: Map<number, FAQCategoryOutput[]> = new Map();
    untranslatedCategories: FAQCategoryOutput[];
    selectedLanguage: number;
    languages;
    editing: boolean = false;
    iconList: string[] = ["ga-dollar","ga-information","ga-shield-check","ga-user","ga-stat","ga-list-check","ga-chart-tree","ga-settings","ga-interrogation","ga-duplicate","ga-dashboard","ga-settings-sliders"]

    constructor(
        injector: Injector,
        public bsModalRef: BsModalRef,
        private toastr: ToastrService,
        private _service: FaqService,
        private _appLocalizationService: AppLocalizationService,
        private ckeditorService: CkeditorService,
        private _fb: FormBuilder
    ) {
        super(injector);
    }

    async ngOnInit() {
        this.editorConfig = this.ckeditorService.getConfig({mediaEmbedding:true});

        this.languages = this._appLocalizationService.getAllLanguages();

        this.formGroup = this._fb.group({
            language: [null, Validators.required],
            translation: [{value: 'none', disabled: true}],
            name: ['', Validators.required],
            icon: ['', Validators.required],
        });

        this.formGroup.get('language').valueChanges.subscribe(languageId => {
            if (languageId) {
                this.selectedLanguage = languageId;
                this.setupTranslation();
            } else {
                this.formGroup.get('translation').disable();
            }
        });
    }

    show(existingCategory?: FAQCategoryOutput, allFaqDict?: Map<number, FAQCategoryOutput[]>): void {
        if (allFaqDict) {
            this.allLanguagesFaqCategories = allFaqDict;
        } else {
            this.getAllFaq();
        }
        if (existingCategory) {
            this.editing = true;
            this.setupForEdit(existingCategory);
        } else {
            this.existingFaqCategory = null;
        }
        this.active = true;
        this.FAQCategoryModal.show();
    }

    setupForEdit(existingCategory: FAQCategoryOutput): void {
        this.existingFaqCategory = {...existingCategory};
        this.selectedLanguage = existingCategory.languageId;
        this.formGroup.get('language').setValue(this.selectedLanguage);
        this.formGroup.get('translation').setValue(existingCategory.translationOf ?? 'none');
        if (existingCategory.translationOf || existingCategory.languageId !== this._appLocalizationService.getLanguageId(Languages.ENGLISH)) {
            this.setupTranslation();
            this.formGroup.get('translation').setValue(existingCategory.translationOf ?? 'none');
        } else {
            this.formGroup.get('translation').disable();
        }
        this.formGroup.get('name').setValue(existingCategory.name);
        this.formGroup.get('icon').setValue(existingCategory.icon);
    }

    setupTranslation() {
        const englishId = this._appLocalizationService.getLanguageId(Languages.ENGLISH);
        if (this.selectedLanguage !== englishId) {
            this.formGroup.get('translation').enable();
            const englishFaqCategories: FAQCategoryOutput[] = this.allLanguagesFaqCategories.get(englishId);
            const thisLanguageFaqCategories: FAQCategoryOutput[] = this.allLanguagesFaqCategories.get(this.selectedLanguage);
            this.untranslatedCategories = englishFaqCategories.filter(englishCategory => {
                return this.existingFaqCategory?.translationOf === englishCategory.id || !thisLanguageFaqCategories.some(c => c.translationOf === englishCategory.id);
            })
        } else {
            this.untranslatedCategories = [];
            this.formGroup.get('translation').disable();
        }
    }

    getAllFaq(): void {
        this._service.getAllFAQDescriptions().subscribe((response) => {
            this.allLanguagesFaqCategories = this._service.splitFaqListPerLanguage(response.result.faqList);
        });
    }

    returnResult(): FAQCategoryInput {
        const translation = this.formGroup.get('translation').value;
        const faqCategory: FAQCategoryInput = {
            ...this.existingFaqCategory,
            languageId: this.formGroup.get('language').value,
            name: this.formGroup.get('name').value,
            icon: this.formGroup.get('icon').value,
            translationOf: translation === 'none' ? null : this.formGroup.get('translation').value,
        };
        return faqCategory;
    }

    isFormInvalid(): boolean {
        return this.formGroup.invalid;
    }

    markAllControlsAsTouch(): void {
        this.formGroup.controls['language'].markAsTouched();
        this.formGroup.controls['translation'].markAsTouched();
        this.formGroup.controls['name'].markAsTouched();
        this.formGroup.controls['icon'].markAsTouched();
    }

    save(): Promise<void> {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }
        this.saving = true;
        const descriptionFaq = this.returnResult();
        const action = this.existingFaqCategory? this._service.updateFAQCategory.bind(this._service): this._service.createFAQCategory.bind(this._service);
        action(descriptionFaq)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .toPromise()
            .then(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
            });
    }

    close(): void {
        this.active = false;
        this.formGroup.reset({translation: 'none'});
        this.formGroup.get('translation').disable();
        this.FAQCategoryModal.hide();
    }

    showForNotValidMessage(): void {
        const title = this._appLocalizationService.l('VerifyAllControlsTitle');
        const message = this._appLocalizationService.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }
}
