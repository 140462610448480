import { Injectable, Inject } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { from, Observable } from 'rxjs'
import { InvoiceOutput } from '@shared/models/InvoiceElements/invoice/invoiceOutput'
import { InvoiceInput } from '@shared/models/InvoiceElements/invoice/invoiceInput'
import { environment as env } from 'environments/environment';
import { map } from 'lodash'
import { InvoiceBillingModeEnum } from '@shared/models/InvoiceElements/invoice/invoiceBillingMode'

@Injectable({
    providedIn: 'root',
})
export class InvoiceService {
    private http: HttpClient
    private baseUrl: string

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http
        this.baseUrl = env.api
    }

    private getUrl(url: string): string {
        let url_ = this.baseUrl + url
        url_ = url_.replace(/[?&]$/, '')
        return url_
    }

    getInvoices(): Observable<any> {
        let url_ = this.getUrl('/api/Invoices/GetInvoices')
        return this.http.get<any>(url_)
    }

    getInvoicesByOrganizationId(organizationId: number): Observable<any> {
        let url_ = this.getUrl(
            `/api/Invoices/GetInvoicesByOrganizationId/${organizationId}`
        )
        return this.http.get<any>(url_)
    }

    deleteCalculation(calculationRequestId: string): Observable<any> {
        let url_ = this.getUrl(
            `/api/Invoices/DeleteCalculation/${calculationRequestId}`
        )
        return this.http.delete<any>(url_)
    }

    getInvoicesByOrganizationPublicId(
        organizationPublicId: string
    ): Observable<any> {
        let url_ = this.getUrl(
            `/api/Invoices/GetInvoicesByOrganizationPublicId/${organizationPublicId}`
        )
        return this.http.get<any>(url_)
    }

    getInvoiceByPublicId(publicId: string): Observable<any> {
        let url_ = this.getUrl(`/api/Invoices/GetInvoiceByPublicId/${publicId}`)
        return this.http.get<any>(url_)
    }

    generateInvoice(
        requestPublicId: string,
        invoiceDate: string,
        billingMode: InvoiceBillingModeEnum
    ): Observable<any> {
        let url_ = this.getUrl(
            `/api/Invoices/GenerateInvoiceForRequest`
        )
        return this.http.post<InvoiceOutput>(url_, {
            requestPublicId,
            invoiceDate,
            billingMode
        })
    }
    UpdateInvoice(invoice: InvoiceInput): Observable<any> {
        let url_ = this.getUrl('/api/Invoices')
        return this.http.put<any>(url_, invoice)
    }

    async asyncCreateInvoiceCalculationRequest(
        requestsPublicIds: string[],
        invoiceDate: string
    ) {
        let url_ = this.getUrl(
            `/api/Invoices/CreateInvoiceCalculationRequest/${invoiceDate}`
        )
        return this.http.post<any>(url_, requestsPublicIds).toPromise()
    }

    generateInvoices(): Observable<any> {
        let url_ = this.getUrl(`/api/Invoices/GenerateInvoices`)
        var result = this.http.get<any>(url_)
        return result
    }

    getInvoiceCalculationRequests(): Observable<any> {
        let url_ = this.getUrl(`/api/Invoices/GetInvoiceCalculationRequests/`)
        return this.http.get<any>(url_)
    }

    sendInvoiceLinkToInvoiceEmail(invoicePublicId: string): Observable<any> {
        let url_ = this.getUrl(
            `/api/Invoices/SendInvoiceLinkToInvoiceEmail/${invoicePublicId}`
        )
        return this.http.get<any>(url_)
    }

    sendInvoices(invoicePublicIds: string[]): Observable<any> {
        let url_ = this.getUrl(`/api/Invoices/SendInvoices`)
        return this.http.post<any>(url_, invoicePublicIds)
    }

    ChargeCreditCardByInvoicePublicId(
        invoicePublicId: string
    ): Observable<any> {
        let url_ = this.getUrl(
            `/api/Invoices/ChargeCreditCard/${invoicePublicId}`
        )
        return this.http.post<any>(url_, invoicePublicId)
    }

    GetCustomizeInvoice(invoicePublicId: string): Observable<any> {
        let url_ = this.getUrl(
            `/api/Invoices/GetCustomizeInvoice/${invoicePublicId}`
        )
        return this.http.get<any>(url_)
    }

    async asyncGetCustomizeInvoice(invoicePublicId: string) {
        let url_ = this.getUrl(
            `/api/Invoices/GetCustomizeInvoice/${invoicePublicId}`
        )
        return this.http.get<any>(url_).toPromise()
    }

    getCustomizeInvoices(invoicePublicIds: string[]) {
        let url_ = this.getUrl(`/api/Invoices/GetPrintingInvoice`)
        return this.http.post<any>(url_, invoicePublicIds)
    }

    getExcelInvoices(invoicePublicIds: string[]): Observable<any> {
        let url_ = this.getUrl(`/api/Invoices/GetExcelInvoices`)
        return this.http.post<any>(url_, invoicePublicIds)
    }

    getPDFInvoice(invoicePublicId: string): Observable<any> {
        let url_ = this.getUrl(`/api/Invoices/GetPdfInvoice/${invoicePublicId}`)
        const options: {
            headers?: HttpHeaders
            observe?: 'body'
            params?: HttpParams
            reportProgress?: boolean
            responseType: 'blob'
            withCredentials?: boolean
        } = {
            responseType: 'blob',
        }

        return this.http.get(url_, options)
    }

    asyncGetpublicIdByInvoiceCalculationRequestId(
        invoiceCalculationRequestId: string
    ): Observable<any> {
        let url_ = this.getUrl(
            `/api/Invoices/GetpublicIdByInvoiceCalculationRequestId/${invoiceCalculationRequestId}`
        )
        return this.http.get<any>(url_)
    }
}
