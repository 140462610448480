import { Injectable } from "@angular/core";
import { AppAuthService } from "@app/shared/common/auth/app-auth.service";
import localizations from 'assets/localizations.json';

@Injectable({
    providedIn: 'root',
    deps: [AppAuthService]
})
export default class LocalizationService {
    languages: { name: string; displayName: string; icon: string; isDefault: boolean; }[];
    get currentLanguage(): any {
        let locale = this.authService.user?.locale;
        if (locale == null) {
        locale = 'fr';
    }
    return this.languages.find(x => x.name === locale);
    };

    constructor(private authService: AppAuthService) {
        this.languages = localizations.languages;
    }

    localize(arg0: string, arg1: string): string {
        return localizations.values[this.authService.user.locale][arg1][arg0];
    }
}
