import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { IServerSideDatasource, IServerSideGetRowsParams } from '@ag-grid-enterprise/all-modules';
import { SortModel } from '@shared/models/request/sortModel';
import { FilterModel } from '@shared/models/request/filterModel';
import { Subject } from 'rxjs';
import { environment as env } from 'environments/environment';
import { overallAdjustLocalDateRangeToUtcDateTimeRange } from '@shared/utils/date-time/adjust-local-date-range-to-utc-date-time-range';

@Injectable()
export class RequestDatasourceService implements IServerSideDatasource {
    private http: HttpClient;
    private baseUrl: string;
    public firstDataRenderedSubject = new Subject<any>();
    public sortChangedSubject = new Subject<any>();
    public filterChangedSubject = new Subject<any>();
    public selectionChangedSubject = new Subject<any>();
    public gridReadySubject = new Subject<any>();

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = env.api;
    }

    getRows(params: IServerSideGetRowsParams): void {
        const filterModel = overallAdjustLocalDateRangeToUtcDateTimeRange(params.request.filterModel);
        this.getRequestsList(params.request.startRow, params.request.endRow, params.request.sortModel, filterModel).toPromise().then(
                value => {
                    params.successCallback(value.result.results, value.result.count);
                }, 
                error => {
                    params.failCallback();
                }
              );
    }

    destroy?(): void {
        
    }

    private getUrl(url: string): string {
        let url_ = this.baseUrl + url;
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    }

    getRequestsList(startRow: number, endRow: number, sortModel: SortModel[], filterModel: FilterModel): Observable<any> {
        let url_ = this.getUrl('/api/requests/filter');
        let request = {
            startRow: startRow,
            endRow: endRow, 
            sortModel: sortModel,
            filterModel: filterModel
        }
        let list = this.http.post<any>(url_, request);
        return list;
    }
}