import { Pipe, PipeTransform } from '@angular/core';
import { ReportLocalizationService } from '@shared/services/report-localization.service';

@Pipe({
  name: 'reportLocalize'
})
export class ReportLocalizePipe implements PipeTransform {

  constructor(private localization: ReportLocalizationService) {
  }

  transform(value: string, ...args: string[]): string {
    return this.localization.getTraduction(value, args[0]);
  }

}
