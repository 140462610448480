import { Component, OnInit, ViewChild, Injector, HostListener } from '@angular/core';
import { AgGridAngular } from '@ag-grid-community/angular';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { DatePipe } from '@angular/common';
import { Extensions } from '@shared/utils/extensions';
import { InvoiceService } from '@shared/services/invoice.service';
import { RowSelectedEvent } from '@ag-grid-enterprise/all-modules';
import { Subject } from 'rxjs';

@Component({
    selector: 'wizzard-email-send',
    templateUrl: './wizzard-email-send.component.html',
    providers: [DatePipe],
})
export class WizzardEmailSendComponent extends AppComponentBase implements OnInit {
    @ViewChild('agGrid') agGrid: AgGridAngular;

    public columnDefs;
    public defaultColDef;
    paginationNumberFormatter;
    paginationPageSize = 20;
    filterText = '';
    rowData: any;
    rowDataSubject: Subject<any> = new Subject<any>();
    rowSelection: string;
    rowMultiSelectWithClick: boolean;
    invoicePublicIdsSelected: string[] | undefined;
    private gridApi;
    constructor(private injector: Injector, private _appLocalizationService: AppLocalizationService, private datePipe: DatePipe, private _invoiceService: InvoiceService) {
        super(injector);
        this.rowSelection = 'multiple';
        this.rowMultiSelectWithClick = true;

        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };

        this.columnDefs = [
            {
                headerName: this._appLocalizationService.l(''),
                checkboxSelection: true,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                filter: false,
            },
            {
                headerName: this._appLocalizationService.l('OrganizationName'),
                field: 'organizationName',
                width: 200,
                getQuickFilterText: Extensions.replaceSpecialCharactersParams.bind(this),
            },
            {
                headerName: this._appLocalizationService.l('Organization'),
                field: 'clientId',
                width: 200,
            },
            {
                headerName: this._appLocalizationService.l('InvoiceNumber'),
                sort: 'desc',
                valueGetter: this.invoiceNumberGetter,
                width: 200,
            },
            {
                headerName: this._appLocalizationService.l('SubTotal'),
                valueGetter: this.subTotalCurrencyFormat,
                width: 200,
            },
        ];
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.gridApi) {
            if (screen.availWidth > 414 && this.gridApi.width > 0) {
                this.gridApi.sizeColumnsToFit();
            }
        }
    }

    ngOnInit() {
        this.defaultColDef = { resizable: true, sortable: true, filter: true };
    }

    invoiceNumberGetter(params) {
        const invoiceNumber = params.data.invoiceNumber;
        const firtThree = invoiceNumber.substring(0, 3);
        const secondThree = invoiceNumber.substring(3, 6);
        const thirdThree = invoiceNumber.substring(6, 9);
        return firtThree + ' ' + secondThree + ' ' + thirdThree;
    }

    subTotalCurrencyFormat(params) {
        const subTotal = params.data.subTotal;
        return subTotal.toFixed(2) + ' $';
    }

    onPageSizeChanged(e) {
        this.gridApi.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    onGridReady(params) {
        this.gridApi = params.api;
        if (screen.availWidth > 414 && this.gridApi.width > 0) {
            this.gridApi.sizeColumnsToFit();
        }
    }

    refresh() {
        this.filterText = '';
        this.agGrid.api.setQuickFilter('');
    }

    search() {
        const filterWithoutAccents = Extensions.replaceAccentedCharacters(this.filterText);
        this.agGrid.api.setQuickFilter(filterWithoutAccents);
    }

    showInvoicesGenerated(invoicePublicIds: string[]) {
        this.agGrid.api.showLoadingOverlay();
        this._invoiceService.getCustomizeInvoices(invoicePublicIds).subscribe((response) => {
            this.rowDataSubject.next(response.result);
        });
    }

    onInvoiceSelected(event: RowSelectedEvent) {
        this.invoicePublicIdsSelected = this.gridApi.getSelectedRows().map((row) => row.invoicePublicId);
    }
}
