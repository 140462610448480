<div bsModal aria-labelledby="invoiceCustomizeModal" #invoiceCustomizeModal="bs-modal">
    <div class="modal-header">
        <h5>
            {{"InvoiceContent" | localize }}
        </h5>
    </div>

    <div class="modal-body">
        <input class="form-check-input" type="checkbox" name="InvoiceWithCandidats" (change)='checkValue($event)'
            id="InvoiceWithCandidats">
        {{"InvoiceWithCandidats" | localize}}
    </div>

    <div class="modal-footer">
        <button class="btn btn-secondary invoiceDetails-btn" (click)="applyFormatOfInvoiceAfterChoice()">
            {{"Apply" | localize}}</button>
        <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{ 'Close' | localize}}</button>
    </div>
</div>