import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent implements OnInit {

    @Input() title: string;
    @Input() message: string;
    @Input() positiveBtnLabel: string;
    @Input() negativeBtnLabel: string;
    @Input() positiveBtnAction: EventEmitter<void> = new EventEmitter();
    @Input() negativeBtnAction: EventEmitter<void> = new EventEmitter();

    constructor(public bsModalRef: BsModalRef) {

    }

    ngOnInit() {
    }

    positiveAction() {
        this.positiveBtnAction.emit();
    }

    negativeAction() {
        this.negativeBtnAction.emit();
    }
}
