import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AgGridAngular } from '@ag-grid-community/angular';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ToastrService } from 'ngx-toastr';
import { DateTime } from 'luxon';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { FaqDescriptionCreateEditModalComponent } from '@app/tutorials/faq/management/modals/description/faq-description-create-edit-modal.component';
import { FaqCategoryCreateEditModalComponent } from '@app/tutorials/faq/management/modals/category/faq-category-create-edit-modal.component';
import { FAQDescriptionOutput } from '@shared/models/faq/faqDescriptionOutput';
import { FAQCategoryOutput } from '@shared/models/faq/faqCategoryOutput';
import { FaqService } from '@shared/services/faq.service';
import { GridBtnUpdateComponent } from '@app/shared/grid/grid-btn-update/grid-btn-update.component';
import { GridBtnDeleteComponent } from '@app/shared/grid/grid-btn-delete/grid-btn-delete.component';
import { HtmlHelper } from '@shared/helpers/HtmlHelper';
import { FAQCategoryListOutput } from '@shared/models/faq/faqCategoryListOutput';

@Component({
    selector: 'app-faq-management',
    templateUrl: './faq-management.component.html',
    styleUrls: ['./faq-management.component.scss'],
    animations: [appModuleAnimation()],
})
export class FaqManagementComponent extends AppComponentBase implements OnInit {
    @ViewChild('agGrid') agGrid: AgGridAngular;
    @ViewChild('createOrEditFaqDescriptionModal') faqDescriptionModal: FaqDescriptionCreateEditModalComponent;
    @ViewChild('createOrEditFaqCategoryModal') faqCategoryModal: FaqCategoryCreateEditModalComponent;
    form: FormGroup;
    columnDefs;
    defaultColDef;
    rowData: FAQDescriptionOutput[] = [];
    selectedQuestionRow: FAQDescriptionOutput;
    selectedLanguage: number;
    languages = []; 
    allLanguagesFaqCategories: Map<number, FAQCategoryOutput[]> = new Map();  
    faqListOutput: FAQCategoryListOutput;
    faqCategories: FAQCategoryOutput[] = [];
    filterText: string = '';
    currentFilterText: string;
    selectedFilter: string;
    private _filteredCategories: FAQCategoryOutput[] = [];
    get filteredCategories(): FAQCategoryOutput[] {
        return this._filteredCategories;
    }
    set filteredCategories(value: FAQCategoryOutput[]) {
        this._filteredCategories = value;
        this.setRowData();
    }

    constructor(
        injector: Injector,
        private _service: FaqService,
        private _appLocalizationService: AppLocalizationService,
        private _toastr: ToastrService,
        private _fb: FormBuilder
    ) {
        super(injector);
    }

    async ngOnInit() {
        this.selectedLanguage = this._appLocalizationService.getCurrentLanguage();
        this.getAllFaq()

        this.columnDefs = [
            {
                field: 'question',
                headerName: this._appLocalizationService.l('Question'),
                flex: 3
            },
            { 
                field: 'answer', 
                headerName: this._appLocalizationService.l('Description'), 
                flex: 3,
                valueFormatter: params => HtmlHelper.decodeText(params.value),
            },
            { 
                field: 'categoryName', 
                headerName: this._appLocalizationService.l('Category'),
                flex: 1
            },
            {
                field: 'status', 
                headerName: this._appLocalizationService.l('Status'), 
                width: 110,
                resizable: false,
                cellRenderer: params => {
                    const classes = params.value? 'badge badge-success' : 'badge badge-dark';
                    const text = params.value? this._appLocalizationService.l('Actif') : this._appLocalizationService.l('Inactif');
                    return `<span class="${classes}">${text}</span>`;
                }
            },
            {
                field: 'onDashboard', 
                headerName: this._appLocalizationService.l('Dashboard'), 
                width: 120,
                resizable: false,
                cellRenderer: params => {
                    return params.value? `<i class="fa fa-star" style="color: var(--bs-active-warning)"></i>` : ``;
                }
            },
            { 
                field: 'creationTime', 
                headerName: this._appLocalizationService.l('CreationTime'), 
                valueFormatter: (data) => DateTime.fromISO(data.value).toFormat("yyyy/LL/dd"),
                width: 150,
                resizable: false,
            },
            {
                headerName: '',
                cellRendererFramework: GridBtnUpdateComponent,
                cellRendererParams: { action: this.editQuestion.bind(this), templateIconStyle: true, preventPropagation: true },
                width: 50,
                resizable: false,
                filter: false,
                editable: false,
                suppressMenu: true,
                suppressColumnsToolPanel: true,
                suppressMovable: true,
            },
            {
                headerName: '',
                cellRendererFramework: GridBtnDeleteComponent,
                cellRendererParams: { action: this.deleteQuestion.bind(this), templateIconStyle: true, preventPropagation: true },
                width: 75,
                resizable: false,
                filter: false,
                editable: false,
                suppressMenu: true,
                suppressColumnsToolPanel: true,
                suppressMovable: true,
            }
        ];

        this.defaultColDef = { resizable: true, sortable: true, filter: true };

        this.form = this._fb.group({
            language: [this.selectedLanguage]
        });

        this.form.get('language').valueChanges.subscribe(value => {
            this.changeLanguage(value);
        });
    }

    getAllFaq() {
        this._service.getAllFAQDescriptions().subscribe((response) => {
            this.faqListOutput = response.result;
            this.allLanguagesFaqCategories = this._service.splitFaqListPerLanguage(this.faqListOutput.faqList);
            this.faqCategories = this.allLanguagesFaqCategories.get(this.selectedLanguage)
            this.filteredCategories = this.faqCategories;
            this.languages = this.getAllLanguages();
        });
    }

    private setRowData() {
        const data = [];
        this.filteredCategories.forEach(category => {
            category.faqDescriptions.forEach(description => {
                data.push({
                    ...description,
                    categoryName: category.name
                });
            });
        });
        this.rowData = data;
    }

    onSelectionChanged(): void {
        this.selectedQuestionRow = this.agGrid.api.getSelectedRows()[0] as FAQDescriptionOutput;
        this.editQuestion(this.selectedQuestionRow);
    }

    preventPropagation(event: Event) {
        event.stopPropagation();
    }


    addQuestion(event?: Event, category?: FAQCategoryOutput) {
        if (event) {
            this.preventPropagation(event);
        }
        this.faqDescriptionModal.show(category, null, this.allLanguagesFaqCategories, this.faqListOutput.numberOnDashboard, this.faqListOutput.maxOnDashboard);
    }

    editQuestion(element: FAQDescriptionOutput) {
        this.faqDescriptionModal.show(null, element, this.allLanguagesFaqCategories, this.faqListOutput.numberOnDashboard, this.faqListOutput.maxOnDashboard);
    };

    deleteQuestion(data: FAQDescriptionOutput) {
        this.message.confirm(this.l('FAQDescriptionDeleteWarningMessage'), this.l('AreYouSure'), (isConfirmed) => {
            if (isConfirmed) {
                try {
                    this._service.deleteFAQDescription(data.id).subscribe((response) => {
                        const title = this._appLocalizationService.l('Question');
                        const successMessage = this._appLocalizationService.l('SuccessfullyDeleted');
                        this._toastr.success(successMessage, title);
                        this.getAllFaq();
                    });
                } catch (e) {
                       const title = this._appLocalizationService.l('Question');
                       const errorMessage = this._appLocalizationService.l('TryAgainLater');
                       this._toastr.error(errorMessage, title);
                }
            }
        }, {
            confirmButtonText: this.l('Yes'),
            cancelButtonText: this.l('No')        
        });
    }

    addCategory() {
        this.faqCategoryModal.show(null, this.allLanguagesFaqCategories);
    }
    
    editCategory(event: Event, category: FAQCategoryOutput) {
        this.preventPropagation(event);
        this.faqCategoryModal.show(category, this.allLanguagesFaqCategories);
    }

    deleteCategory(event: Event, category: FAQCategoryOutput) {
        this.preventPropagation(event);
        if (category.faqDescriptions.length) {
            this.message.error(this._appLocalizationService.l('FAQCategoryDeleteNotEmpty'))
        } else {
            this.message.confirm(this.l('FAQCategoryDeleteWarningMessage'), this.l('AreYouSure'), (isConfirmed) => {
                if (isConfirmed) {
                    try {
                        this._service.deleteFAQCategory(category.id).subscribe((response) => {
                            const title = this._appLocalizationService.l('Category');
                            const successMessage = this._appLocalizationService.l('SuccessfullyDeleted');
                            this._toastr.success(successMessage, title);
                            this.getAllFaq();
                        });
                    } catch (e) {
                        const title = this._appLocalizationService.l('Category');
                        const errorMessage = this._appLocalizationService.l('TryAgainLater');
                        this._toastr.error(errorMessage, title);
                    }
                }
            }, {
                confirmButtonText: this.l('Yes'),
                cancelButtonText: this.l('No')        
            });
        }
    }

    find() {
        this.currentFilterText = this.filterText.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '').trim();
        if (this.searching()) {
            const results: FAQCategoryOutput = {
                id: null,
                name: this.l('Result'),
                icon: null,
                languageId: null,
                faqDescriptions: this.faqCategories.flatMap(category =>
                    category.faqDescriptions.filter(description =>
                        category.name.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '').includes(this.currentFilterText) ||
                        description.question.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '').includes(this.currentFilterText) ||
                        description.answer.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '').includes(this.currentFilterText)
                    )
                )
            };
            this.filteredCategories = [results];
        } else {
            this.filteredCategories = this.faqCategories;
        }
    }

    searching() {
        return this.currentFilterText?.length > 0;
    }

    changeLanguage(language: string) {
        const parsedLanguage = Number.parseInt(language);
        if (this.selectedLanguage !== parsedLanguage) { 
            this.selectedLanguage = parsedLanguage;
            this.faqCategories = this.allLanguagesFaqCategories.get(this.selectedLanguage);
            this.filteredCategories = this.faqCategories;
            this.selectedFilter = null;
        }
    }

    getAllLanguages() {
        const languageKeys = [...this.allLanguagesFaqCategories.keys()];
        return this._appLocalizationService.getAllLanguages().filter(lang => {
            return languageKeys.includes(lang.id);
        });
    }

    filter(categoryId: string) {
        if (this.selectedFilter == null || this.selectedFilter !== categoryId) {
            this.selectedFilter = categoryId;
            this.filteredCategories = this.faqCategories.filter((category) => category.id === this.selectedFilter);
        } else {
            this.selectedFilter = null;
            this.filteredCategories = this.faqCategories;
        }
    }

    isSelected(categoryId: string) {
        return this.selectedFilter === categoryId;
    }
}
