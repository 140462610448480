import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-report-section',
    templateUrl: './report-section.component.html',
    styleUrls: ['./report-section.component.scss']
})
export class ReportSectionComponent {
    @Input() sectionTitle: string;
}
