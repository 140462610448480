<div [@routerTransition] class="d-flex flex-column flex-root">
    <div class="d-flex flex-row flex-column-fluid page">
        <div id="kt_aside" class="noPrint" data-print="none" ktOffcanvas [options]="menuCanvasOptions"
            data-kt-drawer="true"
            data-kt-drawer-name="aside"
            data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="true"
            data-kt-drawer-width="{default:'200px', '300px': '250px'}"
            data-kt-drawer-direction="start"
            data-kt-drawer-toggle="#kt_aside_mobile_toggle"
            class="aside aside-left aside-{{currentTheme.baseSettings.menu.fixedAside ? 'fixed':'static'}} d-flex flex-column flex-row-auto">
            <default-brand></default-brand>
            <div class="aside-menu flex-column-fluid">
                <div
                    id="kt_aside_menu_wrapper"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-height="auto"
                    data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
                    data-kt-scroll-wrappers="#kt_aside_menu"
                    data-kt-scroll-offset="0"
                    class="hover-scroll-overlay-y my-5 my-lg-5"
                >
                    <side-bar-menu></side-bar-menu>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            <div id="kt_header"
                class="header header-{{currentTheme.baseSettings.header.desktopFixedHeader ? 'fixed':'static'}} head--skin-light">
                <div class="{{containerClass}} d-flex align-items-stretch justify-content-between">
                    <div class="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
                    </div>
                    <div class="topbar">
                        <language-switch-dropdown></language-switch-dropdown>
                        <div>
                            <button class="btn btn-active-light-primary btn-icon h-30px h-md-40px position-relative px-5 w-auto ng-star-inserted"
                                    (click)="openHelp()">
                                {{ 'Help' | localize }}
                            </button>
                        </div>
                        <div>
                            <button class="btn btn-active-light-primary btn-icon h-30px h-md-40px position-relative px-5 w-auto ng-star-inserted" 
                                    (click)="contactUsMailTo()">
                                {{ 'ContactUs' | localize }}
                            </button>
                        </div>
                        <user-menu *ngIf="!appAuthService.hasPermission('ANONYMOUS')"></user-menu>
                    </div>
                </div>

            </div>
            <div style="flex:1">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
