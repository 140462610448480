import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { IServerSideDatasource, IServerSideGetRowsParams } from '@ag-grid-enterprise/all-modules';
import { SortModel } from '@shared/models/request/sortModel';
import { FilterModel } from '@shared/models/request/filterModel';
import { environment as env } from 'environments/environment';
import { overallAdjustLocalDateRangeToUtcDateTimeRange } from '@shared/utils/date-time/adjust-local-date-range-to-utc-date-time-range';

@Injectable()
export class CandidateDatasourceService implements IServerSideDatasource {

    private http: HttpClient;
    private baseUrl: string;
    private organizationPublicId: string;

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = env.api;
    }

    setOrganizationPublicId(organizationPublicId: string): void {
        this.organizationPublicId = organizationPublicId;
    }

    getRows(params: IServerSideGetRowsParams): void {
        const filterModel = overallAdjustLocalDateRangeToUtcDateTimeRange(params.request.filterModel);
        this.getList(params.request.startRow, params.request.endRow, params.request.sortModel, filterModel).toPromise().then(
            value => {
                params.successCallback(value.result.results, value.result.count);
            },
            _error => {
                params.failCallback();
            }
        );
    }

    destroy?(): void {

    }

    getList(startRow: number, endRow: number, sortModel: SortModel[], filterModel: FilterModel): Observable<any> {
        const url = this.organizationPublicId
            ? this.getUrl(`/api/organizations/${this.organizationPublicId}/candidates/filter`)
            : this.getUrl('/api/candidats/filter');

        const request = {
            startRow: startRow,
            endRow: endRow,
            sortModel: sortModel,
            filterModel: filterModel
        }
        const list = this.http.post<any>(url, request);
        return list;
    }

    private getUrl(url: string): string {
        url = this.baseUrl + url;
        return url.replace(/[?&]$/, '');
    }
}