<div bsModal #creditCardModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="creditCardModal" aria-hidden="true" [config]="{backdrop: 'static', keyboard: !processing}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <form [formGroup]="formGroup" autocomplete="off" novalidate>
        <div class="card-body">
          <div class="modal-header mb-10">
            <h4 class="modal-title">
              <!-- <h5>{{"ProfessionnalAccreditations" | localize}}</h5> -->
              <h5>{{"CreditCardPopupTitle" | localize}}{{ this.invoice?.invoiceNumber}}</h5>
            </h4>
          </div>
          <div class="m-content">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="number">{{"CreditCardNumber" | localize}}<span class="asterix">*</span></label>
                <input id="number" formControlName="number" type="text" name="number" class="form-control"
                  placeholder="4242 4242 4242 4242" maxlength="16">
                <validation-messages [formCtrl]="formGroup.get('number')">
                </validation-messages>
              </div>
              <div class="form-group col-md-6">
                <label for="expDate">{{"Expdate" | localize}}<span class="asterix">*</span></label>
                <input id="expDate" formControlName="expDate" type="text" name="expDate" class="form-control"
                  minlength="4" maxlength="4" placeholder="{{'PlaceholderExpDate' | localize}}">
                <validation-messages [formCtrl]="formGroup.get('expDate')">
                </validation-messages>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label for="cvc">{{"cvc" | localize}}<span class="asterix">*</span></label>
                <input id="cvc" formControlName="cvc" type="text" name="cvc" class="form-control" minlength="3"
                  maxlength="3" placeholder="123">
                <validation-messages [formCtrl]="formGroup.get('cvc')">
                </validation-messages>
              </div>
            </div>
            <div class="modal-footer">
              <div *ngIf="successMessage" class="moneris-success fade-in">
                <i class="fas fa-check-circle"></i>
                {{ successMessage}}
              </div>
              <div *ngIf="errorMessage" class="moneris-error fade-in">
                <i class="fas fa-ban"></i>
                {{ errorMessage}}
              </div>
              <button *ngIf="!transactionCompleted" [disabled]="processing" type="button" class="btn btn-default"
                (click)="cancel()">{{"Cancel" | localize}}
              </button>
              <button *ngIf="!transactionCompleted" type="button" (click)="submitUniquePaymentCcInfo()" class="btn btn-primary" [disabled]="!formGroup.valid"
                [buttonBusy]="processing" [busyText]="l('ProcessingWithThreeDot')" [busyIf]="false"><i
                  class="fa fa-dollar-sign"></i>
                <span>{{"Payment" | localize}}</span>
              </button>
              <button *ngIf="transactionCompleted && errorMessage" (click)="resetForm()" type="button" class="btn btn-secondary fade-in">
                <i class="la la-refresh"></i>
                <span>{{"Retry" | localize}}</span>
              </button>
              <button *ngIf="transactionCompleted" (click)="close()" type="button" class="btn btn-primary fade-in">
                <i class="fa fa-times"></i>
                <span>{{"Close" | localize}}</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>