import {NgModule} from '@angular/core';
import {SharedModule} from '@app/shared/shared.module';
import {AppSharedModule} from '@app/shared/app-shared.module';
import {CreateOrganisationRoutingModule} from './create-organisation-routing.module';
import {CreateOrganisationComponent} from './create-organisation.component';
import {OrganizationB2BService} from '@shared/services/organizationB2B.service';
import {VerificationB2BService} from '@shared/services/verificationB2B.service';
import {NgxBootstrapDatePickerConfigService} from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import {BsDatepickerConfig, BsDaterangepickerConfig, BsLocaleService} from 'ngx-bootstrap/datepicker';
import {TreeDragDropService} from 'primeng/api';

@NgModule({
    declarations: [CreateOrganisationComponent],
    imports: [AppSharedModule, SharedModule, CreateOrganisationRoutingModule],
    providers: [
        TreeDragDropService,
        OrganizationB2BService,
        VerificationB2BService,
        { provide: BsDatepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig },
        { provide: BsDaterangepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDaterangepickerConfig },
        { provide: BsLocaleService, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerLocale },
    ]
})
export class CreateOrganisationModule { }
