<div class="m-portlet__body" style="padding-top:1%; padding-bottom: 1%;">
    <div class="m-subheader">
        <div class="row align-items-center">
            <div class="col text-end mt-3 mt-md-0">
                <a href="javascript:;" (click)="addProfessionnalReference()" class="btn btn-primary"
                    *ngIf="!allReadOnly && !invoiceSent">
                    <i class="fa fa-plus"></i>
                    {{'New' | localize}}
                </a>
            </div>
        </div>
    </div>
    <div class="grid-pagination-space">
        <grid-pagination class="grid-pagination" (sizeValue)="onPageSizeChanged($event)"></grid-pagination>
        <ag-grid-angular localizeText #agGrid class="ag-theme-material  gardium-small-grid" [rowData]="rowData"
            [paginationNumberFormatter]="paginationNumberFormatter" [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [paginationPageSize]="paginationPageSize"
            [pagination]="true">
        </ag-grid-angular>
    </div>
    <professionnal-reference-add #professionnalRefenceAddModal (modalSave)="refresh()" [candidateId]="candidateId"
        [requestId]="requestId">
    </professionnal-reference-add>
</div>