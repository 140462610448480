<div class="m-portlet__body">
    <div class="m-form m-form--label-align-right">
      <div class="row align-items-center justify-content-center m--margin-bottom-10">
        <form [formGroup]="invoiceDateFormGroup" autocomplete="off" class="row align-items-center justify-content-center">
            <div class="form-group align-items-center justify-content-center">
              <input id="InvoiceDate" style="text-align: center;" formControlName="InvoiceDate" type="text" name="InvoiceDate" class="form-control"
                bsDatepicker
                [bsConfig]="{ dateInputFormat: 'YYYY/MM/DD', containerClass: 'theme-red',  adaptivePosition: true }"
                (bsValueChange)="onInvoiceDateSelection($event)" data-test-id="inoivce-wizzard-date-input">
                <h4 style="margin-top: 15px;">
                  <i class="flaticon-warning-sign" style="font-size: 30px; color: yellow;" [attr.aria-label]="l('Warning')">
                  </i>
                  {{"WizzardDateWarning" | localize}}
                </h4>
            </div>
          </form>
      </div>
    </div>
  </div>