<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid">
        <sub-header [title]="'FAQ' | localize">
            <div role="actions"></div>
        </sub-header>
        <div [class]="containerClass">
            <div class="card listContainer">
                <div class="card-body">
                    <div class="row justify-content-between">
                        <div class="col-lg-5 col-6">
                            <h3 class="gardium-title mb-4">{{'Frequently Asked Questions' | localize}}</h3>
                            <p class="mb-4">{{l('FaqIntroText')}}</p>
                            <p class="mb-0 fw-bolder">{{l('FaqIntroContactQuestion')}}</p>
                            <p>{{l('FaqIntroContactAnswer')}}<a href="mailto:infoverification@gardium.com" class="gardium-title text-decoration-underline">{{l('ContactForm')}}.</a></p>
                        </div>
                        <div class="col-lg-5 col-6 d-flex align-items-center">
                            <div class="d-flex flex-column w-100">
                                <h3 class="gardium-title">{{'Search' | localize}}</h3>
                                <div class="d-flex flex-row">
                                    <input
                                    [(ngModel)]="filterText"
                                    name="filterText"
                                    class="form-control m-input"
                                    [placeholder]="l('SearchWithThreeDot')"
                                    type="text"
                                    (keyup.enter)="find()"
                                    />
                                    <span class="input-group-btn">
                                        <button
                                        (click)="find()"
                                        class="btn btn-primary"
                                            type="submit"
                                            >
                                            <i
                                            class="flaticon-search-1"
                                                [attr.aria-label]="l('Search')"
                                            ></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card listContainer">
                <div class="card-body">
                    <section *ngIf="!searching()">
                        <div class="pb-3">
                            <!-- Big Filtering Buttons -->
                            <h3 class="row mb-5 w-100 gardium-title">{{ "Themes" | localize }}</h3>
                            <div class="row w-100 mb-5 card-grid">
                                <button class="col-lg-3 col-md-4 col-sm-6 filtering-button gold-on-gray"
                                        *ngFor="let faqCategory of faqCategories"
                                        (click)="filter(faqCategory.id)"
                                        [class.selected]="isSelected(faqCategory.id)">
                                        <app-svg-icon iconName="{{faqCategory.icon}}" class="mb-4"></app-svg-icon>
                                        <h2>{{ faqCategory.name }}</h2>
                                    <p class="details-text">{{ faqCategory.faqDescriptions.length }} {{(faqCategory.faqDescriptions.length > 1 ? "AnswersFound" : "AnswerFound") | localize}}</p>
                                </button>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div class="faq-category">
                            <div *ngFor="let faqCategory of filteredCategories">
                                <!-- Categorie Name + number of answers -->
                                <h1 class="my-3 gardium-title">
                                    {{ faqCategory.name }}
                                    <span class="details-text">
                                        {{ faqCategory.faqDescriptions.length }} {{(faqCategory.faqDescriptions.length > 1 ? "AnswersFound" : "AnswerFound") | localize}}
                                    </span>
                                </h1>
                                <!-- Questions in the category -->
                                <ng-container *ngFor="let faqDescription of faqCategory.faqDescriptions">
                                    <div id="{{'faq-'+faqDescription.id}}" class="row ms-4" *ngIf="faqDescription.status">
                                        <button class="btn btn-link d-flex justify-content-between align-items-top text-start w-100 col-12" type="button" (click)="faqDescription.expanded = !faqDescription.expanded">
                                            <span class="gardium-title">{{faqDescription.question}}</span>
                                            <span class="icon-wrapper" [ngClass]="{'icon-wrapper--blue': faqDescription.expanded}">
                                                <i class="fa" [ngClass]="{'fa-plus': !faqDescription.expanded, 'fa-minus': faqDescription.expanded}"></i>
                                            </span>
                                        </button>
                                        <div class="col-11 my-4" *ngIf="faqDescription.expanded">
                                            <ckeditor-output content="{{faqDescription.answer}}"></ckeditor-output>
                                        </div>
                                        <hr class="my-3">
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>
