import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CandidatsService } from '@shared/services/candidats.service';
import { ToastrService } from 'ngx-toastr';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CandidatOutput } from '@shared/models/candidat/candidatOutput';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AgGridAngular } from '@ag-grid-community/angular';
import { AddressHistoryOutput } from '@shared/models/addresshistory/addressHistoryOutput';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { AddressHistoryType } from '@shared/models/addresshistory/addressHistoryType';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { CandidatInput } from '@shared/models/candidat/candidatInput';
import { AppConsts } from '@shared/AppConsts';
import { MenuItem } from 'primeng/api';
import { socialInsuranceNumberValidators } from '@shared/utils/validation/NAS-validator';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';

@Component({
    selector: 'candidat-details',
    templateUrl: './candidat-details.component.html',
    animations: [ appModuleAnimation() ]
})
export class CandidatDetailsComponent extends AppComponentBase implements OnInit {
    @ViewChild('staticTabs') staticTabs: TabsetComponent;
    @ViewChild('agGrid') agGrid: AgGridAngular;

    formGroup = new UntypedFormGroup({
        status: new UntypedFormControl({ value: '', disabled: true }),
        firstName: new UntypedFormControl({ value: '', disabled: true }),
        lastName: new UntypedFormControl({ value: '', disabled: true }),
        sex: new UntypedFormControl({ value: '', disabled: true }),
        language: new UntypedFormControl({ value: '', disabled: true }),
        birthdate: new UntypedFormControl({ value: '', disabled: true }),
        otherNames: new UntypedFormControl({ value: '', disabled: true }),
        maidenName: new UntypedFormControl({ value: '', disabled: true }),
        birthPlace: new UntypedFormControl({ value: '', disabled: true }),
        email: new UntypedFormControl({ value: '', disabled: true }),
        socialInsuranceNumber: new UntypedFormControl({
            value: '',
            disabled: true,
            validators: socialInsuranceNumberValidators
        }),
        driverLicenceNumber: new UntypedFormControl({ value: '', disabled: true }),
        phoneNumber: new UntypedFormControl({ value: '', disabled: true }),
        phoneExtension: new UntypedFormControl({ value: '', disabled: true }),
        organization: new UntypedFormControl({ value: '', disabled: true })
    });

    rowDataAddressHistory: AddressHistoryOutput[] = [];
    selectedAddressHistory: AddressHistoryOutput;
    columnDefsAddressHistory: any;
    rowSelection: string;
    selectedAddressHistoryRow: any;
    filterText = '';
    publicId: string;
    result: CandidatOutput;

    masks = AppConsts.masks;
    fromOrganization = false;
    items: MenuItem[];

    constructor(
        private _service: CandidatsService,
        private toastr: ToastrService,
        public router: Router,
        private _appLocalizationService: AppLocalizationService,
        private _route: ActivatedRoute,
        private _router: Router,
        injector: Injector,
        private _dateTimeService: DateTimeService
    ) {
        super(injector);

        this.items = [
            {
                label: this._appLocalizationService.l('Candidates'),
                routerLink: [ '/candidats' ]
            },
            { label: this._appLocalizationService.l('CandidatDetails') }
        ];
    }

    ngOnInit() {
        if (this._route.snapshot.paramMap.get('publicId') !== '') {
            this.publicId = this._route.snapshot.paramMap.get('publicId');

            this._service.getCandidatByPublicId(this.publicId).subscribe({
                next: (response) => {
                    this.result = response.result;
                    this.formGroup.patchValue(this.result);

                    if (this._route.snapshot.paramMap.get('fromOrganization') !== '') {
                        this.fromOrganization = this._route.snapshot.paramMap.get('fromOrganization') === 'true';
                        if (this.fromOrganization) {
                            this.items = [
                                {
                                    label: this._appLocalizationService.l('Organizations'),
                                    routerLink: [ '/organizations' ]
                                },
                                {
                                    label: this._appLocalizationService.l('OrganizationDetails'),
                                    routerLink: [ `/organizations-details/${this.result.organization.publicId}` ]
                                },
                                {
                                    label: this._appLocalizationService.l('CandidatDetails')
                                }
                            ];
                        }
                    }

                    this.rowDataAddressHistory = this.result.addressHistories;
                    this.columnDefsAddressHistory = [
                        {
                            headerName: this._appLocalizationService.l('AddressHistoryAddress'),
                            width: 300,
                            valueGetter: function streetWithCivic(params) {
                                return params.data.address.civicNo + ' ' + params.data.address.street + ', ' + params.data.address.city + ' ' + params.data.address.postalCode;
                            },
                            sortable: true,
                            filter: true,
                            resizable: true
                        },
                        {
                            // tslint:disable-next-line: arrow-return-shorthand
                            headerName: this._appLocalizationService.l('AddressHistoryFrom'),
                            field: 'from',
                            sortable: true,
                            filter: true,
                            resizable: true,
                            cellRenderer: (data) => this._dateTimeService.formatDate(data.value, 'YYYY/MM/DD')
                        },
                        {
                            headerName: this._appLocalizationService.l('AddressHistoryTo'),
                            field: 'to',
                            sortable: true,
                            filter: true,
                            resizable: true,
                            cellRenderer: (data) => {
                                if (data.value === null) {
                                    return '';
                                }
                                return this._dateTimeService.formatDate(data.value, 'YYYY/MM/DD');
                            }
                        },
                        {
                            headerName: this._appLocalizationService.l('AddressHistoryType'),
                            width: 120,
                            field: 'type',
                            sortable: true,
                            resizable: true,
                            filter: true,
                            valueFormatter: this.toEnum.bind(this)
                        }
                    ];
                    this.formGroup.controls[ 'organization' ].setValue(this.result.organization.organizationName);
                    this.formGroup.controls[ 'status' ].setValue(this.changeStatusName(this.result.status));
                },
                error: (err) => {
                    this.notify.error('Server error: ' + err.error.error.message);
                }
            });
        }
    }

    toEnum(params: ValueFormatterParams): string {
        return this._appLocalizationService.l(AddressHistoryType[ params.value ]);
    }

    delete() {
        const candidat: CandidatInput = CandidatInput.fromOutput(this.result);

        this.message.confirm(this.l('VerificationDeleteWarningMessage'), this.l('AreYouSure'), (isConfirmed) => {
            if (isConfirmed) {
                try {
                    this._service.deleteCandidate(candidat).subscribe((response) => {
                        this.showDeleteSuccess();
                        this.returnToList();
                    });
                } catch (e) {
                    this.showDeleteErrorMessage();
                }
            }
        }, {
            confirmButtonText: this.l('Yes'),
            cancelButtonText: this.l('No')        
        });
    }

    showDeleteSuccess() {
        const title = this._appLocalizationService.l('CandidateDelete');
        const successMessage = this._appLocalizationService.l('CandidateDeleted');
        this.toastr.success(successMessage, title);
    }

    showDeleteErrorMessage() {
        const title = this._appLocalizationService.l('CandidateDeleteIssue');
        const errorMessage = this._appLocalizationService.l('CandidateDeleteTryAgainLater');
        this.toastr.error(errorMessage, title);
    }

    returnToList() {
        this._router.navigate([ '/candidats' ]);
    }

    update() {
        if (this.fromOrganization) {
            this._router.navigate([ `/candidats-update/${this.publicId}/true` ]);
        } else {
            this._router.navigate([ `/candidats-update/${this.publicId}` ]);
        }
    }

    createAddressHistory() {
        this._router.navigate([ '/address-history-create', this.result.publicId ]);
    }

    refresh() {
        this.filterText = '';
        this.agGrid.api.setQuickFilter('');
    }

    search() {
        this.agGrid.api.setQuickFilter(this.filterText);
    }

    changeStatusName(stat: number): string {
        switch (stat) {
            case 0:
                return this._appLocalizationService.l('Actif');
            case 1:
                return this._appLocalizationService.l('Inactif');
        }
    }
}
