import { Component, OnInit, Injector, Input, HostListener, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Router, ActivatedRoute } from '@angular/router';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { CandidatOutput } from '@shared/models/candidat/candidatOutput';
import { ValueFormatterParams, GridApi, GridOptions, ColDef } from '@ag-grid-enterprise/all-modules';
import { GridBtnUpdateComponent } from '@app/shared/grid/grid-btn-update/grid-btn-update.component';
import { candidatstatus } from '@shared/models/candidat/candidatStatus';
import { AppConsts } from '@shared/AppConsts';
import { organizationOutput } from '@shared/models/organization/organizationOutput';
import { Extensions } from '@shared/utils/extensions';
import { CandidateDatasourceService } from '@shared/services/candidates.datasource.service';
import { CandidateListOutput } from '@shared/models/candidat/candidateListOutput';
import { AgGridAngular } from '@ag-grid-community/angular';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';

@Component({
    selector: 'update-organization-candidates-list',
    templateUrl: './update-organization-candidates-list.component.html',
    styleUrls: ['./update-organization-candidates-list.component.scss'],
    providers: [DatePipe]
})
export class UpdateOrganizationCandidatesListComponent extends AppComponentBase implements OnInit {
    @ViewChild('agGridCandidates') agGrid: AgGridAngular;
    @Input() organizationPublicId: string;
    @Input() organizationIsActif = true;
    public gridOptions: GridOptions;
    public defaultColDef;
    public columnDefs: ColDef[];
    paginationNumberFormatter;
    paginationPageSize = 20;
    filterText = sessionStorage.getItem('candidate-search-text') ?? '';
    selectedCandidat: CandidateListOutput;
    rowSelection: string;
    selectedCandidatsRow: any;
    organization: organizationOutput;
    private gridApi: GridApi;

    constructor(
        private injector: Injector,
        private _router: Router,
        private _route: ActivatedRoute,
        private _appLocalizationService: AppLocalizationService,
        private candidateDatasourceService: CandidateDatasourceService,
        private datePipe: DatePipe,
        private _dateTimeService: DateTimeService
    ) {
        super(injector);

        this.gridOptions = <GridOptions>{
            rowModelType: 'serverSide',
            animateRows: true,
            cacheBlockSize: this.paginationPageSize,
            groupSuppressAutoColumn: true,
            blockLoadDebounceMillis: 100
        };
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        if (this.gridApi) {
            if (screen.availWidth > 414) {
                this.gridApi.sizeColumnsToFit();
            }
        }
    }

    toEnum(params: ValueFormatterParams): string {
        return this._appLocalizationService.l(candidatstatus[params.value]);
    }

    async ngOnInit() {
        this.candidateDatasourceService.setOrganizationPublicId(this.organizationPublicId);

        const actif = this._appLocalizationService.l('Actif');
        const inActif = this._appLocalizationService.l('Inactif');
        this.columnDefs = [
            {
                headerName: this._appLocalizationService.l('FirstName'),
                field: 'firstName',
                sortable: true,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                },
                width: 200,
                comparator: Extensions.customComparator
            },
            {
                headerName: this._appLocalizationService.l('LastName'),
                field: 'lastName',
                width: 200,
                sortable: true,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                },
                comparator: Extensions.customComparator
            },
            {
                headerName: this._appLocalizationService.l('PhoneNumber'),
                field: 'phoneNumber',
                valueGetter: this.concatTelephonePoste,
                sortable: true,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                },
                width: 200
            },
            {
                headerName: this._appLocalizationService.l('OrganizationName'),
                field: 'organizationName',
                sortable: true,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                },
                width: 208,
                comparator: Extensions.customComparator
            },
            {
                headerName: this._appLocalizationService.l('CreationTime'),
                field: 'creationTime',
                sortable: true,
                filter: 'agDateColumnFilter',
                filterParams: {
                    filterOptions: ['inRange'],
                    suppressAndOrCondition: true
                },
                valueFormatter: this.dateFormater.bind(this),
                sort: 'desc',
                width: 200
            },
            {
                headerName: this._appLocalizationService.l('Status'),
                sortable: true,
                filter: 'agSetColumnFilter',
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true,
                    values: [actif, inActif]
                },
                valueFormatter: (params) => this.statusFormatter(params, actif, inActif),
                field: 'status'
            },
            {
                headerName: '',
                cellClass: 'text-end',
                cellRendererFramework: this.getEditBtnIfNotReadOnly(),
                cellRendererParams: { action: this.updateCandidat }
            },
            {
                headerName: '',
                field: 'globalFilter',
                sortable: false,
                filter: 'agTextColumnFilter',
                filterParams: { filterOption: ['contains'] },
                flex: 1,
                hide: true,
                lockVisible: true
            }
        ];
        this.defaultColDef = { resizable: true };
        this.rowSelection = this.isGranted('Pages.Management.Candidat.Details') ? 'single' : null;

        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };
    }

    getEditBtnIfNotReadOnly() {
        const btn = GridBtnUpdateComponent;
        if (this.organizationIsActif && this.isGranted('Pages.Management.Candidat.Update')) {
            return btn;
        } else {
            return null;
        }
    }

    concatTelephonePoste(params) {
        if (params && params.data) {
            let prefix = ' + 1 ';
            if (params.data.phoneExtension === null) {
                params.data.phoneExtension = '';
            }
            if (params.data.phoneNumber === null || params.data.phoneNumber === '') {
                params.data.phoneNumber = '';
                prefix = '';
            }
            const phoneExtension = params.data.phoneExtension !== '' ? '#' + params.data.phoneExtension : '';
            return prefix + params.data.phoneNumber + ' ' + phoneExtension;
        }

        return '';
    }

    onPageSizeChanged(e) {
        this.gridApi.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    onGridReady(params) {
        this.gridApi = params.api;

        // register the datasource with the grid
        this.gridApi.setServerSideDatasource(this.candidateDatasourceService);
        this.onFirstDataRendered();

        if (screen.availWidth > 414) {
            this.gridApi.sizeColumnsToFit();
        }

        if (this.filterText !== '') {
            this.search();
        }
    }

    dateFormater(params: ValueFormatterParams) {
        return this.datePipe.transform(this._dateTimeService.toUtcDate(params.value).toLocal().toString(), AppConsts.dateTimeFormat);
    }

    onSelectionChanged() {
        this.selectedCandidatsRow = this.gridApi.getSelectedRows()[0] as CandidatOutput;
        this._router.navigate([`/candidats-details/${this.selectedCandidatsRow.publicId}/true`]);
    }

    refresh() {
        this.filterText = '';
        this.search();
    }

    search() {
        const filterGlobal = this.agGrid.gridOptions.api.getFilterInstance('globalFilter');
        filterGlobal.setModel({
            filterType: 'text',
            type: 'contains',
            filter: this.filterText
        });
        this.agGrid.gridOptions.api.onFilterChanged();
        sessionStorage.setItem('candidate-search-text', this.filterText);
    }

    updateCandidat = (organization: organizationOutput) => {
        if (this.organizationPublicId === undefined) {
            this._router.navigate(['/candidats-update', organization.publicId]);
        } else {
            this._router.navigate(['/organizations-candidats-update', organization.publicId, this.organizationPublicId]);
        }
    };

    onSortChanged(params) {
        sessionStorage.setItem('candidates-sortModel', JSON.stringify(params.api.getSortModel()));
    }

    onFilterChanged(params) {
        sessionStorage.setItem('candidates-filterModel', JSON.stringify(params.api.getFilterModel()));
    }

    onFirstDataRendered() {
        const sortModel = JSON.parse(sessionStorage.getItem('candidates-sortModel'));
        const filterModel = JSON.parse(sessionStorage.getItem('candidates-filterModel'));
        if (sortModel) {
            this.gridApi.setSortModel(sortModel);
        }
        if (filterModel) {
            this.gridApi.setFilterModel(filterModel);
        }
    }

    resetFilters() {
        sessionStorage.setItem('candidates-filterModel', null);
        this.gridApi.setFilterModel(null);
    }

    resetSort() {
        // reset to default as defined in colDefs
        this.gridApi.setSortModel([{ colId: 'creationTime', sort: 'desc' }]);
    }

    private statusFormatter(params: any, active: string, inactive: string) {
        let result: string;
        switch (params.value) {
            case 0:
                result = active;
                break;
            case 1:
                result = inactive;
                break;
            default:
                result = '';
                break;
        }
        params.value = result;
        return result;
    }
}
