<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid">
        <sub-header
            [title]="(isSubOrganization ? 'SubOrganizations' : 'Organizations') | localize"
            [description]="(isSubOrganization ? 'SubOrganizationsDetails' : 'OrganizationDetails') | localize"
        >
            <div role="actions">
                <button
                    class="btn btn-secondary organizationsDetails-btn"
                    (click)="approveOrganization()"
                    *ngIf="(waitingForApprobation || declined) && isGranted('Pages.Management.Organizations.Approve')"
                    data-test-id="org-details-approve-btn"
                >
                    <i class="fas fa-check"></i>
                    {{ 'Approve' | localize }}
                </button>
                <button
                    class="btn btn-secondary organizationsDetails-btn"
                    (click)="declineOrganization()"
                    *ngIf="waitingForApprobation && isGranted('Pages.Management.Organizations.Decline')"
                    data-test-id="org-details-decline-btn"
                >
                    <i class="fas fa-times"></i>
                    {{ 'Decline' | localize }}
                </button>
                <button
                    class="btn btn-primary organizationsDetails-btn"
                    (click)="updateOrganization()"
                    *ngIf="isGranted('Pages.Management.Organizations.Update')"
                    data-test-id="org-details-edit-btn"
                >
                    <i class="la la-edit"></i>
                    {{ 'Edit' | localize }}
                </button>
                <button
                    class="btn btn-danger"
                    (click)="delete()"
                    *ngIf="!waitingForApprobation && isGranted('Pages.Management.Organizations.Delete')"
                    data-test-id="org-details-delete-btn"
                >
                    <i class="fas fa-trash-alt"></i>
                    {{ 'Delete' | localize }}
                </button>
            </div>
        </sub-header>

        <div [class]="containerClass">
            <div class="row" class="breadCrumbContainer">
                <div class="col pl-0 pr-0">
                    <p-breadcrumb [model]="items"></p-breadcrumb>
                </div>
            </div>
            <form [formGroup]="formGroup" autocomplete="off">
                <tabset #detailTabs>
                    <tab (selectTab)="onSelectTab($event)" heading="{{ (isSubOrganization ? 'SubOrganizations' : 'Organization') | localize }}">
                        <div class="card card-custom">
                            <div class="card-body">
                                <div class="form-group" *ngIf="isSubOrganization">
                                    <label for="organizationParentName">{{ 'OrganizationParent' | localize }}</label>
                                    <input
                                        id="organizationParentName"
                                        formControlName="organizationParentName"
                                        type="text"
                                        name="organizationParentName"
                                        class="form-control"
                                        maxlength="256"
                                    />
                                </div>
                                <div class="form-group" *ngIf="organizationLogo != ''">
                                    <label for="organizationLogo" class="flex">{{ 'OrganizationLogo' | localize }}</label>
                                    <img id="organizationLogo" class="symbol-label" alt="orgLogo" [src]="organizationLogo" />
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label for="organizationName">{{ (isSubOrganization ? 'SubOrganizationName' : 'OrganizationName') | localize }}</label>
                                        <input id="organizationName" formControlName="organizationName" type="text" name="organizationName" class="form-control" maxlength="256" />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="lineOfBusiness">{{ 'BusinessActivity' | localize }}</label>
                                        <input id="lineOfBusiness" formControlName="lineOfBusiness" type="text" name="lineOfBusiness" class="form-control" maxlength="256" />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="provenance" *ngIf="isGranted('Pages.Management.Organizations.CanSeePrivateInformation')">
                                            {{ 'HearAboutGardiumLabel' | localize }}
                                        </label>
                                        <input
                                            formControlName="provenance"
                                            type="text"
                                            name="provenance"
                                            class="form-control"
                                            maxlength="256"
                                            *ngIf="isGranted('Pages.Management.Organizations.CanSeePrivateInformation')"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-4">
                                        <label for="status">{{ 'Status' | localize }}</label>
                                        <input id="status" formControlName="status" type="text" name="status" class="form-control" maxlength="256" />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="clientId">{{ 'ClientId' | localize }}</label>
                                        <input id="clientId" formControlName="clientId" type="text" name="clientId" class="form-control" maxlength="256" />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="volumeOfRequestPerYear">{{ 'VolumeOfRequestPerYear' | localize }}</label>
                                        <select
                                            id="volumeOfRequestPerYear"
                                            formControlName="volumeOfRequestPerYear"
                                            name="volumeOfRequestPerYear"
                                            class="form-control"
                                            data-test-id="volume-of-request-per-year-select"
                                        >
                                            <option *ngFor="let item of organizationRequestVolumeRanges | enumToArray | slice : 0 : -1" [value]="item.index">
                                                {{ item.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-4">
                                        <label for="purchaseOrder">{{ 'PurchaseOrder' | localize }}</label>
                                        <input id="purchaseOrder" formControlName="purchaseOrder" type="text" name="purchaseOrder" class="form-control" maxlength="256" />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="idSage" *ngIf="isGranted('Pages.Management.Organizations.CanSeePrivateInformation')">{{ 'IdSage' | localize }}</label>
                                        <input
                                            id="idSage"
                                            formControlName="idSage"
                                            type="text"
                                            name="idSage"
                                            class="form-control"
                                            maxlength="256"
                                            *ngIf="isGranted('Pages.Management.Organizations.CanSeePrivateInformation')"
                                        />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="idAccountantCAR" *ngIf="isGranted('Pages.Management.Organizations.CanSeePrivateInformation')">
                                            {{ 'IdAccountantCAR' | localize }}
                                        </label>
                                        <input
                                            id="idAccountantCAR"
                                            formControlName="idAccountantCAR"
                                            type="text"
                                            name="idAccountantCAR"
                                            class="form-control"
                                            maxlength="256"
                                            *ngIf="isGranted('Pages.Management.Organizations.CanSeePrivateInformation')"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-4">
                                        <label for="firstContact">{{ 'FirstContact' | localize }}</label>
                                        <input id="firstContact" formControlName="firstContact" type="text" name="firstContact" class="form-control" maxlength="256" />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="firstContactEmail">{{ 'FirstContactEmail' | localize }}</label>
                                        <input
                                            id="firstContactEmail"
                                            formControlName="firstContactEmail"
                                            type="text"
                                            name="firstContactEmail"
                                            class="form-control"
                                            maxlength="256"
                                        />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="jobTitle">{{ 'JobTitleLabel' | localize }}</label>
                                        <input type="text" name="jobTitle" class="form-control" formControlName="jobTitle" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label for="phoneNumber">{{ 'PhoneNumber' | localize }}</label>
                                        <input
                                            id="phoneNumber"
                                            formControlName="phoneNumber"
                                            type="text"
                                            name="phoneNumber"
                                            mask="(000) 000-0000"
                                            prefix="+ 1 "
                                            [dropSpecialCharacters]="false"
                                            [specialCharacters]="['+', '1', '(', ')', ' ', '-']"
                                            [showMaskTyped]="true"
                                            class="form-control"
                                            maxlength="256"
                                        />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="phoneExtension">{{ 'PhoneNumberExtension' | localize }}</label>
                                        <input
                                            id="phoneExtension"
                                            formControlName="phoneExtension"
                                            type="number"
                                            name="phoneExtension"
                                            class="form-control"
                                            maxlength="256"
                                            min="0"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label for="language">{{ 'Language' | localize }}</label>
                                        <select name="language" class="form-control" id="language" formControlName="language">
                                            <option value="">{{ 'Select' | localize }}</option>
                                            <option value="english">{{ 'English' | localize }}</option>
                                            <option value="french">{{ 'French' | localize }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="hasActiveClientUser">{{ 'HasActiveClientUser' | localize }}</label>
                                        <input
                                            id="hasActiveClientUser"
                                            formControlName="hasActiveClientUser"
                                            type="text"
                                            name="hasActiveClientUser"
                                            class="form-control"
                                            maxlength="256"
                                            min="0"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-12" *ngIf="isGranted('Pages.Management.Organizations.CanSeePrivateInformation')">
                                        <label for="notes">{{ 'Notes' | localize }}</label>
                                        <textarea class="form-control" rows="5" id="notes" formControlName="notes" name="notes"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab>
                    <tab (selectTab)="onSelectTab($event)" heading="{{ 'AddressTitle' | localize }}" *ngIf="isGranted('Pages.Management.Organizations.AddresseInfoAccess')">
                        <div class="card card-custom">
                            <div class="card-body">
                                <app-address-fields [formGroup]="addressFormGroup" [readOnly]="true"></app-address-fields>
                            </div>
                        </div>
                    </tab>
                    <tab (selectTab)="onSelectTab($event)" heading="{{ 'BillingInfoTitle' | localize }}" *ngIf="isGranted('Pages.Management.Organizations.BillingInfoAccess')">
                        <div class="card card-custom">
                            <div class="card-body">
                                <div class="row">
                                    <div class="form-group edition-list col-md-4">
                                        <label for="billingOutputType">{{ 'BillingOutputType' | localize }}</label>
                                        <input name="billingOutputType" class="form-control" id="billingOutputType" formControlName="billingOutputType" />
                                    </div>
                                    <div class="form-group edition-list col-md-4">
                                        <label for="billingPeriod">{{ 'BillingPeriod' | localize }}</label>
                                        <input name="billingPeriod" class="form-control" id="billingPeriod" formControlName="billingPeriod" />
                                    </div>
                                    <div class="form-group edition-list col-md-4">
                                        <label for="billingMethod">
                                            {{ 'BillingMethod' | localize }}
                                            <i class="la la-exclamation-circle" data-toggle="tooltip" title="{{ 'TooltipBadCreditCard' | localize }}" id="tooltipInfo"></i>
                                        </label>
                                        <input name="billingMethod" class="form-control" id="billingMethod" formControlName="billingMethod" />
                                    </div>
                                </div>
                                <div class="row" *ngIf="hasCreditCard">
                                    <div class="form-group col-md-6">
                                        <label for="creditCardNumber">{{ 'CreditCardNumber' | localize }}</label>
                                        <input id="creditCardNumber" formControlName="creditCardNumber" type="text" name="creditCardNumber" class="form-control" maxlength="16" />
                                        <validation-messages [formCtrl]="formGroup.get('creditCardNumber')"></validation-messages>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="expdate">{{ 'Expdate' | localize }}</label>
                                        <input
                                            id="expdate"
                                            formControlName="expdate"
                                            type="text"
                                            name="expdate"
                                            class="form-control"
                                            maxlength="4"
                                            placeholder="{{ 'PlaceholderExpDate' | localize }}"
                                        />
                                        <validation-messages [formCtrl]="formGroup.get('expdate')"></validation-messages>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-4">
                                        <label for="billingContact">{{ 'BillingContact' | localize }}</label>
                                        <input id="billingContact" formControlName="billingContact" type="text" name="billingContact" class="form-control" maxlength="256" />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="billingEmail">{{ 'BillingEmail' | localize }}</label>
                                        <input id="billingEmail" formControlName="billingEmail" type="text" name="billingEmail" class="form-control" maxlength="256" />
                                    </div>
                                    <div class="form-group col-md-4" *ngIf="hasCreditCard">
                                        <label for="preAuthorizedPayment">
                                            {{ 'PreAuthorizedPayment' | localize }}
                                            <i class="la la-exclamation-circle" data-toggle="tooltip" title="{{ 'TooltipPreAuthorizedPayment' | localize }}" id="tooltipInfo"></i>
                                        </label>
                                        <select
                                            id="preAuthorizedPayment"
                                            formControlName="preAuthorizedPayment"
                                            type="text"
                                            name="preAuthorizedPayment"
                                            class="form-control"
                                            maxlength="256"
                                        >
                                            <option value="0">{{ 'No' | localize }}</option>
                                            <option value="1">{{ 'Yes' | localize }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="formSeparation">
                                    <h4>{{ 'BillingAddress' | localize }}</h4>
                                    <app-address-fields [formGroup]="billingAddressFormGroup" [readOnly]="true"></app-address-fields>
                                </div>
                            </div>
                        </div>
                    </tab>
                    <tab
                        (selectTab)="onSelectTab($event)"
                        heading="{{ 'OrganizationVerificationList' | localize }}"
                        *ngIf="isGranted('Pages.Management.Organizations.VerificationListAcess')"
                    >
                        <div class="card card-custom">
                            <div class="card-body">
                                <div class="row align-items-center mb-5">
                                    <div class="col text-end mt-3 mt-md-0">
                                        <a
                                            href="javascript:;"
                                            (click)="addVerification()"
                                            class="btn btn-primary"
                                            *ngIf="organizationIsActif && isGranted('Pages.Management.Organizations.Details.VerificationLink')"
                                        >
                                            <i class="fa fa-plus"></i>
                                            {{ 'New' | localize }}
                                        </a>
                                    </div>
                                </div>
                                <organization-verifications-list [organizationIsActif]="organizationIsActif"></organization-verifications-list>
                            </div>
                        </div>
                    </tab>
                    <tab (selectTab)="onSelectTab($event)" heading="{{ 'ListCandidates' | localize }}" *ngIf="isGranted('Pages.Management.Organizations.CandidateListAccess')">
                        <div class="card card-custom">
                            <div class="card-body">
                                <div class="row align-items-center mb-5">
                                    <div class="col text-end mt-3 mt-md-0">
                                        <a
                                            href="javascript:;"
                                            (click)="addCandidat()"
                                            class="btn btn-primary"
                                            *ngIf="organizationIsActif && isGranted('Pages.Management.Candidat.Create')"
                                        >
                                            <i class="fa fa-plus"></i>
                                            {{ 'NewCandidate' | localize }}
                                        </a>
                                    </div>
                                </div>

                                <update-organization-candidates-list
                                    [organizationPublicId]="publicId"
                                    [organizationIsActif]="organizationIsActif"
                                ></update-organization-candidates-list>
                            </div>
                        </div>
                    </tab>
                    <tab
                        (selectTab)="onSelectTab($event)"
                        heading="{{ 'SubOrganization' | localize }}"
                        *ngIf="!isSubOrganization && isGranted('Pages.Management.Organizations.SubOrganizationList')"
                    >
                        <div class="card card-custom">
                            <div class="card-body">
                                <div class="row align-items-center mb-5">
                                    <div class="col text-end mt-3 mt-md-0">
                                        <a
                                            href="javascript:;"
                                            (click)="addSubOrganization()"
                                            class="btn btn-primary"
                                            *ngIf="organizationIsActif && isGranted('Pages.Management.Organizations.Create')"
                                        >
                                            <i class="fa fa-plus"></i>
                                            {{ 'New' | localize }}
                                        </a>
                                    </div>
                                </div>

                                <list-search [organizationPublicId]="publicId"></list-search>
                            </div>
                        </div>
                    </tab>
                    <tab (selectTab)="onSelectTab($event)" heading="{{ 'ListInvoices' | localize }}" *ngIf="isGranted('Pages.Management.Organizations.InvoiceListAccess')">
                        <div class="card card-custom">
                            <div class="card-body">
                                <organization-invoice-list [organizationPublicId]="publicId"></organization-invoice-list>
                            </div>
                        </div>
                    </tab>
                    <tab (selectTab)="onSelectTab($event)" heading="{{ 'UserList' | localize }}" *ngIf="isGranted('Pages.Management.Organizations.UserList')">
                        <div class="card card-custom">
                            <div class="card-body">
                                <organization-users></organization-users>
                            </div>
                        </div>
                    </tab>
                </tabset>
            </form>
        </div>
    </div>
</div>
