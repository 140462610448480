import { Component, OnInit, Injector } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { CandidatsService } from '@shared/services/candidats.service';
import { ToastrService } from 'ngx-toastr';
import { CandidatInput } from '@shared/models/candidat/candidatInput';
import { candidatstatus } from '@shared/models/candidat/candidatStatus';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { organizationOutput } from '@shared/models/organization/organizationOutput';
import { OrganizationService } from '@shared/services/organization.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { phoneNumberValidator } from '@shared/utils/validation/phoneNumber-validator.directive';
import { emailAddressValidator } from '@shared/utils/validation/email-validator.directive';
import { organizationStatus } from '@shared/models/organization/organizationStatus';
import { AppConsts } from '@shared/AppConsts';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { MenuItem } from 'primeng/api';
import { CRUDAction } from '@shared/models/shared/CRUDAction';
import { socialInsuranceNumberValidators } from '@shared/utils/validation/NAS-validator';

@Component({
    selector: 'app-candidat-create',
    templateUrl: './candidat-create.component.html',
    styleUrls: [ './candidat-create.component.scss' ],
    animations: [ appModuleAnimation() ]
})
export class CandidatCreateComponent extends AppComponentBase implements OnInit {
    maxDate: Date;

    organizationList: organizationOutput[] = [];
    selectedOrganization: organizationOutput;
    noResultOrganization = false;
    cantChangeOrganization = false;
    isCandidatCreated = false;
    createRequestToo = false;

    // tslint:disable-next-line: no-inferrable-types
    organizationPublicId = '';

    masks = AppConsts.masks;

    formGroup = new UntypedFormGroup({
        firstName: new UntypedFormControl('', Validators.required),
        lastName: new UntypedFormControl('', Validators.required),
        sex: new UntypedFormControl('', Validators.required),
        language: new UntypedFormControl('', Validators.required),
        birthdate: new UntypedFormControl('', Validators.required),
        email: new UntypedFormControl('', emailAddressValidator),
        otherNames: new UntypedFormControl(''),
        maidenName: new UntypedFormControl(''),
        birthPlace: new UntypedFormControl(''),
        socialInsuranceNumber: new UntypedFormControl('', {
            validators: socialInsuranceNumberValidators
        }),
        driverLicenceNumber: new UntypedFormControl(''),
        phoneNumber: new UntypedFormControl('', phoneNumberValidator),
        phoneExtension: new UntypedFormControl(''),
        organization: new UntypedFormControl('', Validators.required)
    });

    items: MenuItem[];

    constructor(
        injector: Injector,
        private service: CandidatsService,
        private _route: ActivatedRoute,
        private toastr: ToastrService,
        private localeService: BsLocaleService,
        private organizationService: OrganizationService,
        private _appLocalizationService: AppLocalizationService,
        private _router: Router
    ) {
        super(injector);
        this.maxDate = new Date();
        this.localeService.use(this.localization.currentLanguage.name);
        this.items = [
            {
                label: this._appLocalizationService.l('Candidates'),
                routerLink: [ '/candidats' ]
            },
            { label: this._appLocalizationService.l('CandidatCreate') }
        ];
    }

    ngOnInit() {
        if (this._route.snapshot.paramMap.get('createRequestToo') !== null) {
            this.createRequestToo = true;
            this.initializeOrganizations();
            return;
        }
        if (this._route.snapshot.paramMap.get('publicId') !== null) {
            this.organizationPublicId = this._route.snapshot.paramMap.get('publicId');

            if (this._route.snapshot.paramMap.get('fromOrganization') !== '') {
                const fromOrganization = this._route.snapshot.paramMap.get('fromOrganization') === 'true';
                if (fromOrganization) {
                    this.items = [
                        {
                            label: this._appLocalizationService.l('Organizations'),
                            routerLink: [ '/organizations' ]
                        },
                        {
                            label: this._appLocalizationService.l('OrganizationDetails'),
                            routerLink: [ `/organizations-details/${this.organizationPublicId}` ]
                        },
                        {
                            label: this._appLocalizationService.l('CandidatCreate')
                        }
                    ];
                }
            }
            this.organizationService.getOrganizationsByPublicId(this.organizationPublicId).subscribe((response) => {
                this.selectedOrganization = response.result as organizationOutput;
                if (this.selectedOrganization !== null) {
                    this.formGroup.controls[ 'organization' ].setValue(this.selectedOrganization.organizationName);
                    this.cantChangeOrganization = true;
                }
            });
        } else {
            this.initializeOrganizations();
        }
    }

    async initializeOrganizations() {
        const organizationsActif = [];
        await this.organizationService
            .getOrganizationsList()
            .toPromise()
            .then((value) => {
                this.organizationList = value.result as organizationOutput[];
                this.organizationList.forEach((element) => {
                    if (element.status === organizationStatus.Actif) {
                        organizationsActif.push(element);
                    }
                });
                this.organizationList = organizationsActif;
                this.organizationList.sort(function (a, b) {
                    const textA = a.organizationName.toUpperCase();
                    const textB = b.organizationName.toUpperCase();
                    if (textA < textB) {
                        return -1;
                    } else if (textA > textB) {
                        return 1;
                    }
                    return 0;
                });
            });
        this.initializeOrganizationIfUserAccessToOnlyOne();
    }

    initializeOrganizationIfUserAccessToOnlyOne() {
        if (this.organizationList.length === 1) {
            this.selectedOrganization = this.organizationList[ 0 ];
            this.formGroup.controls[ 'organization' ].setValue(this.selectedOrganization.organizationName);
            this.formGroup.controls[ 'organization' ].disable();
        }
    }

    onSelect(event: TypeaheadMatch): void {
        this.selectedOrganization = event.item as organizationOutput;
    }

    typeaheadNoResults(event: boolean): void {
        this.noResultOrganization = event;
    }

    save() {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }

        const candidate: CandidatInput = CandidatInput.fromFormControl('', this.formGroup, CRUDAction.Create);

        candidate.id = 0;
        candidate.status = candidatstatus.Actif;
        candidate.organization = this.selectedOrganization;
        candidate.creationTime = new Date();

        this.isCandidatCreated = true;
        this.service.createCandidat(candidate).subscribe({
            next: (value) => {
                if (value.result.publicId !== null) {
                    this.showSuccess();
                    this.return(value.result.publicId);
                } else {
                    this.isCandidatCreated = false;
                    this.showErrorMessage();
                }
                return value.result;
            },
            error: (err) => {
                this.notify.error('Server error: ' + err.error.error.message);
            }
        });
    }

    isFormInvalid(): boolean {
        return this.formGroup.invalid;
    }

    showForNotValidMessage() {
        const title = this._appLocalizationService.l('VerifyAllControlsTitle');
        const message = this._appLocalizationService.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }

    markAllControlsAsTouch() {
        this.formGroup.controls[ 'firstName' ].markAsTouched();
        this.formGroup.controls[ 'lastName' ].markAsTouched();
        this.formGroup.controls[ 'sex' ].markAsTouched();
        this.formGroup.controls[ 'language' ].markAsTouched();
        this.formGroup.controls[ 'birthdate' ].markAsTouched();
        this.formGroup.controls[ 'otherNames' ].markAsTouched();
        this.formGroup.controls[ 'maidenName' ].markAsTouched();
        this.formGroup.controls[ 'birthPlace' ].markAsTouched();
        this.formGroup.controls[ 'socialInsuranceNumber' ].markAsTouched();
        this.formGroup.controls[ 'driverLicenceNumber' ].markAsTouched();
        this.formGroup.controls[ 'phoneNumber' ].markAsTouched();
        this.formGroup.controls[ 'organization' ].markAsTouched();
        this.formGroup.controls[ 'email' ].markAsTouched();
    }

    showSuccess() {
        const title = this._appLocalizationService.l('CandidatCreate');
        const successMessage = this._appLocalizationService.l('CandidatCreated');
        this.toastr.success(successMessage, title);
    }

    showErrorMessage() {
        const title = this._appLocalizationService.l('CandidatCreateIssue');
        const errorMessage = this._appLocalizationService.l('CandidatCreateTryAgainLater');
        this.toastr.error(errorMessage, title);
    }

    return(publicId: string) {
        if (this.createRequestToo) {
            this.goToCreateRequest(publicId);
        } else if (this.organizationPublicId !== '') {
            this.returnToOrganization();
        } else {
            this.returnToList();
        }
    }

    cancelCreation() {
        if (this.organizationPublicId !== '') {
            this.returnToOrganization();
        } else {
            this.returnToList();
        }
    }

    goToCreateRequest(candidatPublicId: string) {
        this._router.navigate([ '/requests-create', candidatPublicId ]);
    }

    returnToList() {
        this._router.navigate([ '/candidats' ]);
    }

    returnToOrganization() {
        this._router.navigate([ '/organizations-details', this.organizationPublicId ]);
    }
}
