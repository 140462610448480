import { HttpClient } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { environment as env } from 'environments/environment';
import { organizationB2bInput } from "@shared/models/organization/organizationB2BInput";
import { OrganizationInput } from "@shared/models/organization/organizationInput";
import { Observable } from "rxjs";

@Injectable()
export class OrganizationB2BService {
    private http: HttpClient;
    private baseUrl: string;

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = env.api;
    }

    createOrganization(organization: organizationB2bInput): Observable<any> {
        let url_ = this.getUrl('/api/organizationB2B/CreateOrgApi');
        return this.http.post<any>(url_, organization);
    }

    approveOrganization(organization: OrganizationInput): Observable<any> {
        let url_ = this.getUrl('/api/organizationB2B/approve');
        return this.http.post<any>(url_, organization);
    }

    declineOrganization(organization: OrganizationInput): Observable<any> {
        let url_ = this.getUrl('/api/organizationB2B/decline');
        return this.http.post<any>(url_, organization);
    }


    private getUrl(url: string): string {
        let url_ = this.baseUrl + url;
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    }
}
