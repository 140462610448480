import { RequestVerificationNoteOutput } from './requestVerificationNoteOutput';
import { RequestVerificationStatus } from './requestVerificationStatus';

export interface RequestVerificationNoteInput {
    id: number;
    verificationStatus: RequestVerificationStatus;
    note: string;
}

export function toRequestVerificationNoteInput(requestVerificationNoteOutput: RequestVerificationNoteOutput):
    RequestVerificationNoteInput {
    return {
        id: requestVerificationNoteOutput.id,
        verificationStatus: requestVerificationNoteOutput.verificationStatus,
        note: requestVerificationNoteOutput.note,
    };
}
