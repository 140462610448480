import { Component, ViewChild, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { InvoiceOutput } from '@shared/models/InvoiceElements/invoice/invoiceOutput';
import { InvoiceService } from '@shared/services/invoice.service';
import { WizardComponent } from 'angular-archwizard';
import { WizzardEmailSendComponent } from '../wizzard-email-send/wizzard-email-send.component';
import { WizzardStatusInvoiceComponent } from '../wizzard-invoice-status/wizzard-status-invoice.component';
import { InvoiceStatusCalculationRequest } from '@shared/models/InvoiceElements/invoice/InvoiceStatusCalculationRequest';

@Component({
    selector: 'app-wizzard-status-invoice-intro',
    templateUrl: './wizzard-status-invoice-intro.component.html',
    animations: [appModuleAnimation()]
})
export class WizzardStatusInvoiceIntroComponent extends AppComponentBase {
    @ViewChild('wizzardEmailSend') wizzardEmailSend: WizzardEmailSendComponent;
    @ViewChild('wizzardStatusInvoice')
    wizzardStatusInvoice: WizzardStatusInvoiceComponent;
    @ViewChild(WizardComponent) public wizard: WizardComponent;
    requestCalculationSelected: string | undefined;
    invoiceList: InvoiceOutput[] = [];
    showLoadingSpinner: boolean;
    calculationRequestId: string;

    constructor(private injector: Injector, private _invoiceService: InvoiceService, private _router: Router) {
        super(injector);
    }

    async onSendEmailRequestSelected(record: InvoiceStatusCalculationRequest) {
        this.calculationRequestId = record.invoiceCalculationRequestId;
        this.wizzardEmailSend.showInvoicesGenerated(record.invoicePublicIds);
        this.wizard.goToNextStep();
    }

    returnToInvoiceStatus() {
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate(['/status-invoice']);
        });
    }

    sendEmailAndComplete() {
        if (this.wizzardEmailSend.invoicePublicIdsSelected === undefined || this.wizzardEmailSend.invoicePublicIdsSelected.length === 0) {
            return;
        }
        this.showLoadingSpinner = true;

        this._invoiceService.sendInvoices(this.wizzardEmailSend.invoicePublicIdsSelected).subscribe(
            () => {
                this._invoiceService.deleteCalculation(this.calculationRequestId).subscribe(() => this.wizard.goToNextStep());
            },
            () => {
                this.notify.error('server error');
                this.showLoadingSpinner = false;
            }
        );
    }

    refreshWizzardEmailSend() {
        this.wizzardEmailSend.rowDataSubject.next([]);
    }
}
