import { Injectable } from '@angular/core';
import { RequestOutput } from '@shared/models/request/requestOutput';
import { Observable, of, race, ReplaySubject } from 'rxjs';
import { filter, share } from 'rxjs/operators';
import { OrganizationUserService } from './organizationUser.service';

@Injectable({
  providedIn: 'root'
})
export class RequestUserListService {

  userListsByOrganizationId: any = {};

  constructor(private organizationUserService: OrganizationUserService) {
  }

  getFetchAgentListObservable(organizationId: number){
    return this.organizationUserService.getAgentList(organizationId);
  }

  preloadAgentList(organizationId: number){
    const subject = new ReplaySubject<any>(1);
    this.getFetchAgentListObservable(organizationId).subscribe(subject);
    this.userListsByOrganizationId[organizationId] = subject.asObservable();
  }

  getAgentList(organizationId: number): Observable<any>{
    var observable = this.userListsByOrganizationId[organizationId]? this.userListsByOrganizationId[organizationId]: this.getFetchAgentListObservable(organizationId);
    this.userListsByOrganizationId[organizationId] = null;
    return observable;
  }
}
