import { StyleLoaderService } from '@shared/utils/style-loader.service'

export class DynamicResourcesHelper {
    static loadResources(callback: () => void): void {
        Promise.all([DynamicResourcesHelper.loadStyles()]).then(() => {
            callback()
        })
    }

    static loadStyles(): Promise<any> {
        let theme = 'default'

        const cssPrefix = ''
        const cssPostfix = ''
        const styleLoaderService = new StyleLoaderService()

        let styleUrls = [
            '/assets/metronic/themes/' + theme + '/css/style.bundle' + cssPostfix.replace('-', '.') + '.min.css',
            '/assets/primeng/datatable/css/primeng.datatable' + cssPostfix + '.min.css',
            '/assets/common/styles/metronic-customize.min.css',
            '/assets/common/styles/themes/' + theme + '/metronic-customize.min.css',
            '/assets/common/styles/metronic-customize-angular.min.css'
        ];

        styleLoaderService.loadArray(styleUrls)

        return Promise.resolve(true)
    }
}
