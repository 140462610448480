export enum verificationsCodes {
    'CIPC' = 'CIPC',
    'CIPC+' = 'CIPC+',
    'VDJP' = 'VDJP',
    'DCQC' = 'DCQC',
    'PCQ' = 'PCQ',
    'CSPQ' = 'CSPQ',
    'CRE' = 'CRE',
    'SAAQ' = 'SAAQ',
    'USAL' = 'USAL',
    'PRO' = 'PRO',
    'CEMP' = 'CEMP',
    'PERS' = 'PERS',
    'FACA' = 'FACA',
    'FACA+' = 'FACA+',
    'ASSP' = 'ASSP',
    'DJI' = 'DJI',
    'ADD' = 'ADD',
    'INTER' = 'INTER',
    'ATMN' = 'ATMN',
    'ATMN+' = 'ATMN+',
    'EDPR' = 'EDPR',
    'AUT' = 'AUT',
    'VPT' = 'VPT',
    'EDIG' = 'EDIG',
    'PMUN' = 'PMUN',
    'LOC' = 'LOC',
    'RSM' = 'RSM',
    'FORFAIT_A' = 'FORFAIT A',
    'FORFAIT_B' = 'FORFAIT B',
    'FORFAIT_C' = 'FORFAIT C',
    'FORFAIT_D' = 'FORFAIT D',
}

export class RequiredFieldsForCodes {
    static getDriverLicenseRequiredCodes(): string[] {
        return [verificationsCodes.SAAQ, verificationsCodes.USAL];
    }

    static getEmailRequiredCodes(): string[] {
        return [verificationsCodes.ATMN, verificationsCodes['ATMN+']];
    }

    static isDriverLicenseRequired(verificationCode: string): boolean {
        switch (verificationCode) {
            case verificationsCodes.SAAQ:
            case verificationsCodes.USAL:
                return true;
            default:
                return false;
        }
    }

    static getMoreThanOneAddressFormerRequiredCodes(): string[] {
        return [
            verificationsCodes.INTER,
            verificationsCodes.DJI,
            verificationsCodes.CIPC,
            verificationsCodes['CIPC+'],
            verificationsCodes.ADD,
        ];
    }
    static isMoreThanOneAddressFormerRequired(
        verificationCode: string
    ): boolean {
        switch (verificationCode) {
            case verificationsCodes.INTER:
            case verificationsCodes.DJI:
            case verificationsCodes.CIPC:
            case verificationsCodes['CIPC+']:
            case verificationsCodes.ADD:
                return true;
            default:
                return false;
        }
    }

    static getProfessionnalReferencesRequiredCodes(): string[] {
        return [verificationsCodes.PRO];
    }
    static isProfessionnalReferencesRequired(
        verificationCode: string
    ): boolean {
        switch (verificationCode) {
            case verificationsCodes.PRO:
                return true;
            default:
                return false;
        }
    }

    static getPersonnalReferencesRequiredCodes(): string[] {
        return [verificationsCodes.PERS];
    }

    static isPersonnalReferencesRequired(verificationCode: string): boolean {
        switch (verificationCode) {
            case verificationsCodes.PERS:
                return true;
            default:
                return false;
        }
    }

    static getFormsRequiredCodes(): string[] {
        return [verificationsCodes.PERS, verificationsCodes.PRO, verificationsCodes.CEMP];
    }

    static getAcademicRecordsRequiredCodes(): string[] {
        return [verificationsCodes.FACA];
    }

    static isAcademicRecordsRequired(verificationCode: string): boolean {
        switch (verificationCode) {
            case verificationsCodes.FACA:
            case verificationsCodes['FACA+']:
                return true;
            default:
                return false;
        }
    }

    static getProfessionnalAccreditationsRequiredCodes(): string[] {
        return [verificationsCodes.ASSP];
    }
    static isProfessionnalAccreditationsRequired(
        verificationCode: string
    ): boolean {
        switch (verificationCode) {
            case verificationsCodes.ASSP:
                return true;
            default:
                return false;
        }
    }

    static getAddressRequiredCodes(): string[] {
        return [
            verificationsCodes.PCQ,
            verificationsCodes.CRE,
            verificationsCodes.PERS,
            verificationsCodes.DJI,
        ];
    }
    static isAddressRequired(verificationCode: string): boolean {
        switch (verificationCode) {
            case verificationsCodes.PCQ:
            case verificationsCodes.CRE:
            case verificationsCodes.PERS:
            case verificationsCodes.DJI:
                return true;
            default:
                return false;
        }
    }
}
