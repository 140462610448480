import { Component, OnInit, Input, Injector } from '@angular/core';
import { DatePipe } from '@angular/common';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { InvoiceDisplay } from '@shared/models/InvoiceElements/invoice/invoiceElements/invoiceDisplay';
import { AppConsts } from '@shared/AppConsts';
import * as jsPDF from 'jspdf';
import { getLocalizedMonth } from '@shared/helpers/DateHelper';

@Component({
    selector: 'invoice-mobile',
    templateUrl: './invoice-mobile.component.html',
    providers: [DatePipe],
    animations: [appModuleAnimation()],
})
export class InvoiceMobileComponent extends AppComponentBase implements OnInit {
    @Input() invoiceDisplay: InvoiceDisplay;
    pageCount = 1;
    numberOfPagePDF: number;
    AppConsts = AppConsts;
    headElements = [];
    adjustmentElements = [];
    pdf: any;
    isPurchaseOrderAvailable = false;

    constructor(private injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        this.initializeBooleans();
        this.initializePrinterInformation();
    }

    initializeBooleans() {
        this.isPurchaseOrderAvailable =
            this.invoiceDisplay.invoiceHeader.purchaseOrder !== '';
    }

    initializePrinterInformation() {
        this.headElements = [
            this.l('Invoice_Verifications'),
            this.l('Invoice_Qty'),
            this.l('Invoice_Rate'),
            this.l('Invoice_Total'),
        ];
        this.adjustmentElements = [
            this.l('Invoice_Description'),
            this.l('Invoice_Adjustment'),
        ];
        this.pdf = new jsPDF('p', 'mm', 'a4');
        this.numberOfPagePDF = this.pdf.internal.getNumberOfPages();
    }

    monthFormat(invoiceDate: Date): string {
        return getLocalizedMonth(this, invoiceDate);
    }
}
