import { Component, OnInit, Output, EventEmitter, Injector } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'wizzard-invoice-date',
    templateUrl: './wizzard-invoice-date.component.html'
})
export class WizzardInvoiceDateComponent extends AppComponentBase implements OnInit {
    @Output() invoiceDateSelected = new EventEmitter<string>();

    invoiceDateFormGroup = new UntypedFormGroup({
        InvoiceDate: new UntypedFormControl({ value: '' })
    });

    constructor(private injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        this.invoiceDateFormGroup.controls['InvoiceDate'].setValue(new Date());
    }

    // Getting UTC Date from Date Picker and emitting to wizzard-intro template
    onInvoiceDateSelection(value: Date) {
        this.invoiceDateSelected.emit(value.toISOString());
    }
}
