import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-report-footer',
    templateUrl: './report-footer.component.html',
    styleUrls: ['./report-footer.component.scss']
})
export class ReportFooterComponent {
    @Input() lang: string;
}
