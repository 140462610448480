import { NgModule } from '@angular/core';
import { CardContainerComponent } from './card-container.component';
import { CommonModule } from '@angular/common';


@NgModule({
    imports: [CommonModule],
    exports: [CardContainerComponent],
    declarations: [CardContainerComponent],
})
export class CardContainerModule { }
