import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingInput } from '@shared/models/appSetting/appSettingInput';
import { AppSettingOutput } from '@shared/models/appSetting/appSettingOutput';
import { AppSettingsNames } from '@shared/models/appSetting/appSettingsNames';
import { environment as env } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class AppSettingService {
    private readonly url: string;

    constructor(
        private http: HttpClient
    ) {
        this.url = this.getUrl();
    }

    getAppSettingValue(appSettingName: AppSettingsNames): Observable<any> {
        const params = new HttpParams().set('name', appSettingName);
        return this.http.get<any>(this.url, { params });
    }

    setAppSettingValue(appSetting: AppSettingInput): Observable<any> {
        let appSettingOutput: AppSettingOutput = {
            name: appSetting.name,
            value: this.parseValue(appSetting)
        };
        return this.http.post<any>(this.url, appSettingOutput);
    }

    private parseValue(appSetting: AppSettingInput): string {
        let result: string;
        switch (appSetting.name) {
            case AppSettingsNames.dashboardInformationMessage:
                result = JSON.stringify(appSetting.value)
                break;
            default:
                result = appSetting.value
                break;
        }
        return result;
    }

    private getUrl(): string {
        let result = env.api + '/api/appSettings';
        return result.replace(/[?&]$/, '');
    }
}