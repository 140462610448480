import { Component, Injector } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Router } from '@angular/router';

@Component({
    selector: 'grid-btn-sendEmail',
    templateUrl: './grid-btn-sendEmail.component.html'
})
export class GridBtnSendEmailComponent extends AppComponentBase implements ICellRendererAngularComp {
    params: any;
    constructor(injector: Injector, private _router: Router) {
        super(injector);
    }

    refresh(params: any): boolean {
        return false;
    }

    agInit(params): void {
        this.params = params;
    }

    onClick() {
        this.params.action(this.params.data.publicId);
    }
}
