import { ValidatorFn, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export const postalCodeValidatorUS: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    if (control.value !== null) {
        const regex = /^\d{5}(?:[-\s]\d{4})?$|^$/;
        const result = control.value;
        const postalCode = regex.test(result);
        if (!postalCode) {
            return { BadPostalCode: true };
        } else {
            return null;
        }
    }
};
