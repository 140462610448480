import { Component, Injector, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { InvoiceService } from '@shared/services/invoice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InvoiceOutput } from '@shared/models/InvoiceElements/invoice/invoiceOutput';
import { ModalDirective, BsModalRef } from 'ngx-bootstrap/modal';
import { InvoiceLineCandidatsOutput } from '@shared/models/InvoiceElements/invoice/invoiceLineCandidats/invoiceLineCandidatsOutput';

@Component({
    selector: 'invoice-details-customize',
    templateUrl: './invoice-details-customize.component.html',
    animations: [appModuleAnimation()]
})
export class InvoiceDetailsCustomizeComponent extends AppComponentBase {
    @Input() selectedInvoice: InvoiceOutput;
    @ViewChild('invoiceCustomizeModal') invoiceCustomizeModal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    isCandidatChecked = false;
    resultCustomizeInvoice: InvoiceOutput;
    resultCandidats: InvoiceLineCandidatsOutput[];

    constructor(
        private injector: Injector,
        private service: InvoiceService,
        private _appLocalizationService: AppLocalizationService,
        private _route: ActivatedRoute,
        private toastr: ToastrService,
        private _router: Router,
        public bsModalRef: BsModalRef
    ) {
        super(injector);
    }

    checkValue(e: any) {
        if (e.target.checked) {
            this.isCandidatChecked = true;
        } else {
            this.isCandidatChecked = false;
        }
    }

    applyFormatOfInvoiceAfterChoice() {
        if (this.isCandidatChecked) {
            this.showSuccess('CustomizeInvoiceTitle', 'GetCustomizeInvoiceSuccess');
            this.modalSave.emit(this.isCandidatChecked);
            this.close();
        } else {
            this.showErrorMessage('CustomizeInvoiceTitle', 'GetCustomizeInvoiceFailed');
            this.modalSave.emit(this.isCandidatChecked);
            this.close();
        }
    }

    showSuccess(title: string, successMessage: string) {
        title = this.l(title);
        successMessage = this.l(successMessage);
        this.toastr.success(successMessage, title);
    }

    showErrorMessage(title: string, errorMessage: string) {
        title = this.l(title);
        errorMessage = this.l(errorMessage);
        this.toastr.error(errorMessage, title);
    }

    close(): void {
        this.bsModalRef.hide();
    }
}
