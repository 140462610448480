import { Component, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    template: '<router-outlet></router-outlet>'
})

export class GuidesManagementComponent extends AppComponentBase implements OnInit {
    ngOnInit() { }
}
