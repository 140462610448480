<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid">
        <sub-header [title]="'Candidates' | localize" [description]="'ListCandidates' | localize">
            <div role="actions">
                <button (click)="createCandidat()" class="btn btn-primary"
                    *ngIf="isGranted('Pages.Management.Candidat.Create')" data-test-id="candidats-list-create-candidat-button">
                    <i class="fa fa-plus"></i>
                    {{"NewCandidate" | localize }}
                </button>
            </div>
        </sub-header>
        <div [class]="containerClass">
            <div class="card card-custom listContainer">
                <div class="card-body">
                    <update-organization-candidates-list></update-organization-candidates-list>
                </div>
            </div>
        </div>
    </div>
</div>
