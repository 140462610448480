<div class="d-flex align-items-center">
    <div
        *ngIf="iconOnly"
        href="#"
        [class]="togglerCssClass"
        id="kt_quick_user_toggle"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
    >
        <span class="symbol symbol-30px">
            <span class="svg-icon svg-icon-1">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                        <path
                            d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                            opacity="0.3"
                        ></path>
                        <path
                            d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                        ></path>
                    </g>
                </svg>
            </span>
        </span>
    </div>

    <div *ngIf="!iconOnly" [class]="togglerCssClass" id="kt_quick_user_toggle" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
        <span [class]="textCssClass">
            <span class="d-inline-block fs-6">{{ _appAuthService.user.email }}</span>
        </span>
        <span [class]="symbolCssClass">
            <span [class]="symbolTextCssClass">
                {{ usernameInitial }}
            </span>
        </span>
    </div>

    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-400px" data-kt-menu="true">
        <div class="menu-item px-3">
            <div class="menu-content d-flex align-items-center px-3">
                <div class="symbol symbol-50px me-5">
                    <img alt="Logo" [src]="profilePicture" />
                </div>
                <div class="d-flex flex-column">
                    <div class="fw-bolder d-flex align-items-center fs-5">
                        {{ _appAuthService.user?.email }}
                    </div>
                    <a href="#" class="fw-bold text-muted text-hover-primary fs-7">
                        {{ userFullName | titlecase }}
                    </a>
                </div>
            </div>
        </div>
        <div class="separator my-2"></div>

        <!-- Profile Picture -->
        <div class="menu-item px-5 d-flex">
            <a href="javascript:;" (click)="changeProfilePicture()" class="menu-link px-5" id="UserProfileChangePictureLink">
                <div class="symbol symbol-30px bg-light me-3">
                    <div class="symbol-label">
                        <span class="svg-icon svg-icon-primary svg-icon-2x svg-icon-info">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <polygon points="0 0 24 0 24 24 0 24" />
                                    <rect fill="#000000" opacity="0.3" x="2" y="4" width="20" height="16" rx="2" />
                                    <polygon fill="#000000" opacity="0.3" points="4 20 10.5 11 17 20" />
                                    <polygon fill="#000000" points="11 20 15.5 14 20 20" />
                                    <circle fill="#000000" opacity="0.3" cx="18.5" cy="8.5" r="1.5" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </div>
                {{ 'ChangeProfilePicture' | localize }}
            </a>
        </div>
        <!-- My Settings -->
        <div class="menu-item px-5 d-flex">
            <a href="javascript:;" (click)="changeMySettings()" class="menu-link px-5" id="UserProfileMySettingsLink">
                <div class="symbol symbol-30px bg-light me-3">
                    <div class="symbol-label">
                        <span class="svg-icon svg-icon-primary svg-icon-2x svg-icon-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path
                                        d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z"
                                        fill="#000000"
                                    />
                                    <path
                                        d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z"
                                        fill="#000000"
                                        opacity="0.3"
                                    />
                                </g>
                            </svg>
                        </span>
                    </div>
                </div>
                {{ 'MySettings' | localize }}
            </a>
        </div>
        <div class="separator my-2"></div>
        <div class="menu-item px-5 d-flex">
            <a href="javascript:;" (click)="logout()" class="menu-link px-5">
                <div class="symbol symbol-30px bg-light me-3">
                    <div class="symbol-label">
                        <span class="svg-icon svg-icon-muted svg-icon-2hx">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.3" x="4" y="11" width="12" height="2" rx="1" fill="black" />
                                <path
                                    d="M5.86875 11.6927L7.62435 10.2297C8.09457 9.83785 8.12683 9.12683 7.69401 8.69401C7.3043 8.3043 6.67836 8.28591 6.26643 8.65206L3.34084 11.2526C2.89332 11.6504 2.89332 12.3496 3.34084 12.7474L6.26643 15.3479C6.67836 15.7141 7.3043 15.6957 7.69401 15.306C8.12683 14.8732 8.09458 14.1621 7.62435 13.7703L5.86875 12.3073C5.67684 12.1474 5.67684 11.8526 5.86875 11.6927Z"
                                    fill="black"
                                />
                                <path
                                    d="M8 5V6C8 6.55228 8.44772 7 9 7C9.55228 7 10 6.55228 10 6C10 5.44772 10.4477 5 11 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H11C10.4477 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18V19C8 20.1046 8.89543 21 10 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H10C8.89543 3 8 3.89543 8 5Z"
                                    fill="#C4C4C4"
                                />
                            </svg>
                        </span>
                    </div>
                </div>
                {{ 'Logout' | localize }}
            </a>
        </div>
    </div>
</div>
