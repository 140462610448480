import Swal, { SweetAlertOptions } from "sweetalert2";

let defaultOptions = {
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    padding: 0,
    toast: true,
};

function showNotification(type: string, message: string, title?: string, options?: (SweetAlertOptions & { imageClass?: string; })) {
    let icon = options.imageClass ? '<i class="me-2 text-white ' + options.imageClass + '"></i>' : '';

    if (title) {
        options.title = icon + '<span class="text-white">' + title + '</span>';
    }

    options.html = (title ? '' : icon) + '<span class="text-white">' + message + '</span>';

    let combinedOptions = Object.assign({}, defaultOptions, options);
    const { imageClass, ...combinedOptionsSafe } = combinedOptions;

    Swal.fire(combinedOptionsSafe);
};

export function success(message: string, title?: string, options?: (SweetAlertOptions & { imageClass?: string; })) {
    showNotification('success', message, title,
        Object.assign({
            background: '#34bfa3',
            imageClass: 'fa fa-check-circle'
        }, options));
};

export function info(message: string, title?: string, options?: (SweetAlertOptions & { imageClass?: string; })) {
    showNotification('info', message, title, Object.assign({
        background: '#36a3f7',
        imageClass: 'fa fa-info-circle'
    }, options));
};

export function warn(message: string, title?: string, options?: (SweetAlertOptions & { imageClass?: string; })) {
    showNotification('warning', message, title, Object.assign({
        background: '#ffb822',
        imageClass: 'fa fa-exclamation-triangle'
    }, options));
};

export function error(message: string, title?: string, options?: (SweetAlertOptions & { imageClass?: string; })) {
    showNotification('error', message, title, Object.assign({
        background: '#f4516c',
        imageClass: 'fa fa-exclamation-circle'
    }, options));
};
