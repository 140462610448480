import { createReducer, on } from '@ngrx/store';
import { GuidesState, guidesInitialState } from '../../state/guides/guides.state';
import * as GuidesActions from '../../actions/guides/actions';
import { GuideOutput } from '@shared/models/guides/guide-output';
import { LanguagesKeys } from '@app/languages/languages.enum';
import { GuideStatus } from '@shared/models/guides/guide-status';
import { FormFilterNames, FormFilterValue, FormFilterValueTypes } from '@app/store/shared/types/filters.models';
import { RequestEvent } from '@shared/models/request-event/request-event.model';
import { AdvancedSearchParams } from '@shared/models/search-filters/search-filters.model';
export const guideKey = 'guides';
const getAllSuccess = (state: GuidesState, payload: GuideOutput[]): GuidesState => ({
    ...state,
    items: payload,
    filteredItems: payload,
    filters: guidesInitialState.filters,
    selectedItem: guidesInitialState.selectedItem,
    event: {
        action: GuidesActions.GUIDES_ACTION_GET_ALL,
        result: 'success'
    }
});
const getById = (state: GuidesState, payload: GuideOutput): GuidesState => ({
    ...state,
    selectedItem: payload,
});
const addSuccess = (state: GuidesState, item: GuideOutput): GuidesState => ({
    ...state,
    items: [ ...state.items, item ],
    filteredItems: [ ...state.items, item ],
    event: {
        action: GuidesActions.GUIDES_ACTION_ADD,
        result: 'success'
    }

});
const updateSuccess = (state: GuidesState, item: GuideOutput): GuidesState => {
    const updatedItems = [...state.items];
    const index = updatedItems.findIndex((x) => x.id === item.id);
    if (index > -1) {
        updatedItems[index] = {
            ...updatedItems[index],
            ...item,
        };
    }

    return {
        ...state,
        items: [ ...updatedItems ],
        filteredItems: [ ...updatedItems ],
        event: {
            action: GuidesActions.GUIDES_ACTION_UPDATE,
            result: 'success'
        }
    };
};
const select = (state: GuidesState, id: number): GuidesState => ({
    ...state,
    selectedItem: id ? [...state.items].find((x) => x.id === id): guidesInitialState.selectedItem,
});

const resetSelect = (state: GuidesState): GuidesState => ({
    ...state,
    selectedItem: guidesInitialState.selectedItem
});

const removeSuccess = (state: GuidesState, id: number): GuidesState => {
    const filteredItems = [ ...state.items ].filter((x) => x.id !== id);
    return {
        ...state,
        items: [ ...filteredItems ],
        filteredItems: [ ...filteredItems ],
        event: {
            action: GuidesActions.GUIDES_ACTION_REMOVE,
            result: 'success'
        }
    };
};
const reset = (state: GuidesState): GuidesState => ({
    ...state,
    ...guidesInitialState,
});

const resetItems = (state: GuidesState): GuidesState => ({
    ...state,
    items: guidesInitialState.items,
    filteredItems: guidesInitialState.filteredItems
});

const resetFilteredItems = (state: GuidesState, filtersName: string): GuidesState => {
    const filters = [ ...state.filters ];
    switch (filtersName) {
        case FormFilterNames.Advanced: {
            const remainingFilters = filters.filter(filter => filter.name !== FormFilterNames.Advanced);
            const searchValue = remainingFilters.find(filter => filter.name !== FormFilterNames.Advanced)?.values.join().toLowerCase();
            if (searchValue) {
                return search(state, searchValue);
            }
            break;
        }
        case FormFilterNames.Global: {
            const remainingFilters = filters.filter(filter => filter.name !== FormFilterNames.Global);
            const remainingFiltersValues = remainingFilters.find(filter => filter.name !== FormFilterNames.Global)?.values as FormFilterValue[];
            const language = remainingFiltersValues.find(filter => filter.name !== FormFilterValueTypes.Language)?.value as LanguagesKeys;
            const status = remainingFiltersValues.find(filter => filter.name !== FormFilterValueTypes.Status)?.value as GuideStatus;
            if (language || status) {
                return filter(state, {language, status});
            }
            break;
        }
    }
    return {
        ...state,
        items: [ ...state.items ],
        filteredItems: [...state.items]
    };
};

const search = (state: GuidesState, value: string): GuidesState => {
    const lowerCasedValue = (value ?? "").toLowerCase();
    return {
        ...state,
        filters: [
            ...state.filters,
            {
                name: FormFilterNames.Global,
                values: [value]
            }
        ],
        filteredItems: [ ...state.items ].filter((item) => item.title.toLowerCase().includes(lowerCasedValue) || item.excerpt.toLowerCase().includes(lowerCasedValue) || item.content.toLowerCase().includes(lowerCasedValue))
    };
};

const resetFilters = (state: GuidesState): GuidesState => ({
    ...state,
    filters: guidesInitialState.filters,
    filteredItems: [ ...state.items ]
});

const filter = (state: GuidesState, { language, status }: AdvancedSearchParams<GuideStatus>): GuidesState => {
    const currentAdvancedFiltersIndex = state.filters.findIndex(f => f.name === FormFilterNames.Advanced);
    const currentFilters = [...state.filters];
    let filteredItems: GuideOutput[] = [ ...state.items ];
    const advancedFilters = {
        name: FormFilterNames.Advanced,
        values: []
    };
    if (language !== null && !isNaN(language) && Number(language) !== -1) {
        filteredItems = [ ...state.items ].filter((item) => item.languageId === Number(language));
        advancedFilters.values.push({
            name: FormFilterValueTypes.Language,
            type: 'number',
            value: Number(language)
        });
    }
    if (status !== null && !isNaN(status) && Number(status) !== -1) {
        filteredItems = filteredItems.filter((item) => item.status === Number(status));
        advancedFilters.values.push({
            name: FormFilterValueTypes.Status,
            type: 'number',
            value: Number(status)
        });
    }

    currentFilters[ currentAdvancedFiltersIndex ] = advancedFilters;
    return {
        ...state,
        filters: [...currentFilters],
        filteredItems: [...filteredItems]
    };
};

const updateRequestEvent = (state: GuidesState, value?: RequestEvent): GuidesState => ({
    ...state,
    event: value ?? guidesInitialState.event
});

const exportPdf = (state: GuidesState, id: number): GuidesState => ({
    ...state,
    exportedItem: [...state.items].find((item) => item.id === id)
});

const resetExportPdf = (state: GuidesState): GuidesState => ({
    ...state,
    exportedItem: guidesInitialState.exportedItem
});

export const guidesReducers = createReducer(
    guidesInitialState,
    on(GuidesActions.select, (state: GuidesState, { id }: { id?: number }) => select(state, id)),
    on(GuidesActions.resetSelect, (state: GuidesState) => resetSelect(state)),
    on(
        GuidesActions.getAllSuccess,
        (state: GuidesState, { payload }: { payload: GuideOutput[] }): GuidesState => getAllSuccess(state, payload)
    ),
    on(GuidesActions.getByIdSuccess, (state: GuidesState, { payload }: { payload: GuideOutput }) => getById(state, payload)),
    on(GuidesActions.addSuccess, (state: GuidesState, { payload }: { payload: GuideOutput }) => addSuccess(state, payload)),
    on(GuidesActions.updateSuccess, (state: GuidesState, { payload }: { payload: GuideOutput }) => updateSuccess(state, payload)),
    on(GuidesActions.removeSuccess, (state: GuidesState, { id }: { id: number }): GuidesState => removeSuccess(state, id)),
    on(GuidesActions.reset, (state: GuidesState): GuidesState => reset(state)),
    on(GuidesActions.resetItems, (state: GuidesState): GuidesState => resetItems(state)),
    on(GuidesActions.search, (state: GuidesState, { value }: {value: string}): GuidesState => search(state, value)),
    on(GuidesActions.filter, (state: GuidesState, { filters }: {filters: AdvancedSearchParams<GuideStatus>}): GuidesState => filter(state, filters)),
    on(GuidesActions.resetFilteredItems, (state: GuidesState, { filtersName }: {filtersName: string}): GuidesState => resetFilteredItems(state,filtersName)),
    on(GuidesActions.updateRequestEvent, (state: GuidesState, { value }: {value?: RequestEvent}): GuidesState => updateRequestEvent(state, value)),
    on(GuidesActions.resetFilters, (state: GuidesState): GuidesState => resetFilters(state)),
    on(GuidesActions.exportPdf, (state: GuidesState, {id}: {id: number}): GuidesState => exportPdf(state, id)),
    on(GuidesActions.resetExportPdf, (state: GuidesState): GuidesState => resetExportPdf(state)),
);
