import {
    Component,
    Injector,
    ViewChild,
    ViewEncapsulation,
    AfterViewInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import {
    EntityDto_1OfInt64,
    GetUsersInput,
    UserListDto,
    UserServiceProxy,
} from '@shared/service-proxies/service-proxies';
import { FileDownloadService } from '@shared/utils/file-download.service';
import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { CreateOrEditUserModalComponent } from './create-or-edit-user-modal.component';
import { HttpClient } from '@angular/common/http';
import { FileUpload } from 'primeng/fileupload';
import { finalize } from 'rxjs/operators';
import { PermissionTreeModalComponent } from '../shared/permission-tree-modal.component';
import { Extensions } from '@shared/utils/extensions';
import { UserTypeEnum } from './userTypeEnum';
import { DatePipe } from '@angular/common';
import { environment as env } from 'environments/environment';

@Component({
    templateUrl: './users.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./users.component.scss'],
    animations: [appModuleAnimation()],
    providers: [DatePipe],
})
export class UsersComponent extends AppComponentBase implements AfterViewInit {
    @ViewChild('createOrEditUserModal', { static: true }) createOrEditUserModal: CreateOrEditUserModalComponent;
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;
    @ViewChild('ExcelFileUpload', { static: false }) excelFileUpload: FileUpload;
    @ViewChild('permissionFilterTreeModal', { static: true }) permissionFilterTreeModal: PermissionTreeModalComponent;

    uploadUrl: string;

    //Filters
    advancedFiltersAreShown = true;
    filterText = sessionStorage.getItem('users-search-text') ?? '';
    role = '';
    onlyLockedUsers = false;

    constructor(
        injector: Injector,
        private _router: Router,
        private _userServiceProxy: UserServiceProxy,
        private _fileDownloadService: FileDownloadService,
        private _activatedRoute: ActivatedRoute,
        private _httpClient: HttpClient,
    ) {
        super(injector);
        this.filterText =
            this._activatedRoute.snapshot.queryParams['filterText'] ||
            sessionStorage.getItem('users-search-text') ||
            '';
        this.uploadUrl =
            env.api + '/api/Users/ImportFromExcel';
    }

    ngAfterViewInit(): void {
        this.primengTableHelper.adjustScroll(this.dataTable);
    }

    getUsers(event?: LazyLoadEvent) {
        if (this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);

            if (
                this.primengTableHelper.records &&
                this.primengTableHelper.records.length > 0
            ) {
                return;
            }
        }

        this.primengTableHelper.showLoadingIndicator();

        const filterWithoutAccents = Extensions.replaceSpecialCharacters(
            this.filterText
        );

        this._userServiceProxy
            .getUsers(
                new GetUsersInput({
                    filter: filterWithoutAccents,
                    permissions: [],
                    role: this.role !== '' ? parseInt(this.role) : undefined,
                    onlyLockedUsers: this.onlyLockedUsers,
                    sorting: this.primengTableHelper.getSorting(this.dataTable),
                    maxResultCount: this.primengTableHelper.getMaxResultCount(
                        this.paginator,
                        event
                    ),
                    skipCount: this.primengTableHelper.getSkipCount(
                        this.paginator,
                        event
                    ),
                    type: UserTypeEnum.User,
                })
            )
            .pipe(
                finalize(() => this.primengTableHelper.hideLoadingIndicator())
            )
            .subscribe((result) => {
                this.primengTableHelper.totalRecordsCount = result.totalCount;
                this.primengTableHelper.records = result.items;
                this.primengTableHelper.hideLoadingIndicator();
            });

        sessionStorage.setItem('users-search-text', filterWithoutAccents);
    }

    unlockUser(record): void {
        this._userServiceProxy
            .unlockUser(new EntityDto_1OfInt64({ id: record.id }))
            .subscribe(() => {
                this.notify.success(this.l('UnlockedTheUser', record.userName));
            });
    }

    getRolesAsString = (roles): string => roles.map(role => role.roleName).join(', ');

    reloadPage(): void {
        this.paginator.changePage(this.paginator.getPage());
    }

    refresh() {
        this.filterText = '';
        this.role = '';
        this.getUsers();
    }

    exportToExcel(): void {
        this._userServiceProxy
            .getUsersToExcel(
                this.filterText,
                [],
                this.role !== '' ? parseInt(this.role) : undefined,
                UserTypeEnum.User,
                this.onlyLockedUsers,
                this.primengTableHelper.getSorting(this.dataTable)
            )
            .subscribe((result) => {
                this._fileDownloadService.downloadTempFile(result);
            });
    }

    createOrEditUser(recordId?: number): void {
        this.createOrEditUserModal.show(recordId);
    }

    uploadExcel(data: { files: File }): void {
        const formData: FormData = new FormData();
        const file = data.files[0];
        formData.append('file', file, file.name);

        this._httpClient
            .post<any>(this.uploadUrl, formData)
            .pipe(finalize(() => this.excelFileUpload.clear()))
            .subscribe((response) => {
                if (response.success) {
                    this.notify.success(this.l('ImportUsersProcessStart'));
                } else if (response.error != null) {
                    this.notify.error(this.l('ImportUsersUploadFailed'));
                }
            });
    }

    onUploadExcelError(): void {
        this.notify.error(this.l('ImportUsersUploadFailed'));
    }

    deleteUser(user: UserListDto): void {
        this.message.confirm(
            this.l('UserDeleteWarningMessage', user.userName),
            this.l('AreYouSure'),
            (isConfirmed) => {
                if (isConfirmed) {
                    this._userServiceProxy.deleteUser(user.id).subscribe(() => {
                        this.reloadPage();
                        this.notify.success(this.l('SuccessfullyDeleted'));
                    });
                }
            }, {
                confirmButtonText: this.l('Yes'),
                cancelButtonText: this.l('No')        
            }
        );
    }

    linkUserOrganization(record): void {
        this._router.navigate(['/users-organizations', record.id, record.userName,]);
    }
}
