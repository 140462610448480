import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment as env } from 'environments/environment';
import { Observable } from 'rxjs';
import { OrganizationVerificationInput } from '@shared/models/organizationVerificationCost/orgVerificationInput';
import { OrganizationVerificationOutput } from '@shared/models/organizationVerificationCost/orgVerificationOutput';

@Injectable()
export class OrganizationVerificationCostService {
    private http: HttpClient;
    private baseUrl: string;

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = env.api;
    }


    private getUrl(url: string): string {
        let url_ = this.baseUrl + url;
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    }

    getVerificationOrganizationCostByPublicId(organizationPublicId: string, verificationPublicId: string): Observable<any> {
        let url_ = this.getUrl(`/api/organizations/${organizationPublicId}/${verificationPublicId}`);
        let list = this.http.get<any>(url_);
        return list;
    }


    getOrganizationVerificationCostList(organizationId: string): Observable<any> {
        let url_ = this.getUrl(`/api/organizations/${organizationId}/verifications`);
        let list = this.http.get<any>(url_);
        return list;
    }

    addOrgVerification(organizationVerificationCost: OrganizationVerificationInput): Observable<OrganizationVerificationOutput> {
        let url_ = this.getUrl('/api/organizations/OrganizationVerifications');
        return this.http.post<OrganizationVerificationOutput>(url_, organizationVerificationCost);
    }


    updateCostOrganizationVerificationByPublicId(organizationPublicId: string,
                                             verificationPublicId: string,
                                             cost: Number): Observable<any> {
        let url_ = this.getUrl(`/api/organizations/UpdateCost/${organizationPublicId}/${verificationPublicId}/${cost}`);
        return this.http.put<any>(url_, 0);
    }

    updateOrganizationVerification(organizationVerificationCost: OrganizationVerificationInput) {
        let url_ = this.getUrl('/api/organizations/UpdateOrganizationVerification');
        return this.http.post<any>(url_, organizationVerificationCost);
    }

    deleteOrganizationVerification(organizationVerificationCost: OrganizationVerificationInput): Observable <any> {
        let url_ = this.getUrl('/api/organizations/deleteOrganizationVerification');
        return this.http.post<any>(url_, organizationVerificationCost);
    }
}
