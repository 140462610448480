import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CustomSvgIconService {
    constructor(private httpClient: HttpClient, private sanitizer: DomSanitizer) { }
    public getIcon = (iconName: string) => {
        const icon = sessionStorage.getItem(`gardium.icons.svg.${iconName}`);
        if (icon) {
            return of(this.sanitizer.bypassSecurityTrustHtml(icon));
        }
        return this.httpClient
            .get(`assets/icons/${iconName}.svg`, { responseType: 'text' })
            .pipe(
                tap(data => sessionStorage.setItem(`gardium.icons.svg.${iconName}`, data)),
                map(data => this.sanitizer.bypassSecurityTrustHtml(data)));
    };

}
