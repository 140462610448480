import { Component, OnInit, Injector } from '@angular/core';
import { VerificationOutput } from '@shared/models/verification/verificationOutput';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { VerificationService } from '@shared/services/verification.service';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { ToastrService } from 'ngx-toastr';
import { OrganizationVerificationInput } from '@shared/models/organizationVerificationCost/orgVerificationInput';
import { OrganizationVerificationCostService } from '@shared/services/organizationVerificationCost.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { OrganizationService } from '@shared/services/organization.service';
import { organizationOutput } from '@shared/models/organization/organizationOutput';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'organization-verification-add',
    templateUrl: './organization-verification-add.component.html',
    animations: [appModuleAnimation()]
})
export class OrganizationVerificationAddComponent extends AppComponentBase implements OnInit {
    items: MenuItem[];
    organizationOutput: organizationOutput;
    publicId: string;
    isOrganizationVerifCreated = false;
    addVerificationForm = new UntypedFormGroup({
        cboCode: new UntypedFormControl('', Validators.required),
        type: new UntypedFormControl({ value: '', disabled: true }),
        basePrice: new UntypedFormControl({ value: '', disabled: true }),
        adjustment: new UntypedFormControl('', Validators.required),
        total: new UntypedFormControl({ value: '', disabled: true }),
        fixBasePrice: new UntypedFormControl('')
    });

    verificationList: VerificationOutput[] = [];
    result: VerificationOutput;
    selectedVerification: VerificationOutput;
    noResultVerification = false;
    fixBasePriceCheck = false;

    constructor(
        injector: Injector,
        private service: VerificationService,
        private organizationService: OrganizationService,
        private _appLocalizationService: AppLocalizationService,
        private toastr: ToastrService,
        private organizationVerificationCostService: OrganizationVerificationCostService,
        private _route: ActivatedRoute,
        private _router: Router
    ) {
        super(injector);
    }

    async ngOnInit() {
        if (this._route.snapshot.paramMap.get('publicId') !== '') {
            this.publicId = this._route.snapshot.paramMap.get('publicId');
            if (this._route.snapshot.paramMap.get('fromOrganization') !== '') {
                const fromOrganization = this._route.snapshot.paramMap.get('fromOrganization') === 'true' || !!this._route.snapshot.paramMap.get('fromOrganization');
                if (fromOrganization) {
                    this.items = [
                        {
                            label: this._appLocalizationService.l('Organizations'),
                            routerLink: ['/organizations']
                        },
                        {
                            label: this._appLocalizationService.l('OrganizationDetails'),
                            routerLink: [`/organizations-details/${this.publicId}`]
                        },
                        {
                            label: this._appLocalizationService.l('SelectVerification')
                        }
                    ];
                }
            }
        }

        this.organizationService.getOrganizationsByPublicId(this.publicId).subscribe((response) => {
            this.organizationOutput = response.result as organizationOutput;
        });

        await this.initializeVerificationList();
        this.addVerificationForm.controls['type'].setValue('');
        this.addVerificationForm.controls['basePrice'].setValue('');
        this.addVerificationForm.controls['total'].setValue('');
    }

    async initializeVerificationList() {
        const verificationActif = [];
        await this.service
            .getVerificationsNotLinkedToOrganization(this.publicId)
            .toPromise()
            .then((value) => {
                this.verificationList = value.result as VerificationOutput[];
                this.verificationList.forEach((element) => {
                    if (element.status === 0) {
                        verificationActif.push(element);
                    }
                });
                this.verificationList = verificationActif;
                const sortByCode = function (a, b) {
                    const textA = a.code.toUpperCase();
                    const textB = b.code.toUpperCase();
                    if (textA < textB) {
                        return -1;
                    } else if (textA > textB) {
                        return 1;
                    }
                    return 0;
                };

                this.verificationList.sort(sortByCode);
            });
    }

    typeaheadNoResults(event: boolean): void {
        this.noResultVerification = event;
    }

    onSelect(event: TypeaheadMatch): void {
        this.selectedVerification = event.item as VerificationOutput;
        this.addVerificationForm.controls['type'].setValue(this.selectedVerification.type);
        this.addVerificationForm.controls['basePrice'].setValue(this.selectedVerification.price);
        this.addVerificationForm.controls['adjustment'].setValue(0);
        this.calculateTotal();
    }

    calculateTotal() {
        const basePrice = this.addVerificationForm.controls['basePrice'].value;
        const adjustment = this.addVerificationForm.controls['adjustment'].value;

        const total = basePrice + adjustment;

        this.addVerificationForm.controls['total'].setValue(Number(total).toFixed(2) + ' $');
    }

    typeaheadNoResultVerification(event: boolean): void {
        this.noResultVerification = event;
    }

    async onAddVerification() {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }

        const organizationVerificationCost: OrganizationVerificationInput = {
            id: 0,
            organizationId: this.organizationOutput.id,
            verificationId: this.selectedVerification.id,
            adjustment: this.addVerificationForm.controls['adjustment'].value,
            basePrice: this.addVerificationForm.controls['basePrice'].value,
            fixBasePrice: this.fixBasePriceCheck
        };

        this.isOrganizationVerifCreated = true;
        await this.organizationVerificationCostService
            .addOrgVerification(organizationVerificationCost)
            .toPromise()
            .then((value) => {
                if (value.organizationId !== null && value.verificationId !== null && value.result.outputStatus !== 1) {
                    this.showSuccess();
                    this.returnToList();
                } else if (value.result.outputStatus === 1) {
                    this.isOrganizationVerifCreated = false;
                    this.showErrorMessage();
                }
                return value;
            });
    }

    isFormInvalid(): boolean {
        return this.addVerificationForm.invalid;
    }

    showForNotValidMessage() {
        const title = this._appLocalizationService.l('VerifyAllControlsTitle');
        const message = this._appLocalizationService.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }

    markAllControlsAsTouch() {
        this.addVerificationForm.controls['adjustment'].markAsTouched();
        this.addVerificationForm.controls['cboCode'].markAsTouched();
    }

    showSuccess() {
        const title = this._appLocalizationService.l('AddTitleVerification');
        const successMessage = this._appLocalizationService.l('OrganizationVerificationAdd');
        this.toastr.success(successMessage, title);
    }

    showErrorMessage() {
        const title = this._appLocalizationService.l('OrganizationVerificationAddIssue');
        const errorMessage = this._appLocalizationService.l('VerificationAddTryAgainLater');
        this.toastr.error(errorMessage, title);
    }

    returnToList() {
        this._router.navigate(['/organizations-details', this.organizationOutput.publicId]);
    }
}
