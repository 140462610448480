import { InvoiceHeader } from './invoiceHeader';
import { InvoiceTotalSection } from './invoiceTotalSection';
import { InvoiceOutput } from '../invoiceOutput';
import { InvoiceLinesOutput } from '../../invoiceLine/invoiceLineOutput';
import { InvoiceAdjustmentsOutput } from '../../InvoiceAdjustment/InvoiceAdjustmentOutput';
import { AddressHistoryOutput } from '@shared/models/addresshistory/addressHistoryOutput';
import { AddressHistoryType } from '@shared/models/addresshistory/addressHistoryType';
import { CandidatOutput } from '@shared/models/candidat/candidatOutput';

export class InvoiceDisplay {
    invoiceHeader: InvoiceHeader;
    invoiceTotalSection: InvoiceTotalSection;
    invoiceLines: InvoiceLinesOutput[];
    resultInvoiceAdjustments: InvoiceAdjustmentsOutput[];

    initializeDisplay(result: InvoiceOutput, candidate: CandidatOutput|undefined) {

        let header = new InvoiceHeader();

        header.organizationName = result.organizationName;
        header.civicNo = result.address.civicNo.toString();
        header.street = result.address.street;
        header.app = result.address.app.toString();
        header.city = result.address.city;
        header.state = result.address.state.stateName;
        header.postalCode = this.addCharInString(result.address.postalCode, 3, ' ');
        header.contactName = result.contactName;
        header.invoiceEmail = result.invoiceEmail;
        header.clientName = result.organizationName;

        if (candidate) {
            let candidateAddress: AddressHistoryOutput;
            if (candidate.addressHistories) {
                candidate.addressHistories.forEach(element => {
                    if (element.type === AddressHistoryType.current && element.requestId === null) {
                        candidateAddress = element;
                    }
                });
            }

            const displayName = candidate.firstName + " " + candidate.lastName;
            header.organizationName = displayName;
            header.contactName = displayName;
            header.invoiceEmail = candidate.email;
            header.clientName = displayName;

            if (candidateAddress) {
                header.civicNo = candidateAddress.address.civicNo.toString();
                header.street = candidateAddress.address.street;
                header.app = candidateAddress.address.app.toString();
                header.city = candidateAddress.address.city;
                header.state = candidateAddress.address.state.stateName;
                header.postalCode = this.addCharInString(candidateAddress.address.postalCode, 3, ' ');
            } else {
                header.civicNo = '';
                header.street = '';
                header.app = '';
                header.city = '';
                header.state = '';
                header.postalCode = '';
            }
        }
        
        header.invoiceNumber = result.invoiceNumber;
        header.invoiceDate = result.invoiceDate;
        header.purchaseOrder = result.purchaseOrder;
        header.clientId = result.clientId;
        this.invoiceHeader = header;

        let totalSection = new InvoiceTotalSection();
        totalSection.subTotal = Number(result.subTotal).toFixed(2);
        totalSection.total = Number(result.total).toFixed(2);

        result.invoiceTaxes.forEach(element => {
            if (element.taxes.code === 'TPS') {
                totalSection.tps = element.calculatedAmount.toFixed(2);
            } else if (element.taxes.code === 'TVQ') {
                totalSection.tvq = element.calculatedAmount.toFixed(2);
            }
        });

        this.invoiceTotalSection = totalSection;

        this.invoiceLines = result.invoiceLines;
        this.resultInvoiceAdjustments = result.invoiceAdjustments;
    }

    addCharInString(stringThatAdd: string, index: number, character: string) {
        return stringThatAdd.slice(0, index) + character + stringThatAdd.slice(index, stringThatAdd.length);
    }


}
