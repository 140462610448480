<div class="alert alert-warning align-left flex margin-top-20 margin-bottom-minus-10">
    {{'RequestWizzardInformationOptional' | localize }}</div>
<div class="accordion margin-top-20 mb-10" id="accordionCandidate">
    <div class="accordion-item">
        <h2 class="accordion-header" id="headingSendingMethod">
            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseSendingMethod" aria-expanded="false" aria-controls="collapseOne">
                {{'SendingMethod' | localize}}
            </button>
        </h2>
        <div id="collapseSendingMethod" class="accordion-collapse collapse show" aria-labelledby="headingSendingMethod"
            data-bs-parent="#accordionCandidate">
            <div class="accordion-body align-left">
                <sending-method #sendingMethod (sendingMethodSelected)="onSendingMethodChange($event)"></sending-method>
            </div>
        </div>
    </div>
    <div class="accordion-item" *ngIf="!isEzSignMethodSelected()">
        <h2 class="accordion-header" id="headingAddressCurrent">
            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseAddressCurrent" aria-expanded="false" aria-controls="collapseOne">
                {{'AddressCurrent' | localize}}
                <span class="asterix" *ngIf="showCurrentAddress() && isAddressInvalid()">*</span>
                <span class="text-alert margin-left-20"
                    *ngIf="showCurrentAddress() && isAddressInvalid()">{{'RequestWizzardInformationRequired' |
                    localize}}</span>
            </button>
        </h2>
        <div id="collapseAddressCurrent" class="accordion-collapse collapse show"
            aria-labelledby="headingAddressCurrent" data-bs-parent="#accordionCandidate">
            <div class="accordion-body align-left">
                <request-address-history #candidatsAddress [selectedCandidat]="request.candidat"
                    [requestVerifications]="request.requestVerifications">
                </request-address-history>
            </div>
        </div>
    </div>
    <div class="accordion-item" *ngIf="showProfessionnalAccreditations() && !isEzSignMethodSelected()">
        <h2 class="accordion-header" id="headingProfessionnalAccreditations">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseProfessionnalAccreditations" aria-expanded="false"
                aria-controls="collapseProfessionnalAccreditations">
                {{'ProfessionnalAccreditations' | localize}}
            </button>
        </h2>
        <div id="collapseProfessionnalAccreditations" class="accordion-collapse collapse"
            aria-labelledby="headingProfessionnalAccreditations" data-bs-parent="#accordionCandidate">
            <div class="accordion-body align-left">
                <professionnal-accreditations-list #accreditationList [requestPublicId]="request.publicId"
                    [candidateId]="request.candidat.id" [candidatePublicId]="request.candidat.publicId"
                    [invoiceStatus]="0" [isAllowedToUpdateRequest]="true">
                </professionnal-accreditations-list>
            </div>
        </div>
    </div>
    <div class="accordion-item" *ngIf="showAddressHistory() && !isEzSignMethodSelected()">
        <h2 class="accordion-header" id="headingAddressHistory">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseAddressHistory" aria-expanded="false" aria-controls="collapseAddressHistory">
                {{'AddressHistory' | localize}}
            </button>
        </h2>
        <div id="collapseAddressHistory" class="accordion-collapse collapse" aria-labelledby="headingAddressHistory"
            data-bs-parent="#accordionCandidate">
            <div class="accordion-body align-left">
                <request-address-history-list #addressFormerList [selectedCandidat]="request.candidat"
                    [requestOpen]="true" [isAllowedToUpdateRequest]="true">
                </request-address-history-list>
            </div>
        </div>
    </div>
    <div class="accordion-item" *ngIf="showAcademicRecord() && !isEzSignMethodSelected()">
        <h2 class="accordion-header" id="headingAcademicRecord">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseAcademicRecord" aria-expanded="false" aria-controls="collapseAcademicRecord">
                {{'AcademicRecord' | localize}}
            </button>
        </h2>
        <div id="collapseAcademicRecord" class="accordion-collapse collapse" aria-labelledby="headingAcademicRecord"
            data-bs-parent="#accordionCandidate">
            <div class="accordion-body align-left">
                <academic-record-list #academicRecordList [candidateId]="request.candidat.id"
                    [candidatePublicId]="request.candidat.publicId" [isAllowedToUpdateRequest]="true">
                </academic-record-list>
            </div>
        </div>
    </div>
    <div class="accordion-item" *ngIf="showPersonnalReferences() && !isEzSignMethodSelected()">
        <h2 class="accordion-header" id="headingPersonnalRefences">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapsePersonnalRefences" aria-expanded="false"
                aria-controls="collapsePersonnalRefences">
                {{'PersonnalRefences' | localize}}
            </button>
        </h2>
        <div id="collapsePersonnalRefences" class="accordion-collapse collapse"
            aria-labelledby="headingPersonnalRefences" data-bs-parent="#accordionCandidate">
            <div class="accordion-body align-left">
                <personnal-references-list #personnalReferenceList [candidateId]="request.candidat.id"
                    [requestId]="request.id" [candidatePublicId]="request.candidat.publicId" [requestOpen]="true"
                    [isAllowedToUpdateRequest]="true">
                </personnal-references-list>
            </div>
        </div>
    </div>
    <div class="accordion-item" *ngIf="showProfessionnalReferences() && !isEzSignMethodSelected()">
        <h2 class="accordion-header" id="headingProfessionnalRefences">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseProfessionnalRefences" aria-expanded="false"
                aria-controls="collapseProfessionnalRefences">
                {{'ProfessionnalRefences' | localize}}
            </button>
        </h2>
        <div id="collapseProfessionnalRefences" class="accordion-collapse collapse"
            aria-labelledby="headingProfessionnalRefences" data-bs-parent="#accordionCandidate">
            <div class="accordion-body align-left">
                <professionnal-reference-list #professionnalReferenceList [candidateId]="request.candidat.id"
                    [requestId]="request.id" [candidatePublicId]="request.candidat.publicId"
                    [isAllowedToUpdateRequest]="true">
                </professionnal-reference-list>
            </div>
        </div>
    </div>
</div>

<h1 id="files" *ngIf="!isEzSignMethodSelected()">{{'Files' | localize}}</h1>
<div class="accordion mt-4 mb-10" id="accordionFiles" *ngIf="!isEzSignMethodSelected()">
    <div class="accordion-item">
        <h2 class="accordion-header" id="headingMandatoryFiles">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseMandatoryFiles" aria-expanded="false" aria-controls="collapseMandatoryFiles">
                {{'ConsentFile' | localize}}
            </button>
        </h2>
        <div id="collapseMandatoryFiles" class="accordion-collapse collapse" aria-labelledby="headingMandatoryFiles"
            data-bs-parent="#accordionFiles">
            <div class="accordion-body align-left">
                <list-request-files #mandatoryFiles [requestPublicId]="request.publicId" [title]="" [fileMode]="appConsts.files.mandatoryMode"
                    [requestId]="request.id">
                </list-request-files>
            </div>
        </div>
    </div>

    <div class="accordion-item">
        <h2 class="accordion-header" id="headingFilledFiles">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseFilledFiles" aria-expanded="false" aria-controls="collapseFilledFiles">
                {{'FilesFilled' | localize}}
                <span class="asterix" *ngIf="showFilesFilled() && isNoneFilesFilled()">*</span>
                <span class="text-alert margin-left-20"
                    *ngIf="showFilesFilled() && isNoneFilesFilled()">{{'FilesFilledWarning' | localize}}</span>
            </button>
        </h2>
        <div id="collapseFilledFiles" class="accordion-collapse collapse show" aria-labelledby="headingFilledFiles"
            data-bs-parent="#accordionFiles">
            <div class="accordion-body align-left">
                <list-request-files #filledFiles [requestPublicId]="request.publicId" [title]="" [fileMode]="appConsts.files.filled"
                    [requestId]="request.id">
                </list-request-files>
            </div>
        </div>
    </div>
</div>
<h1 id="notesHeader">{{'Notes' | localize}}</h1>
<div class="accordion mt-4" id="accordionNotes">
    <div class="accordion-item">
        <h2 class="accordion-header" id="headingNotes">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseNotes" aria-expanded="false" aria-controls="collapseNotes">
                {{'Notes' | localize}}
            </button>
        </h2>
        <div id="collapseNotes" class="accordion-collapse collapse show" aria-labelledby="headingNotes"
            data-bs-parent="#accordionNotes">
            <div class="accordion-body align-left">
                <notes-list #notes *ngIf="request" [requestId]="request.id" [requestPublicId]="request.publicId">
                </notes-list>
            </div>
        </div>
    </div>
</div>

<form [formGroup]="form" autocomplete="off">
    <div class="m-5">
        <div class="row margin-top-20">
            <div class="form-group col-md-6 align-left">
                <label for="purchaseOrder">{{"PurchaseOrder" | localize}}</label>
                <input id="purchaseOrder" formControlName="purchaseOrder" type="text" name="purchaseOrder"
                    class="form-control" maxlength="256" (change)="setRequestPurchaseOrder()">
                <validation-messages [formCtrl]="form.get('purchaseOrder')"></validation-messages>
            </div>
            <div class="form-group col-md-6 align-left">
                <label for="reportLanguage">{{"ReportLanguage" | localize}}<span class="asterix">*</span></label>
                <select name="reportLanguage" class="form-control" id="reportLanguage" formControlName="reportLanguage"
                    (change)="setRequestReportLanguage()" required>
                    <option value="english">{{"English" | localize}}</option>
                    <option value="french">{{"French" | localize}}</option>
                </select>
                <validation-messages [formCtrl]="form.get('reportLanguage')"></validation-messages>
            </div>
        </div>
        <h4>{{"TermsAndConditions" | localize}}</h4>
        <div class="form-group align-center margin-top-20 alert alert-warning" *ngIf="termsNotChecked">
            {{"TermesAndConditionShouldBeCheckedToContinue" | localize}}
        </div>
        <div class="form-inline align-center margin-top-20">
            <label for="termsAndAccept">{{"TermsAndLink_First" | localize}}</label>&nbsp;
            <a (click)="onNavigate(appConsts.linkFrench)" class="link"
                *ngIf="this.request.reportLanguage ==='french'">
                {{"Link" | localize}} &nbsp;
            </a>
            <a (click)="onNavigate(appConsts.linkEnglish)" class="link"
                *ngIf="this.request.reportLanguage ==='english'">
                {{"Link" | localize}} &nbsp;
            </a>
            <label class="margin-left-2" for="termsAndAccept">{{"TermsAndLink_Second" | localize}}</label>
        </div>
        <div class="form-inline align-center">
            <input class="form-check-input" type="checkbox" name="termsAndAccept" (change)='checkValue($event)'
                id="termsAndAccept" data-test-id="termsAndCondition-check" required>
            {{'TermsAndAccept'| localize}} <span class="asterix">*</span>
            <validation-messages [formCtrl]="form.get('termsAndAccept')"></validation-messages>
        </div>
    </div>
</form>

<div class="form margin-top-20 mx-10">
    <div class="row col-md-12">
        <div class="col-md-3"></div>
        <div class="col-md-6 flex flex-column">
            <div class="align-center margin-top-10 margin-bottom-10">
                <button class="btn btn-primary" id="askInfoBtn"
                        [disabled]="sendCandidateButtonIsAvailable"
                        (click)="sendCandidateFormByEmail()" 
                        [buttonBusy]="IsSendCandidateProcessing"
                        [title]="(!sendCandidateButtonIsAvailable ? 'TooltipSendRequestToGardium' : 'EzSignSendingDetails') | localize"
                        *ngIf="isEzSignMethodSelected(); else sendGardium">
                    {{ "EzSignSending" | localize }}
                </button>
            </div>
            <ng-template #sendGardium>
                    <button class="btn btn-primary" 
                            [disabled]="!formIsValid"
                            (click)="updateRequestToNew()" 
                            [title]="(!formIsValid ? 'TooltipSendRequestToGardium' : 'TraditionnalSendingDetails') | localize">
                        {{ "TraditionnalSending" | localize }}
                    </button>
            </ng-template>
        </div>
        <div class="col-md-3"></div>
    </div>
</div>

<div class="d-flex mt-10">
    <div class="">
        <button class="float-lg-right btn-back p-0 mr-2" awPreviousStep>
            <i class="la la-arrow-circle-o-left btn-back float-lg-right"></i>
        </button>
    </div>
    <div class="text-left justify-content-center align-self-center pl-0">
        <label class="mb-0">{{"RequestWizzardPreviousStep" | localize}}</label>
    </div>

    <div class="flex-grow-1"></div>
</div>
