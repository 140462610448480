<div [@routerTransition]>
  <div class="content d-flex flex-column flex-column-fluid">
    <sub-header [title]="'GenerateInvoices' | localize" [description]="'WizzardIntroSubtitle' | localize">
      <div role="actions"> </div>
    </sub-header>
    <div [class]="containerClass">
      <div class="card card-custom listContainer">
        <div class="card-body">
          <div class="m-form m-form--label-align-right">
            <div class="row align-items-center m--margin-bottom-10">
              <div class="col-xl-12">
                <aw-wizard #wizzard [disableNavigationBar]="true">
                  <aw-wizard-step stepTitle="{{l('GenerateInvoices')}}">
                    <br />
                    <div class="col-md-12 text-center">
                      <div>{{"WizzardIntroOne" | localize}}</div>
                      <div>{{"WizzardIntroTwo" | localize}}</div>
                      <div>{{"WizzardIntroThree" | localize}}</div>
                      <div>{{"WizzardIntroFour" | localize}}</div>
                      <div>{{"WizzardIntroFive" | localize}}</div>
                      <div>
                        <br />
                        <button class="btn btn-primary" awNextStep
                          data-test-id="inoivce-wizzard-begin-btn">
                          {{"WizzardBegin" | localize}}
                        </button>
                      </div>
                    </div>
                  </aw-wizard-step>
                  <aw-wizard-step stepTitle="{{l('Organizations')}}">
                    <br />
                    <div class="col-md-12 text-center">
                      {{"WizzardOrganizations" | localize}}
                      <br />
                      <wizzard-organizations #organizations (organizationsSelected)="onOrganizationsSelected($event)">
                      </wizzard-organizations>
                      <div class="pt-5">
                        <button class="btn btn-primary" awPreviousStep>
                          {{"WizzardPrevious" | localize}}
                        </button>
                        <button class="btn btn-primary" (click)="getRequestsByOrganization()"
                          [disabled]="numberOfOrganizationSelected === 0" awNextStep
                          data-test-id="inoivce-wizzard-orgNext-btn">
                          {{"WizzardNext" | localize}}
                        </button>
                      </div>
                    </div>
                  </aw-wizard-step>
                  <aw-wizard-step stepTitle="{{l('Requests')}}">
                    <br />
                    <div class="col-md-12 text-center">
                      {{"WizzardRequests" | localize}}
                      <br />
                      <wizzard-requests #requests (requestsSelected)="onRequestsSelected($event)"></wizzard-requests>
                      <div class="pt-5">
                        <button class="btn btn-primary" awPreviousStep>
                          {{"WizzardPrevious" | localize}}
                        </button>
                        <button class="btn btn-primary" (click)="getSelectedRequests()"
                          [disabled]="numberOfRequestSelected === 0" awNextStep
                          data-test-id="inoivce-wizzard-reqNext-btn">
                          {{"WizzardNext" | localize}}
                        </button>
                      </div>
                    </div>
                  </aw-wizard-step>
                  <aw-wizard-step stepTitle="{{l('Date')}}">
                    <br />
                    <div class="col-md-12 text-center">
                      <div class="flex-column align-items-center justify-content-center">
                        {{"WizzardDate" | localize}}
                      </div>
                      <br />
                      <wizzard-invoice-date #invoiceDate (invoiceDateSelected)="onInvoiceDateSelected($event)">
                      </wizzard-invoice-date>
                      <div class="pt-5">
                        <button class="btn btn-primary" awPreviousStep>
                          {{"WizzardPrevious" | localize}}
                        </button>
                        <button class="btn btn-primary " (click)="generateInvoicesFromRequestList()"
                          [disabled]="numberOfRequestSelected === 0" awNextStep data-test-id="inoivce-wizzard-dateNext-btn">
                          {{"WizzardNext" | localize}}
                        </button>
                      </div>
                    </div>
                  </aw-wizard-step>
                  <aw-wizard-step stepTitle="{{l('Progress')}}">
                    <br />
                    <div class="col-md-12 text-center">
                      {{"WizzardProgress" | localize}}
                      <div class="spinner-border"></div>
                    </div>
                  </aw-wizard-step>
                  <aw-wizard-step stepTitle="{{l('WizzardEndTitle')}}">
                    <br />
                    <div class="col-md-12 text-center">
                      <div>{{"WizzardEndOne" | localize}}</div>
                      <div>{{"WizzardEndTwo" | localize}}</div>
                      <br />
                      <button class="btn btn-primary" (click)="returnToInvoiceList()">
                        {{"WizzardReturnToList" | localize}}
                      </button>
                      <button class="btn btn-primary" (click)="returnToInvoiceStatus()">
                        {{"WizzardReturnToInvoiceStatus" | localize}}
                      </button>
                    </div>
                  </aw-wizard-step>
                </aw-wizard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>