import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { RequestOutput } from '@shared/models/request/requestOutput';
import { CandidatsService } from '@shared/services/candidats.service';
import { emailAddressValidator } from '@shared/utils/validation/email-validator.directive';
import { RequiredFieldsForCodes } from '@shared/utils/verificationsEnumAndFunctions';
import { BehaviorSubject } from 'rxjs';
import { WizzardRequestCreatorComponent } from '../wizzard-request/wizzard-request.component';

@Component({
    selector: 'request-wizard-candidate-basic-infos',
    templateUrl: './request-wizard-candidate-basic-infos.component.html',
    styleUrls: ['./request-wizard-candidate-basic-infos.component.scss']
})
export class RequestWizardCandidateBasicInfosComponent extends AppComponentBase implements OnInit {
    @Input() public request: RequestOutput;
    @Input() public form: UntypedFormGroup;
    @Output() public formChange: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
    @Input() updateCandidateInfos = false;
    @Input() public resetDriverLicenseRequired: BehaviorSubject<boolean>;

    maxDate: Date;
    isCandidateUpdated = false;

    constructor(
        private candidateService: CandidatsService,
        @Inject(WizzardRequestCreatorComponent)
        public requestWizard: WizzardRequestCreatorComponent,
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.resetDriverLicenseRequired?.subscribe((event) => {
            if (event) {
                this.setDriverLicenseValidators();
                this.setEmailValidators();
            }
        });
    }

    setEmailValidators() {
        if (this.isRequiredCodesIncluded(RequiredFieldsForCodes.getEmailRequiredCodes())) {
            this.form.controls['email'].setValidators([Validators.required, emailAddressValidator]);
            this.form.controls['email'].markAsTouched();
        }
    }

    setDriverLicenseValidators() {
        if (this.driverLicenseRequired()) {
            this.form.controls['driverLicenceNumber'].setValidators(Validators.required);
            this.form.controls['driverLicenceNumber'].markAsTouched();
        }
    }

    setCompleteRequestVerifications() {
        if (this.requestWizard.verifications === null) {
            return;
        }
        const updatedRequestVerification = [ ...this.request.requestVerifications ].map((rv) => {
            const verification = this.requestWizard.verifications.find((verif) => verif.id === rv.verificationId);
            return {
                ...rv,
                verification: {...verification},
                verificationPrice: verification?.price || 0
            };
        });
        this.request.requestVerifications = updatedRequestVerification;
    }

    isRequiredCodesIncluded(requiredCodes: string[]): boolean {
        this.setCompleteRequestVerifications();
        return this.request.requestVerifications.filter((requestVerif) => requestVerif.quantity > 0 && requiredCodes.includes(requestVerif.verification.code)).length > 0;
    }

    driverLicenseRequired() {
        return this.isRequiredCodesIncluded(RequiredFieldsForCodes.getDriverLicenseRequiredCodes());
    }

    hasRequiredField(abstractControl: AbstractControl): boolean {
        if (abstractControl.validator) {
            const validator = abstractControl.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
        if (abstractControl['controls']) {
            for (const controlName in abstractControl['controls']) {
                if (abstractControl['controls'][controlName]) {
                    if (this.hasRequiredField(abstractControl['controls'][controlName])) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
}
