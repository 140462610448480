<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid">
        <sub-header [title]="this.title" [description]="this.subTitle"></sub-header>
        <div [class]="containerClass">
            <div class="card card-custom listContainer">
                <div class="card-body">
                    <div class="m-form m-form--label-align-right">
                        <div class="row align-items-center">
                            <div class="col-xl-12" *ngIf="this.request">
                                <div role="actions" class="d-flex justify-content-between">
                                    <button *ngIf="isRequestCreated() && isRequestDraft()" (click)="delete()" class="float-lg-left wizard-btn btn-delete" type="submit">
                                        <i class="la la-trash-o btn-delete"></i>
                                        <span class="wizard-btn-text">{{ 'RequestWizardDelete' | localize }}</span>
                                    </button>
                                    <span *ngIf="isRequestCreated()" class="flex-grow-1 text-center">
                                        <label class="badge status">{{ getStatusLabel(request?.status) }}</label>
                                    </span>
                                    <button *ngIf="isRequestCreated()" (click)="save(true)" class="float-lg-right wizard-btn btn-save justify-content-end" type="submit">
                                        <span class="wizard-btn-text">{{ 'RequestWizardSave' | localize }}</span>
                                        <i class="la la-floppy-o btn-save"></i>
                                    </button>
                                </div>
                                <div role="actions" class="text-end">
                                    <button *ngIf="!isRequestCreated()" (click)="goToRequestList()" class="float-lg-right btn-quit" type="submit">
                                        <i class="la la-close btn-quit"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-center m--margin-bottom-10">
                            <div class="col-xl-12" *ngIf="this.request">
                                <aw-wizard #wizzard [awNavigationMode] navigateBackward="allow" navigateForward="deny">
                                    <aw-wizard-step stepTitle="{{ l('Organization') }}">
                                        <br />
                                        <div class="col-md-12 text-center">
                                            {{ 'RequestWizzardOrganization' | localize }}
                                            <br />
                                        </div>
                                        <request-wizard-organization [(request)]="this.request" (requestChange)="onRequestChange($event)"></request-wizard-organization>
                                    </aw-wizard-step>
                                    <aw-wizard-step stepTitle="{{ l('Verifications') }}">
                                        <br />
                                        <div class="col-md-12 text-center">
                                            {{ 'RequestWizzardVerifications' | localize }}
                                            <br />
                                        </div>
                                        <request-wizard-verifications
                                            *ngIf="request.organization && request.organization.id !== 0 && verifications != null"
                                            [(request)]="request"
                                            [verifications]="verifications"
                                            (requestChange)="onRequestChange($event)"
                                            [verificationsChanged]="verificationsChanged"
                                            [beforeRequestSaved]="beforeRequestSaved"
                                        ></request-wizard-verifications>
                                    </aw-wizard-step>
                                    <aw-wizard-step stepTitle="{{ l('Candidate') }}">
                                        <br />
                                        <div class="text-center">
                                            <request-wizard-candidate-select
                                                *ngIf="(!isRequestCreated() && this.request?.organization?.id != 0) || (isRequestCreated() && this.request?.candidat?.id == 0)"
                                                [(request)]="this.request"
                                                (requestChange)="onRequestChange($event)"
                                                [resetCandidate]="organizationChanged"
                                                [candidateChanged]="candidateChanged"
                                                [resetDriverLicenseRequired]="verificationsChanged"
                                            ></request-wizard-candidate-select>
                                            <request-wizard-candidate-update
                                                *ngIf="isRequestCreated() && this.request?.candidat?.id != 0"
                                                [(request)]="this.request"
                                                (requestChange)="onRequestChange($event)"
                                                [candidateChanged]="candidateChanged"
                                                [resetDriverLicenseRequired]="verificationsChanged"
                                            ></request-wizard-candidate-update>
                                        </div>
                                    </aw-wizard-step>
                                    <aw-wizard-step stepTitle="{{ l('AdditionnalInformations') }}">
                                        <br />
                                        <div class="text-center">
                                            <div class="flex-column align-items-center justify-content-center">
                                                {{ 'RequestWizzardAdditionnalInformations' | localize }}
                                            </div>
                                            <request-wizard-informations-files
                                                *ngIf="isRequestCreated() && verifications != null && this.wizard.currentStepIndex >= 3"
                                                [(request)]="request"
                                                (requestChange)="onRequestChange($event)"
                                                [verifications]="verifications"
                                                (savedEmail)="getOldEmail($event)"
                                                [candidateEmailSent]="candidateEmailSent"
                                                [resetReportLanguage]="organizationChanged"
                                                [requestSaved]="requestSaved"
                                            ></request-wizard-informations-files>
                                        </div>
                                    </aw-wizard-step>
                                    <aw-wizard-step stepTitle="{{ l('Confirm') }}">
                                        <br />
                                        <div class="text-center">
                                            <div *ngIf="isRequestDraft()">
                                                <div>{{ 'RequestWizzardEndOne' | localize }}</div>
                                                <div>{{ 'RequestWizzardEndTwo' | localize }}</div>
                                            </div>
                                            <div *ngIf="isRequestSendCandidate()">
                                                <div>
                                                    <img src="/assets/common/images/check.png" alt="Success" width="5%" />
                                                    {{ 'Success' | localize }}
                                                </div>
                                                <div>
                                                    {{ 'RequestCandidateLinkSentConfirm' | localize }}
                                                </div>
                                            </div>
                                            <div *ngIf="isRequestSendGuardium()">
                                                <div>
                                                    <img src="/assets/common/images/check.png" alt="Success" width="5%" />
                                                    {{ 'Success' | localize }}
                                                </div>
                                                <div>
                                                    {{ 'RequestGuardiumLinkSentConfirm' | localize }}
                                                </div>
                                            </div>
                                            <button class="btn btn-primary margin-top-20" (click)="goToRequestList()">
                                                {{ 'WizzardReturnToRequestList' | localize }}
                                            </button>
                                            <div class="pt-5">
                                                <button class="float-lg-left btn-back" awPreviousStep>
                                                    <i class="la la-arrow-circle-o-left btn-back"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </aw-wizard-step>
                                </aw-wizard>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
