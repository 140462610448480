<form [formGroup]="formGroup" autocomplete="off">
    <div [class]="containerClass">
        <div class="card card-custom mb-10 ">
            <div class="card-body">
                <div class="form-group">
                    <div class="col-md-6">
                        <label for="organization">{{"Organization" | localize}} <span class="asterix">*</span></label>
                    </div>
                    <div class="col-md-6">
                        <input id="organization" formControlName="organization" type="text" name="organization"
                            class="form-control" maxlength="256"
                            [typeahead]="dataSource"
                            [typeaheadOptionField]="'organizationName'"
                            [typeaheadMinLength]="0"
                            [typeaheadScrollable]="true"
                            [typeaheadOptionsInScrollableView]="5"
                            [typeaheadAsync]="true"
                            (typeaheadOnSelect)="onSelectOrganization($event)"
                            (typeaheadNoResults)="typeaheadNoResultsOrganization($event)"
                            autocomplete="off"
                            data-test-id="organization-typeahead">
                        <div class="alert alert-danger" *ngIf="noResultOrganization">
                            {{"ResultNotFoundElementInList" | localize}}</div>
                        <validation-messages [formCtrl]="formGroup.get('organization')"></validation-messages>
                    </div>
                    <div class="form-group col-md-6"
                         *ngIf="isGranted('Pages.Management.Requests.CanSeePrivateInformation')">
                        <label for="primaryContact">{{ 'PrimaryContact' | localize }}</label>
                        <input id="primaryContact" formControlName="primaryContact" type="text" name="primaryContact" class="form-control"
                               maxlength="256" [typeahead]="userList" typeaheadOptionField="userName"
                               [typeaheadMinLength]="0" [typeaheadScrollable]="true"
                               [typeaheadOptionsInScrollableView]="5" autocomplete="off"
                               (typeaheadOnSelect)="onSelectPrimaryContactUser($event)"
                               (typeaheadNoResults)="typeaheadNoResultsUser($event)" />
                        <div class="alert alert-danger" *ngIf="noResultUser">
                            {{ 'ResultNotFoundElementInList' | localize }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<div class="d-flex mt-10">
    <div class="flex-grow-1"></div>

    <div class="col-2 text-end justify-content-center align-self-center pr-0">
            <label class="mb-0">{{"RequestWizzardNextStep" | localize}}</label>
    </div>
    <div class="">
        <button class="float-lg-left btn-next p-0 ml-2" [disabled]="noResultOrganization || formGroup.get('organization').errors" (click)="save()" awNextStep>
        <i class="la la-arrow-circle-o-right btn-next float-lg-left"></i>
        </button>
    </div>

</div>
