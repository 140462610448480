import { Component, OnInit, Injector, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DatePipe } from '@angular/common';
import { InvoiceService } from '@shared/services/invoice.service';
import { InvoiceOutput } from '@shared/models/InvoiceElements/invoice/invoiceOutput';
import { AgGridAngular } from '@ag-grid-community/angular';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AppConsts } from '@shared/AppConsts';
import * as jsPDF from 'jspdf';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailSenderResult, EmailResult } from '@shared/models/shared/emailSenderResult';
import { ToastrService } from 'ngx-toastr';
import { BillingMethodTypeDTO } from '@shared/models/organization/billingInformation/BillingMethodTypeDTO';
import { organizationStatus } from '@shared/models/organization/organizationStatus';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { InvoiceDetailsCustomizeComponent } from './invoice-details-customize/invoice-details-customize.component';
import { InvoiceDisplay } from '@shared/models/InvoiceElements/invoice/invoiceElements/invoiceDisplay';
import { saveAs } from 'file-saver';
import { InvoiceStatusEnum } from '@shared/models/InvoiceElements/invoice/invoiceStatus';
import { InvoiceInput } from '@shared/models/InvoiceElements/invoice/invoiceInput';
import { InvoiceCreditCardPopupComponent } from '../invoice-credit-card-popup/invoice-credit-card-popup.component';
import { LazyLoadEvent } from 'primeng/api';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { CandidatsService } from '@shared/services/candidats.service';
import { CandidatOutput } from '@shared/models/candidat/candidatOutput';
import { getLocalizedFullDateFormat } from '@shared/helpers/DateHelper';

@Component({
    selector: 'invoice-details',
    templateUrl: './invoice-details.component.html',
    styleUrls: ['./invoice-details.component.scss'],
    providers: [DatePipe],
    animations: [appModuleAnimation()],
})
export class InvoiceDetailsComponent extends AppComponentBase implements OnInit {
    @ViewChild('agGrid') agGrid: AgGridAngular;
    @ViewChild('creditCardModal')
        creditCardModal: InvoiceCreditCardPopupComponent;
    @ViewChild('content') content: ElementRef;
    @Output() modalRefresh: EventEmitter<any> = new EventEmitter<any>();

    publicId: string;
    AppConsts = AppConsts;

    result: InvoiceOutput;
    candidate: CandidatOutput;

    thereIsAnState = false;
    axeY = 95;
    date: string;
    today = new Date();
    headElements = [];
    adjustmentElements = [];
    pageCount = 1;
    pdf: any;
    numberOfPagePDF: number;
    /** true when it is the organization's credit card that is to be charged*/
    hasCreditCard = false;
    canUpdateInvoice = true;
    /** false when invoice has reached a status where it should not be edited */
    canUpdateInvoiceStatus = true;
    canCharge = true;
    isNotCharged = true;
    isOrganizationDeleted = false;
    isOrganizationInactif = false;
    calculTotal: number;
    dateLastEmailSent: string;
    paymentSuccessDate: string;
    screenWidth: number;
    organizationId: any;
    private modalRef: BsModalRef;
    /** a property nothing reads */
    iscandidat = false;
    isFirstInvoice = true;
    isCandidatInvoice = false;
    statusString: string;
    isEditingStatus = false;
    freezeSendInvoiceButton = false;
    showSendInvoiceButton = false;
    status: InvoiceStatusEnum;
    isWaitingForTransactionToComplete = false;
    preAuthorizedPayment: string;

    invoiceDisplay: InvoiceDisplay;
    showDesktop = true;
    showMobile = false;
    showBackBtn = false;

    formGroup = new UntypedFormGroup({
        organization: new UntypedFormControl({ value: '', disabled: true }),
        civicNo: new UntypedFormControl({ value: '', disabled: true }),
        street: new UntypedFormControl({ value: '', disabled: true }),
        app: new UntypedFormControl({ value: '', disabled: true }),
        city: new UntypedFormControl({ value: '', disabled: true }),
        postalCode: new UntypedFormControl({ value: '', disabled: true }),
        state: new UntypedFormControl({ value: '', disabled: true }),
        country: new UntypedFormControl({ value: '', disabled: true }),
        contactName: new UntypedFormControl({ value: '', disabled: true }),
        invoiceEmail: new UntypedFormControl({ value: '', disabled: true }),
        invoiceNumber: new UntypedFormControl({ value: '', disabled: true }),
        purchaseOrder: new UntypedFormControl({ value: '', disabled: true }),
        clientName: new UntypedFormControl({ value: '', disabled: true }),
        total: new UntypedFormControl({ value: '', disabled: true }),
        subTotal: new UntypedFormControl({ value: '', disabled: true }),
        tps: new UntypedFormControl({ value: '', disabled: true }),
        tvq: new UntypedFormControl({ value: '', disabled: true }),
        description: new UntypedFormControl({ value: '', disabled: true }),
        adjustment: new UntypedFormControl({ value: '', disabled: true }),
        status: new UntypedFormControl({ value: '', disabled: false }),
    });

    constructor(
        private injector: Injector,
        private service: InvoiceService,
        private _candidatsService: CandidatsService,
        private _appLocalizationService: AppLocalizationService,
        private _route: ActivatedRoute,
        private toastr: ToastrService,
        private modalService: BsModalService,
        private _router: Router,
        private datePipe: DatePipe,
        private _dateTimeService: DateTimeService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        if (this._route.snapshot.paramMap.get('publicId') !== '') {
            this.publicId = this._route.snapshot.paramMap.get('publicId');
            this.loadInvoice();
        }
        this.initializePrinterInformation();
        this.screenWidth = screen.availWidth;
        this.decideWhishModeToShow();

        this.showBackBtn = window.history.length > 1;
    }

    goBack() {
        this._router.navigate(['/invoice-list']);
    }

    convertStatusEnumToString(value: number): void {
        this.statusString = this.l(InvoiceStatusEnum[value]);
    }

    setIsEditingStatus(value: boolean) {
        this.isEditingStatus = value;
    }

    decideWhishModeToShow() {
        if (this.screenWidth > 1024) {
            this.showDesktop = true;
            this.showMobile = false;
        } else if (this.screenWidth <= 1024) {
            this.showDesktop = false;
            this.showMobile = true;
        }
    }

    nextButton() {
        this.isFirstInvoice = false;
        this.isCandidatInvoice = true;
    }

    backButton() {
        this.isFirstInvoice = true;
        this.isCandidatInvoice = false;
    }

    initializePrinterInformation() {
        this.date = this.dateTimeFormatOnlyDate(this.today);
        this.headElements = ['DÉTAILS DES VÉRIFICATIONS/VERIFICATION DETAILS', 'QTÉ/QTY', 'TARIF/RATE', 'TOTAL'];
        this.adjustmentElements = ['DESCRIPTION/DESCRIPTION', 'AJUSTEMENT/ADJUSTMENT'];
        this.pdf = new jsPDF('p', 'mm', 'a4');
        this.numberOfPagePDF = this.pdf.internal.getNumberOfPages();
    }

    async loadInvoice() {
        await this.service
            .getInvoiceByPublicId(this.publicId)
            .toPromise()
            .then((data) => {
                this.result = data.result;
            });

        if (this.result.candidatePublicId != null) {
            await this._candidatsService
                .getCandidatByPublicId(this.result.candidatePublicId)
                .toPromise()
                .then((data) => {
                    this.candidate = data.result;
                });
        }

        this.convertStatusEnumToString(this.result.status);
        this.formatDateFirstAndLastEmailSent();
        this.formatDatePaymentSucceeded();
        this.initializeOtherObjects();
        this.initializeDisplay();
        this.initializeForm();
        this.initializeBooleans();
        this.verifyIfThereIsACustomInvoice();
    }

    initializeBooleans() {
        this.thereIsAnState = this.result.address.state.stateCode !== null;

        if (this.result.organization !== null) {
            this.isOrganizationDeleted = false;
            if (this.result.organization.status === organizationStatus.Inactif) {
                this.isOrganizationInactif = true;
            } else {
                this.isOrganizationInactif = false;
            }
        } else {
            this.isOrganizationDeleted = true;
        }

        // If there is a candidateId in the invoice, it means that it is up to the candidate to pay for the invoice
        // So, we won't use the credit card information associated to the organization and ask the user the credit
        // card infos to use for the payment.
        const paymentIsUpToCandidate = this.result.candidatePublicId != null;
        if (this.result.organization !== null) {
            if (this.result.organization.status === organizationStatus.Actif) {
                if (this.result.organization.billingInfo.billingMethod === BillingMethodTypeDTO.CreditCard) {
                    this.hasCreditCard = !paymentIsUpToCandidate;
                    this.canUpdateInvoice = true;
                }
            }
        }
        if (paymentIsUpToCandidate) {
            this.canUpdateInvoice = true;
            // ask the user the credit card infos to use for the payment.
            this.hasCreditCard = false;
        }
        if (
            this.result.status === InvoiceStatusEnum.PaymentSuccessful ||
            this.result.status === InvoiceStatusEnum.ManualPayment ||
            this.result.status === InvoiceStatusEnum.Paid
        ) {
            this.canUpdateInvoiceStatus = false;
            this.canCharge = false;
        }

        if (this.result.organization !== null) {
            if (this.result.organization.preAuthorizedPayment === true) {
                this.preAuthorizedPayment = this.l('Yes');
            } else {
                this.preAuthorizedPayment = this.l('No');
            }
        }
        const gardiumRolesDisplayNames = [
            'Administrateur Application',
            'Admin Gardium',
            'Gardium - Agent I',
            'Gardium - Agent II',
            'Agent PPO'
        ];
        // show button when [Gardium User]
        this.showSendInvoiceButton = gardiumRolesDisplayNames.some(roleDisplayNames => this.appAuthService.hasRole(roleDisplayNames));
        this.freezeSendInvoiceButton = false;
    }

    initializeForm() {
        this.formGroup.controls['status'].setValue(this.result.status);
    }

    initializeDisplay() {
        this.invoiceDisplay = new InvoiceDisplay();
        this.invoiceDisplay.initializeDisplay(this.result, this.candidate);
        this.invoiceDisplay.invoiceHeader.invoiceDateString = getLocalizedFullDateFormat(this, this.invoiceDisplay.invoiceHeader.invoiceDate);
    }

    initializeOtherObjects() {
        this.organizationId = this.result.organizationId.toString();
    }

    verifyIfThereIsACustomInvoice() {
        this.result.invoiceLines.forEach((r) => {
            // tslint:disable-next-line:triple-equals
            if (r.invoiceLineCandidats != null && r.invoiceLineCandidats !== undefined && r.invoiceLineCandidats.length > 0) {
                this.iscandidat = true;
                return;
            }
        });
    }

    sendInvoiceLinkToInvoiceEmail() {
        if (!this.freezeSendInvoiceButton) {
            this.freezeSendInvoiceButton = true;
            this.service.sendInvoiceLinkToInvoiceEmail(this.publicId).subscribe((response) => {
                const result = response.result as EmailSenderResult;
                if (result.emailSentResult === EmailResult.sent) {
                    this.showEmailSendSuccess();
                    this.reload();
                } else {
                    this.showErrorSendingEmailMessage();
                    this.freezeSendInvoiceButton = false;
                }
            });
        }
    }

    reload() {
        this.loadInvoice();
        this.initializePrinterInformation();
    }

    checkValueToGetCustomizeInvoice() {
        this.modalRef = this.modalService.show(InvoiceDetailsCustomizeComponent, {
            initialState: {
                selectedInvoice: this.result,
            },
            backdrop: 'static',
        });
        (this.modalRef.content as InvoiceDetailsCustomizeComponent).modalSave.subscribe((result) => {
            if (result) {
                this.iscandidat = true;
            } else {
                this.iscandidat = false;
            }
        });
    }

    generateInvoiceWithCandidate() {
        this.service.GetCustomizeInvoice(this.publicId).subscribe((response) => {
            this.reload();
        });
    }

    showEmailSendSuccess() {
        const title = this._appLocalizationService.l('SendInvoiceWithEmail');
        const successMessage = this._appLocalizationService.l('EmailSentSuccess');
        this.toastr.success(successMessage, title);
    }

    showErrorSendingEmailMessage() {
        const title = this._appLocalizationService.l('SendInvoiceWithEmail');
        const errorMessage = this._appLocalizationService.l('EmailSentError');
        this.toastr.error(errorMessage, title);
    }

    async chargeCreditCard() {
        if (!this.hasCreditCard) {
            this.creditCardModal.show();
        } else {
            this.message.confirm(this._appLocalizationService.l('InvoicePaidWarningMessage'), this._appLocalizationService.l('AreYouSure'), async (isConfirmed) => {
                if (isConfirmed) {
                    this.isWaitingForTransactionToComplete = true;
                    try {
                        await this.service
                            .ChargeCreditCardByInvoicePublicId(this.publicId)
                            .toPromise()
                            .then((response) => {
                                if (response.result.outputResult.outputStatus === 0) {
                                    this.paymentSuccess();
                                    this.canCharge = false;
                                    this.isNotCharged = false;
                                    this.canUpdateInvoice = false;
                                    this.isWaitingForTransactionToComplete = false;
                                } else {
                                    this.paymentFailed();
                                    this.isWaitingForTransactionToComplete = false;
                                }
                            });
                    } catch (e) {
                        this.showErrorMessage('InvoicePaidIssue', 'InvoicePaidTryAgainLater');
                        this.isWaitingForTransactionToComplete = false;
                    }
                }
            }, {
                confirmButtonText: this.l('Yes'),
                cancelButtonText: this.l('No')
            });
        }
    }

    showErrorMessage(title: string, errorMessage: string) {
        this.toastr.error(this._appLocalizationService.l(errorMessage), this._appLocalizationService.l(title));
    }

    paymentSuccess() {
        const title = this._appLocalizationService.l('ChargeCreditCard');
        const successMessage = this._appLocalizationService.l('PaymentSucceed');
        this.toastr.success(successMessage, title);
        this.reload();
    }

    paymentFailed() {
        const title = this._appLocalizationService.l('ChargeCreditCard');
        const errorMessage = this._appLocalizationService.l('PaymentFailed');
        this.toastr.error(errorMessage, title);
        this.reload();
    }

    onUpdate() {
        this._router.navigate(['/invoice-update', this.result.publicId]);
    }

    async updateStatus() {
        const input: InvoiceInput = {
            ...this.result,
            status: this.formGroup.controls['status'].value,
        };
        await this.service
            .UpdateInvoice(input)
            .toPromise()
            .then((response) => {
                const valueReceived = response.result;
                if (valueReceived.id !== null) {
                    const title = this._appLocalizationService.l('InvoiceUpdate');
                    const successMessage = this._appLocalizationService.l('InvoiceUpdated');
                    this.showSuccess(title, successMessage);
                    this.setIsEditingStatus(false);
                    this.reload();
                } else {
                    const title = this._appLocalizationService.l('InvoiceUpdateIssue');
                    const errorMessage = this._appLocalizationService.l('InvoiceUpdateTryAgainLater');
                    this.showErrorMessage(errorMessage, title);
                }
                return valueReceived;
            });
    }

    dateTimeFormatOnlyDate(value) {
        return this.datePipe.transform(value, AppConsts.dateTimeFormatOnlyDate);
    }

    formatDateFirstAndLastEmailSent() {
        if (this.result.firstInvoiceEmailDate && this.result.lastInvoiceEmailDate) {
            const firstDateFormatted = this.dateFormater(this.result.firstInvoiceEmailDate);
            const lastDateFormatted = this.dateFormater(this.result.lastInvoiceEmailDate);

            this.dateLastEmailSent = `
            ${this._appLocalizationService.l('EmailSentOn')} <br>
            ${this._appLocalizationService.l('FirstEmailSent')} ${firstDateFormatted} <br>
            ${this._appLocalizationService.l('LastEmailSent')} ${lastDateFormatted}
            `;
        } else {
            this.dateLastEmailSent = this._appLocalizationService.l('EmailNeverSent');
        }
    }

    formatDatePaymentSucceeded() {
        if (this.result.paymentSuccessDate) {
            const paymentSuccessDate = this.dateFormater(this.result.paymentSuccessDate);

            this.paymentSuccessDate = `
            ${this._appLocalizationService.l('SuccessfulCreditCardPayment')} ${paymentSuccessDate}
            `;
        }
    }

    dateFormater(date: Date) {
        return this.datePipe.transform(this._dateTimeService.toUtcDate(date).toLocal().toString(), AppConsts.dateTimeFormat);
    }

    showSuccess(title: string, successMessage: string) {
        this.toastr.success(title, successMessage);
    }

    downloadInvoiceBE() {
        const fileName = this.result.invoiceNumber + '_' + this.result.organizationName + '_' + this.result.clientId + '.pdf';
        this.service
            .getPDFInvoice(this.publicId)
            .toPromise()
            .then((blob) => {
                saveAs(blob, fileName);
            });
    }

    refresh(event?: LazyLoadEvent) {
        // this.initializeRowData();
        this.modalRefresh.emit(null);
    }
}
