import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerificationDescriptionAddComponent } from "./verification-description-add.component";
import { UtilsModule } from '@shared/utils/utils.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ModalModule } from 'ngx-bootstrap/modal';


@NgModule({
    imports: [
        UtilsModule,
        FormsModule, 
        ReactiveFormsModule,
        CKEditorModule,
        ModalModule,
        CommonModule
    ],
    declarations: [
        VerificationDescriptionAddComponent,
    ],
    providers: [],
    exports: [
        VerificationDescriptionAddComponent
    ]
})

export class VerificationDescriptionAddModule {}