<!-- InvoicePDF -->
<div [@routerTransition] class="container-invoiceDetails">
    <div class="content d-flex flex-column flex-column-fluid" style="padding: 5px 0;">
        <sub-header [title]="'Invoices' | localize">
            <div role="actions">
                <button class="btn btn-primary invoiceDetails-btn"
                    *ngIf="isGranted('Pages.Administration.Invoices.Update')"
                    (click)="onUpdate()" [disabled]="isOrganizationDeleted || isOrganizationInactif || isEditingStatus || !canUpdateInvoice || !canUpdateInvoiceStatus">
                    <i class="la la-edit"></i>{{"Edit" | localize}}</button>
                <button *ngIf="showSendInvoiceButton" class="btn btn-secondary invoiceDetails-btn"
                    (click)="sendInvoiceLinkToInvoiceEmail()" [disabled]="isEditingStatus || freezeSendInvoiceButton">
                    <i class="far fa-paper-plane"></i>
                    {{"SendInvoiceWithEmail" | localize}}
                </button>
                <button class="btn btn-primary" *ngIf="canCharge" (click)="chargeCreditCard()"
                    [disabled]="isOrganizationDeleted || isOrganizationInactif || isEditingStatus">
                    <i style='font-size:24px' class='fas'>&#xf53d;</i>
                    {{"ChargeCreditCard" | localize}}</button>
                <button class="btn btn-secondary" (click)="downloadInvoiceBE()" [disabled]="isEditingStatus">
                    <i class="fa fa-download"></i>{{"Download" | localize}}
                </button>
                <button class="btn btn-secondary" (click)="generateInvoiceWithCandidate()"
                    *ngIf="isGranted('Pages.Administration.Invoices.Generate')">
                    {{'GenerateCandidateInvoice' | localize}}
                </button>
                <button *ngIf="showBackBtn" class="btn btn-secondary" (click)="goBack()">
                    {{'Back' | localize}}
                </button>
            </div>
            <div class="row message-organisation-deleted" *ngIf="isOrganizationDeleted">
                <div class="col text-end mt-3 mt-md-0">
                    <i class="fas fa-exclamation-triangle"></i>
                    <span>{{'ToopltipInvoice' | localize}}</span>
                </div>
            </div>
            <div class="row message-organisation-deleted" *ngIf="isOrganizationInactif">
                <div class="col text-end mt-3 mt-md-0">
                    <i class="fas fa-exclamation-triangle"></i>
                    <span>{{'ToopltipInvoiceOrganizationInactif' | localize}}</span>
                </div>
            </div>
        </sub-header>
    </div>
    <div [busyIf]="isWaitingForTransactionToComplete" class="m-section__sub listContainer"
        style="margin-left: 2%; margin-right: 2%; margin-bottom: 1%;">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <span [innerHTML]="dateLastEmailSent">
            </span>
            <div class="d-flex flex-column">
                <div class="d-flex justify-content-around align-items-center">
                    <div *ngIf="isEditingStatus; then thenBlock else elseBlock"></div>
                    <ng-template #thenBlock>
                        <form [formGroup]="formGroup" autocomplete="off">
                            <div class="form-group mr-3">
                                <label for="status">{{"Status" | localize}} <span class="asterix">*</span></label>
                                <select id="status" formControlName="status" type="text" name="status" class="form-control"
                                    maxlength="256" data-test-id="invoice-details-statusEdit-select">
                                    <option *ngIf="result.status === 1" [disabled]="result.status === 1" value="1">
                                        {{"InvoiceSent" | localize}}</option>
                                    <option *ngIf="result.status === 3" [disabled]="result.status === 3" value="3">
                                        {{"FailedPayment" | localize}}</option>
                                    <option value="5">{{"ManualPayment" | localize}}</option>
                                </select>
                            </div>
                        </form>
                        <div role="actions" *ngIf="isEditingStatus">
                            <button (click)="setIsEditingStatus(false)" class="btn btn-secondary">
                                <i class="fas fa-times"></i>
                                {{'Cancel' | localize}}</button>
                            <button class="btn btn-primary" (click)="updateStatus()"
                                data-test-id="invoice-details-statusEdit-save-btn">
                                <i class="la la-floppy-o"></i>
                                {{"Save" | localize}}
                            </button>
                        </div>
                    </ng-template>
                    <ng-template #elseBlock>
                        <span *ngIf="isGranted('Pages.Management.Organizations.CanSeePrivateInformation')">
                            {{'PreAuthorizedPayment' | localize}}: <strong>{{ preAuthorizedPayment }}</strong>
                        </span>
                        <span data-test-id="invoice-details-invoiceStatus-display" style="margin-left: 15px;">
                            {{'StatusInvoice' | localize}}: <strong>{{ statusString }}</strong>
                        </span>
                        <button class="btn btn-secondary invoiceDetails-btn ml-3"
                            *ngIf="canUpdateInvoiceStatus && !isEditingStatus && isGranted('Pages.Administration.Invoices.Status.Update')"
                            (click)="setIsEditingStatus(true)" data-test-id="invoice-details-statusEdit-btn">
                            <i class="la la-edit"></i>{{'InvoiceStatusEdit' | localize}}
                        </button>
                    </ng-template>
                    <br>
                </div>
                <div class="text-end mt-2">{{paymentSuccessDate}}</div>
            </div>
        </div>
        <div id="arrowRight">
            <div class="d-flex justify-content-end text-end mt-3 mt-md-0" *ngIf="isFirstInvoice">
                <button class="btn btn-secondary" (click)="nextButton()" data-toggle="tooltip"
                    title="{{'TooltipNextInvoice' | localize}}">
                    <i class="fa fa-chevron-right"></i>
                </button>
            </div>
            <div class="text-end mt-3 mt-md-0" *ngIf="isCandidatInvoice">
                <button class="btn btn-secondary" (click)="backButton()" data-toggle="tooltip"
                    title="{{'TooltipNextInvoice' | localize}}">
                    <i class="fa fa-chevron-left"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="m-content" id="desktop" *ngIf="showDesktop && invoiceDisplay && isFirstInvoice">
        <invoice-desktop [invoiceDisplay]="invoiceDisplay"></invoice-desktop>
    </div>
    <invoice-details-candidats *ngIf="isCandidatInvoice && invoiceDisplay" [publicId]="result.publicId"
        [invoiceDisplay]="invoiceDisplay"></invoice-details-candidats>
    <!-- INVOICE MOBILE AND TABLET -->
    <div class="m-content" id="mobile" *ngIf="showMobile && invoiceDisplay">
        <invoice-mobile [invoiceDisplay]="invoiceDisplay"></invoice-mobile>
    </div>
    <app-invoice-credit-card-popup #creditCardModal (modalSave)="refresh()" (reload)="reload()" [invoice]="this.result">
    </app-invoice-credit-card-popup>
</div>