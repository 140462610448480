import { Component, OnInit, Input, Injector } from '@angular/core';
import { CandidatOutput } from '@shared/models/candidat/candidatOutput';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AddressHistoryType } from '@shared/models/addresshistory/addressHistoryType';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { AddressInput } from '@shared/models/address/addressInput';
import { AddressHistoryInput } from '@shared/models/addresshistory/addressHistoryInput';
import { CandidatInput } from '@shared/models/candidat/candidatInput';
import { AddressHistoryOutput } from '@shared/models/addresshistory/addressHistoryOutput';
import { AppComponentBase } from '@shared/common/app-component-base';
import { VerificationOutput } from '@shared/models/verification/verificationOutput';

@Component({
    selector: 'request-address-history',
    templateUrl: './address-history-current.component.html',
    styleUrls: ['./address-history-current.component.scss'],
})
export class AddressHistoryComponent extends AppComponentBase implements OnInit {
    @Input() selectedCandidat: CandidatOutput;
    @Input() requestVerifications: VerificationOutput[];
    @Input() readOnly = false;

    maxDate: Date;
    isCurrent = true;
    fromDate: Date = null;
    publicId: string;
    addressCurrent: AddressHistoryOutput;
    length: number;
    minDateTime = new Date();

    formGroupAddress = new UntypedFormGroup({
        fromDate: new UntypedFormControl({ value: '', disabled: true }),
        startDateBetween: new UntypedFormControl({ value: '' }),
        endDateBetween: new UntypedFormControl({ value: '' }),
        type: new UntypedFormControl('', Validators.required),
        civicNo: new UntypedFormControl('', Validators.required),
        street: new UntypedFormControl('', Validators.required),
        app: new UntypedFormControl(''),
        city: new UntypedFormControl('', Validators.required),
        postalCode: new UntypedFormControl(''),
        country: new UntypedFormControl(''),
        countryName: new UntypedFormControl('', Validators.required),
        state: new UntypedFormControl(''),
        stateName: new UntypedFormControl('', Validators.required),
    });

    constructor(private localeService: BsLocaleService, _injector: Injector) {
        super(_injector);
        this.localeService.use(this.localization.currentLanguage.name);
    }

    get operation(): string {
        return this.addressCurrent ? 'update-current' : 'create-current';
    }

    async ngOnInit() {
        this.initializeCurrentAddressHistoryValues(this.selectedCandidat);
    }

    initializeCurrentAddressHistoryValues(candidat: CandidatOutput) {
        this.resetForm();
        if (candidat && candidat.addressHistories != null && candidat.addressHistories.length > 0) {
            candidat.addressHistories.forEach((addressHistory) => {
                if (addressHistory.type === AddressHistoryType.current && addressHistory.requestId === null) {
                    this.addressCurrent = addressHistory;
                }
            });
            if (this.addressCurrent != null) {
                this.initFormWithAddressHistory(this.addressCurrent);
            }
        }
    }

    async initFormWithAddressHistory(address: AddressHistoryOutput) {
        this.formGroupAddress.reset();
        this.addressCurrent = address;
        this.formGroupAddress.controls['type'].disable();
        this.formGroupAddress.controls['type'].setValue(this.addressCurrent.type);
        this.formGroupAddress.controls['fromDate'].enable();
        this.formGroupAddress.controls['fromDate'].setValue(this.addressCurrent.from);
        this.formGroupAddress.controls['civicNo'].setValue(this.addressCurrent.address.civicNo);
        this.formGroupAddress.controls['street'].setValue(this.addressCurrent.address.street);
        this.formGroupAddress.controls['app'].setValue(this.addressCurrent.address.app);
        this.formGroupAddress.controls['city'].setValue(this.addressCurrent.address.city);
        this.formGroupAddress.controls['country'].setValue(this.addressCurrent.address.country);
        this.formGroupAddress.controls['state'].setValue(this.addressCurrent.address.state);
        this.formGroupAddress.controls['countryName'].setValue(this.addressCurrent.address.country.countryName);
        this.formGroupAddress.controls['stateName'].setValue(this.addressCurrent.address.state.stateName);
        this.formGroupAddress.controls['postalCode'].setValue(this.addressCurrent.address.postalCode);
    }

    onFocus(e) {
        e.target.setAttribute('autocomplete', 'off');
    }

    resetForm() {
        this.disableFormControl('type');
        this.formGroupAddress.controls['type'].setValue(AddressHistoryType.current);
        this.isCurrent = true;
        this.maxDate = new Date();
        this.addressCurrent = null;

        this.formGroupAddress.controls['fromDate'].enable();
        this.formGroupAddress.controls['fromDate'].setValue('');
        this.formGroupAddress.controls['civicNo'].setValue('');
        this.formGroupAddress.controls['street'].setValue('');
        this.formGroupAddress.controls['app'].setValue('');
        this.formGroupAddress.controls['city'].setValue('');
        this.formGroupAddress.controls['state'].setValue('');
        this.formGroupAddress.controls['postalCode'].setValue('');
        this.formGroupAddress.controls['country'].setValue('');
    }

    enableFormControl(controlName: string) {
        this.formGroupAddress.controls[controlName].enable();
        this.formGroupAddress.controls[controlName].setValidators(Validators.required);
    }

    disableFormControl(controlName: string) {
        this.formGroupAddress.controls[controlName].clearValidators();
        this.formGroupAddress.controls[controlName].disable();
    }

    isFormInvalid(): boolean {
        return this.formGroupAddress.invalid;
    }
    isFormEmpty(): boolean {
        return (
            (this.formGroupAddress.controls['fromDate'].value === '' || this.formGroupAddress.controls['fromDate'].value === null) &&
            this.formGroupAddress.controls['postalCode'].value === '' &&
            (this.formGroupAddress.controls['civicNo'].value === '' || this.formGroupAddress.controls['civicNo'].value === null) &&
            this.formGroupAddress.controls['street'].value === '' &&
            this.formGroupAddress.controls['app'].value === '' &&
            this.formGroupAddress.controls['city'].value === ''
        );
    }
    returnAddressHistory(): AddressHistoryInput {
        const addressHistory: AddressHistoryInput = {
            id: 0,
            publicId: '',
            type: this.formGroupAddress.controls['type'].value,
            from: null,
            to: null,
            addressId: 0,
            address: this.returnAddress(),
            candidat: this.returnCandidat(),
            candidatsId: this.selectedCandidat.id,
            creationTime: null,
        };

        if (this.isCurrent) {
            addressHistory.from = this.formGroupAddress.controls['fromDate'].value;
        } else {
            addressHistory.from = this.formGroupAddress.controls['startDateBetween'].value;
            addressHistory.to = this.formGroupAddress.controls['endDateBetween'].value;
        }

        return addressHistory;
    }

    returnAddressHistoryToUpdate(): AddressHistoryInput {
        if (this.addressCurrent === null) {
            return null;
        }
        const addressHistory: AddressHistoryInput = {
            id: this.addressCurrent.id,
            publicId: this.addressCurrent.publicId,
            type: AddressHistoryType.current,
            from: this.formGroupAddress.controls['fromDate'].value,
            to: null,
            addressId: this.addressCurrent.address.id,
            address: this.returnAddressToUpdate(),
            candidat: this.returnCandidat(),
            candidatsId: this.selectedCandidat.id,
            creationTime: this.addressCurrent.creationTime,
        };
        return addressHistory;
    }

    getUpdatedCurrentAddress(): AddressHistoryInput {
        let currentAddressId = 0;
        let publicId = '';
        let addressId = 0;
        let creationTime = new Date();

        if (this.addressCurrent !== undefined && this.addressCurrent != null) {
            currentAddressId = this.addressCurrent.id;
            publicId = this.addressCurrent.publicId;
            addressId = this.addressCurrent.address.id;
            creationTime = this.addressCurrent.creationTime;
        }

        const currentAddressHistory: AddressHistoryInput = {
            id: currentAddressId,
            publicId: publicId,
            type: AddressHistoryType.current,
            from: this.formGroupAddress.controls['fromDate'].value,
            to: null,
            addressId: addressId,
            address: this.getInputAddress(),
            candidat: this.returnCandidat(),
            candidatsId: this.selectedCandidat.id,
            creationTime: creationTime,
        };
        return currentAddressHistory;
    }

    getInputAddress(): AddressInput {
        let addressId = 0;
        if (this.addressCurrent !== undefined && this.addressCurrent != null) {
            addressId = this.addressCurrent.id;
        }

        const currentAddress: AddressInput = {
            id: addressId,
            civicNo: this.formGroupAddress.controls['civicNo'].value,
            street: this.formGroupAddress.controls['street'].value,
            app: this.formGroupAddress.controls['app'].value,
            city: this.formGroupAddress.controls['city'].value,
            postalCode: this.formGroupAddress.controls['postalCode'].value,
            state: this.formGroupAddress.controls['state'].value,
            country: this.formGroupAddress.controls['country'].value,
        };
        return currentAddress;
    }

    returnAddress(): AddressInput {
        const address: AddressInput = {
            id: 0,
            civicNo: this.formGroupAddress.controls['civicNo'].value,
            street: this.formGroupAddress.controls['street'].value,
            app: this.formGroupAddress.controls['app'].value,
            city: this.formGroupAddress.controls['city'].value,
            postalCode: this.formGroupAddress.controls['postalCode'].value,
            state: this.formGroupAddress.controls['state'].value,
            country: this.formGroupAddress.controls['country'].value,
        };
        return address;
    }

    returnAddressToUpdate(): AddressInput {
        const address: AddressInput = {
            id: this.addressCurrent.address.id,
            civicNo: this.formGroupAddress.controls['civicNo'].value,
            street: this.formGroupAddress.controls['street'].value,
            app: this.formGroupAddress.controls['app'].value,
            city: this.formGroupAddress.controls['city'].value,
            postalCode: this.formGroupAddress.controls['postalCode'].value,
            state: this.formGroupAddress.controls['state'].value,
            country: this.formGroupAddress.controls['country'].value,
        };
        return address;
    }

    returnCandidat(): CandidatInput {
        const candidat: CandidatInput = CandidatInput.fromOutput(this.selectedCandidat);

        return candidat;
    }
}
