import { FormFilterNames, FormFilterValueTypes, FormFilters } from '@app/store/shared/types/filters.models';
import { GuideOutput } from '@shared/models/guides/guide-output';
import { RequestEvent } from '@shared/models/request-event/request-event.model';

export interface GuidesState {
    items: GuideOutput[];
    filteredItems: GuideOutput[];
    selectedItem?: GuideOutput;
    event: RequestEvent;
    exportedItem: GuideOutput;
    filters: FormFilters[] ;
}

export const guidesInitialState: GuidesState = {
    items: [],
    filteredItems: [],
    selectedItem: undefined,
    event: undefined,
    exportedItem: undefined,
    filters: [
        {
            name: FormFilterNames.Advanced,
            values: [
                {
                    name: FormFilterValueTypes.Status,
                    type: 'number',
                    value: undefined
                },
                {
                    name: FormFilterValueTypes.Language,
                    type: 'number',
                    value: undefined
                },
            ]
        },
        {
            name: FormFilterNames.Global,
            values: [undefined]
        }
    ]
};
