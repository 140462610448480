import { Component, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { UniqueCcPaymentInput } from '@shared/models/payment/uniqueCcPaymentInput';
import { PaymentService } from '@shared/services/payment.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { creditCardValidator } from '@shared/utils/validation/creditCard-validator.directive';
import { InvoiceOutput } from '@shared/models/InvoiceElements/invoice/invoiceOutput';
import { TransactionStatusEnum } from '@shared/models/payment/uniqueCcPaymentOutput';
import { InvoiceInput } from '@shared/models/InvoiceElements/invoice/invoiceInput';
import { InvoiceStatusEnum } from '@shared/models/InvoiceElements/invoice/invoiceStatus';
import { InvoiceService } from '@shared/services/invoice.service';

@Component({
    selector: 'app-invoice-credit-card-popup',
    templateUrl: './invoice-credit-card-popup.component.html',
    styleUrls: ['./invoice-credit-card-popup.component.scss']
})
export class InvoiceCreditCardPopupComponent extends AppComponentBase {
    @ViewChild('creditCardModal') creditCardModal: ModalDirective;
    @Input() invoice: InvoiceOutput;
    @Output() reload: EventEmitter<any> = new EventEmitter<any>();

    uccInput: UniqueCcPaymentInput;
    successMessage = '';
    errorMessage = '';
    processing = false;
    transactionCompleted = false;

    formGroup = new UntypedFormGroup({
        number: new UntypedFormControl({ value: '', disabled: false }, [Validators.required, creditCardValidator]),
        expDate: new UntypedFormControl({ value: '', disabled: false }, Validators.required),
        cvc: new UntypedFormControl({ value: '', disabled: false }, Validators.required)
    });

    constructor(
        private injector: Injector,
        private _appLocalizationService: AppLocalizationService,
        private _paymentService: PaymentService,
        private _invoiceService: InvoiceService
    ) {
        super(injector);
    }

    updateInvoiceStatus(invoiceStatus: InvoiceStatusEnum) {
        const input: InvoiceInput = {
            ...this.invoice,
            status: invoiceStatus
        };
        this._invoiceService.UpdateInvoice(input).subscribe((result) => {
            this.reload.emit();
        });
    }

    handleUniqueCcPayment(paymentInfo: UniqueCcPaymentInput) {
        this.processing = true;
        this.formGroup.disable();

        // Reset Moneris message strings before sending a new request
        this.errorMessage = '';
        this.successMessage = '';

        this._paymentService.makeUniqueCcPayment(paymentInfo, this.invoice.publicId).subscribe(
            (res) => {
                if (res.result.transactionStatus === TransactionStatusEnum.Success) {
                    // this.successMessage = res.result.message.split('=')[0];
                    this.successMessage = this._appLocalizationService.l('PaymentSucceed');

                    // call invoice service to set status to payment successful
                    this.updateInvoiceStatus(InvoiceStatusEnum.PaymentSuccessful);
                } else if (res.result.transactionStatus === TransactionStatusEnum.Fail) {
                    switch (res.result.responseCode) {
                        case 64:
                        case 70:
                        case 71:
                        case 75:
                        case 97:
                        case 99:
                        case 105:
                        case 200:
                        case 477:
                            this.errorMessage = `${this._appLocalizationService.l('PaymentFailed')}: ${this._appLocalizationService.l('InvalidCreditCard')}`;
                            break;

                        case 76:
                            this.errorMessage = `${this._appLocalizationService.l('PaymentFailed')}: ${this._appLocalizationService.l('InsufficientBalance')}`;
                            break;

                        case 51:
                        case 430:
                        case 482:
                        case 484:
                            this.errorMessage = `${this._appLocalizationService.l('PaymentFailed')}: ${this._appLocalizationService.l('ExpiredCard')}`;
                            break;

                        default:
                            this.errorMessage = `${this._appLocalizationService.l('PaymentFailed')}: ${this._appLocalizationService.l('PaymentFailedDefaultError')}`;
                            break;
                    }

                    // call invoice service to set status to payment failed
                    this.updateInvoiceStatus(InvoiceStatusEnum.FailedPayment);
                }
            },
            (err) => {
                this.errorMessage = err.error.result;
            },
            () => {
                this.transactionCompleted = true;
                this.processing = false;
            }
        );
    }

    submitUniquePaymentCcInfo() {
        const uniqueCcPaymentInfo: UniqueCcPaymentInput = {
            number: this.formGroup.controls['number'].value,
            expDate: this.formGroup.controls['expDate'].value,
            cvc: this.formGroup.controls['cvc'].value
        };

        this.handleUniqueCcPayment(uniqueCcPaymentInfo);
    }

    show(): void {
        this.creditCardModal.show();
    }

    cancel(): void {
        this.formGroup.reset();
        this.creditCardModal.hide();
    }

    close(): void {
        this.formGroup.reset();
        this.formGroup.enable();
        this.creditCardModal.hide();
        this.transactionCompleted = false;
        this.successMessage = '';
        this.errorMessage = '';
    }

    resetForm() {
        this.formGroup.enable();
        this.transactionCompleted = false;
        this.successMessage = '';
        this.errorMessage = '';
    }
}
