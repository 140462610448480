import { Component, OnInit, Injector, Input, ViewChild, HostListener } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DatePipe } from '@angular/common';
import { InvoiceService } from '@shared/services/invoice.service';
import { organizationOutput } from '@shared/models/organization/organizationOutput';
import { AgGridAngular } from '@ag-grid-community/angular';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppConsts } from '@shared/AppConsts';
import { GridOptions, ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { InvoiceStatusEnum } from '@shared/models/InvoiceElements/invoice/invoiceStatus';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Extensions } from '@shared/utils/extensions';
import { InvoiceGridOutput } from '@shared/models/InvoiceElements/invoice/invoiceGridOutput';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';

@Component({
    selector: 'organization-invoice-list',
    templateUrl: './organization-invoice.component.html',
    providers: [DatePipe]
})
export class OrganizationInvoiceListComponent extends AppComponentBase implements OnInit {
    @ViewChild('agGrid') agGrid: AgGridAngular;
    private gridApi;
    public defaultColDef;
    paginationNumberFormatter;
    paginationPageSize = 20;

    @Input() organizationPublicId: string;

    filterText = '';
    rowData: InvoiceGridOutput[] = [];

    selectedInvoice: InvoiceGridOutput;
    rowSelection: string;
    selectedInvoiceRow: any;
    columnDefs: any;
    organization: organizationOutput;

    gridOptions = <GridOptions>{
        defaultExportParams: {
            processCellCallback: (params) => {
                const colDef = params.column.getColDef();
                if (colDef.valueFormatter) {
                    const valueFormatterParams: ValueFormatterParams = {
                        ...params,
                        data: params.node.data,
                        node: params.node,
                        colDef: params.column.getColDef()
                    };
                    return colDef.valueFormatter(valueFormatterParams);
                }
                return params.value;
            }
        }
    };

    constructor(
        private injector: Injector,
        private service: InvoiceService,
        private _router: Router,
        private toastr: ToastrService,
        private _appLocalizationService: AppLocalizationService,
        private datePipe: DatePipe,
        private _dateTimeService: DateTimeService
    ) {
        super(injector);
        this.rowSelection = this.isGranted('Pages.Management.Organizations.InvoiceListAccess.InvoiceDetailsAccess') ? 'single' : null;

        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };
    }

    ngOnInit() {
        if (this.organizationPublicId === null) {
            this.service
                .getInvoices()
                .toPromise()
                .then((data) => {
                    this.rowData = data.result;
                });
        } else {
            this.service.getInvoicesByOrganizationPublicId(this.organizationPublicId).subscribe((data) => {
                this.rowData = data.result;
            });
        }
        this.columnDefs = [
            {
                headerName: this._appLocalizationService.l('OrganizationName'),
                field: 'organizationName',
                width: 200
            },
            {
                headerName: this._appLocalizationService.l('Organization'),
                field: 'clientId',
                width: 200
            },
            {
                headerName: this._appLocalizationService.l('InvoiceNumber'),
                field: 'invoiceNumber',
                valueFormatter: this.invoiceNumberFormatter,
                width: 200
            },
            {
                headerName: this._appLocalizationService.l('CreationTime'),
                sort: 'desc',
                field: 'invoiceDate',
                valueFormatter: this.dateFormater.bind(this),
                getQuickFilterText: this.dateFormater.bind(this),
                width: 200
            },
            {
                headerName: this._appLocalizationService.l('SubTotal'),
                valueGetter: this.subTotalCurrencyFormat,
                width: 200
            },
            {
                headerName: this._appLocalizationService.l('Total'),
                valueGetter: this.totalCurrencyFormat,
                width: 200
            },
            {
                headerName: this._appLocalizationService.l('Status'),
                field: 'status',
                valueFormatter: this.statusGetter.bind(this),
                getQuickFilterText: (params) => Extensions.replaceAccentedCharacters(this.statusGetter(params)),
                width: 208
            }
        ];
        this.defaultColDef = { resizable: true, sortable: true, filter: true };
    }

    statusGetter(params: ValueFormatterParams): string {
        return this._appLocalizationService.l(InvoiceStatusEnum[params.value]);
    }

    onPageSizeChanged(e) {
        this.gridApi.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    invoiceNumberFormatter(params) {
        const invoiceNumber = params.data.invoiceNumber;
        const firtThree = invoiceNumber.substring(0, 3);
        const secondThree = invoiceNumber.substring(3, 6);
        const thirdThree = invoiceNumber.substring(6, 9);
        return firtThree + ' ' + secondThree + ' ' + thirdThree;
    }

    subTotalCurrencyFormat(params) {
        const subTotal = params.data.subTotal;
        return subTotal.toFixed(2) + ' $';
    }

    totalCurrencyFormat(params) {
        const total = params.data.total;
        return total.toFixed(2) + ' $';
    }

    onGridReady(params) {
        this.gridApi = params.api;
        if (screen.availWidth > 414) {
            this.gridApi.sizeColumnsToFit();
        }
    }

    dateFormater(params) {
        return this.datePipe.transform(this._dateTimeService.toUtcDate(params.value).toLocal().toString(), AppConsts.dateTimeFormat);
    }

    refresh() {
        this.filterText = '';
        this.agGrid.api.setQuickFilter('');
    }

    search() {
        const filterWithoutAccents = Extensions.replaceAccentedCharacters(this.filterText);
        this.agGrid.api.setQuickFilter(filterWithoutAccents);
    }

    /**
     * Gets a list of filtered invoices publicIds for the excel export
     * @returns List of invoice publicIds (guids)
     */
    getDisplayedNodeIds(): string[] {
        const displayedInvoiceIdsToSend: string[] = [];
        this.gridApi.forEachNodeAfterFilterAndSort(function (node, index) {
            const gridData: InvoiceGridOutput = node.data;

            displayedInvoiceIdsToSend.push(gridData.publicId);
        });

        return displayedInvoiceIdsToSend;
    }

    onSelectionChanged(): void {
        this.selectedInvoiceRow = this.agGrid.api.getSelectedRows()[0] as InvoiceGridOutput;
        this._router.navigate(['/invoice-details', this.selectedInvoiceRow.publicId]);
    }

    showAccessError() {
        const title = this._appLocalizationService.l('AccessIssue');
        const message = this._appLocalizationService.l('NotAccess');
        this.toastr.error(message, title);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.gridApi) {
            if (screen.availWidth > 414) {
                this.gridApi.sizeColumnsToFit();
            }
        }
    }
}
