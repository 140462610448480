import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ProfessionnalReferencesInput } from '@shared/models/CandidatDetails/professionnalReferences/professionnalReferencesInput';
import { finalize } from 'rxjs/operators';
import { ProfessionnalReferenceService } from '@shared/services/professionnalReference.service';
import { ToastrService } from 'ngx-toastr';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { emailAddressValidator } from '@shared/utils/validation/email-validator.directive';
import { phoneNumberValidator } from '@shared/utils/validation/phoneNumber-validator.directive';

@Component({
    selector: 'professionnal-reference-add',
    templateUrl: './professionnal-reference-add.component.html'
})
export class ProfessionnalReferenceAddComponent extends AppComponentBase implements OnInit {
    @ViewChild('professionnalRefenceAddModal') professionnalRefenceAddModal: ModalDirective;
    @Input() candidateId: number;
    @Input() requestId: number;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    maxDate: Date;
    active = false;
    saving = false;
    isCurrentEmployerCheck = true;
    canContactEmployerCheck = true;
    minDateTime = new Date();

    formGroup = new UntypedFormGroup({
        isCurrentEmployer: new UntypedFormControl(''),
        canContactEmployer: new UntypedFormControl(''),
        company: new UntypedFormControl('', Validators.required),
        startDateBetween: new UntypedFormControl(''),
        endDateBetween: new UntypedFormControl(''),
        name: new UntypedFormControl('', Validators.required),
        position: new UntypedFormControl(''),
        title: new UntypedFormControl(''),
        email: new UntypedFormControl('', [Validators.required, emailAddressValidator]),
        phoneNumber: new UntypedFormControl('', [Validators.required, phoneNumberValidator]),
        phoneExtension: new UntypedFormControl('')
    });

    constructor(
        private injector: Injector,
        private service: ProfessionnalReferenceService,
        private toastr: ToastrService,
        private _appLocalizationService: AppLocalizationService
    ) {
        super(injector);
    }

    ngOnInit() {
        this.maxDate = new Date();
    }

    onCreationDateStartValueChange(value: Date): void {
        this.minDateTime = value;
    }

    show(): void {
        this.active = true;
        this.professionnalRefenceAddModal.show();
        this.isCurrentEmployerCheck = true;
        this.canContactEmployerCheck = true;
    }

    isFormInvalid(): boolean {
        return this.formGroup.invalid;
    }

    markAllControlsAsTouch() {
        this.formGroup.controls['isCurrentEmployer'].markAsTouched();
        this.formGroup.controls['canContactEmployer'].markAsTouched();
        this.formGroup.controls['company'].markAsTouched();
        this.formGroup.controls['startDateBetween'].markAsTouched();
        this.formGroup.controls['endDateBetween'].markAsTouched();
        this.formGroup.controls['name'].markAsTouched();
        this.formGroup.controls['position'].markAsTouched();
        this.formGroup.controls['title'].markAsTouched();
        this.formGroup.controls['email'].markAsTouched();
        this.formGroup.controls['phoneNumber'].markAsTouched();
        this.formGroup.controls['phoneExtension'].markAsTouched();
    }

    showForNotValidMessage() {
        const title = this._appLocalizationService.l('VerifyAllControlsTitle');
        const message = this._appLocalizationService.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }

    async save(): Promise<void> {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }
        this.saving = true;

        const professionnalReference: ProfessionnalReferencesInput = {
            id: 0,
            publicId: '',
            isCurrent: this.isCurrentEmployerCheck,
            company: this.formGroup.controls['company'].value,
            name: this.formGroup.controls['name'].value,
            title: this.formGroup.controls['title'].value,
            position: this.formGroup.controls['position'].value,
            email: this.formGroup.controls['email'].value,
            phoneNumber: this.formGroup.controls['phoneNumber'].value,
            from: this.formGroup.controls['startDateBetween'].value,
            to: this.formGroup.controls['endDateBetween'].value,
            canContactEmployer: this.canContactEmployerCheck,
            requestId: this.requestId,
            candidatId: this.candidateId,
            creationTime: new Date(),
            phoneExtension: this.formGroup.controls['phoneExtension'].value
        };

        await this.service
            .addProfessionnalReference(professionnalReference)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe({
                next: (value) => {
                    this.notify.info(this.l('SavedSuccessfully'));
                    this.close();
                    this.modalSave.emit(null);
                },
                error: (err) => {
                    this.notify.error('Server error: ' + err.error.result);
                }
            });
    }

    close(): void {
        this.active = false;
        this.formGroup.reset();
        this.professionnalRefenceAddModal.hide();
    }
}
