import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { RequestFilesService } from './request-files.service';
import { RequestService } from './requests.service';
import { fileType } from '@shared/models/files/fileType';
import * as html2pdf from 'html2pdf.js';
import { RequestInput } from '@shared/models/request/requestInput';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { filterBlankPagesOut } from '@shared/helpers/PDFRendererHelper';
import { outputStatus } from '@shared/models/verification/outputResult';
import { outputResult } from '@shared/models/shared/outputResult';

@Injectable()
export class RequestReportsService {
    constructor(
        private requestFileService: RequestFilesService,
        private requestService: RequestService,
        private _appAuthService: AppAuthService
    ) { }

    updateReport(request: RequestInput, fileType: fileType) {
        const pages = Array.from(document.querySelectorAll('app-report-page'));
        return this.uploadGeneratedReportPDF(this.buildPDF(pages, 0), request, fileType, this.getRequestFileName(request));
    }

    uploadFormAsReport(fileType: fileType, requestId: number, fileName: string, pdfForm: Element[]): Observable<string> {
        return this.uploadGeneratedFormPDF(this.buildPDF(pdfForm, 1), fileType, requestId, fileName);
    }

    uploadGeneratedFormPDF(worker, fileType: fileType, requestId: number, fileName: string): Observable<string> {
        return of(true).pipe(
            filter((x) => x),
            switchMap(() => from(worker.get('pdf').output('blob'))),
            switchMap((blob: Blob) => {
                const file = this.requestFileService.blobToFile(blob, fileName);
                const uploader = this.requestFileService.getUploader(
                    fileType,
                    requestId);
                this.requestFileService.addFile(uploader, file);
                return this.requestFileService.save(uploader);
            })
        );
    }

    uploadGeneratedReportPDF(worker, request: RequestInput, fileType: fileType, fileName: string) {

        return (request.generatedReportPublicId
            ? this.requestService.deleteFile(request.generatedReportPublicId, 'requestReportFile')
                .pipe(
                    map((response) => {
                        const result: outputResult = response?.result?.result;
                        return result.outputStatus === outputStatus.success;
                    }),
                    catchError(() => of(true))
                )
            : of(true)
        ).pipe(
            filter((x) => x),
            switchMap(() => from(worker.get('pdf').output('blob'))),
            switchMap((blob: Blob) => {
                const file = this.requestFileService.blobToFile(blob, fileName);
                const uploader = this.requestFileService.getUploader(
                    fileType,
                    request.id,
                );
                this.requestFileService.addFile(uploader, file);
                return this.requestFileService.save(uploader);
            }),
            tap((filePublicId: string) => {
                request.generatedReportPublicId = filePublicId;
            })
        );
    }

    private getRequestFileName(request: RequestInput) {
        const firstName = request.candidat.firstName.trim();
        const lastName = request.candidat.lastName.toUpperCase().trim();

        return `${request.organization.clientId}_${firstName}${lastName}.pdf`
            .replace(' ', '')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
    }

    private buildPDF(elements: Element[], marginSize: number) {
        let worker = html2pdf()
            .set({
                margin: marginSize,
                image: { type: 'jpeg', quality: 75 },
                jsPDF: {
                    unit: 'in',
                    format: 'letter',
                    orientation: 'portrait',
                },
                html2canvas: {
                    scrollX: 0, scrollY: 0,
                    dpi: 192,
                    scale: 4
                },
            })
            .from(elements[0])
            .then(filterBlankPagesOut);

        if (elements.length > 1) {
            worker = worker.toPdf();
            elements.slice(1).forEach((element) => {
                worker = worker
                    .get('pdf')
                    .then((pdf) => {
                        pdf.addPage();
                    })
                    .from(element)
                    .toContainer()
                    .toCanvas()
                    .then(filterBlankPagesOut)
                    .toPdf();
            });
        }
        return worker;
    }
}
