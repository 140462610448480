export interface OrganizationOutputResult {
    outputStatus: OrganizationOutputStatusType;
    message: string;
}

export enum OrganizationOutputStatusType {
    Success = 0,
    Fail = 1,
    OrganizationCreatedWithVault = 2,
    OrganizationCreatedWithoutVault = 3
}
