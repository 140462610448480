<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid">

        <sub-header [title]="(isSubOrganization ? 'SubOrganizations': 'Organizations') | localize"
            [description]="(isSubOrganization ? 'SubOrganizationsCreate' : 'CreateNewOrganization') | localize">
            <div role="actions">
                <button (click)="returnToList()" class="btn btn-secondary">
                    <i class="fas fa-times"></i>
                    {{'Cancel' | localize}}</button>
                <button class="btn btn-primary" (click)="save()" [disabled]="isOrganizationCreated">
                    <i class="la la-floppy-o" data-test-id="org-create-save-btn"></i>
                    {{"Save" | localize}}</button>
            </div>
        </sub-header>

        <div [class]="containerClass">

            <div class="row" class="breadCrumbContainer">
                <div class="col pl-0 pr-0">
                    <p-breadcrumb [model]="items"></p-breadcrumb>
                </div>
            </div>

            <form [formGroup]="formGroup" autocomplete="off">
                <tabset>
                    <tab heading="{{'OrganizationDetails' | localize}}">
                        <ng-template tabHeading>
                            <span class="invalidTab" *ngIf="isFirstTabInvalid()">*</span>
                        </ng-template>
                        <div class="card card-custom">
                            <div class="card-body">
                                <div class="row">
                                    <div class="form-group col-md-12">
                                        <label for="logo">{{"OrganizationLogo" | localize}}</label><br />
                                        <img *ngIf="organizationLogo != '' && !imageChangedEvent" id="organizationLogo"
                                            class="symbol-label" [src]="organizationLogo" />
                                        <div class="cropper-container" *ngIf="imageChangedEvent">
                                            <image-cropper [containWithinAspectRatio]="false"
                                                [maintainAspectRatio]="false" [imageChangedEvent]="imageChangedEvent"
                                                format="png" (imageCropped)="imageCroppedFile($event)"></image-cropper>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-md-12">
                                            <input type="file" accept=".jpeg,.jpg,.png"
                                                (change)="fileChangeEvent($event)" formControlName="organizationLogo" />
                                            <span class="help-block m-b-none">{{"ProfilePicture_Change_Info" |
                                                localize:maxPictureBytesUserFriendlyValue}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="isSubOrganization">
                                    <label for="parentName">{{("OrganizationParent" ) | localize}} <span
                                            class="asterix">*</span></label>
                                    <input id="parentName" formControlName="parentName" type="text" name="parentName"
                                        class="form-control" maxlength="256">
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label for="name">{{(isSubOrganization?"SubOrganizationName" :
                                            "OrganizationName" )
                                            | localize}}
                                            <span class="asterix">*</span>
                                        </label>
                                        <input id="name" formControlName="name" type="text" name="name"
                                            class="form-control" maxlength="256" data-test-id="org-create-name">
                                        <validation-messages [formCtrl]="formGroup.get('name')"></validation-messages>
                                    </div>
                                    <div class="form-group col-md-6 edition-list">
                                        <label for="lineOfBusiness">{{"BusinessActivity" | localize}} <span
                                                class="asterix">*</span></label>
                                        <select name="lineOfBusiness" class="form-control" id="lineOfBusiness"
                                            formControlName="lineOfBusiness">
                                            <option *ngFor="
                                                    let lineOfBusiness of lineOfBusinessList
                                                " [ngValue]="lineOfBusiness.value">
                                                {{ lineOfBusiness.key }}
                                            </option>
                                        </select>
                                        <validation-messages
                                            [formCtrl]="formGroup.get('lineOfBusiness')"></validation-messages>
                                    </div>
                                    <div class="form-group col-md-6"
                                        *ngIf="isGranted('Pages.Management.Organizations.CanSeePrivateInformation')">
                                        <label for="howYouHear">{{"HearAboutGardiumLabel" | localize}} <span
                                                class="asterix">*</span></label>
                                        <select class="form-select" formControlName="howYouHear" id="howYouHear">
                                            <option *ngFor="let answer of provenanceList" [value]="answer.key">
                                                {{ answer.value }}
                                            </option>
                                        </select>
                                        <div *ngIf="isAnswerOther">
                                            <label for="howYouHearInput">{{"OtherAnswer" | localize}}</label>
                                            <input type="text" id="howYouHearInput" class="form-control"
                                                formControlName="otherAnswer" />
                                            <validation-messages [formCtrl]="formGroup.get('otherAnswer')">
                                            </validation-messages>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="volumeOfRequestPerYear">{{"VolumeOfRequestPerYear" | localize}}
                                    </label>
                                    <select id="volumeOfRequestPerYear" formControlName="volumeOfRequestPerYear"
                                        name="volumeOfRequestPerYear" class="form-control"
                                        data-test-id="volume-of-request-per-year-select">
                                        <option
                                            *ngFor="let item of organizationRequestVolumeRanges | enumToArray | slice:0:-1"
                                            [value]='item.index'>{{item.name}}</option>
                                    </select>
                                    <validation-messages [formCtrl]="formGroup.get('volumeOfRequestPerYear')">
                                    </validation-messages>
                                </div>

                                <div class="row">
                                    <div *ngIf="isGranted('Pages.Management.Organizations.CanSeePrivateInformation')"
                                        class="form-group col-md-4">
                                        <label for="idSage">{{"IdSage" | localize}}</label>
                                        <input id="idSage" formControlName="idSage" type="text" name="idSage"
                                            class="form-control" maxlength="256" data-test-id="org-create-acc-id">
                                        <validation-messages [formCtrl]="formGroup.get('idSage')"></validation-messages>
                                    </div>

                                    <div *ngIf="isGranted('Pages.Management.Organizations.CanSeePrivateInformation')"
                                        class="form-group col-md-4">
                                        <label for="idAccountantCAR">{{"IdAccountantCAR" | localize}}</label>
                                        <input id="idAccountantCAR" formControlName="idAccountantCAR" type="text"
                                            name="idAccountantCAR" class="form-control" maxlength="256"
                                            data-test-id="org-create-acc-id">
                                        <validation-messages
                                            [formCtrl]="formGroup.get('idAccountantCAR')"></validation-messages>
                                    </div>

                                    <div class="form-group col-md-4">
                                        <label for="purchaseOrder">{{"PurchaseOrder" | localize}}</label>
                                        <input id="purchaseOrder" formControlName="purchaseOrder" type="text"
                                            name="purchaseOrder" class="form-control" maxlength="256">
                                        <validation-messages [formCtrl]="formGroup.get('purchaseOrder')">
                                        </validation-messages>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label for="firstContact">{{"FirstContact" | localize}} <span
                                                class="asterix">*</span></label>
                                        <input id="firstContact" formControlName="firstContact" type="text"
                                            name="firstContact" class="form-control" maxlength="256" #saveContacts
                                            data-test-id="org-create-first-contact">
                                        <validation-messages [formCtrl]="formGroup.get('firstContact')">
                                        </validation-messages>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="jobTitle">{{"JobTitleLabel" | localize}}</label>
                                        <input type="text" name="jobTitle" class="form-control"
                                            formControlName="jobTitle" />
                                        <validation-messages [formCtrl]="formGroup.get('jobTitle')">
                                        </validation-messages>
                                    </div>
                                </div>
                                <label for="firstContactEmail">{{"FirstContactEmail" | localize}} <span
                                        class="asterix">*</span></label>
                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <input id="firstContactEmail" formControlName="firstContactEmail" type="text"
                                            name="firstContactEmail" class="form-control" maxlength="256" #saveEmails
                                            data-test-id="org-create-first-contact-email">
                                        <validation-messages [formCtrl]="formGroup.get('firstContactEmail')">
                                        </validation-messages>
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <input class="btn btn-secondary form-control" type="button"
                                            style="border-radius: .25rem;"
                                            [disabled]="this.formGroup.controls['firstContactEmail'].invalid || this.formGroup.controls['firstContact'].invalid"
                                            (click)="copyContactInformationToBillingInfo()" id="btnSaveEmail"
                                            value="{{'TooltipOrganization' | localize}}"
                                            data-test-id="org-create-copyEmailContact">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label for="phoneNumber">{{"PhoneNumber" | localize }} <span
                                                class="asterix">*</span></label>
                                        <input id="phoneNumber" formControlName="phoneNumber" type="text"
                                            name="phoneNumber" Class="form-control" maxlength="256"
                                            placeholder="+1(999) 999-9999" mask="(000) 000-0000" prefix="+ 1 "
                                            [dropSpecialCharacters]="false"
                                            [specialCharacters]="[ '+', '1', '(' , ')' , ' ', '-' ]"
                                            [showMaskTyped]="true" data-test-id="org-create-phone" />
                                        <validation-messages [formCtrl]="formGroup.get('phoneNumber')">
                                        </validation-messages>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="phoneExtension">{{"PhoneNumberExtension" | localize}}</label>
                                        <input id="phoneExtension" formControlName="phoneExtension" type="number"
                                            name="phoneExtension" class="form-control" maxlength="256" min="0">
                                        <validation-messages [formCtrl]="formGroup.get('phoneExtension')">
                                        </validation-messages>
                                    </div>
                                </div>
                                <div class="form-group edition-list">
                                    <label for="language">{{"Language" | localize}} <span
                                            class="asterix">*</span></label>
                                    <select name="language" class="form-control" id="language"
                                        formControlName="language" data-test-id="org-create-language-select">
                                        <option value="">{{"Select" | localize}}</option>
                                        <option value="english">{{"English" | localize}}</option>
                                        <option value="french">{{"French" | localize}}</option>
                                    </select>
                                    <validation-messages [formCtrl]="formGroup.get('language')"></validation-messages>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-12"
                                        *ngIf="isGranted('Pages.Management.Organizations.CanSeePrivateInformation')">
                                        <label for="notes">{{"Notes" | localize}}</label>
                                        <textarea class="form-control" rows="5" id="notes" formControlName="notes"
                                            name="notes"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab>
                    <tab heading="{{'AddressTitle' | localize}}">
                        <ng-template tabHeading>
                            <span class="invalidTab" *ngIf="isSecondTabInvalid()"
                                data-test-id="org-create-address-tab">*</span>
                        </ng-template>
                        <div class="card card-custom">
                            <div class="card-body">
                                <app-address-fields [formGroup]="addressFormGroup"
                                    operation="create"></app-address-fields>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <div class="card card-custom pt-0">
                                            <div class="card-body pt-0">
                                                <input class="btn btn-secondary form-control" type="button"
                                                    autocomplete="off" [disabled]="isSecondTabInvalid()"
                                                    style="border-radius: .25rem;"
                                                    (click)="copyAddressAsBillingAddress()" id="btnCopyAddress"
                                                    value="{{'CopyAddressAsBillingAddress' | localize}}"
                                                    data-test-id="org-create-copyAddress">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab>
                    <tab heading="{{'BillingInfoTitle' | localize}}">
                        <ng-template tabHeading>
                            <span class="invalidTab" *ngIf="isThirdTabInvalid()"
                                data-test-id="org-create-billing-tab">*</span>
                        </ng-template>
                        <div class="card card-custom">
                            <div class="card-body">
                                <div class="row">
                                    <div class="form-group edition-list col-md-4">
                                        <label for="billingOutputType">{{"BillingOutputType" | localize}} <span
                                                class="asterix">*</span></label>
                                        <select name="billingOutputType" class="form-control" id="billingOutputType"
                                            formControlName="billingOutputType">
                                            <option value="">{{"Select" | localize}}</option>
                                            <option value='0'>{{"Email" | localize}}</option>
                                        </select>
                                        <validation-messages [formCtrl]="formGroup.get('billingOutputType')">
                                        </validation-messages>
                                    </div>
                                    <div class="form-group edition-list col-md-4">
                                        <label for="billingPeriod">{{"BillingPeriod" | localize}} <span
                                                class="asterix">*</span></label>
                                        <select name="billingPeriod" class="form-control" id="billingPeriod"
                                            formControlName="billingPeriod"
                                            data-test-id="org-create-billingPeriod-select">
                                            <option value="">{{"Select" | localize}}</option>
                                            <option value='0'>{{"Unit" | localize}}</option>
                                            <option value='1'>{{"Monthly" | localize}}</option>
                                        </select>
                                        <validation-messages [formCtrl]="formGroup.get('billingPeriod')">
                                        </validation-messages>
                                    </div>
                                    <div class="form-group edition-list col-md-4">
                                        <label for="billingMethod">{{"BillingMethod" | localize}} <span
                                                class="asterix">*
                                            </span></label>
                                        <select name="billingMethod" class="form-control" id="billingMethod"
                                            formControlName="billingMethod"
                                            (change)="billingMethodChange($event.target.value)"
                                            data-test-id="org-create-billingMethod-select">
                                            <option value="">{{"Select" | localize}}</option>
                                            <option value='0'>{{"DirectDeposit" | localize}}</option>
                                            <option value='1'>{{"CreditCard" | localize}}</option>
                                            <option value='2'>{{"Check" | localize}}</option>
                                        </select>
                                        <validation-messages [formCtrl]="formGroup.get('billingMethod')">
                                        </validation-messages>
                                    </div>
                                </div>
                                <div class="row" *ngIf="formGroup.get('billingMethod').value === '1'">
                                    <div class="form-group col-md-6">
                                        <label for="creditCardNumber">{{"CreditCardNumber" | localize}} <span
                                                class="asterix">*</span></label>
                                        <input id="creditCardNumber" formControlName="creditCardNumber" type="text"
                                            name="creditCardNumber" class="form-control" maxlength="16">
                                        <validation-messages [formCtrl]="formGroup.get('creditCardNumber')">
                                        </validation-messages>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="expdate">{{"Expdate" | localize}} <span class="asterix">*</span> <i
                                                class="fas fa-info-circle" data-toggle="tooltip"
                                                title="{{'TooltipExpDate' | localize}}" id='tooltipInfo'></i></label>
                                        <input id="expdate" formControlName="expdate" type="text" name="expdate"
                                            class="form-control" maxlength="4"
                                            placeholder="{{'PlaceholderExpDate' | localize}}">
                                        <validation-messages [formCtrl]="formGroup.get('expdate')">
                                        </validation-messages>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-4">
                                        <label for="billingContact">{{"BillingContact" | localize}} <span
                                                class="asterix">*</span></label>
                                        <input id="billingContact" formControlName="billingContact" type="text"
                                            name="billingContact" class="form-control" maxlength="256">
                                        <validation-messages [formCtrl]="formGroup.get('billingContact')">
                                        </validation-messages>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="billingEmail">{{"BillingEmail" | localize}} <span class="asterix">*
                                            </span>
                                            <i class="fas fa-info-circle" data-toggle="tooltip"
                                                title="{{'TooltipOrganizationInfo' | localize}}"
                                                id='tooltipInfo'></i></label>
                                        <input id="billingEmail" formControlName="billingEmail" type="text"
                                            name="billingEmail" class="form-control" maxlength="256">
                                        <validation-messages [formCtrl]="formGroup.get('billingEmail')">
                                        </validation-messages>
                                    </div>
                                    <div class="form-group col-md-4"
                                        *ngIf="formGroup.get('billingMethod').value === '1'">
                                        <label for="preAuthorizedPayment">{{"PreAuthorizedPayment" |
                                            localize}}<span class="asterix">*</span><i class="la la-exclamation-circle"
                                                data-toggle="tooltip"
                                                title="{{'TooltipPreAuthorizedPayment' | localize}}"
                                                id='tooltipInfo'></i></label>
                                        <select id="preAuthorizedPayment" formControlName="preAuthorizedPayment"
                                            type="text" name="preAuthorizedPayment" class="form-control"
                                            maxlength="256">
                                            <option value="">{{"Select" | localize}}</option>
                                            <option value="0">{{"No" | localize}}</option>
                                            <option value="1">{{"Yes" | localize}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class='formSeparation'>
                                    <h4>{{"BillingAddress" | localize}}</h4>
                                    <app-address-fields [formGroup]="billingAddressFormGroup"
                                        operation="create"></app-address-fields>
                                </div>
                            </div>
                        </div>
                    </tab>
                </tabset>
            </form>
        </div>
    </div>
</div>
