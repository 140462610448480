import { Component, EventEmitter, Injector, Input, Output, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { SendingMethodType } from '@shared/models/request/sendingMethodType';

@Component({
    selector: 'sending-method',
    templateUrl: 'sending-method.component.html',
    styleUrls: ["./sending-method.component.scss"]
})
export class SendingMethodComponent extends AppComponentBase{
    @Output() sendingMethodSelected: EventEmitter<SendingMethodType> = new EventEmitter<SendingMethodType>();
    private _showLoadingSpinner = true;
    sendingMethod: SendingMethodType = SendingMethodType.Traditionnal;//Default value
    showEzSignMethod = true;

    constructor(private injector: Injector, private cdr: ChangeDetectorRef) {
        super(injector);
    }

    onChangeSendingMethod(value: SendingMethodType) {
        this.sendingMethodSelected.emit(value);
    }

    @Input()
    set showLoadingSpinner(value: boolean) {
      this._showLoadingSpinner = value;
      this.cdr.detectChanges();
    }
    
    get showLoadingSpinner(): boolean {
      return this._showLoadingSpinner;
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('showLoadingSpinner' in changes) {
          this.cdr.detectChanges();
        }
      }
}
