import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RequestVerificationFormInput } from '@shared/models/CandidatDetails/requestVerificationForm/requestVerificationFormInput';
import { RequestVerificationFormOutput } from '@shared/models/CandidatDetails/requestVerificationForm/requestVerificationFormOutput';
import { Observable } from 'rxjs';
import { RequestVerificationFormEmailInput } from '@shared/models/CandidatDetails/requestVerificationForm/requestVerificationFormEmailInput';
import { RequestVerificationFormDataOutput } from '@shared/models/CandidatDetails/requestVerificationForm/requestVerificationFormDataOutput';
import { environment as env } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RequestVerificationFormService {
    private http: HttpClient;
    private baseUrl: string;

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = env.api;
    }

    updateForm(formInput: RequestVerificationFormInput): Observable<any> {
        let url_ = this.getUrl('/api/requestVerificationForm/UpdateForm');
        return this.http.put<RequestVerificationFormOutput>(url_, formInput);
    }

    submitForm(formInput: RequestVerificationFormInput): Observable<any> {
        let url_ = this.getUrl('/api/requestVerificationForm/SubmitForm');
        return this.http.put<RequestVerificationFormOutput>(url_, formInput);
    }

    deleteForm(formId: number): Observable<any> {
        let url_ = this.getUrl('/api/requestVerificationForm/DeleteForm');
        return this.http.post<any>(url_, formId);
    }

    getForm(formId: number, candidatePublicId: string): Observable<any> {
        let url_ = this.getUrl(`/api/requestVerificationForm/GetFormData/${formId}/${candidatePublicId}`);
        return this.http.get<RequestVerificationFormDataOutput>(url_);
    }

    getFormList(requestPublicId: string): Observable<any> {
        let url_ = this.getUrl('/api/requestVerificationForm/GetFormList');
        let params = new HttpParams().set('requestPublicId', requestPublicId);
        return this.http.get<RequestVerificationFormOutput>(url_, { params });
    }

    validateForm(formId: number): Observable<any> {
        let url_ = this.getUrl('/api/requestVerificationForm/ValidateForm');
        let params = new HttpParams().set('formId', formId);
        return this.http.get<RequestVerificationFormOutput>(url_, { params });
    }

    sendEmail(emailInput: RequestVerificationFormEmailInput): Observable<any> {
        let url_ = this.getUrl('/api/requestVerificationForm/SendEmail');
        return this.http.post<any>(url_, emailInput);
    }

    private getUrl(url: string): string {
        let url_ = this.baseUrl + url;
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    }
}
