import { Component, Injector, NgZone } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'grid-btn-update-organizationVerification',
    templateUrl: './grid-btn-update-organizationVerification.component.html'
})
export class GridBtnUpdateOrganizationVerificationComponent extends AppComponentBase implements ICellRendererAngularComp {
    params: any;
    private ngZone: any;

    constructor(injector: Injector) {
        super(injector);
        this.ngZone = injector.get(NgZone);
    }

    refresh(params: any): boolean {
        return false;
    }

    agInit(params): void {
        this.params = params;
    }

    onClick(): void {
        this.ngZone.run(() => {
            this.params.action(this.params.data);
        });
    }
}
