<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid">
        <sub-header [title]="'Files' | localize" [description]="'ListFiles' | localize">
            <div role="actions">
                <button (click)="filesUploadInput.click()" class="btn btn-primary"
                    *ngIf="isGranted('Pages.Management.Files.Add')"
                    [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')">
                    {{"Add" | localize }}
                </button>
                <input type="file" accept=".jpeg,.jpg,.png,.pdf" #filesUploadInput ng2FileSelect multiple
                    [uploader]="uploader" [hidden]="true" />
            </div>
        </sub-header>
        <div [class]="containerClass">
            <div class="card card-custom listContainer">
                <div class="card-body">
                    <div class="input-group mb-5">
                        <input type="text" class="form-control m-input" [(ngModel)]="filterText"
                            [placeholder]="l('Search')" autoFocus (keydown.enter)="search()" (ngModelChange)="search()" />
                        <span class="input-group-btn">
                            <button class="btn btn-primary" type="submit" (click)="search()">
                                <i class="flaticon-search-1" [attr.aria-label]="l('Search')"></i>
                            </button>
                        </span>
                        <span class="input-group-btn" style="padding-left:5px;">
                            <button class="btn btn-secondary" (click)="refresh()">
                                <i class="la la-refresh"></i> {{'Refresh' | localize}}
                            </button>
                        </span>
                    </div>
                    <div>
                        <div class="action-container">
                            <div class="input-group-btn">
                                <button class="btn btn-outline-secondary" (click)="resetFilters()">
                                    <i class="la la-refresh"></i>
                                    {{ "grid.resetFilters" | localize}}
                                </button>
                                <button class="btn btn-outline-secondary" (click)="resetSort()">
                                    <i class="la la-refresh"></i>
                                    {{ "grid.resetColumns" | localize}}
                                </button>
                            </div>
                            <grid-pagination class="grid-pagination" (sizeValue)="onPageSizeChanged($event)">
                            </grid-pagination>
                          </div>
                        <ag-grid-angular localizeText #agGrid class="ag-theme-material gardium-grid" [rowData]="files"
                            [paginationPageSize]="paginationPageSize" domLayout="autoHeight"
                            [paginationNumberFormatter]="paginationNumberFormatter" [pagination]="true"
                            [columnDefs]="columnDefs" [getRowNodeId]="getRowNodeId" [suppressCellSelection]="true"
                            (gridReady)="onGridReady($event)" (sortChanged)="onSortChanged($event)"
                            (filterChanged)="onFilterChanged($event)" (firstDataRendered)="onFirstDataRendered()">
                        </ag-grid-angular>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
