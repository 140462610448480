<div class="m-portlet m-portlet--mobile invoice-display">
    <div class="m-portlet__body">
        <div id='content-mobile-tablet'>
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <img src="/assets/common/images/app-logo-on-light-pdf.svg" alt="app-logo-on-light"
                            class='logo' />
                    </div>
                    <div class="col-md-4 adress-gardium-position">
                        <span>{{'Invoice_GardiumAddress_FirstLine' | localize}}</span><br>
                        <span>{{'Invoice_GardiumAddress_SecondLine' | localize}}</span><br>
                        <span>{{'Invoice_GardiumAddress_ThirdLine' | localize}}</span>
                    </div>
                </div>
                <div class="row" style="height: 45px">
                    <div class="col-md-12 invoice">{{'Invoice_Invoice' | localize}}</div>
                </div>
                <hr class="hr">
                <div class="row">
                    <div class="col-md-6 organization">
                        <span class="organization-contact">{{'Invoice_Client' | localize}}</span><br>
                        <span> {{invoiceDisplay.invoiceHeader.organizationName}}</span><br>
                        <span>{{invoiceDisplay.invoiceHeader.civicNo}},
                            {{invoiceDisplay.invoiceHeader.street}}<span *ngIf="invoiceDisplay.invoiceHeader.app">,
                                {{invoiceDisplay.invoiceHeader.app}}</span></span><br>
                        <span>{{invoiceDisplay.invoiceHeader.city}},
                            {{invoiceDisplay.invoiceHeader.state}}</span><br>
                        <span>{{invoiceDisplay.invoiceHeader.postalCode}}</span><br>
                        <span>{{invoiceDisplay.invoiceHeader.contactName}}</span><br>
                        <span class="organization-contact">{{'Invoice_Email' | localize}} </span>
                        <span>{{invoiceDisplay.invoiceHeader.invoiceEmail}}</span><br>
                    </div>
                    <div class="col-md-6 organization invoice-number-date">
                        <span class="organization-contact">{{'Invoice_InvoiceNumber' | localize}}</span><br>
                        <span>{{invoiceDisplay.invoiceHeader.invoiceNumber | mask: "000 000 000"}}</span><br><br>
                        <span class="organization-contact">{{'Invoice_InvoiceDate' | localize}}</span><br>
                        <span>{{invoiceDisplay.invoiceHeader.invoiceDateString}}</span>
                    </div>
                </div>
                <hr class="hr">
                <div class="row">
                    <div class="col-md-6 organization">
                        <span class="organization-contact">{{'Invoice_OurFile' | localize}}</span><br>
                        <span>{{invoiceDisplay.invoiceHeader.clientName}}</span><br>
                        <div *ngIf="isPurchaseOrderAvailable">
                            <span class="organization-contact">{{'Invoice_OrderNumber' | localize}}</span><br>
                            <span>{{invoiceDisplay.invoiceHeader.purchaseOrder}}</span>
                        </div>
                    </div>
                    <div class="col-md-6 organization invoice-number-date">
                        <span class="organization-contact">{{'Invoice_MonthOf' | localize}}</span><br>
                        <span>{{monthFormat(invoiceDisplay.invoiceHeader.invoiceDate)}}</span>
                    </div>
                </div>
                <hr class="hr">
                <div class="row verification-mobile">
                    <div class="col-md-12 organization" style="font-size: 11px;">
                        <table mdbTable>
                            <thead>
                                <tr>
                                    <th *ngFor="let head of headElements" scope="col"
                                        style="padding: 10px; text-align: center">
                                        {{head}} </th>
                                </tr>
                            </thead>
                            <tbody *ngIf="invoiceDisplay.invoiceLines">
                                <tr mdbTableCol *ngFor="let element of invoiceDisplay.invoiceLines">
                                    <th scope="row" class="font-name-of-verification" style="padding-left: 10px;">
                                        {{element.verification.type}}</th>
                                    <td style="text-align: center">{{element.quantity}}</td>
                                    <td style="text-align: center">{{(element.unitPrice).toFixed(2)}} $</td>
                                    <td style="text-align: center">
                                        {{(element.quantity * element.unitPrice).toFixed(2)}} $</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div *ngIf="invoiceDisplay.resultInvoiceAdjustments.length > 0">
                    <div class="row" style="margin-top: 30px; font-size: 11px;">
                        <table mdbTable>
                            <thead>
                                <tr>
                                    <th scope="col" style="padding-left: 26px">{{'Invoice_Description' | localize}}</th>
                                    <th scope="col" style="padding-left: 190px">{{'Invoice_Adjustment' | localize}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr mdbTableCol *ngFor="let element of invoiceDisplay.resultInvoiceAdjustments">
                                    <td style="padding-left: 26px">{{element.description}}</td>
                                    <td style="padding-left: 255px">{{(element.adjustment).toFixed(2)}} $</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <br /><br /><br /><br />
                <div class="row">
                    <div class="position-subTotal">
                        <span class="organization-contact">{{'Invoice_SubTotal' | localize}} </span><span
                            class="align-total-section">
                            {{invoiceDisplay.invoiceTotalSection.subTotal}}
                            $</span>
                        <hr><br>
                        <span class="organization-contact">{{'Invoice_TPS' | localize}} </span><span
                            class="align-total-section">
                            {{invoiceDisplay.invoiceTotalSection.tps}}
                            $</span>
                        <hr><br>
                        <span class="organization-contact">{{'Invoice_TVQ' | localize}} </span><span
                            class="align-total-section">
                            {{invoiceDisplay.invoiceTotalSection.tvq}}
                            $</span>
                        <hr><br>
                        <span class="organization-contact">{{'Invoice_Total' | localize}} </span><span
                            class="align-total-section">
                            {{invoiceDisplay.invoiceTotalSection.total}} $</span>
                        <hr>
                    </div>
                </div>
                <div class="row" id="footerPDF-mobile-tablet">
                    <div class="col-md-1" style="line-height: 5px">
                        <span class="organization-contact" style="font-size: 6px; display: none">{{'Invoice_TPS' |
                            localize}}</span><br>
                        <span class="organization-contact" style="font-size: 6px; display: none">{{'Invoice_TVQ' |
                            localize}}</span>
                    </div>
                    <div class="col-md-8" style="text-align: center; font-size: 10px">
                        <span class="span-footer">{{'Invoice_Payable' | localize}} &#60;&#60;
                            <strong>{{'Invoice_NetThirtyDays' | localize}}</strong>
                            &#62;&#62;.</span><br>
                        <span class="span-footer">{{'Invoice_Interest_FirstLine' | localize}}</span><br>
                        <span class="span-footer">{{'Invoice_Interest_SecondLine' | localize}}
                            <strong>{{'Invoice_Interest_ThirdLine' | localize}}</strong> {{'Invoice_Interest_In' |
                            localize}}</span><br>
                        <span class="span-footer">{{'Invoice_Interest_FourthLine' | localize}}</span><br>
                        <span class="span-footer">{{'Invoice_Footer_FirstLine' | localize}}</span><br>
                        <span class="span-footer">{{'Invoice_Footer_SecondLine' | localize}}</span>
                    </div>
                    <div class="col-md-3 page-count">
                        <span>Page {{pageCount}} {{'Invoice_Footer_Of' | localize}} {{numberOfPagePDF}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>