<div [@routerTransition]>
  <div class="content d-flex flex-column flex-column-fluid">
    <sub-header [title]="'InvoiceStatus' | localize" [description]="'ListInvoicesStatus' | localize"> </sub-header>
    <div [class]="containerClass">
      <div class="card card-custom listContainer">
        <div class="card-body">
          <div class="m-form m-form--label-align-right">
            <div class="row align-items-center m--margin-bottom-10">
              <div class="col-xl-12">
                <aw-wizard #wizzard [disableNavigationBar]="true">
                  <aw-wizard-step stepId="beginStep" stepTitle="{{l('InvoiceStatus')}}"> 
                    <wizzard-status-invoice #wizzardStatusInvoice (sendEmailRequestSelected)="onSendEmailRequestSelected($event)"></wizzard-status-invoice>
                  </aw-wizard-step>
                  <aw-wizard-step stepTitle="{{l('EmailSend')}}">
                      <div class="col-md-12 text-center">
                        <wizzard-email-send #wizzardEmailSend></wizzard-email-send>
                        <div class="pt-5" *ngIf="!showLoadingSpinner">
                          <button class="btn btn-primary" awPreviousStep (click)="refreshWizzardEmailSend()">
                            {{"WizzardPrevious" | localize}}
                          </button>
                          <button class="btn btn-primary" 
                            [disabled]="wizzardEmailSend.invoicePublicIdsSelected == undefined || wizzardEmailSend.invoicePublicIdsSelected.length == 0" 
                            (click)="sendEmailAndComplete()">
                            {{"SendEmailAndComplete" | localize}}
                          </button>
                        </div>
                        <i *ngIf="showLoadingSpinner" class="fa fa-spinner fa-spin" style="color: blue; font-size: 40px; margin-top: 20px;" aria-hidden="true"></i>
                      </div>
                  </aw-wizard-step>
                  <aw-wizard-step stepTitle="{{l('WizzardEndTitle')}}">
                    <br />
                    <div class="col-md-12 text-center">
                      <div>{{"WizzardEndOne" | localize}}</div>
                      <div>{{"WizzardEndTwo" | localize}}</div>
                      <br />
                      <button class="btn btn-primary" (click)="returnToInvoiceStatus()">
                        {{"WizzardReturnToInvoiceStatus" | localize}}
                      </button>
                    </div>
                  </aw-wizard-step>
                </aw-wizard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>