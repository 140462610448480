<div class="content d-flex flex-column flex-column-fluid">
    <div [class]="containerClass">
        <div class="card card-custom gutter-b">
            <div class="card-body">
                <button (click)="handleRefresh()" class="btn btn-primary">
                    <i class="la la-refresh"></i>
                    {{ 'Refresh' | localize }}
                </button>
                <div class="row align-items-center pt-5">
                    <!--<Primeng-TurboTable-Start>-->
                    <div
                        class="col-12 primeng-datatable-container"
                        [busyIf]="primengTableHelper.isLoading"
                    >
                        <p-table
                            #dataTable
                            (onLazyLoad)="getInvoiceRequests()"
                            [value]="primengTableHelper.records"
                            rows="{{
                                primengTableHelper.defaultRecordsCountPerPage
                            }}"
                            [paginator]="false"
                            [lazy]="true"
                            [responsive]="primengTableHelper.isResponsive"
                        >
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 130px">
                                        {{ 'Actions' | localize }}
                                    </th>
                                    <th>
                                        {{ 'RequestNumber' | localize }}
                                    </th>
                                    <th>
                                        {{ 'InvoiceCalculationRequestDate' | localize }}
                                    </th>
                                    <th>
                                        {{ 'Status' | localize }}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template
                                pTemplate="body"
                                let-record="$implicit"
                            >
                                <tr>
                                    <td
                                        style="width: 130px; text-align: center"
                                    >
                                        <div
                                            *ngIf="record.isDone"
                                            class="btn-group dropdown"
                                            dropdown
                                            container="body"
                                        >
                                            <button
                                                dropdownToggle
                                                class="dropdown-toggle btn btn-sm btn-primary"
                                            >
                                                <i class="fa fa-cog"></i
                                                ><span class="caret"></span>
                                                {{ 'Actions' | localize }}
                                            </button>
                                            <ul
                                                class="dropdown-menu"
                                                *dropdownMenu
                                            >
                                                <li>
                                                    <a
                                                        href="javascript:;"
                                                        class="dropdown-item"
                                                        (click)="
                                                            sendEmail(record)
                                                        "
                                                        >{{
                                                            'EmailSend'
                                                                | localize
                                                        }}</a
                                                    >
                                                </li>
                                            </ul>
                                        </div>
                                        <div *ngIf="!record.isDone">
                                            <button
                                                disabled
                                                class="dropdown-toggle btn btn-sm btn-primary"
                                            >
                                                <i class="fa fa-cog"></i
                                                ><span class="caret"></span>
                                                {{ 'Actions' | localize }}
                                            </button>
                                        </div>
                                    </td>
                                    <td>
                                        {{ record.invoiceCalculationRequestId }}
                                    </td>
                                    <td>
                                        {{ record.invoiceCalculationRequestDate }}
                                    </td>
                                    <td>
                                        <i
                                            *ngIf="record.isDone"
                                            class="fa fa-check"
                                            style="color: green"
                                        ></i>
                                        <i
                                            *ngIf="!record.isDone"
                                            class="fa fa-spinner fa-spin"
                                            style="color: blue"
                                        ></i>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div
                            class="primeng-no-data"
                            *ngIf="primengTableHelper.totalRecordsCount == 0"
                        >
                            {{ 'NoData' | localize }}
                        </div>
                        <div class="ui-table-footer">
                            {{
                                'TotalRecordsCount'
                                    | localize
                                        : primengTableHelper.totalRecordsCount
                            }}
                        </div>
                    </div>
                    <!--<Primeng-TurboTable-End>-->
                </div>
            </div>
        </div>
    </div>
</div>
