import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective, BsModalRef } from 'ngx-bootstrap/modal';
import { CandidatOutput } from '@shared/models/candidat/candidatOutput';
import { AddressHistoryOutput } from '@shared/models/addresshistory/addressHistoryOutput';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AddressHistoryInput } from '@shared/models/addresshistory/addressHistoryInput';
import { AddressHistoryType } from '@shared/models/addresshistory/addressHistoryType';
import { AddressInput } from '@shared/models/address/addressInput';
import { CandidatInput } from '@shared/models/candidat/candidatInput';
import { finalize } from 'rxjs/operators';
import { AddressHistoryService } from '@shared/services/addressHistory.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'UpdateAddressHistoryFormerComponent',
    templateUrl: './update-address-history-former.component.html'
})
export class UpdateAddressHistoryFormerComponent extends AppComponentBase implements OnInit {
    @ViewChild('updateFormerAddressModal') modal: ModalDirective;
    @Input() selectedCandidat: CandidatOutput;
    @Input() selectedAddressHistory: AddressHistoryOutput;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    publicId: string;
    saving = false;
    active = false;
    rangeDate: Date[];
    length: number;

    formGroupAddress = new UntypedFormGroup({
        startDateBetween: new UntypedFormControl(''),
        endDateBetween: new UntypedFormControl(''),
        type: new UntypedFormControl('', Validators.required),
        civicNo: new UntypedFormControl('', Validators.required),
        street: new UntypedFormControl('', Validators.required),
        app: new UntypedFormControl(''),
        city: new UntypedFormControl('', Validators.required),
        postalCode: new UntypedFormControl('', Validators.required),
        country: new UntypedFormControl(''),
        countryName: new UntypedFormControl('', Validators.required),
        state: new UntypedFormControl(''),
        stateName: new UntypedFormControl('', Validators.required)
    });

    constructor(private injector: Injector, private service: AddressHistoryService, private toastr: ToastrService, public bsModalRef: BsModalRef) {
        super(injector);
    }

    ngOnInit() {
        if (this.selectedAddressHistory !== null && this.selectedAddressHistory !== undefined) {
            this.initializeAddressToUpdate(this.selectedAddressHistory);
        }
    }

    initializeAddressToUpdate(selectedAddressHistory: AddressHistoryOutput) {
        if (selectedAddressHistory.from != null) {
            const currentFrom = new Date(selectedAddressHistory.from);
            this.formGroupAddress.controls['startDateBetween'].setValue(currentFrom);
        }

        if (selectedAddressHistory.to != null) {
            const currentTo = new Date(selectedAddressHistory.to);
            this.formGroupAddress.controls['endDateBetween'].setValue(currentTo);
        }

        this.formGroupAddress.controls['type'].setValue(selectedAddressHistory.type);
        this.formGroupAddress.controls['civicNo'].setValue(selectedAddressHistory.address.civicNo);
        this.formGroupAddress.controls['street'].setValue(selectedAddressHistory.address.street);
        this.formGroupAddress.controls['app'].setValue(selectedAddressHistory.address.app);
        this.formGroupAddress.controls['city'].setValue(selectedAddressHistory.address.city);

        this.formGroupAddress.controls['country'].setValue(selectedAddressHistory.address.country);
        this.formGroupAddress.controls['state'].setValue(selectedAddressHistory.address.state);
        this.formGroupAddress.controls['countryName'].setValue(selectedAddressHistory.address.country.countryName);
        this.formGroupAddress.controls['stateName'].setValue(selectedAddressHistory.address.state.stateName);
        this.formGroupAddress.controls['postalCode'].setValue(selectedAddressHistory.address.postalCode);
    }

    markAllControlsAsTouch() {
        this.formGroupAddress.controls['fromDate'].markAsTouched();
        this.formGroupAddress.controls['startDateBetween'].markAsTouched();
        this.formGroupAddress.controls['endDateBetween'].markAsTouched();
        this.formGroupAddress.controls['type'].markAsTouched();
        this.formGroupAddress.controls['civicNo'].markAsTouched();
        this.formGroupAddress.controls['street'].markAsTouched();
        this.formGroupAddress.controls['app'].markAsTouched();
        this.formGroupAddress.controls['city'].markAsTouched();
        this.formGroupAddress.controls['state'].markAsTouched();
        this.formGroupAddress.controls['postalCode'].markAsTouched();
        this.formGroupAddress.controls['country'].markAsTouched();
    }

    showForNotValidMessage() {
        const title = this.l('VerifyAllControlsTitle');
        const message = this.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }

    onShown(): void {
        document.getElementById('startDateBetween').focus();
    }

    show(): void {
        this.active = true;
        this.modal.show();
    }

    isFormInvalid(): boolean {
        return this.formGroupAddress.invalid;
    }

    returnAddressHistory(): AddressHistoryInput {
        const addressHistory: AddressHistoryInput = {
            id: this.selectedAddressHistory.id,
            publicId: this.selectedAddressHistory.publicId,
            type: AddressHistoryType.former,
            from: this.formGroupAddress.controls['startDateBetween'].value,
            to: this.formGroupAddress.controls['endDateBetween'].value,
            address: this.returnAddress(),
            addressId: this.selectedAddressHistory.address.id,
            candidat: this.returnCandidat(),
            candidatsId: this.selectedCandidat.id,
            creationTime: this.selectedAddressHistory.creationTime
        };
        return addressHistory;
    }

    returnAddress(): AddressInput {
        const address: AddressInput = {
            id: this.selectedAddressHistory.address.id,
            civicNo: this.formGroupAddress.controls['civicNo'].value,
            street: this.formGroupAddress.controls['street'].value,
            app: this.formGroupAddress.controls['app'].value,
            city: this.formGroupAddress.controls['city'].value,
            postalCode: this.formGroupAddress.controls['postalCode'].value,
            state: this.formGroupAddress.controls['state'].value,
            country: this.formGroupAddress.controls['country'].value
        };
        return address;
    }

    returnCandidat(): CandidatInput {
        const candidat = CandidatInput.fromOutput(this.selectedCandidat);

        return candidat;
    }

    close(): void {
        this.active = false;
        this.bsModalRef.hide();
    }

    async save(): Promise<void> {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }
        this.saving = true;

        const addressHistory = this.returnAddressHistory();
        await this.service
            .updateAddressHistory(addressHistory)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .toPromise()
            .then(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
            });
    }
}
