<div class="m-content">
    <div class="card card-custom">
        <div class="card-body">
            <div *ngIf="!showLoadingSpinner" class="d-flex flex-column">
                <div class="radio-option p-5 mb-3 d-flex align-items-center">
                    <label class="w-100">
                        <div class="d-flex align-items-center">
                            <input type="radio" [(ngModel)]="sendingMethod" 
                                   (change)="onChangeSendingMethod(sendingMethod)" 
                                   [value]="0" 
                                   name="sendingMethodSelected">
                            <span class="fw-bolder ms-3">{{"TraditionnalSending" | localize}}</span>
                        </div>
                        <div class="option-info">
                            {{"TraditionnalSendingDetails" | localize}}
                        </div>
                    </label>
                </div>
                <div class="radio-option p-5 d-flex align-items-center" *ngIf="showEzSignMethod">
                    <label class="w-100">
                        <div class="d-flex align-items-center">
                            <input type="radio" [(ngModel)]="sendingMethod" 
                                (change)="onChangeSendingMethod(sendingMethod)" 
                                [value]="1" 
                                name="sendingMethodSelected">
                            <span class="fw-bolder ms-3">{{"EzSignSending" | localize}}</span>
                        </div>
                        <div class="option-info">
                            {{"EzSignSendingDetails" | localize}}
                        </div>
                    </label>
                </div>
            </div>
            <i *ngIf="showLoadingSpinner" class="fa fa-spinner fa-spin" style="color: var(--color-primary); font-size: 40px; margin-top: 20px;" aria-hidden="true"></i>
        </div>
    </div>
</div>