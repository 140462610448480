import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as GuidesActions from '../../actions/guides/actions';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { GuidesService } from '@shared/services/guides.service';
import { GuideOutput } from '@shared/models/guides/guide-output';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { GuideInput } from '@shared/models/guides/guides-input';

@Injectable()
export class GuidesEffects {

    public getAll$ = createEffect(() => this.actions$.pipe(
        ofType(GuidesActions.GUIDES_ACTION_GET_ALL),
        exhaustMap(() => {
            const lang = this.appLocalizationService.getCurrentLanguage();
            return this.service.getAll(lang).pipe(
                map((result: GuideOutput[]) => GuidesActions.getAllSuccess({ payload: result })),
                catchError(() => of(GuidesActions.updateRequestEvent({
                    value: {
                        action: GuidesActions.GUIDES_ACTION_GET_ALL,
                        result: 'fail'
                    } })))
            );
        }
        ),
    ));

    public getAllActive$ = createEffect(() => this.actions$.pipe(
        ofType(GuidesActions.GUIDES_ACTION_GET_ALL_ACTIVE),
        exhaustMap(() => {
            const lang = this.appLocalizationService.getCurrentLanguage();
            return this.service.getAllActive(lang).pipe(
                map((result: GuideOutput[]) => GuidesActions.getAllSuccess({ payload: result })),
                catchError(() => of(GuidesActions.updateRequestEvent({
                    value: {
                        action: GuidesActions.GUIDES_ACTION_GET_ALL_ACTIVE,
                        result: 'fail'
                    } })))
            );
        }
        ),
    ));
    public getById$ = createEffect(() => this.actions$.pipe(
        ofType(GuidesActions.GUIDES_ACTION_GET_BY_ID),
        exhaustMap(({id}: {id: number}) => this.service.getById(id).pipe(
            map((result: GuideOutput) => GuidesActions.getByIdSuccess({ payload: result })),
            catchError(() => of(GuidesActions.updateRequestEvent({
                value: {
                    action: GuidesActions.GUIDES_ACTION_SELECT,
                    result: 'fail'
                } })))
        ))
    ));

    public add$ = createEffect(() => this.actions$.pipe(
        ofType(GuidesActions.GUIDES_ACTION_ADD),
        exhaustMap(({payload}: {payload: GuideInput}) => this.service.create(payload).pipe(
            map((result: GuideOutput) => GuidesActions.addSuccess({ payload: result })),
            catchError(() => of(GuidesActions.updateRequestEvent({
                value: {
                    action: GuidesActions.GUIDES_ACTION_ADD,
                    result: 'fail'
                } })))
        ))
    ));

    public update$ = createEffect(() => this.actions$.pipe(
        ofType(GuidesActions.GUIDES_ACTION_UPDATE),
        exhaustMap(({payload}: {payload: GuideOutput}) => this.service.update(payload).pipe(
            map((result: GuideOutput) => GuidesActions.updateSuccess({ payload: result })),
            catchError(() => of(GuidesActions.updateRequestEvent({
                value: {
                    action: GuidesActions.GUIDES_ACTION_UPDATE,
                    result: 'fail'
                } })))
        )
        )
    ));

    public delete$ = createEffect(() => this.actions$.pipe(
        ofType(GuidesActions.GUIDES_ACTION_REMOVE),
        exhaustMap(({payload}: {payload: GuideOutput}) => this.service.delete(payload).pipe(
            map(() => GuidesActions.removeSuccess({ id: payload.id })),
            catchError(() => of(GuidesActions.updateRequestEvent({
                value: {
                    action: GuidesActions.GUIDES_ACTION_REMOVE,
                    result: 'fail'
                } })))
        )
        ),

    ));

    constructor(private actions$: Actions,
        private store: Store,
        private appLocalizationService: AppLocalizationService,
        private service: GuidesService) { }

}
