import { Component, OnInit } from '@angular/core';
import { RequestVerificationStatusLegend } from '@shared/models/requestVerification/requestVerificationStatusLegend';

@Component({
    selector: 'app-request-table-legend',
    templateUrl: './request-table-legend.component.html',
    styleUrls: ['./request-table-legend.component.scss']
})
export class RequestTableLegendComponent implements OnInit {
    statusList: any[] = [];
    
    ngOnInit(): void {
        this.statusList = [
            ...Object.getOwnPropertyNames(RequestVerificationStatusLegend)
            .filter(p => RequestVerificationStatusLegend[p].statusIcon != null)
            .map(p => RequestVerificationStatusLegend[p])
        ];
    }
}
