import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManagementGuidesRoutingModule } from './guides-management-routing.module';
import { RouterModule } from '@angular/router';
import { GuidesManagementComponent } from './guides-management.component';
import { GuidesService } from '@shared/services/guides.service';


@NgModule({
    declarations: [GuidesManagementComponent],
    imports: [ CommonModule, RouterModule, ManagementGuidesRoutingModule ],
    exports: [ RouterModule ],
    providers: [GuidesService]
})
export class GuidesManagementModule { }
