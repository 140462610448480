import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment as env } from 'environments/environment';
import { GuideOutput } from '@shared/models/guides/guide-output';
import { GuideInput } from '@shared/models/guides/guides-input';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class GuidesService {
    private http: HttpClient;
    private baseUrl: string;

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = `${env.api}/api/guides`;
    }

    public getAll(lang: number): Observable<GuideOutput[]> {
        let updatedRoute = `${this.baseUrl}?languageId=${lang.toString()}`;
        if (!this.baseUrl) {
            updatedRoute = (env.api + updatedRoute).replace(/[?&]$/, '');
        }
        return this.http.get<{ result: GuideOutput[] }>(updatedRoute).pipe(map((response) => response.result));
    }

    public getAllActive(lang: number): Observable<GuideOutput[]> {
        const baseRoute = `${this.baseUrl}/active`;
        let updatedRoute = `${baseRoute}?languageId=${lang.toString()}`;
        if (!this.baseUrl) {
            updatedRoute = (env.api + updatedRoute).replace(/[?&]$/, '');
        }
        return this.http.get<{ result: GuideOutput[] }>(updatedRoute).pipe(map((response) => response.result));
    }

    public getById(id: number): Observable<GuideOutput> {
        let route = `${this.baseUrl}/${id}`;
        if (!this.baseUrl) {
            route = (env.api + route).replace(/[?&]$/, '');
        }
        return this.http.get<{ result: GuideOutput }>(route).pipe(map((response) => response.result));
    }

    public create(payload: GuideInput): Observable<GuideOutput> {
        let route = this.baseUrl;
        if (!this.baseUrl) {
            route = (env.api + route).replace(/[?&]$/, '');
        }
        return this.http.post<GuideOutput>(route, payload);
    }

    public update(payload: GuideInput): Observable<GuideOutput> {
        let route = this.baseUrl;
        if (!this.baseUrl) {
            route = (env.api + route).replace(/[?&]$/, '');
        }
        return this.http.put<GuideOutput>(route, payload);
    }

    public delete(payload: GuideInput): Observable<GuideOutput> {
        let route = `${this.baseUrl}/delete`;
        if (!this.baseUrl) {
            route = (env.api + route).replace(/[?&]$/, '');
        }
        return this.http.post<GuideOutput>(route, payload);
    }
}
