<div class="d-flex flex-wrap">
  <div *ngFor="let data of dataList" class="text-center me-5 mt-1" data-toggle="tooltip" data-placement="top" [ngClass]="{'position-relative': data.express}"
    title="{{data.status}} | {{data.type}}">
    <span>
      <i [style]="{ color: data.color }" class="{{data.statusIcon}} fa-2x"> </i>
    </span>
    <ng-container *ngTemplateOutlet="data.express ? expressTpl : null"></ng-container>
    <div class="verificationCode">
      <span [style]="{ color: data.color }">
        {{data.code}}

      </span>
    </div>
  </div>
</div>

<ng-template #expressTpl let-data>
    <span class="express-badge">Express</span>
</ng-template>
