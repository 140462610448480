import { Component, OnInit, ViewChild, Injector, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AgGridAngular } from '@ag-grid-community/angular';
import { Router } from '@angular/router';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { GridOptions, ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { GridBtnUpdateComponent } from '@app/shared/grid/grid-btn-update/grid-btn-update.component';
import { AppConsts } from '@shared/AppConsts';
import { OrganizationService } from '@shared/services/organization.service';
import { organizationOutput } from '@shared/models/organization/organizationOutput';
import { organizationStatus } from '@shared/models/organization/organizationStatus';
import { ToastrService } from 'ngx-toastr';
import { Extensions } from '@shared/utils/extensions';
import { OrganizationListOutputDetails } from '@shared/models/organization/OrganizationListOutputDetails';
import { OrganizationRequestVolumeRange } from '@shared/models/organization/OrganizationRequestVolumeRange';
import { OrganizationDatasourceService } from '@shared/services/organization.datasource.service';
import { Observable } from 'rxjs';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { OrganizationStatus } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'list-search',
    templateUrl: './list-search.component.html',
    styleUrls: ['./list-search.component.scss'],
    providers: [DatePipe]
})
export class ListSearchComponent extends AppComponentBase implements OnInit {
    @ViewChild('agGrid', { static: false }) agGrid: AgGridAngular;
    @Input() organizationPublicId: string | undefined;
    columnDefs;
    public defaultColDef;
    public gridOptions: GridOptions;

    paginationNumberFormatter;
    paginationPageSize = 20;

    filterText = sessionStorage.getItem('organization-search-text') ?? '';
    selectedOrganization: OrganizationListOutputDetails;
    rowSelection: string;
    rowMultiSelectWithClick: boolean;
    selectedOrganizationRow: any;
    canUpdate: boolean;
    organizationListOutputDetails: OrganizationListOutputDetails[] = [];
    private gridApi;

    constructor(
        private injector: Injector,
        private service: OrganizationService,
        private _router: Router,
        private toastr: ToastrService,
        private _appLocalizationService: AppLocalizationService,
        private organizationDatasourceService: OrganizationDatasourceService,
        private datePipe: DatePipe,
        private _dateTimeService: DateTimeService
    ) {
        super(injector);
        this.rowSelection = this.isGranted('Pages.Management.Organizations.Details') ? 'single' : null;
        this.rowMultiSelectWithClick = false;
        this.canUpdate = this.isGranted('Pages.Management.Organizations.Update');

        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };

        this.gridOptions = <GridOptions>{
            rowModelType: 'serverSide',
            animateRows: true,
            cacheBlockSize: this.paginationPageSize,
            groupSuppressAutoColumn: true,
            blockLoadDebounceMillis: 100
        };
    }

    ngOnInit() {
        this.organizationDatasourceService.setParentOrganizationPublicId(this.organizationPublicId);
        this.tryToRedirectToDetails();

        const actif = this._appLocalizationService.l('Actif');
        const inActif = this._appLocalizationService.l('Inactif');
        const waitingForApprobation = this._appLocalizationService.l('WaitingForApprobation');
        const declined = this._appLocalizationService.l('Declined');

        this.columnDefs = [
            {
                headerName: this._appLocalizationService.l('OrganizationName'),
                field: 'organizationName',
                sortable: true,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                },
                resizable: true,
                comparator: Extensions.customComparator
            },
            {
                headerName: this._appLocalizationService.l('ClientId'),
                field: 'clientId',
                sortable: true,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                },
                width: 150,
                resizable: true
            },
            {
                headerName: this._appLocalizationService.l('FirstContact'),
                field: 'firstContact',
                sortable: true,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                },
                width: 200,
                resizable: true
            },
            {
                headerName: this._appLocalizationService.l('FirstContactEmail'),
                field: 'firstContactEmail',
                sortable: true,
                filter: 'agTextColumnFilter',
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                },
                width: 300,
                resizable: true
            },
            {
                headerName: this._appLocalizationService.l('CreationTime'),
                field: 'creationTime',
                sortable: true,
                filter: 'agDateColumnFilter',
                filterParams: {
                    filterOptions: ['inRange'],
                    suppressAndOrCondition: true
                },
                valueFormatter: this.dateFormater.bind(this),
                width: 300,
                resizable: true
            },
            {
                headerName: this._appLocalizationService.l('Status'),
                sortable: true,
                width: 150,
                valueFormatter: this.statusValueFormatter(actif, inActif, waitingForApprobation, declined),
                field: 'status',
                filter: 'agSetColumnFilter',
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true,
                    values: [actif, inActif, waitingForApprobation, declined]
                },
                resizable: true
            },
            {
                headerName: this._appLocalizationService.l('LastRequestCreationTime'),
                field: 'lastRequestCreationDate',
                sortable: true,
                filter: 'agDateColumnFilter',
                filterParams: {
                    filterOptions: ['inRange'],
                    suppressAndOrCondition: true
                },
                sort: 'desc',
                valueFormatter: this.dateFormater.bind(this),
                width: 300,
                resizable: true,
                hide: !this.isGranted('Pages.Management.Organizations.CanSeePrivateInformation')
            },
            {
                headerName: this._appLocalizationService.l('HasActiveClientUser'),
                sortable: true,
                width: 150,
                filter: 'agSetColumnFilter',
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true,
                    values: [this._appLocalizationService.l('Yes'), this._appLocalizationService.l('No')]
                },
                valueFormatter: this.hasActiveClientUserValueFormatter.bind(this),
                field: 'hasActiveClientUser',
                resizable: true
            },
            {
                headerName: this._appLocalizationService.l('VolumeOfRequestPerYear'),
                hide: true,
                field: 'volumeOfRequestPerYear',
                width: 150,
                valueFormatter: this.volumeOfRequestFormatter.bind(this)
            },
            {
                headerName: this._appLocalizationService.l('BusinessActivity'),
                hide: true,
                field: 'lineOfBusiness',
                width: 150
            },
            {
                headerName: this._appLocalizationService.l('BillingMethod'),
                hide: true,
                field: 'billingMethod',
                width: 150
            },
            {
                headerName: this._appLocalizationService.l('PurchaseOrder'),
                hide: true,
                field: 'purchaseOrder',
                width: 150
            },
            {
                headerName: this._appLocalizationService.l('BillingContact'),
                hide: true,
                field: 'billingContact',
                width: 150
            },
            {
                headerName: this._appLocalizationService.l('BillingEmail'),
                hide: true,
                field: 'billingEmail',
                width: 150
            },
            {
                headerName: '',
                cellRendererFramework: GridBtnUpdateComponent,
                cellRendererParams: { action: this.updateOrganization },
                hide: !this.canUpdate,
                width: 150,
                resizable: false
            },
            {
                headerName: '',
                field: 'globalFilter',
                sortable: false,
                filter: 'agTextColumnFilter',
                filterParams: { filterOption: ['contains'] },
                flex: 1,
                hide: true,
                lockVisible: true
            }
        ];

        this.defaultColDef = { resizable: true };
    }

    volumeOfRequestFormatter(params: ValueFormatterParams) {
        return OrganizationRequestVolumeRange[params.value];
    }

    customComparator(valueA, valueB) {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    }

    export(): Observable<any> {
        return this.service.export(this.gridApi.getSortModel(), this.gridApi.getFilterModel());
    }

    onGridReady(params) {
        this.gridApi = params.api;

        // register the datasource with the grid
        this.gridApi.setServerSideDatasource(this.organizationDatasourceService);
        this.onFirstDataRendered();

        if (screen.availWidth > 414) {
            this.gridApi.sizeColumnsToFit();
        }

        if (this.filterText !== '') {
            this.search();
        }
    }

    onPageSizeChanged(e) {
        this.gridApi.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    tryToRedirectToDetails(): void {
        if (this.organizationPublicId) {
            return;
        }

        //If the current user has only one organization and can't create more organizations, we go to their organization details page.
        this.service
            .filter(null, 0, 2, null, null)
            .toPromise()
            .then((response) => {
                if (!this.isGranted('Pages.Management.Organizations.Create')) {
                    if (response.result.count === 1) {
                        this.goToOrganizationDetails(response.result.results[0].publicId);
                    }
                }
            });
    }

    toEnum(params: ValueFormatterParams): string {
        return this._appLocalizationService.l(organizationStatus[params.value]);
    }

    dateFormater(params: ValueFormatterParams) {
        if (!params.value) {
            return '';
        }
        return this.datePipe.transform(this._dateTimeService.toUtcDate(params.value).toLocal().toString(), AppConsts.dateTimeFormat);
    }

    hasActiveClientUserValueFormatter(params) {
        let valueString;
        switch (params.value) {
            case false:
                valueString = 'No';
                break;
            case true:
                valueString = 'Yes';
                break;
        }
        return this._appLocalizationService.l(valueString);
    }

    onSelectionChanged(): void {
        this.selectedOrganizationRow = this.agGrid.api.getSelectedRows()[0] as organizationOutput;
        this.goToOrganizationDetails(this.selectedOrganizationRow.publicId);
    }

    goToOrganizationDetails(publicId: string) {
        if (this.isGranted('Pages.Management.Organizations.Details')) {
            this._router
                .navigateByUrl('app/organizations-details', {
                    skipLocationChange: true
                })
                .then(() => this._router.navigate(['/organizations-details', publicId]));
        } else {
            this.showAccessError();
        }
    }

    showAccessError() {
        const title = this._appLocalizationService.l('AccessIssue');
        const message = this._appLocalizationService.l('NotAccess');
        this.toastr.error(message, title);
    }

    refresh() {
        this.filterText = '';
        this.search();
    }

    search() {
        const filterGlobal = this.agGrid.gridOptions.api.getFilterInstance('globalFilter');
        filterGlobal.setModel({
            filterType: 'text',
            type: 'contains',
            filter: this.filterText
        });
        this.agGrid.gridOptions.api.onFilterChanged();
        sessionStorage.setItem('organization-search-text', this.filterText);
    }

    updateOrganization = (organization: organizationOutput) => {
        this._router.navigate(['/organizations-update', organization.publicId]);
    };

    onSortChanged(params) {
        sessionStorage.setItem('organizations-sortModel', JSON.stringify(params.api.getSortModel()));
    }

    onFilterChanged(params) {
        sessionStorage.setItem('organizations-filterModel', JSON.stringify(params.api.getFilterModel()));
    }

    onFirstDataRendered() {
        const sortModel = JSON.parse(sessionStorage.getItem('organizations-sortModel'));
        const filterModel = JSON.parse(sessionStorage.getItem('organizations-filterModel'));
        if (sortModel) {
            this.gridApi.setSortModel(sortModel);
        }
        if (filterModel) {
            this.gridApi.setFilterModel(filterModel);
        }
    }

    resetFilters() {
        sessionStorage.removeItem('organizations-filterModel');
        this.gridApi.setFilterModel(null);
    }

    resetSort() {
        // reset to default as defined in colDefs
        this.gridApi.setSortModel([{ colId: 'creationTime', sort: 'desc' }]);
    }

    private statusValueFormatter(actif: string, inActif: string, waitingForApprobation: string, declined: string) {
        return function (params: ValueFormatterParams) {
            switch (params.value) {
                case OrganizationStatus.Actif:
                    return actif;
                case OrganizationStatus.Inactif:
                    return inActif;
                case OrganizationStatus.WaitingForApprobation:
                    return waitingForApprobation;
                case OrganizationStatus.Declined:
                    return declined;
                default:
                    return params.value;
            }
        };
    }
}