<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid">
        <form [formGroup]="updateVerificationForm" autocomplete="off">
            <sub-header [title]="'Verifications' | localize" [description]="'VerificationUpdate' | localize">
                <div role="actions">
                    <button (click)="returnToDetails()" class="btn btn-secondary">
                        <i class="fas fa-times"></i>
                        {{'Cancel' | localize}}</button>
                    <button class="btn btn-primary" (click)="onUpdate()" [disabled]="isVerificationUpdated"
                        data-test-id="verif-update-save-btn">
                        <i class="la la-floppy-o"></i>
                        {{"Save" | localize}}
                    </button>
                </div>
            </sub-header>
            <div [class]="containerClass">
                <div class="row" class="breadCrumbContainer">
                    <div class="col pl-0 pr-0">
                        <p-breadcrumb [model]="items"></p-breadcrumb>
                    </div>
                </div>
                <div class="card card-custom mb-10">
                    <div class="card-body">
                        <div class="form-group">
                            <label for="status">{{"VerificationStatus" | localize}}</label>
                            <select id="status" formControlName="status" type="text" name="status" class="form-control"
                                maxlength="256">
                                <option value="0">{{"Actif" | localize}}</option>
                                <option value="1">{{"Inactif" | localize}}</option>
                            </select>
                            <validation-messages
                                [formCtrl]="updateVerificationForm.get('status')"></validation-messages>
                        </div>
                        <div class="form-group">
                            <label for="code">{{"VerificationCode" | localize}}</label>
                            <input id="code" formControlName="code" type="text" name="code" class="form-control"
                                maxlength="256">
                            <validation-messages [formCtrl]="updateVerificationForm.get('code')"></validation-messages>
                        </div>
                        <div class="form-group">
                            <label for="type">{{"VerificationType" | localize}} </label>
                            <input id="type" formControlName="type" type="text" name="type" class="form-control"
                                maxlength="256">
                            <validation-messages [formCtrl]="updateVerificationForm.get('type')"></validation-messages>
                        </div>
                        <div class="form-group">
                            <label for="price">{{"VerificationPrice" | localize}}</label>
                            <input id="price" formControlName="price" type="text" name="price" class="form-control"
                                maxlength="256" data-test-id="verif-update-price-input">
                            <validation-messages [formCtrl]="updateVerificationForm.get('price')"></validation-messages>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-6 col-xs-12">
                                    <div class="d-flex align-items-center"><input id="canBeExpress"
                                            formControlName="canBeExpress" type="checkbox" name="canBeExpress"
                                            class="form-check-input me-2"
                                            data-test-id="verif-update-canBeExpress-input"><label for="canBeExpress"
                                            class="mb-0">{{"VerificationExpressService" | localize}}
                                        </label>
                                        <validation-messages
                                            [formCtrl]="updateVerificationForm.get('canBeExpress')"></validation-messages>
                                    </div>
                                </div>
                                <div class="col-6 col-xs-12" *ngIf="updateVerificationForm.get('canBeExpress').value">
                                    <label for="expressPrice">{{"VerificationExpressServicePrice" | localize}}</label>
                                    <input id="expressPrice" formControlName="expressPrice" type="number" min="0"
                                        step=".5" name="expressPrice" class="form-control"
                                        data-test-id="verif-update-expressPrice-input">
                                    <validation-messages
                                        [formCtrl]="updateVerificationForm.get('expressPrice')"></validation-messages>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <verificationDescription-update [descriptionsList]="descriptionsList" [verificationId]="result.id" #verificationDescription></verificationDescription-update> -->
                </div>
                <div class="card card-custom" *ngIf="isGranted('Pages.Management.Files')">
                    <div class="card-body">
                        <div class="row align-items-center mb-5">
                            <div class="mr-auto col-auto">
                                <h5>{{ 'File' | localize }}</h5>
                            </div>
                            <div class="col mt-3 mt-md-0">
                                <div class="text-end ">
                                    <a href="javascript:;" (click)="addFile()" class="btn btn-primary">
                                        <i class="fa fa-plus"></i>
                                        {{'Add' | localize}}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <grid-pagination class="grid-pagination"
                            (sizeValue)="onPageSizeChanged($event)"></grid-pagination>
                        <ag-grid-angular localizeText #agGrid class="ag-theme-material gardium-small-grid"
                            [rowData]="files" [paginationPageSize]="paginationPageSize" [pagination]="true"
                            [columnDefs]="columnDefs" [getRowNodeId]="getRowNodeId"
                            [paginationNumberFormatter]="paginationNumberFormatter" [suppressCellSelection]="true"
                            (gridReady)="onGridReady($event)">
                        </ag-grid-angular>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
