import { Injector, Pipe, PipeTransform } from '@angular/core';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';

@Pipe({
    name: 'permission',
})
export class PermissionPipe implements PipeTransform {
    _appAuthService: AppAuthService;

    constructor(injector: Injector) {
        this._appAuthService = injector.get(AppAuthService);
    }

    transform(permission: string): boolean {
        return this._appAuthService.hasPermission(permission);
    }
}
