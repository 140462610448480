import { Injectable, OnDestroy } from '@angular/core';
import { IServerSideDatasource, IServerSideGetRowsParams } from '@ag-grid-enterprise/all-modules';
import { OrganizationService } from './organization.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { overallAdjustLocalDateRangeToUtcDateTimeRange } from '@shared/utils/date-time/adjust-local-date-range-to-utc-date-time-range';

@Injectable()
export class UserOrganizationDatasourceService implements IServerSideDatasource, OnDestroy {
    userId: number;
    public dataLoaded$: Observable<boolean>;
    public dataLoading$: Observable<boolean>;
    private dataLoadedSubject: Subject<boolean> = new Subject();
    private dataLoadingSubject: Subject<boolean> = new Subject();
    private destroyed: Subject<void> = new Subject<void>();

    constructor(private organizationService: OrganizationService) {
        this.dataLoaded$ = this.dataLoadedSubject.asObservable().pipe(takeUntil(this.destroyed));
        this.dataLoading$ = this.dataLoadingSubject.asObservable().pipe(takeUntil(this.destroyed));
    }

    setUserId(userId: number): void {
        this.userId = userId;
    }

    getRows(params: IServerSideGetRowsParams): void {
        this.dataLoadingSubject.next(true);
        const isTopLevelRequest = !params.request.groupKeys.length;
        const parentOrganizationId = isTopLevelRequest ? null : params.request.groupKeys[0];
        const filterModel = overallAdjustLocalDateRangeToUtcDateTimeRange(params.request.filterModel);

        this.organizationService
            .getUsersOrganizationsList(parentOrganizationId, params.request.startRow, params.request.endRow, params.request.sortModel, filterModel, this.userId)
            .toPromise()
            .then(
                (value) => {
                    params.successCallback(value.result.results, value.result.count);
                    this.dataLoadedSubject.next(true);
                },
                (_error) => {
                    params.failCallback();
                }
            );
    }

    destroy?(): void {}

    ngOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
