import { Component, ViewChild, Input, Output, EventEmitter, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { ProfessionnalAccreditationInput } from '@shared/models/CandidatDetails/professionnal-accreditations/professionnalAccreditationInput';
import { finalize } from 'rxjs/operators';
import { ProfessionnalAccreditationService } from '@shared/services/professionnalAccreditation.service';
import { ToastrService } from 'ngx-toastr';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';

@Component({
    selector: 'professionnal-accreditations-add',
    templateUrl: './professionnal-accreditations-add.component.html'
})
export class ProfessionnalAccreditationsAddComponent extends AppComponentBase {
    @ViewChild('accreditationAddModal') accreditationAddModal: ModalDirective;
    @Input() candidateId: number;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;

    formGroup = new UntypedFormGroup({
        year: new UntypedFormControl('', Validators.required),
        institution: new UntypedFormControl('', Validators.required),
        title: new UntypedFormControl('', Validators.required),
        location: new UntypedFormControl('', Validators.required)
    });

    constructor(
        private injector: Injector,
        private _professionnalAccreditationService: ProfessionnalAccreditationService,
        private toastr: ToastrService,
        private _appLocalizationService: AppLocalizationService
    ) {
        super(injector);
    }

    show(): void {
        this.active = true;
        this.accreditationAddModal.show();
    }

    isFormInvalid(): boolean {
        return this.formGroup.invalid;
    }

    markAllControlsAsTouch() {
        this.formGroup.controls['year'].markAsTouched();
        this.formGroup.controls['institution'].markAsTouched();
        this.formGroup.controls['title'].markAsTouched();
        this.formGroup.controls['location'].markAsTouched();
    }

    showForNotValidMessage() {
        const title = this._appLocalizationService.l('VerifyAllControlsTitle');
        const message = this._appLocalizationService.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }

    save(): Promise<void> {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }
        this.saving = true;

        const accreditation: ProfessionnalAccreditationInput = {
            id: 0,
            publicId: '',
            year: this.formGroup.controls['year'].value,
            location: this.formGroup.controls['location'].value,
            instutition: this.formGroup.controls['institution'].value,
            title: this.formGroup.controls['title'].value,
            requestId: null,
            candidatId: this.candidateId,
            creationTime: new Date()
        };

        this._professionnalAccreditationService
            .addProfessionnalAccreditation(accreditation)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe({
                next: (value) => {
                    this.notify.info(this.l('SavedSuccessfully'));
                    this.close();
                    this.modalSave.emit(null);
                },
                error: (err) => {
                    this.notify.error('Server error: ' + err.error.result);
                }
            });
    }

    close(): void {
        this.active = false;
        this.formGroup.reset();
        this.accreditationAddModal.hide();
    }
}
