import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Features } from '../../state/state';
import { RequestEvent } from '@shared/models/request-event/request-event.model';
import { VerificationsState } from '@app/store/state/verifications/verifications.state';
import { VerificationListOutput } from '@shared/models/verification/verificationListOutput';

export const selectVerifications = createFeatureSelector<VerificationsState>(Features.Verifications);

export const selectItems = (sortFn?: (a, b) => number) => createSelector(selectVerifications, (state: VerificationsState): VerificationListOutput[] => {
    const updatedItems = [ ...state.items ];
    if (!updatedItems.length) {
        return [];
    }
    if (sortFn) {
        updatedItems.sort(sortFn);
    }
    return updatedItems;
});
export const selectFilteredItems = (filterFn?: (filterValue) => VerificationListOutput[], sortFn?: (a, b) => number) => createSelector(selectVerifications, (state: VerificationsState): VerificationListOutput[] => {
    const updatedFilteredItems = [ ...state.filteredItems ];
    if (!updatedFilteredItems.length) {
        return [];
    }
    if (sortFn) {
        updatedFilteredItems.sort(sortFn);
    }
    if (filterFn) {
        return updatedFilteredItems.filter(filterFn);
    }
    return updatedFilteredItems;
});
export const selectSelectedItem = createSelector(selectVerifications, (state: VerificationsState): VerificationListOutput => state.selectedItem);
export const selectRequestEvent = createSelector(selectVerifications, (state: VerificationsState): RequestEvent => state.event);
export const selectRequestEventPending = createSelector(selectRequestEvent, (event: RequestEvent): boolean => event?.result === 'pending');
