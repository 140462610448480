import { ErrorHandler, Injectable } from '@angular/core';
import { LoggingService } from './logging.service';
import { DialogService } from './dialog-service';
import LocalizationService from './localization.service';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { handleAuth0LoginRequiredError } from '@shared/helpers/AuthCheckerHelper';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

    constructor(
        private loggingService: LoggingService,
        private authService: AppAuthService,
        private dialogueService: DialogService,
        private localization: LocalizationService) {
        super();
    }

    handleError(error: Error) {
        this.loggingService.logException(error);
        if (error.message === 'Login required') {
            handleAuth0LoginRequiredError(this.localization, this.dialogueService, this.authService);

        } else 
        {
            super.handleError(error);
        }
    }
}