import { Directive} from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AgGridAngular } from '@ag-grid-community/angular';
import { AppLocalizationService } from '../common/localization/app-localization.service';


@Directive({
    selector: '[localizeText]'
})
export class GridLocalizationDirective {
    constructor(private localizationService: AppLocalizationService, private agGrid: AgGridAngular) {
        this.agGrid.gridOptions = {localeTextFunc: this.localizeText.bind(this)};
    }

    localizeText(key: string, defaultValue: string) {
        const gridKey = 'grid.' + key;
        const translatedText = this.localizationService.l(gridKey, AppConsts.localization.defaultLocalizationSourceName);
        return translatedText === gridKey ? defaultValue : translatedText;
    }
}
