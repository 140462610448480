<div bsModal #FAQDescriptionCreateEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="FAQDescriptionCreateEditModal" aria-hidden="true" [config]="{backdrop: 'static', keyboard: !saving}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <form [formGroup]="formGroup" #descriptionFAQCreateEditForm="ngForm" novalidate (ngSubmit)="save()"
        autocomplete="off">
        <div class="modal-header">
          <h4 class="modal-title pull-left">
            <h5 class="gardium-title">{{(editing? "EditInformations" : "NewQuestion") | localize}}</h5>
          </h4>
        </div>
        <div class="modal-body">
          <div class="form-group edition-list row">
            <div class="col">
              <label for="language">{{"SelectLanguage" | localize}} <span class="asterix">*</span></label>
              <select name="language" class="form-control" id="language" formControlName="language">
                <option [ngValue]="null">{{"Select" | localize}}</option>
                <option *ngFor="let lang of languages" [ngValue]="lang.id">{{lang.displayName}}</option>
              </select>
              <validation-messages [formCtrl]="formGroup.get('language')"></validation-messages>
            </div>
            <div class="col">
              <label for="category">{{"FAQCategoryLabel" | localize}} <span class="asterix">*</span></label>
              <select name="category" class="form-control" id="category" formControlName="category">
                <option [ngValue]="null">{{"Select" | localize}}</option>
                <option *ngFor="let category of faqCategories" [ngValue]="category.id">{{category.name}}</option>
              </select>
              <validation-messages [formCtrl]="formGroup.get('category')"></validation-messages>
            </div>
          </div>
          <div class="form-group edition-list">
            <label for="translation">{{"TranslationOf" | localize}}</label>
            <select name="translation" class="form-control" id="translation" formControlName="translation">
              <option [ngValue]="null">{{"None" | localize}}</option>
              <option *ngFor="let question of untranslatedQuestions" [ngValue]="question.id">{{question.question}}</option>
            </select>
          </div>
          <div class="form-group edition-list">
            <label for="question">{{"FAQQuestionLabel" | localize}} <span class="asterix">*</span></label>
            <input name="question" class="form-control m-input" type="text" id="question" formControlName="question"/>
          </div>
          <div class="form-group">
            <ckeditor *ngIf="editorConfig" [editor]="Editor" [config]="editorConfig" name="answer" id="answer" formControlName="answer"></ckeditor>
            <validation-messages [formCtrl]="formGroup.get('answer')"></validation-messages>
          </div>
          <div class="form-group edition-list row">
            <div class="col">
              <label for="status">{{"Status" | localize}} <span class="asterix">*</span></label>
              <select name="status" class="form-control" id="status" formControlName="status">
                <option [ngValue]="1">{{"Actif" | localize}}</option> 
                <option [ngValue]="0">{{"Inactif" | localize}}</option>
              </select>
              <validation-messages [formCtrl]="formGroup.get('status')"></validation-messages>
            </div>
            <div class="col d-flex flex-column">
              <ng-container *ngIf="isEnglish">
                <label for="onDashboard">{{"ShowOnDashboard" | localize}} <span class="asterix">*</span> ({{numberOnDashboard + '/' + maxOnDashboard}})</label>
                <select name="onDashboard" class="form-control" id="onDashboard" formControlName="onDashboard">
                  <option [ngValue]="1">{{"Yes" | localize}}</option> 
                  <option [ngValue]="0">{{"No" | localize}}</option>
                </select>
                <validation-messages [formCtrl]="formGroup.get('onDashboard')"></validation-messages>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default"
            (click)="close()">{{"Cancel" | localize}}</button>
          <button type="submit" class="btn btn-primary" id="savingButton"
            [disabled]="!descriptionFAQCreateEditForm.form.valid" [busyText]="l('SavingWithThreeDot')" [buttonBusy]="saving"
            (onclick)="save()"><i class="fa fa-save"></i>
            <span>{{"Save" | localize}}</span></button>
        </div>
      </form>
    </div>
  </div>
</div>