import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import './polyfills.ts';
import { RootModule } from './root.module';
import { LicenseManager, ModuleRegistry, AllModules } from '@ag-grid-enterprise/all-modules';

LicenseManager.setLicenseKey('Uzinakod__Gardium_1Devs28_May_2020__MTU5MDYyMDQwMDAwMA==377466ff4196cf1162afd1d358a59db8');

if (environment.production) {
    enableProdMode();
} else {
    document.title += ' - ' + environment.name.toUpperCase();
}

ModuleRegistry.registerModules(AllModules);
platformBrowserDynamic().bootstrapModule(RootModule);
