<div class="m-portlet__body">
  <div class="m-form m-form--label-align-right">
    <div class="row align-items-center m--margin-bottom-10">
      <div class="col-xl-12">
        <div class="form-group m-form__group align-items-center">
          <div class="input-group">
            <input [(ngModel)]="filterText" type="text" id="filterText" [placeholder]="l('Search')" autoFocus
              class="form-control m-input" (keydown.enter)="search()" />
            <span class="input-group-btn">
              <button (click)="search()" class="btn btn-primary" type="submit">
                <i class="flaticon-search-1" [attr.aria-label]="l('Search')">
                </i>
              </button>
            </span>
            <span class="input-group-btn" style="padding-left:5px;">
              <button class="btn btn-secondary" (click)="refresh()"><i class="la la-refresh"></i>
                {{'Refresh' | localize}}</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <grid-pagination class="grid-pagination" (sizeValue)="onPageSizeChanged($event)"></grid-pagination>
      <ag-grid-angular localizeText StyleRow [updateList]="'listSearch'" #agGrid
        [paginationPageSize]="paginationPageSize" [paginationNumberFormatter]="paginationNumberFormatter"
        class="ag-theme-material gardium-wizzard-grid" [rowSelection]="rowSelection" [rowData]="rowData"
        (gridReady)="onGridReady($event)" (rowSelected)="onOrganizationSelected($event)" [defaultColDef]="defaultColDef"
        [columnDefs]="columnDefs" [pagination]="true" [enableCellChangeFlash]="true"
        [rowMultiSelectWithClick]="rowMultiSelectWithClick">
      </ag-grid-angular>
    </div>
  </div>
</div>
