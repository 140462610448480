<div [@routerTransition]>
	<div class="content d-flex flex-column flex-column-fluid">
		<sub-header [title]="'FAQ' | localize">
			<div role="actions">
				<button (click)="addCategory()" class="btn btn-outline-secondary">
					<i>
						<app-svg-icon iconName="ga-edit"></app-svg-icon>
					</i>
					{{ 'NewCategory' | localize }}
				</button>
				<button (click)="addQuestion()" class="btn btn-primary">
					<i class="fa fa-plus"></i>
					{{'NewQuestion' | localize}}
				</button>
			</div>
		</sub-header>
		<div [class]="containerClass">
			<div class="card listContainer">
				<div class="card-body">
					<div class="d-flex flex-column w-100">
						<h3 class="gardium-title">{{'SearchEngine' | localize}}</h3>
						<div class="d-flex flex-row">
							<input
								[(ngModel)]="filterText"
								name="filterText"
								autoFocus
								class="form-control m-input"
								[placeholder]="l('FAQSearchPlaceHolder')"
								type="text"
								(keyup.enter)="find()"
							/>
							<span class="input-group-btn">
								<button (click)="find()" class="btn btn-primary" type="submit">
									<i class="flaticon-search-1" [attr.aria-label]="l('Search')"></i>
								</button>
							</span>
						</div>
						<div class="w-25">
							<h3 class="mt-4 gardium-title">{{'Languages' | localize}}</h3>
							<form [formGroup]="form">
								<select formControlName="language" class="form-control">
									<option *ngFor="let lang of languages" [(ngValue)]="lang.id">{{lang.displayName}}</option>
								</select>
							</form>
						</div>
					</div>
				</div>
			</div>
			<div class="card listContainer">
				<div class="card-body">
					<section *ngIf="!searching()">
						<div class="pb-3">
							<!-- Big Filtering Buttons -->
							<h2 class="row mb-5 w-100 gardium-title">{{ "Themes" | localize }}</h2>
							<div class="row w-100 mb-5 card-grid">
								<button class="col-lg-3 col-md-4 col-sm-6 filtering-button"
										*ngFor="let faqCategory of faqCategories"
										(click)="filter(faqCategory.id)"
										[class.selected]="isSelected(faqCategory.id)">
									<app-svg-icon iconName="{{faqCategory.icon}}" class="mb-4" ></app-svg-icon>
									<h2>{{ faqCategory.name }}</h2>
									<p class="details-text">{{ faqCategory.faqDescriptions.length }} {{(faqCategory.faqDescriptions.length > 1 ? "AnswersFound" : "AnswerFound") | localize}}</p>
									<div class="btn-group" dropdown placement="bottom left" container="body">
										<button
											id="dropdownButton-{{faqCategory.id}}"
											type="button"
											class="custom-dropdown-button"
											dropdownToggle
											aria-controls="dropdownMenu"
											(click)="preventPropagation($event)"
										>
											<i class="fa fa-cog settings"></i>
										</button>
										<ul
											id="dropdownMenu"
											class="dropdown-menu"
											role="list"
											*dropdownMenu
											aria-labelledby="dropdownButton"
										>
											<li role="listitem">
												<a
													href="javascript:;"
													class="dropdown-item"
													(click)="editCategory($event, faqCategory)"
												>
													{{ 'EditCategory' | localize }}
												</a>
											</li>
											<li role="listitem">
												<a
													href="javascript:;"
													class="dropdown-item"
													(click)="addQuestion($event, faqCategory)"
												>
													{{ 'NewQuestion' | localize }}
												</a>
											</li>
											<li role="listitem">
												<a
													href="javascript:;"
													class="dropdown-item"
													(click)="deleteCategory($event, faqCategory)"
												>
													{{ 'DeleteCategory' | localize }}
												</a>
											</li>
										</ul>
									</div>
								</button>
							</div>
						</div>
					</section>
					<section>
						<ag-grid-angular
							localizeText
							#agGrid
							class="ag-theme-material gardium-wizzard-grid"
							[rowData]="rowData"
							[columnDefs]="columnDefs"
							[defaultColDef]="defaultColDef"
							rowSelection="single"
							paginationPageSize="20"
							pagination="true"
							(selectionChanged)="onSelectionChanged()"
							suppressContextMenu="true"
						>
						</ag-grid-angular>
					</section>
				</div>
			</div>
		</div>

		<app-create-or-edit-faq-description-modal #createOrEditFaqDescriptionModal (modalSave)="getAllFaq()"></app-create-or-edit-faq-description-modal>
		<app-create-or-edit-faq-category-modal #createOrEditFaqCategoryModal (modalSave)="getAllFaq()"></app-create-or-edit-faq-category-modal>

	</div>
</div>
