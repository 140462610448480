import { Component, Injector, ViewChild } from '@angular/core';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { DatePipe } from '@angular/common';
import { RequestStatus } from '@shared/models/request/requestStatus';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RequestStatusArrayObject } from '@shared/models/request/requestStatusArrayObject';
import { Router } from '@angular/router';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { RequestTableComponent } from '../request-table/request-table.component';
import { RequestDatasourceService } from '@shared/services/requests.datasource.service';
import { Subscription } from 'rxjs';
import { RequestStatusLegend } from '@shared/models/request/requestStatusLegend';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';

@Component({
    selector: 'request-list',
    templateUrl: './request-list.component.html',
    styleUrls: ['./request-list.component.scss'],
    animations: [appModuleAnimation()],
    providers: [DatePipe],
})
export class RequestListComponent extends AppComponentBase {
    @ViewChild(RequestTableComponent) gridTableComponent: RequestTableComponent;
    private subscriptions = new Subscription();
    private advancedSearchSelectionState: any = [];
    filterText = sessionStorage.getItem('requests-search-text') ?? '';
    minDateCreationTime = new Date();
    onlyMyRequestsValue = false;
    canSeeAssignments: boolean;
    advancedSearchFormGroup = new UntypedFormGroup({
        requestStatus: new UntypedFormControl({ value: '' }),
        onlyMyRequests: new UntypedFormControl({ value: false }),
        startCreationTimeBetween: new UntypedFormControl({ value: '' }),
        endCreationTimeBetween: new UntypedFormControl({ value: '' }),
    });
    config = {
        displayKey: 'description', //if objects array passed which key to be displayed defaults to description
        search: false, //true/false for the search functionlity defaults to false,
        customComparator: (a: RequestStatusArrayObject, b: RequestStatusArrayObject) => a.id - b.id, // To reorder by id
        height: '1000px',
        placeholder: this._appLocalizationService.l('SelectAll'),
        moreText: this._appLocalizationService.l('More'),
    };
    dropdownOptions: RequestStatusArrayObject[] = RequestStatusLegend.getAllLegends()
        .filter((value) => value.id !== RequestStatus.Draft || this._appAuthService.hasPermission('Pages.Management.Requests.Create.Wizzard'))
        .map((status) => ({
            id: status.id,
            description: this._appLocalizationService.l(status.name)
        } as RequestStatusArrayObject));

    constructor(
        injector: Injector,
        private _router: Router,
        private _appLocalizationService: AppLocalizationService,
        private _appAuthService: AppAuthService,
        private _dateTimeService: DateTimeService,
        private _service: RequestDatasourceService
    ) {
        super(injector);
        this.canSeeAssignments = this.isGranted('Pages.Management.Requests.CanSeePrivateInformation');
        this.subscriptions.add(this._service.firstDataRenderedSubject.subscribe(() => this.onFirstDataRendered()));
        this.subscriptions.add(this._service.sortChangedSubject.subscribe(event => this.onSortChanged(event)));
        this.subscriptions.add(this._service.filterChangedSubject.subscribe(event => this.onFilterChanged(event)));
        this.subscriptions.add(this._service.gridReadySubject.subscribe(() => this.refreshGrid()));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    onCreationDateStartValueChange(value: Date): void {
        this.minDateCreationTime = value;
    }

    onSelectChange(e): void {
        // Set local state from request state select input
        this.advancedSearchSelectionState = e.value;
    }

    onAdvancedSearchPressed(): void {
        sessionStorage.setItem('advancedRequest-status-state', JSON.stringify(this.advancedSearchSelectionState));
        sessionStorage.setItem('advancedRequest-from-state', JSON.stringify(this.advancedSearchFormGroup.controls['startCreationTimeBetween'].value));
        sessionStorage.setItem('advancedRequest-to-state', JSON.stringify(this.advancedSearchFormGroup.controls['endCreationTimeBetween'].value));
        sessionStorage.setItem('advancedRequest-onlyMyRequests-state', JSON.stringify(this.onlyMyRequestsValue));
        this.makeAdvancedSearch(false);
    }

    refreshAdvancedSearch() {
        sessionStorage.removeItem('advancedRequest-status-state');
        sessionStorage.removeItem('advancedRequest-from-state');
        sessionStorage.removeItem('advancedRequest-to-state');
        sessionStorage.setItem('advancedRequest-onlyMyRequests-state', 'false');
        this.initDefaultAdvancedValues();
        this.makeAdvancedSearch(false);
    }

    initializeRequestStatus(selectedItems: RequestStatusArrayObject[]): RequestStatus[] {
        let searchStatus: RequestStatus[] = selectedItems.map(i => i.id);
        if (searchStatus.length <= 0) {
            searchStatus = Object.values(RequestStatus).filter(value => typeof value === 'number') as RequestStatus[];
        }
        return searchStatus;
    }

    setCheckValue(e: any) {
        this.onlyMyRequestsValue = e.target.checked;
    }

    search() {
        const globalModel = {
            filterType: 'text',
            type: 'contains',
            filter: this.filterText,
        };
        this.gridTableComponent.setColumnFilter('globalFilter', globalModel);
        sessionStorage.setItem('requests-search-text', this.filterText);
    }

    refresh(): void {
        this.filterText = '';
        sessionStorage.removeItem('requests-search-text');
        this.search();
    }

    createRequest(): void {
        this._router.navigate(['/requests-wizard', '']);
    }

    resetFilters(): void {
        sessionStorage.setItem('request-filterModel', null);
        this.gridTableComponent.setGeneralFilter(null);
    }

    resetSort(): void {
        this.gridTableComponent.resetSorting();
    }

    private refreshGrid() {
        this.initWithAdvancedSearch();
        if (this.filterText !== '') {
            this.search();
        }
    }

    private onSortChanged(params): void {
        sessionStorage.setItem('request-sortModel', JSON.stringify(params.api.getSortModel()));
    }

    private onFilterChanged(params): void {
        sessionStorage.setItem('request-filterModel', JSON.stringify(params.api.getFilterModel()));
    }

    private onFirstDataRendered(): void {
        const sortModel = JSON.parse(sessionStorage.getItem('request-sortModel'));
        const filterModel = JSON.parse(sessionStorage.getItem('request-filterModel'));
        if (sortModel) {
            this.gridTableComponent.setSorting(sortModel);
        }
        if (filterModel) {
            this.gridTableComponent.setGeneralFilter(filterModel);
        }
    }

    private initWithAdvancedSearch(): void {
        this.initDefaultAdvancedValues();
        this.makeAdvancedSearch(true);
    }

    private initDefaultAdvancedValues() {
        this.advancedSearchFormGroup.controls['requestStatus'].setValue(JSON.parse(sessionStorage.getItem('advancedRequest-status-state')) ?? []);
        const aMonthAgo = new Date();
        const today = new Date();
        aMonthAgo.setMonth(aMonthAgo.getMonth() - 1);
        this.advancedSearchFormGroup.controls['startCreationTimeBetween'].setValue(JSON.parse(sessionStorage.getItem('advancedRequest-from-state')) ?? aMonthAgo);
        this.advancedSearchFormGroup.controls['endCreationTimeBetween'].setValue(JSON.parse(sessionStorage.getItem('advancedRequest-to-state')) ?? today);
        this.advancedSearchFormGroup.controls['onlyMyRequests'].setValue(JSON.parse(sessionStorage.getItem('advancedRequest-onlyMyRequests-state')) ?? false);
    }

    private makeAdvancedSearch(firstRender: boolean): void {
        if (!this.canSeeAssignments) {
            this.onlyMyRequestsValue = false;
        }

        if (this.isGranted('Pages.Management.Requests.CanSeePrivateInformation')) {
            this.filterPerAssignment();
        }
        this.filterPerStatus();
        this.filterPerDate();

        if (!firstRender) {
            this.gridTableComponent.applyFilters();
        }
    }

    private filterPerAssignment(): void {
        const userName = this._appAuthService.user?.name;
        const onlyMyRequest = this.advancedSearchFormGroup.controls['onlyMyRequests'].value;
        if (userName && onlyMyRequest) {
            this.gridTableComponent.setColumnFilter('assignedUserName', {
                filterType: 'text',
                type: 'contains',
                filter: userName,
            }, false);
        } else {
            if (!onlyMyRequest) {
                this.gridTableComponent.setColumnFilter('assignedUserName', null, false);
            }
        }
    }

    private filterPerStatus(): void {
        const statusArraySel: RequestStatusArrayObject[] = this.advancedSearchFormGroup.controls['requestStatus'].value ?? [];
        let status = '';
        for (let i = 0; i < statusArraySel.length; i++) {
            status += (i === statusArraySel.length - 1 ? statusArraySel[i].id : statusArraySel[i].id + ';');
        }
        const statusModel = {
            filterType: 'text',
            type: 'contains',
            filter: status,
        };
        this.gridTableComponent.setColumnFilter('status', statusModel, false);
    }

    private filterPerDate(): void {
        const startCreation = this.advancedSearchFormGroup.controls['startCreationTimeBetween'].value;
        const formatStartCreation = this._dateTimeService.formatDate(startCreation, 'YYYY-MM-DD');
        const endCreation = this.advancedSearchFormGroup.controls['endCreationTimeBetween'].value;
        const formatEndCreation = this._dateTimeService.formatDate(endCreation, 'YYYY-MM-DD');
        const dateModel = {
            filterType: 'date',
            type: 'inRange',
            dateFrom: formatStartCreation,
            dateTo: formatEndCreation,
        };
        this.gridTableComponent.setColumnFilter('creationTime', dateModel, false);
    }
}
