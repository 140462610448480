import { Component, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
    selector: 'app-grid-btn-update-twoparameters',
    templateUrl: './grid-btn-update-twoparameters.component.html'
})
export class GridBtnUpdateTwoparametersComponent extends AppComponentBase implements ICellRendererAngularComp {
    params: any;

    constructor(injector: Injector) {
        super(injector);
    }

    refresh(params: any): boolean {
        return false;
    }

    agInit(params): void {
        this.params = params;
    }

    onClick(): void {
        this.params.action(this.params.data.organizationPublicId, this.params.data.verificationPublicId);
    }
}
