import { Component, OnInit, Injector, HostListener } from '@angular/core';
import { VerificationOutput } from '@shared/models/verification/verificationOutput';
import { VerificationService } from '@shared/services/verification.service';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { verificationStatus } from '@shared/models/verification/verificationStatus';
import { ValueFormatterParams, GridApi, ColDef, GridOptions } from '@ag-grid-enterprise/all-modules';
import { GridBtnUpdateComponent } from '@app/shared/grid/grid-btn-update/grid-btn-update.component';
import { ToastrService } from 'ngx-toastr';
import { AppConsts } from '@shared/AppConsts';
import { DatePipe } from '@angular/common';
import { Extensions } from '@shared/utils/extensions';
import { VerificationListOutput } from '@shared/models/verification/verificationListOutput';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { AppState } from '@app/store/state/state';
import { Store } from '@ngrx/store';
import * as VerificationActions from '@app/store/actions/verifications/actions';
import { Observable } from 'rxjs';
import * as VerificationSelectors from '@app/store/selectors/verifications/selectors';
@Component({
    selector: 'verifications-list',
    templateUrl: './verifications-list.component.html',
    styleUrls: ['./verifications-list.component.scss'],
    animations: [appModuleAnimation()],
    providers: [DatePipe]
})
export class VerificationsListComponent extends AppComponentBase implements OnInit {
    rowSelection: string;
    selectedVerificationRow: VerificationListOutput;
    filterText = sessionStorage.getItem('verifications-search-text') ?? '';
    rowData$: Observable<VerificationListOutput[]> = this.store.select(VerificationSelectors.selectItems((a, b) => {
        if (a.code > b.code) {
            return 1;
        } else if (b.code > a.code) {
            return -1;
        }
        return 0;
    }));
    columnDefs: ColDef[];

    paginationNumberFormatter;
    paginationPageSize = 20;

    gridOptions = <GridOptions>{
        defaultExportParams: {
            processCellCallback: (params) => {
                const colDef = params.column.getColDef();
                if (colDef.valueFormatter) {
                    const valueFormatterParams: ValueFormatterParams = {
                        ...params,
                        data: params.node.data,
                        node: params.node,
                        colDef: params.column.getColDef()
                    };
                    return colDef.valueFormatter(valueFormatterParams);
                }
                return params.value;
            }
        }
    };
    private gridApi: GridApi;

    constructor(
        private store: Store<AppState>,
        private service: VerificationService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _appLocalizationService: AppLocalizationService,
        private toastr: ToastrService,
        injector: Injector,
        private datePipe: DatePipe,
        private _dateTimeService: DateTimeService
    ) {
        super(injector);

        const actif = this._appLocalizationService.l('Actif');
        const inactif = this._appLocalizationService.l('Inactif');

        this.rowSelection = this.isGranted('Pages.Management.Verifications.Details') ? 'single' : null;
        this.filterText = this._activatedRoute.snapshot.queryParams['filterText'] || sessionStorage.getItem('verifications-search-text') || '';
        this.columnDefs = [
            {
                headerName: this._appLocalizationService.l('VerificationCode'),
                field: 'code',
                sortable: true,
                filter: true,
                width: 300,
                resizable: true
            },
            {
                headerName: this._appLocalizationService.l('VerificationType'),
                field: 'type',
                sortable: true,
                filter: true,
                width: 300,
                getQuickFilterText: Extensions.replaceSpecialCharactersParams.bind(this),
                resizable: true
            },
            {
                headerName: this._appLocalizationService.l('VerificationPrice'),
                valueGetter: this.priceFormat,
                sortable: true,
                filter: true,
                width: 300,
                resizable: true
            },
            {
                headerName: this._appLocalizationService.l('VerificationStatus'),
                width: 300,
                valueFormatter: (params) => this.verificationStatusFormatter(params, actif, inactif),
                getQuickFilterText: (params) => this.verificationStatusFormatter(params, actif, inactif),
                field: 'status',
                sortable: true,
                filter: true,
                resizable: true,
                filterParams: {
                    cellRenderer: (params) => this.verificationStatusFormatter(params, actif, inactif)
                }
            },
            {
                headerName: this._appLocalizationService.l('VerificationExpressService'),
                valueGetter: this.expressPriceFormat,
                sortable: true,
                filter: true,
                width: 300,
                resizable: true
            },
            {
                headerName: '',
                cellClass: 'text-end',
                cellRendererFramework: GridBtnUpdateComponent,
                cellRendererParams: { action: this.updateVerification },
                hide: !this.isGranted('Pages.Management.Verifications.Update')
            }
        ];
        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.gridApi) {
            if (screen.availWidth > 414) {
                this.gridApi.sizeColumnsToFit();
            }
        }
    }

    expressPriceFormat(params) {
        return `${params.data.expressPrice ? params.data.expressPrice.toFixed(2) : 0} $`;
    }
    priceFormat(params) {
        return `${params.data.price ? params.data.price.toFixed(2) : 0} $`;
    }

    toEnum(params: ValueFormatterParams): string {
        return this._appLocalizationService.l(verificationStatus[params.value]);
    }

    onPageSizeChanged(e) {
        this.gridApi.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    createVerification(): void {
        this._router.navigate(['/verificationsCreate']);
    }

    ngOnInit(): void {
        this.store.dispatch(VerificationActions.verificationsGetAll());
    }

    onGridReady(params) {
        this.gridApi = params.api;
        if (screen.availWidth > 414) {
            this.gridApi.sizeColumnsToFit();
        } else if (screen.availWidth <= 414) {
            const allColIds = params.columnApi.getAllColumns().map((column) => column.colId);
            params.columnApi.autoSizeColumns(allColIds);
        }
        this.search();
    }

    dateFormater(params: ValueFormatterParams) {
        return this.datePipe.transform(this._dateTimeService.toUtcDate(params.value).toLocal().toString(), AppConsts.dateTimeFormat);
    }

    search() {
        const filterWithoutAccents = Extensions.replaceAccentedCharacters(this.filterText);
        this.gridApi?.setQuickFilter(filterWithoutAccents);
        sessionStorage.setItem('verifications-search-text', filterWithoutAccents);
    }

    refresh() {
        this.filterText = '';
        sessionStorage.removeItem('verifications-search-text');
        this.gridApi.resetQuickFilter();
        this.search();
    }

    onSelectionChanged() {
        if (this.isGranted('Pages.Management.Verifications.Details')) {
            this.selectedVerificationRow = this.gridApi.getSelectedRows()[0] as VerificationOutput;
            this._router.navigate(['/verifications-details', this.selectedVerificationRow.publicId]);
        } else {
            this.showAccessError();
        }
    }

    showAccessError() {
        const title = this._appLocalizationService.l('AccessIssue');
        const message = this._appLocalizationService.l('NotAccess');
        this.toastr.error(message, title);
    }

    updateVerification = (element: VerificationListOutput) => {
        this._router.navigate(['/verifications-update', element.publicId]);
    };

    onSortChanged(params) {
        sessionStorage.setItem('verifications-sortModel', JSON.stringify(params.api.getSortModel()));
    }

    onFilterChanged(params) {
        sessionStorage.setItem('verifications-filterModel', JSON.stringify(params.api.getFilterModel()));
    }

    onFirstDataRendered() {
        const sortModel = JSON.parse(sessionStorage.getItem('verifications-sortModel'));
        const filterModel = JSON.parse(sessionStorage.getItem('verifications-filterModel'));
        if (sortModel) {
            this.gridApi.setSortModel(sortModel);
        }
        if (filterModel) {
            this.gridApi.setFilterModel(filterModel);
        }
    }

    resetFilters() {
        sessionStorage.setItem('verifications-filterModel', null);
        this.gridApi.setFilterModel(null);
    }

    resetSort() {
        sessionStorage.setItem('verifications-sortModel', null);
        this.gridApi.setSortModel(null);
    }

    private verificationStatusFormatter(params: any, active: string, inactive: string) {
        let result;
        switch (params.value) {
            case 0:
            case '0':
                result = active;
                break;
            case 1:
            case '1':
                result = inactive;
                break;
            default:
                result = params.value;
        }
        params.value = result;
        return result;
    }
}
