import { createReducer, on } from '@ngrx/store';
import * as VerificationsActions from '../../actions/verifications/actions';
import { VerificationsState, verificationsInitialState } from '@app/store/state/verifications/verifications.state';
import { VerificationListOutput } from '@shared/models/verification/verificationListOutput';
import { RequestEvent } from '@shared/models/request-event/request-event.model';
export const verificationsKey = 'verifications';
const getAllSuccess = (state: VerificationsState, payload: VerificationListOutput[]): VerificationsState => ({
    ...state,
    items: payload,
    filteredItems: payload,
});
const getById = (state: VerificationsState, payload: VerificationListOutput): VerificationsState => ({
    ...state,
    selectedItem: payload,
});

const select = (state: VerificationsState, id: number): VerificationsState => ({
    ...state,
    selectedItem: [...state.items].find((x) => x.id === id)
});

const resetSelected = (state: VerificationsState): VerificationsState => ({
    ...state,
    selectedItem: verificationsInitialState.selectedItem,
});

const reset = (state: VerificationsState): VerificationsState => ({
    ...state,
    ...verificationsInitialState,
});

const resetItems = (state: VerificationsState): VerificationsState => ({
    ...state,
    items: verificationsInitialState.items,
    filteredItems: verificationsInitialState.filteredItems
});

const updateRequestEvent = (state: VerificationsState, value?: RequestEvent): VerificationsState => ({
    ...state,
    event: value ?? verificationsInitialState.event
});

export const verificationsReducers = createReducer(
    verificationsInitialState,
    on(VerificationsActions.verificationsSelect, (state: VerificationsState, { id }: { id?: number }) => select(state, id)),
    on(VerificationsActions.verificationsResetSelected, (state: VerificationsState) => resetSelected(state)),
    on(
        VerificationsActions.verificationsGetAllSuccess,
        (state: VerificationsState, { payload }: { payload: VerificationListOutput[] }): VerificationsState => getAllSuccess(state, payload)
    ),
    on(VerificationsActions.verificationsUpdateRequestEvent, (state: VerificationsState, { value }: {value?: RequestEvent}): VerificationsState => updateRequestEvent(state, value)),
    on(VerificationsActions.verificationsGetByIdSuccess, (state: VerificationsState, { payload }: { payload: VerificationListOutput }) => getById(state, payload)),
    on(VerificationsActions.verificationsReset, (state: VerificationsState): VerificationsState => reset(state)),
    on(VerificationsActions.verificationsResetItems, (state: VerificationsState): VerificationsState => resetItems(state)),
);
