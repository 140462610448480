import { AppConsts } from '@shared/AppConsts';
import { RequestStatus } from './requestStatus';

export interface IRequestStatusLegend {
    id: number;
    name: string;
    color: string;
    textColor: string;
}

export class RequestStatusLegend {
    static readonly New: IRequestStatusLegend = {
        id: RequestStatus.New,
        name: 'New',
        color: AppConsts.statusColor.new,
        textColor: 'var(--color-white)'
    };
    static readonly Canceled: IRequestStatusLegend = {
        id: RequestStatus.Canceled,
        name: 'Canceled',
        color: AppConsts.statusColor.cancel,
        textColor: 'var(--color-white)'
    };
    static readonly InProgressClient: IRequestStatusLegend = {
        id: RequestStatus.InProgressClient,
        name: 'InProgressClient',
        color: AppConsts.statusColor.inProgressClient,
        textColor: 'var(--color-white)'
    };
    static readonly InProgressGardium: IRequestStatusLegend = {
        id: RequestStatus.InProgressGardium,
        name: 'InProgressGardium',
        color: AppConsts.statusColor.inProgressGardium,
        textColor: 'var(--color-white)'
    };
    static readonly Completed: IRequestStatusLegend = {
        id: RequestStatus.Completed,
        name: 'Completed',
        color: AppConsts.statusColor.completed,
        textColor: 'var(--color-white)'
    };
    static readonly MissingInformations: IRequestStatusLegend = {
        id: RequestStatus.MissingInformations,
        name: 'MissingInformations',
        color: AppConsts.statusColor.missingInfo,
        textColor: 'var(--color-white)'
    };
    static readonly CantBeCompleted: IRequestStatusLegend = {
        id: RequestStatus.CantBeCompleted,
        name: 'CantBeCompleted',
        color: AppConsts.statusColor.cantBeCompleted,
        textColor: 'var(--color-white)'
    };
    static readonly Draft: IRequestStatusLegend = {
        id: RequestStatus.Draft,
        name: 'Draft',
        color: AppConsts.statusColor.draft,
        textColor: 'var(--color-white)'
    };
    static readonly InProgressCustomerEzSign: IRequestStatusLegend = {
        id: RequestStatus.InProgressCustomerEzSign,
        name: 'InProgressCustomerEzSign',
        color: AppConsts.statusColor.inProgressEzSign,
        textColor: 'var(--color-white)'
    };
    static readonly InProgressPPO: IRequestStatusLegend = {
        id: RequestStatus.InProgressPpo,
        name: 'InProgressPpo',
        color: AppConsts.statusColor.inProgressPpo,
        textColor: 'var(--color-white)'
    };

    static getLegendFromId(id: number | string): IRequestStatusLegend | null {
        const numericValue = typeof id === "string" ? parseInt(id) : id;
        switch (numericValue as RequestStatus) {
            case RequestStatus.New:
                return RequestStatusLegend.New;
            case RequestStatus.Canceled:
                return RequestStatusLegend.Canceled;
            case RequestStatus.InProgressClient:
                return RequestStatusLegend.InProgressClient;
            case RequestStatus.InProgressGardium:
                return RequestStatusLegend.InProgressGardium;
            case RequestStatus.Completed:
                return RequestStatusLegend.Completed;
            case RequestStatus.MissingInformations:
                return RequestStatusLegend.MissingInformations;
            case RequestStatus.CantBeCompleted:
                return RequestStatusLegend.CantBeCompleted;
            case RequestStatus.Draft:
                return RequestStatusLegend.Draft;
            case RequestStatus.InProgressCustomerEzSign:
                return RequestStatusLegend.InProgressCustomerEzSign;
            case RequestStatus.InProgressPpo:
                return RequestStatusLegend.InProgressPPO;
            default:
                return null;
        }
    }

    static getAllLegends(): IRequestStatusLegend[] {
        return [
            RequestStatusLegend.New,
            RequestStatusLegend.Canceled,
            RequestStatusLegend.InProgressClient,
            RequestStatusLegend.InProgressGardium,
            RequestStatusLegend.Completed,
            RequestStatusLegend.MissingInformations,
            RequestStatusLegend.CantBeCompleted,
            RequestStatusLegend.Draft,
            RequestStatusLegend.InProgressCustomerEzSign,
            RequestStatusLegend.InProgressPPO
        ];
    }
}
