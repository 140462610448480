import { Component, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import {
    CurrentUserProfileEditDto,
    SettingScopes,
    ProfileServiceProxy,
} from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UserEmailNotificationOutput } from '@shared/models/userEmailNotification/userEmailNotificationOutput';
import { UserEmailNotificationService } from '@shared/services/userEmailNotification.service';
import { OrganizationUserService } from '@shared/services/organizationUser.service';
import { UserEmailNotificationInput } from '@shared/models/userEmailNotification/userEmailNotificationInput';
import { ToastrService } from 'ngx-toastr';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { EmailNotificationService } from '@shared/services/emailNotification.service';
import { EmailNotificationOutput } from '@shared/models/emailNotification/emailNotificationOutput';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';

@Component({
    selector: 'mySettingsModal',
    templateUrl: './my-settings-modal.component.html'
})
export class MySettingsModalComponent extends AppComponentBase {
    @ViewChild('mySettingsModal', { static: true }) modal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    public active = false;
    public saving = false;
    public isPhoneNumberConfirmed: boolean;
    public user: CurrentUserProfileEditDto;
    public savedPhoneNumber: string;
    public newPhoneNumber: string;
    public formGroup = new UntypedFormGroup({
        notifications: new UntypedFormControl('')
    });
    public userId: number;
    public createdInput: UserEmailNotificationInput;
    public userEmailNotifications: UserEmailNotificationOutput[] = [];
    public emailNotifications: EmailNotificationOutput[] = [];
    public selectedEmailNotifications: EmailNotificationOutput[] = [];
    public subtypeDict: Map<number, EmailNotificationOutput[]> = new Map();

    constructor(
        injector: Injector,
        private _profileService: ProfileServiceProxy,
        private _userNotificationService: UserEmailNotificationService,
        private emailNotificationService: EmailNotificationService,
        private _organizationUserService: OrganizationUserService,
        private toastr: ToastrService,
        private _appLocalizationService: AppLocalizationService,
        private _appAuthService: AppAuthService
    ) {
        super(injector);
    }

    show(): void {
        this.active = true;
        this._profileService.getCurrentUserProfileForEdit().subscribe((result) => {
            this.user = result;
            this.modal.show();
            this.isPhoneNumberConfirmed = result.isPhoneNumberConfirmed;
            this.savedPhoneNumber = result.phoneNumber;

            //getAllNotifications
            this.emailNotificationService.getEmailNotificationsList().subscribe((value) => {
                this.emailNotifications = value.result;
                this.buildSubtypesDict();
            });

            //get userId
            this._organizationUserService.getUserIdByUsername(this.user.userName).subscribe((value) => {
                this.userId = value.result;

                //afficher la liste des emails notifications du user
                this._userNotificationService.getUserEmailNotificationFromUserId(this.userId).subscribe((value) => {
                    this.userEmailNotifications = value.result;
                    this.getCheckedUserEmailNotifications(this.userEmailNotifications);
                });
            });
        });
    }

    getCheckedUserEmailNotifications(outputList: UserEmailNotificationOutput[]) {
        this.selectedEmailNotifications = [];

        outputList.forEach((element) => {
            const notification = this.getEmailNotificationInListByNotificationId(element.notificationId, this.emailNotifications);

            if (notification !== undefined && notification !== null) {
                if (element.emailNotificationOutput.isChecked) {
                    notification.isChecked = true;
                } else {
                    notification.isChecked = false;
                }
                this.selectedEmailNotifications.push(notification);
            }
        });

        const selectedEmailNotificationIds = [];
        this.selectedEmailNotifications.forEach((element) => {
            selectedEmailNotificationIds.push(element.id);
        });

        this.emailNotifications.forEach((element) => {
            if (!selectedEmailNotificationIds.includes(element.id)) {
                element.isChecked = !element.isChecked;
            }
        });
    }

    getEmailNotificationInListByNotificationId(notificationId: number, notificationList: EmailNotificationOutput[]): EmailNotificationOutput {
        let elemToReturn: EmailNotificationOutput = null;
        notificationList.forEach((element) => {
            if (element.id === notificationId) {
                elemToReturn = element;
            }
        });
        return elemToReturn;
    }

    changeCheckbox(emailNotifications: EmailNotificationOutput) {
        if (!emailNotifications.isChecked) {
            this.checkNotification(emailNotifications);
            this.checkParentNotification(emailNotifications);
        } else {
            this.uncheckNotification(emailNotifications);
            this.uncheckParentNotification(emailNotifications);
        }

        this.toggleSubNotifications(emailNotifications);
    }

    checkParentNotification(emailNotifications: EmailNotificationOutput) {
        if (emailNotifications.subtypeOf) {
            const subtypesOfParent = this.subtypeDict.get(emailNotifications.subtypeOf);
            if (subtypesOfParent.every((notif) => notif.isChecked)) {
                const parentNotification = this.emailNotifications.find((notif) => notif.id === emailNotifications.subtypeOf);
                if (parentNotification) {
                    this.checkNotification(parentNotification);
                }
            }
        }
    }

    uncheckParentNotification(emailNotifications: EmailNotificationOutput) {
        if (emailNotifications.subtypeOf) {
            const parentNotification = this.selectedEmailNotifications.find((notif) => notif.id === emailNotifications.subtypeOf);
            if (parentNotification) {
                this.uncheckNotification(parentNotification);
            }
        }
    }

    toggleSubNotifications(emailNotifications: EmailNotificationOutput) {
        if (this.subtypeDict.get(emailNotifications.id)) {
            this.subtypeDict
                .get(emailNotifications.id)
                .map((subtype: EmailNotificationOutput) => ({
                    ...this.emailNotifications.find((en) => en.id === subtype.id),
                    isChecked: emailNotifications.isChecked
                }))
                .forEach((i) => {
                    const notification = this.emailNotifications.find((n) => n.id === i.id);
                    if (notification.isChecked !== i.isChecked) {
                        this.changeCheckbox(notification);
                    }
                });
        }
    }

    checkNotification(emailNotification: EmailNotificationOutput): void {
        emailNotification.isChecked = true;
        if (this.selectedEmailNotifications.findIndex((n) => n.id === emailNotification.id) < 0) {
            this.selectedEmailNotifications.push(emailNotification);
        }
    }

    uncheckNotification(emailNotification: EmailNotificationOutput): void {
        emailNotification.isChecked = false;
        const index: number = this.selectedEmailNotifications.indexOf(emailNotification);
        this.selectedEmailNotifications.splice(index, 1);
    }
    changePhoneNumberToVerified(): void {
        this.isPhoneNumberConfirmed = true;
        this.savedPhoneNumber = this.user.phoneNumber;
    }

    onShown(): void {
        document.getElementById('Name').focus();
    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }

    save(): void {
        this.saving = true;
        this._profileService
            .updateCurrentUserProfile(this.user)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe(() => {
                this._appAuthService.user.name = this.user.name;
                this._appAuthService.user.surname = this.user.surname;
                this._appAuthService.user.userName = this.user.userName;
                this._appAuthService.user.emailAddress = this.user.emailAddress;
                this.addUserEmailNotifications();
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
            });
    }

    convertEmailNotificationOutputToInput(emailNotification: EmailNotificationOutput): UserEmailNotificationInput {
        return {
            id: '',
            notificationId: emailNotification.id,
            userId: this.userId,
            checked: emailNotification.isChecked,
            subtypeOf: emailNotification.subtypeOf
        };
    }

    configureCheckedNotificationsInListInput(checkedNotifications: EmailNotificationOutput[]): Array<UserEmailNotificationInput> {
        const listInput = new Array<UserEmailNotificationInput>();

        checkedNotifications.forEach((element) => {
            const elementInput = this.convertEmailNotificationOutputToInput(element);
            listInput.push(elementInput);
        });
        return listInput;
    }

    async addUserEmailNotifications() {
        const checkedNotificationInputList = this.configureCheckedNotificationsInListInput(this.selectedEmailNotifications);
        if (checkedNotificationInputList !== null) {
            await this._userNotificationService
                .addUserEmailNotificationList(checkedNotificationInputList)
                .toPromise()
                .then((response) => {
                    const valueReceived = response.result.result;

                    if (valueReceived.outputStatus === 0) {
                        const title = this._appLocalizationService.l('NotificationManager');
                        const successMessage = this._appLocalizationService.l('NotificationsSuccessfullySaved');
                        this.showSuccess(title, successMessage);
                    } else {
                        const title = this._appLocalizationService.l('NotificationManager');
                        const errorMessage = this._appLocalizationService.l('NotificationsLinkTryAgainLater');
                        this.showErrorMessage(errorMessage, title);
                    }
                    return valueReceived;
                });
        }
    }

    showSuccess(title: string, successMessage: string) {
        this.toastr.success(title, successMessage);
    }

    showErrorMessage(errorMessage: string, title: string) {
        this.toastr.error(errorMessage, title);
    }

    // Method to keep track of the child-parent relationship between the notifications so that checking a parent affect the child.
    // The use of a dictionnary was to make it more performant than looping each time.
    private buildSubtypesDict() {
        this.subtypeDict.clear();
        this.emailNotifications
            .filter((notification) => notification.subtypeOf !== null)
            .forEach((notification) => {
                if (!this.subtypeDict.has(notification.subtypeOf)) {
                    this.subtypeDict.set(notification.subtypeOf, []);
                }
                if (this.subtypeDict.get(notification.subtypeOf).findIndex((n) => n.id === notification.id) === -1) {
                    this.subtypeDict.get(notification.subtypeOf).push(notification);
                }
            });
    }
}
