import { Injectable } from '@angular/core'

interface EditorOptions {
    strikethrough?: boolean;
    heading?: boolean;
    horizontalLine?: boolean;
    mediaEmbedding?: boolean;
    imagesInsertion?: boolean;
    tableInsertion?: boolean;
    blockQuote?: boolean;
    language?: string;
}

@Injectable()
export class CkeditorService {
    constructor() {}

    
    getConfig({strikethrough = false, heading = false, horizontalLine = false, mediaEmbedding = false, imagesInsertion = true, tableInsertion = false, blockQuote = false, language = 'en'}: EditorOptions = {}) {
        const groupedInsertions: boolean = blockQuote || tableInsertion || mediaEmbedding || horizontalLine;
        const multipleInsertions = {
            label: 'Insert',
            icon: 'plus',
            items: [
                'link',
                ...(blockQuote ? ['blockQuote'] : []),
                ...(tableInsertion ? ['insertTable'] : [] ),
                ...(imagesInsertion ? ['imageInsert'] : []),
                ...(mediaEmbedding ? ['mediaEmbed'] : []),
                ...(horizontalLine ? ['horizontalLine'] : []),
            ]
        };
        const listIcon = '<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 5.75c0 .414.336.75.75.75h9.5a.75.75 0 1 0 0-1.5h-9.5a.75.75 0 0 0-.75.75zm-6 0C1 4.784 1.777 4 2.75 4c.966 0 1.75.777 1.75 1.75 0 .966-.777 1.75-1.75 1.75C1.784 7.5 1 6.723 1 5.75zm6 9c0 .414.336.75.75.75h9.5a.75.75 0 1 0 0-1.5h-9.5a.75.75 0 0 0-.75.75zm-6 0c0-.966.777-1.75 1.75-1.75.966 0 1.75.777 1.75 1.75 0 .966-.777 1.75-1.75 1.75-.966 0-1.75-.777-1.75-1.75z"/></svg>';
        const listOptions = {
            label: 'Lists',
            icon: listIcon,
            items: [
                'bulletedList', 
                'numberedList',
                'outdent', 
                'indent',
            ]
        };
        return {
            toolbar: {
                items: [
                    'bold',
                    'italic',
                    'underline',
                    ...(strikethrough ? ['strikethrough'] : []),
                    '|',
                    'alignment',
                    listOptions,
                    '|',
                    ...(groupedInsertions ? [multipleInsertions] : ['link', ...(imagesInsertion ? ['imageInsert'] : [])]),
                    ...(heading ? ['|', 'heading'] : []),
                    '|',
                    'undo', 
                    'redo', 
                ]
            },
            language: language,
            image: {
                toolbar: [
                    'imageTextAlternative',
                    'toggleImageCaption',
                    'imageStyle:inline',
                    'imageStyle:block',
                    'imageStyle:side'
                ]
            },
            table: {
                contentToolbar: [
                    'tableColumn',
                    'tableRow',
                    'mergeTableCells',
                    'tableCellProperties'
                ]
            },
            link: {
                decorators: {
                    addTargetToExternalLinks: {
                        mode: 'automatic',
                        callback: url => /^(https?:)?\/\//.test( url ),
                        attributes: {
                            target: '_blank',
                            rel: 'noopener noreferrer'
                        }
                    }
                }
            }
        };
    }
}
