import { createAction, props } from '@ngrx/store';
import { RequestEvent } from '@shared/models/request-event/request-event.model';
import { VerificationListOutput } from '@shared/models/verification/verificationListOutput';
export const VERIFICATIONS_ACTION_GET_ALL = '[Verification] GET ALL';
export const VERIFICATIONS_ACTION_RESET_ITEMS = '[Verification] RESET ITEMS';
export const VERIFICATIONS_ACTION_GET_ALL_SUCCESS = '[Verification] GET ALL SUCCESS';
export const VERIFICATIONS_ACTION_GET_BY_ID = '[Verification] GET BY ID';
export const VERIFICATIONS_ACTION_GET_BY_ID_SUCCESS = '[Verification] GET BY ID SUCCESS';
export const VERIFICATIONS_ACTION_SELECT = '[Verification] SELECT';
export const VERIFICATIONS_ACTION_RESET_SELECTED = '[Verification] RESET SELECTED';
export const VERIFICATIONS_ACTION_RESET = '[Verification] RESET';
export const VERIFICATIONS_ACTION_UPDATE_REQUEST_EVENT = '[Verification] UPDATE REQUEST EVENT';
export const verificationsGetAll = createAction(VERIFICATIONS_ACTION_GET_ALL);
export const verificationsGetAllSuccess = createAction(VERIFICATIONS_ACTION_GET_ALL_SUCCESS, props<{ payload: VerificationListOutput[] }>());
export const verificationsGetById = createAction(VERIFICATIONS_ACTION_GET_BY_ID, props<{ id: number }>());
export const verificationsGetByIdSuccess = createAction(VERIFICATIONS_ACTION_GET_BY_ID_SUCCESS, props<{ payload: VerificationListOutput }>());
export const verificationsSelect = createAction(VERIFICATIONS_ACTION_SELECT, props<{ id?: number }>());
export const verificationsResetSelected = createAction(VERIFICATIONS_ACTION_RESET_SELECTED);
export const verificationsReset = createAction(VERIFICATIONS_ACTION_RESET);
export const verificationsResetItems = createAction(VERIFICATIONS_ACTION_RESET_ITEMS);
export const verificationsUpdateRequestEvent = createAction(VERIFICATIONS_ACTION_UPDATE_REQUEST_EVENT, props<{ value?: RequestEvent }>());
