import { DateFilterModel, ISimpleFilterModel } from '@ag-grid-enterprise/all-modules';

export function adjustLocalDateRangeToUtcDateTimeRange(localDateFilterModel: DateFilterModel): DateFilterModel {
    let { dateTo, dateFrom } = localDateFilterModel;
    [dateFrom, dateTo] = [dateFrom, dateTo].map((dateString, dateIndex) => {
        const extraction = /^(\d{1,4})-(\d{2})-(\d{2})$/.exec(dateString);
        if (extraction) {
            // agGrid feeds dateFrom and dateTo as Dates without time
            // We convert those to midnight Date limits at browser local timezone's as UTC value using the native `Date` constructor and native `Date.prototype.toISOString`
            return new Date(
                ...(extraction.slice(1).map((token, numericTokenIndex) => {
                    let value = parseFloat(token);
                    if (numericTokenIndex === 1) {
                        // 1st-12th month for the javascript Date constructor is in the 0 to 11 range
                        value -= 1;
                    } else if (dateIndex && numericTokenIndex === 2) {
                        // the input endDate is the last included date as provided by aggrid.
                        // output will be LOCAL midnight start of the following date, in UTC
                        value += 1;
                        // backend must use a `STRICTLY LESS THAN` DateTime Comparer on the endDate
                    }
                    return value;
                }) as [number, number, number])
            ).toISOString();
        }
        throw Error(JSON.stringify(dateString) + ' wrong date format. ' + dateIndex);
    });

    return { ...localDateFilterModel, dateFrom, dateTo };
}

export function overallAdjustLocalDateRangeToUtcDateTimeRange<T extends string>(filterModel: Record<T, ISimpleFilterModel>): Record<T, ISimpleFilterModel> {
    if (filterModel) {
        const dateFilterEntries = Object.entries(filterModel).filter(([, filter]) => isDateRangeFilter(filter as ISimpleFilterModel)) as [T, DateFilterModel][];
        if (dateFilterEntries.length) {
            filterModel = { ...filterModel };
            for (const [key, dateFilter] of dateFilterEntries) {
                filterModel[key] = adjustLocalDateRangeToUtcDateTimeRange(dateFilter) as ISimpleFilterModel;
            }
        }
    }

    return filterModel;
}

function isDateRangeFilter(filter: ISimpleFilterModel): filter is DateFilterModel {
    return filter && filter.filterType === 'date' && filter.type === 'inRange';
}
