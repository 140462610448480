<div class="content d-flex flex-column flex-column-fluid align-left">
    <!-- <sub-header [title]="'RequestWizzardCandidate' | localize"></sub-header> -->

    <div *ngIf="!createCandidate">
        <p class="mb-5 ml-4 margin-top-20 align-left">{{'RequestWizzardFillNames' | localize}}</p>

        <request-wizard-candidate-search
            [(form)]="form"
            [(candidates)]="candidates"
            [(selectedCandidate)]="selectedCandidate"
            [(request)]="request"
            [(searchDone)]="searchDone"
            [searchOnFocusOut]="false"
            (candidatesChange)="onCandidatesChange($event)"
            [resetCandidate]="resetCandidate"
            [candidateChanged]="candidateChanged"
            [isDriverLicenseRequired]="isDriverLicenseRequired">
        </request-wizard-candidate-search>

        <p *ngIf="form.valid && candidates?.length > 0" class="mb-5 ml-4 margin-top-20 align-left">{{'RequestWizzardChooseCandidate' | localize}}</p>

        <ag-grid-angular localizeText StyleRow *ngIf="form.valid && candidates?.length > 0"
            class="ag-theme-material gardium-wizzard-grid margin-top-20" rowSelection="single"
            suppressRowDeselection="false" [rowData]="candidates" [defaultColDef]="defaultColDef"
            [columnDefs]="columnDefs" [paginationNumberFormatter]="paginationNumberFormatter"
            [paginationPageSize]="paginationPageSize" [pagination]="true" (gridReady)="onGridReady($event)"
            (selectionChanged)="selectCandidate()">
        </ag-grid-angular>

        <div class="form-inline margin-top-20" *ngIf="showAddCandidate()">
            <div class="form-group">
                <label for="addCandidateBtn" class="margin-right-20" *ngIf="candidates?.length > 0">{{'RequestWizzardNoResultQuestion' |
                    localize}}</label>
                <label for="addCandidateBtn" class="margin-right-20" *ngIf="candidates?.length == 0">{{'RequestWizzardNoResult' |
                    localize}}</label>
                <button class="btn btn-secondary" id="addCandidateBtn" (click)="addCandidate()" [disabled]="this.selectedCandidate != null">
                    {{'RequestWizzardAddCandidate' | localize}}
                </button>
            </div>
        </div>

        <div class="d-flex mt-10">
            <div class="pr-0">
                <button class="float-lg-right btn-back p-0 mr-2" awPreviousStep>
                    <i class="la la-arrow-circle-o-left btn-back float-lg-right"></i>
                </button>
            </div>
            <div class="text-left justify-content-center align-self-center pl-0">
                <label for="submitBtn" class="mb-0">{{"RequestWizzardPreviousStep" | localize}}</label>
            </div>
            <div class="flex-grow-1"></div>
            <div class="text-end justify-content-center align-self-center pr-0">
                <label for="submitBtn" class="mb-0" *ngIf="!saving">{{"RequestWizzardNextStep" | localize}}</label>
            </div>
            <div class="pl-0">
                <button type="submit" id="submitBtn" class="float-lg-left btn-next p-0 ml-2"
                    [disabled]="this.request.candidat.id == 0 || saving" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')" (click)="save()">
                    <i class="la la-arrow-circle-o-right btn-next float-lg-left"></i>
                </button>
            </div>
        </div>
    </div>

    <request-wizard-candidate-create *ngIf="createCandidate" [(formSearch)]="form" [(request)]="this.request"
        [(createCandidate)]="createCandidate" (onCreateCandidateChange)="onCreateCandidateChange($event)"
        [(candidateform)]="candidateform" [resetDriverLicenseRequired]="resetDriverLicenseRequired">
    </request-wizard-candidate-create>
</div>
