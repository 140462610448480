<div bsModal #FaqCategoryCreateEditModalComponent="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="FaqCategoryCreateEditModalComponent" aria-hidden="true" [config]="{backdrop: 'static', keyboard: !saving}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <form [formGroup]="formGroup" #categoryFAQUpdateForm="ngForm" novalidate (ngSubmit)="save()"
        autocomplete="off">
        <div class="modal-header">
          <h4 class="modal-title pull-left">
            <h5 class="gardium-title">{{(editing? "EditCategory" : "NewCategory") | localize}}</h5>
          </h4>
        </div>
        <div class="modal-body">
          <div class="form-group edition-list row">
            <label for="language">{{"SelectLanguage" | localize}} <span class="asterix">*</span></label>
            <select name="language" class="form-control" id="language" formControlName="language">
              <option [ngValue]="null">{{"Select" | localize}}</option>
              <option *ngFor="let lang of languages" [ngValue]="lang.id">{{lang.displayName}}</option>
            </select>
            <validation-messages [formCtrl]="formGroup.get('language')"></validation-messages>
          </div>
          <div class="form-group edition-list row">
            <label for="translation">{{"TranslationOf" | localize}}</label>
            <select name="translation" class="form-control" id="translation" formControlName="translation">
              <option [ngValue]="'none'">{{"None" | localize}}</option>
              <option *ngFor="let category of untranslatedCategories" [ngValue]="category.id">{{category.name}}</option>
            </select>
          </div>
          <div class="form-group edition-list">
            <label for="name">{{"FAQCategoryName" | localize}} <span class="asterix">*</span></label>
            <input name="name" class="form-control m-input" type="text" id="name" formControlName="name"/>
          </div>
          <div class="form-group edition-list">
            <label for="icon">{{"SelectIcon" | localize}} <span class="asterix">*</span></label>
            <div class="icon-selection-box row">
              <div class="icon col-3" *ngFor="let iconName of iconList">
                <input type="radio" [value]="iconName" [id]="iconName" formControlName="icon">
                <label [for]="iconName" class="icon-label d-flex justify-content-center align-items-center">
                    <app-svg-icon [iconName]="iconName"></app-svg-icon>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button [disabled]="saving" type="button" class="btn btn-default"
            (click)="close()">{{"Cancel" | localize}}</button>
          <button type="submit" class="btn btn-primary" id="savingButton"
            [disabled]="!categoryFAQUpdateForm.form.valid" [busyText]="l('SavingWithThreeDot')" [buttonBusy]="saving"
            (onclick)="save()"><i class="fa fa-save"></i>
            <span>{{"Save" | localize}}</span></button>
        </div>
      </form>
    </div>
  </div>
</div>