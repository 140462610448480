import { Component, OnInit, ViewChild, Input, Injector, EventEmitter, Output } from '@angular/core';
import { AgGridAngular } from '@ag-grid-community/angular';
import { RequestService } from '@shared/services/requests.service';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DatePipe } from '@angular/common';
import { AppConsts } from '@shared/AppConsts';
import { FilesOutput } from '@shared/models/files/filesOutput';
import { AddRequestFilesComponent } from '../add-request-files/add-request-files.component';
import { RequestFileInput } from '@shared/models/requestFile/requestFileInput';
import { FileDownloadService } from '@shared/utils/file-download.service';
import { ToastrService } from 'ngx-toastr';
import { GridBtnDeleteComponent } from '@app/shared/grid/grid-btn-delete/grid-btn-delete.component';
import { fileType } from '@shared/models/files/fileType';
import { GridBtnComponent } from '@app/shared/grid/grid-btn/grid-btn.component';
import { Extensions } from '@shared/utils/extensions';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';

@Component({
    selector: 'list-request-files',
    templateUrl: './list-request-files.component.html',
    providers: [DatePipe]
})
export class ListRequestFilesComponent extends AppComponentBase implements OnInit {
    @ViewChild('agGrid') agGrid: AgGridAngular;
    @ViewChild('addRequestFiles') addRequestFiles: AddRequestFilesComponent;
    @Input() requestId: number;
    @Input() requestPublicId: string;
    @Input() allReadOnly = false;
    @Input() automaticUpload = true;
    @Input() title;
    @Input() fileMode: string;
    @Input() requestIsOpen = true;

    @Output() filesLoaded: EventEmitter<any> = new EventEmitter();

    private gridApi;
    public defaultColDef;

    paginationNumberFormatter;
    paginationPageSize = 20;

    filterText = '';
    mandatoryFiles: FilesOutput[] = [];
    filledFiles: FilesOutput[] = [];
    rowData: FilesOutput[] = [];
    selectedFile: FilesOutput;
    rowSelection: string;
    columnDefs: any;
    organization: FilesOutput;
    fileType: fileType;
    description: string;

    constructor(
        private injector: Injector,
        private requestService: RequestService,
        private downloadService: FileDownloadService,
        private _appLocalizationService: AppLocalizationService,
        private datePipe: DatePipe,
        private toastr: ToastrService,
        private _appAuthService: AppAuthService,
        private _dateTimeService: DateTimeService
    ) {
        super(injector);
        this.rowSelection = 'single';

        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };
    }

    ngOnInit() {
        if (this.requestPublicId !== null && this.requestPublicId !== undefined) {
            this.getAllFiles();
            this.manageReadOnly();
        }

        this.columnDefs = [
            {
                headerName: this._appLocalizationService.l('Name'),
                field: 'filesName',
                sortable: true,
                filter: true
            },
            {
                headerName: this._appLocalizationService.l('Size'),
                field: 'fileSize',
                sortable: true,
                filter: true
            },
            {
                headerName: this._appLocalizationService.l('CreationTime'),
                field: 'creationTime',
                sortable: true,
                filter: true,
                valueFormatter: this.dateFormater.bind(this),
                sort: 'desc',
                width: 200
            },
            {
                headerName: '',
                width: 90,
                cellRendererFramework: this.getDeleteBtnIfNotReadOnly(),
                cellRendererParams: {
                    action: this.deleteFile,
                    templateIconStyle: true
                }
            },
            {
                headerName: '',
                width: 90,
                cellClass: 'text-end',
                cellRendererFramework: GridBtnComponent,
                cellRendererParams: {
                    action: (data) => this.getFile(data),
                    icon: 'fas fa-download'
                }
            }
        ];

        this.defaultColDef = { resizable: true, sortable: true, filter: true };
    }

    onPageSizeChanged(e) {
        this.agGrid.api.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    getDeleteBtnIfNotReadOnly() {
        const btn = GridBtnDeleteComponent;
        if (this.allReadOnly) {
            return null;
        } else {
            return btn;
        }
    }

    async getAllFiles() {
        if (this.fileMode === AppConsts.files.mandatoryMode) {
            this.fileType = fileType.MandatoryToComplete;
            this.description = this._appLocalizationService.l('MandatoryFileDescription');
            await this.requestService
                .getMandatoryRequestFiles(this.requestPublicId)
                .toPromise()
                .then((value) => {
                    this.rowData = value.result;
                    this.mandatoryFiles = value.result;
                });
        } else if (this.fileMode === AppConsts.files.filled) {
            this.fileType = fileType.Filled;
            this.description = this._appLocalizationService.l('FileToFiledDescription');
            await this.requestService
                .getFilledRequestFiles(this.requestPublicId)
                .toPromise()
                .then((value) => {
                    this.rowData = value.result;
                    this.filledFiles = value.result;
                });
        } else if (this.fileMode === AppConsts.files.reports) {
            this.fileType = fileType.Reports;
            this.description = this._appLocalizationService.l('FileReportsDescription');
            await this.requestService
                .getReportsRequestFiles(this.requestPublicId)
                .toPromise()
                .then((value) => {
                    this.rowData = value.result;
                });
        }

        this.filesLoaded.emit(null);
    }

    manageReadOnly() {
        //If already read only, we don't update it.
        if (this.allReadOnly) {
            return;
        }

        //Different permissions apply depending on the file mode.
        switch (this.fileMode) {
            case AppConsts.files.filled:
            case AppConsts.files.mandatoryMode:
                this.allReadOnly = !this._appAuthService.hasPermission('Pages.Management.Requests.CanAddConsentFiles');
                break;
            case AppConsts.files.reports:
                this.allReadOnly = !this._appAuthService.hasPermission('Pages.Management.Requests.CanAddReports');
                break;
            default:
                break;
        }

        //If request is closed (statut "Terminé"), we need a special permission to update it.
        if (!this.allReadOnly && !this.requestIsOpen) {
            this.allReadOnly = !this._appAuthService.hasPermission('Pages.Management.Requests.UpdateWhenDone');
        }
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    dateFormater(params: ValueFormatterParams) {
        return this.datePipe.transform(this._dateTimeService.toUtcDate(params.value).toLocal().toString(), AppConsts.dateTimeFormat);
    }

    onSelectionChanged() {
        this.selectedFile = this.agGrid.api.getSelectedRows()[0] as FilesOutput;
    }

    getFile(data) {
        const requestFile: RequestFileInput = {
            files: data,
            requestId: this.requestId,
            fileId: 0,
            id: 0,
            request: null
        };
        this.requestService.getFileDto(requestFile).subscribe((response) => {
            const fileDto = response.result;
            if (fileDto.FileName !== '') {
                this.downloadService.downloadTempFile(fileDto);
            } else {
                this.showErrorMessage('FileNotFound', 'FileTryAgainLater');
            }
        });
    }

    refresh() {
        this.filterText = '';
        this.agGrid.api.setQuickFilter('');
    }

    search() {
        const filterWithoutAccents = Extensions.replaceAccentedCharacters(this.filterText);
        this.agGrid.api.setQuickFilter(filterWithoutAccents);
    }

    deleteFile = (data: FilesOutput) => {
        this.message.confirm(this.l('FileDeleteQuestion', data.filesName), this.l('AreYouSure'), (isConfirmed) => {
            if (isConfirmed) {
                this.requestService.deleteFile(data.publicId, 'requestFile').subscribe((response) => {
                    const fileDto = response.result;
                    if (fileDto.publicId != null) {
                        this.showSuccess('FileDeleteTitle', 'FileDeleted');
                        this.getAllFiles();
                    } else {
                        this.showErrorMessage('FileErrorDelete', 'FileTryAgainLater');
                    }
                });
            }
        }, {
            confirmButtonText: this.l('Yes'),
            cancelButtonText: this.l('No')        
        });
    };

    addFile() {
        this.addRequestFiles.requestId = this.requestId;
        this.addRequestFiles.show();
    }

    showErrorMessage(title: string, errorMessage: string) {
        title = this._appLocalizationService.l(title);
        errorMessage = this._appLocalizationService.l(errorMessage);
        this.toastr.error(errorMessage, title);
    }

    showSuccess(title: string, successMessage: string) {
        title = this._appLocalizationService.l(title);
        successMessage = this._appLocalizationService.l(successMessage);
        this.toastr.success(successMessage, title);
    }
}
