import { Component, Input } from '@angular/core';
import { organizationOutput } from '@shared/models/organization/organizationOutput';

@Component({
    selector: 'app-company-info',
    templateUrl: './company-info.component.html',
    styleUrls: ['./company-info.component.scss']
})
export class CompanyInfoComponent {
    @Input() organization: organizationOutput;
    @Input() lang: string;
}
