// "Production" enabled environment

export const environment = {
    name: 'test',
    production: false,
    hmr: false,
    appConfig: 'appconfig.test.json',
    appInsights: {
        instrumentationKey: '6881a0d9-2d6e-4eff-8467-cd49d4cf5ead'
    },
    auth: {
        domain: 'dev-m7s5i6kkbspjyz14.us.auth0.com',
        clientId: '3KvMMcvf7sNNIsmIn2KrarqbvIKXojZF',
        authorizationParams: {
            audience: 'https://preemploi-test.gardium.com/',
            redirect_uri: window.location.origin,
            scope: 'openid profile email offline_access',
        },
        cacheLocation: 'localstorage',
        authorizeTimeoutInSeconds: 60 * 5
    },
    api: 'https://preemploi-api-test.gardium.com',
    httpInterceptor: {
        allowedList: [
            'https://preemploi-api-test.gardium.com/*'
        ]
    },
};
