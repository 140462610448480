<div [@routerTransition]>
  <div class="content d-flex flex-column flex-column-fluid">
    <sub-header [title]="'Invoices' | localize" [description]="'InvoiceUpdate' | localize">
      <div role="actions">
        <button (click)="returnToDetails()" class="btn btn-secondary">
          <i class="fas fa-times"></i>
          {{'Cancel' | localize}}</button>
        <button class="btn btn-primary" (click)="onUpdate()" [disabled]="isInvoiceUpdated">
          <i class="la la-floppy-o"></i>
          {{"Save" | localize}}
        </button>
      </div>
    </sub-header>
  </div>
  <div class="m-content">
    <form [formGroup]="formGroup">
      <div [class]="containerClass">
        <div class="card card-custom listContainer mb-10">
          <div class="card-body">
            <h5>{{"OrganizationDetails" | localize}}</h5>
            <div class="row">
              <div class="form-group col-md-4 purchaseOrderClassOrganization">
                <label for="organization">{{"Organization" | localize}}</label>
                <input id="organization" formControlName="organization" type="text" name="organization"
                  class="form-control" maxlength="256">
              </div>
              <div class="form-group col-md-4 purchaseOrderClassInvoiceNumber">
                <label for="invoiceNumber">{{"InvoiceNumber" | localize }} </label>
                <input id="invoiceNumber" formControlName="invoiceNumber" [dropSpecialCharacters]="false"
                  mask="000 000 000" type="text" name="invoiceNumber" class="form-control" maxlength="256">
              </div>
              <div class="form-group  col-md-4" *ngIf="ispurchaseOrderAvailable">
                <label for="purchaseOrder">{{"PurchaseOrder" | localize }} </label>
                <input id="purchaseOrder" formControlName="purchaseOrder" type="text" name="purchaseOrder"
                  class="form-control" maxlength="256">
              </div>
            </div>
            <div class="form-group">
              <label for="contactName">{{"ContactName" | localize}} <span class="asterix">*</span></label>
              <input id="contactName" formControlName="contactName" type="text" name="contactName" class="form-control"
                maxlength="256">
              <validation-messages [formCtrl]="formGroup.get('contactName')">
              </validation-messages>
            </div>
            <div class="form-group">
              <label for="invoiceEmail">{{"InvoiceEmail" | localize}} <span class="asterix">*</span></label>
              <input id="invoiceEmail" formControlName="invoiceEmail" type="text" name="invoiceEmail"
                class="form-control" maxlength="256">
              <validation-messages [formCtrl]="formGroup.get('invoiceEmail')">
              </validation-messages>
            </div>
            <app-address-fields [formGroup]="formGroup" mandatoryRule="all"></app-address-fields>
          </div>
        </div>
        <div class="card card-custom mb-10">
          <div class="card-body">
            <div class="form-group col-md-12" style='padding:0px;'>
              <label for="clientName">{{"FilesName" | localize}}</label>
              <input id="clientName" formControlName="clientName" type="text" name="clientName" class="form-control"
                maxlength="256">
            </div>
            <div>
              <grid-pagination class="grid-pagination" (sizeValue)="onPageSizeChangedFirstGrid($event)">
              </grid-pagination>
              <ag-grid-angular localizeText class="ag-theme-material gardium-grid" [rowSelection]="rowSelection"
                [gridOptions]="topOptions" [columnDefs]="columnDefsForFirst" [rowData]="rowDataForFirst"
                [paginationPageSize]="paginationPageSizeFirst" domLayout="autoHeight"
                [paginationNumberFormatter]="paginationNumberFormatterFirst" [pagination]="true"
                (gridReady)="onGridReady($event)" [defaultColDef]="defaultColDefFirst" #topGrid>
              </ag-grid-angular>
            </div>
          </div>
        </div>
        <div class="card card-custom mb-10">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="mr-auto col-auto">
                <h5 for="clientName">{{"Discount" | localize}}</h5>
              </div>
              <div class="col text-end mt-3 mt-md-0">
                <button class="btn btn-danger invoiceUpdateAdjs-btn" (click)="onRemoveSelected()">
                  <i class="fas fa-trash-alt"></i>
                  {{"RemoveAdjustment" | localize}}
                </button>
                <button class="btn btn-primary" (click)="onAddAdjustment()">
                  <i class="fas fa-plus"></i>
                  {{"AddAdjustment" | localize}}
                </button>
              </div>
            </div>
            <div class="grid-pagination-space">
              <grid-pagination class="grid-pagination" (sizeValue)="onPageSizeChangedSecondGrid($event)">
              </grid-pagination>
              <ag-grid-angular localizeText class="ag-theme-material gardium-grid" [rowSelection]="rowSelection"
                [gridOptions]="bottomOptions" [columnDefs]="columnDefsForSecond" [rowData]="rowDataForSecond"
                domLayout="autoHeight" (cellValueChanged)="onRowValueChanged($event)"
                [paginationPageSize]="paginationPageSizeSecond" [stopEditingWhenGridLosesFocus]="true"
                [paginationNumberFormatter]="paginationNumberFormatterSecond"
                [getContextMenuItems]="getContextMenuItems" [pagination]="true" (gridReady)="onGridReady($event)"
                [defaultColDef]="defaultColDefSecond" #bottomGrid>
              </ag-grid-angular>
            </div>
          </div>
        </div>
        <div class="card card-custom mb-10">
          <div class="card-body">
            <h5>{{"Summary" | localize}}</h5>
            <div class="row">
              <div class="form-group col-md-6">
                <label for="subTotal">{{"SubTotal" | localize}}</label>
                <input id="subTotal" formControlName="subTotal" type="text" name="subTotal" class="form-control"
                  maxlength="256">
              </div>
              <div class="form-group col-md-6" *ngIf="isGranted('Pages.Administration.Invoices.Status.Update')">
                <label for="status">{{"status" | localize}}</label>
                <input id="status" formControlName="status" type="text" name="status" class="form-control"
                  maxlength="256">
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label for="tps">{{"TPS" | localize}}</label>
                <input id="tps" formControlName="tps" type="text" name="tps" class="form-control" maxlength="256">
              </div>
              <div class="form-group col-md-6">
                <label for="tvq">{{"TVQ" | localize}}</label>
                <input id="tvq" formControlName="tvq" type="text" name="tvq" class="form-control" maxlength="256">
              </div>
            </div>
            <div class="form-group col-md-12" style='padding:0px;'>
              <label for="total">{{"Total" | localize}}</label>
              <input id="total" formControlName="total" type="text" name="total" class="form-control" maxlength="256">
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
