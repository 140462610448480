import { Component, Injector, OnInit, Input } from '@angular/core';
import { ThemesLayoutBaseComponent } from '../themes/themes-layout-base.component';
import { ChangeUserLanguageDto, ProfileServiceProxy } from '@shared/service-proxies/service-proxies';
import { filter as _filter } from 'lodash-es';

interface ILanguageInfo {
    name: string;
    displayName: string;
    icon: string;
    isDefault: boolean;
}
@Component({
    selector: 'language-switch-dropdown',
    templateUrl: './language-switch-dropdown.component.html',
})
export class LanguageSwitchDropdownComponent extends ThemesLayoutBaseComponent implements OnInit {
    @Input() isDropup = false;
    @Input() customStyle =
        'btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px me-2';
    languages: ILanguageInfo[];
    currentLanguage: ILanguageInfo;

    public constructor(injector: Injector, private _profileServiceProxy: ProfileServiceProxy) {
        super(injector);
    }

    ngOnInit(): void {
        this.languages = [];
        import('assets/localizations.json').then((localization) => {
            this.languages = localization.languages;
            this.currentLanguage =
                this.languages.filter((x) => x.name === localStorage.getItem('locale')).at(0)
                ?? this.languages.filter((x) => x.isDefault).at(0);
        });
    }

    changeLanguage(languageName: string): void {
        const input = new ChangeUserLanguageDto();
        input.languageName = languageName;

        localStorage.setItem('locale', languageName);
        window.location.reload();

    }
}
