import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';

import { environment as env } from 'environments/environment';

@Injectable()
export class StatesService {
    private http: HttpClient;
    private baseUrl: string;
    private replays: Partial<Record<string, ReplaySubject<any>>> = Object.create(null);

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = env.api;
    }

    getStatesList(): Observable<any> {
        return this.getReplay('getStatesList');
    }

    getStatesFromCountry(countryId: number): Observable<any> {
        return this.getReplay('getStatesFromCountry', countryId);
    }

    getQuebec(): Observable<any> {
        return this.getReplay('getQuebec');
    }

    private _getStatesList(): Observable<any> {
        const url = this.getUrl('/api/states');
        return this.http.get<any>(url);
    }

    private _getStatesFromCountry(countryId: number): Observable<any> {
        const url = this.getUrl('/api/states/countryId');
        const params = new HttpParams().set('countryId', countryId.toString());
        return this.http.get<any>(url, { params });
    }

    private _getQuebec(): Observable<any> {
        const url = this.getUrl('/api/states/getQuebec');
        return this.http.get<any>(url);
    }

    private getReplay(key: keyof StatesService, ...args: any[]) {
        const replaykey = key + JSON.stringify(args);
        const replay = (this.replays[replaykey] || this.makeReplay(replaykey, this['_' + key](...args)));
        return replay.pipe();
    }

    private makeReplay(replayKey: string, observable: Observable<any>): ReplaySubject<any> {
        const replay: ReplaySubject<any> = new ReplaySubject(1);
        this.replays[replayKey] = replay;
        observable.subscribe({
            next: (data) => replay.next(data),
            error: (err) => {
                replay.error(err);
                if (replay === this.replays[replayKey]) {
                    this.replays[replayKey] = null;
                }
            },
            complete: () => replay.complete()
        });
        return replay;
    }

    private getUrl(url: string): string {
        let url_ = this.baseUrl + url;
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    }
}
