<div class="row align-items-center m--margin-bottom-10">
  <div class="col-xl-12">
    <div class="form-group m-form__group align-items-center">
      <div class="input-group">
        <input [(ngModel)]="filterText" type="text" id="filterText" [placeholder]="l('Search')" autoFocus
          class="form-control m-input" (keydown.enter)="search()" />
        <span class="input-group-btn">
          <button (click)="search()" class="btn btn-primary" type="submit">
            <i class="flaticon-search-1" [attr.aria-label]="l('Search')">
            </i>
          </button>
        </span>
        <span class="input-group-btn" style="padding-left:5px;">
          <button class="btn btn-secondary" (click)="refresh()"><i class="la la-refresh"></i>
            {{'Refresh' | localize}}</button>
        </span>
      </div>
    </div>
  </div>
</div>
<grid-pagination class="grid-pagination" (sizeValue)="onPageSizeChanged($event)"></grid-pagination>
<!-- verification list -->
<ag-grid-angular #agGrid localizeText [updateList]="'organizationVerifications'"
  class="ag-theme-material gardium-grid" [rowSelection]="rowSelection" [rowData]="rowData"
[paginationPageSize]="paginationPageSize" domLayout="autoHeight"
[paginationNumberFormatter]="paginationNumberFormatter" [pagination]="true" (gridReady)="onGridReady($event)"
[defaultColDef]="defaultColDef" [columnDefs]="columnDefs">
</ag-grid-angular>
