import { Component, OnInit, Input, Output, EventEmitter, Injector, HostListener } from '@angular/core';
import { RequestVerificationOutput } from '@shared/models/requestVerification/requestVerificationOutput';
import { GridApi, ColDef } from '@ag-grid-enterprise/all-modules';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RequestVerificationUpdateComponent } from '../request-verification-update/request-verification-update.component';
import { RequestVerificationStatus } from '@shared/models/requestVerification/requestVerificationStatus';
import { AppComponentBase } from '@shared/common/app-component-base';
import { RequestOutput } from '@shared/models/request/requestOutput';
import { InvoiceService } from '@shared/services/invoice.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { RequestVerificationService } from '@shared/services/requestVerification.service';
import { RequestService } from '@shared/services/requests.service';
import { RequestVerificationInput } from '@shared/models/requestVerification/requestVerificationInput';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { toRequestVerificationNoteInput } from '@shared/models/requestVerification/requestVerificationNoteInput';
import { InvoiceBillingModeEnum } from '@shared/models/InvoiceElements/invoice/invoiceBillingMode';
import { BinaryResponse } from '@shared/models/boolean-string.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import { EmailResult, EmailSenderResult } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'app-requestverification-quantity-confirmation',
    templateUrl: './requestverification-quantity-confirmation.component.html'
})
export class RequestverificationQuantityConfirmationComponent extends AppComponentBase implements OnInit {
    @Input() request: RequestOutput;
    @Output() onSave: EventEmitter<RequestVerificationOutput[]> = new EventEmitter();
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    InvoiceBillingModeEnum = InvoiceBillingModeEnum;
    gridRequestVerificationApi: GridApi;
    columnDefsRequestVerifications: ColDef[];
    defaultColDefRequestVerifications: ColDef;
    paginationNumberFormatter;
    paginationPageSize = 5;
    requestVerifications: RequestVerificationOutput[] = [];
    invoiceDate: string;

    invoiceFormGroup = new UntypedFormGroup({
        InvoiceDate: new UntypedFormControl({ value: '' }),
        InvoiceBillingMode: new UntypedFormControl('', Validators.required)
    });
    public loading$: Observable<boolean>;
    private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private modalRef: BsModalRef;

    constructor(
        injector: Injector,
        public bsModalRef: BsModalRef,
        private _invoiceService: InvoiceService,
        private _requestVerificationService: RequestVerificationService,
        private _requestService: RequestService,
        private toastr: ToastrService,
        private modalService: BsModalService,
        private _router: Router,
        private _appLocalizationService: AppLocalizationService
    ) {
        super(injector);
        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.gridRequestVerificationApi) {
            this.gridRequestVerificationApi.sizeColumnsToFit();
        }
    }

    ngOnInit() {
        this.requestVerifications = this.request.requestVerifications;
        if (this.requestVerifications?.length) {
            this.initColumns();
        }
        this.invoiceFormGroup.controls[ 'InvoiceDate' ].setValue(new Date());
        this.loading$ = this.loading.asObservable();
    }

    initColumns() {
        this.columnDefsRequestVerifications = [
            {
                headerName: this.l('Code'),
                valueGetter: (params) => params.data.verification.code
            },
            {
                headerName: this.l('Type'),
                valueGetter: (params) => params.data.verification.type
            },
            {
                headerName: this.l('Status'),
                field: 'status',
                valueFormatter: (params) => this.l(RequestVerificationStatus[params.value])
            },
            {
                headerName: this.l('Quantity'),
                field: 'quantity',
                valueFormatter: (params) => params.data.quantity,
                editable: true
                //onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
            },
            {
                headerName: this.l('VerificationExpressService'),
                field: 'verificationExpressService',
                valueFormatter: (params) => {
                    const isExpress = params.data.isExpress as boolean;
                    const canBeExpressValue = isExpress ? 'Yes' : 'No';
                    const canBeExpress = params.data.verification.canBeExpress;
                    const translation = canBeExpress ? BinaryResponse[canBeExpressValue] : 'NonEligible';

                    return this.l(translation);
                },
            }
        ];
    }

    onRequestVerificationGridReady(params) {
        this.gridRequestVerificationApi = params.api;
        this.gridRequestVerificationApi.sizeColumnsToFit();
    }

    editRequestVerification(requestVerification: RequestVerificationOutput) {
        const initialState = {
            requestVerification: requestVerification
        };

        this.modalRef = this.modalService.show(RequestVerificationUpdateComponent, { initialState, class: 'modal-dialog-centered', backdrop: 'static' });
        (this.modalRef.content as RequestVerificationUpdateComponent).onSave.subscribe((requestVerification: RequestVerificationOutput) => {
            const requestVerificationFound = this.requestVerifications.find((x) => x.id === requestVerification.id);

            requestVerificationFound.status = requestVerification.status;
            requestVerificationFound.quantity = requestVerification.quantity;
            requestVerificationFound.requestVerificationNotes = requestVerification.requestVerificationNotes;

            this.gridRequestVerificationApi.updateRowData({
                update: [requestVerificationFound]
            });
        });
    }

    toRequestVerificationInput = (output: RequestVerificationOutput): RequestVerificationInput => ({
        id: output.id,
        countryId: output.countryId,
        quantity: output.quantity,
        requestId: this.request.id,
        status: output.status,
        verificationCustomPrice: output.verificationCustomPrice,
        verificationId: output.verification.id,
        verificationPrice: output.verificationPrice,
        creationDate: output.creationDate,
        result: output.result,
        isExpress: output.isExpress,
        expressPrice: output.expressPrice || output.verification?.expressPrice || 0,
        detailedResult: output.detailedResult,
        verificationCustomField: output.verificationCustomField,
        requestVerificationNotes: output.requestVerificationNotes.map((x) => toRequestVerificationNoteInput(x)),
    });

    onPageSizeChanged(e) {
        this.gridRequestVerificationApi.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }


    async save() {
        if (!this.request) {
            return;
        }
        this.loading.next(false);
        const updatedRequestVerifications = [ ...this.requestVerifications];
        const requestVerificationInputs: RequestVerificationInput[] = [...updatedRequestVerifications].map((element) => this.toRequestVerificationInput(element));

        await this._requestVerificationService.updateRequestVerifications(requestVerificationInputs).toPromise().then();

        const invoiceBillingMode = this.invoiceFormGroup.controls['InvoiceBillingMode'].value;
        if (invoiceBillingMode === InvoiceBillingModeEnum.PaidByCandidate && this.request.candidat.email === '') {
            this.showErrorCandidateEmailNotProvided();
            this.closeModal();
            return;
        }
        this.loading.next(true);
        this._invoiceService
            .generateInvoice(this.request.publicId, this.invoiceDate, invoiceBillingMode)
            .toPromise()
            .then((response) => {
                if (response.success) {
                    //In case of a cancelled request, invoice is not created
                    if (response.result?.id === 0) {
                        this.showWarning('InvoiceCreatedTitle', 'InvoiceCreatedWarn');
                    } else {
                        this.showSuccess('InvoiceCreatedTitle', 'InvoiceCreatedSuccess');

                        this._invoiceService.GetCustomizeInvoice(response.result.publicId).subscribe((response) => {
                            this._invoiceService.sendInvoiceLinkToInvoiceEmail(response.result.publicId).subscribe((response) => {
                                const result = response.result as EmailSenderResult;
                                if (result.emailSentResult === EmailResult.Sent) {
                                    this.showEmailSendSuccess();
                                } else {
                                    this.showErrorSendingEmailMessage();
                                }
                                this.loading.next(false);
                                this.closeModal();
                            });
                        });
                    }
                    this.closeModal();
                } else {
                    this.loading.next(false);
                    this.showErrorMessage('InvoiceCreatedTitle', 'InvoiceCreatedFail');
                }
            });
    }

    closeModal() {
        this.onSave.emit(this.request.requestVerifications);
        this.bsModalRef.hide();
        this.modalSave.emit(null);
    }

    showErrorCandidateEmailNotProvided() {
        const title = this._appLocalizationService.l('SendInvoiceWithEmail');
        const successMessage = this._appLocalizationService.l('CandidateEmailNotProvidedError');
        this.toastr.error(successMessage, title);
    }

    showEmailSendSuccess() {
        const title = this._appLocalizationService.l('SendInvoiceWithEmailFirstime');
        const successMessage = this._appLocalizationService.l('EmailSentSuccess');
        this.toastr.success(successMessage, title);
    }

    showErrorSendingEmailMessage() {
        const title = this._appLocalizationService.l('SendInvoiceWithEmailFirstime');
        const errorMessage = this._appLocalizationService.l('EmailSentError');
        this.toastr.error(errorMessage, title);
    }

    refresh() {
        this._router.navigate(['/requests-details', this.request.publicId]);
    }

    showSuccess(title: string, successMessage: string) {
        title = this._appLocalizationService.l(title);
        successMessage = this._appLocalizationService.l(successMessage);
        this.toastr.success(successMessage, title);
    }

    showWarning(title: string, warningMessage: string) {
        title = this._appLocalizationService.l(title);
        warningMessage = this._appLocalizationService.l(warningMessage);
        this.toastr.warning(warningMessage, title);
    }

    showErrorMessage(title: string, errorMessage: string) {
        title = this._appLocalizationService.l(title);
        errorMessage = this._appLocalizationService.l(errorMessage);
        this.toastr.error(errorMessage, title);
    }

    onInvoiceDateSelection(value: Date): void {
        this.invoiceDate = value.toISOString();
    }
}
