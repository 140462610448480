import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { RequestOutput } from '@shared/models/request/requestOutput';
import { RequestVerificationOutput } from '@shared/models/requestVerification/requestVerificationOutput';
import { RequestVerificationStatus } from '@shared/models/requestVerification/requestVerificationStatus';
import { OrganizationService } from '@shared/services/organization.service';
import { ReportLocalizationService } from '@shared/services/report-localization.service';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'app-request-summary-report',
    templateUrl: './request-summary-report.component.html',
    styleUrls: ['./request-summary-report.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class RequestSummaryReportComponent implements OnInit {
    @Input() request: RequestOutput;
    @Output() ready: EventEmitter<void> = new EventEmitter<void>();

    verificationPerPage: RequestVerificationOutput[][] = [];
    detailedVerificationPerPage: RequestVerificationOutput[][] = [];

    maxVerificationResultsPerPage = 10;
    maxDetailedVerificationResultsPerPage = 3;
    organizationLogo: string;

    constructor(
        private organizationService: OrganizationService,
        public reportLocalizationService: ReportLocalizationService
    ) { }

    ngOnInit(): void {
        const verifications = this.request.requestVerifications.filter(
            (verification) =>
                ![
                    RequestVerificationStatus.Canceled,
                    RequestVerificationStatus.MissingInformations,
                    RequestVerificationStatus.CantBeCompleted,
                ].includes(verification.status)
        );

        this.verificationPerPage = this.splitArray(
            verifications,
            this.maxVerificationResultsPerPage
        );

        this.detailedVerificationPerPage = this.splitArray(
            verifications,
            this.maxDetailedVerificationResultsPerPage
        );

        this.organizationService
            .getOrganizationLogo(this.request.organization.id)
            .pipe(
                tap((response) => {
                    if (response.result === null) {
                        this.organizationLogo = '';
                    } else {
                        this.organizationLogo = 'data:image/jpeg;base64,' + response.result;
                    }
                })
            )
            .subscribe(() => {
                this.ready.emit();
            });
    }

    splitArray(list: any[], size: number) {
        const arrayOfArrays = [];
        for (let i = 0; i < list.length; i += size) {
            arrayOfArrays.push(list.slice(i, i + size));
        }
        return arrayOfArrays;
    }
}
