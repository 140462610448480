<img class="logo" width="154" src="/assets/common/images/gardium2.png"/>

<div class="info">
    <h3>{{"RequestReportResultSummaryCompanySectionTitle" | reportLocalize : lang}}</h3>
    <div class="icon">
        <img  src='/assets/common/images/phoneIcon.png'>
    </div>
    <p class="phone">(450) 974-4034</p>
</div>

<div class="info-container">
    <div class="info">
        <div class="icon">
            <img src='/assets/common/images/emailIcon.png'>
        </div>
        <p>preemploi@gardium.com</p>
    </div>
    <div class="info">
        <div class="icon">
            <img src='/assets/common/images/mapIcon.png'>
        </div>
        <p class="address">
            136, Saint Laurent,<br>
            St-Eustache, QC<br> 
            J7P 5G1
        </p>
    </div>
</div>
