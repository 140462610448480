import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { IServerSideDatasource, IServerSideGetRowsParams } from '@ag-grid-enterprise/all-modules';
import { SortModel } from '@shared/models/request/sortModel';
import { FilterModel } from '@shared/models/request/filterModel';
import { Subject } from 'rxjs';
import { environment as env } from 'environments/environment';
import { overallAdjustLocalDateRangeToUtcDateTimeRange } from '@shared/utils/date-time/adjust-local-date-range-to-utc-date-time-range';

@Injectable({
    providedIn: 'root'
})
export class InvoiceDataSourceService implements IServerSideDatasource {
    public firstDataRenderedSubject = new Subject<any>();
    public sortChangedSubject = new Subject<any>();
    public filterChangedSubject = new Subject<any>();
    public selectionChangedSubject = new Subject<any>();
    public gridReadySubject = new Subject<any>();
    private http: HttpClient;
    private baseUrl: string;

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = env.api;
    }

    getRows(params: IServerSideGetRowsParams): void {
        const filterModel = overallAdjustLocalDateRangeToUtcDateTimeRange(params.request.filterModel);

        this.getInvoicesList(params.request.startRow, params.request.endRow, params.request.sortModel, filterModel)
            .toPromise()
            .then(
                (value) => {
                    params.successCallback(
                        value.result.results,
                        value.result.count
                    );
                },
                (error) => {
                    console.error(error);
                    params.failCallback();
                }
            );
    }

    getExcelWithTaxes(filterModel: any, sortModel: any) {
        filterModel = overallAdjustLocalDateRangeToUtcDateTimeRange(filterModel);
        return this.getExcelInvoicesList(sortModel, filterModel);
    }

    private getInvoicesList(startRow: number, endRow: number, sortModel: SortModel[], filterModel: FilterModel): Observable<any> {
        const url = this.getUrl('/api/Invoices/filter');
        const request = {
            startRow,
            endRow,
            sortModel,
            filterModel
        };
        return this.http.post<any>(url, request);
    }

    private getExcelInvoicesList(sortModel: SortModel[], filterModel: FilterModel): Observable<any> {
        const url = this.getUrl('/api/Invoices/GetFilteredExcelInvoices');
        const request = {
            sortModel,
            filterModel
        };
        return this.http.post<any>(url, request);
    }

    private getUrl(url: string): string {
        url = this.baseUrl + url;
        return url.replace(/[?&]$/, '');
    }
}
