import { Component, OnInit, ViewChild, Input, Injector, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DatePipe } from '@angular/common';
import { AcademicRecordOutput } from '@shared/models/CandidatDetails/academicRecords/academicRecordOutput';
import { AcademicRecordAddComponent } from '../academic-record-add/academic-record-add.component';
import { AgGridAngular } from '@ag-grid-community/angular';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AcademicRecordService } from '@shared/services/academicRecord.service';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LazyLoadEvent } from 'primeng/api';
import { AcademicRecordUpdateComponent } from '../academic-record-update/academic-record-update.component';
import { GridBtnUpdateComponent } from '@app/shared/grid/grid-btn-update/grid-btn-update.component';
import { GridBtnDeleteComponent } from '@app/shared/grid/grid-btn-delete/grid-btn-delete.component';
import { InformationSourceDTO } from '@shared/models/request/informationSourceDTO';
import { InvoiceStatusEnum } from '@shared/models/InvoiceElements/invoice/invoiceStatus';

@Component({
    selector: 'academic-record-list',
    templateUrl: './academic-record-list.component.html',
    providers: [DatePipe]
})
export class AcademicRecordListComponent extends AppComponentBase implements OnInit {
    @ViewChild('agGrid') agGrid: AgGridAngular;
    @ViewChild('academicRecordAddModal') academicRecordAddModal: AcademicRecordAddComponent;
    @Input() requestPublicId: string;
    @Input() candidateId: number;
    @Input() candidatePublicId: string;
    @Input() requestOpen: boolean;
    @Input() informationSource: InformationSourceDTO = InformationSourceDTO.Candidate;
    @Output() modalRefresh: EventEmitter<any> = new EventEmitter<any>();
    @Input() invoiceStatus: InvoiceStatusEnum;
    @Input() isAllowedToUpdateRequest: boolean;

    paginationNumberFormatter;
    paginationPageSize = 20;

    public defaultColDef;
    rowData: AcademicRecordOutput[] = [];
    publicId: string;
    selectedAcademicRecord: AcademicRecordOutput;
    bsModalRef: BsModalRef;
    rowSelection: string;
    allReadOnly: boolean;
    columnDefs: any;
    invoiceSent: boolean;

    constructor(
        private injector: Injector,
        private modalService: BsModalService,
        private datePipe: DatePipe,
        private _appLocalizationService: AppLocalizationService,
        private _route: ActivatedRoute,
        private service: AcademicRecordService,
        private toastr: ToastrService
    ) {
        super(injector);
        this.rowSelection = 'single';

        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };
    }

    onPageSizeChanged(e) {
        this.agGrid.api.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    ngOnInit() {
        if (this.invoiceStatus === InvoiceStatusEnum.InvoiceSent) {
            this.invoiceSent = true;
        }
        this.initializeRowData();
    }

    getEditBtnIfNotReadOnly() {
        const btn = GridBtnUpdateComponent;
        if (this.allReadOnly) {
            return null;
        } else if (this.isAllowedToUpdateRequest) {
            return btn;
        } else {
            return null;
        }
    }

    getDeleteBtnIfNotReadOnly() {
        const btn = GridBtnDeleteComponent;
        if (this.allReadOnly) {
            return null;
        } else if (this.invoiceSent) {
            return null;
        } else {
            return btn;
        }
    }

    concatDiplomaYear(params) {
        return params.data.diploma + ', ' + params.data.year;
    }

    concatTelephonePoste(params) {
        let prefix = ' + 1 ';
        if (params.data.phoneExtension === null) {
            params.data.phoneExtension = '';
        }
        if (params.data.phoneNumber === null || params.data.phoneNumber === '') {
            params.data.phoneNumber = '';
            prefix = '';
        }
        const phoneExtension = params.data.phoneExtension !== '' ? '#' + params.data.phoneExtension : '';
        return prefix + params.data.phoneNumber + ' ' + phoneExtension;
    }

    initializeRowData() {
        this.service
            .getAcademicRecordsByCandidat(this.candidatePublicId)
            .toPromise()
            .then((response) => {
                this.rowData = response.result;
                this.allReadOnly = false;
            });

        this.columnDefs = [
            {
                headerName: this._appLocalizationService.l('Institution'),
                width: 180,
                field: 'instutitionName',
                sortable: true,
                filter: true
            },
            {
                headerName: this._appLocalizationService.l('Diploma'),
                width: 180,
                valueGetter: this.concatDiplomaYear,
                sortable: true,
                filter: true
            },
            {
                headerName: this._appLocalizationService.l('PermanentCode'),
                width: 180,
                field: 'permanentCode',
                sortable: true,
                filter: true
            },
            {
                headerName: this._appLocalizationService.l('PhoneNumber'),
                width: 250,
                valueGetter: this.concatTelephonePoste,
                sortable: true,
                filter: true
            },
            {
                headerName: this._appLocalizationService.l('Location'),
                width: 200,
                field: 'location',
                sortable: true,
                filter: true
            },
            {
                headerName: '',
                width: 110,
                cellRendererFramework: this.getEditBtnIfNotReadOnly(),
                cellRendererParams: { action: this.updateAcademicRecord }
            },
            {
                headerName: '',
                width: 110,
                cellRendererFramework: this.getDeleteBtnIfNotReadOnly(),
                cellRendererParams: { action: this.deleteAcademicRecord }
            }
        ];
        this.defaultColDef = { resizable: true, sortable: true, filter: true };
    }

    addAcademicRecord() {
        this.academicRecordAddModal.show();
    }

    updateAcademicRecord = async (academicRecord: AcademicRecordOutput) => {
        const publicId: string = academicRecord.publicId; 
        this.rowData.forEach((element) => {
            if (element.publicId === publicId) {
                this.selectedAcademicRecord = element;
            }
        });

        this.bsModalRef = this.modalService.show(AcademicRecordUpdateComponent, {
            initialState: {
                selectedAcademicRecord: this.selectedAcademicRecord
            },
            backdrop: 'static'
        });

        this.bsModalRef.content.modalSave.subscribe((result) => {
            this.refresh();
        });
    };

    deleteAcademicRecord = async (academicRecord: AcademicRecordOutput) => {
        this.service.deleteAcademicRecord(academicRecord.publicId).subscribe((response) => {
            const academicRecord = response.result;
            this.initializeRowData();
            this.modalRefresh.emit(null);
            if (academicRecord.id != null) {
                this.showSuccess();
            } else {
                this.showErrorMessage();
            }
        });
    };

    showSuccess() {
        const title = this._appLocalizationService.l('AcademicRecordDelete');
        const successMessage = this._appLocalizationService.l('AcademicRecordDeleted');
        this.toastr.success(successMessage, title);
    }

    showErrorMessage() {
        const title = this._appLocalizationService.l('AcademicRecordDeleteIssue');
        const errorMessage = this._appLocalizationService.l('AcademicRecordDeleteTryAgainLater');
        this.toastr.error(errorMessage, title);
    }

    refresh(event?: LazyLoadEvent) {
        this.initializeRowData();
        this.modalRefresh.emit(null);
    }
}
