import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AutoFocusDirective } from './auto-focus.directive';
import { BusyIfDirective } from './busy-if.directive';
import { ButtonBusyDirective } from './button-busy.directive';
import { FileDownloadService } from './file-download.service';
import { FriendProfilePictureComponent } from './friend-profile-picture.component';
import { LuxonFormatPipe } from './luxon-format.pipe';
import { LuxonFromNowPipe } from './luxon-from-now.pipe';
import { ValidationMessagesComponent } from './validation-messages.component';
import { EqualValidator } from './validation/equal-validator.directive';
import { PasswordComplexityValidator } from './validation/password-complexity-validator.directive';
import { NullDefaultValueDirective } from './null-value.directive';
import { ScriptLoaderService } from './script-loader.service';
import { StyleLoaderService } from './style-loader.service';
import { ArrayToTreeConverterService } from './array-to-tree-converter.service';
import { TreeDataHelperService } from './tree-data-helper.service';
import { LocalizePipe } from '@shared/common/pipes/localize.pipe';
import { PermissionPipe } from '@shared/common/pipes/permission.pipe';
import { PermissionAnyPipe } from '@shared/common/pipes/permission-any.pipe';
import { PermissionAllPipe } from '@shared/common/pipes/permission-all.pipe';
import { EscapeHtmlPipe } from '@shared/common/pipes/keep-html.pipe';
import { DatePickerLuxonModifierDirective } from './date-time/date-picker-luxon-modifier.directive';
import { DateRangePickerLuxonModifierDirective } from './date-time/date-range-picker-luxon-modifier.directive';
import { ReportLocalizePipe } from '@shared/common/pipes/report-localize.pipe';
import { EnumToArrayPipe } from '@shared/common/pipes/enum-to-array.pipe';
import { ShortenTextPipe } from '@shared/common/pipes/shorten-text.pipe';
import { OrderByPipe } from '@shared/common/pipes/order-by.pipe';
import { OEmbedPipe } from '@shared/common/pipes/oembed.pipe';
const elements = [
    EqualValidator,
    PasswordComplexityValidator,
    ButtonBusyDirective,
    AutoFocusDirective,
    BusyIfDirective,
    FriendProfilePictureComponent,
    LuxonFormatPipe,
    LuxonFromNowPipe,
    ValidationMessagesComponent,
    NullDefaultValueDirective,
    LocalizePipe,
    EnumToArrayPipe,
    ReportLocalizePipe,
    PermissionPipe,
    PermissionAnyPipe,
    DatePickerLuxonModifierDirective,
    DateRangePickerLuxonModifierDirective,
    PermissionAllPipe,
    EscapeHtmlPipe,
    ShortenTextPipe,
    OrderByPipe,
    OEmbedPipe
];
@NgModule({
    imports: [CommonModule],
    providers: [
        FileDownloadService,
        ScriptLoaderService,
        StyleLoaderService,
        ArrayToTreeConverterService,
        TreeDataHelperService,
    ],
    declarations: [
        ...elements
    ],
    exports: [
        ...elements
    ],
})
export class UtilsModule {}
