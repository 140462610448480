import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { country } from '@shared/models/shared/country';

import { environment as env } from 'environments/environment';

@Injectable()
export class CountryService {
    private http: HttpClient;
    private baseUrl: string;
    private replays: Partial<Record<keyof CountryService, ReplaySubject<any>>> = Object.create(null);

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = env.api;
    }

    getCountryList(): Observable<any> {
        return this.getReplay('getCountryList');
    }

    getCanadaCountryList(): Observable<any> {
        return this.getReplay('getCanadaCountryList');
    }

    private _getCountryList(): Observable<any> {
        const url = this.getUrl('/api/country');
        return this.http.get<any>(url);
    }

    private _getCanadaCountryList(): Observable<any> {
        const url = this.getUrl('/api/country/getCanada');
        return this.http.get<any>(url);
    }

    private getReplay(key: keyof CountryService) {
        const replay = this.replays[key] || this.makeReplay(key, this['_' + key]());
        return replay.pipe();
    }

    private makeReplay(key: keyof CountryService, observable: Observable<any>): ReplaySubject<any> {
        const replay: ReplaySubject<any> = new ReplaySubject(1);
        this.replays[key] = replay;
        observable.subscribe({
            next: (data) => replay.next(data),
            error: (err) => {
                replay.error(err);
                if (replay === this.replays[key]) {
                    this.replays[key] = null;
                }
            },
            complete: () => replay.complete()
        });
        return replay;
    }

    private getUrl(url: string): string {
        let url_ = this.baseUrl + url;
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    }
}
