<form [formGroup]="formGroup" autocomplete="off">
    <div [class]="containerClass">
        <div class="card card-custom mb-10">
            <div class="card-body">
                <div *ngIf="verifications?.length === 0">
                    <div class="alert alert-warning">{{ 'OrganizationHaveNotVerifications' | localize }}</div>
                </div>
                <div *ngIf="verifications?.length > 0">
                    <div class="alert alert-warning" *ngIf="noVerificationIsSelected()">
                        {{ 'PleaseSelectAVerification' | localize }}
                    </div>
                    <h5 class="mb-4">
                        {{ 'Verifications' | localize }}
                        <span class="asterix">*</span>
                    </h5>

                    <div class="row" *ngFor="let tag of verifications; let i = index">
                        <div class="col-md-8">
                            <div class="d-flex align-items-center">
                                <p class="d-flex align-items-center">
                                    <input
                                        class="form-check-input me-2"
                                        type="checkbox"
                                        [id]="tag.id"
                                        name="tagOptions"
                                        (change)="changeCheckbox(tag)"
                                        [checked]="tag.checked"
                                        [disabled]="!this.requestWizard.isRequestDraft()"
                                    />
                                    {{ tag.code }} - {{ tag.type }}
                                    <validation-messages [formCtrl]="formGroup.get('verifications')"></validation-messages>
                                </p>
                                <p class="ps-3 d-flex align-items-center" *ngIf="tag.canBeExpress">
                                    <input
                                        class="form-check-input me-2"
                                        [disabled]="!tag.checked"
                                        type="checkbox"
                                        [id]="tag.id + '_express'"
                                        name="tagExpress"
                                        [checked]="tag.isExpress"
                                        (change)="changeVerificationExpress(tag)"
                                    />
                                    <label class="u-color-corporate-gold text-uppercase" [ngClass]="{ disabled: !tag.checked }" [attr.for]="tag.id + '_express'">
                                        <i>
                                            <span class="u-fw(500)">{{ 'RequestWizardVerificationLabel' | localize }}</span>
                                        </i>
                                    </label>
                                </p>
                            </div>
                        </div>
                        <div *ngIf="tag.code === 'DJI' && tag.checked" class="specifications col-md-6">
                            <div class="form-inline">
                                <div class="form-group">
                                    <label for="country">{{ 'Country' | localize }}</label>
                                    <input
                                        id="country"
                                        formControlName="country"
                                        type="text"
                                        name="country"
                                        class="form-control"
                                        maxlength="256"
                                        [typeahead]="countryList"
                                        (typeaheadOnBlur)="typeaheadOnBlur($event)"
                                        typeaheadOptionField="countryName"
                                        [typeaheadScrollable]="true"
                                        [typeaheadOptionsInScrollableView]="5"
                                        autocomplete="off"
                                        (typeaheadOnSelect)="onSelectCountry($event)"
                                        (typeaheadNoResults)="typeaheadNoResults($event)"
                                        [typeaheadMinLength]="0"
                                        typeaheadOptionsLimit="300"
                                    />
                                    <div class="alert alert-danger" *ngIf="noResultCountry">
                                        {{ 'ResultNotFoundElementInList' | localize }}
                                    </div>
                                    <validation-messages [formCtrl]="formGroup.get('country')"></validation-messages>
                                </div>
                                <div *ngIf="isGranted('Pages.Management.Requests.CanSeePrivateInformation')">
                                    <div class="form-group">
                                        <label for="customPriceDji">{{ 'CustomPrice' | localize }}</label>
                                        <input
                                            id="customPriceDji"
                                            formControlName="customPriceDji"
                                            type="text"
                                            name="customPriceDji"
                                            class="form-control"
                                            maxlength="256"
                                            mask="0*.00"
                                            suffix=" $"
                                            [dropSpecialCharacters]="false"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="tag.code === 'ADD' && tag.checked" class="specifications col-md-6">
                            <div class="form-inline">
                                <div class="form-group">
                                    <label for="customfield">{{ 'Service' | localize }}</label>
                                    <input id="customfield" formControlName="customfield" type="text" name="customfield" class="form-control" maxlength="256" />
                                </div>
                                <div class="form-group" *ngIf="isGranted('Pages.Management.Requests.CanSeePrivateInformation')">
                                    <label for="customPriceAdd">{{ 'CustomPrice' | localize }}</label>
                                    <input
                                        id="customPriceAdd"
                                        formControlName="customPriceAdd"
                                        type="text"
                                        name="customPriceAdd"
                                        class="form-control ms-5"
                                        maxlength="256"
                                        mask="0*.00"
                                        suffix=" $"
                                        [dropSpecialCharacters]="false"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer class="w-50 mt-5" *ngIf="canDisplayExpressDescription">
                        <h5 class="u-color-corporate-gold font-weight-normal text-uppercase">
                            <i>{{ 'RequestWizardVerificationDescriptionTitle' | localize }}</i>
                        </h5>
                        <p>
                            {{ 'RequestWizardVerificationDescription' | localize }}
                        </p>
                        <p class="u-color-black">
                            <i>
                                <span class="u-fw(500)">{{ 'RequestWizardVerificationDescriptionFees' | localize }}</span>
                            </i>
                        </p>
                    </footer>
                </div>
            </div>
        </div>
    </div>
    <div
        class="col-md-12 text-center"
        *ngIf="verifications?.length === 0 && isGranted('Pages.Management.Organizations.Update') && isGranted('Pages.Management.Organizations.Details.VerificationLink')"
    >
        <button class="btn btn-secondary btn-default" (click)="goToOrganizationVerificationsList()">
            {{ 'WizzardEditOrganizationVerificationsList' | localize }}
        </button>
    </div>
    <div class="d-flex mt-10">
        <div class="">
            <button class="float-lg-right btn-back p-0 mr-2" awPreviousStep>
                <i class="la la-arrow-circle-o-left btn-back float-lg-right"></i>
            </button>
        </div>
        <div class="text-left justify-content-center align-self-center pl-0">
            <label class="mb-0">{{ 'RequestWizzardPreviousStep' | localize }}</label>
        </div>
        <div class="flex-grow-1"></div>
        <div class="text-end justify-content-center align-self-center pr-0">
            <label class="mb-0">{{ 'RequestWizzardNextStep' | localize }}</label>
        </div>
        <div class="">
            <button class="float-lg-left btn-next p-0 ml-2" [disabled]="noVerificationIsSelected()" (click)="save()" awNextStep>
                <i class="la la-arrow-circle-o-right btn-next float-lg-left"></i>
            </button>
        </div>
    </div>
</form>
