import { Component, Injector, ViewChild, OnInit, Input } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AgGridAngular } from '@ag-grid-community/angular';
import { AppConsts } from '@shared/AppConsts';
import { DatePipe } from '@angular/common';
import { ValueFormatterParams, GridOptions, GridApi, ColDef } from '@ag-grid-enterprise/all-modules';
import { ToastrService } from 'ngx-toastr';
import { RequestStatus } from '@shared/models/request/requestStatus';
import { RequestVerificationStatusGridComponent } from '../request-verification-status-grid/request-verification-status-grid.component';
import { Extensions } from '@shared/utils/extensions';
import { RequestListOutput } from '@shared/models/request/requestListOutput';
import { RequestDatasourceService } from '@shared/services/requests.datasource.service';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { RequestService } from '@shared/services/requests.service';
import { GridBtnCancelComponent } from '@app/shared/grid/grid-btn-cancel/grid-btn-cancel.component';
import { SendingMethodType } from '@shared/models/request/sendingMethodType';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { RequestStatusPillComponent } from './request-status-pill/request-status-pill.component';
import { RequestStatusLegend } from '@shared/models/request/requestStatusLegend';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { BillingPeriodTypeDTO } from '@shared/models/organization/billingInformation/BillingPeriodTypeDTO';

@Component({
    selector: 'request-table',
    templateUrl: './request-table.component.html',
    providers: [DatePipe]
})
export class RequestTableComponent extends AppComponentBase implements OnInit {
    @ViewChild('agGrid') agGrid: AgGridAngular;
    @Input() paginationPageSize = 10;
    @Input() includeLegend = false;
    @Input() pagination = true;
    @Input() shouldHidePagination = false;
    public gridOptions: GridOptions;
    public defaultColDef: ColDef;
    columnDefs: ColDef[];
    rowSelection: string;
    selectedRequests: RequestListOutput;
    goToDetails = true;
    private gridApi: GridApi;

    constructor(
        private _router: Router,
        private toastr: ToastrService,
        injector: Injector,
        private datePipe: DatePipe,
        private requestDatasourceService: RequestDatasourceService,
        private _appAuthService: AppAuthService,
        private requestService: RequestService,
        private _dateTimeService: DateTimeService
    ) {
        super(injector);
        this.rowSelection = this.isGranted('Pages.Management.Requests.Details') ? 'single' : null;
        this.gridOptions = <GridOptions>{
            rowModelType: 'serverSide',
            animateRows: true,
            cacheBlockSize: this.paginationPageSize,
            groupSuppressAutoColumn: true,
            defaultExportParams: {
                processCellCallback: (params) => {
                    const colDef = params.column.getColDef();
                    if (colDef.valueFormatter) {
                        const valueFormatterParams: ValueFormatterParams = {
                            ...params,
                            data: params.node.data,
                            node: params.node,
                            colDef: params.column.getColDef()
                        };
                        return colDef.valueFormatter(valueFormatterParams);
                    }
                    return params.value;
                }
            }
        };
    }

    ngOnInit() {
        const commonColumns: ColDef[] = [
            {
                headerName: this.l('ClientId'),
                field: 'clientId',
                sortable: true,
                filter: 'agTextColumnFilter',
                width: 100,
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                }
            },
            {
                headerName: this.l('Candidate'),
                field: 'candidatName',
                sortable: true,
                filter: 'agTextColumnFilter',
                width: 150,
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                },
                getQuickFilterText: Extensions.replaceSpecialCharactersParams.bind(this),
                cellStyle: {
                    'padding-left': '7px'
                }
            },
            {
                headerName: this.l('Organization'),
                field: 'organizationName',
                sortable: true,
                filter: 'agTextColumnFilter',
                width: 220,
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                },
                getQuickFilterText: Extensions.replaceSpecialCharactersParams.bind(this)
            },
            {
                headerName: this.l('SendingMethod'),
                field: 'sendingMethod',
                sortable: true,
                filter: 'agTextColumnFilter',
                flex: 1,
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                },
                valueFormatter: this.sendingMethodGetter.bind(this),
                cellStyle: {
                    'padding-left': '7px'
                },
                hide: true
            },
            {
                headerName: this.l('Status'),
                field: 'status',
                sortable: true,
                filter: 'agTextColumnFilter',
                width: 190,
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                },
                cellRendererFramework: RequestStatusPillComponent,
                cellStyle: {
                    'padding-left': '7px'
                },
                valueFormatter: this.statusGetter.bind(this)
            },
            {
                headerName: this.l('PrimaryContact'),
                field: 'primaryContactName',
                sortable: true,
                filter: 'agTextColumnFilter',
                flex: 1.5,
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                },
                getQuickFilterText: Extensions.replaceSpecialCharactersParams.bind(this)
            },
            {
                headerName: this.l('Creation'),
                field: 'creationTime',
                sortable: true,
                filter: 'agDateColumnFilter',
                width: 140,
                filterParams: {
                    filterOptions: ['inRange'],
                    suppressAndOrCondition: true
                },
                sort: 'desc',
                valueFormatter: this.dateFormater.bind(this),
                cellStyle: {
                    'padding-left': '7px'
                }
            },
            {
                headerName: this.l('LastModification'),
                field: 'lastModificationTime',
                sortable: true,
                filter: 'agDateColumnFilter',
                width: 140,
                filterParams: {
                    filterOptions: ['inRange'],
                    suppressAndOrCondition: true
                },
                valueFormatter: this.dateFormater.bind(this),
                cellStyle: {
                    'padding-left': '7px'
                }
            },
            {
                headerName: this.l('BillingPeriod'),
                field: 'billingPeriod',
                valueFormatter: this.billingMethodFormater.bind(this),
                width: 120,
                hide: true,
                sortable: false,
                filter:false                
            },
            {
                headerName: this.l('Verifications'),
                cellRendererFramework: RequestVerificationStatusGridComponent,
                autoHeight: true,
                hide: this.hideVerifications(),
                flex: 2,
                cellStyle: {
                    'padding-left': '7px'
                }
            },
            {
                headerName: '',
                cellRendererFramework: GridBtnCancelComponent,
                cellRendererParams: { action: this.cancelRequest },
                flex: 1,
                autoHeight: true,
                resizable: true,
                cellStyle: {
                    'padding-left': '7px'
                }
            },
            {
                headerName: '',
                field: 'globalFilter',
                sortable: false,
                filter: 'agTextColumnFilter',
                filterParams: { filterOption: ['contains'] },
                flex: 1,
                hide: true,
                lockVisible: true
            },
        ];

        if (this.isGranted('Pages.Management.Requests.CanSeePrivateInformation')) {
            commonColumns.splice(6, 0, {
                headerName: this.l('AssignedTo'),
                field: 'assignedUserName',
                sortable: true,
                filter: 'agTextColumnFilter',
                flex: 1.5,
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                },
                getQuickFilterText: Extensions.replaceSpecialCharactersParams.bind(this)
            });
        }

        this.columnDefs = commonColumns;
        this.defaultColDef = { resizable: true };
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.agGrid.api.setServerSideDatasource(this.requestDatasourceService);
        this.requestDatasourceService.gridReadySubject.next();
        this.loadColumnState();
        this.loadSortState();
    }

    setColumnFilter(column: string, filterModel: any, autoRefresh: boolean = true): void {
        const filterInstance = this.agGrid.gridOptions.api.getFilterInstance(column);
        filterInstance.setModel(filterModel);
        if (autoRefresh) {
            this.applyFilters();
        }
    }

    setGeneralFilter(filterModel?: any): void {
        this.gridApi.setFilterModel(filterModel);
    }

    applyFilters(): void {
        this.agGrid.gridOptions.api.onFilterChanged();
    }

    onFilterChanged(event: any) {
        this.requestDatasourceService.filterChangedSubject.next(event);
    }

    setSorting(sortModel?: any): void {
        this.gridApi.setSortModel(sortModel);
    }

    resetSorting(): void {
        this.setSorting([{ colId: 'creationTime', sort: 'desc' }]);
    }

    onSortChanged(event: any) {
        this.gridOptions.api.paginationGoToFirstPage();
        this.requestDatasourceService.sortChangedSubject.next(event);
        this.saveSortState();
    }

    onFirstDataRendered() {
        this.requestDatasourceService.firstDataRenderedSubject.next();
    }

    onSelectionChanged() {
        this.requestDatasourceService.selectionChangedSubject.next();
        const selectedRows = this.agGrid.gridOptions.api.getSelectedRows();
        if (this.goToDetails && selectedRows.length > 0) {
            if (this.isGranted('Pages.Management.Requests.Details')) {
                this.selectedRequests = selectedRows[0] as RequestListOutput;
                if (this.selectedRequests) {
                    if (this._appAuthService.hasPermission('Pages.Management.Requests.Create.Wizzard') && this.selectedRequests.status === RequestStatus.Draft) {
                        this._router.navigate(['/requests-wizard', this.selectedRequests.publicId]);
                    } else {
                        this._router.navigate(['/requests-details', this.selectedRequests.publicId]);
                    }
                }
            } /*else {
                this.showAccessError();
            }*/
        }
    }

    statusGetter(params: ValueFormatterParams): string {
        return this.l(RequestStatusLegend.getLegendFromId(params.value)?.name);
    }

    sendingMethodGetter(params: ValueFormatterParams): string {
        return this.l(SendingMethodType[params.value]);
    }

    getAssignedToValue(params: ValueFormatterParams): string {
        const valueOfAssignedTo = params.value;
        if (this.isGranted('Pages.Management.Requests.CanSeePrivateInformation')) {
            return valueOfAssignedTo;
        } else {
            return '';
        }
    }

    nameValueGetter(params) {
        return params.data.candidat.firstName + ' ' + params.data.candidat.lastName;
    }

    dateFormater(params: ValueFormatterParams) {
        if (params.value) {
            return this.datePipe.transform(this._dateTimeService.toUtcDate(params.value).toLocal().toString(), AppConsts.dateTimeFormat);
        }
        return '';
    }

    paginationNumberFormatter(params) {
        return params.value.toLocaleString();
    }

    onColumnMoved() {
        this.saveColumnState();
    }

    onColumnVisibleChanged() {
        this.saveColumnState();
    }

    billingMethodFormater(params: ValueFormatterParams): string {
        const request: RequestListOutput = params.data;
        let result = '';
        if (request.billingPeriod != null) {
            result = this.l(BillingPeriodTypeDTO[request.billingPeriod])
        }
        return result;
    }

    private showAccessError() {
        const title = this.l('AccessIssue');
        const message = this.l('NotAccess');
        this.toastr.error(message, title);
    }

    private cancelRequest = (requestListOutput: RequestListOutput) => {
        this.goToDetails = false;
        this.message.confirm(this.l('RequestCancelWarningMessage'), this.l('AreYouSure'), (isConfirmed) => {
            if (isConfirmed) {
                if (requestListOutput.publicId !== '') {
                    if (requestListOutput.status === RequestStatus.InProgressCustomerEzSign) {
                        this.cancelEzSignRequest(requestListOutput.publicId);
                    } else {
                        this.cancelTraditionalRequest(requestListOutput.publicId);
                    }
                }
            }
        }, {
            confirmButtonText: this.l('Yes'),
            cancelButtonText: this.l('No')
        });
    };

    private cancelEzSignRequest(publicId: string) {
        this.requestService.cancelEzsignRequest(publicId).subscribe({
            next: () => {
                this.refreshGridAfterSuccessCancel();
            },
            error: () => {
                this.showCancelErrorMessage();
            }
        });
    }

    private cancelTraditionalRequest(publicId: string) {
        try {
            this.requestService.cancelRequest(publicId).subscribe((response) => {
                this.refreshGridAfterSuccessCancel();
            });
        } catch (e) {
            this.showCancelErrorMessage();
        }
    }

    private refreshGridAfterSuccessCancel() {
        this.requestDatasourceService.gridReadySubject.next();
        this.agGrid.api.setServerSideDatasource(this.requestDatasourceService);
        this.agGrid.api.deselectAll();
        this.showCancelSuccess();
        this.goToDetails = true;
    }

    private showCancelSuccess() {
        const title = this.l('CancelRequest');
        const successMessage = this.l('RequestCanceled');
        this.toastr.success(successMessage, title);
    }

    private showCancelErrorMessage() {
        const title = this.l('CancelRequest');
        const errorMessage = this.l('RequestCanceledIssue');
        this.toastr.error(errorMessage, title);
        return of(null);
    }

    private hideVerifications() {
        return this._appAuthService.hasRole('AgentPPO');
    }

    private loadColumnState() {
        const columnState = JSON.parse(localStorage.getItem('requestsTableColumnState'));
        if (columnState) {
            this.gridOptions.columnApi.setColumnState(columnState);
        }
    }

    private saveColumnState() {
        const columnState = JSON.stringify(this.gridOptions.columnApi.getColumnState());
        localStorage.setItem('requestsTableColumnState', columnState);
    }

    private loadSortState() {
        const sortState = JSON.parse(localStorage.getItem('requestsTableSortState'));
        if (sortState) {
            this.gridOptions.api.setSortModel(sortState);
        }
    }

    private saveSortState() {
        const sortState = JSON.stringify(this.gridOptions.api.getSortModel());
        localStorage.setItem('requestsTableSortState', sortState);
    }
}
