import { RequestStatus } from './requestStatus';
import { CandidatOutput } from '../candidat/candidatOutput';
import { organizationOutput } from '../organization/organizationOutput';
import { InformationSourceDTO } from './informationSourceDTO';
import { RequestVerificationInput, To_RequestVerificationInput } from '../requestVerification/requestVerificationInput';
import { RequestOutput } from './requestOutput';
import { SendingMethodType } from './sendingMethodType';

// tslint:disable-next-line:class-name
export class RequestInput {
    id: number;
    publicId: string;
    candidat: CandidatOutput;
    assignedId: number;
    additionalContactId: number;
    status: RequestStatus;
    organization: organizationOutput;
    requestVerifications: RequestVerificationInput[];
    creationTime: Date;
    myInformationSource: InformationSourceDTO;
    files?: string[];
    reportLanguage: string;
    createdBy: number;
    generatedReportPublicId?:string;
    purchaseOrder: string;
    sendingMethod: SendingMethodType;
    primaryContactId?: number;

    constructor () {
        this.id = 0;
        this.publicId = '';
        this.candidat = new CandidatOutput();
        this.assignedId = null;
        this.additionalContactId = null;
        this.status = RequestStatus.Draft;
        this.organization = new organizationOutput();
        this.requestVerifications = [];
        this.creationTime = new Date();
        this.myInformationSource = InformationSourceDTO.Candidate;
        this.files = [];
        this.reportLanguage = '';
        this.createdBy = 0;
        this.generatedReportPublicId = null;
        this.sendingMethod = SendingMethodType.Traditionnal;
        this.primaryContactId = null;
    }

    static FromOutput(requestOutput: RequestOutput): RequestInput {
        return {
            id: requestOutput.id,
            publicId: requestOutput.publicId,
            candidat: requestOutput.candidat,
            assignedId: requestOutput.assignedId,
            additionalContactId: requestOutput.additionalContactId,
            status: requestOutput.status,
            organization: requestOutput.organization,
            requestVerifications: requestOutput.requestVerifications?.map(v => To_RequestVerificationInput(v, requestOutput.id)),
            creationTime: requestOutput.creationTime,
            myInformationSource: requestOutput.myInformationSource,
            files: requestOutput.files?.map(f => f.publicId),
            reportLanguage: requestOutput.reportLanguage,
            createdBy: requestOutput.createdBy,
            generatedReportPublicId: requestOutput.generatedReportPublicId,
            purchaseOrder: requestOutput.purchaseOrder,
            sendingMethod: requestOutput.sendingMethod,
            primaryContactId: requestOutput.primaryContactId
        };
    }
}


