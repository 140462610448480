import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { ActivatedRoute, Router } from '@angular/router';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { OrganizationUserService } from '@shared/services/organizationUser.service';
import { simpleUserOutput } from '@shared/models/user/SimpleUserOutput';
import { AgGridAngular } from '@ag-grid-community/angular';
import { CreateOrEditUserModalComponent } from '@app/users/create-or-edit-user-modal.component';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { AppConsts } from '@shared/AppConsts';
import { DatePipe } from '@angular/common';
import { UserTypeEnum } from '@app/users/userTypeEnum';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { OrganizationUserInput } from '@shared/service-proxies/service-proxies';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'organization-users',
    templateUrl: './organization-user.component.html',
    providers: [DatePipe],
    animations: [appModuleAnimation()],
})
export class OrganizationUserListComponent
    extends AppComponentBase
    implements OnInit {
    @ViewChild('createOrEditUserModal')
        createOrEditUserModal: CreateOrEditUserModalComponent;
    @ViewChild('agGrid') agGrid: AgGridAngular;
    organizationPublicId: string;
    userList: simpleUserOutput[] = [];
    filterText = '';
    gridApi;
    defaultColDef;
    rowSelection: string;
    paginationPageSize = 20;
    paginationNumberFormatter;
    columnDefs: any[];

    constructor(
        injector: Injector,
        private service: OrganizationUserService,
        private _route: ActivatedRoute,
        private _router: Router,
        private datePipe: DatePipe,
        private _appLocalizationService: AppLocalizationService,
        private _organizationUserService: OrganizationUserService,
        private _dateTimeService: DateTimeService,
        private toastr: ToastrService,
    ) {
        super(injector);
    }

    async ngOnInit() {
        if (this._route.snapshot.paramMap.get('publicId') !== '') {
            this.organizationPublicId =
                this._route.snapshot.paramMap.get('publicId');
            this.getUserlist();
        }
    }

    async getUserlist() {
        if(this.agGrid) {
            this.agGrid.api.showLoadingOverlay();
        }
        await this.service
            .getUserNotHideList(this.organizationPublicId)
            .toPromise()
            .then((value) => {
                this.userList = this._organizationUserService.sortUserList(
                    value.result
                );
                this.initializeGrid();
            })
            .finally(() => {
                if(this.agGrid) {
                    this.agGrid.api.hideOverlay();
                }
            });
    }

    createUser(): void {
        this.createOrEditUserModal.show(undefined, this.organizationPublicId);
    }

    initializeGrid() {
        this.columnDefs = [
            {
                headerName: this._appLocalizationService.l('UserName'),
                width: 200,
                field: 'userName',
                sortable: true,
                filter: true,
            },
            {
                headerName: this._appLocalizationService.l('FullName'),
                width: 350,
                field: 'fullName',
                sortable: true,
                filter: true,
            },
            {
                headerName: this._appLocalizationService.l('Email'),
                width: 350,
                field: 'email',
                sortable: true,
                filter: true,
            },
            {
                headerName: this._appLocalizationService.l('CreationTime'),
                width: 200,
                field: 'creationDate',
                sortable: true,
                filter: true,
                valueFormatter: this.dateFormater.bind(this),
            },
            {
                headerName: this._appLocalizationService.l('UserType'),
                width: 200,
                field: 'type',
                sortable: true,
                filter: true,
                valueFormatter: this.userTypeFormatter.bind(this),
            },
        ];

        // Manage the access to the selection feature
        if(this.canEditUsers()){
            this.rowSelection = 'multiple';
            this.columnDefs.unshift(
                {
                    checkboxSelection: true,
                    field: 'selected',
                    headerCheckboxSelection: true,
                    headerName: '',
                    width: 100,  
                });
        }
        else {
            this.rowSelection = null;
        }
        this.defaultColDef = { resizable: true };
    }

    dateFormater(params: ValueFormatterParams) {
        return this.datePipe.transform(
            this._dateTimeService.toUtcDate(params.value).toLocal().toString(),
            AppConsts.dateTimeFormat
        );
    }

    userTypeFormatter(params: ValueFormatterParams) {
        switch (params.value) {
            case UserTypeEnum.User:
                return this._appLocalizationService.l('User');
            case UserTypeEnum.Client:
                return this._appLocalizationService.l('ClientUser');
            case UserTypeEnum.B2BWeb:
                return this._appLocalizationService.l('B2BWeb');
            default:
                return this._appLocalizationService.l('Unknown');
        }
    }

    search() {
        this.agGrid.gridOptions.api.setQuickFilter(this.filterText);
    }

    refresh() {
        this.filterText = '';
        this.agGrid.gridOptions.api.setQuickFilter('');
    }

    onPageSizeChanged(e) {
        this.gridApi.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    onGridReady(params) {
        this.gridApi = params.api;
        if (screen.availWidth > 414) {
            this.gridApi.sizeColumnsToFit();
        }
    }

    onCellClicked(event){
        let fieldName = event.colDef.field;
        let isCheckBox = event.colDef.checkboxSelection;
        let isSelectionCell = fieldName === 'selected' && isCheckBox;

        if(isSelectionCell){
            // Do nothing 
        }
        else{
            this.showCurrentUserDetails();
        }
    }

    showCurrentUserDetails() {
        if(this.canEditUsers()) {
            const selectedUser = this.gridApi.getSelectedRows()[0] as simpleUserOutput;  
            this.createOrEditUserModal.show(selectedUser.userId);  
        }
    }

    getSelectedUsers(): simpleUserOutput[] {
        let result: simpleUserOutput[] = [];

        if(this.gridApi) {
            this.gridApi.getSelectedRows()
                        .forEach((user: simpleUserOutput) => result.push(user));
        }
        return result;
    }

    deleteSelection(){
        let usersNames = '\'' +
                         this.getSelectedUsers()
                             .map(user => user.fullName)
                             .join('\', \'') + 
                         '\'';
        
        this.message.confirm(
            this.l('OrganizationUsersDeleteWarningMessage', usersNames),
            this.l('AreYouSure'),
            async (confirmed: boolean) => {
                if (confirmed) {    
                    await this.deleteSeletedUsers();
                    this.getUserlist();
                }
            }, {
                confirmButtonText: this.l('Yes'),
                cancelButtonText: this.l('No')        
            }
        );
    }

    hasSelection() {
        let selectedUsers = this.getSelectedUsers();
        return selectedUsers && selectedUsers.length > 0;
    }

    canEditUsers(): boolean {
        return this.isGranted('Pages.Administration.Users.Edit');
    }

    async deleteSeletedUsers() {
        let currentOrganization = JSON.parse(sessionStorage.getItem('gardium.organizations.selected'));        
        let usersToDelete = this.getSelectedUsers()
                                .map(simpleUseOutput => {
                                    let user = new OrganizationUserInput();
                                    user.userId = simpleUseOutput.userId;
                                    user.organizationId = currentOrganization.id;
                                    return user;
                                });                                  
        await this._organizationUserService.deleteOrganizationUser(usersToDelete)
                                           .toPromise()
                                           .then(value => {
                                                this.toastr.success(this._appLocalizationService.l('OrganizationUsersDeletion'), this._appLocalizationService.l('OrganizationUsersDeleted'));                                                
                                           })
                                           .catch(e =>{
                                                this.toastr.error(this._appLocalizationService.l('OrganizationUsersDeletion'), this._appLocalizationService.l('OrganizationUsersDeletionError'));                                                
                                           });
    }
}
