import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OrganizationInput } from '@shared/models/organization/organizationInput';
import { organizationLogoInput } from '@shared/models/organization/organizationLogoInput';
import { FilterModel } from '@shared/models/request/filterModel';
import { SortModel } from '@shared/models/request/sortModel';

import { environment as env } from 'environments/environment';

@Injectable()
export class OrganizationService {
    private http: HttpClient;
    private baseUrl: string;

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = env.api;
    }

    getOrganizationsList(): Observable<any> {
        const url = this.getUrl('/api/organizations');
        const list = this.http.get<any>(url);
        return list;
    }

    getOrganizationsByName(organizationName: string, maxResults: number): Observable<any> {
        const url = this.getUrl('/api/organizations/getAllOrganizationsByName');
        const list = this.http.get<any>(url, { params: { organizationName: organizationName, maxResults: maxResults.toString() } });
        return list;
    }

    getOrganizationsListDetails(): Observable<any> {
        const url = this.getUrl('/api/organizations/details');
        const list = this.http.get<any>(url);
        return list;
    }

    getUsersOrganizationsList(parentOrganizationId: string, startRow: number, endRow: number, sortModel: SortModel[], filterModel: FilterModel, userId: number): Observable<any> {
        const url = this.getUrl('/api/organizations/usersOrganizations');

        const request = {
            userId,
            startRow,
            endRow,
            sortModel,
            filterModel,
            OrganizationParentId: parentOrganizationId
        };

        return this.http.post<any>(url, request);
    }

    getOrganizationsNoUser(): Observable<any> {
        const url = this.getUrl('/api/organizations/noUser');
        const list = this.http.get<any>(url);
        return list;
    }

    createOrganization(organization: OrganizationInput): Observable<any> {
        const url = this.getUrl('/api/organizations');
        return this.http.post<any>(url, organization);
    }

    createSubOrganization(organization: OrganizationInput, parentPublicId: string): Observable<any> {
        const url = this.getUrl('/api/organizations/suborganization');
        const params = new HttpParams().set('parentId', parentPublicId);
        return this.http.post<any>(url, organization, { params });
    }

    updateOrganization(organization: OrganizationInput): Observable<any> {
        const url = this.getUrl('/api/organizations');
        return this.http.put<any>(url, organization);
    }

    deleteOrganization(organization: OrganizationInput): Observable<any> {
        const url = this.getUrl('/api/organizations/delete');
        return this.http.post<any>(url, organization);
    }

    getOrganizationsByPublicId(publicId: string): Observable<any> {
        const url = this.getUrl('/api/organizations');
        const params = new HttpParams().set('publicId', publicId);
        return this.http.get<any>(url, { params });
    }

    getLegacyOrganizationsFromUserCreator(userId: number): Observable<any> {
        const url = this.getUrl('/api/organizations/' + userId);
        const lists = this.http.get<any>(url);
        return lists;
    }
    getLegacyOrganizations(): Observable<any> {
        const url = this.getUrl('/api/organizations/my');
        const lists = this.http.get<any>(url);
        return lists;
    }

    getOrganizationsFromParentPublicId(parentPublicId: string): Observable<any> {
        const url = this.getUrl('/api/organizations');
        const params = new HttpParams().set('parentPublicId', parentPublicId);
        return this.http.get<any>(url, { params });
    }

    addOrganizationFile(profilePicture: string, orgId: number): Observable<any> {
        const logoInput: organizationLogoInput = new organizationLogoInput();
        logoInput.organizationId = orgId;
        logoInput.profilePicture = profilePicture;
        const url = this.getUrl('/api/organizations/OrganizationFileAdd');
        return this.http.post<any>(url, logoInput);
    }

    getOrganizationLogo(orgId: number): Observable<any> {
        const url = this.getUrl('/api/organizations/GetOrganizationLogo');
        const params = new HttpParams().set('organizationId', orgId.toString());
        return this.http.get<any>(url, { params });
    }

    removeOrganizationFile(orgId: number): Observable<any> {
        const url = this.getUrl('/api/organizations/OrganizationFileDelete');
        const params = new HttpParams().set('organizationId', orgId.toString());
        return this.http.delete<any>(url, { params });
    }

    filter(parentPublicId: string, startRow: number, endRow: number, sortModel: SortModel[], filterModel: FilterModel): Observable<any> {
        const url = this.getUrl('/api/organizations/filter');

        const request = {
            parentOrganizationPublicId: parentPublicId,
            startRow: startRow,
            endRow: endRow,
            sortModel: sortModel,
            filterModel: filterModel
        };

        return this.http.post<any>(url, request);
    }

    export(sortModel: SortModel[], filterModel: FilterModel): Observable<any> {
        const url = this.getUrl('/api/organizations/export');

        const request = {
            sortModel: sortModel,
            filterModel: filterModel
        };

        return this.http.post(url, request, {
            responseType: 'blob'
        });
    }

    getCcOneMonthExpNotification(): Observable<any> {
        const url = this.getUrl('/api/organizations/GetCcOneMonthExpNotification');
        const orgCcExpOutputList = this.http.get<any>(url);
        return orgCcExpOutputList;
    }

    private getUrl(url: string): string {
        return this.baseUrl + url.replace(/[?&]$/, '');
    }
}
