import { Component, OnInit, EventEmitter, Output, Input, ViewChild, Injector } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ModalDirective, BsModalRef } from 'ngx-bootstrap/modal';
import { PersonnalReferencesOutput } from '@shared/models/CandidatDetails/personnalReferences/personnalReferencesOutput';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PersonnalReferencesInput } from '@shared/models/CandidatDetails/personnalReferences/personnalReferencesInput';
import { finalize } from 'rxjs/operators';
import { PersonnalReferenceService } from '@shared/services/personnalReference.service';
import { ToastrService } from 'ngx-toastr';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { phoneNumberValidator } from '@shared/utils/validation/phoneNumber-validator.directive';

@Component({
    selector: 'personnal-references-update',
    templateUrl: './personnal-references-update.component.html',
    providers: [DatePipe]
})
export class PersonnalReferencesUpdateComponent extends AppComponentBase implements OnInit {
    @ViewChild('personnalRefencesUpdateModal') modal: ModalDirective;
    @Input() selectedPersonalReference: PersonnalReferencesOutput;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    publicId: string;
    saving = false;
    active = false;

    formGroup = new UntypedFormGroup({
        firstName: new UntypedFormControl('', Validators.required),
        lastName: new UntypedFormControl('', Validators.required),
        email: new UntypedFormControl('', Validators.required),
        relationship: new UntypedFormControl('', Validators.required),
        phoneNumber: new UntypedFormControl('', [Validators.required, phoneNumberValidator]),
        phoneExtension: new UntypedFormControl(''),
        location: new UntypedFormControl('', Validators.required)
    });

    constructor(
        public bsModalRef: BsModalRef,
        private injector: Injector,
        private service: PersonnalReferenceService,
        private toastr: ToastrService,
        private _appLocalizationService: AppLocalizationService
    ) {
        super(injector);
    }

    ngOnInit() {
        if (this.selectedPersonalReference !== null && this.selectedPersonalReference !== undefined) {
            this.initializePersonnalReferenceToUpdate(this.selectedPersonalReference);
        }
    }

    initializePersonnalReferenceToUpdate(selectedPersonalReference: PersonnalReferencesOutput) {
        this.formGroup.controls['firstName'].setValue(selectedPersonalReference.firstName);
        this.formGroup.controls['phoneExtension'].setValue(selectedPersonalReference.phoneExtension);
        this.formGroup.controls['lastName'].setValue(selectedPersonalReference.lastName);
        this.formGroup.controls['email'].setValue(selectedPersonalReference.email);
        this.formGroup.controls['relationship'].setValue(selectedPersonalReference.relationship);
        this.formGroup.controls['phoneNumber'].setValue(selectedPersonalReference.phoneNumber);
        this.formGroup.controls['location'].setValue(selectedPersonalReference.location);
    }

    returnPersonnalReference(): PersonnalReferencesInput {
        const personnalRef: PersonnalReferencesInput = {
            id: this.selectedPersonalReference.id,
            publicId: this.selectedPersonalReference.publicId,
            firstName: this.formGroup.controls['firstName'].value,
            lastName: this.formGroup.controls['lastName'].value,
            email: this.formGroup.controls['email'].value,
            relationship: this.formGroup.controls['relationship'].value,
            phoneNumber: this.formGroup.controls['phoneNumber'].value,
            phoneExtension: this.formGroup.controls['phoneExtension'].value,
            location: this.formGroup.controls['location'].value,
            requestId: this.selectedPersonalReference.requestId,
            creationTime: this.selectedPersonalReference.creationTime,
            candidatId: this.selectedPersonalReference.candidatId
        };
        return personnalRef;
    }

    isFormInvalid(): boolean {
        return this.formGroup.invalid;
    }

    save(): Promise<void> {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }
        this.saving = true;

        const personnalReference = this.returnPersonnalReference();
        this.service
            .updatePersonnalReferences(personnalReference)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe({
                next: (value) => {
                    this.notify.info(this.l('SavedSuccessfully'));
                    this.close();
                    this.modalSave.emit(null);
                },
                error: (err) => {
                    this.notify.error('Server error: ' + err.error.result);
                }
            });
    }

    markAllControlsAsTouch() {
        this.formGroup.controls['firstName'].markAsTouched();
        this.formGroup.controls['lastName'].markAsTouched();
        this.formGroup.controls['email'].markAsTouched();
        this.formGroup.controls['relationship'].markAsTouched();
        this.formGroup.controls['phoneNumber'].markAsTouched();
        this.formGroup.controls['phoneExtension'].markAsTouched();
        this.formGroup.controls['location'].markAsTouched();
    }

    showForNotValidMessage() {
        const title = this._appLocalizationService.l('VerifyAllControlsTitle');
        const message = this._appLocalizationService.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }

    close(): void {
        this.active = false;
        this.bsModalRef.hide();
    }
}
