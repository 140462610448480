import { Component, OnInit, ViewChild, Injector, HostListener, Input } from '@angular/core';
import { OrganizationVerificationOutput } from '@shared/models/organizationVerificationCost/orgVerificationOutput';
import { AgGridAngular } from '@ag-grid-community/angular';
import { OrganizationVerificationCostService } from '@shared/services/organizationVerificationCost.service';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { verificationStatus } from '@shared/models/verification/verificationStatus';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { GridBtnUpdateOrganizationVerificationComponent } from '@app/shared/grid/grid-btn-update-organizationVerification/grid-btn-update-organizationVerification.component';
import { OrganizationService } from '@shared/services/organization.service';
import { Extensions } from '@shared/utils/extensions';
import { VerificationService } from '@shared/services/verification.service';
import { VerificationOutput } from '@shared/service-proxies/service-proxies';
import { GridDownloadLinksForVerificationFilesComponent } from '@app/shared/grid/grid-download-links-for-verification-files/grid-download-links-for-verification-files.component';

@Component({
    selector: 'organization-verifications-list',
    templateUrl: './organization-verifications-list.component.html',
    animations: [appModuleAnimation()]
})
export class OrganizationVerificationsListComponent extends AppComponentBase implements OnInit {
    @ViewChild('agGrid') agGrid: AgGridAngular;
    @Input() organizationIsActif = true;
    public defaultColDef;
    paginationNumberFormatter;
    paginationPageSize = 20;
    filterText = '';
    rowData: OrganizationVerificationOutput[] = [];
    publicId: string;
    columnDefs: any;
    verificationPublicId: string;
    rowSelection: string;
    selectedVerificationRow: OrganizationVerificationOutput;
    organizationId: string;
    verifications: VerificationOutput[];
    private gridApi;

    constructor(
        private injector: Injector,
        private _organizationVerificationService: OrganizationVerificationCostService,
        private _organizationService: OrganizationService,
        private _verificationService: VerificationService,
        private _appLocalizationService: AppLocalizationService,
        private _route: ActivatedRoute,
        private _router: Router
    ) {
        super(injector);
        this.rowSelection = 'single';
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.gridApi) {
            if (screen.availWidth > 414) {
                this.gridApi.sizeColumnsToFit();
            }
        }
    }

    toEnum(params: ValueFormatterParams): string {
        return this._appLocalizationService.l(verificationStatus[params.value]);
    }

    async ngOnInit() {
        if (this._route.snapshot.paramMap.get('publicId') !== '') {
            this.publicId = this._route.snapshot.paramMap.get('publicId');
        }
        await this._verificationService
            .getVerificationsLinkedToOrganization(this.publicId)
            .toPromise()
            .then((value) => {
                this.verifications = value.result;
            });
        this.setColumnDefinitions();

        await this._organizationVerificationService
            .getOrganizationVerificationCostList(this.publicId)
            .toPromise()
            .then((value) => {
                this.rowData = value.result;
                this.gridApi.sizeColumnsToFit();
            });
    }

    onPageSizeChanged(e) {
        this.gridApi.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    getGridEditBtnIfAllowToEdit() {
        const btn = GridBtnUpdateOrganizationVerificationComponent;
        if (this.organizationIsActif && this.isGranted('Pages.Management.Organizations.VerificationListAcess.VerificationPriceAccess')) {
            return btn;
        } else {
            return null;
        }
    }

    onGridReady(params) {
        this.gridApi = params.api;
        if (screen.availWidth > 414) {
            this.gridApi.sizeColumnsToFit();
        }
    }

    totalGetter(params) {
        return (params.data.basePrice + params.data.adjustment).toFixed(2) + ' $';
    }

    refresh() {
        this.filterText = '';
        this.agGrid.gridOptions.api.setQuickFilter('');
    }

    search() {
        this.agGrid.gridOptions.api.setQuickFilter(this.filterText);
    }

    updateVerification = (data: any) => {
        const organizationPublicId = data.organizationPublicId;
        const verificationPublicId = data.verificationPublicId;
        this._router.navigate(['/organizations-verification-update', organizationPublicId, verificationPublicId]);
    };

    private setColumnDefinitions = () => {
        if (this.isGranted('Pages.Management.Organizations.VerificationListAcess.VerificationPriceAccess')) {
            this.columnDefs = [
                {
                    headerName: this._appLocalizationService.l('VerificationCode'),
                    width: 150,
                    field: 'verification.code',
                    sortable: true,
                    filter: true
                },
                {
                    headerName: this._appLocalizationService.l('VerificationType'),
                    width: 400,
                    field: 'verification.type',
                    sortable: true,
                    filter: true,
                    getQuickFilterText: Extensions.replaceSpecialCharactersParams.bind(this)
                },
                {
                    headerName: this._appLocalizationService.l('VerificationPrice'),
                    width: 100,
                    valueGetter: this.totalGetter,
                    sortable: true,
                    filter: true
                },
                {
                    headerName: this._appLocalizationService.l('VerificationExpressService'),
                    width: 100,
                    valueFormatter: (params) => {
                        const translation = params.data.verification.canBeExpress ? 'Eligible' : 'NonEligible';
                        return this.l(translation);
                    },
                },
                {
                    headerName: '',
                    width: 120,
                    cellRendererFramework: this.getGridEditBtnIfAllowToEdit(),
                    cellRendererParams: { action: this.updateVerification }
                },
                {
                    headerName: this._appLocalizationService.l('Files'),
                    width: 120,
                    cellRendererFramework: GridDownloadLinksForVerificationFilesComponent,
                    cellRendererParams: { verifications: this.verifications }
                }
            ];
        } else {
            this.columnDefs = [
                {
                    headerName: this._appLocalizationService.l('VerificationCode'),
                    width: 150,
                    field: 'verification.code',
                    sortable: true,
                    filter: true
                },
                {
                    headerName: this._appLocalizationService.l('VerificationType'),
                    width: 400,
                    field: 'verification.type',
                    sortable: true,
                    filter: true,
                    getQuickFilterText: Extensions.replaceSpecialCharactersParams.bind(this)
                },
                {
                    headerName: '',
                    width: 120,
                    cellRendererFramework: this.getGridEditBtnIfAllowToEdit(),
                    cellRendererParams: { action: this.updateVerification }
                },
                {
                    headerName: this._appLocalizationService.l('Files'),
                    width: 120,
                    cellRendererFramework: GridDownloadLinksForVerificationFilesComponent,
                    cellRendererParams: { verifications: this.verifications }
                }
            ];
        }

        this.defaultColDef = { resizable: true };
    };

}
