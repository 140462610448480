<div class="modal-header">
  <h4 class="modal-title pull-left">{{"Add" | localize}}</h4>
</div>
<div class="modal-body">
  <div class="input-group">
    <input type="text" class="form-control m-input" [(ngModel)]="filterText" [placeholder]="l('Search')" autoFocus />
    <span class="input-group-btn">
      <button class="btn btn-primary" type="submit" (click)="search()">
        <i class="flaticon-search-1" [attr.aria-label]="l('Search')"></i>
      </button>
    </span>
    <span class="input-group-btn" style="padding-left:5px;">
      <button class="btn btn-secondary" (click)="refresh()">
        <i class="la la-refresh"></i> {{'Refresh' | localize}}
      </button>
    </span>
  </div>
  <div>
    <ag-grid-angular localizeText #agGrid class="ag-theme-material gardium-grid" [rowData]="files"
      [paginationPageSize]="paginationPageSize" [pagination]="true" [columnDefs]="columnDefs" domLayout="autoHeight"
      [groupColumnDef]="groupColumnDef" [rowSelection]="rowSelection" [suppressRowClickSelection]="true"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="save()">{{"Save" | localize}}</button>
  <button type="button" class="btn btn-default" (click)="close()">{{"Close" | localize}}</button>
</div>
