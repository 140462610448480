import { Component, OnInit, Injector, Input, ViewChild, EventEmitter, Output, AfterViewChecked } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Validators, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { VerificationDescriptionOutput } from '@shared/models/verificationDescription/verificationDescriptionOutput';
import { VerificationDescriptionInput } from '@shared/models/verificationDescription/verificationDescriptionInput';
import { VerificationDescriptionService } from '@shared/services/verificationDescription.service';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ToastrService } from 'ngx-toastr';
import { ModalDirective, BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { Language } from '@shared/models/shared/language';
import { VerificationService } from '@shared/services/verification.service';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CkeditorService } from '@shared/services/ckeditor.service';

@Component({
    selector: 'verification-description-add',
    templateUrl: './verification-description-add.component.html'
})
export class VerificationDescriptionAddComponent extends AppComponentBase implements OnInit, AfterViewChecked {
    @ViewChild('verificationDescriptionAddModal') verificationDescriptionAddModal: ModalDirective;
    @Input() verificationDescriptionInput: VerificationDescriptionInput;
    @Input() id: number;
    @Input() verificationId: number;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    public Editor = Editor;

    verificationPublicId: string;
    verificationDescriptions: VerificationDescriptionOutput[];
    result: VerificationDescriptionOutput[];
    languageMiss: Language[];
    saving = false;
    active = false;
    editorConfig: any;

    formGroup = new UntypedFormGroup({
        description: new UntypedFormControl('', Validators.required),
        language: new UntypedFormControl('', Validators.required)
    });

    constructor(
        public bsModalRef: BsModalRef,
        injector: Injector,
        private _route: ActivatedRoute,
        private toastr: ToastrService,
        private service: VerificationDescriptionService,
        private _service: VerificationService,
        private _appLocalizationService: AppLocalizationService,
        private ckeditorService: CkeditorService
    ) {
        super(injector);
    }

    async ngOnInit() {
        if (this._route.snapshot.paramMap.get('publicId') !== '') {
            this.verificationPublicId = this._route.snapshot.paramMap.get('publicId');
            await this._service
                .getVerificationByPublicId(this.verificationPublicId)
                .toPromise()
                .then((response) => {
                    this.verificationDescriptions = response.result.verificationDescriptionOutputs;
                });
            const lang = this.getMissingLanguage();
            if (typeof lang !== 'undefined') {
                this.languageMiss = this.getSectionTitle(lang);
            }
        }
        this.formGroup.controls[ 'language' ].setValue(null);
        this.editorConfig = this.ckeditorService.getConfig({ mediaEmbedding: true });
    }

    ngAfterViewChecked() {
        this.replaceFontAwesomeIcons('fa-scissors', 'fa-cut');
        this.replaceFontAwesomeIcons('fa-files-o', 'fa-copy');
        this.replaceFontAwesomeIcons('fa-repeat', 'fa-redo');
        this.replaceFontAwesomeIcons('fa-picture-o', 'fa-image');
        this.replaceFontAwesomeIcons('fa-youtube-play', 'fa-video');
        this.removeBtnEditor('imgc-topbar btn-ctnr');
        this.removeBtnEditor('ngx-insert-img-ph ng-star-inserted');
        this.removeBtnEditor('form-control-sm ng-pristine ng-valid ng-touched');
        this.removeBtnEditor('form-control-sm ng-untouched ng-pristine ng-valid');
    }

    show(): void {
        this.active = true;
        this.verificationDescriptionAddModal.show();
    }

    showSuccess(title: string, successMessage: string) {
        title = this._appLocalizationService.l(title);
        successMessage = this._appLocalizationService.l(successMessage);
        this.toastr.success(successMessage, title);
    }

    showErrorMessage(title: string, errorMessage: string) {
        title = this._appLocalizationService.l(title);
        errorMessage = this._appLocalizationService.l(errorMessage);
        this.toastr.error(errorMessage, title);
    }

    returnResult() {
        let verificationSelectedId = 0;
        if (this.verificationId !== null && this.verificationId !== undefined) {
            verificationSelectedId = this.verificationId;
        }
        const verification: VerificationDescriptionInput = {
            id: 0,
            publicId: '',
            verificationId: verificationSelectedId,
            description: this.formGroup.controls[ 'description' ].value,
            languageId: this.formGroup.controls[ 'language' ].value
        };

        return verification;
    }

    getMissingLanguage(): number[] {
        const max = 3;
        const description = this.verificationDescriptions;
        const lang = [];
        const missing = [];
        if (description?.length < max) {
            description.forEach((element) => {
                lang.push(element.languageId);
            });
            for (let i = 0; i <= max - 1; i++) {
                if (lang.indexOf(i) === -1) {
                    missing.push(i);
                }
            }
            return missing;
        }
    }

    isFormInvalid(): boolean {
        return this.formGroup.invalid;
    }

    markAllControlsAsTouch() {
        this.formGroup.controls[ 'language' ].markAsTouched();
        this.formGroup.controls[ 'description' ].markAsTouched();
    }

    showForNotValidMessage() {
        const title = this._appLocalizationService.l('VerifyAllControlsTitle');
        const message = this._appLocalizationService.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }

    save(): Promise<void> {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }
        this.saving = true;
        const description = this.returnResult();
        this.service
            .createVerificationDescription(description)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .toPromise()
            .then(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.languageMiss = this.languageMiss.filter((lang) => lang.id !== description.languageId);
                this.close();
                this.modalSave.emit(null);
            });
    }

    close(): void {
        this.active = false;
        this.formGroup.reset();
        this.verificationDescriptionAddModal.hide();
    }

    getSectionTitle(languageValue: number[]): Language[] {
        const languageString: Language[] = [];
        languageValue.forEach((element) => {
            switch (element) {
                case 0: {
                    const english: Language = {
                        id: 0,
                        name: this.l('English')
                    };
                    languageString.push(english);
                    break;
                }
                case 1: {
                    const french: Language = {
                        id: 1,
                        name: this.l('French')
                    };
                    languageString.push(french);
                    break;
                }
            }
        });
        return languageString;
    }

    private removeBtnEditor(className: string) {
        const elements = document.getElementsByClassName(className);
        for (let i = 0; i < elements.length; i++) {
            const element = elements[ i ] as HTMLElement;
            element.style.display = 'none';
        }
    }

    private replaceFontAwesomeIcons(currentClassName: string, newClassName: string) {
        const icons = document.getElementsByClassName(currentClassName);
        for (let i = 0; i < icons.length; i++) {
            icons.item(i).classList.add(newClassName);
            icons.item(i).classList.remove(currentClassName);
        }
    }
}
