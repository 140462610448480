import { Injector, Pipe, PipeTransform } from '@angular/core';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';

@Pipe({
    name: 'permissionAny',
})
export class PermissionAnyPipe implements PipeTransform {
    _appAuthService: AppAuthService;

    constructor(injector: Injector) {
        this._appAuthService = injector.get(AppAuthService);
    }

    transform(arrPermissions: string[]): boolean {
        if (!arrPermissions) {
            return false;
        }

        for (const permission of arrPermissions) {
            if (this._appAuthService.hasPermission(permission)) {
                return true;
            }
        }

        return false;
    }
}
