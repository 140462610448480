import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment as env } from 'environments/environment';
import { Observable } from 'rxjs';
import { CandidatInput } from '@shared/models/candidat/candidatInput';
import { AddressHistoryInput } from '@shared/models/addresshistory/addressHistoryInput';
import { candidatSex } from '@shared/models/candidat/candidatSex';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';

@Injectable({
    providedIn: 'root'
})
export class CandidatsService {
    private http: HttpClient;
    private baseUrl: string;

    constructor(@Inject(HttpClient) http: HttpClient, private _dateTimeService: DateTimeService) {
        this.http = http;
        this.baseUrl = env.api;
    }

    createCandidat(candidat: CandidatInput): any {
        const url = this.getUrl('/api/candidats');
        const result = this.http.post<CandidatInput>(url, candidat);
        return result;
    }

    searchCandidates(organizationId: number, firstName: string, lastName: string, birthdate: Date, sex: candidatSex): Observable<any> {

        const url_ = this.getUrl('/api/candidats/search');
        let params = new HttpParams()
            .set('organizationId', organizationId.toString())
            .set('firstName', firstName)
            .set('lastName', lastName);

        if (birthdate) {
            params = params.set('birthdate', this._dateTimeService.formatDate(birthdate, 'YYYY/MM/DD'));
        }
        if (sex) {
            params = params.set('sex', sex.toString());
        }

        return this.http.get<any>(url_, { params });
    }

    getCandidatesByNameBirthdateSex(firstName: string, lastName: string, birthdate: Date, sex: candidatSex): Observable<any> {

        const url_ = this.getUrl('/api/candidats');
        const params = new HttpParams()
            .set('firstName', firstName)
            .set('lastName', lastName);

        if (birthdate != null) {
            params.set('birthdate', this._dateTimeService.formatDate(birthdate, 'YYYY/MM/DD'));
        }
        return this.http.get<any>(url_, { params });
    }

    getCandidatForOrganization(organizationPublicId: string): Observable<any> {
        const url_ = this.getUrl('/api/candidats');
        const params = new HttpParams().set('organizationPublicId', organizationPublicId);
        return this.http.get<any>(url_, { params });
    }

    getCandidatList(): Observable<any> {
        const url_ = this.getUrl('/api/candidats');
        const list = this.http.get<any>(url_);
        return list;
    }

    getCandidatByPublicId(candidatPublicId: string): Observable<any> {
        const url_ = this.getUrl(`/api/candidats/${candidatPublicId}`);
        return this.http.get<any>(url_);
    }

    addAddressHistory(addressHistoryInput: AddressHistoryInput): Observable<any> {
        const url = this.getUrl('/api/candidats/addressHistory/add');
        const result = this.http.put<AddressHistoryInput>(url, addressHistoryInput);
        return result;
    }

    updateCandidat(candidatInput: CandidatInput): Observable<any> {
        const url_ = this.getUrl('/api/candidats');
        return this.http.put<any>(url_, candidatInput);
    }

    getOrganizationCandidatesList(organizationPublicId: string): Observable<any> {
        const url_ = this.getUrl(`/api/organizations/${organizationPublicId}/candidates`);
        const list = this.http.get<any>(url_);
        return list;
    }

    deleteCandidate(candidat: CandidatInput): Observable<any> {
        const url_ = this.getUrl('/api/candidats/deleteCandidate');
        return this.http.post<any>(url_, candidat);
    }

    private getUrl(url: string): string {
        let url_ = this.baseUrl + url;
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    }
}
