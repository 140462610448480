import { Component, OnInit, Injector } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CandidatOutput } from '@shared/models/candidat/candidatOutput';
import { CandidatsService } from '@shared/services/candidats.service';
import { ToastrService } from 'ngx-toastr';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CandidatInput } from '@shared/models/candidat/candidatInput';
import { phoneNumberValidator } from '@shared/utils/validation/phoneNumber-validator.directive';
import { emailAddressValidator } from '@shared/utils/validation/email-validator.directive';
import { AppConsts } from '@shared/AppConsts';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { MenuItem } from 'primeng/api';
import { CRUDAction } from '@shared/models/shared/CRUDAction';
import { socialInsuranceNumberValidators } from '@shared/utils/validation/NAS-validator';
@Component({
    selector: 'app-candidat-update',
    templateUrl: './candidat-update.component.html',
    animations: [ appModuleAnimation() ],
})
export class CandidatUpdateComponent extends AppComponentBase implements OnInit {
    maxDate: Date;

    updateForm = new UntypedFormGroup({
        status: new UntypedFormControl(''),
        firstName: new UntypedFormControl('', Validators.required),
        lastName: new UntypedFormControl('', Validators.required),
        sex: new UntypedFormControl('', Validators.required),
        language: new UntypedFormControl('', Validators.required),
        birthdate: new UntypedFormControl('', Validators.required),
        email: new UntypedFormControl('', emailAddressValidator),
        otherNames: new UntypedFormControl(''),
        maidenName: new UntypedFormControl(''),
        birthPlace: new UntypedFormControl(''),
        socialInsuranceNumber: new UntypedFormControl('', {
            validators: socialInsuranceNumberValidators,
        }),
        driverLicenceNumber: new UntypedFormControl(''),
        phoneNumber: new UntypedFormControl('', phoneNumberValidator),
        phoneExtension: new UntypedFormControl(''),
        organization: new UntypedFormControl({ value: '', disabled: true }),
    });

    candidatPublicId: string;
    candidatResult: CandidatOutput;
    isCandidatUpdated = false;
    organizationPublicId: string;

    masks = AppConsts.masks;
    items: MenuItem[];

    constructor(
        injector: Injector,
        private candidatService: CandidatsService,
        private toastr: ToastrService,
        private localeService: BsLocaleService,
        private _appLocalizationService: AppLocalizationService,
        private _router: Router,
        private _route: ActivatedRoute
    ) {
        super(injector);
        this.maxDate = new Date();
        this.localeService.use(this.localization.currentLanguage.name);
    }

    async ngOnInit() {
        if (this._route.snapshot.paramMap.get('publicId')) {
            this.candidatPublicId = this._route.snapshot.paramMap.get('publicId');
            this.items = [
                {
                    label: this._appLocalizationService.l('Candidates'),
                    routerLink: [ '/candidats' ],
                },
                {
                    label: this._appLocalizationService.l('CandidatDetails'),
                    routerLink: [ `/candidats-details/${this.candidatPublicId}` ],
                },
                { label: this._appLocalizationService.l('CandidateUpdate') },
            ];
        } else {
            this.candidatPublicId = this._route.snapshot.paramMap.get('candidatPublicId');
            this.items = [
                {
                    label: this._appLocalizationService.l('Candidates'),
                    routerLink: [ '/candidats' ],
                },
                {
                    label: this._appLocalizationService.l('CandidatDetails'),
                    routerLink: [ `/candidats-details/${this.candidatPublicId}` ],
                },
                { label: this._appLocalizationService.l('CandidateUpdate') },
            ];
            this.organizationPublicId = this._route.snapshot.paramMap.get('organizationPublicId');
        }

        await this.candidatService.getCandidatByPublicId(this.candidatPublicId).subscribe({
            next: (response) => {
                this.candidatResult = response.result;
                this.updateForm.patchValue(this.candidatResult);

                this.updateForm.controls[ 'organization' ].setValue(this.candidatResult.organization.organizationName);

                if (this._route.snapshot.paramMap.get('fromOrganization') !== '') {
                    const fromOrganization = this._route.snapshot.paramMap.get('fromOrganization') === 'true';
                    if (fromOrganization) {
                        this.items = [
                            {
                                label: this._appLocalizationService.l('Organizations'),
                                routerLink: [ '/organizations' ],
                            },
                            {
                                label: this._appLocalizationService.l('OrganizationDetails'),
                                routerLink: [ `/organizations-details/${this.candidatResult.organization.publicId}` ],
                            },
                            {
                                label: this._appLocalizationService.l('CandidatDetails'),
                                routerLink: [ `/candidats-details/${this.candidatResult.publicId}/true` ],
                            },
                            {
                                label: this._appLocalizationService.l('CandidateUpdate'),
                            },
                        ];
                    } else {
                        this.items = [
                            {
                                label: this._appLocalizationService.l('Candidats'),
                                routerLink: [ '/candidats' ],
                            },
                            {
                                label: this._appLocalizationService.l('CandidatDetails'),
                                routerLink: [ `/candidats-details/${this.candidatResult.publicId}` ],
                            },
                            {
                                label: this._appLocalizationService.l('CandidateUpdate'),
                            },
                        ];
                    }
                }
            },
            error: (err) => {
                this.notify.error('Server error: ' + err.error.error.message);
            },
        });
    }

    isFormInvalid(): boolean {
        return this.updateForm.invalid;
    }

    showForNotValidMessage() {
        const title = this._appLocalizationService.l('VerifyAllControlsTitle');
        const message = this._appLocalizationService.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }

    markAllControlsAsTouch() {
        this.updateForm.controls[ 'firstName' ].markAsTouched();
        this.updateForm.controls[ 'lastName' ].markAsTouched();
        this.updateForm.controls[ 'sex' ].markAsTouched();
        this.updateForm.controls[ 'language' ].markAsTouched();
        this.updateForm.controls[ 'birthdate' ].markAsTouched();
        this.updateForm.controls[ 'otherNames' ].markAsTouched();
        this.updateForm.controls[ 'maidenName' ].markAsTouched();
        this.updateForm.controls[ 'birthPlace' ].markAsTouched();
        this.updateForm.controls[ 'socialInsuranceNumber' ].markAsTouched();
        this.updateForm.controls[ 'driverLicenceNumber' ].markAsTouched();
        this.updateForm.controls[ 'phoneNumber' ].markAsTouched();
        this.updateForm.controls[ 'email' ].markAsTouched();
    }

    showSuccess() {
        const title = this._appLocalizationService.l('CandidateUpdate');
        const successMessage = this._appLocalizationService.l('CandidateUpdated');
        this.toastr.success(successMessage, title);
    }

    showErrorMessage() {
        const title = this._appLocalizationService.l('CandidateUpdateIssue');
        const errorMessage = this._appLocalizationService.l('CandidateUpdateTryAgainLater');
        this.toastr.error(errorMessage, title);
    }

    showDeleteSuccess() {
        const title = this._appLocalizationService.l('CandidateDelete');
        const successMessage = this._appLocalizationService.l('CandidateDeleted');
        this.toastr.success(successMessage, title);
    }

    showDeleteErrorMessage() {
        const title = this._appLocalizationService.l('CandidateDeleteIssue');
        const errorMessage = this._appLocalizationService.l('CandidateDeleteTryAgainLater');
        this.toastr.error(errorMessage, title);
    }

    return() {
        if (this.organizationPublicId !== undefined) {
            this.returnToOrganization();
        } else {
            this.returnToCandidatsDetails();
        }
    }

    returnToOrganization() {
        this._router.navigate([ '/organizations-details', this.organizationPublicId ]);
    }

    returnToCandidatsDetails() {
        this._router.navigate([ '/candidats-details', this.candidatPublicId ]);
    }

    cancelUpdate() {
        if (this.organizationPublicId !== undefined) {
            this.returnToOrganization();
        } else {
            this.returnToCandidatsDetails();
        }
    }

    async save() {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }

        const candidate: CandidatInput = CandidatInput.fromFormControl(this.candidatPublicId, this.updateForm, CRUDAction.Update, this.candidatResult);

        this.isCandidatUpdated = true;
        await this.candidatService
            .updateCandidat(candidate)
            .toPromise()
            .then((response) => {
                const candidate = response.result.result;
                return candidate;
            });
        if (candidate.id !== null) {
            this.showSuccess();
            this.return();
        } else {
            this.isCandidatUpdated = false;
            this.showErrorMessage();
        }
    }

    goToCreateRequest(candidatPublicId: string) {
        this._router.navigate([ '/requests-create', candidatPublicId ]);
    }

    onDelete() {
        const candidat: CandidatInput = CandidatInput.fromOutput(this.candidatResult);

        this.message.confirm(this.l('VerificationDeleteWarningMessage'), this.l('AreYouSure'), (isConfirmed) => {
            if (isConfirmed) {
                try {
                    this.candidatService.deleteCandidate(candidat).subscribe((response) => {
                        this.showDeleteSuccess();
                        this.returnToCandidatsDetails();
                    });
                } catch (e) {
                    this.showDeleteErrorMessage();
                }
            }
        }, {
            confirmButtonText: this.l('Yes'),
            cancelButtonText: this.l('No')        
        });
    }
}
