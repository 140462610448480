import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';

@Injectable()
export class AppNavigationService {
    constructor(
        private _appSessionService: AppAuthService
    ) { }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/dashboard'),
            new AppMenuItem('Management', '', 'flaticon-interface-3', '', [], [
                new AppMenuItem('Organizations', 'Pages.Management.Organizations', 'flaticon-map', '/organizations'),
                new AppMenuItem('Requests', 'Pages.Management.Requests', 'flaticon-paper-plane-1', '/requests'),
                new AppMenuItem('Candidates', 'Pages.Management.Candidat.List', 'flaticon-rotate', '/candidats'),
                new AppMenuItem('Verifications', 'Pages.Management.Verifications', 'fas fa-check', '/verifications'),
                new AppMenuItem('Files', 'Pages.Management.Files', 'fas fa-file', '/files')
            ]),
            new AppMenuItem('Administration', '', 'flaticon-interface-8', '', [], [
                new AppMenuItem('Statistics', 'Pages.Administration.Statistics', 'flaticon-statistics', '/statistics'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users.Edit', 'flaticon-users', '/users'),
                new AppMenuItem('Invoices', 'Pages.Administration.Invoices', 'fas fa-file-invoice-dollar', '/invoice-list'),
                new AppMenuItem('InvoiceStatus', 'Pages.Administration.Invoices', 'fas fa-file-invoice-dollar', '/status-invoice'),
                new AppMenuItem('Overview', 'Pages.Administration.Overview', 'fas fa-eye', '/overview'),
                new AppMenuItem('FAQMenu', 'Pages.Administration.FAQMenu.Edit', 'fas fa-edit', '/faq-management'),
                new AppMenuItem('TutorialsGuides', 'Pages.Administration.Guides.Edit', 'fas fa-edit', '/guides-management'),
            ]),
            new AppMenuItem('TutorialsAndGuides', '', 'fas fa-question-circle', '', [], [
                new AppMenuItem('VerificationsList', 'Pages.VerificationListInfo', 'fas fa-list', '/tutorials/verifications'),
                new AppMenuItem('TutorialsGuides', '', 'fas fa-light fa-file', '/tutorials/guides'),
                new AppMenuItem('FAQMenu', '', 'fas fa-question-circle', '/tutorials/faq')
            ])
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            const subMenuItem = menuItem.items[ i ];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._appSessionService.hasPermission(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                const isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._appSessionService.hasPermission(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (!hideMenuItem && menuItem.items?.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        const menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [ menuItem ];
        }

        let menuItems = [ menuItem ];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
