import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment as env } from 'environments/environment';
import { Observable } from 'rxjs';
import { OrganizationUserInput } from '@shared/models/organizationUser/organizationUserInput';
import { OrganizationService } from './organization.service';

@Injectable({
    providedIn: 'root'
})
export class OrganizationUserService {
    private http: HttpClient;
    private baseUrl: string;
    private orgService: OrganizationService;

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = env.api;
    }

    addOrganizationUserList(organizationUserListInput: Array<OrganizationUserInput>): any {
        const url = this.getUrl('/api/organizationUser');
        return this.http.post<Array<OrganizationUserInput>>(url, organizationUserListInput);
    }

    getUserListOrgAndSubOrg(parentOrgId: number, subOrgId: number): Observable<any> {
        const url = this.getUrl('/api/organizationUser/parentOrg');
        const params =
      new HttpParams().set('parentOrganizationId', parentOrgId.toString())
          .set('subOrganizationId', subOrgId.toString());

        return this.http.get(url, {params});
    }

    getUserList(id: number): Observable<any> {
        const url = this.getUrl('/api/organizationUser/organization');
        const params = new HttpParams().set('organizationId', id.toString());
        return this.http.get(url, {params});
    }

    getAgentList(id: number): Observable<any> {
        const url = this.getUrl('/api/organizationUser/organizationAgents');
        const params = new HttpParams().set('organizationId', id.toString());
        return this.http.get(url, {params});
    }

    getRequestCreators(id: number): Observable<any> {
        const url = this.getUrl('/api/organizationUser/organizationRequestCreators');
        const params = new HttpParams().set('organizationId', id.toString());
        return this.http.get(url, {params});
    }

    getUserNotHideList(publicId: string): any {
        const url = this.getUrl('/api/organizationUser/organizationNotHide');
        const params = new HttpParams().set('organizationPublicId', publicId);
        return this.http.get(url, {params});
    }

    getUserIdByUsername(username: string): any {
        const url = this.getUrl('/api/organizationUser/username');
        const params = new HttpParams().set('username', username);
        return this.http.get(url, {params});
    }

    private getUrl(url: string): string {
        let url_ = this.baseUrl + url;
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    }

    sortUserList(userList): [] {
        userList.sort(function (a, b) {
            const textA = a.userName.toUpperCase();
            const textB = b.userName.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        return userList;
    }

    deleteOrganizationUser(organisationUserToDelete: Array<OrganizationUserInput>): Observable<any> {
        const url = this.getUrl('/api/organizationUser/deleteMany');        
        return this.http.post<Array<OrganizationUserInput>>(url, organisationUserToDelete);
    }
}
