<div appBsModal #mySettingsModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #mySettingsModalForm="ngForm" (ngSubmit)="save()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{"MySettings" | localize}}</span>
                    </h5>
                </div>
                <div class="modal-body" *ngIf="user">
                    <tabset>
                        <tab heading="{{'Profile' | localize}}">
                            <div class="form-group">
                                <label for="Name">{{"Name" | localize}} *</label>
                                <input id="Name" #nameInput="ngModel" class="form-control" type="text" name="Name"
                                    [(ngModel)]="user.name" required maxlength="64">
                                <validation-messages [formCtrl]="nameInput"></validation-messages>
                            </div>

                            <div class="form-group">
                                <label for="Surname">{{"Surname" | localize}} *</label>
                                <input id="Surname" #surnameInput="ngModel" type="text" name="Surname"
                                    class="form-control" [(ngModel)]="user.surname" required maxlength="64">
                                <validation-messages [formCtrl]="surnameInput"></validation-messages>
                            </div>

                            <div class="form-group">
                                <label for="EmailAddress">{{"EmailAddress" | localize}} *</label>
                                <input id="EmailAddress" #emailAddressInput="ngModel" type="email" disabled name="EmailAddress"
                                    class="form-control" [(ngModel)]="user.emailAddress" email maxlength="256">
                                <validation-messages [formCtrl]="emailAddressInput"></validation-messages>
                            </div>

                            <div class="form-group">
                                <label for="UserName">{{"UserName" | localize}} *</label>
                                <input id="UserName" #userNameInput="ngModel" type="text"
                                    disabled class="form-control" [(ngModel)]="user.userName"
                                    name="UserName" required maxlength="256">
                                <validation-messages [formCtrl]="userNameInput"></validation-messages>
                            </div>
                        </tab>
                        <tab heading="{{'Notifications' | localize}}">
                            <div class="row">
                                <!-- Loop through the parent notifications -->
                                <ng-container *ngFor="let parent of emailNotifications;">
                                    <div class="col-md-6 notif-option" *ngIf="!parent.subtypeOf">
                                        <input class="form-check-input" type="checkbox" [id]="parent.id" name="tagOptions"
                                        (change)="changeCheckbox(parent)" [checked]="parent.isChecked">
                                        {{parent.description}}
                                        <validation-messages [formCtrl]="formGroup.get('notifications')">
                                        </validation-messages>

                                        <!-- Loop through the children of the current parent notification -->
                                        <div class="col-md notif-option" *ngFor="let child of subtypeDict.get(parent.id)"
                                        style="padding-left: 6%;">
                                            <input class="form-check-input" type="checkbox" [id]="child.id" name="tagOptions"
                                            (change)="changeCheckbox(child)" [checked]="child.isChecked">
                                            {{ child.description }}
                                            <validation-messages [formCtrl]="formGroup.get('notifications')">
                                            </validation-messages>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </tab>
                    </tabset>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary font-weight-bold" (click)="close()"
                        [disabled]="saving">{{"Cancel" | localize}}</button>
                    <button type="submit" class="btn btn-primary font-weight-bold"
                        [disabled]="!mySettingsModalForm.form.valid || saving"><i class="fa fa-save"></i>
                        <span>{{"Save" | localize}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>