<div>
    <p class="mb-5 ml-4 margin-top-20 align-left">{{'RequestWizzardCreationHelp' | localize}}</p>
    <p class="col-md-9 align-left">{{'RequestWizzardFillMandatoryFields' | localize}}</p>

    <request-wizard-candidate-search [(form)]="formSearch" [(candidates)]="candidates"
        [(selectedCandidate)]="selectedCandidate" [(request)]="request" [(searchDone)]="searchDone"
        [searchOnFocusOut]="true" (candidatesChange)="onCandidatesChange($event)">
    </request-wizard-candidate-search>
    <div class="form-inline margin-top-20 margin-left-20" *ngIf="candidates?.length > 0">
        <div class="form-group">
            <label for="returnToSelectCandidateBtn" class="align-left text-warning">
                <i class="la la-warning warning"></i>
                {{'RequestWizzardWarningCandidateMatch' | localize}}
            </label>
            <button class="btn btn-secondary margin-left-20" id="returnToSelectCandidateBtn" (click)="returnToSelectCandidate()">
                {{'Yes' | localize}}
            </button>
        </div>
    </div>
    <request-wizard-candidate-basic-infos [(form)]="candidateform" [(request)]="request"
        [(updateCandidateInfos)]="createCandidate"
        [resetDriverLicenseRequired]="resetDriverLicenseRequired">
    </request-wizard-candidate-basic-infos>
    <div class="form-inline">
        <div class="form-group col-md-2 inline-block align-left">
            <label for="language" class="block">{{"Language" | localize}}
                <span class="asterix">*</span>
            </label>
            <select id="language" [(ngModel)]="languageSelected" class="form-control" required>
                <option value='0'>{{"French" | localize}}</option>
                <option value='1'>{{"English" | localize}}</option>
            </select>
        </div>
    </div>
    <div class="form-inline justify-content-end">
        <div role="actions">
            <div class="row">
                <div class="col-10"></div>
                <div class="col-1 justify-content-end align-self-center pr-0">
                    <label for="btn-add" *ngIf="createCandidate  && (formSearch.invalid || candidateform.invalid)" class="vertical-align labelConfirmDisabled  float-lg-end" >{{"RequestWizzardConfirm" | localize}}</label>
                    <label for="btn-add" *ngIf="formSearch.valid && candidateform.valid" class="labelConfirm vertical-align float-lg-end" >{{"RequestWizzardConfirm" | localize}}</label>
                </div>
                <div class="col-1 justify-content-start align-self-center pl-0">
                    <button type="submit" id="btn-add" name="btn-add"
                    class="inline-block align-start btn-add pl-0 ml-0 float-lg-start "
                    [disabled]="createCandidate && (formSearch.invalid || candidateform.invalid) && !loading" (click)="addCandidate()">

                    <i *ngIf="!loading" class="la la-check-circle btn-add" data-toggle="tooltip"
                        title="{{'TooltipCandidateCreation' | localize}}"></i>

                    <i *ngIf="loading" class="fas fa-spinner fa-spin btn-add"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="form-inline margin-left-10 margin-top-20">
        <div class="form-group">
            <label for="requestWizzardChangeCandidateBtn" class="margin-right-20">{{'RequestWizzardChangeCandidateQuestion' | localize}}</label>
            <button class="btn btn-secondary" [disabled]="createCandidate" id="requestWizzardChangeCandidateBtn">
                {{'RequestWizzardChangeCandidate' | localize}}
            </button>
        </div>
    </div>
    <div class="d-flex mt-10">
        <div class="pr-0">
            <button class="float-lg-right btn-back p-0 mr-2" [disabled]="!createCandidate" (click)="cancel()">
                <i class="la la-arrow-circle-o-left btn-back float-lg-right"></i>
            </button>
        </div>
        <div class="text-left justify-content-center align-self-center pl-0">
            <label for="saveBtn" class="mb-0">{{"RequestWizzardPreviousStep" | localize}}</label>
        </div>
        <div class="flex-grow-1"></div>
        <div class="text-end justify-content-center align-self-center pr-0">
            <label for="saveBtn" class="mb-0">{{"RequestWizzardNextStep" | localize}}</label>
        </div>
        <div class="pl-0" >
            <button class="float-lg-left btn-next p-0 ml-2" id="saveBtn" data-toggle="tooltip" title="{{'RequestWizzardUpdateCandidateNext' | localize}}" data-placement="top" [disabled]="this.request.candidat.id == 0 || loading" (click)="save()" awNextStep>
                <i class="la la-arrow-circle-o-right btn-next float-lg-left"></i>
            </button>
        </div>
    </div>
</div>
