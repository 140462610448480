import { Component, OnInit, ViewChild, ElementRef, Injector, Output, EventEmitter, Input, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FileUploader, FileUploaderOptions, FileItem } from 'ng2-file-upload';
import { AppConsts } from '@shared/AppConsts';
import { IAjaxResponse } from '@shared/models/ajax-response';
import { RequestFileInput } from '@shared/models/requestFile/requestFileInput';
import { RequestService } from '@shared/services/requests.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { ToastrService } from 'ngx-toastr';
import { FileDto } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { fileType } from '@shared/models/files/fileType';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { environment as env } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
    selector: 'add-request-files',
    templateUrl: './add-request-files.component.html',
    styleUrls: ['./add-request-files.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddRequestFilesComponent extends AppComponentBase implements OnInit {
    @ViewChild('labelImport') labelImport: ElementRef;
    @ViewChild('addRequestFilesModal') modal: ModalDirective;
    @ViewChild('importFile') importFile: ElementRef;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @Input() fileType: fileType;
    requestId: number;
    formGroupRequest: UntypedFormGroup;
    canSave = false;
    uploader: FileUploader;
    saving = false;
    readonly fileInputAccept = '.jpeg,.jpg,.png,.pdf';
    private acceptedFileExtensions = this.fileInputAccept.split(',') as ReadonlyArray<string>;
    private filesToUploadSubject = new BehaviorSubject<File[]>([]);
    filesToUpload$: Observable<File[]> = this.filesToUploadSubject.asObservable();
    private baseUrl: string;
    private _uploaderOptions: FileUploaderOptions;

    constructor(
        injector: Injector,
        private _appLocalizationService: AppLocalizationService,
        private _service: RequestService,
        private toastr: ToastrService,
        private _appAuthService: AppAuthService,
    ) {
        super(injector);
    }

    ngOnInit() {
        this.saving = false;
        this.formGroupRequest = new UntypedFormGroup({
            importFile: new UntypedFormControl('', [this.validateExt])
        });
        this.baseUrl = env.api;
        this.initFileUploader();
    }

    preventDefault(event: Event) {
        event.preventDefault();
    }
    droppedFile(event: DragEvent) {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files.length) {
            this.onFileChange(event.dataTransfer.files);
        }
    }

    initFileUploader() {
        this._uploaderOptions = {
            autoUpload: false,
            url: this.getUrl('/api/Files/upload-file'),
            authToken: 'Bearer ' + this._appAuthService.getToken(),
            removeAfterUpload: true,
            maxFileSize: AppConsts.maxFileSize
        };

        this.uploader = new FileUploader(this._uploaderOptions);
        this.uploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };

        this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
            form.append('FileType', fileItem.file.type);
            form.append('FileName', fileItem.file.name);
            form.append('FileToken', this.guid());
        };
        this.uploader.onSuccessItem = (item, response, status) => {
            const resp = <IAjaxResponse>JSON.parse(response);
            if (resp.success) {
                this.addRequestFile(resp);
            } else {
                this.clearModal();
            }
        };
        this.uploader.onErrorItem = () => {
            this.showError('BadFormat', 'FileTryAgainLater');
            this.clearModal();
        };
    }

    guid(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    onFileChange(files: FileList) {
        this.formGroupRequest.updateValueAndValidity();
        this.canSave = true;
        for (const newFile of Array.from(files)) {
            const lastDotPosition = newFile.name.lastIndexOf('.');
            const fileExtension = lastDotPosition > -1 ? newFile.name.substring(lastDotPosition).toLowerCase() : '';
            // Check if the the file doesn't exceed the maximum size, native element is valid, acceptefilenames only
            if (newFile.size > AppConsts.maxFileSize) {
                this.showWarning('FileAddIssue', 'SizeFileTooLarge');
            } else if (!this.acceptedFileExtensions.includes(fileExtension)) {
                this.showWarning('FileAddIssue', 'SizeFile');
            } else {
                this.addFileToUpload(newFile);
            }
        }
        // Clear the input file
        this.importFile.nativeElement.value = '';
    }

    addFileToUpload(file: File) {
        const filesToUpload = this.filesToUploadSubject.getValue();
        // Check if file already exists
        if (this.fileExists(file, filesToUpload)) {
            this.showWarning('Warning', 'FileExists');
            return;
        }
        // Update the DOM
        const updatedItems = [...filesToUpload, file];
        this.filesToUploadSubject.next(updatedItems);
        // Add the selected files to the uploader after clearing it
        this.uploader.clearQueue();
        this.uploader.addToQueue(updatedItems);
    }

    removeFileToUpload(file: File) {
        // Remove from array and update the DOM
        const filesToUpload = this.filesToUploadSubject.getValue();
        const index = filesToUpload.indexOf(file);
        filesToUpload.splice(index, 1);
        this.filesToUploadSubject.next([...filesToUpload]);
        // Update file uploader queue
        this.uploader.clearQueue();
        this.uploader.addToQueue(filesToUpload);
        // Update save button visibility state
        this.canSave = filesToUpload.length > 0;
    }

    clearFilesToUpload() {
        this.filesToUploadSubject.next([]);
    }

    triggerFileInput() {
        console.log('triggerFileInput');
        this.importFile.nativeElement.click();
    }

    save() {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }
        this.saving = true;
        this.uploader.uploadAll();
    }

    fileExists(fileToFind: File, collection: File[]): boolean {
        return collection.some((existingFile) =>
            existingFile.name === fileToFind.name &&
            existingFile.size === fileToFind.size &&
            existingFile.type === fileToFind.type
        );
    }

    getFormatedFileName(file: File): string {
        const name = file.name;
        const readableSize = this.getReadableSize(file.size);
        return `${name} (${readableSize})`;
    }

    getReadableSize(sizeInOctets: number): string {
        if (sizeInOctets === 0) {
            return '0 o';
        }

        const sizes = ['o', 'Ko', 'Mo', 'Go', 'To'];
        const i = Math.floor(Math.log(sizeInOctets) / Math.log(1024));
        const readableSize = (sizeInOctets / Math.pow(1024, i)).toFixed(2);
        return `${readableSize} ${sizes[i]}`;
    }

    addRequestFile(fileUploadResponse: any) {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }

        const fileReceived = <FileDto>fileUploadResponse.result.fileDto;
        const requestFile: RequestFileInput = {
            id: 0,
            request: null,
            files: {
                publicId: null,
                extension: fileReceived.fileName.split('.').pop(),
                filesToken: fileReceived.fileToken,
                filesName: fileReceived.fileName,
                type: this.fileType
            },
            requestId: this.requestId,
            fileId: 0
        };

        this._service.addRequestFile(requestFile).subscribe({
            next: (response) => {
                const requestFile = response.result.result;
                this.modalSave.emit(null);
                if (requestFile.id !== null) {
                    this.showSuccess('FileAddTitle', 'FileAdded');
                    this.saving = false;
                    this.modal.hide();
                    this.clearFilesToUpload();
                } else {
                    this.showError('FileAddIssue', 'FileUpdateTryAgainLater');
                }
                return requestFile;
            },
            error: (err) => {
                this.showError('FileAddIssue', 'FileUpdateTryAgainLater');
                this.saving = false;
            }
        });
        return null;
    }

    validateExt(c: UntypedFormControl): { [key: string]: boolean } | null {
        const path = c.value.toString().toLowerCase();
        const extension = ['png', 'jpg', 'jpeg', 'pdf'];
        const pathExt = path.split('.').pop();
        if (extension.indexOf(pathExt) >= 0) {
            return null;
        } else {
            return { 'Bad range': true };
        }
    }

    showSuccess(title: string, successMessage: string) {
        title = this._appLocalizationService.l(title);
        successMessage = this._appLocalizationService.l(successMessage);
        this.toastr.success(successMessage, title);
    }

    showWarning(title: string, warningMessage: string) {
        title = this._appLocalizationService.l(title);
        warningMessage = this._appLocalizationService.l(warningMessage);
        this.toastr.warning(warningMessage, title);
    }

    showError(title: string, errorMessage: string) {
        title = this._appLocalizationService.l(title);
        errorMessage = this._appLocalizationService.l(errorMessage);
        this.toastr.error(errorMessage, title);
    }

    close(): void {
        this.canSave = false;
        this.modal.hide();
        this.clearFilesToUpload();
    }

    show(): void {
        this.clearFilesToUpload();
        this.formGroupRequest.controls['importFile'].setValue('');
        this.modal.show();
    }

    showForNotValidMessage() {
        const title = this._appLocalizationService.l('BadFormat');
        const message = this._appLocalizationService.l('SizeFile');
        this.toastr.error(message, title);
    }

    isFormInvalid(): boolean {
        const filesToUpload = this.filesToUploadSubject.getValue();
        return !filesToUpload || filesToUpload.length === 0;
    }

    markAllControlsAsTouch() {
        this.formGroupRequest.controls['importFile'].markAsTouched();
    }

    private getUrl(url: string): string {
        let updatedUrl = this.baseUrl + url;
        updatedUrl = updatedUrl.replace(/[?&]$/, '');
        return updatedUrl;
    }

    private clearModal(): void {
        this.saving = false;
        this.modal.hide();
    }
}
