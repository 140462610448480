import { createAction, props } from '@ngrx/store';
import { GuideOutput } from '@shared/models/guides/guide-output';
import { GuideStatus } from '@shared/models/guides/guide-status';
import { GuideInput } from '@shared/models/guides/guides-input';
import { RequestEvent } from '@shared/models/request-event/request-event.model';
import { AdvancedSearchParams } from '@shared/models/search-filters/search-filters.model';
export const GUIDES_ACTION_GET_ALL = '[Guides] GET ALL';
export const GUIDES_ACTION_GET_ALL_ACTIVE = '[Guides] GET ALL ACTIVE';
export const GUIDES_ACTION_GET_ALL_ACTIVE_SUCCESS = '[Guides] GET ALL ACTIVE SUCCESS';
export const GUIDES_ACTION_RESET_ITEMS = '[Guides] RESET ITEMS';
export const GUIDES_ACTION_GET_ALL_SUCCESS = '[Guides] GET ALL SUCCESS';
export const GUIDES_ACTION_GET_BY_ID = '[Guides] GET BY ID';
export const GUIDES_ACTION_GET_BY_ID_SUCCESS = '[Guides] GET BY ID SUCCESS';
export const GUIDES_ACTION_ADD = '[Guides] ADD';
export const GUIDES_ACTION_ADD_SUCCESS = '[Guides] ADD SUCCESS';
export const GUIDES_ACTION_SELECT = '[Guides] SELECT';
export const GUIDES_ACTION_SELECT_RESET = '[Guides] SELECT RESET';
export const GUIDES_ACTION_UPDATE = '[Guides] UPDATE';
export const GUIDES_ACTION_UPDATE_SUCCESS = '[Guides] UPDATE SUCCESS';
export const GUIDES_ACTION_REMOVE = '[Guides] DELETE';
export const GUIDES_ACTION_REMOVE_SUCCESS = '[Guides] DELETE SUCCESS';
export const GUIDES_ACTION_RESET = '[Guides] RESET';
export const GUIDES_ACTION_RESET_FILTERED_ITEMS = '[Guides] RESET FILTERED ITEMS';
export const GUIDES_ACTION_RESET_FILTERS = '[Guides] RESET FILTERS';
export const GUIDES_ACTION_FILTER = '[Guides] FILTER';
export const GUIDES_ACTION_SEARCH = '[Guides] SEARCH';
export const GUIDES_ACTION_UPDATE_REQUEST_EVENT = '[Guides] UPDATED REQUEST EVENT';
export const GUIDES_ACTION_PDF_EXPORT = '[Guides] PDF EXPORT';
export const GUIDES_ACTION_PDF_EXPORT_RESET = '[Guides] PDF EXPORT RESET';
export const getAll = createAction(GUIDES_ACTION_GET_ALL);
export const getAllActive = createAction(GUIDES_ACTION_GET_ALL_ACTIVE);
export const getAllSuccess = createAction(GUIDES_ACTION_GET_ALL_SUCCESS, props<{ payload: GuideOutput[] }>());
export const getById = createAction(GUIDES_ACTION_GET_BY_ID, props<{ id: number }>());
export const getByIdSuccess = createAction(GUIDES_ACTION_GET_BY_ID_SUCCESS, props<{ payload: GuideOutput }>());
export const select = createAction(GUIDES_ACTION_SELECT, props<{ id?: number }>());
export const resetSelect = createAction(GUIDES_ACTION_SELECT_RESET);
export const add = createAction(GUIDES_ACTION_ADD, props<{ payload: GuideInput }>());
export const addSuccess = createAction(GUIDES_ACTION_ADD_SUCCESS, props<{ payload: GuideOutput }>());
export const update = createAction(GUIDES_ACTION_UPDATE, props<{ payload: GuideOutput }>());
export const updateSuccess = createAction(GUIDES_ACTION_UPDATE_SUCCESS, props<{ payload: GuideOutput }>());
export const remove = createAction(GUIDES_ACTION_REMOVE, props<{ payload: GuideOutput }>());
export const removeSuccess = createAction(GUIDES_ACTION_REMOVE_SUCCESS, props<{ id: number }>());
export const reset = createAction(GUIDES_ACTION_RESET);
export const resetItems = createAction(GUIDES_ACTION_RESET_ITEMS);
export const filter = createAction(GUIDES_ACTION_FILTER, props<{ filters: AdvancedSearchParams<GuideStatus> }>());
export const resetFilteredItems = createAction(GUIDES_ACTION_RESET_FILTERED_ITEMS, props<{ filtersName: string }>());
export const resetFilters = createAction(GUIDES_ACTION_RESET_FILTERS);
export const search = createAction(GUIDES_ACTION_SEARCH, props<{ value: string }>());
export const updateRequestEvent = createAction(GUIDES_ACTION_UPDATE_REQUEST_EVENT, props<{ value?: RequestEvent }>());
export const exportPdf = createAction(GUIDES_ACTION_PDF_EXPORT, props<{ id: number }>());
export const resetExportPdf = createAction(GUIDES_ACTION_PDF_EXPORT_RESET);
