<div class="modal-header">
    <h4 *ngIf="!isReadOnly" class="modal-title pull-left">
        {{ 'EditRequestVerification' | localize }}
    </h4>
    <h4 *ngIf="isReadOnly" class="modal-title pull-left">
        {{ 'RequestVerification' | localize }}
    </h4>
</div>
<div class="modal-body">
    <div *ngIf="loading; else form">
        {{ 'LoadWithThreeDot' | localize }}
    </div>

    <ng-template #form>
        <form [formGroup]="formGroup" autocomplete="off" (ngSubmit)="save()">
            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        <label for="code">{{ 'Code' | localize }}</label>
                        <input id="code" name="code" class="form-control" type="text" formControlName="code" [disabled]="true" />
                    </div>
                    <div class="col-6">
                        <label for="status">{{ 'Status' | localize }}</label>
                        <select *ngIf="!isReadOnly" id="status" name="status" class="form-control" formControlName="status" (change)="updateStatus()">
                            <option *ngFor="let requestVerification of requestVerificationList" [ngValue]="requestVerification.value">
                                {{ requestVerification.key }}
                            </option>
                        </select>
                        <input *ngIf="isReadOnly" id="status" name="status" class="form-control" type="text" [value]="status" [disabled]="isReadOnly" />
                    </div>
                </div>
            </div>

            <div *ngIf="!isAgentPpo">
                <div class="form-group">
                    <label for="quantity">{{ 'Quantity' | localize }}</label>
                    <input id="quantity" name="quantity" class="form-control" type="number" maxlength="256" min="0" formControlName="quantity" [readOnly]="isQuantityReadOnly" />
                </div>
                <div *ngIf="editedRequestVerification.verification.canBeExpress" class="form-group">
                    <input
                        id="isExpress"
                        name="isExpress"
                        class="form-check-input"
                        type="checkbox"
                        formControlName="isExpress"
                        (change)="updateExpress()"
                        [readOnly]="isExpressReadOnly"
                    />
                    <label class="ps-3" for="isExpress">{{ 'VerificationExpressService' | localize }}</label>
                </div>
                <div class="form-group">
                    <label for="note">{{ 'Note' | localize }}</label>
                    <textarea id="note" name="note" class="form-control" rows="4" formControlName="note" (change)="updateNote()"></textarea>
                </div>
            </div>

            <div class="form-group" id="results-div" [hidden]="!isResultsVisible()">
                <label id="result-label" for="result">
                    {{ 'Result' | localize }}
                    <span id="asterix-results" [hidden]="!isResultsRequired()" class="asterix">*</span>
                </label>

                <select id="result-select" id="result" name="result" class="form-control" formControlName="result" (change)="getDetailedResult()">
                    <option value=""></option>
                    <option *ngFor="let requestVerificationResult of resultsOptions" [ngValue]="requestVerificationResult.key">
                        {{ requestVerificationResult.value }}
                    </option>
                </select>
            </div>

            <div class="form-group" id="results-required-div" [hidden]="!isResultsVisible()">
                <label for="detailedResult">
                    {{ 'DetailedResult' | localize }}
                    <span id="asterix-detailed-results" [hidden]="!isDetailedResultsRequired()" class="asterix">*</span>
                </label>

                <p-editor id="detailedResult" formControlName="detailedResult" [style]="{ height: '100px' }" [readonly]="isReadOnly">
                    <!-- TODO: when updating lib primeng, we will have to rewrite the lines below.
                        I'm using the deprecated syntax since we are using an old version.
                        doc: https://www.primefaces.org/primeng/editor
                        ref: https://github.com/primefaces/primeng/blob/master/CHANGELOG.md#1120-2021-01-21
                        -->
                    <p-header>
                        <span class="ql-formats">
                            <button class="ql-bold"></button>
                            <button class="ql-italic"></button>
                            <button class="ql-underline"></button>
                            <button class="ql-clean"></button>
                        </span>
                    </p-header>
                </p-editor>
            </div>
            <span
                id="required-message"
                [hidden]="!(formGroup.controls['result'].invalid || formGroup.controls['detailedResult'].invalid || formGroup.controls['detailedResult'].errors?.invalidResult)"
            >
                <p style="color: red">{{ 'resultsRequired' | localize }}</p>
            </span>

            <div *ngIf="isDJI" class="specifications">
                <h5>{{ 'DjiSpecifications' | localize }}</h5>
                <div class="row">
                    <form [formGroup]="formCountry" autocomplete="off">
                        <div class="form-group col-md-12">
                            <label for="country">{{ 'Country' | localize }}</label>
                            <input
                                id="country"
                                formControlName="country"
                                type="text"
                                name="country"
                                class="form-control"
                                maxlength="256"
                                [typeahead]="countryList"
                                (typeaheadOnBlur)="typeaheadOnBlur($event)"
                                typeaheadOptionField="countryName"
                                [typeaheadScrollable]="true"
                                [typeaheadOptionsInScrollableView]="5"
                                autocomplete="off"
                                (typeaheadOnSelect)="onSelect($event)"
                                (typeaheadNoResults)="typeaheadNoResults($event)"
                                [typeaheadMinLength]="0"
                                typeaheadOptionsLimit="300"
                            />
                            <div class="alert alert-danger" *ngIf="noResultCountry">
                                {{ 'ResultNotFoundElementInList' | localize }}
                            </div>
                            <validation-messages [formCtrl]="formCountry.get('country')"></validation-messages>
                        </div>
                    </form>
                    <div *ngIf="isGranted('Pages.Management.Requests.CanSeePrivateInformation')">
                        <div class="form-group col-md-12">
                            <label for="customPrice">{{ 'CustomPrice' | localize }}</label>
                            <input
                                id="customPrice"
                                type="text"
                                name="customPrice"
                                class="form-control"
                                maxlength="256"
                                formControlName="customPrice"
                                mask="0*.00"
                                suffix=" $"
                                [dropSpecialCharacters]="false"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isADD" class="specifications">
                <h5>{{ 'AddSpecifications' | localize }}</h5>
                <div class="row">
                    <div class="form-group col-md-12">
                        <label for="customfield">{{ 'Service' | localize }}</label>
                        <input id="customfield" type="text" formControlName="customFieldADD" name="customfield" class="form-control" maxlength="256" />
                    </div>
                    <div *ngIf="isGranted('Pages.Management.Requests.CanSeePrivateInformation')" class="form-group col-md-12">
                        <label for="customPrice">{{ 'CustomPrice' | localize }}</label>
                        <input
                            id="customPrice"
                            type="text"
                            name="customPrice"
                            class="form-control"
                            maxlength="256"
                            formControlName="customPrice"
                            mask="0*.00"
                            suffix=" $"
                            [dropSpecialCharacters]="false"
                        />
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button id="submit-button" *ngIf="!isReadOnly" [disabled]="!formGroup.valid" type="submit" class="btn btn-primary">
                    {{ 'Save' | localize }}
                </button>
                <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">
                    {{ 'Close' | localize }}
                </button>
            </div>
        </form>
    </ng-template>
</div>
