import { Component, OnInit, Injector, Input, ViewChild, ElementRef } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DatePipe } from '@angular/common';
import { InvoiceService } from '@shared/services/invoice.service';
import { InvoiceOutput } from '@shared/models/InvoiceElements/invoice/invoiceOutput';
import { AgGridAngular } from '@ag-grid-community/angular';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AddressOutput } from '@shared/models/address/addressOutput';
import { InvoiceLinesOutput } from '@shared/models/InvoiceElements/invoiceLine/invoiceLineOutput';
import { InvoiceTaxesOutput } from '@shared/models/InvoiceElements/invoiceTaxe/invoiceTaxeOutput';
import { AppConsts } from '@shared/AppConsts';
import { ActivatedRoute, Router } from '@angular/router';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { AddressInput } from '@shared/models/address/addressInput';
import { ToastrService } from 'ngx-toastr';
import { InvoiceStatusEnum } from '@shared/models/InvoiceElements/invoice/invoiceStatus';
import { InvoiceInput } from '@shared/models/InvoiceElements/invoice/invoiceInput';
import { InvoiceLinesInput } from '@shared/models/InvoiceElements/invoiceLine/invoiceLineInput';
import { InvoiceAdjustmentsOutput } from '@shared/models/InvoiceElements/InvoiceAdjustment/InvoiceAdjustmentOutput';
import { InvoiceAdjustmentsInput } from '@shared/models/InvoiceElements/InvoiceAdjustment/InvoiceAdjustmentInput';
import { InvoiceTaxesInput } from '@shared/models/InvoiceElements/invoiceTaxe/invoiceTaxeInput';
import { multipleEmailAddressValidator } from '@shared/utils/validation/multiple-emails-validator.directive';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';

@Component({
    selector: 'invoice-update',
    templateUrl: './invoice-update.component.html',
    styleUrls: ['./invoice-update.component.scss'],
    providers: [DatePipe],
    animations: [appModuleAnimation()]
})
export class InvoiceUpdateComponent extends AppComponentBase implements OnInit {
    @ViewChild('content') content: ElementRef;
    @ViewChild('topGrid') topGrid: AgGridAngular;
    @ViewChild('bottomGrid') bottomGrid: AgGridAngular;

    private gridApi;
    public defaultColDefFirst;
    public defaultColDefSecond;
    paginationNumberFormatterFirst;
    paginationNumberFormatterSecond;
    paginationPageSizeFirst = 20;
    paginationPageSizeSecond = 20;

    @Input() organizationPublicId: string;

    publicId: string;

    AppConsts = AppConsts;
    topOptions = { alignedGrids: [] };
    bottomOptions = { alignedGrids: [] };
    selectedInvoiceRow: any;
    rowDataForFirst: any;
    columnDefsForFirst: any;
    rowDataForSecond: any;
    columnDefsForSecond: any;
    result: InvoiceOutput;
    resultinvoiceLines: InvoiceLinesOutput[];
    resultInvoiceAdjustments: InvoiceAdjustmentsOutput[];
    resultinvoiceTaxes: InvoiceTaxesOutput[];
    resultAdress: AddressOutput;
    invoiceLinesInput = [];
    invoiceAdjustmentsInput = [];

    rowSelection: string;
    filterText = '';
    invoiceAdjustment: InvoiceAdjustmentsInput;

    idAdress: number;
    test = false;
    isInvoiceUpdated = false;
    ispurchaseOrderAvailable = false;

    formGroup = new UntypedFormGroup({
        organization: new UntypedFormControl({ value: '', disabled: true }),
        civicNo: new UntypedFormControl('', Validators.required),
        street: new UntypedFormControl('', Validators.required),
        app: new UntypedFormControl({ value: '' }),
        city: new UntypedFormControl('', Validators.required),
        postalCode: new UntypedFormControl('', Validators.required),
        country: new UntypedFormControl(''),
        countryName: new UntypedFormControl('', Validators.required),
        state: new UntypedFormControl(''),
        stateName: new UntypedFormControl('', Validators.required),
        contactName: new UntypedFormControl('', Validators.required),
        invoiceEmail: new UntypedFormControl('', [Validators.required, multipleEmailAddressValidator]),
        invoiceNumber: new UntypedFormControl({ value: '', disabled: true }),
        purchaseOrder: new UntypedFormControl({ value: '', disabled: true }),
        clientName: new UntypedFormControl({ value: '', disabled: true }),
        total: new UntypedFormControl({ value: '', disabled: true }),
        subTotal: new UntypedFormControl({ value: '', disabled: true }),
        status: new UntypedFormControl({ value: '', disabled: true }),
        tps: new UntypedFormControl({ value: '', disabled: true }),
        tvq: new UntypedFormControl({ value: '', disabled: true })
    });

    constructor(
        private injector: Injector,
        private service: InvoiceService,
        private _appLocalizationService: AppLocalizationService,
        private _route: ActivatedRoute,
        private _router: Router,
        private toastr: ToastrService,
        private datePipe: DatePipe,
        private _dateTimeService: DateTimeService
    ) {
        super(injector);
        this.rowSelection = 'single';

        this.paginationNumberFormatterFirst = function (params) {
            return params.value.toLocaleString();
        };

        this.paginationNumberFormatterSecond = function (params) {
            return params.value.toLocaleString();
        };
    }

    ngOnInit(): void {
        (<HTMLInputElement>(<unknown>document.getElementsByName('invoiceNumber'))).pattern = '/[0-9][0-9][0-9]s[0-9][0-9][0-9]s[0-9][0-9][0-9]/';
        if (this._route.snapshot.paramMap.get('publicId') !== '') {
            this.publicId = this._route.snapshot.paramMap.get('publicId');
            this.service
                .getInvoiceByPublicId(this.publicId)
                .toPromise()
                .then((data) => {
                    this.result = data.result;
                    this.resultAdress = data.result.address;
                    this.idAdress = data.result.address.id;
                    this.resultinvoiceLines = data.result.invoiceLines;
                    this.resultInvoiceAdjustments = data.result.invoiceAdjustments;
                    this.resultinvoiceTaxes = data.result.invoiceTaxes;
                    this.rowDataForFirst = this.resultinvoiceLines;
                    this.rowDataForSecond = this.resultInvoiceAdjustments;
                    this.formGroup.patchValue(this.result);
                    this.formGroup.controls['organization'].setValue(this.result.organizationName);
                    this.formGroup.controls['civicNo'].setValue(this.resultAdress.civicNo);
                    this.formGroup.controls['street'].setValue(this.resultAdress.street);
                    this.formGroup.controls['app'].setValue(this.resultAdress.app);
                    this.formGroup.controls['city'].setValue(this.resultAdress.city);
                    this.formGroup.controls['country'].setValue(this.resultAdress.country);
                    this.formGroup.controls['state'].setValue(this.resultAdress.state);
                    this.formGroup.controls['countryName'].setValue(this.resultAdress.country.countryName);
                    this.formGroup.controls['stateName'].setValue(this.resultAdress.state.stateName);
                    this.formGroup.controls['postalCode'].setValue(this.resultAdress.postalCode);
                    this.formGroup.controls['clientName'].setValue(this.result.clientId);
                    this.formGroup.controls['total'].setValue(Number(this.result.total).toFixed(2) + ' $');
                    this.formGroup.controls['subTotal'].setValue(Number(this.result.subTotal).toFixed(2) + ' $');
                    this.formGroup.controls['status'].setValue(this.convertStatusEnumToString(this.result.status));

                    this.resultinvoiceTaxes.forEach((element) => {
                        if (element.taxes.code === 'TPS') {
                            this.formGroup.controls['tps'].setValue(element.calculatedAmount.toFixed(2) + ' $');
                        } else if (element.taxes.code === 'TVQ') {
                            this.formGroup.controls['tvq'].setValue(element.calculatedAmount.toFixed(2) + ' $');
                        }
                    });

                    this.columnDefsForFirst = [
                        {
                            headerName: this._appLocalizationService.l('VerificationDetails'),
                            width: 350,
                            field: 'verification.type',
                            sortable: true,
                            filter: true
                        },
                        {
                            headerName: this._appLocalizationService.l('Quantity'),
                            width: 300,
                            field: 'quantity',
                            sortable: true,
                            filter: true
                        },
                        {
                            headerName: this._appLocalizationService.l('Rate'),
                            width: 300,
                            field: 'unitPrice',
                            sortable: true,
                            filter: true
                        },
                        {
                            headerName: this._appLocalizationService.l('Total'),
                            width: 230,
                            field: 'total',
                            sortable: true,
                            filter: true
                        }
                    ];
                    this.columnDefsForSecond = [
                        {
                            headerName: this._appLocalizationService.l('DiscountDescription'),
                            field: 'description',
                            width: 450,
                            sortable: true,
                            filter: true
                        },
                        {
                            headerName: this._appLocalizationService.l('InvoiceAdjustement'),
                            valueGetter: function (params) {
                                return params.data.adjustment;
                            },
                            valueSetter: function (params) {
                                params.data.adjustment = Number(params.newValue);
                                return params.data.adjustment;
                            },
                            width: 300,
                            sortable: true,
                            filter: true
                        }
                    ];
                    const purchaseOrderClassOrganization = <HTMLInputElement>(<unknown>document.getElementsByClassName('form-group col-md-4 purchaseOrderClassOrganization'));
                    const purchaseOrderClassInvoiceNumber = <HTMLInputElement>(<unknown>document.getElementsByClassName('form-group col-md-4 purchaseOrderClassInvoiceNumber'));
                    if (this.result.purchaseOrder !== '') {
                        this.ispurchaseOrderAvailable = true;
                    } else {
                        purchaseOrderClassOrganization[0].classList.add('col-md-6');
                        purchaseOrderClassOrganization[0].classList.remove('col-md-4');
                        purchaseOrderClassInvoiceNumber[0].classList.add('col-md-6');
                        purchaseOrderClassInvoiceNumber[0].classList.remove('col-md-4');
                    }
                    this.bottomGrid.gridOptions.getContextMenuItems = this.getContextMenuItems.bind(this);
                    this.defaultColDefFirst = {
                        resizable: true,
                        sortable: true,
                        filter: true
                    };
                    this.defaultColDefSecond = {
                        editable: true,
                        resizable: true,
                        sortable: true,
                        filter: true
                    };
                });
        }
        this.topOptions.alignedGrids.push(this.topOptions);
        this.bottomOptions.alignedGrids.push(this.bottomOptions);
    }

    onPageSizeChangedFirstGrid(e) {
        this.topGrid.api.paginationSetPageSize(Number(e));
        this.paginationPageSizeFirst = e;
    }

    onPageSizeChangedSecondGrid(e) {
        this.bottomGrid.api.paginationSetPageSize(Number(e));
        this.paginationPageSizeSecond = e;
    }

    changeSummaryAfterAdj(arry) {
        this.calculateSubTotal(arry);
        this.formGroup.controls['total'].setValue(Number(this.result.total).toFixed(2) + ' $');
        this.formGroup.controls['subTotal'].setValue(Number(this.result.subTotal).toFixed(2) + ' $');
        this.resultinvoiceTaxes.forEach((element) => {
            if (element.taxes.code === 'TPS') {
                this.formGroup.controls['tps'].setValue(element.calculatedAmount.toFixed(2) + ' $');
            } else if (element.taxes.code === 'TVQ') {
                this.formGroup.controls['tvq'].setValue(element.calculatedAmount.toFixed(2) + ' $');
            }
        });
    }

    onRowValueChanged(event) {
        const gridData = this.getAllData();
        this.changeSummaryAfterAdj(gridData);
        return gridData;
    }

    getAllData() {
        const rowData = [];
        this.bottomGrid.api.forEachNode((node) => rowData.push(node.data));
        return rowData;
    }

    onGridReady(params) {
        this.gridApi = params.api;
        if (screen.availWidth > 414) {
            this.gridApi.sizeColumnsToFit();
        }
    }

    refresh() {
        this.filterText = '';
        this.bottomGrid.api.setQuickFilter('');
        this.topGrid.api.setQuickFilter('');
    }

    search() {
        this.bottomGrid.api.setQuickFilter(this.filterText);
        this.topGrid.api.setQuickFilter(this.filterText);
    }

    convertStatusEnumToString(value: number): string {
        return this.l(InvoiceStatusEnum[value]);
    }

    dateFormater(params: ValueFormatterParams) {
        return this.datePipe.transform(this._dateTimeService.toUtcDate(params.value).toLocal().toString(), AppConsts.dateTimeFormat);
    }

    isFormInvalid(): boolean {
        return this.formGroup.invalid;
    }

    markAllControlsAsTouch() {
        this.formGroup.controls['invoiceEmail'].markAsTouched();
        this.formGroup.controls['civicNo'].markAsTouched();
        this.formGroup.controls['street'].markAsTouched();
        this.formGroup.controls['app'].markAsTouched();
        this.formGroup.controls['city'].markAsTouched();
        this.formGroup.controls['postalCode'].markAsTouched();
        this.formGroup.controls['state'].markAsTouched();
        this.formGroup.controls['country'].markAsTouched();
    }

    showForNotValidMessage() {
        const title = this._appLocalizationService.l('VerifyAllControlsTitle');
        const message = this._appLocalizationService.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }

    onAddAdjustment() {
        this.invoiceAdjustment = {
            id: 0,
            invoiceId: this.result.id,
            description: this._appLocalizationService.l('AddAdjustment'),
            adjustment: 0,
            creationTime: this.result.creationTime
        };
        this.resultInvoiceAdjustments.push(this.invoiceAdjustment);
        this.bottomGrid.api.setRowData(this.resultInvoiceAdjustments);
    }

    createNewRowData() {
        const newData = {
            description: this._appLocalizationService.l('AddAdjustment'),
            adjustment: 0
        };
        return newData;
    }

    onRemoveSelected() {
        const selectedData = this.bottomGrid.api.getSelectedRows();
        this.bottomGrid.api.updateRowData({ remove: selectedData });
        this.resultInvoiceAdjustments = this.resultInvoiceAdjustments.filter((invAdj) => <any>invAdj !== selectedData[0]);
        this.changeSummaryAfterAdj(this.resultInvoiceAdjustments);
    }

    getContextMenuItems() {
        const removeActionName = this._appLocalizationService.l('RemoveAdjustment');
        const result = [
            {
                name: removeActionName,
                action: this.onRemoveSelected.bind(this),
                icon: '<i class="fas fa-trash-alt"></i>'
            },
            'separator',
            'copy',
            'copyWithHeaders',
            'paste',
            'separator',
            'export'
        ];
        return result;
    }

    onUpdate() {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }
        const address = this.initAddress();
        const invoiceAdjustments = this.initInvoiceAdjustment();

        this.getSubTotal();
        const input: InvoiceInput = {
            id: this.result.id,
            publicId: this.result.publicId,
            idSage: this.result.idSage,
            addressId: this.result.addressId,
            organizationId: this.result.organizationId,
            organizationName: this.result.organizationName,
            purchaseOrder: this.result.purchaseOrder,
            clientId: this.result.clientId,
            contactName: this.formGroup.controls['contactName'].value,
            invoiceEmail: this.formGroup.controls['invoiceEmail'].value,
            total: this.result.total,
            subTotal: this.result.subTotal,
            status: this.result.status,
            invoiceNumber: this.result.invoiceNumber,
            invoiceCode: this.result.invoiceCode,
            creationTime: this.result.creationTime,
            address: address,
            invoiceLines: this.result.invoiceLines,
            invoiceAdjustments: invoiceAdjustments,
            invoiceTaxes: this.resultinvoiceTaxes
        };
        this.isInvoiceUpdated = true;
        this.service
            .UpdateInvoice(input)
            .toPromise()
            .then((response) => {
                const valueReceived = response.result;
                if (valueReceived.id !== null) {
                    const title = this._appLocalizationService.l('InvoiceUpdate');
                    const successMessage = this._appLocalizationService.l('InvoiceUpdated');
                    this.showSuccess(title, successMessage);
                    this.returnToDetails();
                } else {
                    const title = this._appLocalizationService.l('InvoiceUpdateIssue');
                    const errorMessage = this._appLocalizationService.l('InvoiceUpdateTryAgainLater');
                    this.isInvoiceUpdated = false;
                    this.showErrorMessage(errorMessage, title);
                }
                return valueReceived;
            });
    }

    calculateSubTotal(arr) {
        if (arr.length !== 0) {
            this.result.subTotal = 0;
            this.result.subTotal =
                this.result.invoiceLines.map((invl) => invl.unitPrice * invl.quantity).reduce((total, curr) => total + curr, 0) +
                this.getAllData()
                    .map((invl) => invl.adjustment)
                    .reduce((total, curr) => total + curr, 0);

            this.result.total = this.result.subTotal;
            this.result.invoiceTaxes.forEach((tax) => {
                tax.calculatedAmount = (this.result.subTotal * tax.pourcentage) / 100;
                this.result.total += tax.calculatedAmount;
            });
        } else {
            this.result.subTotal = 0;
            this.result.subTotal = this.result.invoiceLines.map((invl) => invl.unitPrice * invl.quantity).reduce((total, curr) => total + curr, 0);

            this.result.total = this.result.subTotal;
            this.result.invoiceTaxes.forEach((tax) => {
                tax.calculatedAmount = (this.result.subTotal * tax.pourcentage) / 100;
                this.result.total += tax.calculatedAmount;
            });
        }
    }
    getSubTotal() {
        this.calculateSubTotal(this.resultInvoiceAdjustments);
    }

    showSuccess(title: string, successMessage: string) {
        this.toastr.success(title, successMessage);
    }

    showErrorMessage(errorMessage: string, title: string) {
        this.toastr.error(errorMessage, title);
    }

    returnToDetails() {
        this._router.navigate(['/invoice-details', this.result.publicId]);
    }

    initInvoiceLine(): InvoiceLinesInput[] {
        this.resultinvoiceLines.forEach((element) => {
            const invoiceLine: InvoiceLinesInput = {
                id: element.id,
                invoiceId: element.invoiceId,
                productId: element.productId,
                quantity: element.quantity,
                unitPrice: element.unitPrice,
                total: element.total,
                verification: element.verification,
                invoice: element.invoice,
                creationTime: element.creationTime
            };
            this.invoiceLinesInput.push(invoiceLine);
        });
        return this.invoiceLinesInput;
    }

    initInvoiceAdjustment(): InvoiceAdjustmentsInput[] {
        const adjustmentValues = this.getAllData();
        this.resultInvoiceAdjustments = adjustmentValues;
        return this.resultInvoiceAdjustments;
    }

    initInvoiceTaxes(): InvoiceTaxesInput[] {
        const invoiceTaxesValues = this.resultinvoiceTaxes;
        return invoiceTaxesValues;
    }

    initAddress(): AddressInput {
        const address: AddressInput = {
            id: this.idAdress,
            civicNo: this.formGroup.controls['civicNo'].value,
            street: this.formGroup.controls['street'].value,
            app: this.formGroup.controls['app'].value,
            city: this.formGroup.controls['city'].value,
            postalCode: this.formGroup.controls['postalCode'].value,
            state: this.formGroup.controls['state'].value,
            country: this.formGroup.controls['country'].value
        };
        return address;
    }
}
