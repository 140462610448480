import { AppComponentBase } from '@shared/common/app-component-base';
import { Component, ViewChild, Injector, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NoteInput } from '@shared/models/note/noteInput';
import { finalize } from 'rxjs/operators';
import { NotesService } from '@shared/services/note.service';
import {AppAuthService} from "@app/shared/common/auth/app-auth.service";

@Component({
    selector: 'notes-add',
    templateUrl: './notes-add.component.html'
})
export class NoteAddComponent extends AppComponentBase {
    @ViewChild('notesAddModal') notesAddModal: ModalDirective;
    @Input() requestId: number;
    @Input() onlyEmitNote = false;
    @Input() requestStatus: number;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    formGroup = new UntypedFormGroup({
        description: new UntypedFormControl('', Validators.required),
        isPrivateNote: new UntypedFormControl(0),
        isPpoNote: new UntypedFormControl(0)
    });

    constructor(injector: Injector, private service: NotesService, private _appAuthService: AppAuthService) {
        super(injector);
    }

    active = false;
    saving = false;
    isPrivateNote = false;
    isPpoNote = false;
    isAgentPpo = false;

    show(): void {
        this.active = true;
        this.formGroup.controls['description'].setValue('');
        this.isPrivateNote = false;
        this.isAgentPpo = this._appAuthService.hasRole('AgentPPO');
        this.isPpoNote = this.isAgentPpo;
        this.notesAddModal.show();
    }

    async save(): Promise<void> {
        this.saving = true;
        const note: NoteInput = {
            description: this.formGroup.controls['description'].value,
            requestId: this.requestId,
            isPrivateNote: this.isPrivateNote,
            requestStatus: this.requestStatus,
            isPpoNote: this.isAgentPpo || this.isPpoNote
        };

        if (this.onlyEmitNote) {
            // Defer note creation. Only emit note (e.g. to add it to a list)
            this.saving = false;
            this.modalSave.emit(note);
            this.close();
        } else {
            await this.service
                .addNote(note)
                .pipe(
                    finalize(() => {
                        this.saving = false;
                    })
                )
                .toPromise()
                .then(() => {
                    this.notify.info(this.l('SavedSuccessfully'));
                    this.close();
                    this.modalSave.emit(null);
                });
        }
    }

    close(): void {
        this.active = false;
        this.notesAddModal.hide();
    }

    changeIsPrivate() {
        this.isPrivateNote = !this.isPrivateNote;
        if (this.isPrivateNote) {
            this.isPpoNote = false;
        }
    }

    changeIsPpo() {
        this.isPpoNote = !this.isPpoNote;
        if (this.isPpoNote) {
            this.isPrivateNote = false;
        }
    }
}
