import {
    Component,
    OnInit,
    ViewChild,
    Injector,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import { AgGridAngular } from '@ag-grid-community/angular';
import { PersonnalReferencesAddComponent } from '../personnal-references-add/personnal-references-add.component';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { DatePipe } from '@angular/common';
import { PersonnalReferencesOutput } from '@shared/models/CandidatDetails/personnalReferences/personnalReferencesOutput';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { AppConsts } from '@shared/AppConsts';
import { ActivatedRoute, Router } from '@angular/router';
import { GridBtnUpdateComponent } from '@app/shared/grid/grid-btn-update/grid-btn-update.component';
import { LazyLoadEvent } from 'primeng/api';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PersonnalReferencesUpdateComponent } from '../personnal-references-update/personnal-references-update.component';
import { GridBtnDeleteComponent } from '@app/shared/grid/grid-btn-delete/grid-btn-delete.component';
import { ToastrService } from 'ngx-toastr';
import { InformationSourceDTO } from '@shared/models/request/informationSourceDTO';
import { PersonnalReferenceService } from '@shared/services/personnalReference.service';
import { RequestService } from '@shared/services/requests.service';
import { InvoiceStatusEnum } from '@shared/models/InvoiceElements/invoice/invoiceStatus';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';

@Component({
    selector: 'personnal-references-list',
    templateUrl: './personnal-references-list.component.html',
    styleUrls: ['./personnal-references-list.component.scss'],
    providers: [DatePipe],
})
export class PersonnalReferencesListComponent
    extends AppComponentBase
    implements OnInit {
    @ViewChild('agGrid') agGrid: AgGridAngular;
    @ViewChild('personnalRefencesAddModal')
    personnalRefencesAddModal: PersonnalReferencesAddComponent;
    @Input() requestId: number;
    @Input() candidateId: number;
    @Input() candidatePublicId: string;
    @Input() requestOpen: boolean;
    @Input() informationSource: InformationSourceDTO =
        InformationSourceDTO.Candidate;
    @Input() fromComponent: string;
    @Output() modalRefresh: EventEmitter<any> = new EventEmitter<any>();
    @Input() invoiceStatus: InvoiceStatusEnum;
    @Input() isAllowedToUpdateRequest: boolean;

    public defaultColDef;
    rowData: PersonnalReferencesOutput[] = [];
    columnDefs: any;
    publicId: string;
    selectedPersonalReference: PersonnalReferencesOutput;
    bsModalRef: BsModalRef;
    rowSelection: string;
    allReadOnly: boolean;

    paginationNumberFormatter;
    paginationPageSize = 20;
    invoiceSent: boolean;

    constructor(
        private injector: Injector,
        private modalService: BsModalService,
        private datePipe: DatePipe,
        private _appLocalizationService: AppLocalizationService,
        private _route: ActivatedRoute,
        private router: Router,
        private service: PersonnalReferenceService,
        private requestService: RequestService,
        private toastr: ToastrService,
        private _dateTimeService: DateTimeService
    ) {
        super(injector);
        this.rowSelection = 'single';

        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };
    }

    async ngOnInit() {
        if (this.invoiceStatus === InvoiceStatusEnum.InvoiceSent) {
            this.invoiceSent = true;
        }

        await this.initializeRowData();

        this.columnDefs = [
            {
                headerName: this._appLocalizationService.l('Name'),
                width: 250,
                valueGetter: this.concatName,
                sortable: true,
                filter: true,
            },
            {
                headerName: this._appLocalizationService.l('Relationship'),
                width: 180,
                field: 'relationship',
                sortable: true,
                filter: true,
            },
            {
                headerName: this._appLocalizationService.l('PhoneNumber'),
                width: 200,
                valueGetter: this.concatTelephonePoste,
                sortable: true,
                filter: true,
            },
            {
                headerName: this._appLocalizationService.l('Location'),
                width: 150,
                field: 'location',
                sortable: true,
                filter: true,
            },
            {
                headerName: '',
                width: 110,
                cellRendererFramework: this.getEditBtnIfNotReadOnly(),
                cellRendererParams: { action: this.updatePersonnalReference },
            },
            {
                headerName: '',
                width: 110,
                cellRendererFramework: this.getDeleteBtnIfNotReadOnly(),
                cellRendererParams: { action: this.deletePersonnalReference },
            },
        ];

        this.defaultColDef = { resizable: true, sortable: true, filter: true };
    }

    concatTelephonePoste(params) {
        let prefix = ' + 1 ';
        if (params.data.phoneExtension === null) {
            params.data.phoneExtension = '';
        }
        if (
            params.data.phoneNumber === null ||
            params.data.phoneNumber === ''
        ) {
            params.data.phoneNumber = '';
            prefix = '';
        }
        const phoneExtension =
            params.data.phoneExtension !== ''
                ? '#' + params.data.phoneExtension
                : '';
        return prefix + params.data.phoneNumber + ' ' + phoneExtension;
    }

    concatName(params) {
        const name = params.data.firstName + ' ' + params.data.lastName;
        return name;
    }

    onPageSizeChanged(e) {
        this.agGrid.api.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    getEditBtnIfNotReadOnly() {
        const btn = GridBtnUpdateComponent;
        if (this.allReadOnly) {
            return null;
        } else if (this.isAllowedToUpdateRequest) {
            return btn;
        } else {
            return null;
        }
    }

    getDeleteBtnIfNotReadOnly() {
        const btn = GridBtnDeleteComponent;
        if (this.allReadOnly) {
            return null;
        } else if (this.invoiceSent) {
            return null;
        } else {
            return btn;
        }
    }

    async initializeRowData() {
        await this.service
            .getPersonnalReferencesByCandidat(this.candidatePublicId)
            .toPromise()
            .then((response) => {
                this.rowData = response.result;
                this.allReadOnly = false;
            });
    }

    dateFormater(params: ValueFormatterParams) {
        return this.datePipe.transform(
            this._dateTimeService.toUtcDate(params.value).toLocal().toString(),
            AppConsts.dateTimeFormat
        );
    }

    addPersonnalReferences() {
        this.personnalRefencesAddModal.show();
    }

    updatePersonnalReference = async (element: PersonnalReferencesOutput) => {
        this.selectedPersonalReference = element;
        this.bsModalRef = this.modalService.show(
            PersonnalReferencesUpdateComponent,
            {
                initialState: {
                    selectedPersonalReference: this.selectedPersonalReference,
                },
                backdrop: 'static',
            }
        );
        this.bsModalRef.content.modalSave.subscribe((result) => {
            this.refresh();
        });
    };

    deletePersonnalReference = async (element: PersonnalReferencesOutput) => {
        this.service
            .deletePersonnalReferences(element.publicId)
            .subscribe((response) => {
                const refPers = response.result;
                //this.requestDetailsComponent.ngOnInit();
                this.initializeRowData();
                this.modalRefresh.emit(null);
                if (refPers.id != null) {
                    this.showSuccess();
                } else {
                    this.showErrorMessage();
                }
            });
    };

    showSuccess() {
        const title = this._appLocalizationService.l('PersonnalReferenceDelete');
        const successMessage = this._appLocalizationService.l(
            'PersonnalReferenceDeleted'
        );
        this.toastr.success(successMessage, title);
    }

    showErrorMessage() {
        const title = this._appLocalizationService.l(
            'PersonnalReferenceDeleteIssue'
        );
        const errorMessage = this._appLocalizationService.l(
            'PersonnalReferenceDeleteTryAgainLater'
        );
        this.toastr.error(errorMessage, title);
    }

    refresh(event?: LazyLoadEvent) {
        this.initializeRowData();
        this.modalRefresh.emit(null);
    }
}
