import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerificationDescriptionUpdateComponent } from "./verification-description-update.component";
import { UtilsModule } from '@shared/utils/utils.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ModalModule } from 'ngx-bootstrap/modal';


@NgModule({
    imports: [
        UtilsModule,
        CKEditorModule,
        FormsModule, 
        ReactiveFormsModule,
        CommonModule,
        ModalModule
    ],
    declarations: [
        VerificationDescriptionUpdateComponent,
    ],
    providers: []
})

export class VerificationDescriptionUpdateModule {}