import { ValidatorFn, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export const socialInsuranceNumberPatternValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    if (control.value) {
        const result = control.value.match('^[0-9]*$');
        if (result === null) {
            return { BadSocialInsuranceNumber: true };
        } else {
            return null;
        }
    }
};
