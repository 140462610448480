import { Component, OnInit, Injector, ViewChild, HostListener } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { VerificationOutput } from '@shared/models/verification/verificationOutput';
import { AppComponentBase } from '@shared/common/app-component-base';
import { VerificationService } from '@shared/services/verification.service';
import { ToastrService } from 'ngx-toastr';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { Router, ActivatedRoute } from '@angular/router';
import { VerificationInput } from '@shared/models/verification/verificationInput';
import { VerificationFileDTO } from '@shared/models/verification/verificationFileDTO';
import { VerificationDescriptionInput } from '@shared/models/verificationDescription/verificationDescriptionInput';
import { VerificationDescriptionUpdateComponent } from '@app/tutorials/verifications/management/update/verification-description-update.component';
import { verificationStatus } from '@shared/models/verification/verificationStatus';
import { FilesOutput } from '@shared/models/files/filesOutput';
import { GridApi, ColDef } from '@ag-grid-enterprise/all-modules';
import { GridBtnDeleteComponent } from '@app/shared/grid/grid-btn-delete/grid-btn-delete.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FilesSelectionModalComponent } from '@app/shared/files/files-selection-modal/files-selection-modal.component';
import { ConfirmDialogComponent } from '@app/shared/dialog/confirm-dialog/confirm-dialog.component';
import { MenuItem } from 'primeng/api';
import { GridCheckboxIsMandatoryComponent } from '@app/shared/grid/grid-checkbox-is-mandatory/grid-checkbox-is-mandatory.component';
import { skip } from 'rxjs/operators';

@Component({
    selector: 'verification-update',
    templateUrl: './verification-update.component.html',
    animations: [appModuleAnimation()]
})
export class VerificationUpdateComponent extends AppComponentBase implements OnInit {
    @ViewChild('verificationDescription') verificationDescription: VerificationDescriptionUpdateComponent;
    @ViewChild('checkbox') isMandatory: GridCheckboxIsMandatoryComponent;

    publicId: string;
    result: VerificationOutput;
    descriptionsList = [];
    verificationDescriptionInput: VerificationDescriptionInput;
    verificationStatus: verificationStatus;
    isVerificationUpdated = false;
    public files: FilesOutput[];
    public filesDto: VerificationFileDTO[];
    updateVerificationForm = new UntypedFormGroup({
        code: new UntypedFormControl({ value: '', disabled: true }),
        type: new UntypedFormControl('', Validators.required),
        price: new UntypedFormControl('', Validators.required),
        status: new UntypedFormControl('', Validators.required),
        canBeExpress: new UntypedFormControl(false),
        expressPrice: new UntypedFormControl(0)
    });

    public columnDefs: ColDef[];

    paginationNumberFormatter;
    paginationPageSize = 20;

    items: MenuItem[];
    private modalRef: BsModalRef;
    private gridApi: GridApi;

    constructor(
        injector: Injector,
        private service: VerificationService,
        private toastr: ToastrService,
        private modalService: BsModalService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _appLocalizationService: AppLocalizationService
    ) {
        super(injector);
    
        this.columnDefs = [
            {
                headerName: this.l('Name'),
                field: 'filesName'
            },
            {
                headerName: this.l('isFileMandatory'),
                field: 'isMandatory',
                cellRendererFramework: GridCheckboxIsMandatoryComponent,
                cellRendererParams: { action: (data) => this.update(data) }
            },
            {
                headerName: '',
                resizable: false,
                filter: false,
                editable: false,
                suppressMenu: true,
                cellClass: 'text-end',
                cellRendererFramework: GridBtnDeleteComponent,
                cellRendererParams: { action: (data) => this.deleteFile(data) }
            }
        ];

        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };

        if (this._route.snapshot.paramMap.get('publicId') !== '') {
            this.publicId = this._route.snapshot.paramMap.get('publicId');
            this.items = [
                {
                    label: this._appLocalizationService.l('Verifications'),
                    routerLink: ['/verifications']
                },
                {
                    label: this._appLocalizationService.l('VerificationDetails'),
                    routerLink: [`/verifications-details/${this.publicId}`]
                },
                { label: this._appLocalizationService.l('VerificationUpdate') }
            ];
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event): void {
        if (this.gridApi) {
            this.gridApi.sizeColumnsToFit();
        }
    }

    update(data: any): void {
        this.service.updateMandatory(this.result.id, data.id, data.isMandatory).subscribe(() => {});
    }

    onPageSizeChanged(e): void {
        this.gridApi.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    isFormInvalid(): boolean {
        return this.updateVerificationForm.invalid;
    }

    markAllControlsAsTouch(): void {
        this.updateVerificationForm.markAsTouched();
    }

    showForNotValidMessage(): void {
        const title = this.l('VerifyAllControlsTitle');
        const message = this.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }

    showSuccess(title: string, message: string): void {
        const updatedTitle = this.l(title);
        const updatedMessage = this.l(message);
        this.toastr.success(updatedMessage, updatedTitle);
    }

    showErrorMessage(title: string, message: string): void {
        const updatedTitle = this.l(title);
        const updatedMessage = this.l(message);
        this.toastr.error(updatedMessage, updatedTitle);
    }

    async ngOnInit(): Promise<void> {
        this._route.snapshot.paramMap.get('publicId');

        if (this._route.snapshot.paramMap.get('publicId') !== '') {
            this.publicId = this._route.snapshot.paramMap.get('publicId');

            await this.service
                .getVerificationByPublicId(this.publicId)
                .toPromise()
                .then(({ result }) => {
                    this.result = result;
                    this.updateVerificationForm.patchValue({
                        ...this.result,
                        canBeExpress: !!result.expressPrice
                    });
                    this.descriptionsList = this.result.verificationDescriptionOutputs;

                    const tempFiles = this.result.files;
                    for (const file of tempFiles) {
                        file.isMandatory = this.result.verificationFiles.find((verifFile) => verifFile.filesId === file.id)?.isMandatory;
                    }

                    this.files = tempFiles;
                });
            this.checkExpressServiceHandler();
        }
    }

    returnToDetails(): void {
        this._router.navigate(['/verifications-details', this.publicId]);
    }

    onDelete(): void {
        const verification: VerificationInput = {
            id: this.result.id,
            publicId: this._route.snapshot.paramMap.get('publicId'),
            code: this.result.code,
            price: this.result.price,
            type: this.result.type,
            status: this.result.status
        };

        this.message.confirm(this.l('VerificationDeleteWarningMessage'), this.l('AreYouSure'), (isConfirmed) => {
            if (isConfirmed) {
                try {
                    this.service.deleteVerification(verification).subscribe(() => {
                        this.showSuccess('VerificationDeleteTitle', 'VerificationDeleted');
                        this.returnToDetails();
                    });
                } catch (e) {
                    this.showErrorMessage('VerificationDeleteIssue', 'VerificationDeleteTryAgainLater');
                }
            }
        }, {
            confirmButtonText: this.l('Yes'),
            cancelButtonText: this.l('No')        
        });
    }

    async onUpdate(): Promise<void> {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }

        const verification: VerificationInput = {
            id: this.result.id,
            publicId: this._route.snapshot.paramMap.get('publicId'),
            code: this.updateVerificationForm.controls['code'].value,
            price: this.updateVerificationForm.controls['price'].value,
            type: this.updateVerificationForm.controls['type'].value,
            canBeExpress: this.updateVerificationForm.controls['canBeExpress'].value,
            expressPrice: !this.updateVerificationForm.controls['canBeExpress'].value ? 0 : this.updateVerificationForm.controls['expressPrice'].value,
            status: this.updateVerificationForm.controls['status'].value
        };
        this.isVerificationUpdated = true;
        await this.service
            .updateVerification(verification)
            .toPromise()
            .then((response) => {
                const verification = response.result.result;
                if (verification.id !== null) {
                    this.showSuccess('VerificationUpdateTitle', 'VerificationUpdated');
                    this.returnToDetails();
                } else {
                    this.isVerificationUpdated = false;
                    this.showErrorMessage('VerificationUpdateIssue', 'VerificationUpdateTryAgainLater');
                }
                return verification;
            });
    }

    // Region file region

    onGridReady(params): void {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    addFile = (): void => {
        this.modalRef = this.modalService.show(FilesSelectionModalComponent, {
            class: 'modal-dialog-centered',
            backdrop: 'static'
        });
        (this.modalRef.content as FilesSelectionModalComponent).onSave.subscribe((files: FilesOutput[]) => {
            let newFiles = [];

            if (this.files.length > 0) {
                newFiles = files.filter((x) => this.files.find((y) => y.publicId === x.publicId) === undefined);
            } else {
                newFiles = files;
            }

            this.service
                .addFiles(
                    this.result.publicId,
                    newFiles.map((x) => x.publicId)
                )
                .subscribe(
                    () => {
                        this.files = this.files.concat(newFiles);
                        this.gridApi.updateRowData({ add: newFiles });
                        this.modalRef.hide();
                    },
                    () => {
                        this.modalRef.hide();
                    }
                );
        });
        (this.modalRef.content as FilesSelectionModalComponent).onClose.subscribe(() => {
            this.modalRef.hide();
        });
    };

    deleteFile = (file: FilesOutput) => {
        const fileName = file.filesName;

        const initialState = {
            message: this.l('FileDeleteQuestion', [fileName]),
            positiveBtnLabel: this.l('Yes'),
            negativeBtnLabel: this.l('No')
        };

        this.modalRef = this.modalService.show(ConfirmDialogComponent, {
            initialState,
            class: 'modal-sm modal-dialog-centered',
            backdrop: 'static'
        });
        (this.modalRef.content as ConfirmDialogComponent).positiveBtnAction.subscribe(() => {
            this.service.deleteFile(this.result.publicId, file.publicId, 'verificationFile').subscribe(
                () => {
                    const fileIndex = this.files.findIndex((x) => x.publicId === file.publicId);

                    if (fileIndex > -1) {
                        this.gridApi.updateRowData({
                            remove: [this.files[fileIndex]]
                        });
                        this.files.splice(fileIndex, 1);
                    }

                    this.modalRef.hide();
                },
                () => {
                    const title = this.l('FileDeleteTitle');
                    const message = this.l('FileDeleteIssue');
                    this.toastr.error(message, title);

                    this.modalRef.hide();
                }
            );
        });
        (this.modalRef.content as ConfirmDialogComponent).negativeBtnAction.subscribe(() => {
            this.modalRef.hide();
        });
    };

    getRowNodeId = (data: any): string => data.publicId;

    public checkExpressServiceHandler = () => {
        this.updateVerificationForm
            .get('canBeExpress')
            .valueChanges.pipe(skip(1))
            .subscribe((newValue: boolean) => {
                if (!newValue) {
                    this.updateVerificationForm.get('expressPrice').setValue(0);
                }
            });
    };
}
