import { AfterViewInit, Component, EventEmitter, Injector, Input, OnInit } from '@angular/core';
import { RequestVerificationOutput } from '@shared/models/requestVerification/requestVerificationOutput';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { AppComponentBase } from '@shared/common/app-component-base';
import { RequestVerificationStatus } from '@shared/models/requestVerification/requestVerificationStatus';
import { country } from '@shared/models/shared/country';
import { verificationsCodes } from '@shared/utils/verificationsEnumAndFunctions';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CountryService } from '@shared/services/country.service';
import { InvoiceStatusEnum } from '@shared/models/InvoiceElements/invoice/invoiceStatus';
import { resultsCodes, resultsForVerificationsId } from '@shared/models/requestVerification/requestVerificationResults';
import { ReportLocalizationService } from '@shared/services/report-localization.service';
import { RequestOutput } from '@shared/models/request/requestOutput';
import { of } from 'rxjs';
import { NgxSpinnerTextService } from '@app/shared/ngx-spinner-text.service';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { cloneDeep } from 'lodash-es';

@Component({
    selector: 'app-request-verification-update',
    templateUrl: './request-verification-update.component.html',
    styleUrls: ['./request-verification-update.component.scss']
})
export class RequestVerificationUpdateComponent extends AppComponentBase implements OnInit, AfterViewInit {
    @Input() request: RequestOutput;
    @Input() requestVerification: RequestVerificationOutput;
    @Input() invoiceStatus: InvoiceStatusEnum;
    @Input() isReadOnly: boolean;
    @Input() isAgentPpo: boolean;
    onSave: EventEmitter<RequestVerificationOutput> = new EventEmitter();
    status: string;
    result: string;
    requestVerificationList = [];
    isDJI = false;
    isADD = false;
    countryList: country[] = [];
    noResultCountry = false;
    selectedCountry: country;
    requestPublicId: string;
    isQuantityReadOnly = true;
    isExpressReadOnly = true;
    isCountryReadOnly = true;
    isCustomPriceReadOnly = true;
    protected editedRequestVerification: RequestVerificationOutput;

    //request verification resultats
    resultsOptions = [];
    detailedResult: string;

    formCountry = new UntypedFormGroup({
        country: new UntypedFormControl('')
    });

    formGroup = new UntypedFormGroup({
        code: new UntypedFormControl(''),
        status: new UntypedFormControl(''),
        quantity: new UntypedFormControl(''),
        note: new UntypedFormControl(''),
        result: new UntypedFormControl(''),
        detailedResult: new UntypedFormControl(''),
        customFieldADD: new UntypedFormControl(''),
        customPrice: new UntypedFormControl(''),
        isExpress: new UntypedFormControl(''),
    });
    loading: boolean;

    constructor(
        injector: Injector,
        public bsModalRef: BsModalRef,
        private _appReportLocalizationService: ReportLocalizationService,
        private countryService: CountryService,
        public ngxSpinnerText: NgxSpinnerTextService,
        private _appAuthService: AppAuthService
    ) {
        super(injector);
    }

    ngAfterViewInit(): void {
        this.setDropdownList();
        this.setResultProperties();
        this.setDetailedResultProperties();
    }

    setDropdownList() {
        let statusOptionsPPO: number[] = [
            RequestVerificationStatus.InProgress,
            RequestVerificationStatus.MissingInformations,
            RequestVerificationStatus.Completed
        ];
        this.requestVerificationList = Object.keys(RequestVerificationStatus)
            .filter((value) => isNaN(Number(value)) === true)
            .map((key) => ({
                value: RequestVerificationStatus[key],
                key: this.l(key)
            })).filter((el) => !this.isAgentPpo || statusOptionsPPO.includes(el.value));

        of(resultsForVerificationsId.getResultsOptions(this.editedRequestVerification.verification.code)).subscribe((resultsOptions) => {
            this.resultsOptions = resultsOptions.map((key) => ({
                value: this.getResultValue(key),
                key: key
            }));
        });
    }

    async ngOnInit() {
        this.editedRequestVerification = cloneDeep(this.requestVerification);

        if (this.isReadOnly) {
            this.formGroup.disable();
            this.formCountry.disable();
        }

        if (this.isReadOnly) {
            this.status = this.l(RequestVerificationStatus[this.editedRequestVerification.status]);
            this.result = this.l(resultsForVerificationsId.getSelectedResultValue(this.editedRequestVerification.result));
        }

        this.detailedResult = this.getDefaultDetailedResult();
        if (this.editedRequestVerification.result != null && this.editedRequestVerification.detailedResult === '') {
            this.editedRequestVerification.detailedResult = this.detailedResult;
        }

        if (this.editedRequestVerification.verification.code === verificationsCodes.DJI) {
            this.isDJI = true;
            this.selectedCountry = this.editedRequestVerification.country;
            this.selectedCountry.id = this.editedRequestVerification.countryId;

            if (!this.isReadOnly) {
                this.loading = true;
                await this.initializeCountryList();
                this.loading = false;
            }
        }

        if (this.editedRequestVerification.verification.code === verificationsCodes.ADD) {
            this.isADD = true;
        }

        if (this.invoiceStatus === InvoiceStatusEnum.InvoiceSent) {
            this.isQuantityReadOnly = true;
            this.isCountryReadOnly = true;
            this.isCustomPriceReadOnly = true;
            this.isExpressReadOnly = true;
        } else {
            this.isQuantityReadOnly = false;
            this.isCountryReadOnly = false;
            this.isCustomPriceReadOnly = false;
            this.isExpressReadOnly = false;
        }

        this.formGroup.controls['code'].setValue(this.editedRequestVerification.verification.code);
        this.formGroup.controls['status'].setValue(this.editedRequestVerification.status);
        this.formGroup.controls['quantity'].setValue(this.editedRequestVerification.quantity);
        this.formGroup.controls['isExpress'].setValue(this.editedRequestVerification.isExpress);

        const verificationNotes = this.editedRequestVerification.requestVerificationNotes.filter((x) => x.verificationStatus === this.editedRequestVerification.status);
        if (verificationNotes.length === 1) {
            this.formGroup.controls['note'].setValue(verificationNotes[0].note);
        }

        this.formGroup.controls['result'].setValue(this.editedRequestVerification.result);
        this.formGroup.controls['detailedResult'].setValue(this.editedRequestVerification.detailedResult);

        this.formGroup.controls['customFieldADD'].setValue(this.editedRequestVerification.verificationCustomField);
        this.formCountry.controls['country'].setValue(this.editedRequestVerification.country.countryName);
        this.formGroup.controls['customPrice'].setValue(this.editedRequestVerification.verificationCustomPrice);

        if (this.isAgentPpo) {
            this.formGroup.controls['code'].disable();
        }
    }

    getResultValue(key: number) {
        const result = resultsForVerificationsId.getSelectedResultValue(key);
        return this._appReportLocalizationService.getTraduction(result, this.request.reportLanguage);
    }

    setResultProperties() {
        if (this.isResultsRequired()) {
            this.formGroup.controls['result'].setValidators(Validators.required);
        } else {
            this.formGroup.controls['result'].clearValidators();
        }

        if (this.isResultsEditable()) {
            this.formGroup.controls['result'].enable();
        } else {
            this.formGroup.controls['result'].disable();
        }
        this.formGroup.updateValueAndValidity();
    }

    getDefaultDetailedResult() {
        const keyDetailedResult = this.editedRequestVerification.result?.toString() + this.editedRequestVerification.verification.code;

        const detailed = resultsForVerificationsId.getDetailedResult(keyDetailedResult);

        this.detailedResult = this._appReportLocalizationService.getTraduction(detailed, this.request.reportLanguage);

        return this.detailedResult;
    }

    setDetailedResultProperties() {
        const detailedResultsNeedValidator = this._appAuthService.hasPermission('Pages.Management.Requests.CanSeePrivateInformation')
            && this.editedRequestVerification.status === RequestVerificationStatus.Completed;

        if (detailedResultsNeedValidator) {
            // We created the function here to pass the variable requestVerificationStatus.
            this.formGroup.controls['detailedResult'].setValidators((control: AbstractControl) => this.detailedResultsValidator(control, this.editedRequestVerification));
        } else {
            this.formGroup.controls['detailedResult'].clearValidators();
        }

        if (this.isResultsEditable()) {
            this.formGroup.controls['detailedResult'].enable();
        } else {
            this.formGroup.controls['detailedResult'].disable();
        }
        this.formGroup.updateValueAndValidity();
    }

    getDetailedResult() {
        this.editedRequestVerification.result = this.formGroup.controls['result'].value;
        this.detailedResult = this.getDefaultDetailedResult();

        this.editedRequestVerification.detailedResult = this.detailedResult;
        this.formGroup.controls['detailedResult'].setValue(this.editedRequestVerification.detailedResult);

        this.setDetailedResultProperties();
    }

    updateNote() {
        const status = this.formGroup.controls['status'].value;
        const note = this.formGroup.controls['note'].value;

        if (note != null) {
            const verificationNotes = this.editedRequestVerification.requestVerificationNotes.filter((x) => x.verificationStatus === this.editedRequestVerification.status);
            if (verificationNotes.length === 1) {
                verificationNotes[0].note = note;
            } else {
                this.editedRequestVerification.requestVerificationNotes.push({
                    id: 0,
                    verificationStatus: status,
                    note: note,
                    creationDate: null,
                    lastModificationTime: null,
                    isPrivateNote: null,
                    lastModifierUserId: null,
                    creationUserId: null
                });
            }
        }
    }

    public updateExpress = () => {
        this.editedRequestVerification.isExpress = this.formGroup.controls['isExpress'].value;
    };

    updateStatus() {
        this.editedRequestVerification.status = this.formGroup.controls['status'].value;
        const verificationNotes = this.editedRequestVerification.requestVerificationNotes.filter((x) => x.verificationStatus === this.editedRequestVerification.status);
        if (verificationNotes.length === 1) {
            this.formGroup.controls['note'].setValue(verificationNotes[0].note);
        } else {
            this.formGroup.controls['note'].setValue(null);
        }

        this.setResultProperties();
        this.setDetailedResultProperties();
    }

    isResultsVisible() {
        return (
            (this._appAuthService.hasPermission('Pages.Management.Requests.CanSeePrivateInformation') || this.isAgentPpo) &&
            (this.editedRequestVerification.status === RequestVerificationStatus.InProgress || this.editedRequestVerification.status === RequestVerificationStatus.Completed)
        );
    }

    isResultsRequired() {
        return (this._appAuthService.hasPermission('Pages.Management.Requests.CanSeePrivateInformation') || this.isAgentPpo) && this.editedRequestVerification.status === RequestVerificationStatus.Completed;
    }

    isDetailedResultsRequired() {
        return (
            this._appAuthService.hasPermission('Pages.Management.Requests.CanSeePrivateInformation') &&
            this.editedRequestVerification.status === RequestVerificationStatus.Completed &&
            this.editedRequestVerification.result !== resultsCodes.NotCompleted
        );
    }

    isResultsEditable() {
        return this._appAuthService.hasPermission('Pages.Management.Requests.Update') && !this.isReadOnly;
    }

    async initializeCountryList() {
        await this.countryService
            .getCountryList()
            .toPromise()
            .then((value) => {
                this.countryList = value.result as country[];
            });
    }

    typeaheadOnBlur(event: any): void {
        this.selectedCountry = event.item;
        this.formCountry.controls['country'].setValue(this.selectedCountry.countryName);
    }

    onSelect(event: TypeaheadMatch): void {
        this.selectedCountry = event.item as country;

        if (this.selectedCountry !== null && this.selectedCountry !== undefined) {
            this.editedRequestVerification.countryId = this.selectedCountry.id;
            this.editedRequestVerification.country = this.selectedCountry;
        } else {
            this.editedRequestVerification.countryId = 0;
        }
    }

    typeaheadNoResults(event: boolean): void {
        this.noResultCountry = event;
    }

    close() {
        this.onSave.emit(this.editedRequestVerification);
        this.bsModalRef.hide();
    }

    save() {
        if (this.isReadOnly) {
            return;
        }
        if (this.formGroup.controls['detailedResult'].value === this.detailedResult) {
            this.editedRequestVerification.detailedResult = '';
        } else {
            this.editedRequestVerification.detailedResult = this.formGroup.controls['detailedResult'].value;
        }
        this.editedRequestVerification.quantity = this.formGroup.controls['quantity'].value;
        this.editedRequestVerification.verificationCustomField = this.formGroup.controls['customFieldADD'].value;
        this.editedRequestVerification.verificationCustomPrice = this.formGroup.controls['customPrice'].value;

        this.close();
    }

    private detailedResultsValidator(control: AbstractControl, rv: RequestVerificationOutput): { [key: string]: any } | null {
        let valid: boolean;
        if (rv.result !== resultsCodes.NotCompleted) {
            valid = control.value !== null && control.value !== '' && !control.value.includes(' COMPLETE') && !control.value.includes('CHAMP LIBRE');
        } else {
            valid = control.value === null || control.value === '' || (!control.value.includes(' COMPLETE') && !control.value.includes('CHAMP LIBRE'));
        }        
        return valid ? null : { invalidResult: { valid: false, value: control.value } };
    }
}
