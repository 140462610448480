<div [@routerTransition] *ngIf="!success; else thankYouBlock" class="p-5">
    <div class="content d-flex flex-column flex-column-fluid">
        <h2 class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
            {{"WelcomeB2bCreationForm" | localize}}
        </h2>
        <p>
            {{"DescB2bCreationForm" | localize}}
        </p>
    </div>
    <div class="pb-5 pt-lg-0 pt-5">
        <h5 class="font-weight-bolder text-dark font-size-h5">
            {{"TitleB2bCreationForm" | localize}}
        </h5>
    </div>
    <form class="form" method="post" [formGroup]="formGroup">
        <div class="b2b-container mb-5">
            <div class="b2b-container-item">
                <label for="organizationName">{{"OrganizationName" | localize}} <span class="required-red">*</span></label>
                <input type="text" id="organizationName" class="form-control" formControlName="organizationName" />
                <validation-messages [formCtrl]="formGroup.get('organizationName')">
                </validation-messages>
            </div>
            <div class="b2b-container-item">
                <label for="howYouHear">{{"HearAboutGardiumLabel" | localize}} <span class="required-red">*</span></label>
                <select class="form-select" formControlName="howYouHear" id="howYouHear">
                    <option *ngFor="let answer of provenanceList" [value]="answer.key">
                        {{ answer.value }}
                    </option>
                </select>
                <div *ngIf="isAnswerOther">
                    <label for="howYouHearInput">{{"OtherAnswer" | localize}} <span class="required-red">*</span></label>
                    <input type="text" id="howYouHearInput" class="form-control" formControlName="otherAnswer" />
                    <validation-messages [formCtrl]="formGroup.get('otherAnswer')">
                    </validation-messages>
                </div>
            </div>
        </div>
        <h5 class="font-weight-bolder text-dark font-size-h5">
            {{"FirstContact" | localize}}
        </h5>
        <div class="mb-5">
            <div class="b2b-container">
                <div class="b2b-container-item">
                    <label for="firstName">{{"PrincipalContactFirstName" | localize}} <span class="required-red">*</span></label>
                    <input type="text" id="firstName" class="form-control" formControlName="firstName" />
                    <validation-messages [formCtrl]="formGroup.get('firstName')">
                    </validation-messages>
                </div>
                <div class="b2b-container-item">
                    <label for="lastName">{{"PrincipalContactLastName" | localize}} <span class="required-red">*</span></label>
                    <input type="text" id="lastName" class="form-control" formControlName="lastName" />
                    <validation-messages [formCtrl]="formGroup.get('lastName')">
                    </validation-messages>
                </div>
            </div>
            <div class="b2b-container">
                <div class="b2b-container-item">
                    <label for="firstContactEmail">{{"FirstContactEmail" | localize}} <span class="required-red">*</span></label>
                    <input type="text" id="firstContactEmail" class="form-control"
                        formControlName="firstContactEmail" />
                    <validation-messages [formCtrl]="formGroup.get('firstContactEmail')">
                    </validation-messages>
                </div>
                <div class="b2b-container-item" id="phone-div">
                    <label for="phoneNumber">{{"PhoneNumber" | localize }} <span class="required-red">*</span></label>
                    <input id="phoneNumber" formControlName="phoneNumber" type="text" name="phoneNumber"
                        Class="form-control" maxlength="256" placeholder="+1(999) 999-9999" mask="(000) 000-0000"
                        prefix="+ 1 " [dropSpecialCharacters]="false"
                        [specialCharacters]="[ '+', '1', '(' , ')' , ' ', '-' ]" [showMaskTyped]="true"
                        data-test-id="org-create-phone" />
                    <validation-messages [formCtrl]="formGroup.get('phoneNumber')">
                    </validation-messages>
                </div>
                <div class="b2b-container-item" id="ext-div">
                    <label for="phoneExtension">{{"PhoneNumberExtension" | localize}}</label>
                    <input type="number" min="0" id="phoneExtension" class="form-control"
                        formControlName="phoneExtension" />
                    <validation-messages [formCtrl]="formGroup.get('phoneExtension')">
                    </validation-messages>
                </div>
            </div>
            <div class="b2b-container">
                <div class="b2b-container-item">
                    <label for="jobTitle">{{"JobTitleLabel" | localize}} <span class="required-red">*</span></label>
                    <input type="text" class="form-control" formControlName="jobTitle"  id="jobTitle"/>
                    <validation-messages [formCtrl]="formGroup.get('jobTitle')">
                    </validation-messages>
                </div>
                <div class="b2b-container-item">
                    <label for="language">{{"Language" | localize}}<span class="required-red">*</span></label>
                    <select name="language" class="form-select" id="language" formControlName="language"
                        data-test-id="org-create-language-select">
                        <option value="">{{"Select" | localize}}</option>
                        <option value="english">{{"English" | localize}}</option>
                        <option value="french">{{"French" | localize}}</option>
                    </select>
                </div>
            </div>
        </div>
        <h5 class="font-weight-bolder text-dark font-size-h5">
            {{"TitleB2bOrgInfoSection" | localize}}
        </h5>
        <div class="mb-5">
            <div class="b2b-container">
                <div class="b2b-container-item" id="civic-div">
                    <label for="civicNo">{{"CivicNo" | localize}} <span class="required-red">*</span></label>
                    <input type="number" min="0" id="civicNo" class="form-control" formControlName="civicNo" />
                    <validation-messages [formCtrl]="formGroup.get('civicNo')">
                    </validation-messages>
                </div>
                <div class="b2b-container-item" id="apt-div">
                    <label for="apartment">{{"Apartment" | localize}}</label>
                    <input type="text" id="apartment" class="form-control" formControlName="apartment" />
                    <validation-messages [formCtrl]="formGroup.get('apartment')">
                    </validation-messages>
                </div>
                <div class="b2b-container-item">
                    <label for="street">{{"Street" | localize}} <span class="required-red">*</span></label>
                    <input type="text" id="street" class="form-control" formControlName="street" />
                    <validation-messages [formCtrl]="formGroup.get('street')"></validation-messages>
                </div>
            </div>
            <div class="b2b-container">
                <div class="b2b-container-item">
                    <label for="state">{{"Province" | localize}} <span class="required-red">*</span></label>
                    <select class="form-select" formControlName="state" id="state" (ngModelChange)="onSelectState($event)">
                        <option *ngFor="let state of statesList" [ngValue]="state">
                            {{ state.stateName }}
                        </option>
                    </select>
                </div>
                <div class="b2b-container-item" id="postal-div">
                    <label for="postalCode">{{"PostalCode" | localize}} <span class="required-red">*</span></label>
                    <input id="postalCode" formControlName="postalCode" type="text" autocomplete="off"
                        name="postalCode" class="form-control" oninput="this.value = this.value.toUpperCase()"
                        [mask]="masks.canadaPostalCode" data-test-id="org-create-postalCode" />
                    <validation-messages *ngIf="formGroup.get('postalCode')!=undefined"
                        [formCtrl]="formGroup.get('postalCode')"></validation-messages>
                </div>
                <div class="b2b-container-item" id="city-div">
                    <label for="city">{{"City" | localize}} <span class="required-red">*</span></label>
                    <input type="text" id="city" class="form-control" formControlName="city" />
                    <validation-messages [formCtrl]="formGroup.get('city')"></validation-messages>
                </div>
            </div>
        </div>
        <div class="b2b-container mb-5">
            <div id="verifications-div">
                <h5 class="font-weight-bolder text-dark font-size-h5">
                    {{"ListVerification" | localize}}
                </h5>
                <div>
                    <p>{{'VerificationInformation1' | localize}}
                        <br />
                        {{'VerificationInformation2' | localize}}
                    </p>
                </div>
                <div *ngIf="verifications?.length > 0">
                    <div class="row" *ngFor="let tag of verifications; let i = index;">
                        <div class="col-md-12 pl-3" style="padding-left: 3%;">
                            <input class="form-check-input" type="checkbox" id="tag.id" name="tagOptions"
                                (change)="changeCheckbox(tag)" [checked]="tag.checked"
                                data-test-id="verification-check">
                            {{tag.code}} - {{tag.type}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-5">
            <h5 class="font-weight-bolder text-dark font-size-h5">
                {{"CreditCardInformation" | localize}}
            </h5>
            <div>
                <p>
                    {{"NoteInformationPaiement1" | localize}}
                    <br />
                    <strong>{{"NoteInformationPaiement2" | localize}}</strong>
                </p>
            </div>
            <div class="b2b-container-item">
                <label for="creditCardNumber">{{"CreditCardNumber" | localize}} <span class="asterix">*</span></label>
                <input id="creditCardNumber" formControlName="creditCardNumber" type="text" name="creditCardNumber"
                    class="form-control" maxlength="16">
                <validation-messages [formCtrl]="formGroup.get('creditCardNumber')">
                </validation-messages>
            </div>
            <div class="b2b-container-item b2b-div">
                <label for="expdate">{{"Expdate" | localize}} <span class="asterix">*</span>&nbsp; <i
                        class="fas fa-info-circle" data-toggle="tooltip" title="{{'TooltipExpDate' | localize}}"
                        id='tooltipInfo'></i></label>
                <input id="expdate" formControlName="expdate" type="text" name="expdate" class="form-control"
                    maxlength="4" placeholder="{{'PlaceholderExpDate' | localize}}">
                <validation-messages [formCtrl]="formGroup.get('expdate')">
                </validation-messages>
            </div>
            <div>
                <span class="asterix">*</span> {{"ThisFieldIsRequired" | localize}}
                <br />
                <br />
                <span>
                    {{"Note" | localize}}
                </span>
                <br />
                <span>
                    {{"NoFeeds" | localize}}
                </span>
            </div>
        </div>
        <div class="mb-5">
            <label class="form-check-label">
                <input class="form-check-input" type="checkbox" formControlName="authorizeData" />
                {{"DataAuthorizeLabel" | localize}}
            </label>
        </div>
        <div class="pb-lg-0 pb-5">
            <button type="submit" class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 me-3"
                [disabled]="this.isInvalid()" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"
                (click)="save()">
                {{ 'Save' | localize }}
            </button>
        </div>
    </form>
</div>
<ng-template #thankYouBlock>
    <div class="content" id="thankYou-div">
        <p>
            <img alt src="/assets/common/images/check.png" width="5%" />
            {{'OrgInscriptionSucceed' | localize}}
        </p>
        <p>{{'ThankyouForInscription' | localize}}</p>
    </div>
</ng-template>
