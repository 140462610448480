import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Component, OnInit, Injector, Input } from '@angular/core';
import { InvoiceService } from '@shared/services/invoice.service';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { InvoiceOutput } from '@shared/models/InvoiceElements/invoice/invoiceOutput';
import { InvoiceLineCandidatsOutput } from '@shared/models/InvoiceElements/invoice/invoiceLineCandidats/invoiceLineCandidatsOutput';
import { InvoiceLinesOutput } from '@shared/models/InvoiceElements/invoiceLine/invoiceLineOutput';
import { InvoiceTaxesOutput } from '@shared/models/InvoiceElements/invoiceTaxe/invoiceTaxeOutput';
import { InvoiceAdjustmentsOutput } from '@shared/models/InvoiceElements/InvoiceAdjustment/InvoiceAdjustmentOutput';
import { AddressOutput } from '@shared/models/address/addressOutput';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AppConsts } from '@shared/AppConsts';
import * as jsPDF from 'jspdf';
import { InvoiceDisplay } from '@shared/models/InvoiceElements/invoice/invoiceElements/invoiceDisplay';
import { getLocalizedMonthYearDate } from '@shared/helpers/DateHelper';

@Component({
    selector: 'invoice-details-candidats',
    templateUrl: './invoice-details-candidats-component.html',
    styleUrls: ['./invoice-details-candidats-component.scss'],
    animations: [appModuleAnimation()],
})
export class InvoiceDetailsCandidatsComponent extends AppComponentBase implements OnInit {
    @Input() invoiceDisplay: InvoiceDisplay;
    @Input() selectedInvoice: InvoiceOutput;
    @Input() publicId: string;

    isPurchaseOrderAvailable = false;

    constructor(
        private injector: Injector,
        private service: InvoiceService,
        private _appLocalizationService: AppLocalizationService,
        private _route: ActivatedRoute,
        private toastr: ToastrService,
        private _router: Router,
        public bsModalRef: BsModalRef,
        private datePipe: DatePipe
    ) {
        super(injector);
    }

    result: InvoiceOutput;
    resultCandidats: InvoiceLineCandidatsOutput[];
    resultinvoiceLine: InvoiceLinesOutput[];
    resultinvoiceTaxes: InvoiceTaxesOutput[];
    resultinvoiceAdjustments: InvoiceAdjustmentsOutput[];
    resultAdress: AddressOutput;
    thereIsAnState = false;
    date: string;
    today = new Date();
    headElements = [];
    headElementsCandidats = [];
    adjustmentElements = [];
    hasCreditCard = false;
    canUpdateInvoice = true;
    canCharge = true;
    isNotCharged = true;
    isOrganizationdeleted = false;
    isOrganizationinactif = false;
    ispurchaseOrderAvailable = false;
    ifResultinvoiceAdjustments = true;
    AppConsts = AppConsts;
    pageCount = 1;
    pdf: any;
    numberOfPagePDF: number;
    pageHeight = 30;

    formGroup = new UntypedFormGroup({
        organization: new UntypedFormControl({ value: '', disabled: true }),
        civicNo: new UntypedFormControl({ value: '', disabled: true }),
        street: new UntypedFormControl({ value: '', disabled: true }),
        app: new UntypedFormControl({ value: '', disabled: true }),
        city: new UntypedFormControl({ value: '', disabled: true }),
        postalCode: new UntypedFormControl({ value: '', disabled: true }),
        state: new UntypedFormControl({ value: '', disabled: true }),
        country: new UntypedFormControl({ value: '', disabled: true }),
        contactName: new UntypedFormControl({ value: '', disabled: true }),
        invoiceEmail: new UntypedFormControl({ value: '', disabled: true }),
        invoiceNumber: new UntypedFormControl({ value: '', disabled: true }),
        purchaseOrder: new UntypedFormControl({ value: '', disabled: true }),
        clientName: new UntypedFormControl({ value: '', disabled: true }),
        total: new UntypedFormControl({ value: '', disabled: true }),
        subTotal: new UntypedFormControl({ value: '', disabled: true }),
        tps: new UntypedFormControl({ value: '', disabled: true }),
        tvq: new UntypedFormControl({ value: '', disabled: true }),
        description: new UntypedFormControl({ value: '', disabled: true }),
        adjustment: new UntypedFormControl({ value: '', disabled: true }),
    });

    ngOnInit(): void {
        this.initializePrinterInformation();
        this.initializeBooleans();
    }

    initializeBooleans() {
        this.isPurchaseOrderAvailable = this.invoiceDisplay.invoiceHeader.purchaseOrder !== '';
    }

    initializePrinterInformation() {
        this.date = this.dateTimeFormatOnlyDate(this.today);
        this.headElements = [
            this.l('Invoice_CandidatesNames'),
            this.l('Invoice_ExpressRate'),
            this.l('Invoice_Qty'),
            this.l('Invoice_Rate'),
            this.l('Invoice_Total')
        ];
        this.adjustmentElements = [this.l('Invoice_Description'), this.l('Invoice_Adjustment')];
        this.pdf = new jsPDF('p', 'mm', 'a4');
        this.numberOfPagePDF = this.pdf.internal.getNumberOfPages();
    }

    dateTimeFormatOnlyDate(value) {
        return this.datePipe.transform(value, AppConsts.dateTimeFormatOnlyDate);
    }

    dateTimeFormatOnlyDay(value) {
        return this.datePipe.transform(value, AppConsts.dateTimeFormatDay);
    }

    monthYearFormat(invoiceDate: Date): string {
        return getLocalizedMonthYearDate(this, invoiceDate);
    }
}
