<div [@routerTransition]>
  <div class="content d-flex flex-column flex-column-fluid">
    <sub-header [title]="'Candidates' | localize" [description]="'CreateNewCandidat' | localize">
      <div role="actions">
        <button (click)="cancelCreation()" class="btn btn-secondary">
          <i class="fas fa-times"></i>
          {{'Cancel' | localize}}</button>
        <button class="btn btn-primary" (click)="save()" [disabled]="isCandidatCreated" data-test-id="save-btn">
            <i class="la la-floppy-o"></i>
          {{"Save" | localize}}</button>
      </div>
    </sub-header>
    <form [formGroup]="formGroup" autocomplete="off">
      <div [class]="containerClass">
        <div class="card listContainer">
          <div class="card-body">
            <div>
              <label for="organization">{{"Organization" | localize}} <span class="asterix">*</span></label>
              <input id="organization" formControlName="organization" type="text" name="organization"
                class="form-control" maxlength="256" [typeahead]="organizationList"
                typeaheadOptionField="organizationName" [typeaheadScrollable]="true"
                [typeaheadOptionsInScrollableView]="5" autocomplete="off" (typeaheadOnSelect)="onSelect($event)"
                (typeaheadNoResults)="typeaheadNoResults($event)" [typeaheadMinLength]="0"
                [readonly]="cantChangeOrganization" data-test-id="candidat-create-organization-input">
              <div class="alert alert-danger" *ngIf="noResultOrganization">
                {{"ResultNotFoundElementInList" | localize}}</div>
              <validation-messages [formCtrl]="formGroup.get('organization')"></validation-messages>
            </div>
          </div>
        </div>
        <div class="card card-custom">
          <div class="card-body">
            <div class="candidatDetails">
              <h5>{{"CandidatDetails" | localize}}</h5>
              <div class="row">
                <div class="col-md-3">
                  <label for="lastName">{{"LastName" | localize}} <span class="asterix">*</span></label>
                  <input id="lastName" formControlName="lastName" type="text" name="lastName" class="form-control"
                    maxlength="256" appTextTransform="upper" data-test-id="lastName-input">
                  <validation-messages [formCtrl]="formGroup.get('lastName')"></validation-messages>
                </div>
                <div class="col-md-3">
                  <label for="firstName">{{"FirstName" | localize}} <span class="asterix">*</span></label>
                  <input id="firstName" formControlName="firstName" type="text" name="firstName" class="form-control"
                    maxlength="256" appTextTransform="title" data-test-id="firstName-input">
                  <validation-messages [formCtrl]="formGroup.get('firstName')"></validation-messages>
                </div>
                <div class="col-md-3">
                  <label for="birthdate">{{"Birthdate" | localize}} {{'FormatDate' | localize}}<span class="asterix">
                      *</span></label>
                  <input id="birthdate" formControlName="birthdate" type="text" name="birthdate" class="form-control"
                    bsDatepicker [maxDate]="maxDate" data-test-id="birthdate-input"
                    [bsConfig]="{ dateInputFormat: 'YYYY/MM/DD', containerClass: 'theme-red',  adaptivePosition: true }">
                  <validation-messages [formCtrl]="formGroup.get('birthdate')"></validation-messages>
                </div>
                <div class="col-md-3">
                  <label for="sex">{{"Sex" | localize}} <span class="asterix">*</span></label>
                  <select id="sex" formControlName="sex" type="sex" name="sex" class="form-control" data-test-id="sex-select">
                    <option value='0'>{{"Male" | localize}}</option>
                    <option value='1'>{{"Female" | localize}}</option>
                    <option value='2'>{{"Other" | localize}}</option>
                  </select>
                  <validation-messages [formCtrl]="formGroup.get('sex')"></validation-messages>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3">
                  <label for="otherNames">{{"OtherNames" | localize}}</label>
                  <input id="otherNames" formControlName="otherNames" type="text" name="otherNames" class="form-control"
                    maxlength="256">
                  <validation-messages [formCtrl]="formGroup.get('otherNames')"></validation-messages>
                </div>
                <div class="col-md-3">
                  <label for="maidenName">{{"MaidenName" | localize}}</label>
                  <input id="maidenName" formControlName="maidenName" type="text" name="maidenName" class="form-control"
                    maxlength="256">
                  <validation-messages [formCtrl]="formGroup.get('maidenName')"></validation-messages>
                </div>
                <div class="col-md-3">
                  <label for="birthPlace">{{"BirthPlace" | localize}}</label>
                  <input id="birthPlace" formControlName="birthPlace" type="text" name="birthPlace" class="form-control"
                    maxlength="256" value="text">
                  <validation-messages [formCtrl]="formGroup.get('birthPlace')"></validation-messages>
                </div>
                <div class="form-group col-md-3">
                  <label for="language">{{"Language" | localize}} <span class="asterix">*</span></label>
                  <select id="language" formControlName="language" type="language" name="language" class="form-control" data-test-id="sex-select">
                    <option value='0'>{{"French" | localize}}</option>
                    <option value='1'>{{"English" | localize}}</option>
                  </select>
                  <validation-messages [formCtrl]="formGroup.get('language')"></validation-messages>
                </div>
              </div>
              <div class="">
                <label for="email">{{"Email" | localize}}</label>
                <input id="email" formControlName="email" type="text" name="email" class="form-control" maxlength="256"
                data-test-id="email-input">
                <validation-messages [formCtrl]="formGroup.get('email')"></validation-messages>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label for="phoneNumber">{{"PhoneNumber" | localize }}</label>
                  <input id="phoneNumber" formControlName="phoneNumber" type="text" name="phoneNumber"
                    Class="form-control" maxlength="256" placeholder="+1(999) 999-9999" mask="(000) 000-0000"
                    prefix="+ 1 " [dropSpecialCharacters]="false"
                    [specialCharacters]="[ '+', '1', '(' , ')' , ' ', '-' ]" [showMaskTyped]="true" />
                  <validation-messages [formCtrl]="formGroup.get('phoneNumber')">
                  </validation-messages>
                </div>
                <div class="col-md-6">
                  <label for="phoneExtension">{{"PhoneNumberExtension" | localize}}</label>
                  <input id="phoneExtension" formControlName="phoneExtension" type="number" name="phoneExtension"
                    class="form-control" maxlength="256" min="0">
                  <validation-messages [formCtrl]="formGroup.get('phoneExtension')"></validation-messages>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label for="socialInsuranceNumber">{{"SocialInsuranceNumber" | localize}}</label>
                  <input id="socialInsuranceNumber" formControlName="socialInsuranceNumber" type="text"
                    name="socialInsuranceNumber" class="form-control" maxlength="250">
                  <validation-messages [formCtrl]="formGroup.get('socialInsuranceNumber')">
                  </validation-messages>
                </div>
                <div class="col-md-6">
                  <label for="driverLicenceNumber">{{"DriverLicenceNumber" | localize}}</label>
                  <input id="driverLicenceNumber" formControlName="driverLicenceNumber" type="text"
                    name="driverLicenceNumber" class="form-control" maxlength="250">
                  <validation-messages [formCtrl]="formGroup.get('driverLicenceNumber')">
                  </validation-messages>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
