import { Component, ViewChild, Input, Output, EventEmitter, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { CandidatOutput } from '@shared/models/candidat/candidatOutput';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AddressHistoryInput } from '@shared/models/addresshistory/addressHistoryInput';
import { AddressInput } from '@shared/models/address/addressInput';
import { CandidatInput } from '@shared/models/candidat/candidatInput';
import { finalize } from 'rxjs/operators';
import { AddressHistoryService } from '@shared/services/addressHistory.service';

@Component({
    selector: 'CreateAddressHistoryFormerComponent',
    templateUrl: './create-address-history-former.component.html'
})
export class CreateAddressHistoryFormerComponent extends AppComponentBase {
    @ViewChild('createFormerAddressModal') modal: ModalDirective;
    @Input() selectedCandidat: CandidatOutput;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    publicId: string;
    saving = false;
    active = false;
    length: number;

    formGroupAddress = new UntypedFormGroup({
        fromDate: new UntypedFormControl({ value: '', disabled: true }),
        startDateBetween: new UntypedFormControl(''),
        endDateBetween: new UntypedFormControl(''),
        type: new UntypedFormControl('', Validators.required),
        civicNo: new UntypedFormControl('', Validators.required),
        street: new UntypedFormControl('', Validators.required),
        app: new UntypedFormControl(''),
        city: new UntypedFormControl('', Validators.required),
        postalCode: new UntypedFormControl(''),
        country: new UntypedFormControl(''),
        countryName: new UntypedFormControl('', Validators.required),
        state: new UntypedFormControl(''),
        stateName: new UntypedFormControl('', Validators.required)
    });

    constructor(private injector: Injector, private localeService: BsLocaleService, private service: AddressHistoryService) {
        super(injector);
        this.localeService.use(this.localization.currentLanguage.name);
    }

    onShown(): void {
        document.getElementById('startDateBetween').focus();
    }

    show(): void {
        this.active = true;
        this.modal.show();
    }

    markAllControlsAsTouch() {
        this.formGroupAddress.controls['fromDate'].markAsTouched();
        this.formGroupAddress.controls['startDateBetween'].markAsTouched();
        this.formGroupAddress.controls['endDateBetween'].markAsTouched();
        this.formGroupAddress.controls['type'].markAsTouched();
        this.formGroupAddress.controls['civicNo'].markAsTouched();
        this.formGroupAddress.controls['street'].markAsTouched();
        this.formGroupAddress.controls['app'].markAsTouched();
        this.formGroupAddress.controls['city'].markAsTouched();
        this.formGroupAddress.controls['state'].markAsTouched();
        this.formGroupAddress.controls['postalCode'].markAsTouched();
        this.formGroupAddress.controls['country'].markAsTouched();
    }

    isFormInvalid(): boolean {
        return this.formGroupAddress.invalid;
    }

    returnAddressHistory(): AddressHistoryInput {
        const addressHistory: AddressHistoryInput = {
            id: 0,
            publicId: '',
            type: this.formGroupAddress.controls['type'].value,
            from: this.formGroupAddress.controls['startDateBetween'].value,
            to: this.formGroupAddress.controls['endDateBetween'].value,
            addressId: 0,
            address: this.returnAddress(),
            candidat: this.returnCandidat(),
            candidatsId: this.selectedCandidat.id,
            creationTime: new Date()
        };
        return addressHistory;
    }

    returnAddress(): AddressInput {
        const address: AddressInput = {
            id: 0,
            civicNo: this.formGroupAddress.controls['civicNo'].value,
            street: this.formGroupAddress.controls['street'].value,
            app: this.formGroupAddress.controls['app'].value,
            city: this.formGroupAddress.controls['city'].value,
            postalCode: this.formGroupAddress.controls['postalCode'].value,
            state: this.formGroupAddress.controls['state'].value,
            country: this.formGroupAddress.controls['country'].value
        };
        return address;
    }

    returnCandidat(): CandidatInput {
        const candidat: CandidatInput = CandidatInput.fromOutput(this.selectedCandidat);

        return candidat;
    }

    async save(): Promise<void> {
        this.saving = true;
        const addressHistory = this.returnAddressHistory();

        await this.service
            .addAddressHistory(addressHistory)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .toPromise()
            .then(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
            });
    }

    close(): void {
        this.active = false;
        this.formGroupAddress.reset();
        this.modal.hide();
    }
}
