import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { organizationOutput } from '@shared/models/organization/organizationOutput';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AddressOutput } from '@shared/models/address/addressOutput';
import { OrganizationInput } from '@shared/models/organization/organizationInput';
import { OrganizationService } from '@shared/services/organization.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AddressInput } from '@shared/models/address/addressInput';
import { AppComponentBase } from '@shared/common/app-component-base';
import { BillingOutputTypeDTO } from '@shared/models/organization/billingInformation/BillingOutputTypeDTO';
import { BillingPeriodTypeDTO } from '@shared/models/organization/billingInformation/BillingPeriodTypeDTO';
import { AgGridAngular } from '@ag-grid-community/angular';
import { BillingMethodTypeDTO } from '@shared/models/organization/billingInformation/BillingMethodTypeDTO';
import { BillingInformationInput } from '@shared/models/organization/billingInformation/billingInformationInput';
import { OrganizationCreditCardInput } from '@shared/models/payment/organizationCreditCardInput';
import { OrganizationCreditCardOutput } from '@shared/models/payment/organizationCreditCardOutput';
import { organizationStatus } from '@shared/models/organization/organizationStatus';
import { MenuItem } from 'primeng/api';
import { OrganizationB2BService } from '@shared/services/organizationB2B.service';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { OrganizationRequestVolumeRange } from '@shared/models/organization/OrganizationRequestVolumeRange';
import { organizationLineOfBusiness } from '@shared/models/organization/organizationLineOfBusiness';
import { clientProvenance } from '@shared/models/organization/organizationClientProvenance';

@Component({
    selector: 'app-organization-details',
    templateUrl: './organization-details.component.html',
    styleUrls: [ './organization-details.component.scss' ],
    animations: [ appModuleAnimation() ]
})
export class OrganizationDetailsComponent extends AppComponentBase implements OnInit {
    @ViewChild('agGrid') agGrid: AgGridAngular;
    @ViewChild('detailTabs', { static: false }) detailTabs: TabsetComponent;
    viewVerifications = true;
    organizationRequestVolumeRanges = OrganizationRequestVolumeRange;

    addressFormGroup = new UntypedFormGroup({
        civicNo: new UntypedFormControl(''),
        street: new UntypedFormControl(''),
        app: new UntypedFormControl(''),
        city: new UntypedFormControl(''),
        postalCode: new UntypedFormControl(''),
        country: new UntypedFormControl(''),
        countryName: new UntypedFormControl(''),
        state: new UntypedFormControl(''),
        stateName: new UntypedFormControl('')
    });
    billingAddressFormGroup = new UntypedFormGroup({
        civicNo: new UntypedFormControl(''),
        street: new UntypedFormControl(''),
        app: new UntypedFormControl(''),
        city: new UntypedFormControl(''),
        postalCode: new UntypedFormControl(''),
        country: new UntypedFormControl(''),
        countryName: new UntypedFormControl(''),
        state: new UntypedFormControl(''),
        stateName: new UntypedFormControl('')
    });

    formGroup = new UntypedFormGroup({
        organizationName: new UntypedFormControl({ value: '', disabled: true }),
        organizationParentName: new UntypedFormControl({ value: '', disabled: true }),
        status: new UntypedFormControl({ value: '', disabled: true }),
        clientId: new UntypedFormControl({ value: '', disabled: true }),
        idSage: new UntypedFormControl({ value: '', disabled: true }),
        idAccountantCAR: new UntypedFormControl({ value: '', disabled: true }),
        purchaseOrder: new UntypedFormControl({ value: '', disabled: true }),
        firstContact: new UntypedFormControl({ value: '', disabled: true }),
        firstContactEmail: new UntypedFormControl({ value: '', disabled: true }),
        phoneNumber: new UntypedFormControl({ value: '', disabled: true }),
        phoneExtension: new UntypedFormControl({ value: '', disabled: true }),
        language: new UntypedFormControl({ value: '', disabled: true }),
        notes: new UntypedFormControl({ value: '', disabled: true }),
        address: this.addressFormGroup,
        billingOutputType: new UntypedFormControl({ value: '', disabled: true }),
        billingPeriod: new UntypedFormControl({ value: '', disabled: true }),
        billingMethod: new UntypedFormControl({ value: '', disabled: true }),
        billingContact: new UntypedFormControl({ value: '', disabled: true }),
        billingEmail: new UntypedFormControl({ value: '', disabled: true }),
        billingAddress: this.billingAddressFormGroup,
        creditCardNumber: new UntypedFormControl({ value: '', disabled: true }),
        expdate: new UntypedFormControl({ value: '', disabled: true }),
        hasActiveClientUser: new UntypedFormControl({ value: '', disabled: true }),
        volumeOfRequestPerYear: new UntypedFormControl({ value: '', disabled: true }),
        preAuthorizedPayment: new UntypedFormControl({ value: '', disabled: true }),
        lineOfBusiness: new UntypedFormControl({ value: '', disabled: true }),
        provenance: new UntypedFormControl({ value: '', disabled: true }),
        jobTitle: new UntypedFormControl({ value: '', disabled: true })
    });

    result: organizationOutput;
    resultAdress: AddressOutput;
    resultBillingAdress: AddressOutput;
    resultOrganizationCreditCard: OrganizationCreditCardOutput;
    valueReceived: OrganizationInput;
    publicId: string;
    idAdress: number;
    idBillingAddress: number;
    idOrganizationCreditcard: number;
    isSubOrganization = false;
    hasCreditCard = false;
    organizationIsActif = true;
    items: MenuItem[];
    waitingForApprobation = false;
    declined = false;
    organizationLogo = '';

    constructor(
        injector: Injector,
        private _b2bService: OrganizationB2BService,
        private _service: OrganizationService,
        private _route: ActivatedRoute,
        private _router: Router,
        private toastr: ToastrService,
        private _appLocalizationService: AppLocalizationService
    ) {
        super(injector);
    }

    async ngOnInit() {
        if (this._route.snapshot.paramMap.get('publicId') !== '') {
            this.publicId = this._route.snapshot.paramMap.get('publicId');
            await this._service
                .getOrganizationsByPublicId(this.publicId)
                .toPromise()
                .then((response) => {
                    this.result = response.result;
                    if (response.result) {
                        sessionStorage.setItem('gardium.organizations.selected', JSON.stringify({ id: response.result.id, publicId: response.result.publicId }));
                    }
                    this._service.getOrganizationLogo(this.result.id).subscribe((response) => {
                        if (response.result === null) {
                            this.organizationLogo = '';
                        } else {
                            this.organizationLogo = 'data:image/jpeg;base64,' + response.result;
                        }
                    });

                    this.resultAdress = this.result.address;
                    this.idAdress = this.resultAdress.id;

                    this.resultBillingAdress = this.result.billingInfo.billingAddress;
                    this.idBillingAddress = this.resultBillingAdress.id;

                    this.resultOrganizationCreditCard = this.result.organizationCreditCard;

                    this.organizationIsActif = true;
                    this.waitingForApprobation = false;
                    this.declined = false;
                    switch (this.result.status) {
                        case organizationStatus.WaitingForApprobation:
                            this.waitingForApprobation = true;
                            this.organizationIsActif = false;
                            break;
                        case organizationStatus.Declined:
                            this.declined = true;
                            this.organizationIsActif = false;
                            break;
                        case organizationStatus.Inactif:
                            this.organizationIsActif = false;
                            break;
                    }

                    this.formGroup.patchValue(this.result);
                    this.formGroup.controls[ 'organizationName' ].setValue(this.result.organizationName);
                    this.formGroup.controls[ 'lineOfBusiness' ].setValue(this._appLocalizationService.l(organizationLineOfBusiness[ this.result.lineOfBusiness ]));
                    this.formGroup.controls[ 'status' ].setValue(this.changeStatusName(this.result.status));
                    this.formGroup.controls[ 'hasActiveClientUser' ].setValue(this.hasActiveClientUserValueFormatter(this.result.hasActiveClientUser));
                    this.formGroup.controls[ 'provenance' ].setValue(this.getProvenanceAnswer(this.result.provenance));
                    this.formGroup.controls[ 'jobTitle' ].setValue(this.result.firstContactJob);

                    this.addressFormGroup.controls[ 'civicNo' ].setValue(this.resultAdress.civicNo);
                    this.addressFormGroup.controls[ 'street' ].setValue(this.resultAdress.street);
                    this.addressFormGroup.controls[ 'app' ].setValue(this.resultAdress.app);
                    this.addressFormGroup.controls[ 'city' ].setValue(this.resultAdress.city);
                    this.addressFormGroup.controls[ 'country' ].setValue(this.resultAdress.country);
                    this.addressFormGroup.controls[ 'state' ].setValue(this.resultAdress.state);
                    this.addressFormGroup.controls[ 'countryName' ].setValue(this.resultAdress.country.countryName);
                    this.addressFormGroup.controls[ 'stateName' ].setValue(this.resultAdress.state.stateName);
                    this.addressFormGroup.controls[ 'postalCode' ].setValue(this.resultAdress.postalCode);
                    const billingOutput = this.getBillingOutputTypeLocalized(this.result.billingInfo.billingOutputType);
                    this.formGroup.controls[ 'billingOutputType' ].setValue(billingOutput);
                    const billingType = this.getBillingPeriodLocalized(this.result.billingInfo.billingPeriod);
                    this.formGroup.controls[ 'billingPeriod' ].setValue(billingType);
                    const billingMethod = this.getBillingMethodTypeLocalized(this.result.billingInfo.billingMethod);
                    this.formGroup.controls[ 'billingMethod' ].setValue(billingMethod);
                    this.formGroup.controls[ 'billingContact' ].setValue(this.result.billingInfo.billingContact);
                    this.formGroup.controls[ 'billingEmail' ].setValue(this.result.billingInfo.billingEmail);

                    if (this.result.preAuthorizedPayment) {
                        this.formGroup.controls[ 'preAuthorizedPayment' ].setValue(1);
                    } else {
                        this.formGroup.controls[ 'preAuthorizedPayment' ].setValue(0);
                    }

                    this.formGroup.controls[ 'notes' ].setValue(this.result.notes);

                    this.billingAddressFormGroup.controls[ 'civicNo' ].setValue(this.resultBillingAdress.civicNo);
                    this.billingAddressFormGroup.controls[ 'street' ].setValue(this.resultBillingAdress.street);
                    this.billingAddressFormGroup.controls[ 'app' ].setValue(this.resultBillingAdress.app);
                    this.billingAddressFormGroup.controls[ 'city' ].setValue(this.resultBillingAdress.city);
                    this.billingAddressFormGroup.controls[ 'country' ].setValue(this.resultBillingAdress.country);
                    this.billingAddressFormGroup.controls[ 'state' ].setValue(this.resultBillingAdress.state);
                    this.billingAddressFormGroup.controls[ 'countryName' ].setValue(this.resultBillingAdress.country.countryName);
                    this.billingAddressFormGroup.controls[ 'stateName' ].setValue(this.resultBillingAdress.state.stateName);
                    this.billingAddressFormGroup.controls[ 'postalCode' ].setValue(this.resultBillingAdress.postalCode);

                    if (this.result.organizationParent !== null) {
                        this.formGroup.controls[ 'organizationParentName' ].setValue(this.result.organizationParent.organizationName);
                        this.isSubOrganization = true;
                        this.items = [
                            {
                                label: this._appLocalizationService.l('Organizations'),
                                routerLink: [ '/organizations' ]
                            },
                            {
                                label: this._appLocalizationService.l('OrganizationDetails'),
                                routerLink: [ `/organizations-details/${this.result.organizationParent.publicId}` ]
                            },
                            {
                                label: this._appLocalizationService.l('SubOrganizationsDetails')
                            }
                        ];
                    } else {
                        this.isSubOrganization = false;
                        this.items = [
                            {
                                label: this._appLocalizationService.l('Organizations'),
                                routerLink: [ '/organizations' ]
                            },
                            {
                                label: this._appLocalizationService.l('OrganizationDetails')
                            }
                        ];
                    }
                    if (this.result.billingInfo.billingMethod === 1 && this.result.organizationCreditCard != null) {
                        this.hasCreditCard = true;
                        this.formGroup.controls[ 'creditCardNumber' ].setValue(this.result.organizationCreditCard.creditCardNumber);
                        this.formGroup.controls[ 'expdate' ].setValue(this.result.organizationCreditCard.expdate);
                    }
                });

            this._route.queryParamMap.subscribe((params) => {
                if (params.has('tab')) {
                    this.selectTab(parseInt(params.get('tab')));
                } else {
                    this.goToTab(0, true);
                }
            });
        }
    }

    selectTab(id: number) {
        this.detailTabs.tabs[ id ].active = true;
    }

    onSelectTab(event: TabDirective) {
        const tabId = event.tabset.tabs.findIndex((x) => x === event);
        this.goToTab(tabId);
    }

    hasActiveClientUserValueFormatter(hasActiveClientUser: boolean) {
        if (hasActiveClientUser) {
            return this._appLocalizationService.l('Yes');
        }
        return this._appLocalizationService.l('No');
    }

    getBillingPeriodLocalized(billingPeriod: BillingPeriodTypeDTO) {
        return this._appLocalizationService.l(BillingPeriodTypeDTO[ billingPeriod ]);
    }

    getBillingOutputTypeLocalized(billingOutputType: BillingOutputTypeDTO) {
        return this._appLocalizationService.l(BillingOutputTypeDTO[ billingOutputType ]);
    }

    getBillingMethodTypeLocalized(billingMethod: BillingMethodTypeDTO) {
        return this._appLocalizationService.l(BillingMethodTypeDTO[ billingMethod ]);
    }

    getProvenanceAnswer(answer: string) {
        if (Object.values(clientProvenance).includes(answer as clientProvenance)) {
            answer = this._appLocalizationService.l(clientProvenance[ answer ]);
        }
        return answer;
    }

    updateOrganization() {
        this._router.navigate([ '/organizations-update', this.publicId ]);
    }

    addCandidat() {
        this._router.navigate([ `/candidats-create/${this.result.publicId}/true` ]);
    }

    addVerification(): void {
        this._router.navigate([ `/organizations-verificationAdd/${this.publicId}/true` ]);
    }

    showAllCandidats() {
        this._router.navigate([ '/candidats', this.result.organizationName ]);
    }

    showAllOrganizationVerifications() {
        this._router.navigate([ '/organizationVerifications', this.result.publicId ]);
    }

    async approveOrganization() {
        const input = this.initOrganizationInput();
        // validate
        if (this.isFirstTabInvalid() || this.isSecondTabInvalid() || this.isThirdTabInvalid()) {
            const title = this._appLocalizationService.l('OrganizationApproveIssue');
            const message = this._appLocalizationService.l('OrganizationApproveMissingFields');
            this.showErrorMessage(title, message);
            return;
        }

        this.message.confirm(
            this._appLocalizationService.l('OrganizationApproveWarning', input.organizationName),
            this._appLocalizationService.l('AreYouSure'),
            async (isConfirmed) => {
                if (isConfirmed) {
                    try {
                        await this._b2bService
                            .approveOrganization(input)
                            .toPromise()
                            .then(() => this.showSuccess('OrganizationApprove', 'OrganizationApproved'));

                        await this.ngOnInit();
                    } catch (e) {
                        console.dir(e);
                        this.showErrorMessage('OrganizationApproveIssue', 'OrganizationApproveTryAgainLater');
                    }
                }
            }, {
            confirmButtonText: this.l('Yes'),
            cancelButtonText: this.l('No')
        }
        );
    }

    async declineOrganization() {
        const input = this.initOrganizationInput();
        this.message.confirm(
            this._appLocalizationService.l('OrganizationDeclineWarning', input.organizationName),
            this._appLocalizationService.l('AreYouSure'),
            async (isConfirmed) => {
                if (isConfirmed) {
                    try {
                        await this._b2bService
                            .declineOrganization(input)
                            .toPromise()
                            .then(() => this.showSuccess('OrganizationDecline', 'OrganizationDeclined'));

                        await this.ngOnInit();
                    } catch (e) {
                        this.showErrorMessage('OrganizationDeclineIssue', 'OrganizationDeclineTryAgainLater');
                    }
                }
            }, {
            confirmButtonText: this.l('Yes'),
            cancelButtonText: this.l('No')
        }
        );
    }

    delete() {
        const input = this.initOrganizationInput();
        this.message.confirm(
            this._appLocalizationService.l('OrganizationDeleteWarningMessage', input.organizationName),
            this._appLocalizationService.l('AreYouSure'),
            (isConfirmed) => {
                if (isConfirmed) {
                    try {
                        this._service.deleteOrganization(input).subscribe(() => {
                            this.showSuccess('OrganizationDelete', 'OrganizationDeleted');
                            this.returnToList();
                        });
                    } catch (e) {
                        this.showErrorMessage('OrganizationDeletedIssue', 'OrganizationDeletedTryAgainLater');
                    }
                }
            }, {
            confirmButtonText: this.l('Yes'),
            cancelButtonText: this.l('No')
        }
        );
    }

    initOrganizationInput() {
        const address = this.initAddress();
        const billingInfo = this.initBillingInfo();
        let organizationCreditCard;
        if (this.resultOrganizationCreditCard === null) {
            organizationCreditCard = null;
        } else {
            organizationCreditCard = this.initOrganizationCreditCard();
        }

        const input: OrganizationInput = {
            id: this.result.id,
            idSage: this.result.idSage,
            idAccountantCAR: this.result.idAccountantCAR,
            publicId: this.result.publicId,
            clientId: this.result.clientId,
            organizationName: this.result.organizationName,
            purchaseOrder: this.result.purchaseOrder,
            firstContact: this.result.firstContact,
            firstContactEmail: this.result.firstContactEmail,
            firstContactJob: this.result.firstContactJob,
            language: this.result.language,
            notes: this.result.notes,
            phoneNumber: this.result.phoneNumber,
            phoneExtension: this.result.phoneExtension,
            status: this.result.status,
            organizationParentId: this.result.organizationParentId,
            address: address,
            billingInfo: billingInfo,
            organizationCreditCard: organizationCreditCard,
            volumeOfRequestPerYear: this.result.volumeOfRequestPerYear,
            preAuthorizedPayment: this.result.preAuthorizedPayment,
            lineOfBusiness: this.result.lineOfBusiness,
            provenance: this.result.provenance
        };

        return input;
    }

    initAddress(): AddressInput {
        const address: AddressInput = {
            id: this.idAdress,
            civicNo: this.resultAdress.civicNo,
            street: this.resultAdress.street,
            app: this.resultAdress.app,
            city: this.resultAdress.city,
            postalCode: this.resultAdress.postalCode,
            state: this.resultAdress.state,
            country: this.resultAdress.country
        };
        return address;
    }

    initBillingInfo(): BillingInformationInput {
        const billingAddress = this.initBillingAddress();

        const billingInfo: BillingInformationInput = {
            billingOutputType: this.result.billingInfo.billingOutputType,
            billingPeriod: this.result.billingInfo.billingPeriod,
            billingMethod: this.result.billingInfo.billingMethod,
            billingEmail: this.result.billingInfo.billingEmail,
            id: this.result.billingInfo.id,
            billingContact: this.result.billingInfo.billingContact,
            billingAddress: billingAddress
        };

        return billingInfo;
    }

    initBillingAddress(): AddressInput {
        const address: AddressInput = {
            id: this.idBillingAddress,
            civicNo: this.resultBillingAdress.civicNo,
            street: this.resultBillingAdress.street,
            app: this.resultBillingAdress.app,
            city: this.resultBillingAdress.city,
            postalCode: this.resultBillingAdress.postalCode,
            state: this.resultBillingAdress.state,
            country: this.resultBillingAdress.country
        };
        return address;
    }

    initOrganizationCreditCard(): OrganizationCreditCardInput {
        const creditCard: OrganizationCreditCardInput = {
            id: this.idOrganizationCreditcard,
            creditCardNumber: this.resultOrganizationCreditCard.creditCardNumber,
            expdate: this.resultOrganizationCreditCard.expdate,
            creditCardChecked: false
        };
        return creditCard;
    }

    showSuccess(title: string, successMessage: string) {
        this.toastr.success(this._appLocalizationService.l(successMessage), this._appLocalizationService.l(title));
    }

    showErrorMessage(title: string, errorMessage: string) {
        this.toastr.error(this._appLocalizationService.l(errorMessage), this._appLocalizationService.l(title));
    }
    returnToList() {
        this._router.navigate([ '/organizations' ]);
    }

    addSubOrganization() {
        this._router.navigate([ '/suborganization-create', this.result.publicId, this.result.organizationName ]);
    }

    changeStatusName(stat: number): string {
        switch (stat) {
            case 0:
                return this._appLocalizationService.l('Actif');
            case 1:
                return this._appLocalizationService.l('Inactif');
            case 2:
                return this._appLocalizationService.l('WaitingForApprobation');
            case 3:
                return this._appLocalizationService.l('Declined');
        }
    }

    isFirstTabInvalid(): boolean {
        const org = this.formGroup.getRawValue();
        if (org.organizationName !== '' && org.firstContact !== '' && org.firstContactEmail !== '' && org.phoneNumber !== '' && org.language !== '') {
            return false;
        } else {
            return true;
        }
    }

    isSecondTabInvalid(): boolean {
        const org = this.formGroup.getRawValue();
        if (org.civicNo !== '' && org.street !== '' && org.city !== '' && org.postalCode !== '' && org.state !== '' && org.country !== '') {
            return false;
        } else {
            return true;
        }
    }

    isThirdTabInvalid(): boolean {
        const org = this.formGroup.getRawValue();
        const billingMethod = org.billingMethod;
        // tslint:disable-next-line:triple-equals
        if (billingMethod === 1) {
            if (
                org.billingOutputType !== '' &&
                org.billingPeriod !== '' &&
                org.billingMethod !== '' &&
                org.billingContact !== '' &&
                org.billingEmail !== '' &&
                org.preAuthorizedPayment !== '' &&
                org.billingCivicNo !== '' &&
                org.billingStreet !== '' &&
                org.billingCity !== '' &&
                org.billingPostalCode !== '' &&
                org.billingState !== '' &&
                org.billingCountry !== '' &&
                org.creditCardNumber !== '' &&
                org.expdate !== ''
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            if (
                org.billingOutputType !== '' &&
                org.billingPeriod !== '' &&
                org.billingMethod !== '' &&
                org.billingContact !== '' &&
                org.billingEmail !== '' &&
                org.preAuthorizedPayment !== '' &&
                org.billingCivicNo !== '' &&
                org.billingStreet !== '' &&
                org.billingCity !== '' &&
                org.billingPostalCode !== '' &&
                org.billingState !== '' &&
                org.billingCountry !== ''
            ) {
                return false;
            } else {
                return true;
            }
        }
    }

    private goToTab(tabId: number, replace = false) {
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: {
                tab: tabId
            },
            queryParamsHandling: 'merge',
            replaceUrl: replace
        });
    }
}
