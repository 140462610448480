import { Component, Injector, OnInit } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { OrganizationService } from '@shared/services/organization.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { OrganizationInput } from '@shared/models/organization/organizationInput';
import { ToastrService } from 'ngx-toastr';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AddressInput } from '@shared/models/address/addressInput';
import { organizationOutput } from '@shared/models/organization/organizationOutput';
import { CountryService } from '@shared/services/country.service';
import { country } from '@shared/models/shared/country';
import { Constants } from '@shared/models/shared/constants';
import { organizationStatus } from '@shared/models/organization/organizationStatus';
import { BillingInformationInput } from '@shared/models/organization/billingInformation/billingInformationInput';
import { phoneNumberValidator } from '@shared/utils/validation/phoneNumber-validator.directive';
import { multipleEmailAddressValidator } from '@shared/utils/validation/multiple-emails-validator.directive';
import { emailAddressValidator } from '@shared/utils/validation/email-validator.directive';
import { OrganizationCreditCardInput } from '@shared/models/payment/organizationCreditCardInput';
import { BillingMethodTypeDTO } from '@shared/models/organization/billingInformation/BillingMethodTypeDTO';
import { AppConsts } from '@shared/AppConsts';
import { expirationDateValidator } from '@shared/utils/validation/expirationDate-validator.directive';
import { creditCardValidator } from '@shared/utils/validation/creditCard-validator.directive';
import { OrganizationOutputStatusType } from '@shared/models/organization/organizationOutputStatus';
import { MenuItem } from 'primeng/api';
import { FileItem, FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';
import { IAjaxResponse } from '@shared/models/ajax-response';
import { ProfileServiceProxy, UpdateProfilePictureInput } from '@shared/service-proxies/service-proxies';
import { organizationLineOfBusiness } from '@shared/models/organization/organizationLineOfBusiness';
import { OrganizationRequestVolumeRange } from '@shared/models/organization/OrganizationRequestVolumeRange';
import { clientProvenance } from '@shared/models/organization/organizationClientProvenance';
import { filter } from 'rxjs/operators';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { environment as env } from 'environments/environment';

@Component({
    selector: 'organization-create',
    templateUrl: './organization-create.component.html',
    styleUrls: [ './organization-create.component.scss' ],
    animations: [ appModuleAnimation() ]
})
export class OrganizationCreateComponent extends AppComponentBase implements OnInit {
    appConsts = AppConsts;
    isSubOrganization = false;

    orgoutput: organizationOutput;
    publicParentId: string;
    parentOrganizationName: string;

    lineOfBusiness: string;
    lineOfBusinessList = [];
    optionsBillingType: string[];
    optionsBillingPeriod: string[];

    provenanceList = [];
    provenanceAnswer: string;
    isAnswerOther = false;

    firstTabValid = true;
    value = '';
    valueinput = '';
    createdOrganization: OrganizationInput;
    isOrganizationCreated = false;
    isDisabled = false;
    currentLanguage: any;
    items: MenuItem[];

    maxPictureBytesUserFriendlyValue = 5;
    imageChangedEvent: any = '';
    uploader: FileUploader;
    organizationLogo = '';
    organizationRequestVolumeRanges = OrganizationRequestVolumeRange;
    countryCanada: country;

    addressFormGroup = new UntypedFormGroup({
        civicNo: new UntypedFormControl('', Validators.required),
        street: new UntypedFormControl('', Validators.required),
        app: new UntypedFormControl(''),
        city: new UntypedFormControl('', Validators.required),
        postalCode: new UntypedFormControl(''),
        country: new UntypedFormControl(''),
        countryName: new UntypedFormControl('', Validators.required),
        state: new UntypedFormControl(''),
        stateName: new UntypedFormControl('', Validators.required)
    });
    billingAddressFormGroup = new UntypedFormGroup({
        civicNo: new UntypedFormControl('', Validators.required),
        street: new UntypedFormControl('', Validators.required),
        app: new UntypedFormControl(''),
        city: new UntypedFormControl('', Validators.required),
        postalCode: new UntypedFormControl('', Validators.required),
        country: new UntypedFormControl(''),
        countryName: new UntypedFormControl('', Validators.required),
        state: new UntypedFormControl(''),
        stateName: new UntypedFormControl('', Validators.required)
    });
    formGroup = new UntypedFormGroup({
        parentName: new UntypedFormControl({ value: '', disabled: true }),
        name: new UntypedFormControl('', Validators.required),
        idSage: new UntypedFormControl(''),
        idAccountantCAR: new UntypedFormControl(''),
        purchaseOrder: new UntypedFormControl(''),
        firstContact: new UntypedFormControl('', Validators.required),
        firstContactEmail: new UntypedFormControl('', [ Validators.required, emailAddressValidator ]),
        phoneNumber: new UntypedFormControl('', [ Validators.required, phoneNumberValidator ]),
        phoneExtension: new UntypedFormControl(''),
        language: new UntypedFormControl('', Validators.required),
        notes: new UntypedFormControl(''),
        address: this.addressFormGroup,
        volumeOfRequestPerYear: new UntypedFormControl({
            value: '',
            disabled: !this.isGranted('Pages.Management.Organizations.CanSeePrivateInformation')
        }),
        billingOutputType: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
        billingPeriod: new UntypedFormControl('', Validators.required),
        billingMethod: new UntypedFormControl('', Validators.required),
        billingContact: new UntypedFormControl('', Validators.required),
        billingEmail: new UntypedFormControl('', [ Validators.required, multipleEmailAddressValidator ]),
        billingAddress: this.billingAddressFormGroup,
        creditCardNumber: new UntypedFormControl('', [ Validators.required, creditCardValidator ]),
        expdate: new UntypedFormControl('', [ Validators.required, expirationDateValidator ]),
        preAuthorizedPayment: new UntypedFormControl('', Validators.required),
        organizationLogo: new UntypedFormControl(''),
        lineOfBusiness: new UntypedFormControl(''),
        jobTitle: new UntypedFormControl(''),
        howYouHear: new UntypedFormControl(''),
        otherAnswer: new UntypedFormControl('')
    });

    saving: boolean;

    private _uploaderOptions: FileUploaderOptions;

    constructor(
        injector: Injector,
        private service: OrganizationService,
        private countryService: CountryService,
        private toastr: ToastrService,
        private _appLocalizationService: AppLocalizationService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _appAuthService: AppAuthService,
        private _profileService: ProfileServiceProxy
    ) {
        super(injector);
    }

    fileChangeEvent(event: any): void {
        if (event.target.files[ 0 ].size > 5242880) {
            //5MB
            this.message.warn(this.l('ProfilePicture_Warn_SizeLimit', this.maxPictureBytesUserFriendlyValue));
            return;
        }

        this.imageChangedEvent = event;
    }

    imageCroppedFile(event: ImageCroppedEvent) {
        this.uploader.clearQueue();
        this.uploader.addToQueue([ <File>base64ToFile(event.base64) ]);
    }

    initFileUploader(): void {
        this._uploaderOptions = {
            url: env.api + '/api/Profile/UploadProfilePicture',
            autoUpload: false,
            authToken: 'Bearer ' + this._appAuthService.getToken(),
            removeAfterUpload: true
        };

        this.uploader = new FileUploader(this._uploaderOptions);
        this.uploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };

        this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
            form.append('FileType', fileItem.file.type);
            form.append('FileName', 'OrganizationLogo');
            form.append('FileToken', this.guid());
        };

        this.uploader.onSuccessItem = (item, response, status) => {
            const resp = <IAjaxResponse>JSON.parse(response);
            if (resp.success) {
                this.updateOrganizationPicture(resp.result.fileToken);
            } else {
                this.message.error(resp.error.message);
            }
        };
    }

    updateOrganizationPicture(fileToken: string): void {
        const input = new UpdateProfilePictureInput();
        input.fileToken = fileToken;
        input.x = 0;
        input.y = 0;
        input.width = 0;
        input.height = 0;

        this.saving = true;
        this._profileService.updateOrganizationPicture(input).subscribe((response) => {
            this.service.addOrganizationFile(response, this.orgoutput.id).subscribe((response) => {
                if (response.result === null) {
                    this.organizationLogo = '';
                } else {
                    this.organizationLogo = 'data:image/jpeg;base64,' + response.result;
                }
                this.saving = false;
                this.goToOrganizationDetails(this.orgoutput.publicId);
            });
        });
    }

    guid(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    async saveOrganizationLogo() {
        this.uploader.uploadAll();
    }

    async ngOnInit() {
        if (this._route.snapshot.paramMap.get('publicId') != null && this._route.snapshot.paramMap.get('organizationName') != null) {
            this.publicParentId = this._route.snapshot.paramMap.get('publicId');
            this.parentOrganizationName = this._route.snapshot.paramMap.get('organizationName');
            this.formGroup.controls[ 'parentName' ].setValue(this.parentOrganizationName);
            this.isSubOrganization = true;

            this.items = [
                {
                    label: this._appLocalizationService.l('Organizations'),
                    routerLink: [ '/organizations' ]
                },
                {
                    label: this._appLocalizationService.l('OrganizationDetails'),
                    routerLink: [ `/organizations-details/${this.publicParentId}` ]
                },
                {
                    label: this._appLocalizationService.l('SubOrganizationsCreate')
                }
            ];
        } else {
            this.items = [
                {
                    label: this._appLocalizationService.l('Organizations'),
                    routerLink: [ '/organizations' ]
                },
                {
                    label: this._appLocalizationService.l('OrganizationsCreate')
                }
            ];
            this.isSubOrganization = false;
        }
        const billingOutput = <HTMLInputElement>document.getElementById('billingOutputType');
        this.formGroup.controls[ 'billingOutputType' ].setValue(0);
        this.formGroup.controls[ 'preAuthorizedPayment' ].setValue(0);

        billingOutput.disabled = true;
        this.currentLanguage = this._appLocalizationService.currentLanguage.displayName;

        await this.getCanadaCountryList();
        await this.setLineOfBusinessList();
        await this.initProvenanceList();

        this.initFileUploader();

        // Listen to provenance select input
        this.onSelectProvenance();
        // Listen to other answer input
        this.onOtherAnswerInput();
    }

    async setLineOfBusinessList() {
        this.lineOfBusinessList = Object.keys(organizationLineOfBusiness)
            .filter((value) => isNaN(Number(value)) === true)
            .map((key) => ({
                value: organizationLineOfBusiness[ key ],
                key: this.l(key)
            }));

        this.lineOfBusinessList.sort((a, b) =>
            a.key.localeCompare(b.key, {
                ignorePunctuation: true,
                sensitivity: 'base'
            })
        );
    }

    async getCanadaCountryList() {
        await this.countryService
            .getCanadaCountryList()
            .toPromise()
            .then((value) => {
                this.countryCanada = value.result as country;
            });
    }

    onNavigate(link) {
        window.open(link, '_blank');
    }

    billingMethodChange(event) {
        // tslint:disable-next-line:triple-equals
        if (event === BillingMethodTypeDTO.CreditCard) {
            if (this.billingAddressFormGroup.controls[ 'countryName' ].value !== 'Canada') {
                this.billingAddressFormGroup.controls[ 'country' ].setValue(this.countryCanada);
                this.billingAddressFormGroup.controls[ 'countryName' ].setValue('Canada');
                this.billingAddressFormGroup.controls[ 'country' ].updateValueAndValidity();
            }
            this.billingAddressFormGroup.controls[ 'countryName' ].disable();
            this.formGroup.controls[ 'creditCardNumber' ].setValidators([ Validators.required, creditCardValidator ]);
            this.formGroup.controls[ 'expdate' ].setValidators([ Validators.required, expirationDateValidator ]);
        } else {
            this.billingAddressFormGroup.controls[ 'countryName' ].enable();
            this.formGroup.controls[ 'creditCardNumber' ].clearValidators();
            this.formGroup.controls[ 'expdate' ].clearValidators();
        }

        this.formGroup.controls[ 'creditCardNumber' ].updateValueAndValidity();
        this.formGroup.controls[ 'expdate' ].updateValueAndValidity();
    }

    isStateRequired(countryCode: string): boolean {
        return countryCode.toLowerCase() === Constants.USA_CODE.toLowerCase() || countryCode.toLowerCase() === Constants.CANADA_CODE.toLowerCase();
    }

    async save() {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }

        const address = this.initAddress();
        const billingInfo = this.initBillingInfo();
        const organizationCreditCard = this.initOrganizationCreditCard();
        const preAuthorizedPayment = this.formGroup.controls[ 'billingMethod' ].value === 1 && this.formGroup.controls[ 'preAuthorizedPayment' ].value === 1;
        const input: OrganizationInput = {
            id: 0,
            publicId: '',
            idSage: this.formGroup.controls[ 'idSage' ].value,
            idAccountantCAR: this.formGroup.controls[ 'idAccountantCAR' ].value,
            clientId: '',
            organizationName: this.formGroup.controls[ 'name' ].value,
            purchaseOrder: this.formGroup.controls[ 'purchaseOrder' ].value,
            firstContact: this.formGroup.controls[ 'firstContact' ].value,
            firstContactEmail: this.formGroup.controls[ 'firstContactEmail' ].value,
            firstContactJob: this.formGroup.controls[ 'jobTitle' ].value,
            language: this.formGroup.controls[ 'language' ].value,
            notes: this.formGroup.controls[ 'notes' ].value,
            phoneNumber: this.formGroup.controls[ 'phoneNumber' ].value,
            phoneExtension: this.formGroup.controls[ 'phoneExtension' ].value,
            status: organizationStatus.Actif,
            organizationParentId: null,
            address: address,
            billingInfo: billingInfo,
            organizationCreditCard: organizationCreditCard,
            volumeOfRequestPerYear: this.formGroup.controls[ 'volumeOfRequestPerYear' ].value,
            preAuthorizedPayment: preAuthorizedPayment,
            lineOfBusiness: this.formGroup.controls[ 'lineOfBusiness' ].value,
            provenance: this.provenanceAnswer
        };
        this.isOrganizationCreated = true;

        if (this.isSubOrganization) {
            await this.service
                .createSubOrganization(input, this.publicParentId)
                .toPromise()
                .then(
                    (value) => {
                        this.finalizeCreateOrganisation(value);
                    },
                    (error) => {
                        this.showErrorMessage();
                        this.isOrganizationCreated = false;
                    }
                );
        } else {
            await this.service
                .createOrganization(input)
                .toPromise()
                .then(
                    (value) => {
                        this.finalizeCreateOrganisation(value);
                    },
                    (error) => {
                        this.showErrorMessage();
                        this.isOrganizationCreated = false;
                    }
                );
        }
    }

    finalizeCreateOrganisation(createResult: any) {
        const organization = createResult.result;
        if (organization.result.outputStatus === OrganizationOutputStatusType.OrganizationCreatedWithoutVault) {
            this.showErrorCreditCardMessage();
        }
        this.orgoutput = createResult.result;
        this.saveOrganizationLogo();

        if (organization.id !== null) {
            this.showSuccess();
            if (this.isGranted('Pages.Management.Organizations.Details')) {
                this._router
                    .navigateByUrl('app/organizations-details', {
                        skipLocationChange: true
                    })
                    .then(() => this._router.navigate([ '/organizations-details', organization.publicId ]));
            }
        } else {
            this.isOrganizationCreated = false;
            this.showErrorMessage();
        }
    }

    goToOrganizationDetails(publicId: string) {
        if (this.isGranted('Pages.Management.Organizations.Details')) {
            this._router.navigate([ '/organizations-details', publicId ]);
        } else {
            this.showAccessError();
        }
    }

    showAccessError() {
        const title = this._appLocalizationService.l('AccessIssue');
        const message = this._appLocalizationService.l('NotAccess');
        this.toastr.error(message, title);
    }

    initBillingInfo(): BillingInformationInput {
        const billingAddress = this.initBillingAddress();

        const billingInfo: BillingInformationInput = {
            billingOutputType: this.formGroup.controls[ 'billingOutputType' ].value,
            billingPeriod: this.formGroup.controls[ 'billingPeriod' ].value,
            billingMethod: this.formGroup.controls[ 'billingMethod' ].value,
            billingEmail: this.formGroup.controls[ 'billingEmail' ].value,
            id: 0,
            billingContact: this.formGroup.controls[ 'billingContact' ].value,
            billingAddress: billingAddress
        };

        return billingInfo;
    }

    initOrganizationCreditCard(): OrganizationCreditCardInput {
        const creditCard: OrganizationCreditCardInput = {
            id: 0,
            creditCardNumber: this.formGroup.controls[ 'creditCardNumber' ].value,
            expdate: this.formGroup.controls[ 'expdate' ].value,
            creditCardChecked: false
        };
        return creditCard;
    }

    isFormInvalid(): boolean {
        return this.formGroup.invalid;
    }

    showForNotValidMessage() {
        const title = this._appLocalizationService.l('VerifyAllControlsTitle');
        const message = this._appLocalizationService.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }

    markAllControlsAsTouch() {
        this.formGroup.controls[ 'name' ].markAsTouched();
        this.formGroup.controls[ 'purchaseOrder' ].markAsTouched();
        this.formGroup.controls[ 'firstContact' ].markAsTouched();
        this.formGroup.controls[ 'firstContactEmail' ].markAsTouched();
        this.formGroup.controls[ 'phoneNumber' ].markAsTouched();
        this.formGroup.controls[ 'phoneExtension' ].markAsTouched();
        this.formGroup.controls[ 'language' ].markAsTouched();
        this.formGroup.controls[ 'idSage' ].markAsTouched();
        this.formGroup.controls[ 'idAccountantCAR' ].markAsTouched();

        this.addressFormGroup.markAllAsTouched();
        this.billingAddressFormGroup.markAllAsTouched();

        this.formGroup.controls[ 'billingOutputType' ].markAsTouched();
        this.formGroup.controls[ 'billingPeriod' ].markAsTouched();
        this.formGroup.controls[ 'billingMethod' ].markAsTouched();
        this.formGroup.controls[ 'billingContact' ].markAsTouched();
        this.formGroup.controls[ 'billingEmail' ].markAsTouched();
        this.formGroup.controls[ 'preAuthorizedPayment' ].markAsTouched();

        this.formGroup.controls[ 'creditCardNumber' ].markAsTouched();
        this.formGroup.controls[ 'expdate' ].markAsTouched();
        this.formGroup.controls[ 'lineOfBusiness' ].markAllAsTouched();
    }

    copyContactInformationToBillingInfo() {
        this.formGroup.controls[ 'billingEmail' ].setValue(this.formGroup.controls[ 'firstContactEmail' ].value);
        this.formGroup.controls[ 'billingContact' ].setValue(this.formGroup.controls[ 'firstContact' ].value);
    }

    copyAddressAsBillingAddress() {
        this.billingAddressFormGroup.patchValue(this.addressFormGroup.value);
        this.billingAddressFormGroup.controls[ 'postalCode' ].setValue(this.addressFormGroup.get('postalCode').value);
    }

    isFirstTabInvalid(): boolean {
        if (
            this.formGroup.controls[ 'name' ].valid &&
            this.formGroup.controls[ 'firstContact' ].valid &&
            this.formGroup.controls[ 'firstContactEmail' ].valid &&
            this.formGroup.controls[ 'phoneNumber' ].valid &&
            this.formGroup.controls[ 'language' ].valid &&
            this.formGroup.controls[ 'lineOfBusiness' ].valid
        ) {
            return false;
        } else {
            return true;
        }
    }

    isSecondTabInvalid(): boolean {
        return this.addressFormGroup.invalid;
    }

    checkValidityOfCountry(input) {
        const element = this.formGroup.controls[ input ];
        if (element.value === '1') {
            return this.billingAddressFormGroup.controls[ 'countryName' ].value;
        } else {
            return this.billingAddressFormGroup.controls[ 'countryName' ].valid;
        }
    }

    isBillingOutputTypeValid() {
        return !this.formGroup.controls[ 'billingOutputType' ].invalid;
    }

    isThirdTabInvalid(): boolean {
        if (
            this.isBillingOutputTypeValid() &&
            this.formGroup.controls[ 'billingPeriod' ].valid &&
            this.formGroup.controls[ 'billingMethod' ].valid &&
            this.formGroup.controls[ 'billingContact' ].valid &&
            this.formGroup.controls[ 'billingEmail' ].valid &&
            this.formGroup.controls[ 'preAuthorizedPayment' ].valid &&
            this.billingAddressFormGroup.valid &&
            this.checkValidityOfCountry('billingMethod') &&
            this.formGroup.controls[ 'creditCardNumber' ].valid &&
            this.formGroup.controls[ 'expdate' ].valid
        ) {
            return false;
        } else {
            return true;
        }
    }
    removeTouchForcreditCard() {
        if (this.formGroup.controls[ 'creditCardNumber' ].value || this.formGroup.controls[ 'expdate' ].value) {
            this.formGroup.controls[ 'creditCardNumber' ].markAsUntouched();
            this.formGroup.controls[ 'expdate' ].markAsUntouched();
        }
    }

    initAddress(): AddressInput {
        const address: AddressInput = {
            id: 0,
            civicNo: this.addressFormGroup.controls[ 'civicNo' ].value,
            street: this.addressFormGroup.controls[ 'street' ].value,
            app: this.addressFormGroup.controls[ 'app' ].value,
            city: this.addressFormGroup.controls[ 'city' ].value,
            postalCode: this.addressFormGroup.controls[ 'postalCode' ].value,
            state: this.addressFormGroup.controls[ 'state' ].value,
            country: this.addressFormGroup.controls[ 'country' ].value
        };
        return address;
    }

    initBillingAddress(): AddressInput {
        const address: AddressInput = {
            id: 0,
            civicNo: this.billingAddressFormGroup.controls[ 'civicNo' ].value,
            street: this.billingAddressFormGroup.controls[ 'street' ].value,
            app: this.billingAddressFormGroup.controls[ 'app' ].value,
            city: this.billingAddressFormGroup.controls[ 'city' ].value,
            postalCode: this.billingAddressFormGroup.controls[ 'postalCode' ].value,
            state: this.billingAddressFormGroup.controls[ 'state' ].value,
            country: this.billingAddressFormGroup.controls[ 'country' ].value
        };
        return address;
    }

    showSuccess() {
        let title;
        let successMessage;
        if (this.isSubOrganization) {
            title = this._appLocalizationService.l('SubOrganizationsCreate');
            successMessage = this._appLocalizationService.l('SubOrganizationCreated');
        } else {
            title = this._appLocalizationService.l('OrganizationsCreate');
            successMessage = this._appLocalizationService.l('OrganizationCreated');
        }
        this.toastr.success(successMessage, title);
    }

    showErrorMessage() {
        let title;
        let errorMessage;
        if (this.isSubOrganization) {
            title = this._appLocalizationService.l('SubOrganizationCreateIssue');
        } else {
            title = this._appLocalizationService.l('OrganizationCreateIssue');
        }
        errorMessage = this._appLocalizationService.l('OrganizationCreateTryAgainLater');
        this.toastr.error(errorMessage, title);
    }

    showErrorCreditCardMessage() {
        const title = this._appLocalizationService.l('CreditCardIssue');
        let warningMessage;
        if (this.isSubOrganization) {
            warningMessage = this._appLocalizationService.l('OrganizationCreatePaymentIssue');
        } else {
            warningMessage = this._appLocalizationService.l('SubOrganizationCreatePaymentIssue');
        }
        this.toastr.warning(warningMessage, title);
    }

    returnToList() {
        this._router.navigate([ '/organizations' ]);
    }

    returnToSubList() {
        this._router.navigate([ '/organizations-details', this.publicParentId ]);
    }

    async initProvenanceList() {
        this.provenanceList = Object.keys(clientProvenance)
            .filter((value) => isNaN(Number(value)) === true)
            .map((key) => ({
                value: this.l(key),
                key: clientProvenance[ key ]
            }));
    }

    onSelectProvenance(): void {
        this.formGroup.get('howYouHear').valueChanges.pipe(filter((answer) => !!answer)).subscribe((answer) => {
            if (answer === clientProvenance.Other) {
                this.isAnswerOther = true;
                this.formGroup.controls[ 'otherAnswer' ].addValidators(Validators.required);
            } else {
                this.isAnswerOther = false;
                this.formGroup.controls[ 'otherAnswer' ].clearValidators();
                // Else we assign the value of the selected answer to the form field.
                this.provenanceAnswer = answer;
            }
            this.formGroup.controls[ 'otherAnswer' ].updateValueAndValidity();
        });
    }

    onOtherAnswerInput(): void {
        // if 'Other' is choosed, we take the answer typed to send to our backend.
        this.formGroup.get('otherAnswer').valueChanges
            .pipe(filter(() => this.formGroup.controls[ 'howYouHear' ].value === clientProvenance.Other))
            .subscribe((answer) => {
                this.provenanceAnswer = answer;
            });
    }
}
