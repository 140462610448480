import { Injectable } from '@angular/core';
import { IServerSideDatasource, IServerSideGetRowsParams } from '@ag-grid-enterprise/all-modules';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { organizationLineOfBusiness } from '@shared/models/organization/organizationLineOfBusiness';
import { OrganizationService } from './organization.service';
import { overallAdjustLocalDateRangeToUtcDateTimeRange } from '@shared/utils/date-time/adjust-local-date-range-to-utc-date-time-range';

@Injectable()
export class OrganizationDatasourceService implements IServerSideDatasource {

    private parentOrganizationPublicId: string;

    constructor(
        private appLocalizationService: AppLocalizationService,
        private organizationService: OrganizationService) {
    }

    setParentOrganizationPublicId(parentOrganizationPublicId: string): void {
        this.parentOrganizationPublicId = parentOrganizationPublicId;
    }

    getRows(params: IServerSideGetRowsParams): void {
        const filterModel = overallAdjustLocalDateRangeToUtcDateTimeRange(params.request.filterModel);
        this.organizationService
            .filter(this.parentOrganizationPublicId, params.request.startRow, params.request.endRow, params.request.sortModel, filterModel)
            .toPromise()
            .then(
                (value) => {
                    value.result.results.forEach((element) => {
                        element.billingMethod = this.appLocalizationService.l(element.billingMethod);
                        const x = element.lineOfBusiness;
                        const y: number = +x;
                        if (element.lineOfBusiness.length > 0) {
                            element.lineOfBusiness = this.appLocalizationService.l(organizationLineOfBusiness[y]);
                        }
                    });
                    params.successCallback(value.result.results, value.result.count);
                },
                _error => {
                    params.failCallback();
                }
            );
    }

    destroy?(): void {

    }
}
