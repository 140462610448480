import { AppComponentBase } from "@shared/common/app-component-base";

/**
 * Set the date to UTC to avoid invalid dates when the timezone changes
 * @param sourceDate Date to change with or without a different offset
 * @returns Date sets to year, month, day, and 0 time
 */
export function setDateTimeToUTC(sourceDate: Date) {
    // We won't set the date if it's undefined or null
    if (sourceDate === null) {
        return sourceDate;
    }

    const dateUTC = new Date(Date.UTC(sourceDate.getFullYear(), sourceDate.getMonth(), sourceDate.getDate(), 0, 0, 0, 0));

    return dateUTC;
}

/**
 * 
 * @param component Component that extends AppComponentBase
 * @param inputDate Input date to format and localize in the format "MMMM - YY"
 * @returns 
 */
export function getLocalizedMonthYearDate(component:AppComponentBase, inputDate:Date) {
    let date = new Date(inputDate);
    let monthLabel = getLocalizedMonth(component, date).toUpperCase();
    let formatedYear = date.getFullYear().toString().slice(-2);
    return `${monthLabel} - ${formatedYear}`;    
}

/**
 * 
 * @param component Component that extends AppComponentBase
 * @param inputDate Input date to format and localize in the format "MMMM - dd, YYYY"
 * @returns 
 */
export function getLocalizedFullDateFormat(component:AppComponentBase, inputDate:Date) {
    let date = new Date(inputDate);
    let monthLabel = getLocalizedMonth(component, date).toUpperCase();
    let day = date.getDate().toString().padStart(2,'0');
    let year = date.getFullYear().toString();
    return `${monthLabel} ${day}, ${year}`;    
}

/**
 * 
 * @param component Component that extends AppComponentBase
 * @param inputDate Input date to format and localize in the format "MMMM"
 * @returns 
 */
export function getLocalizedMonth(component:AppComponentBase, inputDate:Date){    
    let date = new Date(inputDate);
    let month = date.toLocaleString('en-US', { month: 'long' });
    let monthLabel = component.ls('PreEmploiMonths', month);
    return monthLabel;
}
