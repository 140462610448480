export class FileHelper {

    static downloadFile(data: any, fileNameWithExtension: string) {
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = fileNameWithExtension;
      link.click();
      }

}