import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { RequestVerificationListOutput } from '@shared/models/requestVerification/requestVerificationListOutput';
import { RequestVerificationStatusLegend } from '@shared/models/requestVerification/requestVerificationStatusLegend';

@Component({
    selector: 'app-request-verification-status-grid',
    templateUrl: './request-verification-status-grid.component.html',
    styleUrls: ['./request-verification-status-grid.component.scss']
})
export class RequestVerificationStatusGridComponent implements ICellRendererAngularComp {
    requestVerifications: RequestVerificationListOutput[];
    dataList: any[] = [];
    constructor(private _appLocalizationService: AppLocalizationService) {}

    refresh(params: any): boolean {
        return false;
    }

    agInit(params): void {
        this.requestVerifications = params.data?.requestVerifications;
    
        this.requestVerifications?.forEach((rv) => {
            let legend = RequestVerificationStatusLegend.getLegendFromId(rv.status);    
            if (legend) {
                const data = {
                    code: rv.code,
                    type: rv.type,
                    result: rv.result,
                    express: rv.isExpress,
                    status: this._appLocalizationService.l(legend.name),
                    statusIcon: legend.statusIcon,
                    color: legend.id === RequestVerificationStatusLegend.Completed.id ? RequestVerificationStatusLegend.getColorFromResult(rv.result) : legend.color
                };
                this.dataList.push(data);
            }
        });
    }
}
