<div bsModal #notesDetailsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="notesAddModal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form [formGroup]="formGroup" autocomplete="off" novalidate>
                <div class="card-body">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            <h5>{{"Notes" | localize}}</h5>
                        </h4>
                    </div>
                    <div class="m-content">
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label for="userName">{{"CreatedBy" | localize}}</label>
                                <input class="form-control" id="userName" formControlName="userName" type="text"
                                    name="userName" />
                            </div>
                            <div class="form-group col-md-6">
                                <label for="creationOrModificationTime">{{"CreationOrModificationTime" | localize}}</label>
                                <input class="form-control" id="creationOrModificationTime" formControlName="creationOrModificationTime" type="text"
                                    name="creationOrModificationTime" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label for="status">{{"Status" | localize}}</label>
                                <input class="form-control" id="status" formControlName="status" type="text"
                                    name="status" />
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="description">{{"Description" | localize}}</label>
                            <textarea class="form-control" id="description" formControlName="description" type="text"
                                name="description" rows="5"></textarea>
                        </div>
                    </div>
                </div>
            </form>
            <div class="col text-end mt-3 mb-3">
                <a href="javascript:;" (click)="close()" class="btn btn-primary">
                    <i class="fa fa-times"></i>
                    {{'Close' | localize}}
                </a>
            </div>
        </div>
    </div>
</div>
