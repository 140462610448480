import { ValidatorFn, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export const multipleEmailAddressValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    /**
     * ? This regular expression matches one or more email addresses separated by semicolons or periods, with each email address consisting of a username and a domain name (including a top-level domain).
     * Matching results:
     * * example.email@test.com
     * * example.email@test.co.uk;another.email@test.com
     * * my.email123@test.com;another.email@test.net;third.email@test.org
     *
     * NOT Matching results:
     * * example.email@test (missing top-level domain)
     * * example.email@test..com (double period in domain)
     * * example.email@.test.com (leading period in domain)
     * * example.email@test;another.email@test.com (semicolon instead of period for delimiter)
     * * email@test.com;another.email@test.com. (trailing delimiter)
     */
    const regex = /^([\w-.]+@[\w-.]+.[a-zA-Z]{2,5})([;.][\w-.]+@[\w-.]+.[a-zA-Z]{2,5})*$/gm;

    const isValidEmail = regex.test(control.value);
    return isValidEmail ? null : { BadEmailAddress: true };
};
