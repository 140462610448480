import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'grid-pagination',
    templateUrl: './grid-pagination-component.html'
})
export class GridPaginationComponent implements OnInit {
    @Output() sizeValue = new EventEmitter();

    formGroup = new UntypedFormGroup({
        pageSize: new UntypedFormControl({ value: '' })
    });

    ngOnInit() {
        this.formGroup.controls['pageSize'].setValue(20);
    }

    onPageSizeChanged() {
        const optionValue = this.formGroup.controls['pageSize'].value;
        return this.sizeValue.emit(optionValue);
    }
}
