import { Component, Injector, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AcademicRecordService } from '@shared/services/academicRecord.service';
import { AcademicRecordInput } from '@shared/models/CandidatDetails/academicRecords/academicRecordInput';
import { finalize } from 'rxjs/operators';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { phoneNumberValidator } from '@shared/utils/validation/phoneNumber-validator.directive';

@Component({
    selector: 'academic-record-add',
    templateUrl: './academic-record-add.component.html'
})
export class AcademicRecordAddComponent extends AppComponentBase {
    @ViewChild('academicRecordAddModal') academicRecordAddModal: ModalDirective;
    @Input() candidateId: number;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    formGroup = new UntypedFormGroup({
        institution: new UntypedFormControl('', Validators.required),
        diploma: new UntypedFormControl('', Validators.required),
        year: new UntypedFormControl('', Validators.required),
        permanentCode: new UntypedFormControl(''),
        phoneNumber: new UntypedFormControl('', phoneNumberValidator),
        location: new UntypedFormControl('', Validators.required),
        phoneExtension: new UntypedFormControl('')
    });

    constructor(private injector: Injector, private service: AcademicRecordService, private toastr: ToastrService, private _appLocalizationService: AppLocalizationService) {
        super(injector);
    }

    active = false;
    saving = false;

    show(): void {
        this.active = true;
        this.academicRecordAddModal.show();
    }

    isFormInvalid(): boolean {
        return this.formGroup.invalid;
    }

    markAllControlsAsTouch() {
        this.formGroup.controls['institution'].markAsTouched();
        this.formGroup.controls['diploma'].markAsTouched();
        this.formGroup.controls['year'].markAsTouched();
        this.formGroup.controls['permanentCode'].markAsTouched();
        this.formGroup.controls['phoneNumber'].markAsTouched();
        this.formGroup.controls['location'].markAsTouched();
        this.formGroup.controls['phoneExtension'].markAsTouched();
    }

    showForNotValidMessage() {
        const title = this._appLocalizationService.l('VerifyAllControlsTitle');
        const message = this._appLocalizationService.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }

    save(): Promise<void> {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }
        this.saving = true;

        const academicRecord: AcademicRecordInput = {
            id: 0,
            publicId: '',
            diploma: this.formGroup.controls['diploma'].value,
            year: this.formGroup.controls['year'].value,
            instutitionName: this.formGroup.controls['institution'].value,
            permanentCode: this.formGroup.controls['permanentCode'].value,
            phoneNumber: this.formGroup.controls['phoneNumber'].value,
            location: this.formGroup.controls['location'].value,
            requestId: null,
            candidatId: this.candidateId,
            phoneExtension: this.formGroup.controls['phoneExtension'].value,
            creationTime: new Date()
        };

        this.service
            .addAcademicRecord(academicRecord)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe({
                next: (value) => {
                    this.notify.info(this.l('SavedSuccessfully'));
                    this.close();
                    this.modalSave.emit(null);
                },
                error: (err) => {
                    this.notify.error('Server error: ' + err.error.result);
                }
            });
    }

    close(): void {
        this.active = false;
        this.formGroup.reset();
        this.academicRecordAddModal.hide();
    }
}
