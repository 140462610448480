import { ColDef, GridApi, ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CandidateListOutput } from '@shared/models/candidat/candidateListOutput';
import { CandidatOutput } from '@shared/models/candidat/candidatOutput';
import { candidatSex } from '@shared/models/candidat/candidatSex';
import { RequestOutput } from '@shared/models/request/requestOutput';
import { BehaviorSubject } from 'rxjs';
import { WizzardRequestCreatorComponent } from '../wizzard-request/wizzard-request.component';
import { emailAddressValidator } from '@shared/utils/validation/email-validator.directive';
import { phoneNumberValidator } from '@shared/utils/validation/phoneNumber-validator.directive';
import { RequiredFieldsForCodes } from '@shared/utils/verificationsEnumAndFunctions';
import { CandidatsService } from '@shared/services/candidats.service';
import { socialInsuranceNumberValidators } from '@shared/utils/validation/NAS-validator';
@Component({
    selector: 'request-wizard-candidate-select',
    templateUrl: './request-wizard-candidate-select.component.html',
    styleUrls: ['./request-wizard-candidate-select.component.scss'],
    animations: [appModuleAnimation()],
    providers: [DatePipe]
})
export class RequestWizardCandidateSelectComponent extends AppComponentBase implements OnInit {
    @Input() public request: RequestOutput;
    @Output() public requestChange: EventEmitter<RequestOutput> = new EventEmitter<RequestOutput>();
    @Input() public resetCandidate: BehaviorSubject<boolean>;
    @Input() public resetDriverLicenseRequired: BehaviorSubject<boolean>;
    @Input() public candidateChanged: BehaviorSubject<UntypedFormGroup>;

    public defaultColDef;
    public columnDefs: ColDef[];
    paginationNumberFormatter;
    paginationPageSize = 5;
    selectedCandidate: CandidatOutput = null;
    searchDone = false;
    createCandidate = false;
    candidates: CandidateListOutput[] = [];
    isDriverLicenseRequired = false;
    isEmailRequired = false;
    saving = false;

    form = new UntypedFormGroup({
        firstName: new UntypedFormControl('', Validators.required),
        lastName: new UntypedFormControl('', Validators.required),
        sex: new UntypedFormControl(''),
        birthdate: new UntypedFormControl('')
    });

    candidateform = new UntypedFormGroup({
        email: new UntypedFormControl('', emailAddressValidator),
        otherNames: new UntypedFormControl(''),
        maidenName: new UntypedFormControl(''),
        birthPlace: new UntypedFormControl(''),
        language: new UntypedFormControl(''),
        socialInsuranceNumber: new UntypedFormControl('', {
            validators: socialInsuranceNumberValidators
        }),
        driverLicenceNumber: new UntypedFormControl(''),
        phoneNumber: new UntypedFormControl('', phoneNumberValidator),
        phoneExtension: new UntypedFormControl('')
    });
    private gridApi: GridApi;

    constructor(
        injector: Injector,
        private _appLocalizationService: AppLocalizationService,
        private _candidatService: CandidatsService,
        @Inject(WizzardRequestCreatorComponent)
        public requestWizard: WizzardRequestCreatorComponent,
        private datePipe: DatePipe
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.columnDefs = [
            {
                headerName: this._appLocalizationService.l('Select'),
                checkboxSelection: true,
                width: 125
            },
            {
                headerName: this._appLocalizationService.l('LastName'),
                field: 'lastName',
                width: 200,
                sortable: true,
                filter: true
            },
            {
                headerName: this._appLocalizationService.l('FirstName'),
                field: 'firstName',
                sortable: true,
                filter: true,
                width: 200
            },
            {
                headerName: this._appLocalizationService.l('Birthdate'),
                field: 'birthdate',
                valueFormatter: this.dateFormater.bind(this),
                sortable: true,
                filter: true,
                width: 200
            },
            {
                headerName: this._appLocalizationService.l('Sex'),
                field: 'sex',
                valueFormatter: this.sexGetter.bind(this),
                sortable: true,
                filter: true,
                width: 100
            },
            {
                headerName: this._appLocalizationService.l('CandidateLastRequestDate'),
                field: 'lastRequestDate',
                valueFormatter: this.dateFormater.bind(this),
                sortable: true,
                filter: true,
                width: 200
            },
        ];

        this.defaultColDef = { resizable: true };
        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };
        this.resetDriverLicenseRequired?.subscribe((event) => {
            if (event) {
                this.isDriverLicenseRequired = this.driverLicenseRequired();
                this.isEmailRequired = this.emailRequired();
                this.setDriverLicenseValidators();
                this.setEmailValidators();
            }
        });
    }

    showAddCandidate() {
        return this.form.valid && this.searchDone;
    }

    sexGetter(params: ValueFormatterParams): string {
        return this._appLocalizationService.l(candidatSex[params.value]);
    }

    dateFormater(params: ValueFormatterParams) {
        const paramHasValue: boolean = params.value != null && params.value != undefined;
        return paramHasValue ? this.datePipe.transform(params.value, AppConsts.dateTimeFormatOnlyDate) : '';
    }

    setDriverLicenseValidators() {
        if (this.isDriverLicenseRequired) {
            this.candidateform.controls['driverLicenceNumber'].setValidators(Validators.required);
            this.candidateform.controls['driverLicenceNumber'].markAsTouched();
        }
    }

    setEmailValidators() {
        if (this.isEmailRequired) {
            this.candidateform.controls['email'].setValidators(Validators.required);
            this.candidateform.controls['email'].setValidators(emailAddressValidator);
            this.candidateform.controls['email'].markAsTouched();
            this.candidateform.controls['email'].updateValueAndValidity();
        }
    }

    addCandidate() {
        this.createCandidate = true;
        this.setDriverLicenseValidators();
    }

    onCreateCandidateChange(event) {
        this.createCandidate = event;
    }

    onCandidatesChange(event) {
        this.candidates = event;
    }

    selectCandidate() {
        this.selectedCandidate = this.gridApi.getSelectedRows() === undefined ? null : (this.gridApi.getSelectedRows()[0] as CandidatOutput);
        if (this.selectedCandidate != null) {
            this._candidatService.getCandidatByPublicId(this.selectedCandidate.publicId).subscribe((candidat) => {
                if (candidat) {
                    this.request.candidat = candidat.result;
                }
            });
        } else {
            this.request.candidat = new CandidatOutput();
        }

        this.requestChange.emit(this.request);
    }

    onGridReady(params) {
        this.gridApi = params.api;
        if (screen.availWidth > 414) {
            this.gridApi.sizeColumnsToFit();
        }
    }

    setCompleteRequestVerifications() {
        if (this.requestWizard.verifications === null) {
            return;
        }
        const updatedRequestVerification = [...this.request.requestVerifications].map((rv) => {
            const verification = this.requestWizard.verifications.find((verif) => verif.id === rv.verificationId);
            return {
                ...rv,
                verification: { ...verification },
                verificationPrice: verification?.price || 0
            };
        });
        this.request.requestVerifications = updatedRequestVerification;
    }

    isRequiredCodesIncluded(requiredCodes: string[]): boolean {
        this.setCompleteRequestVerifications();
        return this.request.requestVerifications.filter((requestVerif) => requestVerif.quantity > 0 && requiredCodes.includes(requestVerif.verification?.code)).length > 0;
    }

    driverLicenseRequired() {
        return this.isRequiredCodesIncluded(RequiredFieldsForCodes.getDriverLicenseRequiredCodes());
    }

    emailRequired() {
        return this.isRequiredCodesIncluded(RequiredFieldsForCodes.getEmailRequiredCodes());
    }

    save() {
        this.saving = true;
        this.requestChange.emit(this.request);
        this.requestWizard.save(false);
    }
}
