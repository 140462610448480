import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment as env } from 'environments/environment';

@Injectable()
export class EmailNotificationService {
    private http: HttpClient;
    private baseUrl: string;

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = env.api;
    }

    private getUrl(url: string): string {
        let url_ = this.baseUrl + url;
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    }

    getEmailNotificationsList(): Observable<any> {
        let url_ = this.getUrl('/api/emailNotification');
        let list = this.http.get<any>(url_);
        return list;
    }
}
