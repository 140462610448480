export enum organizationLineOfBusiness {
    OrganizationAeronautics = 0,
    OrganizationFood = 1,
    OrganizationAutomotive = 2,
    OrganizationVentilation = 3,
    OrganizationRetail = 4,
    OrganizationConstruction = 5,
    OrganizationDistribution = 6,
    OrganizationEntertainment = 7,
    OrganizationEducation = 8,
    OrganizationFinance = 9,
    OrganizationHotel = 10,
    OrganizationRealEstate = 11,
    OrganizationIndustrial = 12,
    OrganizationIT = 13,
    OrganizationMaintenance = 14,
    OrganizationMunicipal = 15,
    OrganizationPharmaceutical = 16,
    OrganizationRecruitment = 17,
    OrganizationRetirementHome = 18,
    OrganizationHealth = 19,
    OrganizationFuneral = 20,
    OrganizationMiningSector = 21,
    OrganizationSecurity = 22,
    OrganizationAdministrative = 23,
    OrganizationCateringServices = 24,
    OrganizationSport = 25,
    OrganizationTransport = 26
}
