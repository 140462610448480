import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { VerificationDescriptionInput } from '@shared/models/verificationDescription/verificationDescriptionInput';
import { environment as env } from 'environments/environment';


@Injectable()
export class VerificationDescriptionService {

    private http: HttpClient;
    private baseUrl: string;

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = env.api;
    }

    private getUrl(url: string): string {
        let url_ = this.baseUrl + url;
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    }

    getAllVerificationDescriptions(): Observable<any> {
        let url_ = this.getUrl('/api/VerificationFAQDescription');
        let list = this.http.get<any>(url_);
        return list;
    }

    getVerificationDescriptionsByLanguageId(languageId: string|number): Observable<any> {
        let url_ = this.getUrl('/api/VerificationFAQDescription');
        let params = new HttpParams().set('languageId', languageId);
        return this.http.get<any>(url_, { params });
    }

    getVerificationDescriptionsByVerificationPublicId(publicId: string): Observable<any> {
        let url_ = this.getUrl('/api/VerificationFAQDescription');
        let params = new HttpParams().set('publicId', publicId);
        return this.http.get<any>(url_, { params });
    }

    createVerificationDescription(verificationDescription: VerificationDescriptionInput ): Observable <any> {
        let url_ = this.getUrl('/api/VerificationFAQDescription');
        return this.http.post<any[]>(url_, verificationDescription);
    }

    updateVerificationDescription(verificationDescription: VerificationDescriptionInput): Observable <any> {
        let url_ = this.getUrl('/api/VerificationFAQDescription');
        return this.http.put<any>(url_, verificationDescription);
    }

    deleteVerificationDescription(verificationDescription: VerificationDescriptionInput): Observable <any> {
        let url_ = this.getUrl('/api/VerificationFAQDescription/delete');
        return this.http.post<any>(url_, verificationDescription);
    }
}
