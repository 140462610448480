<ng-container [formGroup]="formGroup">
    <div class="row">
        <div class="form-group col" [ngClass]="{'col-md-6':isCurrent}"  *ngIf="isCurrent || isFormer">
            <label for="type">{{"TypeAddressHistory" | localize}} <span *ngIf="showAsterisk" class="asterix">*</span></label>
            <select name="type" class="form-control" id="type" formControlName="type">
                <option value="">{{"Select" | localize}}</option>
                <option value="0">{{"Former" | localize}}</option>
                <option value="1">{{"Current" | localize}}</option>
            </select>
            <validation-messages [formCtrl]="formGroup.get('type')"></validation-messages>
        </div>
        <div class="form-group col-md-6" *ngIf="isCurrent">
            <label for="fromDate">{{"StartDate" | localize}} {{'FormatDate' | localize}} <span
                class="asterix"></span></label>
            <input id="fromDate" formControlName="fromDate" type="text" name="fromDate" class="form-control"
                bsDatepicker [maxDate]="maxDate"
                [bsConfig]="{ dateInputFormat: 'YYYY/MM/DD', containerClass: 'theme-red',  adaptivePosition: true }">
            <validation-messages [formCtrl]="formGroup.get('fromDate')"></validation-messages>
        </div>
    </div>
    <div class="row" *ngIf="isFormer">
        <div class="form-group col-md-6">
            <label for="startDateBetween">{{("From" ) | localize}}<span class="asterix"></span></label>
            <input id="startDateBetween" formControlName="startDateBetween" type="text" name="startDateBetween"
            class="form-control" bsDatepicker
            [bsConfig]="{ dateInputFormat: 'YYYY/MM/DD', containerClass: 'theme-red',  adaptivePosition: true }"
            (bsValueChange)="onCreationDateStartValueChange($event)">
        </div>
        <div class="form-group col-md-6">
            <label for="endDateBetween">{{("To" ) | localize}}<span class="asterix"></span></label>
            <input id="endDateBetween" formControlName="endDateBetween" type="text" name="endDateBetween"
            class="form-control" bsDatepicker
            [bsConfig]="{ dateInputFormat: 'YYYY/MM/DD', containerClass: 'theme-red',  adaptivePosition: true }"
            [minDate]="minDateTime">
        </div>
    </div>
   
    <div class="m-content">
        <div class="card card-custom mb-10">
            <div [ngClass]="{'card-body': showInCard}">
                <div class="row">
                    <div class="form-group col-md-4">
                        <label for="civicNo">{{"CivicNo" | localize}} <span
                                *ngIf="showAsterisk" class="asterix">*</span></label>
                        <input id="civicNo" formControlName="civicNo" type="number" name="civicNo" 
                            autocomplete="off" class="form-control" maxlength="256" min="0" (focusout)="onFocusOut()">
                        <validation-messages *ngIf="formGroup.get('civicNo')!=undefined"
                            [formCtrl]="formGroup.get('civicNo')"></validation-messages>
                    </div>
                    <div class="form-group col-md-2">
                        <label for="app">{{"Apartment" | localize}}</label>
                        <input id="app" formControlName="app" type="text" name="app"
                            class="form-control" autocomplete="off-app" maxlength="256" (focusout)="onFocusOut()">
                        <validation-messages *ngIf="formGroup.get('app')!=undefined"
                            [formCtrl]="formGroup.get('app')"></validation-messages>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="street">{{"Street" | localize}} <span
                            *ngIf="showAsterisk" class="asterix">*</span></label>
                        <input id="street" formControlName="street" type="text" name="street"
                            autocomplete="off-street" class="form-control" maxlength="256" (focusout)="onFocusOut()">
                        <validation-messages *ngIf="formGroup.get('street')!=undefined"
                            [formCtrl]="formGroup.get('street')"></validation-messages>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6">
                        <label for="country">{{"Country" | localize}} <span
                            *ngIf="showAsterisk" class="asterix">*</span></label>
                        <input id="country" formControlName="countryName" name="country"
                            class="form-control" maxlength="256" [typeahead]="countryList"
                            (typeaheadOnBlur)="countryTypeaheadOnBlur($event)"
                            typeaheadOptionField="countryName" [typeaheadScrollable]="true"
                            [typeaheadOptionsInScrollableView]="5" autocomplete="off-country" (focusout)="onFocusOut()"
                            (typeaheadOnSelect)="countryOnSelect($event)" typeaheadOptionsLimit="300"
                            [typeaheadMinLength]="0">
                            
                        <div class="alert alert-danger" *ngIf="noResultCountry">
                            {{"ResultNotFoundElementInList" | localize}}</div>
                        <validation-messages [formCtrl]="formGroup.get('country')">
                        </validation-messages>
                    </div>
                    <div class="form-group col-md-6" *ngIf="!stateNotRequired">
                        <label for="state">{{"State" | localize}} <span *ngIf="showAsterisk" class="asterix">*</span></label>
                        <input id="state" formControlName="stateName" type="text" name="state"
                            (typeaheadOnBlur)="stateTypeaheadOnBlur($event)" class="form-control"
                            maxlength="256" [typeahead]="statesList" typeaheadOptionField="stateName"
                            [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="5"
                            autocomplete="off-state" [typeaheadLatinize]="true" (focusout)="onFocusOut()"
                            (typeaheadOnBlur)="stateTypeaheadOnBlur($event)"
                            (typeaheadOnSelect)="onSelectState($event)"
                            (typeaheadNoResults)="typeaheadNoResultsStates($event)"
                            typeaheadOptionsLimit="100" [typeaheadMinLength]="0">
                        <div class="alert alert-danger" *ngIf="noResultState">
                            {{"ResultNotFoundElementInList" | localize}}</div>
                        <validation-messages [formCtrl]="formGroup.get('state')">
                        </validation-messages>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6">
                        <label for="city">{{"City" | localize}} <span *ngIf="showAsterisk" class="asterix">*</span></label>
                        <input id="city" formControlName="city" type="text" name="city"
                            autocomplete="off-city" class="form-control" maxlength="256" (focusout)="onFocusOut()">
                        <validation-messages [formCtrl]="formGroup.get('city')">
                        </validation-messages>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="postalCode">{{"PostalCode" | localize}} <span
                            *ngIf="showAsterisk" class="asterix">*</span></label>
                        <input id="postalCode" formControlName="postalCode"
                            autocomplete="off-postalCode" type="text" name="postalCode"
                            class="form-control" oninput="this.value = this.value.toUpperCase()"
                            [mask]="maskOfPostalCodeAddress" [placeholder]="postalCodePlaceholder" [maxlength]="postalCodeMaxLength" (focusout)="onFocusOut()">
                        <validation-messages *ngIf="formGroup.get('postalCode')!=undefined"
                            [formCtrl]="formGroup.get('postalCode')"></validation-messages>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
