import { Component, Injector, NgZone } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'grid-btn-update',
    templateUrl: './grid-btn-update.component.html',
    styleUrls: ['./grid-btn-update.component.scss']
})
export class GridBtnUpdateComponent extends AppComponentBase implements ICellRendererAngularComp {
    params: any;
    private ngZone: NgZone;
    templateIconStyle: boolean;
    preventPropagation: boolean;

    constructor(injector: Injector) {
        super(injector);
        this.ngZone = injector.get(NgZone);
    }

    refresh(params: any): boolean {
        return false;
    }

    agInit(params): void {
        this.params = params;
        this.templateIconStyle = params.templateIconStyle || false;
        this.preventPropagation = params.preventPropagation || false;
    }

    onClick(event: Event): void {
        if (this.preventPropagation) {
            event.stopPropagation();
        }
        this.ngZone.run(() => {
            this.params.action(this.params.data);
        });
    }
}
