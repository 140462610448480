import { Component, EventEmitter, Injector, Output } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { InvoiceService } from '@shared/services/invoice.service';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'wizzard-status-invoice',
    templateUrl: './wizzard-status-invoice.component.html',
    animations: [appModuleAnimation()],
})
export class WizzardStatusInvoiceComponent extends AppComponentBase {
    @Output() sendEmailRequestSelected = new EventEmitter<string>();

    constructor(injector: Injector, private _invoiceService: InvoiceService) {
        super(injector);
    }

    handleRefresh() {
        this.eventService.trigger('app.invoices.generateInvoices');
        this.getInvoiceRequests();
    }

    getInvoiceRequests() {
        this.primengTableHelper.showLoadingIndicator();
        this._invoiceService
            .getInvoiceCalculationRequests()
            .pipe(
                finalize(() => this.primengTableHelper.hideLoadingIndicator())
            )
            .subscribe((response) => {
                this.primengTableHelper.records = response.result;
                this.primengTableHelper.totalRecordsCount =
                    response.result.length;
                this.primengTableHelper.hideLoadingIndicator();
            });
    }

    sendEmail(record: any) {
        this.sendEmailRequestSelected.emit(record);
    }
}
