import { Component, Injector } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ToastrService } from 'ngx-toastr';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'grid-btn-delete',
    templateUrl: './grid-btn-delete.component.html',
    styleUrls: ['./grid-btn-delete.component.scss']
})
export class GridBtnDeleteComponent extends AppComponentBase implements ICellRendererAngularComp {
    params: any;
    templateIconStyle: boolean;
    preventPropagation: boolean;

    constructor(
        injector: Injector,
        private toastr: ToastrService,
    ) {
        super(injector);
    }

    refresh(params: any): boolean {
        return false;
    }

    agInit(params): void {
        this.params = params;
        this.templateIconStyle = params.templateIconStyle;
        this.preventPropagation = params.preventPropagation || false;
    }

    onClick(event: Event): void {
        if (this.preventPropagation) {
            event.stopPropagation();
        }
        this.params.action(this.params.data);
    }

    showSuccess(title: string, successMessage: string) {
        this.toastr.success(title, successMessage);
    }

    showErrorMessage(title: string, errorMessage: string) {
        this.toastr.error(errorMessage, title);
    }
}
