import { Component, Injector, OnInit, Input } from '@angular/core';
import { ProfileServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { ThemesLayoutBaseComponent } from '../themes/themes-layout-base.component';

@Component({
    selector: 'user-menu',
    templateUrl: './user-menu.component.html'
})
export class UserMenuComponent extends ThemesLayoutBaseComponent implements OnInit {
    @Input() iconOnly = false;
    @Input() togglerCssClass = 'btn btn-icon btn-active-light-primary position-relative w-auto h-30px h-md-40px px-5';
    @Input() textCssClass = 'text-dark-50 font-weight-bolder font-size-base d-none d-md-inline me-3';
    @Input() symbolCssClass = 'symbol symbol-lg-30px symbol-20px';
    @Input() symbolTextCssClass = 'symbol-label fs-2 fw-bold bg-success text-inverse-success';

    profilePicture = `/assets/common/images/default-profile-picture.png`;

    mQuickUserOffcanvas: any;

    public constructor(
        injector: Injector,
        private _profileServiceProxy: ProfileServiceProxy,
        public _appAuthService: AppAuthService,
        private _profileService: ProfileServiceProxy,
    ) {
        super(injector);
    }

    get usernameInitial(): string {
        return this._appAuthService.user.given_name.substring(0, 1).toUpperCase();
    }

    get userFullName(): string {
        return `${this._appAuthService.user.family_name} ${this._appAuthService.user.given_name}`;
    }

    ngOnInit(): void {
        this.getProfilePicture();
        this.registerToEvents();
    }

    getProfilePicture(): void {
        this._profileServiceProxy.getProfilePicture().subscribe((result) => {
            if (result && result.profilePicture) {
                this.profilePicture =
                    'data:image/jpeg;base64,' + result.profilePicture;
            } else {
                this.profilePicture =
                    '/assets/common/images/default-profile-picture.png';
            }
        });
    }

    changeProfilePicture(): void {
        this.eventService.trigger('app.show.changeProfilePictureModal');
    }

    changeMySettings(): void {
        this.eventService.trigger('app.show.mySettingsModal');
    }

    registerToEvents() {
        this.subscribeToEvent('profilePictureChanged', () => {
            this.getProfilePicture();
        });
    }

    logout(): void {
        this._appAuthService.logout();
    }

    downloadCollectedData(): void {
        this._profileServiceProxy.prepareCollectedData().subscribe(() => {
            this.message.success(this.l('GdprDataPrepareStartedNotification'));
        });
    }
}
