<div bsModal #verificationDescriptionAddModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="verificationDescriptionAddModal" aria-hidden="true" [config]="{backdrop: 'static', keyboard: !saving}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <form [formGroup]="formGroup" #descriptionAddForm="ngForm" novalidate (ngSubmit)="save()"
        autocomplete="off">
        <div class="modal-header">
          <h4 class="modal-title pull-left">
            <h5>{{"VerificationInformation" | localize}}</h5>
          </h4>
        </div>
        <div class="modal-body">
          <div class="form-group edition-list">
            <label for="language">{{"Language" | localize}} <span class="asterix">*</span></label>
            <select name="language" class="form-control" id="language" formControlName="language">
              <option [ngValue]="null">{{"Select" | localize}}</option>
              <option *ngFor="let lang of languageMiss" [ngValue]="lang.id">{{lang.name}}</option>
            </select>
            <validation-messages [formCtrl]="formGroup.get('language')"></validation-messages>
          </div>
          <div class="form-group">
            <ckeditor *ngIf="editorConfig" [editor]="Editor" [config]="editorConfig" name="description" id="description" formControlName="description"></ckeditor>
            <validation-messages [formCtrl]="formGroup.get('description')"></validation-messages>
          </div>
        </div>
        <div class="modal-footer">
          <button [disabled]="saving" type="button" class="btn btn-default"
            (click)="close()">{{"Cancel" | localize}}</button>
          <button type="submit" class="btn btn-primary" id="savingButton"
            [disabled]="!descriptionAddForm.form.valid" [busyText]="l('SavingWithThreeDot')" [buttonBusy]="saving" (onclick)="save()" ><i
              class="fa fa-save"></i>
            <span>{{"Save" | localize}}</span></button>
        </div>
      </form>
    </div>
  </div>
</div>
