/**
 * no Typings found for html2pdf plugin, this was derived from the html2pdf's Worker's toPdf method ( in \node_modules\html2pdf.js\src\worker.js )
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type html2pdfWorker = {
    prop: {
        canvas: HTMLCanvasElement;
        pageSize: {
            inner: {
                ratio: number;
                height: number;
                width: number;
            };
        };
    };
    opt: {
        margin: [number, number];
        image: {
            type: string;
            quality: number;
        };
    };
    toCanvas: () => html2pdfWorker;
    thenList: (a: (() => unknown)[]) => html2pdfWorker;
    then: (a: () => unknown) => html2pdfWorker;
};

// for worker at the stage that a Canvas has been rendered to (else it gets itself to that stage as a prerequisite), fixes empty pages out of the work Canvas
export function filterBlankPagesOut(this: html2pdfWorker) {
    // Set up function prerequisites.
    const prereqs = [
        function checkCanvas(this: html2pdfWorker) {
            return this.prop.canvas || this.toCanvas();
        }
    ];

    // Fulfill prereqs then consolidate to pages with content
    return this.thenList(prereqs).then(function filterBlankPagesOut_main(this: html2pdfWorker) {
        // Create local copies of frequently used properties.
        const canvas = this.prop.canvas;
        let currentTop = 0;
        // Track the validated vertical image size
        let heightKept = 0;

        // Calculate the current number of pages.
        const pxFullHeight = canvas.height;
        const pxExpectedPageHeight = Math.floor(canvas.width * this.prop.pageSize.inner.ratio);
        const nPages = Math.ceil(pxFullHeight / pxExpectedPageHeight);

        // Define current effective page Height separately so it can be less on the final page.
        let currentPageHeight = pxExpectedPageHeight;

        const width = canvas.width;

        const context = canvas.getContext('2d', { willReadFrequently: true });
        const segments: { top: number; bottom: number }[] = [];

        for (let page = 0; page < nPages; page++) {
            if (page === nPages - 1) {
                // Trim the final page to reduce file size.
                const lesserFinalPageHeight = pxFullHeight % pxExpectedPageHeight;
                if (lesserFinalPageHeight) {
                    currentPageHeight = pxFullHeight % pxExpectedPageHeight;
                }
            }
            const imageDataToKeep = findNonBlank(context, 0, currentTop, width, currentPageHeight);
            const nextTop = currentTop + currentPageHeight;
            if (imageDataToKeep) {
                segments.push({ top: currentTop, bottom: nextTop });
                heightKept += currentPageHeight;
            }
            currentTop = nextTop;
        }

        if (heightKept !== pxFullHeight) {
            // replace canvas with canvas whithout blank pages
            const replacementCanvas = document.createElement('canvas');
            replacementCanvas.width = width;
            replacementCanvas.height = heightKept;
            const replacementContext = replacementCanvas.getContext('2d');
            let currentDestinationTop = 0;
            for (let index = 0; index < segments.length; ) {
                const segment = segments[index];
                const top = segment.top;
                let bottom = segment.bottom;
                let nextSegment = segments[++index];
                while (nextSegment?.top === bottom) {
                    bottom = nextSegment.bottom;
                    nextSegment = segments[++index];
                }
                const height = bottom - top;
                replacementContext.drawImage(canvas, 0, top, width, height, currentDestinationTop, 0, width, height);
                currentDestinationTop += height;
            }
            // replace canvas
            this.prop.canvas = replacementCanvas;
        }
    });
}
//  int32, value depends on CPU endianness
const fullAlpha = packInt32(0, 0, 0, 255);

/**
 * @returns page's true if it contains enough non blank
 */
function findNonBlank(context: CanvasRenderingContext2D, left: number, top: number, width: number, height: number): boolean {
    const imageData = context.getImageData(left, top, width, height);
    const pixels32bit = new Int32Array(imageData.data.buffer);

    // the 2 pixel values considered blank, as signed Int32 results and safe to both little and big Endian-ness system context
    const clear = 0; // 00000000 canvas default
    const int32opaqueWhite = -1; // FFFFFFFF white filling
    // eslint-disable-next-line no-bitwise
    const indexOfNotClearNotWhite = pixels32bit.findIndex((pixel) => (pixel & fullAlpha) !== clear && (pixel | fullAlpha) !== int32opaqueWhite);
    if (indexOfNotClearNotWhite === -1) {
        return false;
    }
    const firstProblemValue = pixels32bit[indexOfNotClearNotWhite];
    const nearlyMax = 251;
    const [r, g, b, a] = unpackInt32(firstProblemValue);
    if (a > 1 && (r < nearlyMax || g < nearlyMax || b < nearlyMax)) {
        return true;
    }
    // clear, white, and firstProblemValue considered uninformative, check for at least a second problem value
    // eslint-disable-next-line no-bitwise
    return pixels32bit.some((pixel) => (pixel & fullAlpha) !== clear && (pixel | fullAlpha) !== int32opaqueWhite && pixel !== firstProblemValue);
}

// cpu endianness dependant
function packInt32(r = 0, g = 0, b = 0, a = 0) {
    return new Int32Array(new Uint8ClampedArray([r, g, b, a]).buffer)[0];
}

// cpu endianness dependant
function unpackInt32(int32RGBA) {
    return new Uint8ClampedArray(new Int32Array([int32RGBA]).buffer);
}
