import { PlatformLocation, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { AppConsts } from '@shared/AppConsts';
import { PreEmploiCommonModule } from '@shared/common/common.module';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import {
    API_BASE_URL,
} from '@shared/service-proxies/service-proxies';
import { ServiceProxyModule } from '@shared/service-proxies/service-proxy.module';
import { AppModule } from './app/app.module';
import { RootRoutingModule } from './root-routing.module';
import { RootComponent } from './root.component';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { LocaleMappingService } from '@shared/locale-mapping.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule} from 'ngx-bootstrap/timepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AppStoreModule } from '@app/store/store.module';
import { AuthModule, AuthHttpInterceptor } from '@auth0/auth0-angular';
import { environment as env } from 'environments/environment';
import { EventService } from '@shared/services/event.service';
import { GardiumHttpConfiguration, GardiumHttpInterceptor } from '@app/shared/common/auth/Gardiumhttpinterceptor';
import { DynamicResourcesHelper } from '@shared/helpers/DynamicResourcesHelper';
import { ErrorHandlerService } from '@shared/services/error-handler.service';

export function appInitializerFactory(injector: Injector, platformLocation: PlatformLocation) {
    return () => {
        const appAuthService = injector.get(AppAuthService);
    
        return new Promise<boolean>((resolve, reject) => {
            appAuthService.initializeAsync().then(() => {
                DynamicResourcesHelper.loadResources(() => {
                    initializeAppCssClasses(injector);
                    registerLocales(appAuthService, resolve, reject);
                });
            }).catch((err) => {
                reject(err);
            });
        });
    };
}
function initializeAppCssClasses(injector: Injector) {
    const appUiCustomizationService = injector.get(AppUiCustomizationService);
    appUiCustomizationService.init(AppConsts.theme);

    document.body.className = appUiCustomizationService.getAppModuleBodyClass();
    document.body.setAttribute('style', appUiCustomizationService.getAppModuleBodyStyle());
}

function registerLocales(
    appAuthService: AppAuthService,
    resolve: (value?: boolean | Promise<boolean>) => void,
    reject: any
) {
    appAuthService.user ??= { locale: 'fr' };
    const angularLocale = new LocaleMappingService().map('angular', appAuthService.user.locale);
    import(`/node_modules/@angular/common/locales/${angularLocale}.mjs`).then((module) => {
        registerLocaleData(module.default);
        NgxBootstrapDatePickerConfigService.registerNgxBootstrapDatePickerLocales(appAuthService.user.locale).then((_) => {
            resolve(true);
        });
    }, reject);
}

export function getRemoteServiceBaseUrl(): string {
    return env.api;
}

@NgModule({
    imports: [
        AuthModule.forRoot({
            ...env.auth,
            httpInterceptor: {
                ...env.httpInterceptor,
            },
            cacheLocation: 'localstorage'
        }),
        BrowserModule,
        BrowserAnimationsModule,
        AppModule,
        PreEmploiCommonModule.forRoot(),
        ServiceProxyModule,
        HttpClientModule,
        RootRoutingModule,
        NgxSpinnerModule,
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        TypeaheadModule.forRoot(),
        TabsModule.forRoot(),
        ModalModule.forRoot(),
        AppStoreModule,
    ],
    declarations: [RootComponent],
    providers: [
        GardiumHttpConfiguration,
        AuthHttpInterceptor,
        {
            provide: HTTP_INTERCEPTORS, 
            useClass: GardiumHttpInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: ErrorHandlerService,
        },
        { provide: API_BASE_URL, useFactory: getRemoteServiceBaseUrl },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [Injector, PlatformLocation],
            multi: true,
        },
        EventService
    ],
    bootstrap: [RootComponent],
})
export class RootModule {
}

