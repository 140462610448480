<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid">
        <sub-header [title]="'Organizations' | localize" [description]="'ListOrganizations' | localize">
            <div role="actions">
                <button *ngIf="isGranted('Pages.Management.Organizations.CanSeePrivateInformation')" class="btn btn-secondary" [buttonBusy]="isExcelLoading" (click)="export()">
                    {{ "ExportToExcel" | localize}}
                </button>
                <button *ngIf="isGranted('Pages.Management.Organizations.Create')" (click)="createOrganization()"
                    class="btn btn-primary" data-test-id="organizations-list-new-btn"><i class="fa fa-plus"></i> {{"NewOrganization" | localize}}
                </button>
            </div>
        </sub-header>

        <div [class]="containerClass">
            <div class="card card-custom listContainer">
                <div class="card-body">
                    <list-search></list-search>
                </div>
            </div>
        </div>
    </div>
</div>
