<div bsModal #createFormerAddressModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="createFormerAddressModal" aria-hidden="true" [config]="{backdrop: 'static', keyboard: !saving}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <form [formGroup]="formGroupAddress" *ngIf="active" #addressFormerForm="ngForm" novalidate (ngSubmit)="save()"
        autocomplete="off">
        <div class="card-body">
          <div>
            <div class="address">
              <div class="modal-header">
                <h4 class="modal-title">
                  <h5>{{"AddressFormer" | localize}}</h5>
                </h4>
              </div>
              <app-address-fields [formGroup]="formGroupAddress" operation="create-former"></app-address-fields>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button [disabled]="saving" type="button" class="btn btn-default"
            (click)="close()">{{"Cancel" | localize}}</button>
          <button type="submit" class="btn btn-primary" [disabled]="!addressFormerForm.form.valid" [buttonBusy]="saving"
            [busyText]="l('SavingWithThreeDot')"><i class="fa fa-save"></i>
            <span>{{"Save" | localize}}</span></button>
        </div>
      </form>
    </div>
  </div>
</div>