import { ValidatorFn, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export const creditCardValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    if (control.value !== null) {
        /**
         * ? This regular expression matches a string that contains exactly 16 digits, and nothing else
         * Matching results:
         * * 1234567890123456
         * * 9876543210987654
         * * 0000000000000000
         *
         * NOT Matching results:
         * * 123456789012345 (only 15 digits)
         * * 12345678901234567 (17 digits)
         * * 1a34567890123456 (contains non-digit characters)
         */
        const regExp = /^(\d{16})$/;
        const isValid = regExp.test(control.value);
        return isValid ? null : { BadCreditCard: true };
    }
};
