import { candidatstatus } from './candidatStatus';
import { candidatSex } from './candidatSex';
import { organizationOutput } from '../organization/organizationOutput';
import { AddressHistoryOutput } from '../addresshistory/addressHistoryOutput';
import { CandidatInput } from './candidatInput';
import { candidatLanguage } from './candidatLanguage';

export class CandidatOutput {
    id: number;
    publicId: string;
    firstName: string;
    lastName: string;
    sex: candidatSex;
    language: candidatLanguage;
    birthdate: Date;
    otherNames: string;
    maidenName: string;
    birthPlace: string;
    email: string;
    socialInsuranceNumber: string;
    driverLicenceNumber: string;
    phoneNumber: string;
    status: candidatstatus;
    organization: organizationOutput;
    addressHistories: AddressHistoryOutput[];
    creationTime: Date;
    lastModificationTime: Date;
    phoneExtension: string;

    constructor() {
        this.id = 0;
        this.publicId = '';
        this.firstName = '';
        this.lastName = '';
        this.sex = candidatSex.Other;
        this.language = candidatLanguage.French;
        this.birthdate = null;
        this.otherNames = null;
        this.maidenName = '';
        this.birthPlace = '';
        this.email = '';
        this.socialInsuranceNumber = '';
        this.driverLicenceNumber = '';
        this.phoneNumber = '';
        this.status = candidatstatus.Actif;
        this.organization = new organizationOutput();
        this.addressHistories = [];
        this.creationTime = new Date();
        this.lastModificationTime = new Date();
        this.phoneExtension = '';
    }

    static FromInput(candidate: CandidatInput): CandidatOutput {
        const outputData: CandidatOutput = {
            id: candidate.id,
            publicId: candidate.publicId,
            firstName: candidate.firstName,
            lastName: candidate.lastName,
            sex: candidate.sex,
            language: candidate.language,
            birthdate: candidate.birthdate,
            otherNames: candidate.otherNames,
            maidenName: candidate.maidenName,
            birthPlace: candidate.birthPlace,
            socialInsuranceNumber: candidate.socialInsuranceNumber,
            driverLicenceNumber: candidate.driverLicenceNumber,
            phoneNumber: candidate.phoneNumber,
            phoneExtension: candidate.phoneExtension,
            status: candidate.status,
            organization: candidate.organization,
            email: candidate.email,
            creationTime: candidate.creationTime,
            addressHistories: [],
            lastModificationTime: new Date()
        };

        return outputData;
    }
}


