import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OrganizationCreditCardInput } from '@shared/models/payment/organizationCreditCardInput';
import { OrganizationCreditCardOutput } from '@shared/models/payment/organizationCreditCardOutput';

import { environment as env } from 'environments/environment';

@Injectable()
export class OrganizationCreditCardService {
    private http: HttpClient;
    private baseUrl: string;

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = env.api;
    }

    CreateOrganizationCreditCard(organizationCreditCard: OrganizationCreditCardInput): Observable<OrganizationCreditCardOutput> {
        let url_ = this.getUrl('/api/organizationCreditCard');
        return this.http.post<OrganizationCreditCardOutput>(url_, organizationCreditCard);
    }

    private getUrl(url: string): string {
        let url_ = this.baseUrl + url;
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    }

}
