<div bsModal role="dialog" class="modal-lg">
  <form [formGroup]="formGroupAddress" #addressFormerForm="ngForm" novalidate (ngSubmit)="save()" autocomplete="off">
    <div class="modal-header">
      <h4 class="modal-title pull-left">
        <h5>{{"AddressFormer" | localize}}</h5>
      </h4>
    </div>
    <div class="modal-body">
      <app-address-fields [formGroup]="formGroupAddress" operation="update-former"></app-address-fields>
    </div>
    <div class="modal-footer">
      <button [disabled]="saving" type="button" class="btn btn-default"
        (click)="bsModalRef.hide()">{{"Cancel" | localize}}</button>
      <button type="submit" class="btn btn-primary" [buttonBusy]="saving" [disabled]="!addressFormerForm.form.valid"
        [busyText]="l('SavingWithThreeDot')" (click)="save()"><i class="fa fa-save"></i>
        <span>{{"Save" | localize}}</span></button>
    </div>
  </form>
</div>