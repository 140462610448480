
// hydration.reducer.ts
import { Action, ActionReducer, MetaReducer } from '@ngrx/store';
import * as HydrationActions from '../../actions/hydration/actions';
import { AppState } from '@app/store/state/state';

function isHydrateSuccess(
    action: Action
): action is ReturnType<typeof HydrationActions.hydrateSuccess> {
    return action.type === HydrationActions.hydrateSuccess.type;
}

export const hydrationMetaReducer = (
    reducer: ActionReducer<AppState>
): ActionReducer<AppState> => (state, action) => {
    if (isHydrateSuccess(action)) {
        return action.state;
    } else {
        return reducer(state, action);
    }
};

export const metaReducers: MetaReducer[] = [hydrationMetaReducer];
