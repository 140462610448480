import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, User } from '@auth0/auth0-angular';
import { anonymousRoutes } from './../../../../root-routing.module';
import { interval, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AppAuthService implements OnDestroy {
    public user: User;
    private _token: string;
    canGrantAccessAllOrganization: boolean;
    isAuthenticated: boolean;
    private checkAuthInterval: number = 5 * 60 * 1000; // min * sec * ms
    private authCheckSubscription: Subscription;

    constructor(private _authService: AuthService, private _router: Router) {
        this.startAuthChecker();
    }

    ngOnDestroy(): void {
        this.authCheckSubscription?.unsubscribe();
    }

    async initializeAsync() {
        if (this.isAuthenticated) {
            return { isAuthenticated: this.isAuthenticated, continue: true };
        }
        this.isAuthenticated = await this.checkIsAuthenticated();

        if (!this.isAuthenticated) {
            for (const route of anonymousRoutes) {
                if ('/' + route.path === window.location.pathname) {
                    this.user = {
                        locale: localStorage.getItem('locale') ?? 'fr',
                        'custom-scope': ['ANONYMOUS']
                    };
                    return { isAuthenticated: this.isAuthenticated, continue: true };
                }
            }
            this._authService.loginWithRedirect();
            return { isAuthenticated: this.isAuthenticated, continue: false };
        }
        if (window.location.pathname === '/') {
            this._router.navigateByUrl('/dashboard');
        }
        this.user = await new Promise<User>((resolve, reject) => {
            this._authService.user$.subscribe(user => {
                resolve(user);
            });
        });
        if (localStorage.getItem('locale') == null) {
            localStorage.setItem('locale', this.user.locale ?? 'fr');
        }
        this.user.locale = localStorage.getItem('locale');
        this._token = await new Promise<string>((resolve, reject) => {
            this._authService.getAccessTokenSilently({
                cacheMode: 'on'
            }).subscribe(token => {
                resolve(token);
            });
        });
        this.canGrantAccessAllOrganization = this.user['full_access'];
        return { isAuthenticated: this.isAuthenticated, continue: true };
    }

    async logout(reload?: boolean, returnUrl?: string): Promise<void> {
        this._authService.logout();

        if (reload !== false) {
            location.href = '/';
        }
    }

    getToken(): string {
        return this._token;
    }

    hasPermission(permission: string): boolean {
        return (this.user['custom-scope'] as string[]).includes(permission);
    }

    hasRole(role: string): boolean {
        return this.user['role'].includes(role);
    }

    userCanSendToPPO(): boolean {
        return this.user['role'].includes('AdminGardium')
            || this.user['role'].includes('Admin')
            || this.user['role'].includes('GardiumAgentI')
            || this.user['role'].includes('GardiumAgentII');
    }

    loginWithRedirect() {
        this._authService.loginWithRedirect();
    }

    startAuthChecker() {
        this.authCheckSubscription = interval(this.checkAuthInterval).pipe(
            switchMap(async () => await this.checkIsAuthenticated())
        ).subscribe(isAuthenticated => {
            if (!isAuthenticated) {
                throw new Error('Login required');
            }
        });
    }

    checkIsAuthenticated(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this._authService.isAuthenticated$.subscribe({
                next: isAuthenticated => resolve(isAuthenticated),
                error: err => reject(err instanceof Error ? err : new Error(err))
            });
        });
    }
}
