import { Directive, AfterViewInit, Injector, Input, OnInit } from '@angular/core';
import { AgGridAngular } from '@ag-grid-community/angular';
import { AppLocalizationService } from '../common/localization/app-localization.service';

@Directive({
    selector: '[updateList]'
})

export class GridUpdateListDirective implements AfterViewInit, OnInit {
    @Input('updateList') gridParmeterKey: string;

    constructor(injector: Injector,
        private agGrid: AgGridAngular,
        private _appLocalizationService: AppLocalizationService) {
        // super(injector);

    }

    getContextMenuItems(params) {
        const result = [
            {
                name: this._appLocalizationService.l('SaveState'),
                action: this.saveState.bind(this),
                icon: '<i class="fas fa-bookmark"></i>'
            },
            {
                name: this._appLocalizationService.l('ResetState'),
                action: this.resetState.bind(this),
                icon: '<i class="far fa-bookmark"></i>'
            },
            'separator',
            'copy',
            'copyWithHeaders',
            'paste',
            'separator',
            {
                name: this._appLocalizationService.l('ExportList'),
                // disabled: !this.isGranted('Pages.Administration.Export'),
                subMenu: ['export']
            }
        ];
        return result;
    }

    ngOnInit(): void {
        this.agGrid.getContextMenuItems = this.getContextMenuItems.bind(this);
    }

    ngAfterViewInit(): void {
        this.restoreState();
    }

    getState() {
        if (this.agGrid.gridOptions.columnApi !== null) {
            const stateList = {
                colState: this.agGrid.gridOptions.columnApi.getColumnState(),
                sortState: this.agGrid.gridOptions.api.getSortModel(),
                filterState: this.agGrid.gridOptions.api.getFilterModel()
            };
            return stateList;
        }
    }

    saveState() {
        window.localStorage.setItem(this.gridParmeterKey, JSON.stringify(this.getState()));
    }

    restoreState() {
        const staleList = JSON.parse(window.localStorage.getItem(this.gridParmeterKey));
        if (staleList) {
            this.agGrid.gridOptions.columnApi.setColumnState(staleList.colState);
            this.agGrid.gridOptions.api.setSortModel(staleList.sortState);
            this.agGrid.gridOptions.api.setFilterModel(staleList.setFilterModel);
        }
    }
    resetState() {
        window.localStorage.removeItem(this.gridParmeterKey);
        this.agGrid.gridOptions.columnApi.resetColumnState();
        this.agGrid.gridOptions.columnApi.resetColumnGroupState();
    }

}
