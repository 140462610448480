export enum RequestStatus {
    New = 0,
    Canceled = 1,
    InProgressClient = 2,
    InProgressGardium = 3,
    Completed = 4,
    MissingInformations = 5,
    CantBeCompleted = 6,
    Draft = 7,
    InProgressCustomerEzSign = 8,
    InProgressPpo = 9
}
