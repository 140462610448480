import { NgModule } from '@angular/core';
import { AppSharedModule } from '@app/shared/app-shared.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { RequestVerificationStatusGridComponent } from './request-verification-status-grid.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
    imports: [
        AppSharedModule, 
        UtilsModule,
        SharedModule
    ],
    declarations: [RequestVerificationStatusGridComponent],
    exports: [RequestVerificationStatusGridComponent]
})
export class RequestVerificationStatusGridModule {}