import { appModuleAnimation } from '@shared/animations/routerTransition';
import { DatePipe } from '@angular/common';
import { Component, ViewChild, Injector, Input, OnInit, HostListener } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AgGridAngular } from '@ag-grid-community/angular';
import { NoteOutput } from '@shared/models/note/noteOutput';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { ValueFormatterParams, GridApi } from '@ag-grid-enterprise/all-modules';
import { AppConsts } from '@shared/AppConsts';
import { NoteAddComponent } from '../notes-add/notes-add.component';
import { LazyLoadEvent } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { NotesService } from '@shared/services/note.service';
import { NoteDetailsComponent } from '../notes-details/notes-details.component';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { RequestNoteOutput } from '@shared/models/request/requestNoteOutput';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';

@Component({
    selector: 'notes-list',
    templateUrl: './notes-list.component.html',
    styleUrls: ['./notes-list.component.scss'],
    animations: [appModuleAnimation()],
    providers: [DatePipe],
})
export class NoteListComponent extends AppComponentBase implements OnInit {
    @ViewChild('agGrid') agGrid: AgGridAngular;
    @ViewChild('notesAddModal') notesAddModal: NoteAddComponent;
    @ViewChild('notesDetailsModal') notesDetailsModal: NoteDetailsComponent;
    notesOutput: NoteOutput[];
    @Input() requestId: number;
    @Input() requestPublicId: string;
    @Input() requestStatus: number;

    public defaultColDef;
    private gridApi: GridApi;
    paginationNumberFormatter;
    paginationPageSize = 20;
    rowData: RequestNoteOutput[] = [];
    columnDefs: any;
    publicId: string;
    selectedNote: RequestNoteOutput;
    rowSelection: string;
    showPrivateNote: boolean;

    formGroup = new UntypedFormGroup({
        showPrivateNote: new UntypedFormControl(''),
    });

    constructor(
        injector: Injector,
        private datePipe: DatePipe,
        private _route: ActivatedRoute,
        private service: NotesService,
        private _appLocalizationService: AppLocalizationService,
        private _dateTimeService: DateTimeService
    ) {
        super(injector);
        this.rowSelection = 'single';

        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };
    }

    async ngOnInit() {
        if (this.requestPublicId) {
            this.publicId = this.requestPublicId;
        } else {
            this.publicId = this._route.snapshot.paramMap.get('publicId');
        }
        this.showPrivateNote = this.isGranted('Pages.Management.Requests.PrivateNotes');
        await this.initializeRowData();
        this.columnDefs = [
            {
                headerName: this._appLocalizationService.l('NoteType'),
                resizable: true,
                field: 'noteType',
                width: 160,
            },
            {
                headerName: this._appLocalizationService.l('Status'),
                resizable: true,
                width: 200,
                field: 'status',
            },
            {
                headerName: this._appLocalizationService.l('Description'),
                resizable: true,
                field: 'description',
                width: 425,
            },
            {
                headerName: this._appLocalizationService.l('CreatedBy') + ' / ' + this._appLocalizationService.l('ModifyBy'),
                width: 220,
                resizable: true,
                field: 'author',
            },
            {
                headerName: this._appLocalizationService.l('CreationOrModificationTime'),
                width: 220,
                field: 'date',
                sort: 'desc',
                resizable: true,
                valueFormatter: this.dateFormater.bind(this),
            },
            {
                headerName: this._appLocalizationService.l('Private'),
                field: 'isPrivateNote',
                valueFormatter: (params) => (params.value ? '\u2714' : ''),
                hide: !this.isGranted('Pages.Management.Requests.PrivateNotes'),
                lockVisible: true,
            },
            {
                headerName: this._appLocalizationService.l('Ppo'),
                field: 'isPpoNote',
                valueFormatter: (params) => (params.value ? '\u2714' : ''),
                hide: !this.isGranted('Pages.Management.Requests.PpoNotes'),
                lockVisible: true,
            },
        ];

        this.defaultColDef = { sortable: true, filter: true };
    }

    async initializeRowData() {
        await this.service
            .getNotesForRequest(this.publicId)
            .toPromise()
            .then((result) => {
                if (!this.showPrivateNote) {
                    this.rowData = result.result.filter((x) => !x.isPrivateNote);
                } else {
                    this.rowData = result.result;
                }
            });
    }

    onPageSizeChanged(e) {
        this.agGrid.api.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    dateFormater(params: ValueFormatterParams) {
        return this.datePipe.transform(this._dateTimeService.toUtcDate(params.value).toLocal().toString(), AppConsts.dateTimeFormat);
    }

    addNotes() {
        this.notesAddModal.show();
    }

    refresh(event?: LazyLoadEvent) {
        this.initializeRowData();
    }

    onSelectionChanged() {
        this.selectedNote = this.agGrid.api.getSelectedRows()[0] as RequestNoteOutput;
        if (this.selectedNote) {
            this.notesDetailsModal.note = this.selectedNote;
            this.notesDetailsModal.show();
            this.agGrid.api.deselectAll();
        }
    }

    changeCheckbox() {
        this.showPrivateNote = !this.showPrivateNote;
        this.refresh();
    }
    onGridReady(params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.gridApi) {
            this.gridApi.sizeColumnsToFit();
        }
    }
}
