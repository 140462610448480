import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment as env } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  private http: HttpClient;
  private baseUrl: string;

  constructor(@Inject(HttpClient) http: HttpClient) {
    this.http = http;
    this.baseUrl = `${env.api}/api/files`;
  }

  private getUrl(url: string): string {
    let url_ = this.baseUrl + url;
    url_ = url_.replace(/[?&]$/, '');
    return url_;
  }

  getFiles(isGardium: boolean): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}?isGardium=${isGardium}`);
  }

  getUrlPostFile() {
    return this.baseUrl;
  }

  deleteFile(filePublicId: string, deletedFrom: string): Observable<any> {
    let params = new HttpParams().set('filePublicId', filePublicId).set('deletedFrom',deletedFrom);
    return this.http.delete<any>(this.baseUrl, {params});
  }

  getDownloadFileUrl(filePublicId: string): Observable<any> {
    let url_ = this.getUrl(`/getFile/${filePublicId}`);

    const options: {
      headers?: HttpHeaders,
      observe?: 'body',
      params?: HttpParams,
      reportProgress?: boolean,
      responseType: 'blob',
      withCredentials?: boolean
    } = {
      responseType: 'blob'
    };

    return this.http.get(url_, options);
  }
}
