import { ValidatorFn, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export const phoneNumberValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    if (control.value !== null && control.value !== '') {
        const result = control.value.match('\\D*([0-9]\\d{2})(\\D*)([0-9]\\d{2})(\\D*)(\\d{4})\\D*');
        if (result === null) {
            return { BadPhoneNumber: true };
        } else {
            return null;
        }
    }
};
