<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid">
        <sub-header [title]="'Verifications' | localize" [description]="'VerificationDetails' | localize">
            <div role="actions">
                <button class="btn btn-primary" (click)="onUpdate()"
                    *ngIf="isGranted('Pages.Management.Verifications.Update')">
                    <i class="la la-edit"></i>
                    {{"Edit" | localize}}
                </button>
            </div>
        </sub-header>
        <div [class]="containerClass">
            <div class="row" class="breadCrumbContainer">
                <div class="col pl-0 pr-0">
                    <p-breadcrumb [model]="items"></p-breadcrumb>
                </div>
            </div>
            <form [formGroup]="formGroup" autocomplete="off">
                <div class="card card-custom mb-10">
                    <div class="card-body">
                        <div class="form-group">
                            <label for="status">{{"VerificationStatus" | localize}}</label>
                            <input id="status" formControlName="status" type="text" name="status" class="form-control"
                                maxlength="256">
                        </div>
                        <div class="form-group">
                            <label for="code">{{"VerificationCode" | localize}}</label>
                            <input id="code" formControlName="code" type="text" name="code" class="form-control"
                                maxlength="256">
                        </div>
                        <div class="form-group">
                            <label for="type">{{"VerificationType" | localize}} </label>
                            <input id="type" formControlName="type" type="text" name="type" class="form-control"
                                maxlength="256">
                        </div>
                        <div class="form-group">
                            <label for="price">{{"VerificationPrice" | localize}}</label>
                            <input id="price" formControlName="price" type="text" name="price" class="form-control"
                                maxlength="256">
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-6 col-xs-12">
                                    <div class="d-flex align-items-center"><input id="canBeExpress"
                                            formControlName="canBeExpress" type="checkbox" name="canBeExpress"
                                            class="form-check-input me-2"
                                            data-test-id="verif-update-canBeExpress-input"><label for="canBeExpress"
                                            class="mb-0">{{"VerificationExpressService" | localize}}
                                        </label>
                                        <validation-messages
                                            [formCtrl]="formGroup.get('canBeExpress')"></validation-messages>
                                    </div>
                                </div>
                                <div class="col-6 col-xs-12" *ngIf="formGroup.get('canBeExpress').value">
                                    <label for="expressPrice">{{"VerificationExpressServicePrice" | localize}}</label>
                                    <input id="expressPrice" formControlName="expressPrice" type="text"
                                        name="expressPrice" class="form-control"
                                        data-test-id="verif-update-expressPrice-input">
                                    <validation-messages
                                        [formCtrl]="formGroup.get('expressPrice')"></validation-messages>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="card card-custom mb-10">
                <div class="card-body">
                    <div class="row align-items-center mb-5">
                        <div class="mr-auto col-auto">
                            <h5>{{ 'VerificationInformation' | localize }}</h5>
                        </div>
                        <div class="col mt-3 mt-md-0">
                            <div class="text-end ">
                                <button (click)="onCreateDescription()" class="btn btn-primary"
                                    [hidden]="behaviorBtn">
                                    <i class="fa fa-plus"></i>
                                    {{'New' | localize}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group descriptionVerif" *ngFor="let description of descriptions">
                        <div class="mr-auto col-auto row btnDescription">
                            <h5 class="col">{{getSectionTitle(description.languageId)}}</h5>
                            <div class="col text-end mt-3 mt-md-0" style="padding: initial;">
                                <button class="btn btn-secondary"
                                    (click)="onUpdateDescription(description.languageId)">
                                    <i class="la la-edit"></i>
                                    {{"Edit" | localize}}
                                </button>
                            </div>
                        </div>
                        <div [innerHTML]="changeSizeImgEditor(description.description) | keepHtml"
                            class="description">
                        </div>
                    </div>
                </div>
            </div>
            <verification-description-add #verificationDescriptionAddModal (modalSave)="refresh()">
            </verification-description-add>
            <div class="card card-custom" *ngIf="isGranted('Pages.Management.Files')">
                <div class="card-body">
                    <div class="row align-items-center mb-5">
                        <div class="mr-auto col-auto">
                            <h5>{{ 'File' | localize }}</h5>
                        </div>
                        <div class="col mt-3 mt-md-0">
                            <div class="text-end ">
                                <button (click)="addFile()" class="btn btn-primary">
                                    <i class="fa fa-plus"></i>
                                    {{'Add' | localize}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <grid-pagination class="grid-pagination" (sizeValue)="onPageSizeChanged($event)"></grid-pagination>
                    <ag-grid-angular localizeText #agGrid class="ag-theme-material gardium-small-grid"
                        [rowData]="files" [paginationNumberFormatter]="paginationNumberFormatter"
                        [paginationPageSize]="paginationPageSize" [pagination]="true" [columnDefs]="columnDefs"
                        [getRowNodeId]="getRowNodeId" [suppressCellSelection]="true" (gridReady)="onGridReady($event)">
                    </ag-grid-angular>
                </div>
            </div>
        </div>
    </div>
</div>