import { AppConsts } from "@shared/AppConsts";
import { RequestVerificationStatus } from "./requestVerificationStatus";

interface IRequestVerificationLegend {
    id: number;
    name: string;
    statusIcon: string;
    color: string;
}

export class RequestVerificationStatusLegend {
    static readonly New: IRequestVerificationLegend = {
        id: RequestVerificationStatus.New,
        name: 'New',
        statusIcon: 'fas fa-certificate',
        color: AppConsts.statusColor.new
    };
    static readonly InProgress: IRequestVerificationLegend = {
        id: RequestVerificationStatus.InProgress,
        name: 'InProgress',
        statusIcon: 'fas fa-clipboard-list',
        color: AppConsts.statusColor.inProgress
    };
    static readonly MissingInformations: IRequestVerificationLegend = {
        id: RequestVerificationStatus.MissingInformations,
        name: 'MissingInformations',
        statusIcon: 'fas fa-question',
        color: AppConsts.statusColor.missingInfo
    };
    static readonly CantBeCompleted: IRequestVerificationLegend = {
        id: RequestVerificationStatus.CantBeCompleted,
        name: 'CantBeCompleted',
        statusIcon: 'fas fa-times',
        color: AppConsts.statusColor.cantBeCompletedVerif
    };
    static readonly Canceled: IRequestVerificationLegend = {
        id: RequestVerificationStatus.Canceled,
        name: 'Canceled',
        statusIcon: 'fas fa-stop-circle',
        color: AppConsts.statusColor.cancel
    };
    static readonly Completed: IRequestVerificationLegend = {
        id: RequestVerificationStatus.Completed,
        name: 'Completed',
        statusIcon: 'fas fa-check',
        color: AppConsts.statusColor.completed
    };

    static getColorFromResult(result: any): string {
        switch (result) {
            case 10:
                return AppConsts.statusColor.completedPositive;
            case 11:
                return AppConsts.statusColor.completedNegative;
            case 5:
                return AppConsts.statusColor.completedExcellent;
            case 4:
                return AppConsts.statusColor.completedVeryGood;
            case 3:
                return AppConsts.statusColor.completedGood;
            case 2:
                return AppConsts.statusColor.completedFair;
            case 1:
                return AppConsts.statusColor.completedLow;
            case 0:
                return AppConsts.statusColor.completedNotAvailable;
            case 6:
                return AppConsts.statusColor.completed;
            case 12:
                return AppConsts.statusColor.completedNotCompleted;
            case 9:
                return AppConsts.statusColor.completedInvalid;
            case 14:
                return AppConsts.statusColor.completedConfirmed;
            case 7:
                return AppConsts.statusColor.completedIncomplete;
            case 15:
                return AppConsts.statusColor.furtherCheckRecommended;
            default:
                return AppConsts.statusColor.completed;
        }
    }

    static getLegendFromId(id: number): IRequestVerificationLegend {
        switch (id) {
            case RequestVerificationStatus.New:
                return RequestVerificationStatusLegend.New;
            case RequestVerificationStatus.InProgress:
                return RequestVerificationStatusLegend.InProgress;
            case RequestVerificationStatus.MissingInformations:
                return RequestVerificationStatusLegend.MissingInformations;
            case RequestVerificationStatus.CantBeCompleted:
                return RequestVerificationStatusLegend.CantBeCompleted;
            case RequestVerificationStatus.Canceled:
                return RequestVerificationStatusLegend.Canceled;
            case RequestVerificationStatus.Completed:
                return RequestVerificationStatusLegend.Completed;
        }
    }
}
