import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubheaderModule } from '@app/shared/common/sub-header/subheader.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { FaqListComponent } from './faq-list.component';
import { FormsModule } from '@angular/forms';
import { FaqListRoutingModule } from './faq-list-routing.module';
import { AppSharedModule } from '@app/shared/app-shared.module';

@NgModule({
    imports: [
        SubheaderModule,
        UtilsModule,
        CommonModule,
        FormsModule,
        FaqListRoutingModule,
        AppSharedModule
    ],
    declarations: [
        FaqListComponent,
    ],
    providers: []
})

export class FaqListModule {}