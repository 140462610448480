<div [@routerTransition]>
  <div class="content d-flex flex-column flex-column-fluid">
    <form [formGroup]="updateVerificationForm" autocomplete="off">

      <sub-header [title]="'Verifications' | localize" [description]="'VerificationUpdate' | localize">
        <div role="actions">
          <button (click)="returnToList()" class="btn btn-secondary">
            <i class="fas fa-times"></i>
            {{'Cancel' | localize}}</button>
          <button class="btn btn-danger organizationsVerificationUpdate-btn" (click)="onDelete()">
            <i class="fas fa-trash-alt"></i>
            {{"Delete" | localize}}
          </button>
          <button class="btn btn-primary" (click)="onUpdate()" [buttonBusy]="isOrganizationVerifUpdated">
            <i class="la la-floppy-o"></i>
            {{"Save" | localize}}
          </button>
        </div>
      </sub-header>

      <div [class]="containerClass">

        <div class="row" class="breadCrumbContainer">
          <div class="col pl-0 pr-0">
            <p-breadcrumb [model]="items"></p-breadcrumb>
          </div>
        </div>

        <div class="card card-custom">
          <div class="card-body">
            <div class="form-group">
              <label for="code">{{"VerificationCode" | localize}} *</label>
              <input id="code" formControlName="code" type="text" name="code" class="form-control" maxlength="256">
            </div>
            <div class="form-group">
              <label for="type">{{"VerificationType" | localize}} </label>
              <input id="type" formControlName="type" type="text" name="type" class="form-control" maxlength="256">
            </div>
            <div class="checkbox-list form-group"
              *ngIf="isGranted('Pages.Management.Organizations.VerificationListAcess.VerificationPriceAccess')">
              <label for="fixBasePrice" class="checkbox">
                <input id="fixBasePrice" type="checkbox" name="fixBasePrice" [checked]="fixBasePriceCheck"
                  (change)="fixBasePriceCheck = !fixBasePriceCheck">
                {{"FixBasePrice" | localize}}
                <span></span>
              </label>
            </div>
            <div class="row"
              *ngIf="isGranted('Pages.Management.Organizations.VerificationListAcess.VerificationPriceAccess')">
              <div class="form-group col-md-4">
                <label for="basePrice">{{"VerificationBasePrice" | localize}}</label>
                <input id="basePrice" formControlName="basePrice" type="number" name="basePrice" class="form-control"
                  maxlength="256" readonly (change)="calculateTotal()">
              </div>
              <div class="form-group col-md-6">
                <label for="adjustment">{{"VerificationAdjustment" | localize}} *</label>
                <input id="adjustment" formControlName="adjustment" type="number" name="adjustment" class="form-control"
                  maxlength="256" (change)="calculateTotal()">
                <validation-messages [formCtrl]="updateVerificationForm.get('adjustment')"></validation-messages>
              </div>
              <div class="form-group col-md-2">
                <label for="total">{{"VerificationTotal" | localize}}</label>
                <input id="total" formControlName="total" type="text" name="total" class="form-control" maxlength="256"
                  readonly>
              </div>
            </div>
            <div class="row" *ngIf="verification">
                <div class="col-md-8">
                    <div class=" d-flex align-items-center">
                        <span class="express-badge" [ngClass]="[verification.canBeExpress ? 'express-badge--success' : 'express-badge--danger']">Express</span>
                        <span class="ps-3"><b>{{(verification.canBeExpress ? "Eligible": "NonEligible") | localize}}</b><span class="ps-1">{{ "ToThe" | localize}} {{"VerificationExpressService" | localize}}</span></span>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
