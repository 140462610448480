<div [@routerTransition]>
  <div class="content d-flex flex-column flex-column-fluid">
    <form [formGroup]="addVerificationForm" autocomplete="off">

      <sub-header [title]="'AddTitleVerification' | localize">
        <div role="actions">
          <button (click)="returnToList()" class="btn btn-secondary">
            <i class="fas fa-times"></i>
            {{'Cancel' | localize}}</button>
          <button class="btn btn-primary" (click)="onAddVerification()" [disabled]="isOrganizationVerifCreated">
            <i class="la la-floppy-o"></i>
            {{"Save" | localize}}
          </button>
        </div>
      </sub-header>

      <div [class]="containerClass">

        <div class="row" class="breadCrumbContainer">
          <div class="col pl-0 pr-0">
            <p-breadcrumb [model]="items"></p-breadcrumb>
          </div>
        </div>

        <div class="card card-custom">
          <div class="card-body">
            <div class="form-group">
              <label for="cboCode">{{"VerificationCode" | localize}} *</label>
              <input id="cboCode" formControlName="cboCode" type="text" name="cboCode" class="form-control" [ngClass]="{'disabled': !verificationList?.length}" [typeahead]="verificationList" typeaheadOptionField="code" [typeaheadScrollable]="true"
                [typeaheadOptionsInScrollableView]="15" autocomplete="off" (typeaheadOnSelect)="onSelect($event)"
                [typeaheadMinLength]="0" (typeaheadNoResults)="typeaheadNoResults($event)">
              <div class="alert alert-danger" *ngIf="noResultVerification">
                {{"ResultNotFoundElementInList" | localize}}</div>
              <validation-messages [formCtrl]="addVerificationForm.get('cboCode')"></validation-messages>
            </div>
            <div class="form-group">
              <label for="type">{{"VerificationType" | localize}} </label>
              <input id="type" formControlName="type" type="text" name="type" class="form-control" maxlength="256"
                readonly>
              <validation-messages [formCtrl]="addVerificationForm.get('type')"></validation-messages>
            </div>
            <div class="m-checkbox-list form-group">
              <label for="fixBasePrice" class="m-checkbox">
                <input id="fixBasePrice" type="checkbox" name="fixBasePrice" [checked]="fixBasePriceCheck"
                  (change)="fixBasePriceCheck = !fixBasePriceCheck">
                {{"FixBasePrice" | localize}}
                <span></span>
              </label>
            </div>
            <div class="row">
              <div class="form-group col-md-4">
                <label for="basePrice">{{"VerificationBasePrice" | localize}}</label>
                <input id="basePrice" formControlName="basePrice" type="number" name="basePrice" class="form-control"
                  maxlength="256" readonly (change)="calculateTotal()">

                <validation-messages [formCtrl]="addVerificationForm.get('basePrice')"></validation-messages>
              </div>
              <div class="form-group col-md-6">
                <label for="adjustment">{{"VerificationAdjustment" | localize}} *</label>
                <input id="adjustment" formControlName="adjustment" type="number" name="adjustment" class="form-control"
                  maxlength="256" (change)="calculateTotal()">

                <validation-messages [formCtrl]="addVerificationForm.get('adjustment')"></validation-messages>
              </div>
              <div class="form-group col-md-2">
                <label for="total">{{"VerificationTotal" | localize}}</label>
                <input id="total" formControlName="total" type="text" name="total" class="form-control" maxlength="256"
                  readonly>
                <validation-messages [formCtrl]="addVerificationForm.get('total')"></validation-messages>
              </div>
            </div>
          </div>
        </div>
      </div>

    </form>
  </div>
</div>
