import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { RequestFileInput } from '@shared/models/requestFile/requestFileInput';
import { FileDto } from '@shared/service-proxies/service-proxies';
import { IAjaxResponse } from '@shared/models/ajax-response';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { RequestService } from './requests.service';
import { fileType } from '@shared/models/files/fileType';
import { Observable, Subject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { environment as env } from 'environments/environment';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';

@Injectable()
export class RequestFilesService {
    private baseUrl: string = env.api;
    saving: boolean;

    private onSuccess: Subject<{
        uploader: FileUploader;
        filePublicId: string;
    }> = new Subject<{ uploader: FileUploader; filePublicId: string }>();

    constructor(
        private _service: RequestService,
        private _appAuthService: AppAuthService
    ) { }

    blobToFile = (theBlob: Blob, fileName: string): File => {
        var b: any = theBlob;
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        b.lastModifiedDate = new Date();
        b.name = fileName;

        //Cast to a File() type
        return <File>theBlob;
    };

    getUploader(fileType: fileType, requestId: number): FileUploader {
        let uploader = new FileUploader({
            url: this.getUrl('/api/Files/upload-file'),
        });

        let uploaderOptions: any = {};
        uploaderOptions.autoUpload = false;
        uploaderOptions.authToken = 'Bearer ' + this._appAuthService.getToken();
        uploaderOptions.removeAfterUpload = true;
        uploaderOptions.maxFileSize = AppConsts.maxFileSize;

        uploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };

        uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
            form.append('FileType', fileItem.file.type);
            form.append('FileName', fileItem.file.name);
            form.append('FileToken', this.guid());
        };
        uploader.onSuccessItem = (item, response, status) => {
            const resp = <IAjaxResponse>JSON.parse(response);
            if (resp.success) {
                this.addRequestFile(resp, fileType, requestId, uploader);
            }
        };
        uploader.setOptions(uploaderOptions);

        return uploader;
    }

    addFile(uploader: FileUploader, file: File) {
        uploader.clearQueue();
        uploader.addToQueue([file]);
    }

    save(uploader: FileUploader) {
        this.saving = true;
        uploader.uploadAll();

        return this.getRequestSuccessObservable(uploader);
    }

    private getUrl(url: string): string {
        let url_ = this.baseUrl + url;
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    }

    private guid(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return (`${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`);
    }

    private addRequestFile(
        fileUploadResponse: any,
        fileType: fileType,
        requestId: number,
        uploader: FileUploader
    ) {
        let fileReceived = <FileDto>fileUploadResponse.result.fileDto;
        let requestFile: RequestFileInput = {
            id: 0,
            request: null,
            files: {
                publicId: null,
                extension: fileReceived.fileName.split('.').pop(),
                filesToken: fileReceived.fileToken,
                filesName: fileReceived.fileName,
                type: fileType,
            },
            requestId: requestId,
            fileId: 0,
        };

        this._service.addRequestFile(requestFile).subscribe({
            next: (response) => {
                const requestFile = response.result.result;
                if (requestFile.id !== null) {
                    this.saving = false;
                    const filePublicId = response.result.filePublicId;
                    this.onSuccess.next({ uploader, filePublicId })
                }
                return requestFile;
            },
            error: (err) => {
                this.saving = false;
            },
        });
        return null;
    }

    private getRequestSuccessObservable(uploader: FileUploader): Observable<string> {
        return this.onSuccess.pipe(
            filter((x) => x.uploader === uploader),
            map((x) => x.filePublicId),
            take(1)
        );
    }
}
