import { Component, OnInit, ViewChild, Input, Injector, Inject } from '@angular/core';
import { AgGridAngular } from '@ag-grid-community/angular';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DatePipe } from '@angular/common';
import { AppConsts } from '@shared/AppConsts';
import { FilesOutput } from '@shared/models/files/filesOutput';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GridBtnDeleteComponent } from '@app/shared/grid/grid-btn-delete/grid-btn-delete.component';
import { fileType } from '@shared/models/files/fileType';
import { GridBtnComponent } from '@app/shared/grid/grid-btn/grid-btn.component';
import { Extensions } from '@shared/utils/extensions';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { RequestVerificationFormService } from '@shared/services/requestVerificationForm.service';
import { RequestVerificationFormOutput } from '@shared/models/CandidatDetails/requestVerificationForm/requestVerificationFormOutput';
import { FormType } from '@shared/models/CandidatDetails/requestVerificationForm/formTypes';
import { RequestVerificationFormEmailInput } from '@shared/models/CandidatDetails/requestVerificationForm/requestVerificationFormEmailInput';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ListFormUpdateComponent } from '../list-forms-update/list-forms-update.component';
@Component({
    selector: 'list-forms',
    templateUrl: './list-forms.component.html',
    providers: [DatePipe]
})
export class ListFormsComponent extends AppComponentBase implements OnInit {
    @ViewChild('agGrid') agGrid: AgGridAngular;
    @Input() candidatePublicId: number;
    @Input() candidateName: string;
    @Input() requestPublicId: string;
    @Input() title: string;

    paginationNumberFormatter;
    paginationPageSize = 20;

    filterText = '';
    formList: RequestVerificationFormOutput[] = [];
    selectedRow: RequestVerificationFormOutput;
    bsModalRef: BsModalRef;
    rowSelection: string;
    columnDefs: any;
    organization: FilesOutput;
    fileType: fileType;
    description: string;
    formType: FormType;
    public defaultColDef;
    private gridApi;

    constructor(
        private injector: Injector,
        private modalService: BsModalService,
        @Inject(RequestVerificationFormService) private requestVerificationFormservice: RequestVerificationFormService,
        private _appLocalizationService: AppLocalizationService,
        private datePipe: DatePipe,
        private toastr: ToastrService,
        private _dateTimeService: DateTimeService,
        private _router: Router
    ) {
        super(injector);
        this.rowSelection = 'single';

        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };
    }

    ngOnInit() {
        if (this.requestPublicId) {
            // Load row data.
            this.getAllForms();
        }

        this.columnDefs = [
            {
                headerName: this._appLocalizationService.l('Name'),
                field: 'title',
                sortable: true,
                filter: true,
            },
            {
                headerName: this._appLocalizationService.l('CreationTime'),
                field: 'creationTime',
                sortable: true,
                filter: true,
                valueFormatter: this.dateFormater.bind(this),
                sort: 'desc'
            },
            {
                headerName: this._appLocalizationService.l('LastModification'),
                field: 'modificationTime',
                sortable: true,
                filter: true,
                valueFormatter: this.dateFormater.bind(this)
            },
            {
                headerName: '',
                cellClass: 'text-end',
                cellRendererFramework: GridBtnComponent,
                cellRendererParams: {
                    action: (data) => this.editForm(data),
                    icon: 'fas fa-edit',
                    tooltip: 'Edit form'
                }
            },
            {
                headerName: '',
                cellClass: 'text-end',
                cellRendererFramework: GridBtnComponent,
                cellRendererParams: {
                    action: (data) => this.updateFormEmail(data),
                    icon: 'fas fa-envelope',
                    tooltip: 'Send email'
                }
            },
            {
                headerName: '',
                cellClass: 'text-end',
                cellRendererFramework: GridBtnDeleteComponent,
                cellRendererParams: {
                    action: (data) => this.deleteForm(data),
                }
            }
        ];

        this.defaultColDef = { resizable: true, sortable: true, filter: true };
    }

    onPageSizeChanged(e) {
        this.agGrid.api.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    getAllForms() {
        this.description = this._appLocalizationService.l('FormListDesc');
        this.requestVerificationFormservice.getFormList(this.requestPublicId)
            .subscribe((response) => {
                this.formList = response.result;
            });
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    dateFormater(params: ValueFormatterParams) {
        return this.datePipe.transform(this._dateTimeService.toUtcDate(params.value).toLocal().toString(), AppConsts.dateTimeFormat);
    }

    onSelectionChanged() {
        this.selectedRow = this.agGrid.api.getSelectedRows()[0] as RequestVerificationFormOutput;
    }

    editForm(data) {
        const queryParams: any = {
            formId: data.formId,
            candidatePublicId: this.candidatePublicId
        };
        const page = data.type.toString();
        const url = this._router.createUrlTree(['/forms/' + page], { queryParams }).toString();
        window.open(url, '_blank');
    }

    deleteForm(data): void {
        this.message.confirm(this.l('FileDeleteQuestion', data.filesName), this.l('AreYouSure'), (isConfirmed) => {
            if (isConfirmed) {
                this.requestVerificationFormservice.deleteForm(data.formId)
                    .subscribe((response) => {
                        this.notify.info(this.l('FileDeleteTitle'));
                        this.getAllForms();
                    });
            }
        }, {
            confirmButtonText: this.l('Yes'),
            cancelButtonText: this.l('No')
        });
    }

    refresh() {
        this.filterText = '';
        this.agGrid.api.setQuickFilter('');
    }

    search() {
        const filterWithoutAccents = Extensions.replaceAccentedCharacters(this.filterText);
        this.agGrid.api.setQuickFilter(filterWithoutAccents);
    }

    updateFormEmail = async (input: RequestVerificationFormOutput) => {
        const emailInput: RequestVerificationFormEmailInput = {
            formId: input.formId,
            email: input.email,
            formType: input.type,
            candidatePublicId: this.candidatePublicId,
            requestPublicId: this.requestPublicId,
            candidateName: this.candidateName
        };

        this.bsModalRef = this.modalService.show(
            ListFormUpdateComponent,
            {
                initialState: {
                    selectedForm: emailInput,
                },
                backdrop: 'static',
            }
        );
        this.bsModalRef.content.modalSave.subscribe((result) => {
            // Update email row with inputted email in the modal.
            this.selectedRow.email = result;
            this.refresh();
        });
    };

    showErrorMessage(title: string, errorMessage: string) {
        title = this._appLocalizationService.l(title);
        errorMessage = this._appLocalizationService.l(errorMessage);
        this.toastr.error(errorMessage, title);
    }

    showSuccess(title: string, successMessage: string) {
        title = this._appLocalizationService.l(title);
        successMessage = this._appLocalizationService.l(successMessage);
        this.toastr.success(successMessage, title);
    }
}
