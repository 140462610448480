import { Injector, Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { environment as env } from 'environments/environment';
import { ToggleOptions } from '@metronic/app/core/_base/layout/directives/toggle.directive';
import { OffcanvasOptions } from '@metronic/app/core/_base/layout/directives/offcanvas.directive';
import { Router } from '@angular/router';

@Component({
    templateUrl: './default-layout.component.html',
    selector: 'default-layout',
    animations: [ appModuleAnimation() ],
})
export class DefaultLayoutComponent extends ThemesLayoutBaseComponent implements OnInit {    
    menuCanvasOptions: OffcanvasOptions = {
        baseClass: 'aside',
        overlay: true,
        closeBy: 'kt_aside_close_btn',
        toggleBy: 'kt_aside_mobile_toggle'
    };

    userMenuToggleOptions: ToggleOptions = {
        target: this.document.body,
        targetState: 'topbar-mobile-on',
        toggleState: 'active'
    };

    remoteServiceBaseUrl: string = env.api;

    constructor(
        injector: Injector,
        @Inject(DOCUMENT) private document: Document,
        private router: Router) {
        super(injector);
    }

    ngOnInit() {
        this.installationMode = false;
    }
    
    contactUsMailTo() {        
        let mailRecipient  = 'preemploi@gardium.com';
        let mailSubject = encodeURIComponent(this.l('ContactUsMailSubject'));
        let mailTo = `mailto:${mailRecipient}?subject=${mailSubject}`
        window.location.href = mailTo;
    }

    openHelp(){
        this.router.navigate(['tutorials/faq']);
    }
}
