<div bsModal #professionnalRefenceAddModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="professionnalRefenceAddModal" aria-hidden="true" [config]="{backdrop: 'static', keyboard: !saving}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <form [formGroup]="formGroup" (ngSubmit)="save()" autocomplete="off" novalidate>
        <div class="card-body">
          <div class="modal-header">
            <h4 class="modal-title">
              <h5>{{"ProfessionnalRefences" | localize}}</h5>
            </h4>
          </div>
          <div class="m-content">
            <div class="row">
              <div class="alert alert-warning">
                <strong>{{"Warning" | localize}}!</strong> {{"warningProfessionalReference" | localize}}
              </div>
            </div>
            <div class="form-group">
              <label for="company">{{"Company" | localize}} <span class="asterix">*</span></label>
              <input id="company" formControlName="company" type="text" name="company" class="form-control"
                maxlength="256">
              <validation-messages [formCtrl]="formGroup.get('company')"></validation-messages>
            </div>
            <div class="form-group">
              <label for="position">{{"Position" | localize}} <span class="asterix"></span></label>
              <input id="position" formControlName="position" type="text" name="position" class="form-control"
                maxlength="256">
              <validation-messages [formCtrl]="formGroup.get('position')"></validation-messages>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label for="startDateBetween">{{("From" ) | localize}}<span class="asterix"></span></label>
                <input id="startDateBetween" formControlName="startDateBetween" type="text" name="startDateBetween"
                  class="form-control" bsDatepicker
                  [bsConfig]="{ dateInputFormat: 'YYYY/MM/DD', containerClass: 'theme-red',  adaptivePosition: true }"
                  (bsValueChange)="onCreationDateStartValueChange($event)">
              </div>
              <div class="form-group col-md-6">
                <label for="endDateBetween">{{("To" ) | localize}}<span class="asterix"></span></label>
                <input id="endDateBetween" formControlName="endDateBetween" type="text" name="endDateBetween"
                  class="form-control" bsDatepicker
                  [bsConfig]="{ dateInputFormat: 'YYYY/MM/DD', containerClass: 'theme-red',  adaptivePosition: true }"
                  [minDate]="minDateTime">
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label for="name">{{"Name" | localize}} <span class="asterix">*</span></label>
                <input id="name" formControlName="name" type="text" name="name" class="form-control" maxlength="256">
                <validation-messages [formCtrl]="formGroup.get('name')"></validation-messages>
              </div>
              <div class="form-group col-md-6">
                <label for="title">{{"Title" | localize}} <span class="asterix"></span></label>
                <input id="title" formControlName="title" type="text" name="title" class="form-control" maxlength="256">
                <validation-messages [formCtrl]="formGroup.get('title')"></validation-messages>
              </div>
            </div>
            <div class="form-group">
              <label for="email">{{"EmailAddress" | localize}}</label>
              <input id="email" formControlName="email" type="text" name="email" class="form-control" maxlength="256">
              <validation-messages [formCtrl]="formGroup.get('email')"></validation-messages>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label for="phoneNumber">{{"PhoneNumber" | localize }} <span class="asterix">*</span></label>
                <input id="phoneNumber" formControlName="phoneNumber" type="text" name="phoneNumber"
                  Class="form-control" maxlength="256" placeholder="+1(999) 999-9999" mask="(000) 000-0000"
                  prefix="+ 1 " [dropSpecialCharacters]="false" [specialCharacters]="[ '+', '1', '(' , ')' , ' ', '-' ]"
                  [showMaskTyped]="true" />
                <validation-messages [formCtrl]="formGroup.get('phoneNumber')">
                </validation-messages>
              </div>
              <div class="form-group col-md-6">
                <label for="phoneExtension">{{"PhoneNumberExtension" | localize}}</label>
                <input id="phoneExtension" formControlName="phoneExtension" type="number" name="phoneExtension"
                  class="form-control" maxlength="256" min="0">
                <validation-messages [formCtrl]="formGroup.get('phoneExtension')"></validation-messages>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button [disabled]="saving" type="button" class="btn btn-default"
              (click)="close()">{{"Cancel" | localize}}</button>
            <button type="submit" class="btn btn-primary" [disabled]="!formGroup.valid" [buttonBusy]="saving"
              [busyText]="l('SavingWithThreeDot')"><i class="fa fa-save"></i>
              <span>{{"Save" | localize}}</span></button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>