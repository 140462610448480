import { Component, OnInit, EventEmitter, Output, Input, ViewChild, Injector, Inject } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ModalDirective, BsModalRef } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/common/app-component-base';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { RequestVerificationFormService } from '@shared/services/requestVerificationForm.service';
import { RequestVerificationFormEmailInput } from '@shared/models/CandidatDetails/requestVerificationForm/requestVerificationFormEmailInput';

@Component({
    selector: 'list-forms-update',
    templateUrl: './list-forms-update.component.html',
    providers: [DatePipe]
})
export class ListFormUpdateComponent extends AppComponentBase implements OnInit {
    @ViewChild('formUpdateModal') modal: ModalDirective;
    @Input() selectedForm: RequestVerificationFormEmailInput;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    publicId: string;
    saving = false;
    active = false;

    formGroup = new UntypedFormGroup({
        email: new UntypedFormControl('', Validators.required),
    });

    constructor(
        public bsModalRef: BsModalRef,
        private injector: Injector,
        @Inject(RequestVerificationFormService) private requestVerificationFormservice: RequestVerificationFormService,
        private toastr: ToastrService,
        private _appLocalizationService: AppLocalizationService
    ) {
        super(injector);
    }

    ngOnInit() {
        if (this.selectedForm !== null && this.selectedForm !== undefined) {
            this.initializeFormToUpdate(this.selectedForm);
        }
    }

    initializeFormToUpdate(selectedForm: RequestVerificationFormEmailInput) {
        this.formGroup.controls['email'].setValue(selectedForm.email);
    }

    isFormInvalid(): boolean {
        return this.formGroup.invalid;
    }

    save(): Promise<void> {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }
        this.saving = true;
        // Take the field value instead of the table data.
        this.selectedForm.email = this.formGroup.controls['email'].value;
        // Send email to inputted email and save it in the process.
        this.requestVerificationFormservice
            .sendEmail(this.selectedForm)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe({
                next: (value) => {
                    this.notify.info(this.l('SavedSuccessfully'));
                    this.close();
                    this.modalSave.emit(this.selectedForm.email);
                },
                error: (err) => {
                    this.notify.error('Server error: ' + err.error.result);
                }
            });
    }

    markAllControlsAsTouch() {
        this.formGroup.controls['email'].markAsTouched();
    }

    showForNotValidMessage() {
        const title = this._appLocalizationService.l('VerifyAllControlsTitle');
        const message = this._appLocalizationService.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }

    close(): void {
        this.active = false;
        this.bsModalRef.hide();
    }
}
