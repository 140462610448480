
<ng-container *ngIf="sectionTitle">
    <hr class="solid mt-0">
    <h1 class="section-title">{{sectionTitle}}</h1>
    <hr class="solid">
</ng-container>

<div class="section-content">
    <ng-content></ng-content>
</div>
