import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment as env } from 'environments/environment';
import { AddressHistoryInput } from '@shared/models/addresshistory/addressHistoryInput';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AddressHistoryService {
    private http: HttpClient;
    private baseUrl: string;

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = env.api;
    }

    private getUrl(url: string): string {
        let url_ = this.baseUrl + url;
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    }

    addAddressHistory(addressHistoryInput: AddressHistoryInput): Observable<any> {
        let url = this.getUrl('/api/Addresshistory');
        let result = this.http.post<AddressHistoryInput>(url, addressHistoryInput);
        return result;
    }

    updateAddressHistory(addressHistoryInput: AddressHistoryInput): Observable<any> {
        let url_ = this.getUrl('/api/Addresshistory');
        return this.http.put<any>(url_, addressHistoryInput);
    }

    getAddressHistoryByRequest(requestPublicId: string): Observable<any> {
        let url_ = this.getUrl(`/api/Addresshistory/GetAddressHistoryByRequest/${requestPublicId}`);
        return this.http.get<any>(url_);
    }

    getAddressHistoryByCandidat(candidatPublicId: string): Observable<any> {
        let url_ = this.getUrl(`/api/Addresshistory/GetAddressHistoryByCandidat/${candidatPublicId}`);
        return this.http.get<any>(url_);
    }

    deleteAddressHistory(addressHistoryInputPublicId: string): Observable<any> {
        let url_ = this.getUrl('/api/Addresshistory/DeleteAddressHistory');
        let params = new HttpParams().set('addressHistoryInputPublicId', addressHistoryInputPublicId);
        return this.http.post<any>(url_, null, { params });
    }

}
