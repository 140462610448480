import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppBsModalModule } from '@shared/common/appBsModal/app-bs-modal.module';
import { ServiceProxyModule } from '@shared/service-proxies/service-proxy.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { FileUploadModule } from 'ng2-file-upload';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule} from 'ngx-bootstrap/timepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { AppCommonModule } from './common/app-common.module';
import { ThemesLayoutBaseComponent } from './layout/themes/themes-layout-base.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { NgxMaskModule } from 'ngx-mask';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { SubheaderModule } from './common/sub-header/subheader.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabViewModule } from 'primeng/tabview';
import { CardModule } from './card/card/card.module';
import { CardContainerModule } from './card/card-container/card-container.module';
import { TreeModule } from 'primeng/tree';
import { DragDropModule } from 'primeng/dragdrop';
import { ContextMenuModule } from 'primeng/contextmenu';
import { EditorModule } from 'primeng/editor';
import { InputMaskModule } from 'primeng/inputmask';
import CountoModule from 'angular2-counto';
import { DropdownModule } from 'primeng/dropdown';
import { CreateOrEditUserModalComponent } from '@app/users/create-or-edit-user-modal.component';
import { PermissionComboComponent } from './permission-combo.component';
import { PermissionTreeModalComponent } from './permission-tree-modal.component';
import { PermissionTreeComponent } from './permission-tree.component';
import { RoleComboComponent } from './role-combo.component';
import { FileUploadModule as PrimeNgFileUploadModule } from 'primeng/fileupload';
import { DirectivesModule } from './directives/directives.module';

const imports = [
    DirectivesModule,
    AppCommonModule,
    ReactiveFormsModule,
    TooltipModule,
    CommonModule,
    TableModule,
    TreeModule,
    DragDropModule,
    ContextMenuModule,
    PaginatorModule,
    AutoCompleteModule,
    EditorModule,
    InputMaskModule,
    CountoModule,
    ImageCropperModule,
    PerfectScrollbarModule,
    DropdownModule,
    AppBsModalModule,
    FileUploadModule,
    PrimeNgFileUploadModule,
    SubheaderModule,
    CardModule,
    CardContainerModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ModalModule,
    TabsModule,
    BsDropdownModule,
    PopoverModule,
    BsDatepickerModule,
    TimepickerModule,
    UtilsModule,
    ServiceProxyModule,
    TableModule,
    ProgressBarModule,
    ImageCropperModule,
    AutoCompleteModule,
    NgxSpinnerModule,
    BreadcrumbModule,
    NgxMaskModule,
    AccordionModule,
    TooltipModule,
    TabViewModule
];

@NgModule({
    imports: [ ...imports ],
    exports: [
        RoleComboComponent,
        PermissionTreeComponent,
        PermissionTreeModalComponent,
        PermissionComboComponent,
        CreateOrEditUserModalComponent,
        ...imports
    ],
    declarations: [
        RoleComboComponent,
        PermissionTreeComponent,
        PermissionTreeModalComponent,
        PermissionComboComponent,
        CreateOrEditUserModalComponent,
        ThemesLayoutBaseComponent
    ],
})
export class AppSharedModule { }
