<div class="[containerClass]">
    <div class="card card-custom mb-10 invoice-display" *ngIf="invoiceDisplay">
        <div class="card-body">
            <div id='content'>
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <img src="/assets/common/images/app-logo-on-light-pdf.svg" alt="app-logo-on-light" />
                        </div>
                        <div class="col-md-4 adress-gardium-position">
                            <span>{{'Invoice_GardiumAddress_FirstLine' | localize}}</span><br>
                            <span>{{'Invoice_GardiumAddress_SecondLine' | localize}}</span><br>
                            <span>{{'Invoice_GardiumAddress_ThirdLine' | localize}}</span>
                        </div>
                    </div>
                    <div class="row" style="height: 35px">
                        <div class="col-md-12 invoice">{{'Invoice_Summary' | localize}}</div>
                    </div>
                    <hr class="hr">
                    <div class="row">
                        <div class="col-md-6 organization">
                            <span class="organization-contact">{{'Invoice_Client' | localize}} </span><br>
                            <span><b>{{invoiceDisplay.invoiceHeader.organizationName}}</b></span><br>
                            <span>{{invoiceDisplay.invoiceHeader.civicNo}},
                                {{invoiceDisplay.invoiceHeader.street}}
                                <span *ngIf="invoiceDisplay.invoiceHeader.app">,
                                    {{invoiceDisplay.invoiceHeader.app}}</span></span><br>
                            <span>{{invoiceDisplay.invoiceHeader.city}},
                                {{invoiceDisplay.invoiceHeader.state}}</span><br>
                            <span>{{invoiceDisplay.invoiceHeader.postalCode}}</span><br>
                            <span><b>{{invoiceDisplay.invoiceHeader.contactName}}</b></span><br>
                            <span class="organization-contact">{{'Invoice_Email' | localize}} </span>
                            <span><b>{{invoiceDisplay.invoiceHeader.invoiceEmail}}</b></span><br>
                        </div>
                        <div class="col-md-6 organization invoice-number-date">
                            <span class="organization-contact">{{'Invoice_InvoiceNumber' | localize}}</span><br>
                            <span><b>{{invoiceDisplay.invoiceHeader.invoiceNumber | mask: "000 000 000"}}</b></span>
                            <br /><br />
                            <span class="organization-contact">{{'Invoice_InvoiceDate' | localize}}</span><br>
                            <span><b>{{invoiceDisplay.invoiceHeader.invoiceDateString}}</b></span>
                        </div>
                    </div>
                    <hr class="hr">
                    <div class="row">
                        <div class="col-md-6 organization">
                            <span class="organization-contact">{{'Invoice_OurFile' | localize}}</span>&nbsp;
                            <span>{{invoiceDisplay.invoiceHeader.clientId}}</span><br>
                            <div *ngIf="isPurchaseOrderAvailable">
                                <span class="organization-contact">{{'Invoice_OrderNumber' | localize}}</span>&nbsp;
                                <span>{{invoiceDisplay.invoiceHeader.purchaseOrder}}</span>
                            </div>
                        </div>
                        <div class="col-md-6 organization invoice-number-date">
                            <span class="organization-contact">{{'Invoice_MonthOf' | localize}}</span>&nbsp;
                            <span>{{monthYearFormat(invoiceDisplay.invoiceHeader.invoiceDate)}}</span>
                        </div>
                    </div>
                    <hr class="hr">
                    <div class="row" style="height: 15px; padding-top: 15px;">
                        <div class="col-md-12 tableTitle"><b>{{'Invoice_VerificationSummary' | localize}}</b></div>
                    </div>
                    <div class="row verification-mobile verificationInformation">
                        <div class="col-md-12 organization" style="font-size: 11px;">
                            <table mdbTable style="width: 100%;">
                                <thead style="width: 100%;">
                                    <tr>
                                        <ng-container *ngFor="let head of headElements; let i = index">
                                            <th scope="col" [style.text-align]="i === 0 ? 'left' : 'center'" style="padding: 10px;">
                                                {{head}}
                                            </th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody *ngIf="invoiceDisplay.invoiceLines">
                                    <tr mdbTableCol *ngFor="let element of invoiceDisplay.invoiceLines">
                                        <th scope="col" scope="row" class="font-name-of-verification"
                                            style="padding-left: 10px; width: 410px;">
                                            {{element.verification.type}}<span class="express" *ngIf="element.expressPrice !== 0">{{' + ' + headElements[1]}}</span></th>
                                        <td style="text-align: center">{{(element.expressPrice).toFixed(2)}} $</td>
                                        <td style="text-align: center">{{element.quantity}}</td>
                                        <td style="text-align: center">{{(element.unitPrice).toFixed(2)}} $</td>
                                        <td style="text-align: center">
                                            {{(element.quantity * (element.unitPrice + element.expressPrice)).toFixed(2)}} $</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div *ngIf="invoiceDisplay.resultInvoiceAdjustments.length > 0">
                        <div class="row" style="margin-top: 30px; font-size: 11px;">
                            <table mdbTable style="width: 100%;">
                                <thead>
                                    <tr>
                                        <th scope="col" scope="col" style="padding-left: 26px">{{'Invoice_Description' |
                                            localize}}</th>
                                        <th scope="col" scope="col" style="padding-left: 190px">{{'Invoice_Adjustment' |
                                            localize}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr mdbTableCol *ngFor="let element of invoiceDisplay.resultInvoiceAdjustments">
                                        <td style="padding-left: 26px">{{element.description}}</td>
                                        <td style="padding-left: 255px">{{(element.adjustment).toFixed(2)}} $</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row totalSection">
                        <div class="position-subTotal">
                            <span class="organization-contact">{{'Invoice_SubTotal' | localize}} </span><span
                                class="align-total-section">
                                {{invoiceDisplay.invoiceTotalSection.subTotal}}
                                $</span>
                            <hr><br>
                            <span class="organization-contact">{{'Invoice_TPS' | localize}} </span><span
                                class="align-total-section">
                                {{invoiceDisplay.invoiceTotalSection.tps}}
                                $</span>
                            <hr><br>
                            <span class="organization-contact">{{'Invoice_TVQ' | localize}} </span><span
                                class="align-total-section">
                                {{invoiceDisplay.invoiceTotalSection.tvq}}
                                $</span>
                            <hr><br>
                            <span class="organization-contact">{{'Invoice_Total' | localize}} </span><span
                                class="align-total-section">
                                {{invoiceDisplay.invoiceTotalSection.total}} $</span>
                            <hr>
                        </div>
                    </div>
                    <div class="row" id="footerPDF">
                        <div class="col-md-2" style="line-height: 5px">
                            <span class="organization-contact" style="font-size: 6px; display: none">{{'Invoice_TPS' |
                                localize}}</span><br>
                            <span class="organization-contact" style="font-size: 6px; display: none">{{'Invoice_TVQ' |
                                localize}}</span>
                        </div>
                        <div class="col-md-8" style="text-align: center; font-size: 10px">
                            <span class="span-footer">{{'Invoice_Payable' | localize}} &#60;&#60;
                                <strong>{{'Invoice_NetThirtyDays' | localize}}</strong>
                                &#62;&#62;.</span><br>
                            <span class="span-footer">{{'Invoice_Interest_FirstLine' | localize}}</span><br>
                            <span class="span-footer">{{'Invoice_Interest_SecondLine' | localize}}
                                <strong>{{'Invoice_Interest_ThirdLine' | localize}}</strong> {{'Invoice_Interest_In' |
                                localize}}</span><br>
                            <span class="span-footer">{{'Invoice_Interest_FourthLine' | localize}}</span><br>
                            <span class="span-footer">{{'Invoice_Footer_FirstLine' | localize}}</span><br>
                            <span class="span-footer">{{'Invoice_Footer_SecondLine' | localize}}</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
