import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubheaderModule } from '@app/shared/common/sub-header/subheader.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { FaqManagementComponent } from './faq-management.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaqDescriptionCreateEditModule } from './modals/description/faq-description-create-edit-modal.module';
import { FaqManagementRoutingModule } from './faq-management-routing.module';
import { AppSharedModule } from '@app/shared/app-shared.module';
import { AgGridModule } from '@ag-grid-community/angular';
import { FaqCategoryCreateEditModule } from './modals/category/faq-category-create-edit-modal.module';


@NgModule({
    imports: [
        SubheaderModule,
        UtilsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FaqDescriptionCreateEditModule,
        FaqCategoryCreateEditModule,
        FaqManagementRoutingModule,
        AppSharedModule,
        AgGridModule
    ],
    declarations: [
        FaqManagementComponent,
    ]
})

export class FaqManagementModule {}