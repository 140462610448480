import { AppComponentBase } from '@shared/common/app-component-base';
import { Component, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { AppConsts } from '@shared/AppConsts';
import { RequestNoteOutput } from '@shared/models/request/requestNoteOutput';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';

@Component({
    selector: 'notes-details',
    templateUrl: './notes-details.component.html',
    providers: [DatePipe]
})
export class NoteDetailsComponent extends AppComponentBase {
    @ViewChild('notesDetailsModal') notesDetailsModal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    note: RequestNoteOutput;

    formGroup = new UntypedFormGroup({
        description: new UntypedFormControl({ value: '', disabled: true }),
        userName: new UntypedFormControl({ value: '', disabled: true }),
        creationOrModificationTime: new UntypedFormControl({
            value: '',
            disabled: true
        }),
        status: new UntypedFormControl({ value: '', disabled: true })
    });
    active = false;
    constructor(injector: Injector, private datePipe: DatePipe, private _dateTimeService: DateTimeService) {
        super(injector);
    }

    show(): void {
        this.active = true;
        this.formGroup.controls['description'].setValue(this.note.description);
        this.formGroup.controls['userName'].setValue(this.note.author);
        this.formGroup.controls['creationOrModificationTime'].setValue(this.dateFormater(this.note.date));
        this.formGroup.controls['status'].setValue(this.note.status);
        this.notesDetailsModal.show();
    }

    dateFormater(params: Date) {
        return this.datePipe.transform(this._dateTimeService.toUtcDate(params).toLocal().toString(), AppConsts.dateTimeFormat);
    }

    close(): void {
        this.active = false;
        this.notesDetailsModal.hide();
    }
}
