import { Component, Injector, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PersonnalReferencesInput } from '@shared/models/CandidatDetails/personnalReferences/personnalReferencesInput';
import { finalize } from 'rxjs/operators';
import { PersonnalReferenceService } from '@shared/services/personnalReference.service';
import { ToastrService } from 'ngx-toastr';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { phoneNumberValidator } from '@shared/utils/validation/phoneNumber-validator.directive';

@Component({
    selector: 'personnal-references-add',
    templateUrl: './personnal-references-add.component.html'
})
export class PersonnalReferencesAddComponent extends AppComponentBase {
    @ViewChild('personnalRefencesAddModal') personnalRefencesAddModal: ModalDirective;
    @Input() candidateId: number;
    @Input() requestId: number;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;

    formGroup = new UntypedFormGroup({
        firstName: new UntypedFormControl('', Validators.required),
        lastName: new UntypedFormControl('', Validators.required),
        email: new UntypedFormControl('', Validators.required),
        relationship: new UntypedFormControl('', Validators.required),
        phoneNumber: new UntypedFormControl('', [Validators.required, phoneNumberValidator]),
        phoneExtension: new UntypedFormControl(''),
        location: new UntypedFormControl('', Validators.required)
    });

    constructor(private injector: Injector, private service: PersonnalReferenceService, private toastr: ToastrService, private _appLocalizationService: AppLocalizationService) {
        super(injector);
    }

    show(): void {
        this.active = true;
        this.personnalRefencesAddModal.show();
    }

    isFormInvalid(): boolean {
        return this.formGroup.invalid;
    }

    markAllControlsAsTouch() {
        this.formGroup.controls['firstName'].markAsTouched();
        this.formGroup.controls['lastName'].markAsTouched();
        this.formGroup.controls['email'].markAsTouched();
        this.formGroup.controls['relationship'].markAsTouched();
        this.formGroup.controls['phoneNumber'].markAsTouched();
        this.formGroup.controls['phoneExtension'].markAsTouched();
        this.formGroup.controls['location'].markAsTouched();
    }

    showForNotValidMessage() {
        const title = this._appLocalizationService.l('VerifyAllControlsTitle');
        const message = this._appLocalizationService.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }

    save(): Promise<void> {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }
        this.saving = true;

        const personnalReference: PersonnalReferencesInput = {
            id: 0,
            publicId: '',
            firstName: this.formGroup.controls['firstName'].value,
            lastName: this.formGroup.controls['lastName'].value,
            email: this.formGroup.controls['email'].value,
            relationship: this.formGroup.controls['relationship'].value,
            phoneNumber: this.formGroup.controls['phoneNumber'].value,
            phoneExtension: this.formGroup.controls['phoneExtension'].value,
            location: this.formGroup.controls['location'].value,
            requestId: this.requestId,
            creationTime: new Date(),
            candidatId: this.candidateId
        };

        this.service
            .addPersonnalReference(personnalReference)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe({
                next: (value) => {
                    this.notify.info(this.l('SavedSuccessfully'));
                    this.close();
                    this.modalSave.emit(null);
                },
                error: (err) => {
                    this.notify.error('Server error: ' + err.error.result);
                }
            });
    }

    close(): void {
        this.active = false;
        this.formGroup.reset();
        this.personnalRefencesAddModal.hide();
    }
}
