import { RequestEvent } from '@shared/models/request-event/request-event.model';
import { VerificationListOutput } from '@shared/models/verification/verificationListOutput';

export interface VerificationsState {
    items: VerificationListOutput[];
    filteredItems: VerificationListOutput[];
    selectedItem?: VerificationListOutput;
    event: RequestEvent;
}

export const verificationsInitialState: VerificationsState = {
    items: [],
    filteredItems: [],
    selectedItem: undefined,
    event: undefined,
};
