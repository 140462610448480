import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Injector, Input, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AddressHistoryInput } from '@shared/models/addresshistory/addressHistoryInput';
import { AddressHistoryType } from '@shared/models/addresshistory/addressHistoryType';
import { RequestOutput } from '@shared/models/request/requestOutput';
import { RequestStatus } from '@shared/models/request/requestStatus';
import { SendingMethodType } from '@shared/models/request/sendingMethodType';
import { RequestVerificationOutput } from '@shared/models/requestVerification/requestVerificationOutput';
import { EmailResult, EmailSenderResult } from '@shared/models/shared/emailSenderResult';
import { VerificationOutput } from '@shared/models/verification/verificationOutput';
import { AddressHistoryService } from '@shared/services/addressHistory.service';
import { RequestService } from '@shared/services/requests.service';
import { RequiredFieldsForCodes } from '@shared/utils/verificationsEnumAndFunctions';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { AddressHistoryComponent } from '../../requests-informations/address-history/address-history-current/address-history-current.component';
import { ListRequestFilesComponent } from '../../requests-informations/request-files/list-request-files/list-request-files.component';
import { SendingMethodComponent } from '../../requests-informations/sending-method/sending-method.component';
import { WizzardRequestCreatorComponent } from '../wizzard-request/wizzard-request.component';

@Component({
    selector: 'request-wizard-informations-files',
    templateUrl: './request-wizard-informations-files.component.html',
    styleUrls: ['./request-wizard-informations-files.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [appModuleAnimation()]
})
export class RequestWizardInformationsFilesComponent extends AppComponentBase implements OnInit {
    @ViewChild('sendingMethod', { static: true }) sendingMethodComponent: SendingMethodComponent;
    @Input() public request: RequestOutput;
    @Output() public requestChange: EventEmitter<RequestOutput> = new EventEmitter<RequestOutput>();
    @Input() public verifications: VerificationOutput[];
    @Input() public resetReportLanguage: BehaviorSubject<boolean>;
    @Input() public requestSaved: BehaviorSubject<boolean>;
    @Input() public candidateEmailSent: string;
    //conserver le courriel pour validation envoi candidat
    @Output() public savedEmail: EventEmitter<any> = new EventEmitter<any>();
    public candidatsAddressLoaded = false;
    public mandatoryFilesLoaded = false;
    public filledFilesLoaded = false;
    public form = new UntypedFormGroup({
        reportLanguage: new UntypedFormControl(''),
        purchaseOrder: new UntypedFormControl(''),
        termsAndAccept: new UntypedFormControl('', Validators.required)
    });

    termsNotChecked = true;
    emailChanged = true;
    currentLanguage: any;
    appConsts = AppConsts;
    loading: boolean;
    IsSendCandidateProcessing: boolean;
    selectedVerifications: RequestVerificationOutput[];
    allowedEzSignVerificationCode: string[] = [
        'CIPC',
        'CIPC+',
        'CRE',
        'AUT',
        'VDJP',
        'PCQ',
        'CSPQ',
        'SAAQ',
        'PMUN',
        'PERS',
        'FACA',
        'FACA+',
        'ASSP',
        'EDPR',
        'LOC',
        'DCQC',
        'DJI',
        'USAL',
        'INTERN',
        'PRO',
        'CEMP',
        'CONF'
    ];
    private mandatoryFiles: ListRequestFilesComponent;
    private candidatsAddress: AddressHistoryComponent;
    private filledFiles: ListRequestFilesComponent;

    constructor(
        injector: Injector,
        public _appLocalizationService: AppLocalizationService,
        @Inject(WizzardRequestCreatorComponent)
        public requestWizard: WizzardRequestCreatorComponent,
        private addressHistoryService: AddressHistoryService,
        private toastr: ToastrService,
        private requestService: RequestService,
        private cdr: ChangeDetectorRef
    ) {
        super(injector);
    }

    @ViewChild('candidatsAddress', { static: false })
    set candidatsAddressContent(content) {
        this.candidatsAddress = content;
        if (this.candidatsAddress) {
            this.candidatsAddressLoaded = true;
        }
    }

    @ViewChild('mandatoryFiles', { static: false }) set mandatoryFilesContent(content) {
        this.mandatoryFiles = content;
        if (this.mandatoryFiles) {
            this.mandatoryFilesLoaded = true;
        }
    }

    @ViewChild('filledFiles', { static: false }) set filledFilesContent(content) {
        this.filledFiles = content;
        if (this.filledFiles) {
            this.filledFilesLoaded = true;
        }
    }

    setReportLanguage() {
        this.form.controls['reportLanguage'].setValue(this.request.reportLanguage);
    }

    ngOnInit(): void {
        this.selectedVerifications = this.request?.requestVerifications ?? [];


        this.requestSaved.subscribe(() => {
            if (this.candidatsAddress && this.candidatsAddress.formGroupAddress.valid) {
                this.saveAddressHistories();
            }
        });

        //Get selected requestVerification
        this.requestService.getRequestByPublicId(this.request.publicId).subscribe({
            next: (response) => {
                this.selectedVerifications = response.result.requestVerifications;
                this.setSendingMethod();
                this.sendingMethodComponent.showLoadingSpinner = false;
                this.cdr.detectChanges();
            },
            error: (err) => {
                this.notify.error('Server error: ' + err.error.error.message);
            }
        });

        this.currentLanguage = this._appLocalizationService.currentLanguage.displayName;
        this.setReportLanguage();
        this.setPurchaseOrder();

        this.resetReportLanguage?.subscribe((event) => {
            if (event) {
                this.setReportLanguage();
            }
        });
    }

    onNavigate(link) {
        window.open(link, '_blank');
    }

    isAddressInvalid(): boolean {
        return this.candidatsAddress?.formGroupAddress?.value?.street === '' || this.candidatsAddress?.formGroupAddress?.invalid;
    }

    setCompleteRequestVerifications() {
        if (this.requestWizard.verifications === null) {
            return;
        }
        const updatedRequestVerification = [ ...this.request.requestVerifications ].map((rv) => {
            const verification = this.requestWizard.verifications.find((verif) => verif?.id === rv?.verificationId);
            return {
                ...rv,
                verification: { ...verification },
                verificationPrice: verification?.price || 0
            };
        });
        this.request.requestVerifications = updatedRequestVerification;
    }

    isRequiredCodesIncluded(requiredCodes: string[]): boolean {
        return this.request.requestVerifications.filter((requestVerif) => requestVerif.quantity > 0 && requiredCodes.includes(requestVerif.verification?.code)).length > 0;
    }

    showCurrentAddress() {
        this.setCompleteRequestVerifications();
        return this.isRequiredCodesIncluded(RequiredFieldsForCodes.getAddressRequiredCodes());
    }

    showAddressHistory() {
        this.setCompleteRequestVerifications();
        return this.isRequiredCodesIncluded(RequiredFieldsForCodes.getMoreThanOneAddressFormerRequiredCodes());
    }

    showPersonnalReferences() {
        this.setCompleteRequestVerifications();
        return this.isRequiredCodesIncluded(RequiredFieldsForCodes.getPersonnalReferencesRequiredCodes());
    }

    showProfessionnalReferences() {
        this.setCompleteRequestVerifications();
        return this.isRequiredCodesIncluded(RequiredFieldsForCodes.getProfessionnalReferencesRequiredCodes());
    }

    showProfessionnalAccreditations() {
        this.setCompleteRequestVerifications();
        return this.isRequiredCodesIncluded(RequiredFieldsForCodes.getProfessionnalAccreditationsRequiredCodes());
    }

    showAcademicRecord() {
        this.setCompleteRequestVerifications();
        return this.isRequiredCodesIncluded(RequiredFieldsForCodes.getAcademicRecordsRequiredCodes());
    }

    showFilesFilled() {
        if (this.mandatoryFilesLoaded) {
            return this.requestWizard.mandatoryFilesNotOptional.length > 0;
        }
        return false;
    }

    isNoneFilesFilled(): boolean {
        return this.filledFiles?.filledFiles.length === 0;
    }

    isAddressEmpty(addressHistory: AddressHistoryInput): boolean {
        if (addressHistory.address.street === '') {
            return true;
        }
        return false;
    }

    additionalInfoMissing() {
        const currentAddressMissing: boolean = this.showCurrentAddress() && this.isAddressInvalid();
        const filledFilesMissing: boolean = this.showFilesFilled() && this.isNoneFilesFilled();
        return currentAddressMissing || filledFilesMissing;
    }

    setRequestReportLanguage() {
        this.request.reportLanguage = this.form.controls['reportLanguage'].value;
    }

    setRequestPurchaseOrder() {
        this.request.purchaseOrder = this.form.controls['purchaseOrder'].value;
    }

    async saveAddressHistories() {
        const addressHistory = this.candidatsAddress?.returnAddressHistory();
        const addressHistoryList = this.request.candidat.addressHistories;

        if (addressHistoryList.length !== 0) {
            addressHistoryList.forEach(async (element) => {
                if (element.type === AddressHistoryType.current) {
                    // this.isCurrent = true;
                    if (addressHistory !== null && element.requestId === null) {
                        addressHistory.id = element.id;
                        addressHistory.publicId = element.publicId;
                        addressHistory.addressId = element.address.id;
                        await this.addressHistoryService
                            .updateAddressHistory(addressHistory)
                            .toPromise()
                            .then((value) => {
                                if (value.id === -1) {
                                    this.requestWizard.showErrorMessage();
                                }
                                return value;
                            });
                    }
                }
            });
        } else {
            if (!this.isAddressEmpty(addressHistory)) {
                await this.addressHistoryService
                    .addAddressHistory(addressHistory)
                    .toPromise()
                    .then((value) => {
                        if (value.id === -1) {
                            this.requestWizard.showErrorMessage();
                        }
                        this.request.candidat.addressHistories.push(value);
                        return value;
                    });
            }
        }
    }

    async save() {
        this.saveAddressHistories();
        this.setRequestReportLanguage();

        this.request.purchaseOrder = this.form.controls['purchaseOrder'].value;

        this.requestChange.emit(this.request);
        this.requestWizard.save(true);
    }

    showEmailSendSuccess() {
        const title = this._appLocalizationService.l('AskForAdditionalInformation');
        const successMessage = this._appLocalizationService.l('EmailSentSuccess');
        this.requestWizard._toastr.success(successMessage, title);
    }

    showErrorSendingEmailMessage() {
        const title = this._appLocalizationService.l('AskForAdditionalInformation');
        const errorMessage = this._appLocalizationService.l('EmailSentError');
        this.requestWizard._toastr.error(errorMessage, title);
    }

    showSuccess(title: string, successMessage: string) {
        title = this._appLocalizationService.l(title);
        successMessage = this._appLocalizationService.l(successMessage);
        this.toastr.success(successMessage, title);
    }

    showErrorMessage(title: string, errorMessage: string) {
        title = this._appLocalizationService.l(title);
        errorMessage = this._appLocalizationService.l(errorMessage);
        this.toastr.error(errorMessage, title);
    }

    getTooltipSendCandidate() {
        if (this.isDraftOrEmailChanged()) {
            return this._appLocalizationService.l('TooltipAskCandidateForInformations');
        }
        return this._appLocalizationService.l('TooltipWait24HoursAskCandidate');
    }

    async sendCandidateFormByEmail() {
        if (!this.loading) {
            this.loading = true;
            this.IsSendCandidateProcessing = true;

            //EzSign method selected
            if (this.isEzSignMethodSelected()) {
                this.requestService.sendEzsignRequest(this.request.publicId).subscribe({
                    next: () => {
                        this.requestWizard.showSuccess();
                        this.requestWizard.goToRequestList();
                    },
                    error: () => {
                        this.requestWizard.showErrorMessage();
                        this.IsSendCandidateProcessing = false;
                        this.loading = false;
                    }
                });
            }
        }
    }

    isDraftOrEmailChanged() {
        return this.requestWizard.isRequestDraft() || this.candidateEmailSent !== this.request.candidat.email;
    }

    checkValue(e: any) {
        const acceptTerms = this.form.controls['termsAndAccept'];
        if (!e.target.checked) {
            acceptTerms.setValidators(Validators.required);
            this.termsNotChecked = true;
        } else {
            acceptTerms.clearValidators();
            this.termsNotChecked = false;
        }
        acceptTerms.updateValueAndValidity();
        if (this.candidateEmailSent != null) {
            if (this.candidateEmailSent === this.request.candidat.email) {
                this.emailChanged = false;
            }
        }
    }

    isFormValid(): boolean {
        return !this.additionalInfoMissing() && !this.form.invalid && !this.loading;
    }

    get formIsValid(): boolean {
        return this.isFormValid();
    }

    isEzSignMethodSelected(): boolean {
        return this.request.sendingMethod === SendingMethodType.EzSign;
    }

    async updateRequestToNew() {
        if (!this.loading) {
            this.loading = true;
            await this.requestService
                .updateRequestToNew(this.request.publicId)
                .toPromise()
                .then((response) => {
                    const result = response.result as EmailSenderResult;
                    if (result.emailSentResult === EmailResult.sent) {
                        this.showSuccess('RequestSentToGardiumTitle', 'RequestSentToGardiumSuccessfully');
                        this.request.status = RequestStatus.New;

                        const date = new Date();
                        const nowUTC = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

                        this.request.creationTime = new Date(nowUTC);
                        this.save();
                        this.requestWizard.wizard.goToNextStep();
                    } else {
                        this.showErrorMessage('RequestSentToGardiumTitle', 'RequestSentToGardiumError');
                        this.loading = false;
                    }
                });
        }
    }

    onSendingMethodChange(value: SendingMethodType) {
        this.request.sendingMethod = Number(value);
    }

    isSendCandidateButtonAvailable(): boolean {
        return this.request.candidat.email === '' 
        || this.form.invalid 
        || !this.isDraftOrEmailChanged() 
        || !this.emailChanged 
        || this.requestWizard.isRequestSendGuardium() 
        || this.loading
    }

    get sendCandidateButtonIsAvailable(): boolean {
        return this.isSendCandidateButtonAvailable();
    }

    private setPurchaseOrder() {
        this.form.controls['purchaseOrder'].setValue(this.request.purchaseOrder);
    }

    private setSendingMethod() {
        const hasEzSignModeleSelected = this.selectedVerifications.find((x) => this.allowedEzSignVerificationCode.includes(x.verification.code));

        //we check if there is an allowed ezSign verification
        if (!hasEzSignModeleSelected) {
            this.sendingMethodComponent.showEzSignMethod = false;
            this.request.sendingMethod = SendingMethodType.Traditionnal;
        }

        this.sendingMethodComponent.sendingMethod = this.request.sendingMethod;
    }
}
