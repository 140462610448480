import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment as env } from 'environments/environment';
import { Observable } from 'rxjs';
import { AcademicRecordInput } from '@shared/models/CandidatDetails/academicRecords/academicRecordInput';
import { AcademicRecordOutput } from '@shared/models/CandidatDetails/academicRecords/academicRecordOutput';

@Injectable({
    providedIn: 'root'
  })

  export class AcademicRecordService {
    private http: HttpClient;
    private baseUrl: string;

    constructor(@Inject(HttpClient) http: HttpClient) {
      this.http = http;
      this.baseUrl = env.api;
    }

    private getUrl(url: string): string {
        let url_ = this.baseUrl + url;
        url_ = url_.replace(/[?&]$/, '');
        return url_;
      }

      addAcademicRecord(academicRecordInput: AcademicRecordInput): Observable<any> {
        let url_ = this.getUrl('/api/academicRecords/AddAcademicRecord');
        return this.http.post<AcademicRecordOutput>(url_, academicRecordInput);
      }

      getAcademicRecordsByRequest(requestPublicId: string): Observable<any> {
        let url_ = this.getUrl(`/api/academicRecords/GetAcademicRecordsByRequest/${requestPublicId}`);
        return this.http.get<any>(url_);
      }

      getAcademicRecordsByCandidat(candidatPublicId: string): Observable<any> {
        let url_ = this.getUrl(`/api/academicRecords/GetAcademicRecordsByCandidat/${candidatPublicId}`);
        return this.http.get<any>(url_);
      }

      updateAcademicRecord(academicRecordInput: AcademicRecordInput): Observable<any> {
        let url_ = this.getUrl('/api/academicRecords/UpdateAcademicRecord');
        return this.http.put<any>(url_, academicRecordInput);
      }

      deleteAcademicRecord(academicRecordInputPublicId: string): Observable<any> {
        let url_ = this.getUrl('/api/academicRecords/DeleteAcademicRecord');
        let params = new HttpParams().set('academicRecordInputPublicId', academicRecordInputPublicId);
        return this.http.post<any>(url_, null, { params });
      }

}
