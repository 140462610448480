import { Component, ViewChild, Injector } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { InvoiceListComponent } from '../invoice-list/invoice-list.component';
import { InvoiceStatusEnum } from '@shared/models/InvoiceElements/invoice/invoiceStatus';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppConsts } from '@shared/AppConsts';
import { DatePipe } from '@angular/common';
import { ExcelExportService } from '@shared/services/excelExport.service';
import { Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { InvoiceListOutput } from '@shared/models/InvoiceElements/invoice/invoiceListOutput';
import { InvoiceDataSourceService } from '@shared/services/invoice-data-source.service';

@Component({
    selector: 'main-invoice-list',
    templateUrl: './main-invoice-list.component.html',
    styleUrls: ['./main-invoice-list.component.scss'],
    animations: [appModuleAnimation()],
    providers: [DatePipe]
})
export class MainInvoiceListComponent extends AppComponentBase {
    @ViewChild('invoiceList') invoiceList: InvoiceListComponent;

    public excelData: InvoiceListOutput[] = [];

    constructor(
        private injector: Injector,
        private _appLocalizationService: AppLocalizationService,
        private datePipe: DatePipe,
        private exportService: ExcelExportService,
        private _router: Router,
        private invoiceDataService: InvoiceDataSourceService
    ) {
        super(injector);
    }

    generateInvoices() {
        this._router.navigate(['/invoice-generator']);
    }

    exportToExcel() {
        const { filterModel, sortModel } = this.invoiceList.getGridFilterAndSort();

        let invoices: InvoiceListOutput[] = [];
        this.invoiceDataService.getExcelWithTaxes(filterModel, sortModel).subscribe((response) => {
            if (response.success && !response.unAuthorizedRequest) {
                invoices = response.result;

                const exporterList = invoices.map((invoice) => {
                    let taxesTps;
                    let taxesTvq;

                    invoice.invoiceTaxes.forEach((invoiceTaxe) => {
                        switch (invoiceTaxe.taxes.code) {
                            case 'TPS':
                                taxesTps = invoiceTaxe.calculatedAmount;
                                break;
                            case 'TVQ':
                                taxesTvq = invoiceTaxe.calculatedAmount;
                                break;
                        }
                    });

                    const invoiceLighter = {
                        [this._appLocalizationService.l('OrganizationName')]: invoice.organizationName,
                        [this._appLocalizationService.l('Organization')]: invoice.clientId,
                        [this._appLocalizationService.l('IdSage')]: invoice.sageId,
                        [this._appLocalizationService.l('IdAccountantCAR')]: invoice.idAccountantCAR,
                        [this._appLocalizationService.l('InvoiceNumber')]: invoice.invoiceNumber,
                        [this._appLocalizationService.l('InvoiceCode')]: invoice.invoiceCode,
                        [this._appLocalizationService.l('SubTotal')]: invoice.subTotal.toString(),
                        [this._appLocalizationService.l('Total')]: invoice.total.toString(),
                        [this._appLocalizationService.l('TPS')]: taxesTps.toString(),
                        [this._appLocalizationService.l('TVQ')]: taxesTvq.toString(),
                        [this._appLocalizationService.l('CreationTime')]: this.datePipe.transform(invoice.creationDate, AppConsts.dateTimeFormat)
                    };
                    return invoiceLighter;
                });
                this.exportService.exportExcel(exporterList, 'invoicesFile');
            }
        });
    }

    dateFormater(params: Date) {
        return this.datePipe.transform(params, AppConsts.dateTimeFormat);
    }

    statusGetter(status: number): string {
        return this._appLocalizationService.l(InvoiceStatusEnum[status]);
    }
}
