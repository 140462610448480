import { Component, EventEmitter, Injector, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import {
    CreateOrUpdateUserInput,
    OrganizationUnitDto,
    ProfileServiceProxy,
    UserEditDto,
    UserRoleDto,
    UserServiceProxy,
} from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { map as _map, filter as _filter } from 'lodash-es';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { OrganizationUserService } from '@shared/services/organizationUser.service';
import { OrganizationService } from '@shared/services/organization.service';
import { ToastrService } from 'ngx-toastr';
import { OrganizationUserInput } from '@shared/models/organizationUser/organizationUserInput';
import { UserTypeEnum } from './userTypeEnum';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';

@Component({
    selector: 'create-or-edit-user-modal',
    templateUrl: './create-or-edit-user-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['create-or-edit-user-modal.component.scss'],
})
export class CreateOrEditUserModalComponent extends AppComponentBase {
    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;
    canChangeUserName = true;

    user: UserEditDto = new UserEditDto();
    roles: UserRoleDto[];
    sendActivationEmail = true;
    sendPasswordChangeEmail = true;
    profilePicture: string;
    organizationsList: [];
    allowedUserNameCharacters = '';
    //new aspnet zero 11.2 variable (isSMTPSettingsProvided) -- we don't use smtp so we just leave this at true
    isSMTPSettingsProvided = true;

    allOrganizationUnits: OrganizationUnitDto[];
    userIdCreator: number;
    memberedOrganizationUnits: string[];
    userPasswordRepeat = '';
    isCreated: boolean;
    userId: number;
    hasOrganization: boolean;
    organizationPublicId: string;

    constructor(
        injector: Injector,
        private _router: Router,
        private _userService: UserServiceProxy,
        private _organizationUserService: OrganizationUserService,
        private _organizationService: OrganizationService,
        private _profileService: ProfileServiceProxy,
        private serviceOrganization: OrganizationService,
        private toastr: ToastrService,
        private _appSessionService: AppAuthService
    ) {
        super(injector);
    }

    show(userId?: number, organizationId?: string): void {
        if (!userId) {
            this.active = true;
            this.sendActivationEmail = true;
            this.sendPasswordChangeEmail = true;
        }

        this.hasOrganization = !!organizationId;
        this.organizationPublicId = organizationId;

        this._userService.getUserForEdit(userId).subscribe((userResult) => {
            this.user = userResult.user;
            this.roles = userResult.roles;
            this.canChangeUserName = this.canEditUsername();
            this.allowedUserNameCharacters = userResult.allowedUserNameCharacters;

            this.allOrganizationUnits = userResult.allOrganizationUnits;
            this.memberedOrganizationUnits = userResult.memberedOrganizationUnits;

            this.getProfilePicture(userId);

            if (userId) {
                // Handle B2B user
                const isB2BUser = this.roles.filter((r) => r.roleName === 'B2BWeb').length > 0;

                // For inactive B2B Users, avoid not setting a password or sending an activation email
                this.active = true;
                this.sendPasswordChangeEmail = false;
                this.sendActivationEmail = isB2BUser && !this.user.isActive;
            }
            this.modal.show();
        });
    }

    canEditUsername() {
        return (
            (this.isGranted('Pages.Administration.Users.Edit') || this.isGranted('Pages.Administration.Users.Create'))
        );
    }

    getProfilePicture(userId: number): void {
        if (!userId) {
            this.profilePicture = '/assets/common/images/default-profile-picture.png';
            return;
        }

        this._profileService.getProfilePictureByUser(userId).subscribe((result) => {
            if (result?.profilePicture) {
                this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
            } else {
                this.profilePicture = '/assets/common/images/default-profile-picture.png';
            }
        });
    }

    onShown(): void {
        document.getElementById('Name').focus();
    }

    async save() {
        const input = new CreateOrUpdateUserInput();

        input.user = this.user;
        input.user.type = UserTypeEnum.User;
        input.sendActivationEmail = this.sendActivationEmail;
        input.sendPasswordChangeEmail = this.sendPasswordChangeEmail;
        input.assignedRoleNames = _map(_filter(this.roles, { isAssigned: true }), (role) => role.roleName);
        this.saving = true;
        // Handle Custom Organization Units in call
        await this._userService
            .createOrUpdateUser(input)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .toPromise()
            .then(() => { });
        if (input.user.id === null) {
            this.isCreated = true;
            await this._organizationUserService
                .getUserIdByUsername(input.user.userName)
                .toPromise()
                .then((value) => {
                    this.userId = value.result;
                });
            if (this.isGranted('Pages.Administration.Users.Organizations')) {
                if (this.hasOrganization) {
                    await this.addUserToOrganization();
                } else if (!this.user.hasAccessAllOrganization) {
                    this._router.navigate(['/users-organizations', this.userId, input.user.userName]);
                }
            } else if (this.hasOrganization) {
                    await this.addUserToOrganization();
            } else {
                await this.serviceOrganization
                    .getLegacyOrganizations()
                    .toPromise()
                    .then(async (LegacyOrgResult) => {
                        const listInput = new Array<OrganizationUserInput>();
                        LegacyOrgResult.result.forEach((element) => {
                            listInput.push({
                                id: 0,
                                userId: this.userId,
                                organizationId: element.id,
                            });
                        });
                        await this.addUsersOrganization(listInput);
                    });
            }

        }
        this.notify.info(this.l('SavedSuccessfully'));
        this.close();
        this.modalSave.emit(null);
    }

    async addUsersOrganization(listInput, moveToListOnSuccess = true) {
        await this._organizationUserService
            .addOrganizationUserList(listInput)
            .toPromise()
            .then((response) => {
                const valueReceived = response.result;
                if (valueReceived.id !== null) {
                    const title = this.l('LinkUserOrganization');
                    const successMessage = this.l(moveToListOnSuccess ? 'LinkedUserOrganization' : 'UserOrganizationCreated');
                    this.showSuccess(title, successMessage);

                    if (moveToListOnSuccess) {
                        this.returnToList();
                    }
                } else {
                    const title = this.l('UserOrganizationLinkIssue');
                    const errorMessage = this.l('UserOrganizationLinkTryAgainLater');
                    this.showErrorMessage(errorMessage, title);
                }
                return valueReceived;
            });
    }

    showSuccess(title: string, successMessage: string) {
        this.toastr.success(title, successMessage);
    }

    showErrorMessage(errorMessage: string, title: string) {
        this.toastr.error(errorMessage, title);
    }

    close(): void {
        this.active = false;
        this.userPasswordRepeat = '';
        this.modal.hide();
    }

    getAssignedRoleCount(): number {
        return _filter(this.roles, { isAssigned: true }).length;
    }

    returnToList() {
        this._router.navigate(['/users']);
    }

    emailChanged() {
        if (this.user.id) {
            return;
        }

        // In creation mode, set email in the userName field
        this.user.userName = this.user.emailAddress;
    }

    shouldDisableActivation() {
        if (this.user.id) {
            return;
        } else {
            return !this.isSMTPSettingsProvided;
        }
    }

    private async addUserToOrganization() {
        const result = await this._organizationService
            .getOrganizationsByPublicId(this.organizationPublicId)
            .toPromise()
            .then((x) => x.result);

        await this.addUsersOrganization(
            [
                {
                    id: 0,
                    userId: this.userId,
                    organizationId: result.id,
                },
            ],
            false
        );
    }
}
