import { Component, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'candidats-list',
    templateUrl: './candidats-list.component.html',
    styleUrls: ['./candidats-list.component.scss'],
    animations: [appModuleAnimation()]
})
export class CandidatsListComponent extends AppComponentBase {
    constructor(private injector: Injector, private _router: Router) {
        super(injector);
    }

    createCandidat() {
        this._router.navigate(['candidats-create']);
    }

    canCreateCandidateAndRequest(): boolean {
        return this.isGranted('Pages.Management.Candidat.Create') && this.isGranted('Pages.Management.Requests.Create');
    }

    createCandidatRequest() {
        this._router.navigate(['candidats-create', { createRequestToo: true }]);
    }
}
