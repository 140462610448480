import { NgModule } from '@angular/core';
import { GridPaginationComponent } from './grid-pagination-component';
import { CommonModule } from '@angular/common';
import { AppSharedModule } from '../app-shared.module';

@NgModule({
    imports: [CommonModule, AppSharedModule],
    exports: [GridPaginationComponent],
    declarations: [GridPaginationComponent],
})
export class GridPaginationModule { }
