import { AppLocalizationService } from "@app/shared/common/localization/app-localization.service";
import { PagedResultDto_1OfUserListDto } from "@shared/service-proxies/service-proxies";
import { BsLocaleService } from "ngx-bootstrap/datepicker";



export enum resultsCodes {

    notAvailable = 0,
    Low = 1,
    Fair = 2,
    Good = 3,
    VeryGood = 4,
    Excellent = 5,
    Completed = 6,
    Incomplete = 7,
    Valid = 8,
    Invalid = 9,
    Positive = 10,
    Negative = 11,
    NotCompleted = 12,
    CompletedValid = 13,
    Confirmed = 14,
    FurtherCheckRecommended = 15

}


export class resultsForVerificationsId  {

    static getResultsOptions(verificationCode : string) : number[]{


        var resultsList : number[] = [];

        switch (verificationCode){
            case "CIPC":
            case "CIPC+":
            resultsList = [resultsCodes.Incomplete,resultsCodes.Negative,resultsCodes.Confirmed, resultsCodes.FurtherCheckRecommended];
            break;
            case "VDJP":
            case "PCQ":
            case "CSPQ":
            case "DJI":
            case "INTER":
            case "PMUN":
                resultsList = [resultsCodes.Positive,resultsCodes.Negative];
                break;
            case "CRE":
                resultsList = [resultsCodes.notAvailable,resultsCodes.Low,resultsCodes.Fair,resultsCodes.Good,resultsCodes.VeryGood,resultsCodes.Excellent];
                break;
            case "SAAQ":
            case "USAL":
                resultsList = [resultsCodes.Valid, resultsCodes.Invalid];
                break;

            case "PRO":
            case "CEMP":
            case "PERS":
            case "FACA":
            case "ASSP":
            case "ATMN":
            case "ATMN+":
            case "EDPR":
            case "AUT":
            case "EDIG":
            case "LOC":
            case "FORFAIT A":
            case "FORFAIT B":
            case "FORFAIT C":
            case "FORFAIT D":
            case "RSM":
            case "ADD":
            case "DCQC":
            case"DC+":
                resultsList = [resultsCodes.Completed,resultsCodes.NotCompleted];
                break;
            case "FACA+":
                resultsList = [resultsCodes.CompletedValid, resultsCodes.NotCompleted];
                break;

        }

        return resultsList;

    }


    static getSelectedResultValue(key : Number){
        let value = "";

        switch (key){
            case 0:
                value = "notAvailable"
                break;
            case 1:
                value = "Low"
                break;
            case 2:
                value = "Fair"
                break;
            case 3:
                value = "Good"
                break;
            case 4:
                value = "VeryGood"
                break;
            case 5:
                value = "Excellent"
                break;
            case 6:
                value = "Completed"
                break;
            case 7:
                value = "Incomplete"
                break;
            case 8:
                value = "Valid"
                break;
            case 9:
                value = "Invalid"
                break;
            case 10:
                value = "Positive"
                break;
            case 11:
                value = "Negative"
                break;
            case 12:
                value = "NotCompleted"
                break;
            case 13:
                value = "CompletedValid"
                break;
            case 14:
                value = "Confirmed"
                break;
            case 15:
                value = "FurtherCheckRecommended"
            break;
        }

        return value;
    }



    static getDetailedResult(key : string){
        let detailedResult = "";

        switch (key){
            case "11CIPC":
            case "11CIPC+":
                detailedResult = "DetailedResultsNegativeCIPC";
                break;
            case "11VDJP":
                detailedResult ="DetailedResultsNegativeVDJP";
                break;
            case "11PCQ":
                detailedResult ="DetailedResultsNegativePCQ";
                break;
            case "11INTER":
                detailedResult ="DetailedResultsNegativeINTER";
                break;
            case "11PMUN":
                detailedResult ="DetailedResultsNegativePMUN";
                break;
            case "11DJI":
                detailedResult ="DetailedResultsNegativeDJI";
                break;
            case "11CSPQ":
                detailedResult ="DetailedResultsNegativeCSPQ";
                break;
            case "5CRE":
                detailedResult="DetailedResultsExcellent"
                break;
            case "4CRE":
                detailedResult="DetailedResultsVeryGood"
                break;
            case "3CRE":
                detailedResult="DetailedResultsGood"
                break;
            case "2CRE":
                detailedResult="DetailedResultsFair"
                break;
            case "1CRE":
                detailedResult="DetailedResultsLow"
                break;
            case "0CRE":
                detailedResult="DetailedResultsNotAvailable"
                break;
            case "14CIPC":
            case "14CIPC+":
                detailedResult = "DetailedResultsConfirmed";
                break;
            case "7CIPC":
            case "7CIPC+":
                detailedResult = "DetailedResultsIncomplete";
                break;
            case "15CIPC":
            case "15CIPC+":
                detailedResult = "DetailedResultsFurtherCheckRecommended";
                break;
            case "6FACA":
            case "13FACA+":
                detailedResult = "DetailedResultsCompleteFACA";
                break;
            case "6ASSP":
                detailedResult = "DetailedResultsCompleteASSP";
                break;
            case "12FACA":
            case "12FACA+":
                detailedResult = "DetailedResultsNotCompleted";
                break;
            case "12ASSP":
                detailedResult = "DetailedResultsNotCompletedASSP";
                break;
            case "8SAAQ":
            case "8USAL":
                detailedResult = "DetailedResultsValid";
                break;
            case "9SAAQ":
            case "9USAL":
                detailedResult = "DetailedResultsInvalid";
                break;
            case"6PRO":
            case "6CEMP":
            case "6PERS":
                detailedResult = "DetailedResultsConsultReferencesReports";
                break;
            case "6ATMN":
            case "6ATMN+":
            case "6EDPR":
            case "6AUT":
            case "6EDIG":
            case "6LOC":
            case "6FORFAIT A":
            case "6FORFAIT B":
            case "6FORFAIT C":
            case "6FORFAIT D":
            case "6ADD":
            case "6RSM":
            case "6DCQC":
            case "6DC+":
            case "10INTER":
            case "10DJI":
            case "10VDJP":
            case "10CSPQ":
            case "10PMUN":
            case "10PCQ":
            case "12ATMN":
            case "12ATMN+":
            case "12EDPR":
            case "12AUT":
            case "12EDIG":
            case "12LOC":
            case "12FORFAIT A":
            case "12FORFAIT B":
            case "12FORFAIT C":
            case "12FORFAIT D":
            case "12RSM":
            case "12DCQC":
            case "12DC+":
            case "12ADD":
                detailedResult = ""
                break;
        }

        return detailedResult;
    }

}

