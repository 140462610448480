<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid">
        <sub-header [title]="'Users' | localize" [description]="'UsersHeaderInfo' | localize">
            <div role="actions">
                <button
                    (click)="exportToExcel()"
                    class="btn btn-secondary"
                    *ngIf="'Pages.Administration.Users.ExportList' | permission"
                >
                    {{ 'ExportToExcel' | localize }}
                </button>
                <button
                    (click)="createOrEditUser()"
                    *ngIf="'Pages.Administration.Users.Create' | permission"
                    class="btn btn-primary"
                >
                    <i class="fa fa-plus"></i>
                    {{ 'CreateNewUser' | localize }}
                </button>
            </div>
        </sub-header>
        <div [class]="containerClass">
            <div class="card card-custom listContainer gutter-b">
                <div class="card-body">
                    <form class="form" autocomplete="off">
                        <div>
                            <div class="row align-items-center mb-4">
                                <div class="col-xl-12">
                                    <div class="mb-5 m-form__group align-items-center">
                                        <div class="input-group">
                                            <input
                                                [(ngModel)]="filterText"
                                                name="filterText"
                                                autoFocus
                                                class="form-control m-input"
                                                [placeholder]="l('SearchWithThreeDot')"
                                                type="text"
                                                (ngModelChange)="getUsers()"
                                            />
                                            <span class="input-group-btn">
                                                <button (click)="getUsers()" class="btn btn-primary" type="submit">
                                                    <i class="flaticon-search-1" [attr.aria-label]="l('Search')"></i>
                                                </button>
                                                <button class="btn btn-secondary" (click)="refresh()">
                                                    <i class="la la-refresh"></i>
                                                    {{ 'Refresh' | localize }}
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div class="accordion-item mb-10" style="border: 1px solid #e5eaee; border-radius: 0.42rem; margin-bottom: 1%" *ngIf="isGranted('Pages.Administration.Roles')">
                        <h2 class="accordion-header" id="advancedSearchUsersLabel">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseUsersAdvancedSearch" aria-expanded="true" aria-controls="collapseUsersAdvancedSearch">
                                <strong>{{ 'AdvancedSearch' | localize }}</strong>
                            </button>
                        </h2>
                        <div id="collapseUsersAdvancedSearch" class="accordion-collapse collapse show" aria-labelledby="advancedSearchUsersLabel">
                            <div class="accordion-body align-left">
                                <form autocomplete="off">
                                    <div class="row">
                                        <div class="form-group col-md-4">
                                            <role-combo name="roleFilter" [(ngModel)]="role"></role-combo>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div
                                            class="form-group"
                                            style="text-align: right; width: 100%; margin-bottom: 0px"
                                        >
                                            <button (click)="getUsers()" class="btn btn-primary" type="submit">
                                                <i
                                                    class="flaticon-search-1"
                                                    [attr.aria-label]="l('Search')"
                                                    data-test-id="request-list-advanced-search-button"
                                                ></i>
                                            </button>
                                            <button (click)="refresh()" class="btn btn-secondary" type="submit">
                                                <i
                                                    class="la la-refresh"
                                                    [attr.aria-label]="l('RefrfeshAdvancedSearch')"
                                                    data-test-id="request-list-refresh-advanced-search-button"
                                                ></i>
                                                {{ 'Refresh' | localize }}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="row align-items-center">
                        <!--<Primeng-TurboTable-Start>-->
                        <div class="primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                            <p-table
                                #dataTable
                                sortMode="multiple"
                                (onLazyLoad)="getUsers($event)"
                                [value]="primengTableHelper.records"
                                rows="{{ primengTableHelper.defaultRecordsCountPerPage }}"
                                [paginator]="false"
                                [lazy]="true"
                                [scrollable]="true"
                                ScrollWidth="100%"
                                scrollDirection="horizontal"
                                [responsive]="primengTableHelper.isResponsive"
                                [resizableColumns]="primengTableHelper.resizableColumns"
                            >
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th scope="col"
                                            style="min-width: 130px"
                                            [hidden]="
                                                !(
                                                    [
                                                        'Pages.Administration.Users.Edit',
                                                        'Pages.Administration.Users.ChangePermissions',
                                                        'Pages.Administration.Users.Delete'
                                                    ] | permissionAny
                                                )
                                            "
                                        >
                                            {{ 'Actions' | localize }}
                                        </th>
                                        <th scope="col" style="min-width: 150px" pSortableColumn="username">
                                            {{ 'UserName' | localize }}
                                            <p-sortIcon field="username"></p-sortIcon>
                                        </th>
                                        <th scope="col" style="min-width: 150px" pSortableColumn="name">
                                            {{ 'Name' | localize }}
                                            <p-sortIcon field="name"></p-sortIcon>
                                        </th>
                                        <th scope="col" style="min-width: 150px" pSortableColumn="surname">
                                            {{ 'Surname' | localize }}
                                            <p-sortIcon field="surname"></p-sortIcon>
                                        </th>
                                        <th scope="col" style="min-width: 150px">
                                            {{ 'Roles' | localize }}
                                        </th>
                                        <th scope="col" style="min-width: 250px" pSortableColumn="emailAddress">
                                            {{ 'EmailAddress' | localize }}
                                            <p-sortIcon field="emailAddress"></p-sortIcon>
                                        </th>
                                        <th scope="col" style="min-width: 200px" pSortableColumn="creationTime">
                                            {{ 'CreationTime' | localize }}
                                            <p-sortIcon field="creationTime"></p-sortIcon>
                                        </th>
                                        <th scope="col" style="min-width: 200px" pSortableColumn="isActive">
                                            {{ 'Active' | localize }}
                                            <p-sortIcon field="isActive"></p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-record="$implicit">
                                    <tr>
                                        <td
                                            style="min-width: 130px"
                                            [hidden]="
                                                !(
                                                    [
                                                        'Pages.Administration.Users.Edit',
                                                        'Pages.Administration.Users.ChangePermissions',
                                                        'Pages.Administration.Users.Delete'
                                                    ] | permissionAny
                                                )
                                            "
                                        >
                                            <div class="btn-group" dropdown placement="bottom left" container="body">
                                                <button
                                                    id="dropdownButton"
                                                    type="button"
                                                    class="btn btn-primary btn-sm dropdown-toggle"
                                                    dropdownToggle
                                                    aria-controls="dropdownMenu"
                                                >
                                                    <i class="fa fa-cog"></i>
                                                    <span class="caret"></span>
                                                    {{ 'Actions' | localize }}
                                                </button>
                                                <ul
                                                    id="dropdownMenu"
                                                    class="dropdown-menu"
                                                    *dropdownMenu
                                                    aria-labelledby="dropdownButton"
                                                >
                                                    <li
														*ngIf="
                                                            ('Pages.Administration.Users.Organizations' | permission)
                                                        "
													>
                                                        <a
                                                            href="javascript:;"
                                                            class="dropdown-item"
                                                            (click)="linkUserOrganization(record)"
                                                        >
                                                            {{ 'LinkUserOrganization' | localize }}
                                                        </a>
                                                    </li>
                                                    <li
                                                        *ngIf="'Pages.Administration.Users.Edit' | permission"
                                                    >
                                                        <a
                                                            class="cursor-pointer dropdown-item"
                                                            (click)="createOrEditUserModal.show(record.id)"
                                                        >
                                                            {{ 'Edit' | localize }}
                                                        </a>
                                                    </li>
                                                    <li
                                                        *ngIf="'Pages.Administration.Users.Unlock' | permission"
                                                    >
                                                        <a
                                                            href="javascript:;"
                                                            class="dropdown-item"
                                                            (click)="unlockUser(record)"
                                                        >
                                                            {{ 'Unlock' | localize }}
                                                        </a>
                                                    </li>
                                                    <li
                                                        *ngIf="'Pages.Administration.Users.Delete' | permission"
                                                    >
                                                        <a
                                                            href="javascript:;"
                                                            class="dropdown-item"
                                                            (click)="deleteUser(record)"
                                                        >
                                                            {{ 'Delete' | localize }}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                        <td style="min-width: 150px">
                                            <span class="p-column-title">{{ 'UserName' | localize }}</span>
                                                {{ record.userName }}
                                        </td>
                                        <td style="min-width: 150px">
                                            <span class="p-column-title">{{ 'FirstName' | localize }}</span>
                                            {{ record.name }}
                                        </td>
                                        <td style="min-width: 150px">
                                            <span class="p-column-title">{{ 'Surname' | localize }}</span>
                                            {{ record.surname }}
                                        </td>
                                        <td style="min-width: 150px">
                                            <span class="p-column-title">{{ 'Roles' | localize }}</span>
                                            {{ getRolesAsString(record.roles) | titlecase}}
                                        </td>
                                        <td style="min-width: 250px">
                                            <span class="p-column-title">{{ 'EmailAddress' | localize }}</span>
                                            {{ record.emailAddress }}
                                        </td>
                                        <td style="min-width: 200px">
                                            <span class="p-column-title">{{ 'CreationTime' | localize }}</span>
                                            {{ record.creationTime | luxonFormat: 'F' }}
                                        </td>
                                        <td style="min-width: 150px">
                                            <span class="p-column-title">{{ 'Active' | localize }}</span>
                                            {{ record.isActive && 'Active' || 'Blocked' | localize }}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                                {{ 'NoData' | localize }}
                            </div>
                            <div class="primeng-paging-container">
                                <p-paginator
                                    [rows]="primengTableHelper.defaultRecordsCountPerPage"
                                    #paginator
                                    (onPageChange)="getUsers($event)"
                                    [totalRecords]="primengTableHelper.totalRecordsCount"
                                    [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
                                    [showCurrentPageReport]="true"
                                    [currentPageReportTemplate]="
                                        'TotalRecordsCount' | localize: primengTableHelper.totalRecordsCount
                                    "
									data-test-id="users-paginator"
                                ></p-paginator>
                            </div>
                        </div>
                        <!--<Primeng-TurboTable-End>-->
                    </div>
                </div>
            </div>
        </div>

        <create-or-edit-user-modal #createOrEditUserModal (modalSave)="getUsers()"></create-or-edit-user-modal>
    </div>
</div>
