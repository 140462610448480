import { Component, Input } from '@angular/core';
import { RequestOutput } from '@shared/models/request/requestOutput';

@Component({
    selector: 'app-request-report',
    templateUrl: './request-report.component.html'
})
export class RequestReportComponent {
    @Input() request: RequestOutput;
}
