import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment as env } from 'environments/environment';
import { Observable } from 'rxjs';
import { NoteInput } from '@shared/models/note/noteInput';

@Injectable()
export class NotesService {

    private http: HttpClient;
    private baseUrl: string;
    //@Output() public onUploadFinished = new EventEmitter();

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = env.api;
    }

    private getUrl(url: string): string {
        let url_ = this.baseUrl + url;
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    }

    getNotesForRequest(publicId: string): Observable<any> {
        let url_ = this.getUrl('/api/Notes');
        let params = new HttpParams().set('publicId', publicId);
        return this.http.get<any>(url_, { params });
    }

    addNote(note: NoteInput): Observable<any> {
        let url_ = this.getUrl('/api/Notes');
        return this.http.post<any>(url_, note);
    }

    getNoteById(noteId: number): Observable<any> {
        let url_ = this.getUrl('/api/Notes/' + noteId);
        return this.http.get<any>(url_);
    }

}
