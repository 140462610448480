import { AddressOutput } from "../address/addressOutput";
import { OrganizationCreditCardOutput } from "../payment/organizationCreditCardOutput";
import { BillingInformationOutput } from "./billingInformation/billingInformationOutput";
import { organizationLineOfBusiness } from "./organizationLineOfBusiness";
import { OrganizationOutputResult } from "./organizationOutputStatus";
import { OrganizationRequestVolumeRange } from "./OrganizationRequestVolumeRange";
import { organizationStatus } from "./organizationStatus";


// tslint:disable-next-line: class-name
export class organizationOutput {
    id: number;
    usersId: number[];
    idSage: string;
    idAccountantCAR: string;
    publicId: string;
    clientId: string;
    organizationName: string;
    purchaseOrder: number;
    firstContact: string;
    firstContactEmail: string;
    firstContactJob: string;
    phoneNumber: string;
    phoneExtension: string;
    language: string;
    notes: string;
    status: organizationStatus;
    result: OrganizationOutputResult;
    address: AddressOutput;
    creationTime: Date;
    lastModificationTime: Date;
    organizationParent: organizationOutput;
    organizationParentId: number;
    billingInfo: BillingInformationOutput;
    organizationCreditCard: OrganizationCreditCardOutput;
    hasActiveClientUser: boolean;
    preAuthorizedPayment: boolean;
    lineOfBusiness: organizationLineOfBusiness;
    provenance: string;
    volumeOfRequestPerYear: OrganizationRequestVolumeRange;

    constructor() {
        this.id = 0;
        this.usersId = [];
        this.idSage = '';
        this.idAccountantCAR = '';
        this.publicId = '';
        this.clientId = '';
        this.organizationName = '';
        this.purchaseOrder = null;
        this.firstContact = '';
        this.firstContactEmail = '';
        this.firstContactJob = '';
        this.phoneNumber = '';
        this.phoneExtension = '';
        this.language = '';
        this.notes = '';
        this.status = organizationStatus.Actif;
        this.result = null;
        this.address = null;
        this.creationTime = new Date();
        this.lastModificationTime = new Date();
        this.organizationParent = null;
        this.organizationParentId = null;
        this.billingInfo = null;
        this.organizationCreditCard = null;
        this.hasActiveClientUser = false;
        this.preAuthorizedPayment = false;
        this.volumeOfRequestPerYear = null;
        this.lineOfBusiness = null;
        this.provenance = '';
    }
}

