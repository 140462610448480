import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-report-last-page-footer',
    templateUrl: './report-last-page-footer.component.html',
    styleUrls: ['./report-last-page-footer.component.scss'],
})
export class ReportLastPageFooterComponent implements OnInit {
    @Input() lang: string;
    today: Date;

    constructor() {}

    ngOnInit(): void {
        this.today = new Date();
    }
}
