import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { VerificationInput } from '@shared/models/verification/verificationInput';
import { map } from 'rxjs/operators';
import { VerificationListOutput } from '@shared/models/verification/verificationListOutput';
import { environment as env } from 'environments/environment';

@Injectable()
export class VerificationService {
    private http: HttpClient;

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
    }

    getVerificationsList(): Observable<VerificationListOutput[]> {
        const url = '/api/Verifications';
        const route = (env.api + url).replace(/[?&]$/, '');
        return this.http.get<VerificationListOutput[]>(route).pipe(map((response: any) => response.result));
    }

    getCompletelyVerificationsList(): Observable<any> {
        const url = '/api/Verifications/CompletelyVerifications';
        const route = (env.api + url).replace(/[?&]$/, '');
        const list = this.http.get<any>(route);
        return list;
    }

    getVerificationsNotLinkedToOrganization(organizationPublicId: string): Observable<any> {
        const url = `/api/Verifications/GetVerificationsNotLinkedToOrganization/${organizationPublicId}`;
        const route = (env.api + url).replace(/[?&]$/, '');
        const list = this.http.get<any>(route);
        return list;
    }

    getVerificationsLinkedToOrganization(organizationPublicId: string): Observable<any> {
        const url = '/api/Verifications/GetVerificationsLinkedToOrganization';
        const route = (env.api + url).replace(/[?&]$/, '');
        const list = this.http.get<any>(`${route}/${organizationPublicId}`);
        return list;
    }

    getVerificationByType(type: string): Observable<any> {
        const url = '/api/Verifications/type';
        const route = (env.api + url).replace(/[?&]$/, '');
        const params = new HttpParams().set('type', type);
        return this.http.get<any[]>(route, { params });
    }

    getVerificationByPublicId(publicId: string): Observable<any> {
        const url = '/api/Verifications';
        const route = (env.api + url).replace(/[?&]$/, '');
        const params = new HttpParams().set('publicId', publicId);
        return this.http.get<any>(route, { params });
    }

    createVerification(verification: VerificationInput): Observable<any> {
        const url = '/api/Verifications';
        const route = (env.api + url).replace(/[?&]$/, '');
        return this.http.post<any[]>(route, verification);
    }

    updateVerification(verification: VerificationInput): Observable<any> {
        const url = '/api/Verifications';
        const route = (env.api + url).replace(/[?&]$/, '');
        return this.http.put<any>(route, verification);
    }

    deleteVerification(verification: VerificationInput): Observable<any> {
        const url = '/api/Verifications/delete';
        const route = (env.api + url).replace(/[?&]$/, '');
        return this.http.post<any>(route, verification);
    }

    addFiles(verificationPublicId: string, filesPublicIds: string[]): Observable<any> {
        const url = `/api/verifications/${verificationPublicId}/files`;
        const route = (env.api + url).replace(/[?&]$/, '');
        return this.http.post<any>(route, filesPublicIds);
    }

    deleteFile(verificationPublicId: string, filesPublicId: string, deletedFrom: string): Observable<any> {
        const url = '/api/Verifications/files';
        const route = (env.api + url).replace(/[?&]$/, '');
        const params = new HttpParams()
            .set('verificationPublicId', verificationPublicId)
            .set('filesPublicId', filesPublicId)
            .set('deletedFrom', deletedFrom);
        return this.http.delete<any>(route, { params });
    }

    updateMandatory(verificationId: number, filesId: number, isMandatory: boolean): Observable<any> {
        const url = `/api/verifications/${verificationId}/files/${filesId}/mandatory/${isMandatory}`;
        const route = (env.api + url).replace(/[?&]$/, '');
        return this.http.post<any>(route, null);
    }
}
