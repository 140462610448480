<p class="mt-4">{{"RequestWidgetDescription" | localize }}</p>
<div class="row">
  <div class="col-auto me-5 py-3 d-flex align-items-center">
    <span><strong>{{'VerificationsStatus' | localize}} :</strong></span>
  </div>
  <div class="col d-flex flex-wrap align-items-center">
    <div *ngFor="let status of statusList" class="text-center d-flex align-items-center px-3 py-3">
      <span>
        <i [style]="{ color: status.color }" class="{{status.statusIcon}} fa-2x me-2"> </i>
      </span>
      <span [style]="{ color: status.color }">
        {{status.name | localize}}
      </span>
    </div>
  </div>
</div>
