import { AppAuthService } from "@app/shared/common/auth/app-auth.service";
import { AppConsts } from "@shared/AppConsts";
import { DialogService } from "@shared/services/dialog-service";
import LocalizationService from "@shared/services/localization.service";

export function handleAuth0LoginRequiredError(
    localization: LocalizationService,
    dialogueService: DialogService,
    authService: AppAuthService) {
    const sourceName = AppConsts.localization.defaultLocalizationSourceName;
    const title = localization.localize('Warning', sourceName);
    const message = localization.localize('SessionExpiredRedirectionMessage', sourceName);

    hideRootInitialOverlay();
    dialogueService.warn(message, title)
        .then((result) => {
            if (result) {
                // It's necessary to check if result.dismiss != undefined because result.isDismissed can be true even any interactions happened
                let isDismissed = result.dismiss && result.isDismissed;
                if (result.isConfirmed || isDismissed) {
                    authService.loginWithRedirect();
                }
            }
        });
}

function hideRootInitialOverlay() {
    const loader = document.querySelector('.root-initial-loader');
    if (loader) {
        loader.setAttribute('style', 'display: none;');
    }
}
