import { Component, Injector } from '@angular/core';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import {
    TenantLoginInfoDto,
} from '@shared/service-proxies/service-proxies';

@Component({ template: '' })
export class ThemesLayoutBaseComponent extends AppComponentBase {
    tenant: TenantLoginInfoDto = new TenantLoginInfoDto();
    installationMode = true;
    dateTimeService: DateTimeService;

    defaultLogo =
        '/assets/common/images/app-logo-on-' +
        this.currentTheme.baseSettings.menu.asideSkin +
        '.svg';

    constructor(injector: Injector) {
        super(injector);
        this.dateTimeService = injector.get(DateTimeService);
    }
}
