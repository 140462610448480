import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
    selector: 'app-grid-btn',
    templateUrl: './grid-btn.component.html',
    styleUrls: ['./grid-btn.component.scss']
})
export class GridBtnComponent implements ICellRendererAngularComp {
    params: any;
    tooltip: string;

    refresh(params: any): boolean {
        return false;
    }

    agInit(params): void {
        this.params = params;
        this.tooltip = params.tooltip || '';
    }

    onClick(): void {
        this.params.action(this.params.data);
    }
}
