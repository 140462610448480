import { Injectable } from '@angular/core';
import { EventEmitter } from 'events';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private eventEmitter = new EventEmitter();

  on(eventName: string, callback: (...args: any[]) => void): void {
    this.eventEmitter.on(eventName, callback);
  }

  off(eventName: string, callback: (...args: any[]) => void): void {
    this.eventEmitter.off(eventName, callback);
  }

  trigger(eventName: string, ...args: any[]): void {
    this.eventEmitter.emit(eventName, ...args);
  }
}