import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '@shared/utils/utils.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaqCategoryCreateEditModalComponent } from './faq-category-create-edit-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AppCommonModule } from '@app/shared/common/app-common.module';


@NgModule({
    imports: [
        UtilsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        AppCommonModule
    ],
    declarations: [
        FaqCategoryCreateEditModalComponent,
    ],
    exports: [
        FaqCategoryCreateEditModalComponent
    ]
})

export class FaqCategoryCreateEditModule {}