import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { RequestInput } from '@shared/models/request/requestInput';
import { RequestOutput } from '@shared/models/request/requestOutput';
import { Observable } from 'rxjs';
import { RequestFileInput } from '@shared/models/requestFile/requestFileInput';
import { RequestAllInformation } from '@shared/models/request/requestInformation/requestInformation';
import { AdvancedSearchRequestInput } from '@shared/models/request/advancedSearchRequestInput';
import { map } from 'rxjs/operators';
import { environment as env } from 'environments/environment';

@Injectable()
export class RequestService {
    private http: HttpClient;
    private baseUrl: string;
    private cachedRequest: RequestOutput;

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = env.api;
    }

    getCachedRequestOrFetch(publicId: string) {
        return this.getRequestByPublicId(publicId).pipe(map((r: any) => r.result));
    }

    deleteCachedRequest() {
        this.cachedRequest = null;
    }

    createRequest(request: RequestInput): Observable<RequestOutput> {
        const url_ = this.getUrl('/api/requests');
        return this.http.post<RequestOutput>(url_, request);
    }

    getRequestsList(): Observable<any> {
        const url_ = this.getUrl('/api/requests');
        const list = this.http.get<any>(url_);
        return list;
    }

    getRequestsLiteList(): Observable<any> {
        const url_ = this.getUrl('/api/requests/lite');
        const list = this.http.get<any>(url_);
        return list;
    }

    getRequestByPublicId(publicId: string): Observable<any> {
        const url_ = this.getUrl('/api/requests');
        const params = new HttpParams().set('publicId', publicId);
        return this.http.get<any>(url_, { params });
    }

    getRequestByOrganizationPublicId(organizationPublicId: string): Observable<any> {
        const url_ = this.getUrl('/api/requests');
        const params = new HttpParams().set('organizationPublicId', organizationPublicId);
        return this.http.get<any>(url_, { params });
    }

    updateRequest(request: RequestInput): Observable<any> {
        const url_ = this.getUrl('/api/requests');
        return this.http.put<any>(url_, request);
    }

    cancelRequest(publicId: string): Observable<any> {
        const url_ = this.getUrl(`/api/requests/Cancel/${publicId}`);
        return this.http.put<any>(url_, publicId);
    }

    addRequestFile(requestFileInput: RequestFileInput): Observable<any> {
        const url_ = this.getUrl('/api/requests/RequestFileAdd');
        return this.http.post<any>(url_, requestFileInput);
    }

    getAllRequestFiles(requestId: string): Observable<any> {
        const url_ = this.getUrl('/api/requests/GetAllRequestFile');
        const params = new HttpParams().set('requestId', requestId);
        const list = this.http.get<any>(url_, { params });
        return list;
    }

    getMandatoryRequestFiles(publicId: string): Observable<any> {
        const url_ = this.getUrl('/api/requests/GetMandatoryRequestFile');
        const params = new HttpParams().set('publicId', publicId);
        const list = this.http.get<any>(url_, { params });
        return list;
    }

    getFilledRequestFiles(publicId: string): Observable<any> {
        const url_ = this.getUrl('/api/requests/GetFilledRequestFile');
        const params = new HttpParams().set('publicId', publicId);
        const list = this.http.get<any>(url_, { params });
        return list;
    }

    getReportsRequestFiles(publicId: string): Observable<any> {
        const url_ = this.getUrl('/api/requests/GetReportsRequestFile');
        const params = new HttpParams().set('publicId', publicId);
        const list = this.http.get<any>(url_, { params });
        return list;
    }

    getRequestVerificationsForms(publicId: string): Observable<any> {
        const url_ = this.getUrl('/api/requests/GetRequestVerificationForms');
        const params = new HttpParams().set('publicId', publicId);
        const list = this.http.get<any>(url_, { params });
        return list;
    }

    getFileDto(requestFile: RequestFileInput): Observable<any> {
        const url_ = this.getUrl('/api/requests/GetRequestFile');
        return this.http.post<any>(url_, requestFile);
    }

    deleteFile(filePublicId: string, deletedFrom: string): Observable<any> {
        const url_ = this.getUrl('/api/requests/DeleteRequestFile/');
        const params = new HttpParams().set('filePublicId', filePublicId).set('deletedFrom', deletedFrom);
        return this.http.put<any>(url_, null, { params });
    }

    public addRequestFileLink(requestFilePublicId: string, requestId: number, verificationId: number): Observable<any> {
        const url_ = this.getUrl(`/api/requests/${requestId}/${verificationId}/AddRequestFileLink/${requestFilePublicId}`);
        return this.http.post<any>(url_, {});
    }

    public deleteRequestFile(requestFilePublicId: string, requestId: number): Observable<any> {
        const url_ = this.getUrl(`/api/requests/${requestId}/DeleteRequestFileLink/${requestFilePublicId}`);
        return this.http.delete<any>(url_);
    }

    deleteAllRequestMandatoryFiles(requestId: number): Observable<any> {
        const url_ = this.getUrl(`/api/requests/${requestId}/DeleteAllRequestMandatoryFiles`);
        return this.http.delete<any>(url_);
    }

    deleteRequest(publicId: string): Observable<any> {
        const url_ = this.getUrl(`/api/requests/Delete/${publicId}`);
        return this.http.delete<any>(url_);
    }

    updateAllRequest(anonymousInput: RequestAllInformation): Observable<any> {
        const url_ = this.getUrl('/api/requests/UpdateAllRequestInfo');
        this.deleteCachedRequest();
        return this.http.put<any>(url_, anonymousInput);
    }

    sentRequestToGardium(requestPublicId: string): Observable<any> {
        const url_ = this.getUrl(`/api/requests/SentRequestToGardium/${requestPublicId}`);
        return this.http.get<any>(url_);
    }
    updateRequestToNew(requestPublicId: string): Observable<any> {
        const url_ = this.getUrl(`/api/requests/updateRequestToNew/${requestPublicId}`);
        return this.http.get<any>(url_);
    }

    advanceSearch(advanceSearchRequestInput: AdvancedSearchRequestInput) {
        const url_ = this.getUrl('/api/requests/AdvancedSearch/lite');
        return this.http.post<any>(url_, advanceSearchRequestInput);
    }

    getRequestForOrganizationWithNotInvoice(organizationPublicId): Observable<any> {
        const url_ = this.getUrl('/api/requests/GetRequestForOrganizationWithNotInvoice');
        const params = new HttpParams().set('organizationPublicId', organizationPublicId);
        return this.http.get<any>(url_, { params });
    }

    getRequestsWithNoInvoiceByOrganizationPublicIds(organizationPublicIds: string[]): Observable<any> {
        const url_ = this.getUrl('/api/requests/GetRequestsWithNoInvoiceByOrganizationPublicIds');
        return this.http.post<any>(url_, organizationPublicIds);
    }

    getCandidateRequestCountNotDraftOrInProgressCandidate(candidateId): Observable<number> {
        const url_ = this.getUrl('/api/requests/GetCandidateRequestCountNotDraftOrInProgressCandidate');
        const params = new HttpParams().set('candidateId', candidateId);
        return this.http.get<any>(url_, { params });
    }

    sendEzsignRequest(requestPublicId: string): Observable<any> {
        const url_ = this.getUrl(`/api/requests/SendEzsignRequest/${requestPublicId}`);
        return this.http.post<any>(url_, {});
    }

    cancelEzsignRequest(requestPublicId: string): Observable<any> {
        const url_ = this.getUrl(`/api/requests/CancelEzsignRequest/${requestPublicId}`);
        return this.http.delete<any>(url_);
    }

    sendToPpo(requestPublicId: string): Observable<any> {
        const url_ = this.getUrl(`/api/requests/SendPpoRequest/${requestPublicId}`);
        return this.http.post<any>(url_, {});
    }

    getRequestsStatistics(numberOfDays: number): Observable<any> {
        const url_ = this.getUrl('/api/requests/statistics');
        const params = new HttpParams().set('numberOfDays', numberOfDays);
        return this.http.get<any>(url_, { params });
    }

    private getUrl(url: string): string {
        let url_ = this.baseUrl + url;
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    }
}
