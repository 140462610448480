import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment as env } from 'environments/environment';
import { UniqueCcPaymentInput } from '@shared/models/payment/uniqueCcPaymentInput';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private http: HttpClient;
  private baseUrl: string;

  constructor(@Inject(HttpClient) http: HttpClient) {
    this.http = http;
    this.baseUrl = env.api;
  }

  private getUrl(url: string): string {
    let url_ = this.baseUrl + url;
    url_ = url_.replace(/[?&]$/, '');
    return url_;
}

  makeUniqueCcPayment(uniqueCcPaymentInput: UniqueCcPaymentInput, invoicePublicId: string): Observable<any> {
    let url_ = this.getUrl(`/api/Invoices/ChargeCreditCardUniquePayment/${invoicePublicId}`);
    return this.http.post<any>(url_, uniqueCcPaymentInput);
}
}
