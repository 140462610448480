import { Component, OnInit, Injector, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, AbstractControl } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CandidatOutput } from '@shared/models/candidat/candidatOutput';
import { CandidatInput } from '@shared/models/candidat/candidatInput';
import { emailAddressValidator } from '@shared/utils/validation/email-validator.directive';
import { AppConsts } from '@shared/AppConsts';
import { socialInsuranceNumberValidators } from '@shared/utils/validation/NAS-validator';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';

@Component({
    selector: 'request-candidats-details',
    templateUrl: './candidats-details.component.html'
})
export class CandidatsDetailsComponent extends AppComponentBase implements OnInit {
    @Input() selectedCandidat: CandidatOutput;
    @Input() canUpdate: boolean;

    masks = AppConsts.masks;

    formGroupCandidate = new UntypedFormGroup({
        firstName: new UntypedFormControl({ value: '', disabled: true }),
        lastName: new UntypedFormControl({ value: '', disabled: true }),
        sex: new UntypedFormControl({ value: '', disabled: true }),
        language: new UntypedFormControl({ value: '', disabled: true }),
        birthdate: new UntypedFormControl({ value: '', disabled: !this.canEditBirthDate() }),
        otherNames: new UntypedFormControl({ value: '', disabled: true }),
        maidenName: new UntypedFormControl({ value: '', disabled: true }),
        birthPlace: new UntypedFormControl({ value: '', disabled: true }),
        email: new UntypedFormControl('', emailAddressValidator),
        socialInsuranceNumber: new UntypedFormControl('', {
            validators: socialInsuranceNumberValidators
        }),
        driverLicenceNumber: new UntypedFormControl(''),
        phoneNumber: new UntypedFormControl({ value: '', disabled: true }),
        phoneExtension: new UntypedFormControl({ value: '', disabled: true })
    });

    constructor(
        injector: Injector,
        private _dateTimeService: DateTimeService
    ) {
        super(injector);
    }

    ngOnInit() {
        if (this.selectedCandidat != null) {
            this.formGroupCandidate.patchValue(this.selectedCandidat);
        }
        if (!this.canUpdate) {
            this.formGroupCandidate.disable();
        }
    }

    markAllControlsAsTouch() {
        this.formGroupCandidate.controls['socialInsuranceNumber'].markAsTouched();
        this.formGroupCandidate.controls['driverLicenceNumber'].markAsTouched();
    }

    isFormInvalid(): boolean {
        if (this.formGroupCandidate !== undefined) {
            return this.formGroupCandidate.invalid;
        }
    }

    returnCandidate(): CandidatInput {
        const candidat: CandidatInput = CandidatInput.fromOutput(this.selectedCandidat);
        candidat.email = this.formGroupCandidate.controls['email'].value;
        candidat.driverLicenceNumber = this.formGroupCandidate.controls['driverLicenceNumber'].value;
        candidat.socialInsuranceNumber = this.formGroupCandidate.controls['socialInsuranceNumber'].value;
        if (this.canEditBirthDate()) {
            const updatedBirthDate: Date = this.formGroupCandidate.controls['birthdate'].value;
            if (updatedBirthDate != candidat.birthdate) {
                const utcUpdatedBirthDate: Date = this._dateTimeService.toUtcDate(updatedBirthDate).toJSDate();
                candidat.birthdate = utcUpdatedBirthDate;
            }
        }
        return candidat;
    }

    setAllAsNotRequired() {
        this.formGroupCandidate.controls['socialInsuranceNumber'].clearValidators();
        this.formGroupCandidate.controls['driverLicenceNumber'].clearValidators();
        this.formGroupCandidate.controls['email'].clearValidators();

        this.formGroupCandidate.controls['email'].setValidators(emailAddressValidator);
        this.formGroupCandidate.controls['socialInsuranceNumber'].setValidators(socialInsuranceNumberValidators);

        this.formGroupCandidate.controls['socialInsuranceNumber'].updateValueAndValidity();
        this.formGroupCandidate.controls['driverLicenceNumber'].updateValueAndValidity();
        this.formGroupCandidate.controls['email'].updateValueAndValidity();
    }

    candidateHaveAnEmail(): boolean {
        return this.formGroupCandidate.controls['email'].value !== '';
    }

    hasRequiredField(abstractControl: AbstractControl): boolean {
        if (abstractControl.validator) {
            const validator = abstractControl.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
        if (abstractControl['controls']) {
            for (const controlName in abstractControl['controls']) {
                if (abstractControl['controls'][controlName]) {
                    if (this.hasRequiredField(abstractControl['controls'][controlName])) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    canEditBirthDate(): boolean {
        return this.isGranted('Pages.Management.Requests.Create');
    }
}
