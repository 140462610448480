import { ChangeDetectorRef, Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { country } from '@shared/models/shared/country';
import { state } from '@shared/models/shared/state';
import { Constants } from '@shared/models/shared/constants';
import { CountryService } from '@shared/services/country.service';
import { StatesService } from '@shared/services/states.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { AppConsts } from '@shared/AppConsts';
import { postalCodeValidatorUS } from '@shared/utils/validation/postalCodeUS-validator.directive';
import { postalCodeValidatorCA } from '@shared/utils/validation/postalCodeCA-validator.directive';
import { RequiredFieldsForCodes } from '@shared/utils/verificationsEnumAndFunctions';
import { RequestVerificationOutput } from '@shared/models/requestVerification/requestVerificationOutput';
import { AddressHistoryType } from '@shared/models/addresshistory/addressHistoryType';
import { CandidatOutput } from '@shared/models/candidat/candidatOutput';
import { forkJoin, Subscription } from 'rxjs';
import * as _ from 'lodash';

@Component({
    selector: 'app-address-fields',
    templateUrl: './address-fields.component.html'
})
export class AddressFieldsComponent extends AppComponentBase implements OnInit, OnDestroy {
    @Input()
    set formGroup(val: UntypedFormGroup) {
        this._formGroup = val;
        if (this._formGroup) {
            this.initCountryValueChanged();
        }
    }
    get formGroup(): UntypedFormGroup {
        return this._formGroup;
    }

    @Input()
    set requestVerifications(val: RequestVerificationOutput[]) {
        this._requestVerifications = val;
        if (this._requestVerifications) {
            this.setCurrentAddressRequirement();
        }
    }
    get requestVerifications(): RequestVerificationOutput[] {
        return this._requestVerifications;
    }

    @Input() selectedCandidat: CandidatOutput;
    @Input()
    showInCard = true;

    @Input()
    readOnly = false;

    @Input()
    mandatoryRule: 'all' | 'onVerificationCode' | 'onLostFocus' = 'all';

    @Input()
    operation: 'create-former' | 'update-former' | 'create-current' | 'update-current' | 'create' | null;
    isAddressForRequiredForCode: boolean;
    statesList: state[] = [];
    countryList: country[] = [];
    stateNotRequired = true;
    noResultCountry = false;
    noResultState = false;
    maskOfPostalCodeAddress = '';
    postalCodePlaceholder: string;
    postalCodeMaxLength: number;
    masks = AppConsts.masks;
    showAsterisk: boolean;
    countryCanada: country;
    provinceQuebec: state;

    minDateTime = new Date();
    maxDate: Date;
    private _formGroup: UntypedFormGroup;
    private _requestVerifications: RequestVerificationOutput[];
    private _subscriptions: Subscription[] = [];

    get isCurrent(): boolean {
        return this.operation === 'create-current' || this.operation === 'update-current';
    }

    get isFormer(): boolean {
        return this.operation === 'create-former' || this.operation === 'update-former';
    }

    get isCreate(): boolean {
        return this.operation === 'create' || this.operation === 'create-former' || this.operation === 'create-current';
    }

    constructor(
        private stateService: StatesService,
        private localeService: BsLocaleService,
        private cdr: ChangeDetectorRef,
        private countryService: CountryService,
        _injector: Injector
    ) {
        super(_injector);
        this.localeService.use(this.localization.currentLanguage.name);
    }

    async ngOnInit() {
        this.maxDate = new Date();
        this.updateRequiredFieldRules();

        if (this.isFormer) {
            this.formGroup.controls['type'].setValue(AddressHistoryType.former);
            this.disableFormControl('type');
        } else if (this.isCurrent) {
            this.formGroup.controls['type'].setValue(AddressHistoryType.current);
            this.disableFormControl('type');
        }

        if (this.readOnly) {
            this.setAllAsNotRequired();
            this.formGroup.disable();
        }

        if (this.isCreate) {
            this.setDefaultCountryProvince();
        }

        this.initializeCountryList();
    }
    ngOnDestroy() {
        for (const sub of this._subscriptions) {
            try {
                sub.unsubscribe();
            } catch (e) {
                console.error(e);
            }
        }
    }

    updateRequiredFieldRules() {
        switch (this.mandatoryRule) {
            case 'all':
                this.setAllAsRequired();
                break;
            case 'onVerificationCode':
                if (this.isAddressForRequiredForCode || !this.isFormEmpty()) {
                    this.setAllAsRequired();
                } else {
                    this.setAllAsNotRequired();
                }
                break;
            case 'onLostFocus':
                if (!this.isFormEmpty()) {
                    this.setAllAsRequired();
                    this.formGroup.markAllAsTouched();
                } else {
                    this.setAllAsNotRequired();
                }
                break;
        }
    }

    onFocusOut() {
        if (this.mandatoryRule === 'onLostFocus' || this.mandatoryRule === 'onVerificationCode') {
            this.updateRequiredFieldRules();
        }
    }

    onCreationDateStartValueChange(value: Date): void {
        this.minDateTime = value;
    }

    isFormEmpty(): boolean {
        return (
            (this.formGroup.controls['fromDate'].value === '' || this.formGroup.controls['fromDate'].value === null) &&
            this.formGroup.controls['postalCode'].value === '' &&
            (this.formGroup.controls['civicNo'].value === '' || this.formGroup.controls['civicNo'].value === null) &&
            this.formGroup.controls['street'].value === '' &&
            this.formGroup.controls['app'].value === '' &&
            this.formGroup.controls['city'].value === ''
        );
    }

    setCurrentAddressRequirement() {
        this.isAddressForRequiredForCode = this.requestVerifications.some((verif) => RequiredFieldsForCodes.isAddressRequired(verif.verification.code));
        if (this.mandatoryRule === 'onVerificationCode' && this.isAddressForRequiredForCode) {
            this.setAllAsRequired();
        }
    }

    initializeCountryList() {
        this._subscriptions.push(
            this.countryService
                .getCountryList()
                .subscribe((value) => {
                    this.countryList = value.result as country[];
                })
        );
    }

    setDefaultCountryProvince() {
        this._subscriptions.push(
            forkJoin([this.countryService.getCanadaCountryList(), this.stateService.getQuebec()]).subscribe(([canada, quebec]) => {
                this.countryCanada = canada.result as country;
                this.formGroup.controls['country'].setValue(this.countryCanada);
                this.formGroup.controls['countryName'].setValue(this.countryCanada.countryName);
                this.provinceQuebec = quebec.result as state;
                this.formGroup.controls['state'].setValue(this.provinceQuebec);
                this.formGroup.controls['stateName'].setValue(this.provinceQuebec.stateName);
                this.formGroup.markAsPristine();
            })
        );
    }

    initCountryValueChanged() {
        const countryValue = this.formGroup.get('country').value;
        if (countryValue) {
            this.tryToLoadState(countryValue);
            this.maskPostalCode(countryValue);
        }

        this._subscriptions.push(
            this.formGroup.get('country').valueChanges.subscribe((country) => {
                if (country) {
                    this.clearStateAndZipCode();
                    this.tryToLoadState(country);
                    this.maskPostalCode(country);
                }
            })
        );
    }

    tryToLoadState(country: country) {
        if (this.isStateRequired(country.countryCode)) {
            this.stateNotRequired = false;
            this.formGroup.controls['stateName'].setValidators(Validators.required);
            this.initStateList(country.id);
            this.noResultState = false;
        } else {
            this.stateNotRequired = true;
            this.noResultState = false;
            this.formGroup.controls['state'].setValue('');
            this.formGroup.controls['stateName'].setValue('');
            this.formGroup.controls['stateName'].clearValidators();
        }
        this.formGroup.controls['stateName'].updateValueAndValidity();
    }

    initStateList(countryId: number) {
        this._subscriptions.push(
            this.stateService
                .getStatesFromCountry(countryId)
                .subscribe((value) => {
                    this.statesList = value.result as state[];
                })
        );
    }

    isStateRequired(countryCode: string): boolean {
        return countryCode && (countryCode.toLowerCase() === Constants.USA_CODE.toLowerCase() || countryCode.toLowerCase() === Constants.CANADA_CODE.toLowerCase());
    }

    countryTypeaheadOnBlur(event: any): void {
        const countryValue = this.formGroup.get('country').value;
        const areEqual = _.isEqual(countryValue, event.item);
        this.formGroup.controls['country'].setValue(event.item as country, { emitEvent: !areEqual });
    }

    stateTypeaheadOnBlur(event: any): void {
        this.formGroup.controls['state'].setValue(event.item as state);
    }

    countryOnSelect(event: TypeaheadMatch): void {
        const countryValue = this.formGroup.get('country').value;
        const areEqual = _.isEqual(countryValue, event.item);
        this.formGroup.controls['country'].setValue(event.item as country, { emitEvent: !areEqual });
    }

    onSelectState(event: TypeaheadMatch): void {
        this.formGroup.controls['state'].setValue(event.item as state);
    }

    typeaheadNoResults(event: boolean): void {
        this.stateNotRequired = true;
        this.noResultCountry = event;
        this.formGroup.controls['state'].clearValidators();
    }

    typeaheadNoResultsStates(event: boolean): void {
        this.noResultState = event;
    }

    clearStateAndZipCode(): void {
        this.formGroup.controls['state'].setValue('');
        this.formGroup.controls['stateName'].setValue('');
        this.formGroup.controls['postalCode'].setValue('');
    }

    maskPostalCode(country: country): void {
        if (country.countryCode === Constants.CANADA_CODE) {
            this.maskOfPostalCodeAddress = this.masks.canadaPostalCode;
            this.postalCodePlaceholder = 'A1A 1A1';
            this.postalCodeMaxLength = 7;
        } else if (country.countryCode === Constants.USA_CODE) {
            this.maskOfPostalCodeAddress = '';
            this.postalCodePlaceholder = '11111 or 11111-1111';
            this.postalCodeMaxLength = 10;
        } else {
            this.maskOfPostalCodeAddress = '';
            this.postalCodePlaceholder = '';
            this.postalCodeMaxLength = 256;
        }
        this.updatePostalCodeValidators();
        this.cdr.detectChanges();
    }

    updatePostalCodeValidators() {
        const countryValue = this.formGroup.get('country').value;
        const validators = [];
        if (this.showAsterisk) {
            validators.push(Validators.required);
        }
        if (countryValue && countryValue.countryCode === Constants.CANADA_CODE) {
            validators.push(postalCodeValidatorCA);
        } else if (countryValue && countryValue.countryCode === Constants.USA_CODE) {
            validators.push(postalCodeValidatorUS);
        }
        this.formGroup.controls['postalCode'].setValidators(validators);
    }

    setAllAsNotRequired() {
        this.showAsterisk = false;
        this.updatePostalCodeValidators();
        if (this.formGroup.controls['type']) {
            this.formGroup.controls['type'].clearValidators();
        }

        this.formGroup.controls['civicNo'].clearValidators();
        this.formGroup.controls['street'].clearValidators();
        this.formGroup.controls['city'].clearValidators();
        this.formGroup.controls['country'].clearValidators();
        this.formGroup.controls['state'].clearValidators();
        this.formGroup.controls['countryName'].clearValidators();
        this.formGroup.controls['stateName'].clearValidators();

        this.formGroup.controls['postalCode'].updateValueAndValidity();
        if (this.formGroup.controls['type']) {
            this.formGroup.controls['type'].updateValueAndValidity();
        }
        this.formGroup.controls['civicNo'].updateValueAndValidity();
        this.formGroup.controls['street'].updateValueAndValidity();
        this.formGroup.controls['city'].updateValueAndValidity();
        this.formGroup.controls['country'].updateValueAndValidity({ emitEvent: false });
        this.formGroup.controls['countryName'].updateValueAndValidity();
        this.formGroup.controls['state'].updateValueAndValidity();
        this.formGroup.controls['stateName'].updateValueAndValidity();
    }

    setAllAsRequired() {
        this.showAsterisk = true;
        this.updatePostalCodeValidators();
        if (this.formGroup.controls['type']) {
            this.formGroup.controls['type'].setValidators(Validators.required);
        }
        this.formGroup.controls['civicNo'].setValidators(Validators.required);
        this.formGroup.controls['street'].setValidators(Validators.required);
        this.formGroup.controls['city'].setValidators(Validators.required);
        this.formGroup.controls['country'].setValidators(Validators.required);
        this.formGroup.controls['countryName'].setValidators(Validators.required);

        this.formGroup.controls['postalCode'].updateValueAndValidity();
        if (this.formGroup.controls['type']) {
            this.formGroup.controls['type'].updateValueAndValidity();
        }
        this.formGroup.controls['civicNo'].updateValueAndValidity();
        this.formGroup.controls['street'].updateValueAndValidity();
        this.formGroup.controls['city'].updateValueAndValidity();
        this.formGroup.controls['state'].updateValueAndValidity();
        this.formGroup.controls['country'].updateValueAndValidity({ emitEvent: false });
        this.formGroup.controls['countryName'].updateValueAndValidity();
        this.formGroup.controls['state'].updateValueAndValidity();
        this.formGroup.controls['stateName'].updateValueAndValidity();
    }

    disableFormControl(controlName: string) {
        this.formGroup.controls[controlName].clearValidators();
        this.formGroup.controls[controlName].disable();
    }
}
