import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Reducers from './reducers/';
import * as Effects from './effects/';
@NgModule({
    imports: [
        StoreModule.forRoot({
            [Reducers.guideKey]: Reducers.guidesReducers,
            [Reducers.verificationsKey]: Reducers.verificationsReducers,
        }, { metaReducers: Reducers.metaReducers }),
        EffectsModule.forRoot([
            Effects.GuidesEffects,
            Effects.VerificationsEffects,
            Effects.HydrationEffects,
        ]),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: false,
            autoPause: true,
            features: {
                pause: false,
                lock: true,
                persist: true,
            },
        }),
    ],
})
export class AppStoreModule {}
