import { RequestVerificationNoteInput, toRequestVerificationNoteInput } from './requestVerificationNoteInput';
import { RequestVerificationOutput } from './requestVerificationOutput';
import { RequestVerificationStatus } from './requestVerificationStatus';

export interface RequestVerificationInput {
    verificationCustomField?: string;
    id?: number;
    status?: RequestVerificationStatus;
    verificationId: number;
    requestId?: number;
    countryId?: number;
    isExpress?: boolean;
    expressPrice?: number;
    verificationCustomPrice?: number;
    quantity: number;
    verificationPrice?: number;
    creationDate: Date;
    result: number;
    detailedResult: string;
    requestVerificationNotes: RequestVerificationNoteInput[];
}

export function To_RequestVerificationInput(requestVerificationOutput: RequestVerificationOutput, requestId: number): RequestVerificationInput {
    return {
        id: requestVerificationOutput.id,
        status: requestVerificationOutput.status,
        verificationId: requestVerificationOutput.verification?.id,
        requestId: requestId,
        countryId: requestVerificationOutput.countryId,
        verificationCustomPrice: requestVerificationOutput.verificationCustomPrice,
        isExpress: requestVerificationOutput.isExpress,
        expressPrice: requestVerificationOutput.expressPrice,
        quantity: requestVerificationOutput.quantity,
        verificationPrice: requestVerificationOutput.verificationPrice,
        creationDate: requestVerificationOutput.creationDate,
        result: requestVerificationOutput.result,
        detailedResult: requestVerificationOutput.detailedResult,
        verificationCustomField: requestVerificationOutput.verificationCustomField,
        requestVerificationNotes: requestVerificationOutput.requestVerificationNotes?.map((x) => toRequestVerificationNoteInput(x)),
    };
}
