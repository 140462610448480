import { Component, OnInit, Injector } from '@angular/core';
import {
    FileUploader,
    FileUploaderOptions,
    ParsedResponseHeaders,
    FileItem,
} from 'ng2-file-upload';
import { FilesService } from '@shared/services/files.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { GridBtnDeleteComponent } from '@app/shared/grid/grid-btn-delete/grid-btn-delete.component';
import { ColDef, GridApi } from '@ag-grid-enterprise/all-modules';
import { ToastrService } from 'ngx-toastr';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmDialogComponent } from '@app/shared/dialog/confirm-dialog/confirm-dialog.component';
import { FilesOutput } from '@shared/models/files/filesOutput';
import { DatePipe } from '@angular/common';
import { AppConsts } from '@shared/AppConsts';
import { Extensions } from '@shared/utils/extensions';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';

@Component({
    selector: 'app-files',
    templateUrl: './files.component.html',
    animations: [appModuleAnimation()],
    styleUrls: ['./files.component.scss'],
    providers: [DatePipe],
})
export class FilesComponent extends AppComponentBase implements OnInit {
    public uploader: FileUploader;

    public filterText = sessionStorage.getItem('files-search-text') ?? '';
    public files: FilesOutput[];

    private gridApi: GridApi;
    public columnDefs: ColDef[];

    private modalRef: BsModalRef;
    paginationNumberFormatter;
    paginationPageSize = 20;
    saving = false;

    private _uploaderOptions: FileUploaderOptions;

    constructor(
        injector: Injector,
        private filesService: FilesService,
        private _appAuthService: AppAuthService,
        private toastr: ToastrService,
        private _appLocalizationService: AppLocalizationService,
        private modalService: BsModalService,
        private datePipe: DatePipe,
    ) {
        super(injector);

        this.columnDefs = [
            {
                headerName: this.l('Name'),
                field: 'filesName',
                sortable: true,
                filter: true,
                width: 200,
                resizable: true,
            },
            {
                headerName: this.l('CreationTime'),
                field: 'creationTime',
                sortable: true,
                filter: true,
                width: 200,
                valueFormatter: (data) =>
                    this.datePipe.transform(
                        data.value,
                        AppConsts.dateTimeFormat
                    ),
                getQuickFilterText: (data) =>
                    this.datePipe.transform(
                        data.value,
                        AppConsts.dateTimeFormat
                    ),
                resizable: true,
            },
            {
                headerName: '',
                width: 200,
                resizable: false,
                filter: false,
                editable: false,
                suppressMenu: true,
                cellClass: 'text-end',
                cellRendererFramework: this.getDeleteBtn(),
                cellRendererParams: { action: (data) => this.deleteFile(data) },
            },
        ];

        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };
    }

    getDeleteBtn() {
        const btn = GridBtnDeleteComponent;
        if (this.isGranted('Pages.Management.Files.Delete')) {
            return btn;
        } else {
            return null;
        }
    }

    async ngOnInit() {
        this.files = await this.filesService
            .getFiles(true)
            .toPromise()
            .then((data) => data.result);

        this.initFileUploader();
    }

    initFileUploader() {
        this._uploaderOptions = {
            authToken: `Bearer ${this._appAuthService.getToken()}`,
            url: this.filesService.getUrlPostFile(),
            autoUpload: true,
            removeAfterUpload: true,
            allowedMimeType: AppConsts.allowedMimeType,
            maxFileSize: AppConsts.maxFileSize,
        };

        this.uploader = new FileUploader(this._uploaderOptions);
        this.uploader.onErrorItem = (item, response, status, headers) =>
            this.onErrorItem(item, response, status, headers);
        this.uploader.onSuccessItem = (item, response, status, headers) =>
            this.onSuccessItem(item, response, status, headers);
        this.uploader.onWhenAddingFileFailed = () =>
            this.eventService.trigger('app.file.adding.failed');

        this.uploader.onAfterAddingFile = (file) => {
            this.saving = true;
            file.withCredentials = false;
        };
    }

    onPageSizeChanged(e) {
        this.gridApi.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    onGridReady(params) {
        this.gridApi = params.api;
        if (screen.availWidth > 414) {
            this.gridApi.sizeColumnsToFit();
        }
        this.search();
    }

    onSuccessItem(
        item: FileItem,
        response: string,
        status: number,
        headers: ParsedResponseHeaders
    ): any {
        const data = JSON.parse(response);
        this.files.push(data.result);
        this.gridApi.updateRowData({ add: [data.result] });
        this.showSuccess('FileAddTitle', 'FileAdded');
        this.saving = false;
    }

    showSuccess(title: string, successMessage: string) {
        title = this._appLocalizationService.l(title);
        successMessage = this._appLocalizationService.l(successMessage);
        this.toastr.success(successMessage, title);
        this.saving = false;
    }

    onErrorItem(
        item: FileItem,
        response: string,
        status: number,
        headers: ParsedResponseHeaders
    ): any {
        const title = this.l('FileAddTitle');
        const message = this.l('FileAddIssue');
        this.toastr.error(message, title);
        this.saving = false;
    }

    deleteFile = (file) => {
        const fileName = this.files.find(
            (x) => x.publicId === file.publicId
        ).filesName;

        const initialState = {
            message: this.l('FileDeleteQuestion', [fileName]),
            positiveBtnLabel: this.l('Yes'),
            negativeBtnLabel: this.l('No'),
        };

        this.modalRef = this.modalService.show(ConfirmDialogComponent, {
            initialState,
            class: 'modal-sm modal-dialog-centered',
            backdrop: 'static',
        })

            ; (
                this.modalRef.content as ConfirmDialogComponent
            ).positiveBtnAction.subscribe(() => {
                this.filesService
                    .deleteFile(file.publicId, 'files')
                    .subscribe(
                        () => {
                            const fileIndex = this.files.findIndex(
                                (x) => x.publicId === file.publicId
                            );

                            if (fileIndex > -1) {
                                this.gridApi.updateRowData({
                                    remove: [this.files[fileIndex]],
                                });
                                this.files.splice(fileIndex, 1);
                            }

                            this.modalRef.hide();
                        },
                        () => {
                            const title = this.l('FileDeleteTitle');
                            const message = this.l('FileDeleteIssue');
                            this.toastr.error(message, title);

                            this.modalRef.hide();
                        }
                    );
            })

            ; (
                this.modalRef.content as ConfirmDialogComponent
            ).negativeBtnAction.subscribe(() => {
                this.modalRef.hide();
            });
    };

    getRowNodeId(data) {
        return data.publicId;
    }

    search() {
        const filterWithoutAccents = Extensions.replaceAccentedCharacters(
            this.filterText
        );
        this.gridApi?.setQuickFilter(filterWithoutAccents);
        sessionStorage.setItem('files-search-text', filterWithoutAccents);
    }

    refresh() {
        this.filterText = '';
        this.gridApi?.setQuickFilter('');
        sessionStorage.setItem('files-search-text', this.filterText);
    }

    onSortChanged(params) {
        sessionStorage.setItem(
            'files-sortModel',
            JSON.stringify(params.api.getSortModel())
        );
    }

    onFilterChanged(params) {
        sessionStorage.setItem(
            'files-filterModel',
            JSON.stringify(params.api.getFilterModel())
        );
    }

    onFirstDataRendered() {
        const sortModel = JSON.parse(sessionStorage.getItem('files-sortModel'));
        const filterModel = JSON.parse(
            sessionStorage.getItem('files-filterModel')
        );
        if (sortModel) {
            this.gridApi.setSortModel(sortModel);
        }
        if (filterModel) {
            this.gridApi.setFilterModel(filterModel);
        }
    }

    resetFilters() {
        sessionStorage.setItem('files-filterModel', null);
        this.gridApi.setFilterModel(null);
    }

    resetSort() {
        sessionStorage.setItem('files-sortModel', null);
        this.gridApi.setSortModel(null);
    }
}
