<form [formGroup]="form" autocomplete="off" class="card card-custom">
    <div class="row">
        <div class="form-group col-md-3 inline-block align-left px-3">
            <label for="lastName" class="block">{{"LastName" | localize}} <span class="asterix">*</span></label>
            <input id="lastName" appTextTransform="upper" formControlName="lastName" type="text" name="lastName" class="form-control"
                minlenth="1" maxlength="256" (focusout)="searchCandidate(searchOnFocusOut)" required>
        </div>
        <div class="form-group col-md-3 inline-block align-left px-3">
            <label for="firstName" class="block">{{"FirstName" | localize}} <span class="asterix">*</span></label>
            <input id="firstName" appTextTransform="title" formControlName="firstName" type="text" name="firstName" class="form-control"
                minlength="1" maxlength="256" (focusout)="searchCandidate(searchOnFocusOut)" required>
        </div>
        <div class="form-group col-md-3 inline-block align-left px-3">
            <label for="birthdate" class="block">{{"Birthdate" | localize}} {{'FormatDate' | localize}}
                <span *ngIf="searchOnFocusOut" class="asterix">*</span>
            </label>
            <input id="birthdate" formControlName="birthdate" type="text" name="birthdate" class="form-control"
                bsDatepicker [maxDate]="maxDate" (change)="searchCandidate(searchOnFocusOut)"
                [bsConfig]="{ dateInputFormat: 'YYYY/MM/DD', containerClass: 'theme-red',  adaptivePosition: true }">
        </div>
        <div class="form-group col-md-2 inline-block align-left px-3" [ngClass]="(searchOnFocusOut) ? 'col-md-3':'col-md-2'">
            <label for="sex" class="block">{{"Sex" | localize}}
                <span *ngIf="searchOnFocusOut" class="asterix">*</span>
            </label>
            <select id="sex" formControlName="sex" type="sex" name="sex" class="form-control"
                (change)="searchCandidate(searchOnFocusOut)">
                <option *ngIf="!searchOnFocusOut" value=''></option>
                <option value='0'>{{"Male" | localize}}</option>
                <option value='1'>{{"Female" | localize}}</option>
                <option value='2'>{{"Other" | localize}}</option>
            </select>
        </div>
        <div class="form-group col-md-1 mt-2" role="actions" *ngIf="!searchOnFocusOut">
            <button type="submit" id="verify-btn" class="btn btn-primary margin-top-20" (click)="searchCandidate(true)"
                [disabled]="form.invalid">
                <i class="la la-search"></i>
            </button>
        </div>
    </div>
</form>
