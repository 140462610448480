import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment as env } from 'environments/environment';
import { PersonnalReferencesInput } from '@shared/models/CandidatDetails/personnalReferences/personnalReferencesInput';
import { PersonnalReferencesOutput } from '@shared/models/CandidatDetails/personnalReferences/personnalReferencesOutput';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PersonnalReferenceService {
    private http: HttpClient;
    private baseUrl: string;

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = env.api;
    }

    private getUrl(url: string): string {
        let url_ = this.baseUrl + url;
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    }

    addPersonnalReference(personnalReference: PersonnalReferencesInput): Observable<any> {
        let url_ = this.getUrl('/api/PersonalReference');
        return this.http.post<PersonnalReferencesOutput>(url_, personnalReference);
    }

    getPersonnalReferencesByRequest(requestPublicId: string): Observable<any> {
        let url_ = this.getUrl(`/api/PersonalReference/GetPersonnalReferencesByRequest/${requestPublicId}`);
        return this.http.get<any>(url_);
    }

    getPersonnalReferencesByCandidat(candidatePublicId: string): Observable<any> {
        let url_ = this.getUrl(`/api/PersonalReference/GetPersonnalReferencesByCandidat/${candidatePublicId}`);
        return this.http.get<any>(url_);
    }

    updatePersonnalReferences(personalReferenceInput: PersonnalReferencesInput): Observable<any> {
        let url_ = this.getUrl('/api/PersonalReference');
        return this.http.put<any>(url_, personalReferenceInput);
    }

    deletePersonnalReferences(personalReferenceInputPublicId: string): Observable<any> {
        let url_ = this.getUrl('/api/PersonalReference/DeletePersonnalReference');
        let params = new HttpParams().set('personalReferenceInputPublicId', personalReferenceInputPublicId);
        return this.http.post<any>(url_, null, { params });
    }
}
