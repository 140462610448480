import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { CandidateListOutput } from '@shared/models/candidat/candidateListOutput';
import { CandidatOutput } from '@shared/models/candidat/candidatOutput';
import { candidatSex } from '@shared/models/candidat/candidatSex';
import { candidatstatus } from '@shared/models/candidat/candidatStatus';
import { RequestOutput } from '@shared/models/request/requestOutput';
import { CandidatsService } from '@shared/services/candidats.service';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'request-wizard-candidate-search',
    templateUrl: './request-wizard-candidate-search.component.html',
    styleUrls: [ './request-wizard-candidate-search.component.scss' ],
})
export class RequestWizardCandidateSearchComponent implements OnInit {
    @Input() public form: UntypedFormGroup;
    @Output() public formChange: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
    @Input() public candidates: CandidateListOutput[];
    @Output() public candidatesChange: EventEmitter<CandidateListOutput[]> = new EventEmitter<CandidateListOutput[]>();
    @Input() public selectedCandidate: CandidatOutput;
    @Output() public selectedCandidateChange: EventEmitter<CandidatOutput> = new EventEmitter<CandidatOutput>();
    @Input() public request: RequestOutput;
    @Output() public requestChange: EventEmitter<RequestOutput> = new EventEmitter<RequestOutput>();
    @Input() public searchDone: boolean;
    @Output() public searchDoneChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() public searchOnFocusOut: boolean;
    @Input() public resetCandidate: BehaviorSubject<boolean>;
    @Input() public isDriverLicenseRequired: boolean;
    @Input() public resetDriverLicenseRequired: BehaviorSubject<boolean>;
    @Input() public candidateChanged: BehaviorSubject<UntypedFormGroup>;

    maxDate: Date;

    constructor(private candidateService: CandidatsService) {
        this.maxDate = new Date();
    }

    ngOnInit(): void {
        this.resetCandidate?.subscribe((event) => {
            if (event) {
                this.selectedCandidate = null;
                this.request.candidat = new CandidatOutput();
                this.requestChange.emit(this.request);
                this.searchCandidate(true);
            }
        });

        this.candidateChanged?.subscribe((event) => {
            if (event) {
                this.form.controls[ 'firstName' ].setValue(event.controls[ 'firstName' ].value);
                this.form.controls[ 'lastName' ].setValue(event.controls[ 'lastName' ].value);
                this.form.controls[ 'sex' ].setValue(event.controls[ 'sex' ].value);
                this.form.controls[ 'birthdate' ].setValue(event.controls[ 'birthdate' ].value);
            }
        });

        if (!this.searchOnFocusOut) {
            this.setOptionalElements();
        } else {
            this.setRequiredElements();
        }

        this.searchCandidate(true);

        this.resetDriverLicenseRequired?.subscribe();
    }

    emptySelectedCandidate() {
        this.selectedCandidate = null;
        this.selectedCandidateChange.emit(this.selectedCandidate);
        this.request.candidat = new CandidatOutput();
        this.requestChange.emit(this.request);
    }

    setRequiredElements() {
        this.form.controls[ 'sex' ].setValidators(Validators.required);
        this.form.controls[ 'birthdate' ].setValidators(Validators.required);
        this.form.controls[ 'sex' ].updateValueAndValidity();
        this.form.controls[ 'birthdate' ].updateValueAndValidity();
    }

    setOptionalElements() {
        this.form.controls[ 'birthdate' ].clearValidators();
        this.form.controls[ 'sex' ].clearValidators();
        this.form.controls[ 'sex' ].updateValueAndValidity();
        this.form.controls[ 'birthdate' ].updateValueAndValidity();
    }

    searchCandidate(focusOut: boolean) {
        if ((focusOut && this.searchOnFocusOut) || focusOut) {
            this.formChange.emit(this.form);
            const firstName: string = this.form.get('firstName').value;
            const lastName: string = this.form.get('lastName').value;

            if (firstName !== '' && lastName !== '') {
                const birthDate: Date = this.form.get('birthdate').value == '' ? null : this.form.get('birthdate').value;
                const sex: candidatSex = this.form.get('sex').value == '' ? null : this.form.get('sex').value;

                this.candidateService.searchCandidates(this.request.organization.id, firstName, lastName, birthDate, sex).subscribe((response) => {
                    //Initialize selectedCandidat null
                    this.emptySelectedCandidate();
                    this.candidates = response.result.filter((candidate) => candidate.status === candidatstatus.Actif);
                    this.candidatesChange.emit(this.candidates);
                    this.searchDone = true;
                    this.searchDoneChange.emit(this.searchDone);
                });
            }
        }
    }
}
