<div bsModal role="dialog" class="modal-lg">
    <form [formGroup]="formGroup" #personnalRefencesUpdateForm="ngForm" novalidate (ngSubmit)="save()"
        autocomplete="off">
        <div class="modal-header">
            <h4 class="modal-title pull-left">
                <h5>{{"PersonnalRefences" | localize}}</h5>
            </h4>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="form-group col-md-6">
                    <label for="firstName">{{"FirstName" | localize}} <span class="asterix">*</span></label>
                    <input id="firstName" formControlName="firstName" type="text" name="firstName" class="form-control"
                        maxlength="256">
                    <validation-messages [formCtrl]="formGroup.get('firstName')"></validation-messages>
                </div>
                <div class="form-group col-md-6">
                    <label for="lastName">{{"LastName" | localize}} <span class="asterix">*</span></label>
                    <input id="lastName" formControlName="lastName" type="text" name="lastName" class="form-control"
                        maxlength="256">
                    <validation-messages [formCtrl]="formGroup.get('lastName')"></validation-messages>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label for="relationship">{{"Relationship" | localize}} <span class="asterix">*</span></label>
                    <input id="relationship" formControlName="relationship" type="text" name="relationship"
                        class="form-control" maxlength="256">
                    <validation-messages [formCtrl]="formGroup.get('relationship')"></validation-messages>
                </div>
                <div class="form-group col-md-6">
                    <label for="email">{{"EmailAddress" | localize}} <span class="asterix">*</span></label>
                    <input id="email" formControlName="email" type="email" name="email" class="form-control"
                        maxlength="256">
                    <validation-messages [formCtrl]="formGroup.get('email')"></validation-messages>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label for="phoneNumber">{{"PhoneNumber" | localize }} <span class="asterix">*</span></label>
                    <input id="phoneNumber" formControlName="phoneNumber" type="text" name="phoneNumber"
                        Class="form-control" maxlength="256" placeholder="+1(999) 999-9999" mask="(000) 000-0000"
                        prefix="+ 1 " [dropSpecialCharacters]="false"
                        [specialCharacters]="[ '+', '1', '(' , ')' , ' ', '-' ]" [showMaskTyped]="true" />
                    <validation-messages [formCtrl]="formGroup.get('phoneNumber')">
                    </validation-messages>
                </div>
                <div class="form-group col-md-6">
                    <label for="phoneExtension">{{"PhoneNumberExtension" | localize}}</label>
                    <input id="phoneExtension" formControlName="phoneExtension" type="number" name="phoneExtension"
                        class="form-control" maxlength="256" min="0">
                    <validation-messages [formCtrl]="formGroup.get('phoneExtension')"></validation-messages>
                </div>
            </div>
            <div class="form-group">
                <label for="location">{{"Location" | localize}} <span class="asterix">*</span></label>
                <input id="location" formControlName="location" type="text" name="location" class="form-control"
                    maxlength="256">
                <validation-messages [formCtrl]="formGroup.get('location')"></validation-messages>
            </div>
        </div>
        <div class="modal-footer">
            <button [disabled]="saving" type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{"Cancel" |
                localize}}</button>
            <button type="submit" class="btn btn-primary" [buttonBusy]="saving"
                [disabled]="!personnalRefencesUpdateForm.form.valid" [busyText]="l('SavingWithThreeDot')"
                (click)="save()"><i class="fa fa-save"></i>
                <span>{{"Save" | localize}}</span></button>
        </div>
    </form>
</div>