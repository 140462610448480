import { Component, Input } from '@angular/core';
import { CandidatOutput } from '@shared/models/candidat/candidatOutput';
import { organizationOutput } from '@shared/models/organization/organizationOutput';

@Component({
    selector: 'app-report-header',
    templateUrl: './report-header.component.html',
    styleUrls: ['./report-header.component.scss']
})
export class ReportHeaderComponent {
    @Input() candidat: CandidatOutput;
    @Input() organization: organizationOutput;
    @Input() organizationLogo: string;
    @Input() lang: string;
}
