import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-grid-checkbox-is-mandatory',
    templateUrl: './grid-checkbox-is-mandatory.component.html'
})
export class GridCheckboxIsMandatoryComponent implements OnInit, AgRendererComponent {
    public params: any;
    public checked: boolean;
    constructor() {}

    ngOnInit(): void {}

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    refresh(event): boolean {
        if (event.currentTarget.checked) {
            this.checked = true;
        } else {
            this.checked = false;
        }

        this.params.data.isMandatory = this.checked;
        this.params.action(this.params.data);

        return true;
    }
}
