import { candidatstatus } from './candidatStatus';
import { candidatSex } from './candidatSex';
import { organizationOutput } from '../organization/organizationOutput';
import { UntypedFormGroup } from '@angular/forms';
import { CandidatOutput } from './candidatOutput';
import { setDateTimeToUTC } from '@shared/helpers/DateHelper';
import { CRUDAction } from '../shared/CRUDAction';
import { candidatLanguage } from './candidatLanguage';

export class CandidatInput {
    id?: number;
    publicId: string;
    firstName: string;
    lastName: string;
    sex: candidatSex;
    language: candidatLanguage;
    birthdate: Date;
    otherNames: string;
    maidenName: string;
    email: string;
    birthPlace: string;
    socialInsuranceNumber: string;
    driverLicenceNumber: string;
    phoneNumber: string;
    status?: candidatstatus;
    organization?: organizationOutput;
    creationTime?: Date;
    phoneExtension: string;

    constructor() { }

    static fromOutput(outputData: CandidatOutput) {
        const inputData: CandidatInput = {
            id: outputData.id,
            publicId: outputData.publicId,
            firstName: outputData.firstName,
            lastName: outputData.lastName,
            sex: outputData.sex,
            language: outputData.language,
            birthdate: outputData.birthdate,
            otherNames: outputData.otherNames,
            maidenName: outputData.maidenName,
            birthPlace: outputData.birthPlace,
            socialInsuranceNumber: outputData.socialInsuranceNumber,
            driverLicenceNumber: outputData.driverLicenceNumber,
            phoneNumber: outputData.phoneNumber,
            phoneExtension: outputData.phoneExtension,
            status: outputData.status,
            organization: outputData.organization,
            email: outputData.email,
            creationTime: outputData.creationTime,
        };

        return inputData;
    }

    static fromFormControl(publicId: string, formData: UntypedFormGroup, action: CRUDAction, outputData?: CandidatOutput) {
        // https://valor-software.com/ngx-bootstrap/#/datepicker
        // Keep in mind that value of ngModel is Date object for datepicker
        const birthdateWithTime: Date = new Date(formData.controls[ 'birthdate' ].value);
        const birthdateUTC = setDateTimeToUTC(birthdateWithTime);

        const inputData: CandidatInput = {
            publicId: publicId,
            firstName: formData.controls[ 'firstName' ].value,
            lastName: formData.controls[ 'lastName' ].value,
            sex: formData.controls[ 'sex' ].value,
            language: formData.controls[ 'language' ].value,
            birthdate: birthdateUTC,
            otherNames: formData.controls[ 'otherNames' ].value,
            maidenName: formData.controls[ 'maidenName' ].value,
            birthPlace: formData.controls[ 'birthPlace' ].value,
            socialInsuranceNumber: formData.controls[ 'socialInsuranceNumber' ].value,
            driverLicenceNumber: formData.controls[ 'driverLicenceNumber' ].value,
            phoneNumber: formData.controls[ 'phoneNumber' ].value,
            phoneExtension: formData.controls[ 'phoneExtension' ].value,
            email: formData.controls[ 'email' ].value,
        };

        if (outputData != null) {
            if (action === CRUDAction.Update) {
                inputData.id = outputData.id;
                inputData.status = formData.controls[ 'status' ].value;
                inputData.organization = outputData.organization;
                inputData.creationTime = outputData.creationTime;
            } else if (action === CRUDAction.Retrieve) {
                inputData.id = outputData.id;
                inputData.status = outputData.status;
                inputData.organization = outputData.organization;
                inputData.creationTime = outputData.creationTime;
            }
        }

        return inputData;
    }
}
