// tslint:disable-next-line:class-name
export interface outputResult {
    outputStatus: outputStatus;
    message: string;
}

export enum outputStatus {
    success = 0,
    fail = 1
}
