<div class="row align-items-center">
    <div class="col-xl-12">
        <div class="form-group align-items-center">
            <div class="input-group">
                <input [(ngModel)]="filterText" type="text" id="filterText" [placeholder]="l('Search')" autoFocus
                class="form-control m-input" (keydown.enter)="search()" data-test-id="invoice-list-search-input" (ngModelChange)="search()" />
              <span class="input-group-btn">
                <button (click)="search()" class="btn btn-primary" type="submit" data-test-id="invoice-list-search-btn">
                  <i class="flaticon-search-1" [attr.aria-label]="l('Search')">
                  </i>
                </button>
              </span>
              <span class="input-group-btn" style="padding-left:5px;">
                <button class="btn btn-secondary" (click)="refresh()"><i class="la la-refresh"></i>
                  {{'Refresh' | localize}}</button>
              </span>
            </div>
        </div>
    </div>
</div>
<div>
    <div class="action-container">
        <div class="input-group-btn">
            <button class="btn btn-outline-secondary" (click)="resetFilters()">
                <i class="la la-refresh"></i>
                {{ "grid.resetFilters" | localize}}
            </button>
            <button class="btn btn-outline-secondary" (click)="resetSort()">
                <i class="la la-refresh"></i>
                {{ "grid.resetColumns" | localize}}
            </button>
        </div>
        <grid-pagination class="grid-pagination" (sizeValue)="onPageSizeChanged($event)">
        </grid-pagination>
    </div>
    <ag-grid-angular #agGrid class="ag-theme-material gardium-grid"
        [paginationPageSize]="paginationPageSize" [paginationNumberFormatter]="paginationNumberFormatter"
        [gridOptions]="gridOptions" 
        [rowSelection]="rowSelection" [rowData]="rowData" [pagination]="true" domLayout="autoHeight"
        [columnDefs]="columnDefs" (selectionChanged)="onSelectionChanged()" (gridReady)="onGridReady($event)"
        [defaultColDef]="defaultColDef" (sortChanged)="onSortChanged($event)" (filterChanged)="onFilterChanged($event)"
        [updateList]="'invoiceList'">
    </ag-grid-angular>
</div>