import { Injectable } from '@angular/core';
import { resultsForVerificationsId } from '@shared/models/requestVerification/requestVerificationResults';
import { VerificationOutput } from '@shared/models/verification/verificationOutput';

@Injectable({
    providedIn: 'root'
})
export class ReportLocalizationService {

    readonly traductions = {
        'english': {
            'RequestReportResultSummaryTitle': 'SUMMARY OF RESULTS',
            'RequestReportResultSummaryCustomerId': 'CUSTOMER ID',
            'RequestReportResultSummaryNameLabel': 'REPORT OF',
            'RequestReportResultSummaryNameSubLabel': 'Given Name SURNAME',
            'RequestReportResultSummaryDateOfBirthLabel': 'DATE OF BIRTH',
            'RequestReportResultSummaryDateOfBirthSubLabel': 'YYYY-MM-DD',
            'RequestReportResultSummarySectionTitle': 'SUMMARY TABLE OF THE RESULT(S)',
            'RequestReportResultSummaryVerificationName': 'NAME OF THE VERIFICATION',
            'RequestReportResultSummaryResult': 'RESULT',
            'RequestReportResultSummaryCompanySectionTitle': 'Company’s Information',
            'RequestReportResultSummaryConfidentialityTitle': 'CONFIDENTIALITY',
            'RequestReportResultSummaryConfidentiality': 'These documents transmitted by email are intended only for the person or entity to which they are addressed to. They can contain confidential information and be compelled with the professional secrecy. The confidentiality and the professional secrecy remain in spite of being sent to the wrong person. If you are not the recipient concerned or the person charged to give this document to its recipient, please immediately destroy this document and advise us. Any distribution, reproduction or another use of this document by a recipient not concerned is strictly prohibited.',
            'RequestReportResultSummaryCautionTitle': 'CAUTION',
            'RequestReportResultSummaryCaution': 'Despite all our efforts, we cannot guarantee that the data obtained from various information suppliers, which is contained in this report, is partially or entirely accurate. This document is confidential and cannot be released and/or altered in any way without prior written authorization by those individuals who contributed to its production.',
            'RequestReportResultSummaryTimestamp': 'Report completed on',
            'RequestReportResultSummaryLastPageFooterMessage': 'This completes the research we’ve been asked to do. If additional details are required, please let us know. We remain at your disposal for any further information on this subject.',
            'RequestReportResultSummaryFileNotesTitle': 'FILE NOTES',
            'Result': 'Result',
            'DetailedResult': 'Detailed result',
            'notAvailable': 'Not available',
            'Low': 'Low',
            'Fair': 'Fair',
            'Good': 'Good',
            'VeryGood': 'Very good',
            'Excellent': 'Excellent',
            'Completed': 'Completed',
            'Incomplete': 'Incomplete',
            'Valid': 'Valid',
            'Invalid': 'Invalid',
            'Positive': 'Positive',
            'Negative': 'Negative',
            'NotCompleted': 'Not completed',
            'CompletedValid': 'Completed - valid',
            'Confirmed': 'Confirmed',
            'FurtherCheckRecommended': 'Further Check Recommended',
            'DetailedResultsNegativeCIPC': 'Based solely on the name(s) and date of birth provided and the criminal record information declared by the applicant, a search of the RCMP National Repository of Criminal Records did not identify any records with the name(s) and date of birth of the applicant.  Positive identification that a criminal record does or does not exist at the RCMP National Repository of Criminal Records can only be confirmed by fingerprint comparison. Delays do exist between a conviction being rendered in court, and the details being accessible on the RCMP National Repository of Criminal Records.  Not all offences are reported to the RCMP National Repository of Criminal Records. <b>NEGATIVE</b>',
            'DetailedResultsConfirmed': 'Based solely on the name(s) and date of birth  provided and the criminal record information declared by the applicant, a search of the RCMP National Repository of Criminal Records has resulted in a possible match to a registered criminal record. Positive identification that a criminal record does or does not exist at the RCMP National Repository of Criminal Records can only be confirmed by fingerprint comparison.   As such, the criminal record information declared by the applicant does not constitute a Certified Criminal Record by the RCMP. Delays do exist between a conviction being rendered in court, and the details being accessible on the RCMP National Repository of Criminal Records.  Not all offences are reported to the RCMP National Repository of Criminal Records. <b>CONFIRMED AS DECLARED</b>',
            'DetailedResultsIncomplete': 'Based solely on the name(s) and date of birth provided and the criminal record information declared by the applicant, a search of the RCMP National Repository of Criminal Records could not be completed. Positive identification that a criminal record does or does not exist requires the applicant to submit fingerprints to the RCMP National Repository of Criminal Records by an authorized police service or accredited private fingerprinting company. Delays do exist between a conviction being rendered in court, and the details being accessible on the RCMP National Repository of Criminal Records. Not all offences are reported to the RCMP National Repository of Criminal Records. <b>INCOMPLETE</b>',
            'DetailedResultsFurtherCheckRecommended':'Based solely on the name(s) and date of birth provided, search of the National Criminal Record repository maintained by the RCMP could not be completed. In order to complete the request, the applicant is required  to have fingerprints company. Positive identification that a criminal record may or may not exist at the National Criminal Records repository. A local indices check may or may not reveal criminal record convictions that have not been reported to the National Criminal Records repository. <b>FURTHER CHECK RECOMMENDED</b>',
            'DetailedResultsNegative': 'Based solely on the name(s) and date of birth provided and the criminal record information declared by the applicant, a search of the RCMP National Repository of Criminal Records did not identify any records with the name(s) and date of birth of the applicant.  Positive identification that a criminal record does or does not exist at the RCMP National Repository of Criminal Records can only be confirmed by fingerprint comparison.  Delays do exist between a conviction being rendered in court, and the details being accessible on the RCMP National Repository of Criminal Records.  Not all offences are reported to the RCMP National Repository of Criminal Records. <b>NEGATIVE</b>',
            'DetailedResultsNegativeVDJP': 'No known criminal records found in the Quebec court ledger at the time of the research.',
            'DetailedResultsNegativePCQ': 'No known civil records found in the Quebec court ledger at the time of the research.',
            'DetailedResultsNegativeCSPQ': 'No known statutory records found in the Quebec court ledger at the time of the research.',
            'DetailedResultsGood': 'The candidate’s credit profil matches the good category (TO COMPLETE).',
            'DetailedResultsExcellent': 'The candidate’s credit profil matches the excellent category (TO COMPLETE).',
            'DetailedResultsLow': 'The candidate’s credit profil matches the low *low risky* category (TO COMPLETE). Raison : ',
            'DetailedResultsFair': 'The candidate’s credit profil matches the fair category (TO COMPLETE). Raison : ',
            'DetailedResultsVeryGood': 'The candidate’s credit profil matches the very good category (TO COMPLETE).',
            'DetailedResultsNotAvailable': 'The candidate has no credit history at the time of the research. ',
            'DetailedResultsValid': 'The driver’s licence is valid and contains classes (TO COMPLETE)',
            'DetailedResultsInvalid': 'The driver\'s licence is not valid at the time of the research.',
            'DetailedResultsComplete': 'Consult the report.',
            'DetailedResultsNegativePMUN': 'No known municipal records found in the Quebec court ledger at the time of the research.',
            'DetailedResultsNegativeINTER': 'The information contained in this report has been collected pursuant to a request from client and from sources deemed reliable. Client is cautioned that this material is privileged information. While every effort has been made to verify the accuracy and validity of this information, Gardium Security does not guarantee the accuracy or completeness of this report and cannot be held responsible for any actions which client may take, or cause others to take, a result of its use. Background check results will contain information reported by the source of information at a minimum of 7 years back. <b>NO REPORTABLE RECORDS FOUND</b>',
            'DetailedResultsNegativeDJI': 'Based on the information provided to us searched for public records in the sources referenced herein for criminal history information as permitted by country laws. \'No Reportable Records Found\' means that our researchers could not locate a record that matched at least two personal identifiers (i.e., Name, SSN, Date of Birth, Address) for the subject in that jurisdiction. Further investigation into additional jurisdictions, or utilization of additional identifying information, may be warranted. <b>NO FILE TO REPORT (NEGATIVE)</b>',
            'DetailedResultsCompleteASSP': 'The candidate is a standing member of (TO COMPLETE)',
            'DetailedResultsNotCompletedASSP': 'The candidate is not a member of (TO COMPLETE)',
            'DetailedResultsCompleteFACA': 'The registrar\'s office of (TO COMPLETE) confirms that the candidate has obtained (TO COMPLETE). Diploma awarded on (TO COMPLETE) ',
            'DetailedResultsNotCompleted': 'The candidate doesn\'t possess a diploma. For more information please send us a copy of the candidate\'s diploma. ',
            'DetailedResultsCompleteValidFACA+': 'The registrar\'s office of (TO COMPLETE) confirms that the candidate has obtained (TO COMPLETE). Diploma awarded on (TO COMPLETE) ',
            'DetailedResultsConsultReferencesReports': 'Consult the reference report(s).'
        },
        'french': {
            'RequestReportResultSummaryTitle': 'SOMMAIRE DES RÉSULTATS',
            'RequestReportResultSummaryCustomerId': 'Numéro de client',
            'RequestReportResultSummaryNameLabel': 'Rapport de',
            'RequestReportResultSummaryNameSubLabel': 'Prénom NOM',
            'RequestReportResultSummaryDateOfBirthLabel': 'DATE DE NAISSANCE',
            'RequestReportResultSummaryDateOfBirthSubLabel': 'AAAA-MM-JJ',
            'RequestReportResultSummarySectionTitle': 'TABLEAU SOMMATIF DES RÉSULTAT(S)',
            'RequestReportResultSummaryVerificationName': 'NOM DE LA VÉRIFICATION',
            'RequestReportResultSummaryResult': 'RÉSULTAT',
            'RequestReportResultSummaryCompanySectionTitle': 'Information compagnie',
            'RequestReportResultSummaryConfidentialityTitle': 'CONFIDENTIALITÉ',
            'RequestReportResultSummaryConfidentiality': 'Ces documents transmis par voie électronique sont destinés uniquement à la personne ou à l\'entité à laquelle ils sont adressés. Ils peuvent contenir des informations confidentielles et soumis au secret professionnel. La confidentialité et le secret professionnel demeurent malgré l\'envoi de ce document à la mauvaise personne. Si vous n\'êtes pas le destinataire prévu ou la personne.',
            'RequestReportResultSummaryCautionTitle': 'MISE EN GARDE',
            'RequestReportResultSummaryCaution': 'Malgré tous les efforts déployés, les parties ayant participé à l’élaboration du présent rapport, en totalité ou en partie, ne peuvent garantir l’exactitude des renseignements transmis par les différents fournisseurs d’informations utilisés. Le présent document est confidentiel et ne pourra être cédé et/ou reproduit de quelque façon que ce soit sans avoir obtenu au préalable le consentement écrit des parties ayant élaboré ledit rapport.',
            'RequestReportResultSummaryTimestamp': 'Rapport complété le',
            'RequestReportResultSummaryLastPageFooterMessage': 'Ceci complète la recherche qui nous a été demandée. Si des détails supplémentaires sont requis, veuillez-nous en informer. Nous restons à votre disposition pour toute information complémentaire à ce sujet.',
            'RequestReportResultSummaryFileNotesTitle': 'NOTE AU DOSSIER',
            'Result': 'Résultat',
            'DetailedResult': 'Résultat détaillé',
            'notAvailable': 'Sans objet',
            'Low': 'Faible',
            'Fair': 'Passable',
            'Good': 'Bon',
            'VeryGood': 'Très bon',
            'Excellent': 'Excellent',
            'Completed': 'Complété',
            'Incomplete': 'Incomplet',
            'Valid': 'Valide',
            'Invalid': 'Invalide',
            'Positive': 'Positif',
            'Negative': 'Négatif',
            'NotCompleted': 'Non-complété',
            'CompletedValid': 'Complété - valide',
            'Confirmed': 'Confirmé',
            'FurtherCheckRecommended': 'Vérif. supp. recommandée',
            'DetailedResultsNegative': 'La seule recherche nominale, à l\'aide du nom, de la date de naissance et des antécédents judiciaires fournis par le demandeur, dans le répertoire national des casiers judiciaires tenu par la GRC, n\'a révélé aucun casier judiciaire pour une personne dont le nom et date de naissance correspondent à ceux du demandeur. Seule la comparaison des empreintes digitales permet de confirmer l\'existence ou l\'inexistence d\'un casier judiciaire dans le répertoire national des casiers judiciaires tenu par la GRC. Il peut y avoir un certain délai entre le prononcé d\'une déclaration de culpabilité et l\'accessibilité des données dans le répertoire national des casiers judiciaires tenu par la GRC. Toutes les infractions ne sont pas non plus nécessairement versées au répertoire national. <b>NEGATIF</b>',
            'DetailedResultsNegativePCQ': 'Aucun dossier au plumitif civil connu ou localisé au Québec au moment de la recherche.',
            'DetailedResultsNegativeCSPQ': 'Aucune infraction connue au Code de la route du Québec au moment de la recherche.',
            'DetailedResultsNegativeVDJP': 'Aucun dossier au plumitif criminel connu ou localisé au Québec au moment de la recherche.',
            'DetailedResultsNegativePMUN': 'Aucun dossier au plumitif municipal connu ou localisé au Québec au moment de la recherche.',
            'DetailedResultsNegativeINTER': 'Basé uniquement sur le (s) nom et date de naissance fournie et les informations du casier judiciaire déclaré par le demandeur, une recherche dans le répertoire national du FBI sur les casiers judiciaires n\'a pas identifié tous les dossiers avec le nom (s) et date de naissance du demandeur. L\'identification positive d\'un casier judiciaire peut ou ne pas exister dans le répertoire national du FBI des casiers judiciaires. L’information ne peut être confirmée que par la comparaison des empreintes digitales. Des retards existent entre une condamnation étant rendue à la cour, et les détails étant accessibles sur le répertoire national du FBI des casiers judiciaires. Ce ne sont pas tous les délits qui sont signalés au FBI et qui sont inscrits dans le répertoire national des casiers judiciaires. <b>NÉGATIF</b>',
            'DetailedResultsNegativeDJI': 'Sur la base des informations qui nous ont été fournies, nous avons recherché des documents publics dans les sources référencées ici pour des informations sur les antécédents criminels, conformément aux lois du pays. « Aucun enregistrement à signaler trouvé » signifie que nos chercheurs n\'ont pas pu localiser un enregistrement correspondant à au moins deux identifiants personnels (c\'est-à-dire, nom, NAS, date de naissance, adresse) pour le sujet dans cette juridiction. Une enquête plus approfondie dans d\'autres juridictions ou l\'utilisation d\'informations d\'identification supplémentaires peut être justifiée. <b>AUCUN DOSSIER A SIGNALER (NEGATIF)</b>',
            'DetailedResultsComplete': 'Consulter le rapport.',
            'DetailedResultsCompleteASSP': 'Le candidat est membre en règle (CHAMP LIBRE)',
            'DetailedResultsNotCompletedASSP': 'Le candidat ne fait pas partie de l\'ordre (CHAMP LIBRE)',
            'DetailedResultsCompleteFACA': 'Le bureau du registraire (CHAMP LIBRE) nous confirme l\'obtention (CHAMP LIBRE) du candidat. Diplôme octroyé (CHAMP LIBRE)',
            'DetailedResultsCompleteADD': '**devons avoir un champ pour ajout le nom de la vérification (menu déroulant) + résutat champ libre***',
            'DetailedResultsConfirmed': 'La seule recherche nominale, à l\'aide du nom, de la date de naissance et des antécédents judiciaires fournis par le demandeur, dans le répertoire national des casiers judiciaires tenu par la GRC, a révélé une correspondance possible avec un casier judiciaire enregistré. Seule la comparaison des empreintes digitales permet de confirmer l\'existence ou l\'inexistence d\'un casier judiciaire dans le répertoire national des casiers judiciaires tenu par la GRC. Il peut y avoir un certain délai entre le prononcé d\'une déclaration de culpabilité et l\'accessibilité des données dans le répertoire national des casiers judiciaires tenu par la GRC. Toutes les infractions ne sont pas nécessairement versées au répertoire national. <b>CONFIRMÉ SELON LA DÉCLARATION</b>',
            'DetailedResultsIncomplete': 'La seule recherche nominale, à l\'aide du nom, de la date de naissance et des antécédents judiciaires fournis par le demandeur, dans le répertoire national des casiers judiciaires tenu par la GRC, n\'a pu être menée à terme. Pour que l\'on puisse terminer le traitement de la demande, le demandeur doit faire transmettre ses empreintes digitales au répertoire national des casiers judiciaires par un service de police autorisé ou une entreprise privée de dactyloscopie accréditée. Il peut y avoir un certain délai entre le prononcé d\'une déclaration de culpabilité et l\'accessibilité des données dans le répertoire national des casiers judiciaires tenu par la GRC. Toutes les infractions ne sont pas nécessairement versées au répertoire national. <b>INCOMPLET</b>',
            'DetailedResultsFurtherCheckRecommended':'En se basant uniquement sur le(s) nom(s) et la date de naissance fournis, la recherche dans le répertoire National des casiers judiciaires tenu par la GRC n\'a pas pu être effectuée. Afin de compléter la demande, le demandeur est tenu d\'avoir une société d\'empreintes digitales. Identification positive qu\'un casier judiciaire peut ou non exister dans le référentiel national des casiers judiciaires. Une vérification des indices locaux peut ou non révéler des condamnations au casier judiciaire qui n\'ont pas été signalées au référentiel national des casiers judiciaires. <b>VÉRIFICATION SUPPLÉMENTAIRE RECOMMANDÉE</b>',
            'DetailedResultsNegativeCIPC': 'La seule recherche nominale, à l\'aide du nom, de la date de naissance et des antécédents judiciaires fournis par le demandeur, dans le répertoire national des casiers judiciaires tenu par la GRC, n\'a révélé aucun casier judiciaire pour une personne dont le nom et date de naissance correspondent à ceux du demandeur. Seule la comparaison des empreintes digitales permet de confirmer l\'existence ou l\'inexistence d\'un casier judiciaire dans le répertoire national des casiers judiciaires tenu par la GRC. Il peut y avoir un certain délai entre le prononcé d\'une déclaration de culpabilité et l\'accessibilité des données dans le répertoire national des casiers judiciaires tenu par la GRC. Toutes les infractions ne sont pas non plus nécessairement versées au répertoire national. <b>NEGATIF</b>',
            'DetailedResultsNotCompleted': 'Le candidat ne possède pas de diplôme. Pour plus d\'information, veuillez svp nous fournir une copie de son diplôme.',
            'DetailedResultsCompleteValidFACA+': 'Le bureau du registraire (CHAMP LIBRE) nous confirme l\'obtention (CHAMP LIBRE) du candidat. Diplôme octroyé (CHAMP LIBRE)',
            'DetailedResultsNotAvailable': 'Le candidat ne possède aucun historique de crédit au moment de la recherche.',
            'DetailedResultsValid': 'Le permis de conduire est valide et comprend la classe (CHAMP LIBRE)',
            'DetailedResultsInvalid': 'Le permis de conduire n\'est plus valide au moment de la recherche.',
            'DetailedResultsGood': 'Le profil de crédit se situe dans la catégorie bonne (CHAMP LIBRE).',
            'DetailedResultsExcellent': 'Le profil de crédit se situe dans la catégorie excellente (CHAMP LIBRE).',
            'DetailedResultsLow': 'Le profil de crédit se situe dans la catégorie faible *faible risqué* (CHAMP LIBRE). Raison :',
            'DetailedResultsFair': 'Le profil de crédit se situe dans la catégorie passable (CHAMP LIBRE). Raison :',
            'DetailedResultsVeryGood': 'Le profil de crédit se situe dans la catégorie très bonne (CHAMP LIBRE).',
            'DetailedResultsConsultReferencesReports': 'Consulter le(s) rapport(s) de prise de références.'
        }
    };

    constructor() { }

    getTraduction(id: string, lang: string) {


        return this.traductions[ lang ] && this.traductions[ lang ][ id ] || id;
    }

    getResultValue(key: number, lang: string) {
        if (key === null) {
            return '';
        }

        const result = resultsForVerificationsId.getSelectedResultValue(key);
        return this.getTraduction(result, lang);
    }

    getDetailedResult(key: number, verification: VerificationOutput, lang: string) {
        if (key === null) {
            return '';
        }

        const keyDetailedResult = key.toString() + verification.code;
        const detailed = resultsForVerificationsId.getDetailedResult(keyDetailedResult);
        return this.getTraduction(detailed, lang);
    }

}
