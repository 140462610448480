import {Component, EventEmitter, Inject, Injector, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {appModuleAnimation} from '@shared/animations/routerTransition';
import {AppComponentBase} from '@shared/common/app-component-base';
import {organizationOutput} from '@shared/models/organization/organizationOutput';
import {RequestInput} from '@shared/models/request/requestInput';
import {OrganizationService} from '@shared/services/organization.service';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {WizzardRequestCreatorComponent} from '../wizzard-request/wizzard-request.component';
import {simpleUserOutput} from "@shared/models/user/SimpleUserOutput";
import {OrganizationUserService} from "@shared/services/organizationUser.service";

@Component({
    selector: 'request-wizard-organization',
    templateUrl: './request-wizard-organization.component.html',
    styleUrls: ['./request-wizard-organization.component.scss'],
    animations: [appModuleAnimation()]
})
export class RequestWizardOrganizationComponent extends AppComponentBase implements OnInit {
    @Input() public request: RequestInput;
    @Output() public requestChange: EventEmitter<RequestInput> = new EventEmitter<RequestInput>();

    userList: simpleUserOutput[] = [];
    selectedPrimaryContactUser: simpleUserOutput;
    noResultUser = false;

    organizationList: organizationOutput[] = [];
    noResultOrganization = false;
    noOrganization = false;

    formGroup = new UntypedFormGroup({
        organization: new UntypedFormControl('', Validators.required),
        primaryContact: new UntypedFormControl(''),
    });
    dataSource: any;

    constructor(
        private injector: Injector,
        private service: OrganizationService,
        private _route: ActivatedRoute,
        private organizationUserService: OrganizationUserService,
        @Inject(WizzardRequestCreatorComponent)
        public requestWizard: WizzardRequestCreatorComponent
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.initializeOrganizations();

        this.dataSource = new Observable((observer: any) => {
            observer.next(this.formGroup.controls['organization'].value);
        }).pipe(switchMap((token: string) => this.service.getOrganizationsByName(token, 5).pipe(map((response) => response.result || []))));
    }

    async initializeOrganizations() {
        if (this.requestWizard.isRequestCreated()) {
            this.initializeOrganizationIfRequest();
        } else {
            this.service
                .getOrganizationsByName('', 5)
                .pipe(map((response) => response.result || []))
                .subscribe((result) => {
                    this.initializeOrganizationIfUserAccessToOnlyOne(result);
                });
        }
    }

    initializeOrganizationIfUserAccessToOnlyOne(result) {
        if (result.length === 1) {
            this.request.organization = result[0];
            this.request.reportLanguage = this.request.organization.language;
            this.formGroup.controls['organization'].setValue(this.request.organization.organizationName);
            this.formGroup.controls['organization'].disable();
        }
    }

    initializeOrganizationIfRequest() {
        this.formGroup.controls['organization'].setValue(this.request.organization.organizationName);
        this.formGroup.controls['organization'].disable();
        this.formGroup.controls['primaryContact'].disable();
        this.organizationUserService.getUserList(this.request.organization.id).subscribe((value) => {
            this.userList = this.organizationUserService.sortUserList(value.result);
            if (this.request.primaryContactId != null) {
                let primaryContact= this.userList.find(el => el.userId == this.request.primaryContactId);
                if (primaryContact != null) {
                    this.formGroup.controls['primaryContact'].setValue(primaryContact.userName);
                }
            }

        });
    }

    onSelectOrganization(event: TypeaheadMatch): void {
        this.request.organization = event.item as organizationOutput;
        this.request.reportLanguage = this.request.organization.language;
        this.organizationUserService.getRequestCreators(this.request.organization.id).subscribe((value) => {
            this.userList = this.organizationUserService.sortUserList(value.result);
            this.formGroup.controls['primaryContact'].setValue(null);
            this.request.primaryContactId = null;
            this.requestChange.emit(this.request);
        });
    }

    typeaheadNoResultsOrganization(event: boolean): void {
        this.noResultOrganization = event;
    }

    onSelectPrimaryContactUser(event: TypeaheadMatch): void {
        this.selectedPrimaryContactUser = event.item as simpleUserOutput;
        this.request.primaryContactId = this.selectedPrimaryContactUser ? this.selectedPrimaryContactUser.userId : null;
        this.requestChange.emit(this.request);
    }

    typeaheadNoResultsUser(event: boolean): void {
        this.noResultUser = event;
    }

    save() {
        this.requestChange.emit(this.request);
        this.requestWizard.save(false);
    }
}
