import { Component, Injector, OnDestroy } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { PrimengTableHelper } from 'shared/helpers/PrimengTableHelper';
import { UiCustomizationSettingsDto } from '@shared/service-proxies/service-proxies';
import { NgxSpinnerTextService } from '@app/shared/ngx-spinner-text.service';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { EventService } from '@shared/services/event.service';
import * as utils from '@shared/utils/utils';
import * as notify from '@shared/utils/notify';
import { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { DialogService } from '@shared/services/dialog-service';
import LocalizationService from '@shared/services/localization.service';

interface EventSubscription {
    eventName: string;
    callback: (...args: any[]) => void;
}

@Component({
    template: ''
})
export abstract class AppComponentBase implements OnDestroy {
    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
    localization: LocalizationService;
    primengTableHelper: PrimengTableHelper;
    dateTimeService: DateTimeService;

    eventSubscriptions: EventSubscription[] = [];
    private ngxSpinnerTextService: NgxSpinnerTextService;
    appAuthService: AppAuthService;
    eventService: EventService;
    message: {
        success: (message: string, title?: string, options?: SweetAlertOptions & { isHtml?: boolean; }) => Promise<SweetAlertResult<any>>;
        error: (message: string, title?: string, options?: SweetAlertOptions & { isHtml?: boolean; }) => Promise<SweetAlertResult<any>>;
        info: (message: string, title?: string, options?: SweetAlertOptions & { isHtml?: boolean; }) => Promise<SweetAlertResult<any>>;
        warn: (message: string, title?: string, options?: SweetAlertOptions & { isHtml?: boolean; }) => Promise<SweetAlertResult<any>>;
        confirm: (message: string, title?: string, callback?: Function, options?: SweetAlertOptions & { isHtml?: boolean; }) => Promise<void>;
    };
    notify: {
        success: (message: string, title?: string, options?: SweetAlertOptions & { imageClass?: string; }) => void;
        error: (message: string, title?: string, options?: SweetAlertOptions & { imageClass?: string; }) => void;
        info: (message: string, title?: string, options?: SweetAlertOptions & { imageClass?: string; }) => void;
        warn: (message: string, title?: string, options?: SweetAlertOptions & { imageClass?: string; }) => void;
    };

    constructor(injector: Injector) {
        this.localization = injector.get(LocalizationService);
        this.appAuthService = injector.get(AppAuthService);
        this.primengTableHelper = new PrimengTableHelper();
        this.dateTimeService = injector.get(DateTimeService);
        this.eventService = injector.get(EventService);
        const dialogService = injector.get(DialogService);

        this.message = {
            success: dialogService.success,
            error: dialogService.error,
            info: dialogService.info,
            warn: dialogService.warn,
            confirm: dialogService.confirm
        };
        this.notify = {
            success: notify.success,
            error: notify.error,
            info: notify.info,
            warn: notify.warn
        };
    }
    get currentTheme(): UiCustomizationSettingsDto {
        return AppConsts.theme;
    }

    get containerClass(): string {
        return 'container-fluid';
    }
    flattenDeep(array) {
        return array.reduce((acc, val) => (Array.isArray(val) ? acc.concat(this.flattenDeep(val)) : acc.concat(val)), []);
    }

    l(key: string, ...args: any[]): string {
        args.unshift(key);
        args.unshift(this.localizationSourceName);
        return this.ls.apply(this, args);
    }

    ls(sourcename: string, key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, sourcename);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return utils.formatString.apply(this, this.flattenDeep(args));
    }

    isGranted(permissionName: string): boolean {
        return this.appAuthService.hasPermission(permissionName);
    }

    isGrantedAny(...permissions: string[]): boolean {
        if (!permissions) {
            return false;
        }

        for (const permission of permissions) {
            if (this.isGranted(permission)) {
                return true;
            }
        }

        return false;
    }

    ngOnDestroy(): void {
        this.unSubscribeAllEvents();
    }

    protected agGridLocaleTextFunc = (key, defaultValue) => {
        const newKey = `grid.${key}`;
        const value = this.l(newKey);
        return newKey === value ? defaultValue : value;
    };

    protected getAgGridDefaultDateFilterParams = (): any => ({
        comparator: function (filterLocalDateAtMidnight, cellValue) {
            const dateAsString = cellValue;

            if (dateAsString === null) {
                return 0;
            }

            // In the example application, dates are stored as dd/mm/yyyy
            // We create a Date object for comparison against the filter date
            const dateParts = dateAsString.split('T')[0].split('-');
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);

            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return 0;
            }
        },
        localeTextFunc: this.agGridLocaleTextFunc
    });

    protected subscribeToEvent(eventName: string, callback: (...args: any[]) => void): void {
        this.eventService.on(eventName, callback);
        this.eventSubscriptions.push({
            eventName,
            callback
        });
    }

    private unSubscribeAllEvents() {
        this.eventSubscriptions.forEach((s) => this.eventService.off(s.eventName, s.callback));
        this.eventSubscriptions = [];
    }
}
