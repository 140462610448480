import { OnInit, Injector, Input, ViewChild, Output, EventEmitter, Component, AfterViewChecked } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { VerificationDescriptionOutput } from '@shared/models/verificationDescription/verificationDescriptionOutput';
import { VerificationDescriptionService } from '@shared/services/verificationDescription.service';
import { VerificationDescriptionInput } from '@shared/models/verificationDescription/verificationDescriptionInput';
import { ModalDirective, BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CkeditorService } from '@shared/services/ckeditor.service';

@Component({
    selector: 'verificationDescription-update',
    templateUrl: './verification-description-update.component.html',
    styleUrls: ['./verification-description-update.component.scss'],
})
export class VerificationDescriptionUpdateComponent extends AppComponentBase implements OnInit, AfterViewChecked {
    @ViewChild('verificationDescriptionUpdateModal') modal: ModalDirective;
    @Input() verificationId: number;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    public Editor = Editor;

    result: VerificationDescriptionOutput;
    saving = false;
    active = false;

    formGroup = new UntypedFormGroup({
        description: new UntypedFormControl(''),
        language: new UntypedFormControl({ value: '', disabled: true }),
    });
    editorConfig: any;

    constructor(
        public bsModalRef: BsModalRef, 
        injector: Injector, 
        private service: VerificationDescriptionService, 
        private ckeditorService: CkeditorService
    ) {
        super(injector);
    }

    ngOnInit() {
        if (this.result !== null && this.result !== undefined) {
            this.initializeDescriptionToUpdate(this.result);
        }

        this.editorConfig = this.ckeditorService.getConfig({mediaEmbedding:true});
    }

    ngAfterViewChecked() {
        this.replaceFontAwesomeIcons('fa-scissors', 'fa-cut');
        this.replaceFontAwesomeIcons('fa-files-o', 'fa-copy');
        this.replaceFontAwesomeIcons('fa-repeat', 'fa-redo');
        this.replaceFontAwesomeIcons('fa-picture-o', 'fa-image');
        this.replaceFontAwesomeIcons('fa-youtube-play', 'fa-video');
        this.removeBtnEditor('imgc-topbar btn-ctnr');
        this.removeBtnEditor('ngx-insert-img-ph ng-star-inserted');
        this.removeBtnEditor('form-control-sm ng-pristine ng-valid ng-touched');
        this.removeBtnEditor('form-control-sm ng-untouched ng-pristine ng-valid');
    }

    initializeDescriptionToUpdate(result: VerificationDescriptionOutput) {
        this.formGroup.controls['description'].setValue(result.description);
        this.formGroup.controls['language'].setValue(result.languageId);
    }

    returnResult() {
        const verification: VerificationDescriptionInput = {
            id: this.result.id,
            publicId: this.result.publicId,
            verificationId: this.result.verificationId,
            description: this.formGroup.controls['description'].value,
            languageId: this.formGroup.controls['language'].value,
        };
        return verification;
    }

    async save(): Promise<void> {
        this.saving = true;
        const description = this.returnResult();
        await this.service
            .updateVerificationDescription(description)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .toPromise()
            .then(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
            });
    }

    close(): void {
        this.active = false;
        this.bsModalRef.hide();
    }

    private replaceFontAwesomeIcons(currentClassName: string, newClassName: string) {
        const icons = document.getElementsByClassName(currentClassName);
        for (let i = 0; i < icons.length; i++) {
            icons.item(i).classList.add(newClassName);
            icons.item(i).classList.remove(currentClassName);
        }
    }

    private removeBtnEditor(className: string) {
        const elements = document.getElementsByClassName(className);
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i] as HTMLElement;
            element.style.display = 'none';
        }
    }
}
