<div bsModal #addRequestFilesModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="addRequestFilesModal" aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="m-subheader">
        <div class="modal-header">
          <div class="row align-items-center">
            <div class="mr-auto col-auto">
              <h5>{{"AddFile" | localize}}</h5>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="form-group" [formGroup]="formGroupRequest">
              <div class="row" *ngIf="(filesToUpload$ | async) as files">
                <div class="selected-files-counter">
                  <span>{{ 'FilesSelectedCount' | localize: files.length }} </span>
                </div>
                <div class="row file-row-container" *ngFor="let file of files">
                  <div class="col-md-2 btn-square-container">
                    <button type="button" class="btn btn-danger btn-square" (click)="removeFileToUpload(file)">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div>
                  <div class="col-md-8 selected-file-container">
                    <span class="selected-file">{{ getFormatedFileName(file) }}</span>
                  </div>
                </div>
              </div>
              <div class="help-message">
                <span class="help-block m-b-none">{{"SizeFile"| localize}}</span>
              </div>              
              <div class="custom-file">
                <input [accept]=fileInputAccept
                       class="hidden-input"
                       #importFile
                       id="importFile"
                       formControlName="importFile" 
                       multiple="multiple"
                       type="file"  
                       (change)="onFileChange($event.target.files)">
                <input class="btn btn-success"
                       formControlName="customImportFile" 
                       (dragenter)="preventDefault($event)"
                       (dragover)="preventDefault($event)"
                       (drop)="droppedFile($event)"
                       type="button" 
                       value="{{ 'ChooseAFile' | localize}}" 
                       (click)="triggerFileInput()">
              </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="close()" [buttonBusy]="saving">{{"Cancel" | localize}}</button>
          <button type="submit" class="btn btn-primary" (click)="save()" *ngIf="canSave"
           [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')">
            <i class="fa fa-save"></i>
            <span>{{"Save" | localize}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
