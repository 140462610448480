<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid">
        <sub-header [title]="'Invoices' | localize" [description]="'ListInvoices' | localize">
            <div role="actions">
                <a class="btn btn-secondary invoicesList-btn"
                    (click)="exportToExcel()">{{'ExportToExcel' | localize}}</a>
                <a class="btn btn-primary" (click)="generateInvoices()"
                    *ngIf="isGranted('Pages.Administration.Invoices.Generate')"
                    data-test-id="invoice-list-main-generateInvoices-btn">{{'GenerateInvoices' | localize}}</a>
            </div>
        </sub-header>
        <div [class]="containerClass">
            <div class="card card-custom listContainer">
                <div class="card-body">
                    <invoice-list #invoiceList></invoice-list>
                </div>
            </div>
        </div>
    </div>
</div>