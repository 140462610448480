import { Injector, Component, ViewEncapsulation, Inject, Input } from '@angular/core';
import { environment as env } from 'environments/environment';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DOCUMENT } from '@angular/common';

@Component({
    templateUrl: './default-brand.component.html',
    selector: 'default-brand',
    encapsulation: ViewEncapsulation.None,
})
export class DefaultBrandComponent extends AppComponentBase {
    @Input() menuVisible = true;

    defaultLogo =
        '/assets/common/images/app-logo-on-' +
        this.currentTheme.baseSettings.menu.asideSkin +
        '.svg';
    remoteServiceBaseUrl: string = env.api;

    constructor(injector: Injector, @Inject(DOCUMENT) private document: Document) {
        super(injector);
    }
}
