// tslint:disable-next-line: class-name
export class organizationLogoInput {
    organizationId: number;
    profilePicture: string;

    constructor() {
        this.organizationId = 0;
        this.profilePicture = "";
    }
}
