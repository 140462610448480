import { GuidesState } from './guides/guides.state';
import { VerificationsState } from './verifications/verifications.state';

export interface AppState {
    guides: GuidesState;
    verifications: VerificationsState;
}

export const initialAppState = {
    guides: undefined,
    verifications: undefined,
};

export const APP_STORAGE_KEY = 'gardium';
export enum Features {
    Guides = 'guides',
    Verifications = 'verifications',
}
