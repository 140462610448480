import { ValidatorFn, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export const emailAddressValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    const value = control.value;
    if (typeof value === 'string' && value) {
        //Valid pattern test@test.com || test.test@test.ca || test@test.test.com || test@test.test.ca
        const regex = /^\w+(?:[-.+]\w+)*@\w+(?:[-.]\w+)*(?:\.\w{2,30})+$/;
        const isEmail = regex.test(value);
        if (!isEmail) {
            return { BadEmailAddress: true };
        } else {
            return null;
        }
    }
};
