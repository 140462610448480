<div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'RequestVerificationQuantityConfirmation' | localize }}</h4>
</div>
<div class="modal-body">
    <div class="m-portlet m-portlet--mobile">
        <form [formGroup]="invoiceFormGroup" autocomplete="off">
            <h5>{{ 'InvoiceDate' | localize }}</h5>
            <div class="form-group col-md-6">
                <label for="InvoiceDate">{{ 'InvoiceDateInfo' | localize }}</label>
                <input
                    id="InvoiceDate"
                    formControlName="InvoiceDate"
                    type="text"
                    name="InvoiceDate"
                    class="form-control"
                    bsDatepicker
                    [bsConfig]="{ dateInputFormat: 'YYYY/MM/DD', containerClass: 'theme-red', adaptivePosition: true }"
                    (bsValueChange)="onInvoiceDateSelection($event)"
                    data-test-id="single-invoice-generator-datePicker-input"
                />
            </div>
            <h5>{{ 'InvoiceBillingMode' | localize }}</h5>
            <div class="form-group col-md-6">
                <div class="form-check">
                    <input
                        class="form-check-input"
                        id="InvoiceBillingModePaidByOrganization"
                        formControlName="InvoiceBillingMode"
                        [value]="InvoiceBillingModeEnum.PaidByOrganization"
                        type="radio"
                        checked
                    />
                    <label class="form-check-label" for="InvoiceBillingModePaidByOrganization">{{ 'InvoiceBillingModePaidByOrganization' | localize }}</label>
                </div>
                <div class="form-check">
                    <input
                        class="form-check-input"
                        id="InvoiceBillingModePaidByCandidate"
                        formControlName="InvoiceBillingMode"
                        [value]="InvoiceBillingModeEnum.PaidByCandidate"
                        type="radio"
                    />
                    <label class="form-check-label" for="InvoiceBillingModePaidByCandidate">{{ 'InvoiceBillingModePaidByCandidate' | localize }}</label>
                </div>
            </div>
        </form>
    </div>
    <div class="verifications">
        <h5>{{ 'Verifications' | localize }}</h5>
        <label for="InvoiceDate" class="ml-4">{{ 'VerificationsDescription' | localize }}</label>
        <div>
            <grid-pagination class="grid-pagination" (sizeValue)="onPageSizeChanged($event)"></grid-pagination>
            <ag-grid-angular
                localizeText
                class="ag-theme-material gardium-small-grid"
                [paginationNumberFormatter]="paginationNumberFormatter"
                [rowData]="requestVerifications"
                [columnDefs]="columnDefsRequestVerifications"
                [defaultColDef]="defaultColDefRequestVerifications"
                [stopEditingWhenGridLosesFocus]="true"
                [paginationPageSize]="paginationPageSize"
                [pagination]="true"
                [singleClickEdit]="true"
                (gridReady)="onRequestVerificationGridReady($event)"
            ></ag-grid-angular>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="save()" [disabled]="!invoiceFormGroup.valid || (loading$ | async)" data-test-id="single-invoice-generator-confirm-btn">
        {{ ((loading$ | async) ? 'LoadWithThreeDot' : 'Confirm') | localize }}
    </button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{ 'Close' | localize }}</button>
</div>
