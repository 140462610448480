<div bsModal role="dialog" class="modal-lg">
  <form [formGroup]="formGroup" #descriptionUpdateForm="ngForm" novalidate (ngSubmit)="save()" autocomplete="off">
    <div class="modal-header">
      <h4 class="modal-title pull-left">
        <h5>{{"VerificationInformation" | localize}}</h5>
      </h4>
    </div>
    <div class="modal-body">
      <div class="form-group edition-list">
        <label for="language">{{"Language" | localize}}</label>
        <select name="language" class="form-control" id="language" formControlName="language">
          <option value="">{{"Select" | localize}}</option>
          <option value="0">{{"English" | localize}}</option>
          <option value="1">{{"French" | localize}}</option>
        </select>
      </div>
      <div class="form-group">
        <ckeditor *ngIf="editorConfig" [editor]="Editor" [config]="editorConfig" name="description" id="description" formControlName="description"></ckeditor>
      </div>
    </div>
    <div class="modal-footer">
      <button [disabled]="saving" type="button" class="btn btn-default"
        (click)="bsModalRef.hide()">{{"Cancel" | localize}}</button>
      <button type="submit" class="btn btn-primary" [buttonBusy]="saving"
        [disabled]="!descriptionUpdateForm.form.valid" [busyText]="l('SavingWithThreeDot')" (click)="save()"><i
          class="fa fa-save"></i>
        <span>{{"Save" | localize}}</span></button>
    </div>
  </form>
</div>
