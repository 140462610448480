import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'oembed'
})
export class OEmbedPipe implements PipeTransform {
    constructor(private sanitize: DomSanitizer) {}
    transform(value: string): SafeHtml {
        if (!value) {
            return;
        }
        const urlRegex = /<oembed url="([^"]+)"><\/oembed>/g;
        const match = value.match(urlRegex);
        let replacement = '';
        if (match) {
            const url = match[0];
            if (url.includes('youtube.com') || url.includes('youtu.be')) {
                // Si l'URL est pour YouTube, convertissez-la en iframe sécurisé
                replacement = `<iframe width="560" height="315" src="https://www.youtube.com/embed/${this.getYouTubeVideoId(url)}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>`;
            } else if (url.includes('vimeo.com')) {
                // Si l'URL est pour Vimeo, convertissez-la en iframe sécurisé
                replacement = `<iframe src="https://player.vimeo.com/video/${this.getVimeoVideoId(url)}" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;
            } else if (url.includes('dailymotion.com') || url.includes('dai.ly')) {
                // Si l'URL est pour Dailymotion, convertissez-la en iframe sécurisé
                replacement = `<iframe src="https://www.dailymotion.com/embed/video/${this.getDailymotionVideoId(url)}" width="640" height="360" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>`;
            }

            return this.sanitize.bypassSecurityTrustHtml(value.replace(urlRegex, replacement));
        }

        return value;
    }

    private getYouTubeVideoId(url: string): string {
        const regex = /(?:youtu.be\/|youtube.com\/(?:embed\/|v\/|watch\?v=|watch\?feature=player_embedded&v=))([^&?/ ]{11})/;
        const match = url.match(regex);
        return match ? match[1] : '';
    }

    private getVimeoVideoId(url: string): string {
        const regex = /\/\/(?:www\.)?vimeo.com\/([0-9a-zA-Z\-_]+)/;
        const match = url.match(regex);
        return match ? match[1] : '';
    }

    private getDailymotionVideoId(url: string): string {
        const regex = /(?:dailymotion.com\/video\/|dai.ly\/|dailymotion.com\/embed\/video\/)([^_]+)[^#]*(?:#video=([^_&]+))?/;
        const match = url.match(regex);
        return match ? (match[2] || match[1]) : '';
    }
}
