import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CandidatInput } from '@shared/models/candidat/candidatInput';
import { CandidatOutput } from '@shared/models/candidat/candidatOutput';
import { RequestOutput } from '@shared/models/request/requestOutput';
import { CRUDAction } from '@shared/models/shared/CRUDAction';
import { CandidatsService } from '@shared/services/candidats.service';
import { RequestService } from '@shared/services/requests.service';
import { emailAddressValidator } from '@shared/utils/validation/email-validator.directive';
import { socialInsuranceNumberValidators } from '@shared/utils/validation/NAS-validator';
import { phoneNumberValidator } from '@shared/utils/validation/phoneNumber-validator.directive';
import { RequiredFieldsForCodes } from '@shared/utils/verificationsEnumAndFunctions';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { WizzardRequestCreatorComponent } from '../wizzard-request/wizzard-request.component';

@Component({
    selector: 'request-wizard-candidate-update',
    templateUrl: './request-wizard-candidate-update.component.html',
    styleUrls: ['./request-wizard-candidate-update.component.scss']
})
export class RequestWizardCandidateUpdateComponent extends AppComponentBase implements OnInit {
    @Input() public request: RequestOutput;
    @Output() public requestChange: EventEmitter<RequestOutput> = new EventEmitter<RequestOutput>();
    @Input() public resetDriverLicenseRequired: BehaviorSubject<boolean>;
    @Input() public candidateChanged: BehaviorSubject<UntypedFormGroup>;

    constructor(
        injector: Injector,
        private _appLocalizationService: AppLocalizationService,
        private toastr: ToastrService,
        @Inject(WizzardRequestCreatorComponent)
        public requestWizard: WizzardRequestCreatorComponent,
        private candidateService: CandidatsService,
        private requestService: RequestService
    ) {
        super(injector);
    }

    maxDate: Date;
    isCandidateUpdated = false;
    updateCandidate = false;
    selectedCandidate: CandidatOutput = null;
    canUpdateBasicInfos = false;
    isDriverLicenseRequired = false;
    isEmailRequired = false;

    form = new UntypedFormGroup({
        firstName: new UntypedFormControl('', Validators.required),
        lastName: new UntypedFormControl('', Validators.required),
        birthdate: new UntypedFormControl('', Validators.required),
        sex: new UntypedFormControl('', Validators.required),
        language: new UntypedFormControl('', Validators.required)
    });

    candidateform = new UntypedFormGroup({
        email: new UntypedFormControl('', emailAddressValidator),
        otherNames: new UntypedFormControl(''),
        maidenName: new UntypedFormControl(''),
        birthPlace: new UntypedFormControl(''),
        socialInsuranceNumber: new UntypedFormControl('', {
            validators: socialInsuranceNumberValidators
        }),
        driverLicenceNumber: new UntypedFormControl(''),
        phoneNumber: new UntypedFormControl('', phoneNumberValidator),
        phoneExtension: new UntypedFormControl('')
    });

    ngOnInit(): void {
        this.candidateform.controls['firstName'] = this.form.controls['firstName'];
        this.candidateform.controls['lastName'] = this.form.controls['lastName'];
        this.candidateform.controls['sex'] = this.form.controls['sex'];
        this.candidateform.controls['language'] = this.form.controls['language'];
        this.candidateform.controls['birthdate'] = this.form.controls['birthdate'];
        this.initializeCandidate();

        this.resetDriverLicenseRequired?.subscribe((event) => {
            if (event) {
                this.isDriverLicenseRequired = this.driverLicenseRequired();
                this.isEmailRequired = this.emailRequired();
                this.setDriverLicenseValidators();
                this.setEmailValidators();

                if (!this.isDriverLicenseRequired && !this.isEmailRequired) {
                    this.updateCandidate = false;
                    this.disableAllControls();
                }
            }
        });
    }

    async initializeCandidate() {
        if (this.requestWizard.isRequestCreated()) {
            this.initializeCandidateIfRequest();
        }
    }

    setEmailValidators() {
        this.isEmailRequired = this.emailRequired();
        if (this.isEmailRequired) {
            this.candidateform.controls['email'].setValidators([Validators.required, emailAddressValidator]);
            this.candidateform.controls['email'].markAsTouched();
            this.candidateform.controls['email'].updateValueAndValidity();

            if (this.request.candidat.email === null || this.request.candidat.email === '') {
                this.edit();
            }
        }
    }

    setDriverLicenseValidators() {
        this.isDriverLicenseRequired = this.driverLicenseRequired();
        if (this.isDriverLicenseRequired) {
            this.candidateform.controls['driverLicenceNumber'].setValidators(Validators.required);
            this.candidateform.controls['driverLicenceNumber'].markAsTouched();

            if (this.request.candidat.driverLicenceNumber === null || this.request.candidat.driverLicenceNumber === '') {
                this.edit();
            }
        }
    }

    initializeCandidateIfRequest() {
        this.candidateform.controls['firstName'].setValue(this.request.candidat.firstName);
        this.candidateform.controls['lastName'].setValue(this.request.candidat.lastName);
        this.candidateform.controls['sex'].setValue(this.request.candidat.sex);
        this.candidateform.controls['language'].setValue(this.request.candidat.language);
        this.candidateform.controls['birthdate'].setValue(this.request.candidat.birthdate);
        this.candidateform.controls['otherNames'].setValue(this.request.candidat.otherNames);
        this.candidateform.controls['maidenName'].setValue(this.request.candidat.maidenName);
        this.candidateform.controls['birthPlace'].setValue(this.request.candidat.birthPlace);
        this.candidateform.controls['socialInsuranceNumber'].setValue(this.request.candidat.socialInsuranceNumber);
        this.candidateform.controls['driverLicenceNumber'].setValue(this.request.candidat.driverLicenceNumber);
        this.candidateform.controls['phoneNumber'].setValue(this.request.candidat.phoneNumber);
        this.candidateform.controls['phoneExtension'].setValue(this.request.candidat.phoneExtension);
        this.candidateform.controls['email'].setValue(this.request.candidat.email);

        this.disableAllControls();
        this.setDriverLicenseValidators();
        this.setEmailValidators();
    }

    disableAllControls() {
        //if you cancel, formGroup is form
        this.form.controls['firstName'].disable();
        this.form.controls['lastName'].disable();
        this.form.controls['sex'].disable();
        this.form.controls['language'].disable();
        this.form.controls['birthdate'].disable();

        //else formGroup is candidateform
        this.candidateform.controls['firstName'].disable();
        this.candidateform.controls['lastName'].disable();
        this.candidateform.controls['sex'].disable();
        this.candidateform.controls['language'].disable();
        this.candidateform.controls['birthdate'].disable();
        this.candidateform.controls['otherNames'].disable();
        this.candidateform.controls['maidenName'].disable();
        this.candidateform.controls['birthPlace'].disable();
        this.candidateform.controls['socialInsuranceNumber'].disable();
        this.candidateform.controls['driverLicenceNumber'].disable();
        this.candidateform.controls['phoneNumber'].disable();
        this.candidateform.controls['phoneExtension'].disable();
        this.candidateform.controls['email'].disable();
    }

    enableControls() {
        this.requestService.getCandidateRequestCountNotDraftOrInProgressCandidate(this.request.candidat.id).subscribe({
            next: (value) => {
                const requestCount = value;
                if (requestCount > 0 || !this.requestWizard.isRequestDraft()) {
                    this.canUpdateBasicInfos = false;
                    this.candidateform.controls['firstName'].disable();
                    this.candidateform.controls['lastName'].disable();
                    this.candidateform.controls['sex'].disable();
                    this.candidateform.controls['language'].disable();
                    this.candidateform.controls['birthdate'].disable();
                } else {
                    this.canUpdateBasicInfos = true;
                    this.candidateform.controls['firstName'].enable();
                    this.candidateform.controls['lastName'].enable();
                    this.candidateform.controls['sex'].enable();
                    this.candidateform.controls['language'].enable();
                    this.candidateform.controls['birthdate'].enable();
                }
            },
            error: (err) => {
                this.notify.error('Server error: ' + err.error.error.message);
            }
        });

        this.candidateform.controls['otherNames'].enable();
        this.candidateform.controls['maidenName'].enable();
        this.candidateform.controls['birthPlace'].enable();
        this.candidateform.controls['socialInsuranceNumber'].enable();
        this.candidateform.controls['driverLicenceNumber'].enable();
        this.candidateform.controls['phoneNumber'].enable();
        this.candidateform.controls['phoneExtension'].enable();
        this.candidateform.controls['email'].enable();
    }

    edit() {
        this.updateCandidate = true;
        this.enableControls();
        this.markAllControlsAsTouch();
    }

    async update() {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }
        const candidate: CandidatInput = CandidatInput.fromFormControl(this.request.candidat.publicId, this.candidateform, CRUDAction.Update);

        candidate.id = this.request.candidat.id;
        candidate.organization = this.request.organization;
        candidate.creationTime = this.request.candidat.creationTime;
        this.isCandidateUpdated = true;
        await this.candidateService.updateCandidat(candidate).subscribe({
            next: (value) => {
                if (value.result.publicId !== null) {
                    this.selectedCandidate = CandidatOutput.FromInput(candidate);
                    this.selectedCandidate.id = value.result.id;
                    this.selectedCandidate.publicId = value.result.publicId;
                    this.request.candidat = this.selectedCandidate;

                    this.showSuccess();
                    this.updateCandidate = false;
                    this.disableAllControls();
                    this.save();
                } else {
                    this.isCandidateUpdated = false;
                    this.showErrorMessage();
                }
                return value.result;
            },
            error: (err) => {
                this.notify.error('Server error: ' + err.error.error.message);
            }
        });
    }

    isFormInvalid(): boolean {
        return this.candidateform.invalid;
    }

    showForNotValidMessage() {
        const title = this._appLocalizationService.l('VerifyAllControlsTitle');
        const message = this._appLocalizationService.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }

    markAllControlsAsTouch() {
        this.form.controls['firstName'].markAsTouched();
        this.form.controls['lastName'].markAsTouched();
        this.form.controls['sex'].markAsTouched();
        this.form.controls['language'].markAsTouched();
        this.form.controls['birthdate'].markAsTouched();

        this.candidateform.controls['firstName'].markAsTouched();
        this.candidateform.controls['lastName'].markAsTouched();
        this.candidateform.controls['sex'].markAsTouched();
        this.candidateform.controls['language'].markAsTouched();
        this.candidateform.controls['birthdate'].markAsTouched();

        this.candidateform.controls['otherNames'].markAsTouched();
        this.candidateform.controls['maidenName'].markAsTouched();
        this.candidateform.controls['birthPlace'].markAsTouched();
        this.candidateform.controls['socialInsuranceNumber'].markAsTouched();
        this.candidateform.controls['driverLicenceNumber'].markAsTouched();
        this.candidateform.controls['phoneNumber'].markAsTouched();
        this.candidateform.controls['email'].markAsTouched();
    }

    showSuccess() {
        const title = this._appLocalizationService.l('CandidateUpdate');
        const successMessage = this._appLocalizationService.l('CandidateUpdated');
        this.toastr.success(successMessage, title);
    }

    showErrorMessage() {
        const title = this._appLocalizationService.l('CandidateUpdateIssue');
        const errorMessage = this._appLocalizationService.l('CandidateUpdateTryAgainLater');
        this.toastr.error(errorMessage, title);
    }

    cancel() {
        this.initializeCandidateIfRequest();
        this.updateCandidate = false;
        this.disableAllControls();
    }

    setCompleteRequestVerifications() {
        if (this.requestWizard.verifications === null) {
            return;
        }
        const updatedRequestVerification = [ ...this.request.requestVerifications ].map((rv) => {
            const verification = this.requestWizard.verifications.find((verif) => verif.id === rv.verificationId);
            return {
                ...rv,
                verification: {...verification},
                verificationPrice: verification?.price || 0
            };
        });
        this.request.requestVerifications = updatedRequestVerification;
    }

    isRequiredCodesIncluded(requiredCodes: string[]): boolean {
        this.setCompleteRequestVerifications();
        return this.request.requestVerifications.filter((requestVerif) => requestVerif.quantity > 0 && requiredCodes.includes(requestVerif.verification?.code)).length > 0;
    }

    driverLicenseRequired() {
        return this.isRequiredCodesIncluded(RequiredFieldsForCodes.getDriverLicenseRequiredCodes());
    }

    emailRequired() {
        return this.isRequiredCodesIncluded(RequiredFieldsForCodes.getEmailRequiredCodes());
    }

    changeCandidate() {
        this.selectedCandidate = null;
        this.request.candidat = new CandidatOutput();
        this.candidateChanged.next(this.form);
        this.requestChange.emit(this.request);
    }

    save() {
        this.requestChange.emit(this.request);
        this.requestWizard.save(false);
    }
}
