import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ckeditor-output',
  template: '<div [innerHTML]="content | oembed"></div>',
  encapsulation: ViewEncapsulation.None,
})
export class CkeditorOutput {
  @Input() content: string;
  constructor() {}
}
