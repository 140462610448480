<form [formGroup]="form" autocomplete="off" class="card card-custom">
    <div class="form-inline">
        <div class="form-group col-md-3 inline-block align-left px-3">
            <label for="otherNames" class="block">{{"OtherNames" | localize}}</label>
            <input id="otherNames" formControlName="otherNames" type="text" name="otherNames" class="form-control"
                minlenth="1" maxlength="256">
        </div>
        <div class="form-group col-md-3 inline-block align-left px-3">
            <label for="maidenName" class="block">{{"MaidenName" | localize}}</label>
            <input id="maidenName" formControlName="maidenName" type="text" name="maidenName" class="form-control"
                minlength="1" maxlength="256">
        </div>
        <div class="form-group col-md-3 inline-block align-left px-3">
            <label for="birthPlace" class="block">{{"BirthPlace" | localize}}</label>
            <input id="birthPlace" formControlName="birthPlace" type="text" name="birthPlace" class="form-control"
                minlength="1" maxlength="256">
        </div>
        <div class="form-group col-md-3 inline-block align-left px-3">
            <label for="phoneNumber" class="block">{{"PhoneNumber" | localize }}</label>
            <input id="phoneNumber" formControlName="phoneNumber" type="text" name="phoneNumber" Class="form-control"
                maxlength="256" placeholder="+1(999) 999-9999" mask="(000) 000-0000" prefix="+ 1 "
                [dropSpecialCharacters]="false" [specialCharacters]="[ '+', '1', '(' , ')' , ' ', '-' ]"
                [showMaskTyped]="true" />
        </div>
    </div>
    <div class="form-inline">
        <div class="form-group col-md-3 inline-block align-left px-3">
            <label for="phoneExtension" class="block">{{"PhoneNumberExtension" | localize}}</label>
            <input id="phoneExtension" formControlName="phoneExtension" type="number" name="phoneExtension"
                class="form-control" maxlength="256" min="0">
        </div>
        <div class="form-group col-md-3 inline-block align-left px-3">
            <label for="email" class="block">{{"Email" | localize}}
                <span *ngIf="updateCandidateInfos && hasRequiredField(form.get('email'))" class="asterix">*</span>
            </label>
            <input id="email" formControlName="email" type="text" name="email" class="form-control" maxlength="256">
            <validation-messages *ngIf="form.get('email').dirty" [formCtrl]="form.get('email')"></validation-messages>
        </div>
        <div class="form-group col-md-3 inline-block align-left px-3">
            <label for="socialInsuranceNumber" class="block">{{"SocialInsuranceNumber" | localize}}</label>
            <input id="socialInsuranceNumber" formControlName="socialInsuranceNumber" type="text"
                name="socialInsuranceNumber" class="form-control" maxlength="256">
            <validation-messages *ngIf="form.get('socialInsuranceNumber').dirty" [formCtrl]="form.get('socialInsuranceNumber')"></validation-messages>
        </div>
        <div class="form-group col-md-3 inline-block align-left px-3">
            <label for="driverLicenceNumber" class="block">{{"DriverLicenceNumber" | localize}}
                <span *ngIf="updateCandidateInfos && hasRequiredField(form.get('driverLicenceNumber'))" class="asterix">*</span>
            </label>
            <input id="driverLicenceNumber" formControlName="driverLicenceNumber" type="text" name="driverLicenceNumber"
                class="form-control" maxlength="256">
            <validation-messages *ngIf="form.get('driverLicenceNumber').dirty" [formCtrl]="form.get('driverLicenceNumber')"></validation-messages>
        </div>
    </div>
</form>