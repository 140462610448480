import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { SubscriptionStartType } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from 'shared/common/app-component-base';
import { ChangeProfilePictureModalComponent } from '@app/shared/layout/profile/change-profile-picture-modal.component';
import { MySettingsModalComponent } from '@app/shared/layout/profile/my-settings-modal.component';
import { ToastrService } from 'ngx-toastr';
import { InvoiceService } from '@shared/services/invoice.service';
import { ToggleComponent, ScrollTopComponent, DrawerComponent, StickyComponent, MenuComponent, ScrollComponent } from '@metronic/app/kt/components';

@Component({
    templateUrl: './app.component.html',
    styleUrls: [ './app.component.scss' ]
})
export class AppComponent extends AppComponentBase implements OnInit {
    @ViewChild('changeProfilePictureModal', { static: true }) changeProfilePictureModal: ChangeProfilePictureModalComponent;
    @ViewChild('mySettingsModal', { static: true }) mySettingsModal: MySettingsModalComponent;

    @ViewChild('chatBarComponent') chatBarComponent;
    subscriptionStartType = SubscriptionStartType;
    theme: string;
    installationMode = true;
    isCalculatingInvoice = false;

    public constructor(
        injector: Injector,
        private _toastr: ToastrService,
        private invoiceService: InvoiceService,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.theme = 'default';
        
        this.registerModalOpenEvents();

        this.registerToasterEvents();

        this.eventService.on('app.invoices.generateInvoices', () => {
            if (!this.isCalculatingInvoice) {
                this.calculateInvoices();
            }
        });

        this.pluginsInitialization();
    }

    async calculateInvoices() {
        this.isCalculatingInvoice = true;
        const result = await this.invoiceService.generateInvoices().toPromise();
        if (result.result) {
            this.calculateInvoices();
        } else {
            this.isCalculatingInvoice = false;
        }
    }

    pluginsInitialized(): boolean {
        return Array.from(document.querySelectorAll('[data-kt-menu="true"]')).some((el) => {
            const menuItem = el as HTMLElement;
            const menuInstance = MenuComponent.getInstance(menuItem);
            return menuInstance !== null;
        });
    }

    pluginsInitialization() {
        setTimeout(() => {
            if (this.pluginsInitialized()) {
                return;
            }

            ToggleComponent.bootstrap();
            ScrollTopComponent.bootstrap();
            DrawerComponent.bootstrap();
            StickyComponent.bootstrap();
            MenuComponent.bootstrap();
            ScrollComponent.bootstrap();
        }, 200);
    }

    registerModalOpenEvents(): void {

        this.subscribeToEvent('app.show.changeProfilePictureModal', () => {
            this.changeProfilePictureModal.show();
        });

        this.subscribeToEvent('app.show.mySettingsModal', () => {
            this.mySettingsModal.show();
        });
    }

    registerToasterEvents(): void {
        this.eventService.on('app.file.adding.failed', () => {
            this._toastr.error(this.l('SizeFile'));
        });
    }

    onMySettingsModalSaved(): void {
        this.eventService.trigger('app.onMySettingsModalSaved');
    }
}
