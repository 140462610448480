<app-report-page
    [organization]="request.organization"
    [organizationLogo]="organizationLogo"
    [candidat]="request.candidat"
    [lang]="request.reportLanguage"
    *ngFor="let verifications of verificationPerPage; index as i"
>
    <div class="first-page">
        <app-company-info
            [organization]="request.organization"
            [lang]="request.reportLanguage"
            *ngIf="i == 0"
            class="sidebar"
        ></app-company-info>
        <app-report-section
            [sectionTitle]="
                'RequestReportResultSummarySectionTitle'
                    | reportLocalize: request.reportLanguage
            "
            class="page-content results"
        >
            <table>
                <tr>
                    <th>
                        {{
                            "RequestReportResultSummaryVerificationName"
                                | reportLocalize: request.reportLanguage
                        }}
                    </th>
                    <th>
                        {{
                            "RequestReportResultSummaryResult"
                                | reportLocalize: request.reportLanguage
                        }}
                    </th>
                </tr>
                <ng-container *ngFor="let verificationOutput of verifications">
                    <tr>
                        <td>
                            {{ verificationOutput.verification.reportType }}
                        </td>
                        <td>
                            {{
                                reportLocalizationService.getResultValue(
                                    verificationOutput.result,
                                    request.reportLanguage
                                )
                            }}
                        </td>
                    </tr>
                </ng-container>
            </table>
        </app-report-section>
    </div>
</app-report-page>

<app-report-page
    [organization]="request.organization"
    [organizationLogo]="organizationLogo"
    [candidat]="request.candidat"
    [lang]="request.reportLanguage"
    *ngFor="let verifications of detailedVerificationPerPage; index as i"
>
    <app-report-section class="page-content results results-detailed">
        <table>
            <tr>
                <th>
                    {{
                        "RequestReportResultSummaryVerificationName"
                            | reportLocalize: request.reportLanguage
                    }}
                </th>
                <th>
                    {{
                        "RequestReportResultSummaryResult"
                            | reportLocalize: request.reportLanguage
                    }}
                </th>
            </tr>
            <ng-container *ngFor="let verificationOutput of verifications">
                <tr>
                    <td>
                        {{ verificationOutput.verification.reportType }}
                    </td>
                    <td>
                        <div
                            class="mt-3 mb-3 ms-2 me-2 request-verification-detailed-note"
                            [innerHTML]="
                                verificationOutput.detailedResult ||
                                reportLocalizationService.getDetailedResult(
                                    verificationOutput.result,
                                    verificationOutput.verification,
                                    request.reportLanguage
                                )
                            "
                        ></div>
                    </td>
                </tr>
            </ng-container>
        </table>
    </app-report-section>

    <app-report-last-page-footer
        [lang]="request.reportLanguage"
        *ngIf="i == detailedVerificationPerPage.length - 1"
    >
    </app-report-last-page-footer>
</app-report-page>
