import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { AppUiCustomizationService } from "./ui/app-ui-customization.service";

@NgModule({
    imports: [CommonModule],
})
export class PreEmploiCommonModule {
    static forRoot(): ModuleWithProviders<CommonModule> {
        return {
            ngModule: CommonModule,
            providers: [AppUiCustomizationService],
        }
    }
}
