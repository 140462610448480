import {Component, Injector, OnInit} from '@angular/core';
import {AppLocalizationService} from '@app/shared/common/localization/app-localization.service';
import {appModuleAnimation} from '@shared/animations/routerTransition';
import {AppConsts} from '@shared/AppConsts';
import {AsyncValidatorFn, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {AppComponentBase} from '@shared/common/app-component-base';
import {emailAddressValidator} from '@shared/utils/validation/email-validator.directive';
import {phoneNumberValidator} from '@shared/utils/validation/phoneNumber-validator.directive';
import {organizationOutput} from '@shared/models/organization/organizationOutput';
import {BillingPeriodTypeDTO, UserEditDto} from '@shared/service-proxies/service-proxies';
import {country} from '@shared/models/shared/country';
import {state} from '@shared/models/shared/state';
import {CountryService} from '@shared/services/country.service';
import {OrganizationUserService} from '@shared/services/organizationUser.service';
import {StatesService} from '@shared/services/states.service';
import {OrganizationB2BService} from '@shared/services/organizationB2B.service';
import {AddressInput} from '@shared/models/address/addressInput';
import {organizationB2bInput} from '@shared/models/organization/organizationB2BInput';
import {clientProvenance} from '@shared/models/organization/organizationClientProvenance';
import {Observable, of} from 'rxjs';
import {catchError, filter, switchMap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {VerificationOutput} from '@shared/models/verification/verificationOutput';
import {VerificationB2BService} from '@shared/services/verificationB2B.service';
import {verificationStatus} from '@shared/models/verification/verificationStatus';
import {creditCardValidator} from '@shared/utils/validation/creditCard-validator.directive';
import {expirationDateValidator} from '@shared/utils/validation/expirationDate-validator.directive';
import {BillingInformationB2BInput} from '@shared/models/organization/billingInformation/billingInformationB2BInput';
import {OrganizationCreditCardInput} from '@shared/models/payment/organizationCreditCardInput';

@Component({
    templateUrl: './create-organisation.component.html',
    styleUrls: ['./create-organisation.component.scss'],
    animations: [appModuleAnimation()],
})
export class CreateOrganisationComponent extends AppComponentBase implements OnInit {

    user: UserEditDto = new UserEditDto();
    userId: number;
    organizationId: number;
    saving: boolean;
    success = false;
    masks = AppConsts.masks;
    canadaCountry: country;
    selectedState: state;
    noResultState = false;
    verifications: VerificationOutput[] = [];
    statesList = [] as state[];
    provenanceList = [];
    provenanceAnswer: string;
    isAnswerOther = false;

    formGroup = new UntypedFormGroup({
        organizationName: new UntypedFormControl('', Validators.required),
        firstName: new UntypedFormControl('', Validators.required),
        lastName: new UntypedFormControl('', Validators.required),
        firstContactEmail: new UntypedFormControl('', [Validators.required, emailAddressValidator], [this.checkIfUsernameAlreadyInUse()]),
        phoneNumber: new UntypedFormControl('', [Validators.required, phoneNumberValidator]),
        phoneExtension: new UntypedFormControl(''),
        language: new UntypedFormControl('', Validators.required),
        civicNo: new UntypedFormControl('', Validators.required),
        street: new UntypedFormControl('', Validators.required),
        apartment: new UntypedFormControl(''),
        city: new UntypedFormControl('', Validators.required),
        postalCode: new UntypedFormControl('', Validators.required),
        state: new UntypedFormControl('', Validators.required), // Make required as only allowed country is Canada for now
        jobTitle: new UntypedFormControl('', Validators.required),
        howYouHear: new UntypedFormControl('', Validators.required),
        otherAnswer: new UntypedFormControl(''),
        authorizeData: new UntypedFormControl('', Validators.requiredTrue),
        verifications: new UntypedFormControl(''),
        creditCardNumber: new UntypedFormControl('', [Validators.required, creditCardValidator]),
        expdate: new UntypedFormControl('', [Validators.required, expirationDateValidator])
    });

    constructor(injector: Injector,
        private toastr: ToastrService,
        private _appLocalizationService: AppLocalizationService,
        private _router: Router,
        private stateService: StatesService,
        private countryService: CountryService,
        private organizationUserService: OrganizationUserService,
        private b2bService: OrganizationB2BService,
        private verificationService: VerificationB2BService) {
        super(injector);
    }

    async ngOnInit() {
        await this.initializeCanadaCountry();
        await this.initStateList();
        await this.initProvenanceList();
        await this.initVerifications();
        // Listen to provenance select
        this.onSelectProvenance();
        // Listen to other answer input
        this.onOtherAnswerInput();
    }

    async save() {
        this.saving = true;
        let newOrganization: organizationOutput = null;
        try {
            newOrganization = await this.createB2bOrganization();
        } catch (exception) {
            this.saving = false;
            if (exception.error.error != null) {
                this.notify.error('Server error: ' + exception.error.error.result);
            } else if (exception.error.result.status === 400) {
                    this.notify.error('Server error: ' + this.l('MissingInfo'));
            } else {
                    this.notify.error('Server error: ' + exception.error.result.title);
            }
        }

        this.saving = false;

        if (newOrganization === null) {
            this.markAsTouch();
            this.showForNotValidMessage();
        } else {
            this.success = true;
        }
    }

    async createB2bOrganization(): Promise<organizationOutput> {
        if (this.isInvalid()) {
            return null;
        }

        const organization = this.getOrganization();

        return await this.b2bService
            .createOrganization(organization)
            .toPromise()
            .then((value) => {
                const organization = value.result;

                if (organization.id !== null) {
                    this.organizationId = organization.id;
                }

                return organization;
            });
    }

    async initializeCanadaCountry() {
        await this.countryService
            .getCanadaCountryList()
            .toPromise()
            .then((value) => {
                this.canadaCountry = value.result as country;
            });
    }

    async initStateList() {
        await this.stateService
            .getStatesFromCountry(this.canadaCountry.id)
            .toPromise()
            .then((value) => {
                this.statesList = value.result as state[];
            });
    }

    async initProvenanceList() {
        this.provenanceList = Object.keys(clientProvenance)
            .filter((value) => isNaN(Number(value)) === true)
            .map((key) => ({
                value: this.l(key),
                key: clientProvenance[key]
            }));
    }

    async initVerifications() {
        await this.verificationService
            .getVerificationsList()
            .toPromise()
            .then(({result}: {result: VerificationOutput[]}) => {
                this.verifications = result.filter(x => x.status === verificationStatus.Actif);
            });
    }

    onSelectState(state: state): void {
        this.selectedState = state;
    }

    onSelectProvenance(): void {
        this.formGroup.get('howYouHear').valueChanges.pipe(filter((answer) => !!answer)).subscribe((answer) => {
            if (answer === clientProvenance.Other) {
                this.isAnswerOther = true;
                this.formGroup.controls['otherAnswer'].addValidators(Validators.required);
            } else {
                this.isAnswerOther = false;
                this.formGroup.controls['otherAnswer'].clearValidators();
                // Else we assign the value of the selected answer to the form field.
                this.provenanceAnswer = answer;
            }
            this.formGroup.controls['otherAnswer'].updateValueAndValidity();
        });
    }

    onOtherAnswerInput(): void {
        // if 'Other' is choosed, we take the answer typed to send to our backend.
        this.formGroup.get('otherAnswer').valueChanges
            .pipe(filter(() => this.formGroup.controls['howYouHear'].value === clientProvenance.Other))
            .subscribe((answer) => {
                this.provenanceAnswer = answer;
            });
    }

    getOrganization(): organizationB2bInput {
        const address = this.initAddress();
        return {
            organizationName: this.formGroup.controls['organizationName'].value,
            firstNameContact: this.formGroup.controls['firstName'].value,
            lastNameContact: this.formGroup.controls['lastName'].value,
            firstContactEmail: this.formGroup.controls['firstContactEmail'].value,
            language: this.formGroup.controls['language'].value,
            phoneNumber: this.formGroup.controls['phoneNumber'].value,
            phoneExtension: this.formGroup.controls['phoneExtension'].value,
            provenance: this.provenanceAnswer,
            firstContactJob: this.formGroup.controls['jobTitle'].value,
            address: address,
            billingInfo: this.getBillingInfo(),
            organizationCreditCard: this.getOrganizationCreditCard(),
            verifications: this.getVerificationsChecked(),
        } as organizationB2bInput;
    }

    initAddress(): AddressInput {
        return {
            id: 0,
            civicNo: this.formGroup.controls['civicNo'].value,
            street: this.formGroup.controls['street'].value,
            app: this.formGroup.controls['apartment'].value,
            city: this.formGroup.controls['city'].value,
            postalCode: this.formGroup.controls['postalCode'].value,
            state: this.selectedState,
            country: this.canadaCountry,
        } as AddressInput;
    }

    markAsTouch() {
        this.formGroup.controls['organizationName'].markAsTouched();
        this.formGroup.controls['firstName'].markAsTouched();
        this.formGroup.controls['lastName'].markAsTouched();
        this.formGroup.controls['firstContactEmail'].markAsTouched();
        this.formGroup.controls['language'].markAsTouched();
        this.formGroup.controls['civicNo'].markAsTouched();
        this.formGroup.controls['street'].markAsTouched();
        this.formGroup.controls['city'].markAsTouched();
        this.formGroup.controls['postalCode'].markAsTouched();
        this.formGroup.controls['state'].markAsTouched();
        this.formGroup.controls['creditCardNumber'].markAsTouched();
        this.formGroup.controls['expdate'].markAsTouched();
    }

    isInvalid(): boolean {
        return this.formGroup.invalid || this.noResultState;
    }

    checkIfUsernameAlreadyInUse(): AsyncValidatorFn {
        return (formGroup: UntypedFormGroup): Observable<ValidationErrors | null> => {
            const userName = this.formGroup.controls['firstContactEmail'].value;
            const err: ValidationErrors = { UserNameAlreadyInUse: true };
            const organizationUserObservable: Observable<any> = this.organizationUserService.getUserIdByUsername(userName);

            return organizationUserObservable.pipe(
                switchMap((result) => (result.result === 0 ? of(null) : of(err))),
                catchError((_) => of(null))
            );
        };
    }

    showForNotValidMessage() {
        const title = this._appLocalizationService.l('VerifyAllControlsTitle');
        const message = this._appLocalizationService.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }

    changeCheckbox(verification: VerificationOutput) {
        verification.checked = !verification.checked;
    }

    getVerificationsChecked(): VerificationOutput[] {
        const verificationChecked = [];
        this.verifications.forEach((element) => {
            if (element.checked) {
                verificationChecked.push(element);
            }
        });
        return verificationChecked;
    }

    getBillingInfo(): BillingInformationB2BInput {
        const billingInfo: BillingInformationB2BInput = {
            billingPeriod: BillingPeriodTypeDTO.Unit
        };
        return billingInfo;
    }

    getOrganizationCreditCard(): OrganizationCreditCardInput {
        const creditCard: OrganizationCreditCardInput = {
            id: 0,
            creditCardNumber: this.formGroup.controls['creditCardNumber'].value,
            expdate: this.formGroup.controls['expdate'].value,
            creditCardChecked: false
        };
        return creditCard;
    }
}
