import { Component, OnInit, Injector, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DatePipe } from '@angular/common';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AcademicRecordService } from '@shared/services/academicRecord.service';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { AcademicRecordOutput } from '@shared/models/CandidatDetails/academicRecords/academicRecordOutput';
import { AcademicRecordInput } from '@shared/models/CandidatDetails/academicRecords/academicRecordInput';
import { finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { phoneNumberValidator } from '@shared/utils/validation/phoneNumber-validator.directive';

@Component({
    selector: 'academic-record-update',
    templateUrl: './academic-record-update.component.html',
    providers: [DatePipe]
})
export class AcademicRecordUpdateComponent extends AppComponentBase implements OnInit {
    @ViewChild('academicReferenceUpdateModal') modal: ModalDirective;
    @Input() selectedAcademicRecord: AcademicRecordOutput;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    publicId: string;
    saving = false;
    active = false;

    formGroup = new UntypedFormGroup({
        institution: new UntypedFormControl('', Validators.required),
        diploma: new UntypedFormControl('', Validators.required),
        year: new UntypedFormControl('', Validators.required),
        permanentCode: new UntypedFormControl(''),
        phoneNumber: new UntypedFormControl('', phoneNumberValidator),
        location: new UntypedFormControl('', Validators.required),
        phoneExtension: new UntypedFormControl('')
    });

    constructor(
        public bsModalRef: BsModalRef,
        private injector: Injector,
        private service: AcademicRecordService,
        private toastr: ToastrService,
        private _appLocalizationService: AppLocalizationService
    ) {
        super(injector);
    }

    ngOnInit() {
        if (this.selectedAcademicRecord !== null && this.selectedAcademicRecord !== undefined) {
            this.initializeAcademicRecordToUpdate(this.selectedAcademicRecord);
        }
    }

    initializeAcademicRecordToUpdate(selectedAcademicRecord: AcademicRecordOutput) {
        this.formGroup.controls['institution'].setValue(selectedAcademicRecord.instutitionName);
        this.formGroup.controls['phoneExtension'].setValue(selectedAcademicRecord.phoneExtension);
        this.formGroup.controls['diploma'].setValue(selectedAcademicRecord.diploma);
        this.formGroup.controls['year'].setValue(selectedAcademicRecord.year);
        this.formGroup.controls['permanentCode'].setValue(selectedAcademicRecord.permanentCode);
        this.formGroup.controls['phoneNumber'].setValue(selectedAcademicRecord.phoneNumber);
        this.formGroup.controls['location'].setValue(selectedAcademicRecord.location);
    }

    returnAcademicRecord(): AcademicRecordInput {
        const academicRecord: AcademicRecordInput = {
            id: this.selectedAcademicRecord.id,
            publicId: this.selectedAcademicRecord.publicId,
            diploma: this.formGroup.controls['diploma'].value,
            year: this.formGroup.controls['year'].value,
            instutitionName: this.formGroup.controls['institution'].value,
            permanentCode: this.formGroup.controls['permanentCode'].value,
            phoneNumber: this.formGroup.controls['phoneNumber'].value,
            location: this.formGroup.controls['location'].value,
            requestId: this.selectedAcademicRecord.requestId,
            candidatId: this.selectedAcademicRecord.candidatId,
            creationTime: this.selectedAcademicRecord.creationTime,
            phoneExtension: this.formGroup.controls['phoneExtension'].value
        };
        return academicRecord;
    }

    isFormInvalid(): boolean {
        return this.formGroup.invalid;
    }

    markAllControlsAsTouch() {
        this.formGroup.controls['institution'].markAsTouched();
        this.formGroup.controls['diploma'].markAsTouched();
        this.formGroup.controls['year'].markAsTouched();
        this.formGroup.controls['permanentCode'].markAsTouched();
        this.formGroup.controls['phoneNumber'].markAsTouched();
        this.formGroup.controls['location'].markAsTouched();
        this.formGroup.controls['phoneExtension'].markAsTouched();
    }

    showForNotValidMessage() {
        const title = this._appLocalizationService.l('VerifyAllControlsTitle');
        const message = this._appLocalizationService.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }

    async save(): Promise<void> {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }
        this.saving = true;

        const academicRecord = this.returnAcademicRecord();

        await this.service
            .updateAcademicRecord(academicRecord)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe({
                next: (value) => {
                    this.notify.info(this.l('SavedSuccessfully'));
                    this.close();
                    this.modalSave.emit(null);
                },
                error: (err) => {
                    this.notify.error('Server error: ' + err.error.result);
                }
            });
    }

    close(): void {
        this.active = false;
        this.bsModalRef.hide();
    }
}
