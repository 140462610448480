
    <div class="top-bar">
        <div class="title-container">
            <h1 style="color:white;">{{"RequestReportResultSummaryTitle" | reportLocalize : lang}}</h1>
            <div>{{"RequestReportResultSummaryCustomerId" | reportLocalize : lang}} : {{organization.clientId}}</div>
        </div>
        <div class="flex-grow-1"></div>
        <div class="symbol-label">
            <img [src]="organizationLogo" [alt]="organization.organizationName" />
        </div>
    </div>
    <table class="info-table">
        <tr>
            <th>
                {{"RequestReportResultSummaryNameLabel" | reportLocalize : lang}}<br/>
                <span>{{"RequestReportResultSummaryNameSubLabel" | reportLocalize : lang}}</span>
            </th>
            <td>
                <b>{{candidat.firstName + " " + candidat.lastName}}</b>
            </td>
        </tr>
        <tr>
            <th>
                {{"RequestReportResultSummaryDateOfBirthLabel" | reportLocalize : lang}} :<br/>
                <span>{{"RequestReportResultSummaryDateOfBirthSubLabel" | reportLocalize : lang}}</span>
            </th>
            <td>
                <b>{{candidat.birthdate | date : 'yyyy-MM-dd'}}</b>
            </td>
        </tr>
    </table>


