import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Component, Injector, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { OrganizationService } from '@shared/services/organization.service';
import { Router, ActivatedRoute } from '@angular/router';
import { organizationOutput } from '@shared/models/organization/organizationOutput';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AddressOutput } from '@shared/models/address/addressOutput';
import { AddressInput } from '@shared/models/address/addressInput';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { ToastrService } from 'ngx-toastr';
import { OrganizationInput } from '@shared/models/organization/organizationInput';
import { country } from '@shared/models/shared/country';
import { CountryService } from '@shared/services/country.service';
import { BillingInformationInput } from '@shared/models/organization/billingInformation/billingInformationInput';
import { phoneNumberValidator } from '@shared/utils/validation/phoneNumber-validator.directive';
import { emailAddressValidator } from '@shared/utils/validation/email-validator.directive';
import { multipleEmailAddressValidator } from '@shared/utils/validation/multiple-emails-validator.directive';
import { InputMask } from 'primeng/inputmask';
import { MenuItem } from 'primeng/api';
import { OrganizationCreditCardInput } from '@shared/models/payment/organizationCreditCardInput';
import { BillingMethodTypeDTO } from '@shared/models/organization/billingInformation/BillingMethodTypeDTO';
import { AppConsts } from '@shared/AppConsts';
import { expirationDateValidator } from '@shared/utils/validation/expirationDate-validator.directive';
import { creditCardValidator } from '@shared/utils/validation/creditCard-validator.directive';
import { OrganizationOutputStatusType } from '@shared/models/organization/organizationOutputStatus';
import { organizationStatus } from '@shared/models/organization/organizationStatus';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';
import { ProfileServiceProxy, UpdateProfilePictureInput } from '@shared/service-proxies/service-proxies';
import { IAjaxResponse } from '@shared/models/ajax-response';
import { organizationLineOfBusiness } from '@shared/models/organization/organizationLineOfBusiness';
import { OrganizationRequestVolumeRange } from '@shared/models/organization/OrganizationRequestVolumeRange';
import { clientProvenance } from '@shared/models/organization/organizationClientProvenance';
import { filter } from 'rxjs/operators';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { environment as env } from 'environments/environment';

@Component({
    selector: 'organization-update',
    templateUrl: './organization-update.component.html',
    styleUrls: [ './organization-update.component.scss' ],
    animations: [ appModuleAnimation() ]
})
export class OrganizationUpdateComponent extends AppComponentBase implements OnInit {
    appConsts = AppConsts;
    lineOfBusiness: string;
    lineOfBusinessList = [];

    isSubOrganization = false;
    isChecked = false;
    isOrganizationUpdated = false;
    isCreditCardUpdated = false;

    result: organizationOutput;
    resultAdress: AddressOutput;
    resultBillingAdress: AddressOutput;
    valueReceived: OrganizationInput;
    publicId: string;
    idAdress: number;
    idBillingAddress: number;
    IdParent: number;
    Inputmask: InputMask;
    hasCreditCard = false;
    isDisabled = false;
    currentLanguage: any;
    waitingForApprobation = false;
    declined = false;
    organizationRequestVolumeRanges = OrganizationRequestVolumeRange;
    countryCanada: country;
    provenanceList = [];
    provenanceAnswer: string;
    isAnswerOther = false;

    addressFormGroup = new UntypedFormGroup({
        civicNo: new UntypedFormControl('', Validators.required),
        street: new UntypedFormControl('', Validators.required),
        app: new UntypedFormControl(''),
        city: new UntypedFormControl('', Validators.required),
        postalCode: new UntypedFormControl(''),
        country: new UntypedFormControl(''),
        countryName: new UntypedFormControl('', Validators.required),
        state: new UntypedFormControl(''),
        stateName: new UntypedFormControl('', Validators.required)
    });
    billingAddressFormGroup = new UntypedFormGroup({
        civicNo: new UntypedFormControl('', Validators.required),
        street: new UntypedFormControl('', Validators.required),
        app: new UntypedFormControl(''),
        city: new UntypedFormControl('', Validators.required),
        postalCode: new UntypedFormControl('', Validators.required),
        country: new UntypedFormControl(''),
        countryName: new UntypedFormControl('', Validators.required),
        state: new UntypedFormControl(''),
        stateName: new UntypedFormControl('', Validators.required)
    });
    formGroup = new UntypedFormGroup({
        organizationName: new UntypedFormControl('', Validators.required),
        status: new UntypedFormControl('', Validators.required),
        clientId: new UntypedFormControl({ value: '', disabled: true }),
        idSage: new UntypedFormControl(''),
        idAccountantCAR: new UntypedFormControl(''),
        purchaseOrder: new UntypedFormControl(''),
        firstContact: new UntypedFormControl('', Validators.required),
        firstContactEmail: new UntypedFormControl('', [ Validators.required, emailAddressValidator ]),
        phoneNumber: new UntypedFormControl('', [ Validators.required, phoneNumberValidator ]),
        phoneExtension: new UntypedFormControl(''),
        language: new UntypedFormControl('', Validators.required),
        notes: new UntypedFormControl(''),
        address: this.addressFormGroup,
        volumeOfRequestPerYear: new UntypedFormControl({
            value: '',
            disabled: !this.isGranted('Pages.Management.Organizations.CanSeePrivateInformation')
        }),
        billingAddress: this.billingAddressFormGroup,
        billingOutputType: new UntypedFormControl('', Validators.required),
        billingPeriod: new UntypedFormControl('', Validators.required),
        billingMethod: new UntypedFormControl('', Validators.required),
        billingContact: new UntypedFormControl('', Validators.required),
        billingEmail: new UntypedFormControl('', [ Validators.required, multipleEmailAddressValidator ]),
        creditCardNumber: new UntypedFormControl(''),
        expdate: new UntypedFormControl(''),
        preAuthorizedPayment: new UntypedFormControl({ value: '', disabled: true }),
        organizationLogo: new UntypedFormControl(''),
        lineOfBusiness: new UntypedFormControl(''),
        jobTitle: new UntypedFormControl(''),
        howYouHear: new UntypedFormControl({
            value: '',
            disabled: !this.isGranted('Pages.Management.Organizations.CanSeePrivateInformation')
        }),
        otherAnswer: new UntypedFormControl({
            value: '',
            disabled: !this.isGranted('Pages.Management.Organizations.CanSeePrivateInformation')
        })
    });

    items: MenuItem[];
    billingMethodEnum = BillingMethodTypeDTO;
    maxPictureBytesUserFriendlyValue = 5;
    imageChangedEvent: any = '';
    uploader: FileUploader;
    organizationLogo = '';
    saving: boolean;


    constructor(
        injector: Injector,
        private _service: OrganizationService,
        private _router: Router,
        private toastr: ToastrService,
        private _countryService: CountryService,
        private _route: ActivatedRoute,
        private _appLocalizationService: AppLocalizationService,
        private _profileService: ProfileServiceProxy,
        private _appAuthService: AppAuthService
    ) {
        super(injector);
    }

    fileChangeEvent(event: any): void {
        if (event.target.files[ 0 ].size > 5242880) {
            //5MB
            this.message.warn(this.l('ProfilePicture_Warn_SizeLimit', this.maxPictureBytesUserFriendlyValue));
            return;
        }

        this.imageChangedEvent = event;
    }

    imageCroppedFile(event: ImageCroppedEvent) {
        this.uploader.clearQueue();
        this.uploader.addToQueue([ <File>base64ToFile(event.base64) ]);
    }

    initFileUploader(): void {
        const uploaderOptions = {
            url: env.api + '/api/Profile/UploadProfilePicture',
            autoUpload: false,
            authToken: 'Bearer ' + this._appAuthService.getToken(),
            removeAfterUpload: true
        };
        this.uploader = new FileUploader(uploaderOptions);
        this.uploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };

        this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
            form.append('FileType', fileItem.file.type);
            form.append('FileName', 'OrganizationLogo');
            form.append('FileToken', this.guid());
        };

        this.uploader.onSuccessItem = (item, response, status) => {
            const resp = <IAjaxResponse>JSON.parse(response);
            if (resp.success) {
                this.updateOrganizationPicture(resp.result.fileToken);
            } else {
                this.message.error(resp.error.message);
            }
        };

        this.uploader.setOptions(uploaderOptions);
    }

    updateOrganizationPicture(fileToken: string): void {
        const input = new UpdateProfilePictureInput();
        input.fileToken = fileToken;
        input.x = 0;
        input.y = 0;
        input.width = 0;
        input.height = 0;

        this.saving = true;
        this._profileService.updateOrganizationPicture(input).subscribe((response) => {
            this._service.addOrganizationFile(response, this.result.id).subscribe((response) => {
                if (response.result === null) {
                    this.organizationLogo = '';
                } else {
                    this.organizationLogo = 'data:image/jpeg;base64,' + response.result;
                }
                this.saving = false;
                this.returnToOrganizationDetails();
            });
        });
    }

    guid(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    async saveOrganizationLogo() {
        this.uploader.uploadAll();
    }

    async ngOnInit() {
        if (this._route.snapshot.paramMap.get('publicId') !== '') {
            this.publicId = this._route.snapshot.paramMap.get('publicId');
            await this._service
                .getOrganizationsByPublicId(this.publicId)
                .toPromise()
                .then((response) => {
                    this.result = response.result;
                    this.resultAdress = this.result.address;

                    this.resultBillingAdress = this.result.billingInfo.billingAddress;
                    this.idBillingAddress = this.resultBillingAdress.id;
                    this.idAdress = this.resultAdress.id;
                    this.IdParent = this.result.organizationParentId;

                    this.waitingForApprobation = false;
                    this.declined = false;

                    this.formGroup.patchValue(this.result);

                    this.addressFormGroup.controls[ 'civicNo' ].setValue(this.resultAdress.civicNo);
                    this.addressFormGroup.controls[ 'street' ].setValue(this.resultAdress.street);
                    this.addressFormGroup.controls[ 'app' ].setValue(this.resultAdress.app);
                    this.addressFormGroup.controls[ 'city' ].setValue(this.resultAdress.city);
                    this.addressFormGroup.controls[ 'country' ].setValue(this.resultAdress.country);
                    this.addressFormGroup.controls[ 'state' ].setValue(this.resultAdress.state);
                    this.addressFormGroup.controls[ 'countryName' ].setValue(this.resultAdress.country.countryName);
                    this.addressFormGroup.controls[ 'stateName' ].setValue(this.resultAdress.state.stateName);
                    this.addressFormGroup.controls[ 'postalCode' ].setValue(this.resultAdress.postalCode);

                    switch (this.result.status) {
                        case organizationStatus.WaitingForApprobation:
                            this.waitingForApprobation = true;
                            this.formGroup.controls[ 'status' ].disable();
                            break;
                        case organizationStatus.Declined:
                            this.declined = true;
                            this.formGroup.controls[ 'status' ].disable();
                            break;
                    }

                    this.billingAddressFormGroup.controls[ 'civicNo' ].setValue(this.resultBillingAdress.civicNo);
                    this.billingAddressFormGroup.controls[ 'street' ].setValue(this.resultBillingAdress.street);
                    this.billingAddressFormGroup.controls[ 'app' ].setValue(this.resultBillingAdress.app);
                    this.billingAddressFormGroup.controls[ 'city' ].setValue(this.resultBillingAdress.city);
                    this.billingAddressFormGroup.controls[ 'country' ].setValue(this.resultBillingAdress.country);
                    this.billingAddressFormGroup.controls[ 'state' ].setValue(this.resultBillingAdress.state);
                    this.billingAddressFormGroup.controls[ 'countryName' ].setValue(this.resultBillingAdress.country.countryName);
                    this.billingAddressFormGroup.controls[ 'stateName' ].setValue(this.resultBillingAdress.state.stateName);
                    this.billingAddressFormGroup.controls[ 'postalCode' ].setValue(this.resultBillingAdress.postalCode);

                    this.formGroup.controls[ 'howYouHear' ].setValue(this.getProvenanceChoice(this.result.provenance));
                    this.formGroup.controls[ 'jobTitle' ].setValue(this.result.firstContactJob);
                    this.formGroup.controls[ 'otherAnswer' ].setValue(this.getOtherAnswer(this.result.provenance));
                    this.formGroup.controls[ 'lineOfBusiness' ].setValue(this.result.lineOfBusiness);
                    this.formGroup.controls[ 'billingOutputType' ].setValue(this.result.billingInfo.billingOutputType);
                    this.formGroup.controls[ 'billingPeriod' ].setValue(this.result.billingInfo.billingPeriod);
                    this.formGroup.controls[ 'billingMethod' ].setValue(this.result.billingInfo.billingMethod);
                    this.formGroup.controls[ 'billingContact' ].setValue(this.result.billingInfo.billingContact);
                    this.formGroup.controls[ 'billingEmail' ].setValue(this.result.billingInfo.billingEmail);

                    if (this.result.preAuthorizedPayment) {
                        this.formGroup.controls[ 'preAuthorizedPayment' ].setValue(1);
                    } else {
                        this.formGroup.controls[ 'preAuthorizedPayment' ].setValue(0);
                    }

                    if (this.result.organizationParent !== null) {
                        //this.formGroup.controls['organizationParentName'].setValue(this.result.organizationParent.organizationName);
                        this.isSubOrganization = true;
                        // Set breadcrumb items
                        this.items = [
                            {
                                label: this._appLocalizationService.l('Organizations'),
                                routerLink: [ '/organizations' ]
                            },
                            {
                                label: this._appLocalizationService.l('OrganizationDetails'),
                                routerLink: [ `/organizations-details/${this.result.organizationParent.publicId}` ]
                            },
                            {
                                label: this._appLocalizationService.l('SubOrganizationsDetails'),
                                routerLink: [ `/organizations-details/${this.publicId}` ]
                            },
                            {
                                label: this._appLocalizationService.l('OrganizationUpdate')
                            }
                        ];
                    } else {
                        this.isSubOrganization = false;
                        // Set breadcrumb items
                        this.items = [
                            {
                                label: this._appLocalizationService.l('Organizations'),
                                routerLink: [ '/organizations' ]
                            },
                            {
                                label: this._appLocalizationService.l('OrganizationDetails'),
                                routerLink: [ `/organizations-details/${this.publicId}` ]
                            },
                            {
                                label: this._appLocalizationService.l('OrganizationUpdate')
                            }
                        ];
                    }

                    if (this.result.billingInfo.billingMethod === 1) {
                        this.hasCreditCard = true;
                        this.enablePreAuthorizedPayment();
                        this.billingAddressFormGroup.controls[ 'countryName' ].disable();
                        this.formGroup.controls[ 'creditCardNumber' ].setValidators([ Validators.required, creditCardValidator ]);
                        this.formGroup.controls[ 'expdate' ].setValidators([ Validators.required, expirationDateValidator ]);
                        this.formGroup.controls[ 'expdate' ].disable();
                        this.formGroup.controls[ 'creditCardNumber' ].disable();
                        if (this.result.organizationCreditCard != null) {
                            this.formGroup.controls[ 'creditCardNumber' ].setValue(this.result.organizationCreditCard.creditCardNumber);
                            this.formGroup.controls[ 'expdate' ].setValue(this.result.organizationCreditCard.expdate);
                        }
                    } else {
                        this.hasCreditCard = false;
                        this.formGroup.controls[ 'preAuthorizedPayment' ].disable();
                    }
                });
            if (this.isGranted('Pages.Management.Organizations.BillingInfoAccess')) {
                const billingOutput = <HTMLInputElement>document.getElementById('billingOutputType');
                this.formGroup.controls[ 'billingOutputType' ].setValue(0);
                billingOutput.disabled = true;
            }

            this._service.getOrganizationLogo(this.result.id).subscribe((response) => {
                if (response.result === null) {
                    this.organizationLogo = '';
                } else {
                    this.organizationLogo = 'data:image/jpeg;base64,' + response.result;
                }
            });

            this.initFileUploader();
        }

        this.currentLanguage = this._appLocalizationService.currentLanguage.displayName;
        await this.initProvenanceList();
        await this.setLineOfBusinessList();
        await this.getCanadaCountryList();
        // Listen to provenance select input
        this.onSelectProvenance();
        // Listen to other answer input
        this.onOtherAnswerInput();
    }

    enablePreAuthorizedPayment() {
        if (this.isGranted('Pages.Management.Organizations.BillingInfoAccess')) {
            this.formGroup.controls[ 'preAuthorizedPayment' ].setValidators(Validators.required);
            this.formGroup.controls[ 'preAuthorizedPayment' ].enable();
        }
    }

    async setLineOfBusinessList() {
        this.lineOfBusinessList = Object.keys(organizationLineOfBusiness)
            .filter((value) => isNaN(Number(value)) === true)
            .map((key) => ({
                value: organizationLineOfBusiness[ key ],
                key: this.l(key)
            }));

        this.lineOfBusinessList.sort((a, b) =>
            a.key.localeCompare(b.key, {
                ignorePunctuation: true,
                sensitivity: 'base'
            })
        );
    }

    async getCanadaCountryList() {
        await this._countryService
            .getCanadaCountryList()
            .toPromise()
            .then((value) => {
                this.countryCanada = value.result as country;
            });
    }

    changeStatusName(stat: number): string {
        switch (stat) {
            case 0:
                return this._appLocalizationService.l('Actif');
            case 1:
                return this._appLocalizationService.l('Inactif');
            case 2:
                return this._appLocalizationService.l('WaitingForApprobation');
            case 3:
                return this._appLocalizationService.l('Declined');
        }
    }

    copyContactInformationToBillingInfo() {
        this.formGroup.controls[ 'billingEmail' ].setValue(this.formGroup.controls[ 'firstContactEmail' ].value);
        this.formGroup.controls[ 'billingContact' ].setValue(this.formGroup.controls[ 'firstContact' ].value);
    }

    checkValue(e: any) {
        if (e.target.checked) {
            this.isChecked = true;
            this.formGroup.controls[ 'creditCardNumber' ].setValue('');
            this.formGroup.controls[ 'expdate' ].setValue('');
            this.formGroup.controls[ 'creditCardNumber' ].enable();
            this.formGroup.controls[ 'expdate' ].enable();
        } else {
            this.isChecked = false;
            this.formGroup.controls[ 'creditCardNumber' ].disable();
            this.formGroup.controls[ 'expdate' ].disable();
        }
    }
    billingMethodChange(event) {
        const creditCard = this.formGroup.controls[ 'creditCardNumber' ];
        const expDate = this.formGroup.controls[ 'expdate' ];
        // tslint:disable-next-line:triple-equals
        if (event === this.billingMethodEnum.CreditCard.toString()) {
            this.hasCreditCard = true;
            this.enablePreAuthorizedPayment();
            this.isChecked = false;

            if (this.billingAddressFormGroup.controls[ 'countryName' ].value !== 'Canada') {
                this.billingAddressFormGroup.controls[ 'country' ].setValue(this.countryCanada);
                this.billingAddressFormGroup.controls[ 'countryName' ].setValue('Canada');
                this.billingAddressFormGroup.controls[ 'country' ].updateValueAndValidity();
            }

            this.billingAddressFormGroup.controls[ 'countryName' ].disable();

            this.formGroup.controls[ 'creditCardNumber' ].setValidators([ Validators.required, creditCardValidator ]);
            this.formGroup.controls[ 'expdate' ].setValidators([ Validators.required, expirationDateValidator ]);
            this.formGroup.controls[ 'creditCardNumber' ].disable();
            this.formGroup.controls[ 'expdate' ].disable();
            // tslint:disable-next-line:triple-equals
            if (creditCard.value !== '' && expDate.value !== '' && creditCard.touched === false && expDate.touched === false) {
                this.formGroup.controls[ 'creditCardNumber' ].setValue(this.result.organizationCreditCard.creditCardNumber);
                this.formGroup.controls[ 'expdate' ].setValue(this.result.organizationCreditCard.expdate);
            } else {
                this.formGroup.controls[ 'creditCardNumber' ].setValue('');
                this.formGroup.controls[ 'expdate' ].setValue('');
            }
        } else {
            this.isChecked = true;
            this.hasCreditCard = false;
            this.formGroup.controls[ 'preAuthorizedPayment' ].disable();
            this.billingAddressFormGroup.controls[ 'countryName' ].enable();
            this.formGroup.controls[ 'creditCardNumber' ].clearValidators();
            this.formGroup.controls[ 'expdate' ].clearValidators();
        }
        this.formGroup.controls[ 'creditCardNumber' ].updateValueAndValidity();
        this.formGroup.controls[ 'expdate' ].updateValueAndValidity();
    }

    addCandidat() {
        this._router.navigate([ '/candidats-create', this.result.publicId ]);
    }

    onNavigate(link) {
        window.open(link, '_blank');
    }

    addVerification(): void {
        this._router.navigate([ '/organizations-verificationAdd', this.publicId ]);
    }

    addSubOrganization() {
        this._router.navigate([ '/suborganization-create', this.result.publicId, this.result.organizationName ]);
    }

    async update() {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }
        const address = this.initAddress();
        const billingInfo = this.initBillingInfo();
        const organizationCreditCard = this.initOrganizationCreditCard();
        const preAuthorizedPayment = this.formGroup.controls[ 'preAuthorizedPayment' ].value === '1';
        const input: OrganizationInput = {
            id: this.result.id,
            idSage: this.formGroup.controls[ 'idSage' ].value,
            idAccountantCAR: this.formGroup.controls[ 'idAccountantCAR' ].value,
            publicId: this.result.publicId,
            clientId: this.formGroup.controls[ 'clientId' ].value,
            organizationName: this.formGroup.controls[ 'organizationName' ].value,
            purchaseOrder: this.formGroup.controls[ 'purchaseOrder' ].value,
            firstContact: this.formGroup.controls[ 'firstContact' ].value,
            firstContactEmail: this.formGroup.controls[ 'firstContactEmail' ].value,
            firstContactJob: this.formGroup.controls[ 'jobTitle' ].value,
            language: this.formGroup.controls[ 'language' ].value,
            notes: this.formGroup.controls[ 'notes' ].value,
            phoneNumber: this.formGroup.controls[ 'phoneNumber' ].value,
            phoneExtension: this.formGroup.controls[ 'phoneExtension' ].value,
            status: this.formGroup.controls[ 'status' ].value,
            organizationParentId: this.IdParent,
            address: address,
            billingInfo: billingInfo,
            organizationCreditCard: organizationCreditCard,
            volumeOfRequestPerYear: this.formGroup.controls[ 'volumeOfRequestPerYear' ].value,
            preAuthorizedPayment: preAuthorizedPayment,
            lineOfBusiness: this.formGroup.controls[ 'lineOfBusiness' ].value,
            provenance: this.provenanceAnswer
        };
        this.isOrganizationUpdated = true;
        this.isCreditCardUpdated = true;

        await this._service
            .updateOrganization(input)
            .toPromise()
            .then(
                (response) => {
                    const valueReceived = response.result;

                    if (valueReceived.id !== null) {
                        const title = this._appLocalizationService.l((this.result.organizationParentId ? 'Sub' : '') + 'OrganizationUpdate');
                        const successMessage = this._appLocalizationService.l((this.result.organizationParentId ? 'Sub' : '') + 'OrganizationUpdated');

                        this.showSuccess(title, successMessage);

                        // uploader will redirect if new file
                        if (this.uploader.queue.length === 0) {
                            this.returnToOrganizationDetails();
                        }
                    } else {
                        const title = this._appLocalizationService.l('OrganizationUpdateIssue');
                        const errorMessage = this._appLocalizationService.l('OrganizationUpdateTryAgainLater');
                        this.isOrganizationUpdated = false;
                        this.showErrorMessage(title, errorMessage);
                    }

                    if (valueReceived.result.outputStatus !== OrganizationOutputStatusType.OrganizationCreatedWithoutVault) {
                    } else {
                        this.isCreditCardUpdated = false;
                        this.showErrorCreditCardMessage();
                    }
                    return valueReceived;
                },
                (error) => {
                    const title = this._appLocalizationService.l('OrganizationUpdateIssue');
                    const errorMessage = this._appLocalizationService.l('OrganizationUpdateTryAgainLater');

                    this.showErrorMessage(title, errorMessage);
                    this.isOrganizationUpdated = false;
                    this.isCreditCardUpdated = false;
                }
            );

        await this.saveOrganizationLogo();
    }

    initBillingInfo(): BillingInformationInput {
        const billingAddress = this.initBillingAddress();

        const billingInfo: BillingInformationInput = {
            billingOutputType: this.formGroup.controls[ 'billingOutputType' ].value,
            billingPeriod: this.formGroup.controls[ 'billingPeriod' ].value,
            billingMethod: this.formGroup.controls[ 'billingMethod' ].value,
            billingEmail: this.formGroup.controls[ 'billingEmail' ].value,
            id: this.result.billingInfo.id,
            billingContact: this.formGroup.controls[ 'billingContact' ].value,
            billingAddress: billingAddress
        };

        return billingInfo;
    }

    initBillingAddress(): AddressInput {
        const address: AddressInput = {
            id: this.idBillingAddress,
            civicNo: this.billingAddressFormGroup.controls[ 'civicNo' ].value,
            street: this.billingAddressFormGroup.controls[ 'street' ].value,
            app: this.billingAddressFormGroup.controls[ 'app' ].value,
            city: this.billingAddressFormGroup.controls[ 'city' ].value,
            postalCode: this.billingAddressFormGroup.controls[ 'postalCode' ].value,
            state: this.billingAddressFormGroup.controls[ 'state' ].value,
            country: this.billingAddressFormGroup.controls[ 'country' ].value
        };
        return address;
    }

    initAddress(): AddressInput {
        const address: AddressInput = {
            id: this.idAdress,
            civicNo: this.addressFormGroup.controls[ 'civicNo' ].value,
            street: this.addressFormGroup.controls[ 'street' ].value,
            app: this.addressFormGroup.controls[ 'app' ].value,
            city: this.addressFormGroup.controls[ 'city' ].value,
            postalCode: this.addressFormGroup.controls[ 'postalCode' ].value,
            state: this.addressFormGroup.controls[ 'state' ].value,
            country: this.addressFormGroup.controls[ 'country' ].value
        };
        return address;
    }

    initOrganizationCreditCard(): OrganizationCreditCardInput {
        const creditCard: OrganizationCreditCardInput = {
            id: 0,
            creditCardNumber: this.formGroup.controls[ 'creditCardNumber' ].value,
            expdate: this.formGroup.controls[ 'expdate' ].value,
            creditCardChecked: this.isChecked
        };
        return creditCard;
    }

    showForNotValidMessage() {
        const title = this._appLocalizationService.l('VerifyAllControlsTitle');
        const message = this._appLocalizationService.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }

    isFormInvalid(): boolean {
        return this.formGroup.invalid;
        //TODO check this|| this.noResultCountry || this.noResultState || this.noResultBillingCountry || this.noResultBillingState
    }

    markAllControlsAsTouch() {
        this.formGroup.controls[ 'organizationName' ].markAsTouched();
        this.formGroup.controls[ 'idSage' ].markAsTouched();
        this.formGroup.controls[ 'idAccountantCAR' ].markAsTouched();
        this.formGroup.controls[ 'purchaseOrder' ].markAsTouched();
        this.formGroup.controls[ 'firstContact' ].markAsTouched();
        this.formGroup.controls[ 'firstContactEmail' ].markAsTouched();
        this.formGroup.controls[ 'phoneNumber' ].markAsTouched();
        this.formGroup.controls[ 'phoneExtension' ].markAsTouched();
        this.formGroup.controls[ 'language' ].markAsTouched();
        this.formGroup.controls[ 'notes' ].markAsTouched();

        this.addressFormGroup.markAllAsTouched();
        this.billingAddressFormGroup.markAllAsTouched();

        this.formGroup.controls[ 'billingOutputType' ].markAsTouched();
        this.formGroup.controls[ 'billingPeriod' ].markAsTouched();
        this.formGroup.controls[ 'billingMethod' ].markAsTouched();
        this.formGroup.controls[ 'billingContact' ].markAsTouched();
        this.formGroup.controls[ 'billingEmail' ].markAsTouched();
        this.formGroup.controls[ 'preAuthorizedPayment' ].markAsTouched();

        this.formGroup.controls[ 'creditCardNumber' ].markAsTouched();
        this.formGroup.controls[ 'expdate' ].markAsTouched();
        this.formGroup.controls[ 'lineOfBusiness' ].markAsTouched();
    }

    isFirstTabInvalid(): boolean {
        if (
            this.formGroup.controls[ 'organizationName' ].valid &&
            this.formGroup.controls[ 'firstContact' ].valid &&
            this.formGroup.controls[ 'firstContactEmail' ].valid &&
            this.formGroup.controls[ 'phoneNumber' ].valid &&
            this.formGroup.controls[ 'language' ].valid &&
            this.formGroup.controls[ 'lineOfBusiness' ].valid
        ) {
            return false;
        } else {
            return true;
        }
    }

    isSecondTabInvalid(): boolean {
        return this.addressFormGroup.invalid;
    }

    isThirdTabInvalid(): boolean {
        const billingMethod = this.formGroup.controls[ 'billingMethod' ].value;
        // tslint:disable-next-line:triple-equals
        if (billingMethod === 1) {
            if (
                this.isBillingOutputTypeValid() &&
                this.formGroup.controls[ 'billingPeriod' ].valid &&
                this.formGroup.controls[ 'billingMethod' ].valid &&
                this.formGroup.controls[ 'billingContact' ].valid &&
                this.formGroup.controls[ 'billingEmail' ].valid &&
                this.formGroup.controls[ 'preAuthorizedPayment' ].valid &&
                this.billingAddressFormGroup.valid &&
                this.isCreditCardInfoValid()
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            if (
                this.isBillingOutputTypeValid() &&
                this.formGroup.controls[ 'billingPeriod' ].valid &&
                this.formGroup.controls[ 'billingMethod' ].valid &&
                this.formGroup.controls[ 'billingContact' ].valid &&
                this.formGroup.controls[ 'billingEmail' ].valid &&
                this.billingAddressFormGroup.valid
            ) {
                return false;
            } else {
                return true;
            }
        }
    }

    isBillingOutputTypeValid() {
        return !this.formGroup.controls[ 'billingOutputType' ].invalid;
    }

    isCreditCardInfoValid() {
        return !this.formGroup.controls[ 'creditCardNumber' ].invalid && !this.formGroup.controls[ 'expdate' ].invalid;
    }

    showSuccess(title: string, successMessage: string) {
        this.toastr.success(successMessage, title);
    }

    showWarningMessage(title: string, warningMessage: string) {
        this.toastr.warning(warningMessage, title);
    }

    showErrorMessage(title: string, errorMessage: string) {
        this.toastr.error(errorMessage, title);
    }

    showErrorCreditCardMessage() {
        const title = this._appLocalizationService.l('CreditCardIssue');
        let warningMessage;
        if (this.isSubOrganization) {
            warningMessage = this._appLocalizationService.l('OrganizationUpdatePaymentIssue');
        } else {
            warningMessage = this._appLocalizationService.l('SubOrganizationUpdatePaymentIssue');
        }
        this.toastr.warning(warningMessage, title);
    }

    returnToOrganizationDetails() {
        this._router.navigate([ '/organizations-details', this.publicId ]);
    }

    returnToOrganizationList() {
        this._router.navigate([ '/organizations' ]);
    }

    delete() {
        const input = this.initOrganizationInput();
        this.message.confirm(
            this._appLocalizationService.l('OrganizationDeleteWarningMessage', input.organizationName),
            this._appLocalizationService.l('AreYouSure'),
            (isConfirmed) => {
                if (isConfirmed) {
                    try {
                        const title = this._appLocalizationService.l('OrganizationDelete');
                        const successMessage = this._appLocalizationService.l('OrganizationDeleted');
                        this._service.deleteOrganization(input).subscribe(() => {
                            this.showSuccess(title, successMessage);
                            this.returnToOrganizationList();
                        });
                    } catch (e) {
                        const title = this._appLocalizationService.l('OrganizationDeletedIssue');
                        const message = this._appLocalizationService.l('OrganizationDeletedTryAgainLater');
                        this.showErrorMessage(title, message);
                    }
                }
            }, {
            confirmButtonText: this.l('Yes'),
            cancelButtonText: this.l('No')
        }
        );
    }

    deleteLogo() {
        this._service.removeOrganizationFile(this.result.id).subscribe((response: any) => {
            if (response.result.result.outputStatus === OrganizationOutputStatusType.Success) {
                this.organizationLogo = '';
            };
        });
    }

    initOrganizationInput() {
        const address = this.initAddress();
        const billingInfo = this.initBillingInfo();
        const organizationCreditCard = this.initOrganizationCreditCard();

        const input: OrganizationInput = {
            id: this.result.id,
            idSage: this.result.idSage,
            idAccountantCAR: this.result.idAccountantCAR,
            publicId: this.result.publicId,
            clientId: this.result.clientId,
            organizationName: this.result.organizationName,
            purchaseOrder: this.result.purchaseOrder,
            firstContact: this.result.firstContact,
            firstContactEmail: this.result.firstContactEmail,
            firstContactJob: this.result.firstContactJob,
            language: this.result.language,
            notes: this.result.notes,
            phoneNumber: this.result.phoneNumber,
            phoneExtension: this.result.phoneExtension,
            status: this.result.status,
            organizationParentId: this.result.organizationParentId,
            address: address,
            billingInfo: billingInfo,
            organizationCreditCard: organizationCreditCard,
            preAuthorizedPayment: this.result.preAuthorizedPayment,
            volumeOfRequestPerYear: this.result.volumeOfRequestPerYear,
            lineOfBusiness: this.result.lineOfBusiness,
            provenance: this.result.provenance
        };
        return input;
    }

    copyAddressAsBillingAddress() {
        this.billingAddressFormGroup.patchValue(this.addressFormGroup.value);
        this.billingAddressFormGroup.controls[ 'postalCode' ].setValue(this.addressFormGroup.get('postalCode').value);
    }

    async initProvenanceList() {
        this.provenanceList = Object.keys(clientProvenance)
            .filter((value) => isNaN(Number(value)) === true)
            .map((key) => ({
                value: this.l(key),
                key: clientProvenance[ key ]
            }));
    }

    onSelectProvenance(): void {
        this.formGroup.get('howYouHear').valueChanges.pipe(filter((answer) => !!answer)).subscribe((answer) => {
            if (answer === clientProvenance.Other) {
                this.isAnswerOther = true;
                this.formGroup.controls[ 'otherAnswer' ].addValidators(Validators.required);
            } else {
                this.isAnswerOther = false;
                this.formGroup.controls[ 'otherAnswer' ].clearValidators();
                // Else we assign the value of the selected answer to the form field.
                this.provenanceAnswer = answer;
            }
            this.formGroup.controls[ 'otherAnswer' ].updateValueAndValidity();
        });
    }

    onOtherAnswerInput(): void {
        // if 'Other' is choosed, we take the answer typed to send to our backend.
        this.formGroup.get('otherAnswer').valueChanges
            .pipe(filter(() => this.formGroup.controls[ 'howYouHear' ].value === clientProvenance.Other))
            .subscribe((answer) => {
                this.provenanceAnswer = answer;
            });
    }

    // Other answer input
    getOtherAnswer(answer: string): string {
        return this.isInProvenanceEnum(answer) ? '' : this.result.provenance;
    }

    // Select input choice depending on the value returned
    getProvenanceChoice(choice: string) {
        if (!this.isInProvenanceEnum(choice)) {
            this.isAnswerOther = true;
            choice = clientProvenance.Other;
        }
        return choice;
    }

    isInProvenanceEnum(value: string) {
        return Object.values(clientProvenance).includes(value as clientProvenance);
    }
}
