import { NgModule } from '@angular/core';
import { AppSharedModule } from '@app/shared/app-shared.module';
import { SharedModule } from '@app/shared/shared.module';
import { UsersRoutingModule } from './users-routing.module';
import { UsersComponent } from './users.component';

@NgModule({
    declarations: [UsersComponent],
    imports: [SharedModule, AppSharedModule, UsersRoutingModule],
    exports: [],
    providers: []
})
export class UsersModule {}
