import { Component, OnInit, Injector, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { ProfessionnalAccreditationOutput } from '@shared/models/CandidatDetails/professionnal-accreditations/professionnalAccreditationOutput';
import { ProfessionnalAccreditationInput } from '@shared/models/CandidatDetails/professionnal-accreditations/professionnalAccreditationInput';
import { finalize } from 'rxjs/operators';
import { ProfessionnalAccreditationService } from '@shared/services/professionnalAccreditation.service';
import { ToastrService } from 'ngx-toastr';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';

@Component({
    selector: 'app-professionnal-accreditations-update',
    templateUrl: './professionnal-accreditations-update.component.html'
})
export class ProfessionnalAccreditationsUpdateComponent extends AppComponentBase implements OnInit {
    @ViewChild('accreditationUpdateForm') modal: ModalDirective;
    @Input() selectedAccreditation: ProfessionnalAccreditationOutput;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    publicId: string;
    saving = false;
    active = false;

    formGroup = new UntypedFormGroup({
        year: new UntypedFormControl('', Validators.required),
        institution: new UntypedFormControl('', Validators.required),
        title: new UntypedFormControl('', Validators.required),
        location: new UntypedFormControl('', Validators.required)
    });

    constructor(
        public bsModalRef: BsModalRef,
        private injector: Injector,
        private _professionnalAccreditationService: ProfessionnalAccreditationService,
        private toastr: ToastrService,
        private _appLocalizationService: AppLocalizationService
    ) {
        super(injector);
    }

    ngOnInit() {
        if (this.selectedAccreditation !== null && this.selectedAccreditation !== undefined) {
            this.initializeAccreditationToUpdate(this.selectedAccreditation);
        }
    }

    initializeAccreditationToUpdate(selectedAccreditation: ProfessionnalAccreditationOutput) {
        this.formGroup.controls['year'].setValue(selectedAccreditation.year);
        this.formGroup.controls['institution'].setValue(selectedAccreditation.instutition);
        this.formGroup.controls['title'].setValue(selectedAccreditation.title);
        this.formGroup.controls['location'].setValue(selectedAccreditation.location);
    }

    returnAccreditation(): ProfessionnalAccreditationInput {
        const accreditation: ProfessionnalAccreditationInput = {
            id: this.selectedAccreditation.id,
            publicId: this.selectedAccreditation.publicId,
            year: this.formGroup.controls['year'].value,
            instutition: this.formGroup.controls['institution'].value,
            title: this.formGroup.controls['title'].value,
            location: this.formGroup.controls['location'].value,
            requestId: this.selectedAccreditation.requestId,
            candidatId: this.selectedAccreditation.candidatId,
            creationTime: this.selectedAccreditation.creationTime
        };
        return accreditation;
    }

    isFormInvalid(): boolean {
        return this.formGroup.invalid;
    }

    showForNotValidMessage() {
        const title = this._appLocalizationService.l('VerifyAllControlsTitle');
        const message = this._appLocalizationService.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }

    markAllControlsAsTouch() {
        this.formGroup.controls['year'].markAsTouched();
        this.formGroup.controls['institution'].markAsTouched();
        this.formGroup.controls['title'].markAsTouched();
        this.formGroup.controls['location'].markAsTouched();
    }

    save(): Promise<void> {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }
        this.saving = true;

        const accreditation = this.returnAccreditation();
        this._professionnalAccreditationService
            .updateProfessionnalAccreditation(accreditation)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe({
                next: (value) => {
                    this.notify.info(this.l('SavedSuccessfully'));
                    this.close();
                    this.modalSave.emit(null);
                },
                error: (err) => {
                    this.notify.error('Server error: ' + err.error.result);
                }
            });
    }

    close(): void {
        this.active = false;
        this.bsModalRef.hide();
    }
}
