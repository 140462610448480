<form [formGroup]="formGroup">
  <div class="form-group edition-list">
      {{'PageSize' | localize}}:
      <select (change)="onPageSizeChanged()" formControlName="pageSize" name="pageSize">
        <option value="20" selected>20</option>
        <option value="100">100</option>
        <option value="500">500</option>
        <option value="1000">1000</option>
      </select>
  </div>
</form>
