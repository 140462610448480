import { Component, OnInit, Injector, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { VerificationOutput } from '@shared/models/verification/verificationOutput';
import { VerificationService } from '@shared/services/verification.service';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { VerificationInput } from '@shared/models/verification/verificationInput';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ToastrService } from 'ngx-toastr';
import { VerificationDescriptionUpdateComponent } from '@app/tutorials/verifications/management/update/verification-description-update.component';
import { VerificationDescriptionAddComponent } from '@app/tutorials/verifications/management/add/verification-description-add.component';
import { VerificationDescriptionOutput } from '@shared/models/verificationDescription/verificationDescriptionOutput';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { FilesSelectionModalComponent } from '@app/shared/files/files-selection-modal/files-selection-modal.component';
import { FilesOutput } from '@shared/models/files/filesOutput';
import { GridApi, ColDef } from '@ag-grid-enterprise/all-modules';
import { ConfirmDialogComponent } from '@app/shared/dialog/confirm-dialog/confirm-dialog.component';
import { GridBtnDeleteComponent } from '@app/shared/grid/grid-btn-delete/grid-btn-delete.component';
import { appModuleAnimation } from '@shared/animations/routerTransition';

@Component({
    selector: 'verification-details',
    templateUrl: './verification-details.component.html',
    styleUrls: [ './verification-details.component.scss' ],
    animations: [ appModuleAnimation() ]
})
export class VerificationDetailsComponent extends AppComponentBase implements OnInit {
    @ViewChild('verificationDescription') verificationDescription: VerificationDescriptionUpdateComponent;
    @ViewChild('verificationDescriptionAddModal') verificationDescriptionAddModal: VerificationDescriptionAddComponent;

    formGroup = new UntypedFormGroup({
        code: new UntypedFormControl({ value: '', disabled: true }),
        type: new UntypedFormControl({ value: '', disabled: true }),
        price: new UntypedFormControl({ value: '', disabled: true }),
        status: new UntypedFormControl({ value: '', disabled: true }),
        canBeExpress: new UntypedFormControl({ value: false, disabled: true }),
        expressPrice: new UntypedFormControl({ value: 0, disabled: true })
    });

    publicId: string;
    behaviorBtn: boolean;
    result: VerificationOutput;
    descriptions: VerificationDescriptionOutput[];
    bsModalRef: BsModalRef;
    ngxMessage: any;
    public files: FilesOutput[];

    paginationNumberFormatter;
    paginationPageSize = 20;

    private modalRef: BsModalRef;
    private gridApi: GridApi;
    public columnDefs: ColDef[];
    items: MenuItem[];

    constructor(
        injector: Injector,
        el: ElementRef,
        private modalService: BsModalService,
        private _route: ActivatedRoute,
        private toastr: ToastrService,
        private _service: VerificationService,
        private _router: Router,
        private service: VerificationService,
        private _appLocalizationService: AppLocalizationService
    ) {
        super(injector);

        this.columnDefs = [
            { headerName: this.l('Name'), field: 'filesName' },
            {
                headerName: this._appLocalizationService.l('isFileMandatory'),
                valueFormatter: this.isMandatoryValueFormatter.bind(this),
                field: 'isMandatory'
            },
            {
                headerName: '',
                resizable: false,
                filter: false,
                editable: false,
                suppressMenu: true,
                cellClass: 'text-end',
                cellRendererFramework: GridBtnDeleteComponent,
                cellRendererParams: { action: (data) => this.deleteFile(data) }
            }
        ];

        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };
        if (this._route.snapshot.paramMap.get('publicId') !== '') {
            this.publicId = this._route.snapshot.paramMap.get('publicId');
            this.items = [
                {
                    label: this._appLocalizationService.l('Verifications'),
                    routerLink: [ '/verifications' ]
                },
                {
                    label: this._appLocalizationService.l('VerificationDetails'),
                    routerLink: [ `/verifications-details/${this.publicId}` ]
                }
            ];
        }
    }

    @HostListener('window:resize', [ '$event' ])
    onResize(event) {
        if (this.gridApi) {
            this.gridApi.sizeColumnsToFit();
        }
    }

    isMandatoryValueFormatter(params) {
        const isMandatory = params.data.isMandatory;
        return isMandatory ? this._appLocalizationService.l('Yes') : this._appLocalizationService.l('No');
    }

    async ngOnInit() {
        await this.initializeVerification();
    }

    onPageSizeChanged(e) {
        this.gridApi.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    changeSizeImgEditor(description: string) {
        return description.replace('<img ', '<img class="sizeEditor"');
    }

    showSuccess(title: string, successMessage: string) {
        title = this._appLocalizationService.l(title);
        successMessage = this._appLocalizationService.l(successMessage);
        this.toastr.success(successMessage, title);
    }

    showErrorMessage(title: string, errorMessage: string) {
        title = this._appLocalizationService.l(title);
        errorMessage = this._appLocalizationService.l(errorMessage);
        this.toastr.error(errorMessage, title);
    }

    returnToList() {
        this._router.navigate([ '/verifications' ]);
    }

    onDelete() {
        const verification: VerificationInput = {
            id: this.result.id,
            publicId: this._route.snapshot.paramMap.get('publicId'),
            code: this.result.code,
            price: this.result.price,
            type: this.result.type,
            status: 0
        };

        this.message.confirm(this.l('VerificationDeleteWarningMessage'), this.l('AreYouSure'), (isConfirmed) => {
            if (isConfirmed) {
                try {
                    this._service.deleteVerification(verification).subscribe(() => {
                        this.showSuccess('VerificationDeleteTitle', 'VerificationDeleted');
                        this.returnToList();
                    });
                } catch (e) {
                    this.showErrorMessage('VerificationDeleteIssue', 'VerificationDeleteTryAgainLater');
                }
            }
        }, {
            confirmButtonText: this.l('Yes'),
            cancelButtonText: this.l('No')
        });
    }

    onUpdate() {
        this._router.navigate([ '/verifications-update', this.result.publicId ]);
    }

    onUpdateDescription = async (languageId: number) => {
        let description: VerificationDescriptionOutput;
        this.descriptions.forEach((element) => {
            if (element.languageId === languageId) {
                description = element;
            }
        });
        this.bsModalRef = this.modalService.show(VerificationDescriptionUpdateComponent, {
            initialState: {
                result: description
            },
            backdrop: 'static'
        });

        this.bsModalRef.content.modalSave.subscribe((result) => {
            this.refresh();
        });
    };

    onCreateDescription() {
        this.verificationDescriptionAddModal.verificationId = this.result.id;
        this.verificationDescriptionAddModal.show();
    }

    changeBehaviorNewButton(): boolean {
        return this.descriptions?.length >= 3;
    }

    async initializeVerification() {
        if (this._route.snapshot.paramMap.get('publicId') !== '') {
            this.publicId = this._route.snapshot.paramMap.get('publicId');
            await this._service
                .getVerificationByPublicId(this.publicId)
                .toPromise()
                .then(({ result }) => {
                    this.result = result;
                    this.formGroup.patchValue({
                        ...this.result,
                        canBeExpress: !!this.result.expressPrice
                    });
                    this.formGroup.controls[ 'price' ].setValue(this.getPriceWithCurrency(this.result.price));
                    this.formGroup.controls[ 'expressPrice' ].setValue(this.getPriceWithCurrency(this.result.expressPrice));
                    this.descriptions = this.result.verificationDescriptionOutputs;
                    this.behaviorBtn = this.changeBehaviorNewButton();

                    const tempFiles = this.result.files;
                    for (const file of tempFiles) {
                        file.isMandatory = this.result.verificationFiles.find((verifFile) => verifFile.filesId === file.id)?.isMandatory;
                    }

                    this.files = tempFiles;
                });
        }
        this.formGroup.controls[ 'status' ].setValue(this.changeStatusName(this.result.status));
    }

    refresh(event?: LazyLoadEvent) {
        this.initializeVerification();
    }

    changeStatusName(stat: number): string {
        switch (stat) {
            case 0:
                return this._appLocalizationService.l('Actif');
            case 1:
                return this._appLocalizationService.l('Inactif');
        }
    }

    getSectionTitle(languageId: number): string {
        switch (languageId) {
            case 0:
                return this.l('English');
            case 1:
                return this.l('French');
            default:
                return this.l('Default');
        }
    }

    // Region file region

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    addFile = () => {
        this.modalRef = this.modalService.show(FilesSelectionModalComponent, {
            class: 'modal-dialog-centered',
            backdrop: 'static'
        });
        (this.modalRef.content as FilesSelectionModalComponent).onSave.subscribe((files: FilesOutput[]) => {
            let newFiles = [];

            if (this.files.length > 0) {
                newFiles = files.filter((x) => this.files.find((y) => y.publicId === x.publicId) === undefined);
            } else {
                newFiles = files;
            }

            this.service
                .addFiles(
                    this.result.publicId,
                    newFiles.map((x) => x.publicId)
                )
                .subscribe(
                    (data) => {
                        this.files = this.files.concat(newFiles);
                        this.gridApi.updateRowData({ add: newFiles });
                        this.modalRef.hide();
                    },
                    (error) => {
                        this.modalRef.hide();
                        this.showErrorMessage('FileAddIssue', 'FileAddTryAgainLater');
                    }
                );
        });
        (this.modalRef.content as FilesSelectionModalComponent).onClose.subscribe(() => {
            this.modalRef.hide();
        });
    };

    deleteFile = (file: FilesOutput) => {
        const initialState = {
            message: this.l('FileDeleteQuestion', [ file.filesName ]),
            positiveBtnLabel: this.l('Yes'),
            negativeBtnLabel: this.l('No')
        };

        this.modalRef = this.modalService.show(ConfirmDialogComponent, {
            initialState,
            class: 'modal-sm modal-dialog-centered',
            backdrop: 'static'
        });
        (this.modalRef.content as ConfirmDialogComponent).positiveBtnAction.subscribe(() => {
            this.service.deleteFile(this.result.publicId, file.publicId, 'verificationFile').subscribe(
                () => {
                    const fileIndex = this.files.findIndex((x) => x.publicId === file.publicId);

                    if (fileIndex > -1) {
                        this.gridApi.updateRowData({
                            remove: [ this.files[ fileIndex ] ]
                        });
                        this.files.splice(fileIndex, 1);
                    }

                    this.modalRef.hide();
                },
                () => {
                    const title = this.l('FileDeleteTitle');
                    const message = this.l('FileDeleteIssue');
                    this.toastr.error(message, title);

                    this.modalRef.hide();
                }
            );
        });
        (this.modalRef.content as ConfirmDialogComponent).negativeBtnAction.subscribe(() => {
            this.modalRef.hide();
        });
    };

    getRowNodeId(data) {
        return data.publicId;
    }

    private getPriceWithCurrency = (value: number): string => `${value ? value.toFixed(2) : 0} $`;
}
