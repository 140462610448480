<div bsModal role="dialog" class="modal-lg">
  <form [formGroup]="formGroup" #accreditationUpdateForm="ngForm" novalidate (ngSubmit)="save()" autocomplete="off">
    <div class="modal-header">
      <h4 class="modal-title pull-left">
        <h5>{{"ProfessionnalAccreditations" | localize}}</h5>
      </h4>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label for="year">{{"Year" | localize}} <span class="asterix">*</span></label>
        <input id="year" formControlName="year" type="number" name="year" class="form-control" maxlength="256" min="0">
        <validation-messages [formCtrl]="formGroup.get('year')"></validation-messages>
      </div>
      <div class="form-group">
        <label for="location">{{"Location" | localize}} <span class="asterix">*</span></label>
        <input id="location" formControlName="location" type="text" name="location" class="form-control"
          maxlength="256">
        <validation-messages [formCtrl]="formGroup.get('location')"></validation-messages>
      </div>
      <div class="form-group">
        <label for="institution">{{"Institution" | localize}} <span class="asterix">*</span></label>
        <input id="institution" formControlName="institution" type="text" name="institution" class="form-control"
          maxlength="256">
        <validation-messages [formCtrl]="formGroup.get('institution')"></validation-messages>
      </div>
      <div class="form-group">
        <label for="title">{{"Title" | localize}} <span class="asterix">*</span></label>
        <input id="title" formControlName="title" type="text" name="title" class="form-control" maxlength="256">
        <validation-messages [formCtrl]="formGroup.get('title')"></validation-messages>
      </div>
    </div>
    <div class="modal-footer">
      <button [disabled]="saving" type="button" class="btn btn-default"
        (click)="bsModalRef.hide()">{{"Cancel" | localize}}</button>
      <button type="submit" class="btn btn-primary" [buttonBusy]="saving"
        [disabled]="!accreditationUpdateForm.form.valid" [busyText]="l('SavingWithThreeDot')" (click)="save()"><i
          class="fa fa-save"></i>
        <span>{{"Save" | localize}}</span></button>
    </div>
  </form>
</div>