import { Component, Injector } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { AppComponentBase } from '@shared/common/app-component-base';
import { FilesOutput } from '@shared/service-proxies/service-proxies';
import { saveAs } from 'file-saver';
import { FilesService } from '@shared/services/files.service';

@Component({
    selector: 'grid-download-links-for-verification-files',
    templateUrl: './grid-download-links-for-verification-files.component.html'
})
export class GridDownloadLinksForVerificationFilesComponent extends AppComponentBase implements ICellRendererAngularComp {
    params: any;
    filesFR: FilesOutput[];
    filesEN: FilesOutput[];
    files: FilesOutput[];

    constructor(injector: Injector, private _fileService: FilesService) {
        super(injector);
    }

    refresh(_params: any): boolean {
        return false;
    }

    agInit(params): void {
        if (!params) {
            return;
        }
        this.params = params;
        const verifications = params.verifications.filter((x) => x.code === params.data.verification.code);
        if (verifications.length === 1) {
            this.filesFR = verifications[0].files.filter((x) => x.filesName.startsWith('FR_'));
            this.filesEN = verifications[0].files.filter((x) => x.filesName.startsWith('EN_'));
            this.files = verifications[0].files.filter((x) => !x.filesName.startsWith('EN_') && !x.filesName.startsWith('FR_'));
        }
    }

    downloadFiles(files: any[]): void {
        if (this.files.length > 0 && this.files !== files) {
            files = files.concat(this.files);
        }

        files.forEach((file) => {
            this._fileService
                .getDownloadFileUrl(file.publicId)
                .toPromise()
                .then((blob) => {
                    saveAs(blob, file.filesName);
                });
        });
    }
}
