<div class="row align-items-center">
    <div class="mr-auto col-auto">
        <h5>{{ title }} <ng-content></ng-content></h5>
        <h6 style="font-weight:normal;">{{ description }}</h6>
    </div>
</div>
<div>
    <grid-pagination class="grid-pagination" (sizeValue)="onPageSizeChanged($event)"></grid-pagination>
    <ag-grid-angular localizeText #agGrid class="ag-theme-material gardium-small-grid" [rowSelection]="rowSelection"
        [rowData]="formList" [columnDefs]="columnDefs" (selectionChanged)="onSelectionChanged()"
        [paginationNumberFormatter]="paginationNumberFormatter" (gridReady)="onGridReady($event)"
        [defaultColDef]="defaultColDef" [paginationPageSize]="paginationPageSize" [pagination]="true">
    </ag-grid-angular>
</div>