import { Injectable } from '@angular/core'
import { AppConsts } from '@shared/AppConsts'
import { FileDto } from '@shared/service-proxies/service-proxies'
import { environment as env } from 'environments/environment';

@Injectable()
export class FileDownloadService {
    downloadTempFile(file: FileDto) {
        const url =
            env.api +
            '/File/DownloadTempFile?fileType=' +
            file.fileType +
            '&fileToken=' +
            file.fileToken +
            '&fileName=' +
            file.fileName;                
        window.open(url, "_blank");
    }
}
