<div appBsModal #createOrEditModal="bs-modal" (onShown)="onShown()" class="modal fade create-or-edit-user-modal"
    tabindex="-1" aria-labelledby="createOrEditModal" aria-hidden="true"
    [config]="{ backdrop: 'static', keyboard: !saving }">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #userForm="ngForm" novalidate (ngSubmit)="save()">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span *ngIf="user.id">{{ 'EditUser' | localize }}: {{ user.userName }}</span>
                        <span *ngIf="!user.id">{{ 'CreateNewUser' | localize }}</span>
                    </h4>
                </div>
                <div class="modal-body">
                    <tabset>
                        <tab class="pt-5" heading="{{ 'UserInformations' | localize }}">
                            <div class="row">
                                <div class="col-sm-3 text-center mb-5 mt-5">
                                    <img src="{{ profilePicture }}" alt="profile" width="128" height="128"
                                        class="img-thumbnail img-rounded" />
                                </div>
                                <div class="col-sm-9">
                                    <div class="mb-5">
                                        <label for="Name">{{"Name" | localize}} *</label>
                                        <input id="Name" #nameInput="ngModel" class="form-control" type="text"
                                            name="Name" [(ngModel)]="user.name" required maxlength="64" />
                                        <validation-messages [formCtrl]="nameInput"></validation-messages>
                                    </div>

                                    <div class="mb-5">
                                        <label for="Surname">{{"Surname" | localize}} *</label>
                                        <input id="Surname" #surnameInput="ngModel" type="text" name="Surname"
                                            class="form-control" [(ngModel)]="user.surname" required maxlength="64" />
                                        <validation-messages [formCtrl]="surnameInput"></validation-messages>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-5">
                                <label class="form-label" for="EmailAddress">{{ 'EmailAddress' | localize }} *</label>
                                <input id="EmailAddress" #emailAddressInput="ngModel" type="email" name="EmailAddress"
                                    class="form-control" [(ngModel)]="user.emailAddress" [disabled]="!!user.id"
                                    (ngModelChange)="emailChanged()" required maxlength="256" email />
                                <validation-messages [formCtrl]="emailAddressInput"></validation-messages>
                            </div>

                            <div class="mb-5">
                                <label class="form-label" for="PhoneNumber">{{ 'PhoneNumber' | localize }}</label>
                                <input id="PhoneNumber" type="text" name="PhoneNumber" class="form-control"
                                    [(ngModel)]="user.phoneNumber" maxlength="32" />
                            </div>

                            <br />

                            <label class="form-check form-check-custom form-check-solid py-1" *ngIf="!!user.id">
                                <input id="EditUser_SendActivationEmail" type="checkbox" name="SendInvitationEmail" [defaultValue]="true"
                                       [(ngModel)]="sendActivationEmail" class="form-check-input" />
                                <span class="form-check-label">
                                    {{ 'ResendInvitationEmail' | localize }}
                                </span>
                            </label>

                            <label class="form-check form-check-custom form-check-solid py-1" *ngIf="!!user.id">
                                <input id="EditUser_SendPasswordChangeEmail" type="checkbox" name="SendPasswordChangeEmail" [defaultValue]="true"
                                    [(ngModel)]="sendPasswordChangeEmail" class="form-check-input" />
                                <span class="form-check-label">
                                    {{ 'ResendChangePasswordEmail' | localize }}
                                </span>
                            </label>

                            <label class="form-check form-check-custom form-check-solid py-1">
                                <input id="EditUser_IsActive" type="checkbox" name="IsActive"
                                    [(ngModel)]="user.isActive" class="form-check-input" />
                                <span class="form-check-label">
                                    {{ 'Active' | localize }}
                                </span>
                            </label>
                        </tab>
                        <tab class="pt-5" heading="{{ 'Roles' | localize }}">
                            <ng-template tabHeading>
                                <span class="badge badge-success assigned-role-count ms-2">
                                    {{ getAssignedRoleCount() }}
                                </span>
                            </ng-template>
                            <div class="row">
                                <div *ngFor="let role of roles" class="col-4">
                                    <label class="form-check form-check-custom form-check-solid py-1">
                                        <input id="EditUser_{{ role.roleName }}" type="checkbox"
                                            name="{{ role.roleName }}" [(ngModel)]="role.isAssigned"
                                            [disabled]="role.inheritedFromOrganizationUnit" class="form-check-input" />
                                        <span class="form-check-label">
                                            {{ role.roleDisplayName }}
                                            <small *ngIf="role.inheritedFromOrganizationUnit">
                                                ({{ 'RoleIsInheritedFromOrganizationUnit' | localize }})
                                            </small>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </tab>
                    </tabset>
                </div>
                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-secondary font-weight-bold"
                        (click)="close()">
                        {{ 'Cancel' | localize }}
                    </button>
                    <button type="submit" class="btn btn-primary font-weight-bold" [disabled]="!userForm.form.valid"
                        [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')">
                        <i class="fa fa-save"></i>
                        <span>{{ 'Save' | localize }}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
