import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestVerificationInput } from '@shared/models/requestVerification/requestVerificationInput';
import { environment as env } from 'environments/environment';

@Injectable()
export class RequestVerificationService {

    private http: HttpClient;
    private baseUrl: string;

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = env.api;
    }

    getRequestVerificationById(requestVerificationId: number): Observable<any> {
        const url_ = this.getUrl('/api/requestVerification/requestVerificationId');
        const params = new HttpParams().set('requestVerificationId', requestVerificationId.toString());
        return this.http.get<any>(url_, { params });
    }

    updateRequestVerifications(requestVerificationInputs: RequestVerificationInput[]): Observable<any> {
        const url_ = this.getUrl('/api/requestVerification/updateRequestVerifications');
        return this.http.put<any>(url_, requestVerificationInputs);
    }

    updateRequestVerification(requestVerificationInput: RequestVerificationInput): Observable<any> {
        const url_ = this.getUrl('/api/requestVerification/updateRequestVerification');
        return this.http.put<any>(url_, requestVerificationInput);
    }

    private getUrl(url: string): string {
        let url_ = this.baseUrl + url;
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    }

}
