import { IAjaxResponse } from '@shared/models/ajax-response';
import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { environment as env } from 'environments/environment';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ProfileServiceProxy, UpdateProfilePictureInput } from '@shared/service-proxies/service-proxies';
import { FileUploader, FileUploaderOptions, FileItem } from 'ng2-file-upload';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { ImageCroppedEvent, base64ToFile } from 'ngx-image-cropper';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { DialogService } from '@shared/services/dialog-service';

@Component({
    selector: 'changeProfilePictureModal',
    templateUrl: './change-profile-picture-modal.component.html',
})
export class ChangeProfilePictureModalComponent extends AppComponentBase {
    @ViewChild('changeProfilePictureModal', { static: true }) modal: ModalDirective;
    @ViewChild('fileInput') fileInput: ElementRef;

    public active = false;
    public uploader: FileUploader;
    public temporaryPictureUrl: string;
    public saving = false;
    public maxProfilPictureBytesUserFriendlyValue = 5;

    imageChangedEvent: any = '';
    private _uploaderOptions: FileUploaderOptions = { url: env.api + '/api/Profile/UploadProfilePicture' };

    constructor(
        injector: Injector,
        private _profileService: ProfileServiceProxy,
        private _appAuthService: AppAuthService,
        private dialogueService: DialogService
    ) {
        super(injector);
    }

    initializeModal(): void {
        this.temporaryPictureUrl = '';
        this.initFileUploader();
        this.active = true;
    }

    show(): void {
        this.initializeModal();
        this.modal.show();
    }

    close(): void {
        this.active = false;
        this.imageChangedEvent = '';
        this.uploader.clearQueue();
        this.modal.hide();
    }

    fileChangeEvent(event: any): void {
        const maxSizeBytes = 5 * 1024 * 1024;
        if (event.target.files[0].size > maxSizeBytes) {
            let message = this.l('ProfilePicture_Warn_SizeLimit', this.maxProfilPictureBytesUserFriendlyValue);
            this.dialogueService.warn(message);
            this.resetFileInput();
            return;
        }
        this.imageChangedEvent = event;
    }

    resetFileInput(): void {
        this.fileInput.nativeElement.value = '';
    }

    imageCroppedFile(event: ImageCroppedEvent) {
        this.uploader.clearQueue();
        this.uploader.addToQueue([<File>base64ToFile(event.base64)]);
    }



    initFileUploader(): void {
        this._uploaderOptions.autoUpload = false;
        this._uploaderOptions.authToken = 'Bearer ' + this._appAuthService.getToken();
        this._uploaderOptions.removeAfterUpload = true;

        this.uploader = new FileUploader(this._uploaderOptions);

        this.uploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };

        this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
            form.append('FileType', fileItem.file.type);
            form.append('FileName', 'ProfilePicture');
            form.append('FileToken', this.guid());
        };

        this.uploader.onSuccessItem = (item, response, status) => {
            const resp = <IAjaxResponse>JSON.parse(response);
            if (resp.success) {
                this.updateProfilePicture(resp.result.fileToken);
            } else {
                this.message.error(resp.error.message);
            }
        };

        this.uploader.setOptions(this._uploaderOptions);
    }

    updateProfilePicture(fileToken: string): void {
        const input = new UpdateProfilePictureInput();
        input.fileToken = fileToken;
        input.x = 0;
        input.y = 0;
        input.width = 0;
        input.height = 0;
        this.saving = true;
        this._profileService
            .updateProfilePicture(input)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe(() => {
                this.eventService.trigger('profilePictureChanged');
                this.close();
            });
    }

    guid(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    save(): void {
        this.uploader.uploadAll();
    }
}
