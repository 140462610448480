<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid">
        <sub-header [title]="'LinkUserOrganization' | localize" [description]="userName">
            <div role="actions">
                <button (click)="save()" class="btn btn-primary">
                    <i class="fa fa-plus"></i>
                    {{ 'Save' | localize }}
                </button>
            </div>
        </sub-header>
        <div [class]="containerClass">
            <div class="card card-custom listContainer">
                <div class="card-body">
                    <form class="horizontal-form" autocomplete="off">
                        <div class="m-form m-form--label-align-right">
                            <div class="row align-items-center m--margin-bottom-10">
                                <div class="col-xl-12">
                                    <div class="form-group m-form__group align-items-center">
                                        <div class="input-group">
                                            <input
                                                [(ngModel)]="filterText"
                                                name="filterText"
                                                id="filterText"
                                                autoFocus
                                                class="form-control m-input"
                                                [placeholder]="l('Search')"
                                                type="text"
                                                (keydown.enter)="search()"
                                            />
                                            <span class="input-group-btn">
                                                <button
                                                    (click)="search()"
                                                    class="btn btn-primary"
                                                    type="submit"
                                                >
                                                    <i class="flaticon-search-1" [attr.aria-label]="l('Search')"></i>
                                                </button>
                                            </span>
                                            <span class="input-group-btn" style="padding-left: 5px">
                                                <button class="btn btn-secondary" (click)="refresh()">
                                                    <i class="la la-refresh"></i>
                                                    {{ 'Refresh' | localize }}
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="row m--margin-bottom-10">
                        <div class="row m-checkbox-list user-org-checkbox" *ngIf="canHaveAccessAll">
                            <label for="EditUser_HasAccessAllOrganization" class="m-checkbox d-flex align-items-center">
                                <input
                                    id="EditUser_HasAccessAllOrganization"
                                    type="checkbox"
                                    name="HasAccessAllOrganization"
                                    [(ngModel)]="hasAccessAll"
                                    (change)="changeHasAccessAll()"
                                    class="form-check-input me-2"
                                />
                                <span>{{ 'HasAccessAllOrganization' | localize }}</span>
                            </label>
                        </div>
                    </div>
                    <grid-pagination (sizeValue)="onPageSizeChanged($event)" class="grid-pagination"></grid-pagination>
                    <ag-grid-angular
                        localizeText
                        #agGrid
                        [gridOptions]="gridOptions" 
                        class="ag-theme-material gardium-grid"
                        [ngClass]="{'disabled-grid' : hasAccessAll}"
                        domLayout="autoHeight"
                        rowModelType="serverSide"
                        [isServerSideGroup]="isServerSideGroup"
                        [getServerSideGroupKey]="getServerSideGroupKey"
                        [pagination]="true"
                        [paginationNumberFormatter]="paginationNumberFormatter"
                        [paginationPageSize]="paginationPageSize"
                        [rowMultiSelectWithClick]="true"
                        [rowSelection]="'multiple'"
                        [treeData]="true"
                        [columnDefs]="columnDefs"
                        [autoGroupColumnDef]="autoGroupColumnDef"
                        (gridReady)="onGridReady($event)"
                        (rowSelected)="onRowSelected($event)"
                        (rowGroupOpened)="rowGroupOpened($event)"
                    ></ag-grid-angular>
                </div>
            </div>
        </div>
    </div>
</div>
