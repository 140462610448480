import { Component, OnInit, Injector, HostListener, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserOrganizationDatasourceService } from '@shared/services/userOrganization.datasource.service';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { ValueFormatterParams, GridApi, GridOptions, ColDef, RowNode } from '@ag-grid-enterprise/all-modules';
import { AgGridAngular } from '@ag-grid-community/angular';
import { organizationStatus } from '@shared/models/organization/organizationStatus';
import { OrganizationUserInput } from '@shared/models/organizationUser/organizationUserInput';
import { OrganizationUserService } from '@shared/services/organizationUser.service';
import { ToastrService } from 'ngx-toastr';
import { UserEditDto, UserServiceProxy, CreateOrUpdateUserInput, UserRoleDto, OrganizationUnitDto } from '@shared/service-proxies/service-proxies';
import { OrganizationUserListOutput } from '@shared/models/organizationUser/organizationUserListOutput';
import { UserTypeEnum } from '../userTypeEnum';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { AppConsts } from '@shared/AppConsts';

@Component({
    selector: 'users-organizations',
    templateUrl: './users-organizations.component.html',
    styleUrls: ['./users-organizations.component.scss'],
    animations: [appModuleAnimation()]
})
export class UsersOrganizationsComponent extends AppComponentBase implements OnInit, OnDestroy {
    @ViewChild('agGrid', { static: false }) agGrid: AgGridAngular;
    rowSelection: string;
    columnDefs: ColDef[];
    autoGroupColumnDef: ColDef;
    filterText: string;
    paginationNumberFormatter;
    paginationPageSize = 20;

    user: UserEditDto;
    userName: string;
    userId: number;
    canHaveAccessAll: boolean;
    hasAccessAll: boolean;
    roles: UserRoleDto[];
    memberedOrganizationUnits: string[];
    allOrganizationUnits: OrganizationUnitDto[];
    // parents getting expanded, missing the children rows, which all need be selected
    parentLoadingForMassSelection: Map<number, RowNode> = new Map();
    /** parent Ids of loaded children */
    loadedParent: Set<number> = new Set();
    initiating = true;
    public defaultColDef;
    public gridOptions: GridOptions;
    private subscriptions: Subscription[] = [];
    private gridApi: GridApi;
    private trackedIds: Set<number> = new Set();
    constructor(
        private injector: Injector,
        private userService: UserServiceProxy,
        private _organizationUserService: OrganizationUserService,
        private userOrganizationDatasourceService: UserOrganizationDatasourceService,
        private toastr: ToastrService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _appLocalizationService: AppLocalizationService,
        private _appAuthService: AppAuthService
    ) {
        super(injector);
        this.rowSelection = 'multiple';
        this.gridOptions = <GridOptions>{
            getChildCount: (data) => data?.childCount,
            localeTextFunc: this.localizeGridText.bind(this)
        };

        this.canHaveAccessAll = _appAuthService.canGrantAccessAllOrganization;

        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };
        this.autoGroupColumnDef = {
            headerName: this._appLocalizationService.l('OrganizationName'),
            field: 'organizationName',
            flex: 1,
            minWidth: 240,
            cellRendererParams: {
                checkbox: true
            },
            valueFormatter: (params) => {
                const data: OrganizationUserListOutput = params.data;
                if (data){
                    const id = data.id;
                    // check data is not already tracked
                    if (!this.trackedIds.has(id)) {
                        const node = params.node;
                        this.trackedIds.add(id);
                        // maybe force aggrid to select or expand ROW
                        if (data.checked) {
                            node.setSelected(true);
                        } else if (data.childCheckedCount) {
                            setTimeout(() => {
                                node.setExpanded(true);
                            });
                        }
                    }
                    return data.organizationName;
                }
            }
        };
        this.columnDefs = [
            {
                headerName: this._appLocalizationService.l('ClientId'),
                width: 250,
                field: 'clientId'
            },
            {
                headerName: this._appLocalizationService.l('FirstContact'),
                field: 'firstContact'
            },
            {
                headerName: this._appLocalizationService.l('FirstContactEmail'),
                width: 250,
                field: 'firstContactEmail',
                hide: true
            },
            {
                headerName: this._appLocalizationService.l('StatusOrganization'),
                width: 200,
                field: 'status',
                valueFormatter: this.toEnum.bind(this),
                hide: true
            },
            {
                field: 'organizationParentId',
                hide: true,
                lockVisible: true
            },
            {
                headerName: '',
                field: 'globalFilter',
                sortable: false,
                filter: 'agTextColumnFilter',
                filterParams: { filterOption: ['contains'] },
                flex: 1,
                hide: true,
                lockVisible: true
            }
        ];
        this.defaultColDef = {
            resizable: true,
            sortable: false,
            filter: true
        };
    }

    // Events

    @HostListener('window:resize', ['$event'])
    onResize() {
        if (this.gridApi) {
            this.gridApi.sizeColumnsToFit();
        }
    }

    ngOnInit() {
        const dataLoadingSubscription = this.userOrganizationDatasourceService.dataLoaded$.pipe(filter((x) => !!x)).subscribe(() => this.onDataLoaded());
        const dataLoadedSubscription = this.userOrganizationDatasourceService.dataLoading$.pipe(filter((x) => !!x)).subscribe(() => this.initiating = true);
        this.subscriptions.push(dataLoadingSubscription, dataLoadedSubscription);

        if (this._route.snapshot.paramMap.get('id') !== '' && this._route.snapshot.paramMap.get('userName') !== '') {
            this.userId = Number(this._route.snapshot.paramMap.get('id'));
            this.userOrganizationDatasourceService.setUserId(this.userId);
            this.userName = this._route.snapshot.paramMap.get('userName');

            this.userService.getUserForEdit(this.userId).subscribe((userResult) => {
                this.user = userResult.user;
                this.roles = userResult.roles;
                this.allOrganizationUnits = userResult.allOrganizationUnits;
                this.memberedOrganizationUnits = userResult.memberedOrganizationUnits;
                this.hasAccessAll = this.user.hasAccessAllOrganization;
            });
        }
    }

    onGridReady(params) {
        const api: GridApi = params.api;
        this.gridApi = api;
        api.setServerSideDatasource(this.userOrganizationDatasourceService);

        if (screen.availWidth > 414) {
            api.sizeColumnsToFit();
        }
    }

    onPageSizeChanged(e) {
        this.gridApi.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    isServerSideGroup(dataItem: OrganizationUserListOutput) {
        return dataItem.id === dataItem.organizationParentId && dataItem.childCount > 0;
    }

    getServerSideGroupKey(dataItem) {
        return dataItem.organizationParentId;
    }

    getChildCount(data) {
        if (data?.childCount > 0) {
            return data.childCount;
        }
    }

    onDataLoaded() {
        if (this.parentLoadingForMassSelection.size) {
            const justLoadedParents: Set<number> = new Set();
            this.gridApi.forEachNode((node: RowNode) => {
                const data: OrganizationUserListOutput = node.data;
                const parentLoading = this.parentLoadingForMassSelection.get(data.organizationParentId);
                if (parentLoading && node.isParentOfNode(parentLoading)) {
                    if (this.hasAccessAll) {
                        this.disableAndDeselectRow(node);
                    } 
                    justLoadedParents.add(data.organizationParentId);
                }
            });
            for (const id of justLoadedParents) {
                this.parentLoadingForMassSelection.delete(id);
                this.loadedParent.add(id);
            }
        }
        this.initiating = false;
    }

    onRowSelected(event) {
        const node: RowNode = event.node;
        const data: OrganizationUserListOutput = node.data;
        const id = data.id;
        const checked = node.isSelected();
        if (data.childCount > 0) {
            node.setExpanded(true);
            if (!this.initiating) {
                if (checked) {
                    this.parentLoadingForMassSelection.set(id, node);
                    if (this.loadedParent.has(id)) {
                        this.onDataLoaded();
                    }
                } else {
                    this.parentLoadingForMassSelection.delete(id);
                }
            }
        }
    }

    rowGroupOpened(event) {
        this.loadedParent.add(event.node.data.id);
    }

    changeHasAccessAll() {
        this.gridApi.forEachNode((rowNode: RowNode) => {
            if (this.hasAccessAll) {
                this.disableAndDeselectRow(rowNode);
            } else {
                this.enableRow(rowNode);
            }
        });
    }

    enableRow(rowNode: RowNode & { data: OrganizationUserListOutput }, forcedValue: boolean = null) {
        const shouldSelect = forcedValue || (rowNode.data?.checked as boolean) || false;
        if (!rowNode.selectable) {
            rowNode.setRowSelectable(true);
        }
        rowNode.setSelected(shouldSelect);
    }

    disableAndDeselectRow(rowNode: RowNode) {
        rowNode.setSelected(false);
        rowNode.setRowSelectable(false);
    }

    toEnum(params: ValueFormatterParams): string {
        return this._appLocalizationService.l(organizationStatus[params.value]);
    }

    localizeGridText(key: string, defaultValue: string) {
        const gridKey = 'grid.' + key;
        const translatedText = this._appLocalizationService.l(gridKey, AppConsts.localization.defaultLocalizationSourceName);
        return translatedText === gridKey ? defaultValue : translatedText;
    }

    async save() {
        this.updateUser();
        if (!this.hasAccessAll) {
            await this.addUsersOrganization();
        }
    }

    async addUsersOrganization() {
        const listInput = new Array<OrganizationUserInput>();

        this.gridApi.getSelectedNodes().forEach((node) => {
            listInput.push({
                id: 0,
                userId: this.userId,
                organizationId: node.data.id
            });
        });

        if (listInput.length === 0) {
            listInput.push({
                id: 0,
                userId: this.userId,
                organizationId: 0
            });
        }

        await this._organizationUserService
            .addOrganizationUserList(listInput)
            .toPromise()
            .then((response) => {
                const valueReceived = response.result;
                if (valueReceived.id !== null) {
                    const title = this._appLocalizationService.l('LinkUserOrganization');
                    const successMessage = this._appLocalizationService.l('LinkedUserOrganization');
                    this.showSuccess(title, successMessage);
                    this.returnToList();
                } else {
                    const title = this._appLocalizationService.l('UserOrganizationLinkIssue');
                    const errorMessage = this._appLocalizationService.l('UserOrganizationLinkTryAgainLater');
                    this.showErrorMessage(errorMessage, title);
                }
                return valueReceived;
            });
    }

    updateUser() {
        const input = new CreateOrUpdateUserInput();
        input.user = this.user;
        input.user.hasAccessAllOrganization = this.hasAccessAll;
        input.assignedRoleNames = this.roles?.filter((role) => role.isAssigned).map((role) => role.roleName) ?? [];
        this.userService.createOrUpdateUser(input).subscribe(() => {
            const title = this._appLocalizationService.l('UserUpdate');
            const successMessage = this._appLocalizationService.l('UserUpdated');
            this.showSuccess(title, successMessage);
            this.returnToList();
        });
    }

    showSuccess(title: string, successMessage: string) {
        this.toastr.success(title, successMessage);
    }

    showErrorMessage(errorMessage: string, title: string) {
        this.toastr.error(errorMessage, title);
    }

    refresh() {
        this.filterText = '';
        this.search();
    }

    search() {
        this.parentLoadingForMassSelection.clear();
        this.loadedParent.clear();
        const filterGlobal = this.agGrid.gridOptions.api.getFilterInstance('globalFilter');
        filterGlobal.setModel({
            filterType: 'text',
            type: 'contains',
            filter: this.filterText
        });
        this.agGrid.gridOptions.api.onFilterChanged();
    }

    returnToList() {
        if (this.user.type === UserTypeEnum.Client) {
            this._router.navigate(['/client-users']);
        }
        this._router.navigate(['/users']);
    }

    ngOnDestroy() {
        if (this.subscriptions.length > 0) {
            this.subscriptions.forEach((element) => {
                element.unsubscribe();
            });
        }
    }
}
