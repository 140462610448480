import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { Languages } from '@app/languages/languages.enum';
import * as utils from '@shared/utils/utils';
import LocalizationService from '@shared/services/localization.service';

@Injectable()
export class AppLocalizationService extends LocalizationService {
    l(key: string, ...args: any[]): string {
        args.unshift(key);
        args.unshift(AppConsts.localization.defaultLocalizationSourceName);
        return this.ls.apply(this, args);
    }

    ls(sourcename: string, key: string, ...args: any[]): string {
        let localizedText = this.localize(key, sourcename);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return utils.formatString.apply(this, args);
    }

    public getCurrentLanguage(): number {
        return this.getLanguageId(this.currentLanguage.displayName);
    }

    public getLanguageId(language: string): number {
        switch (language) {
            case Languages.ENGLISH:
                return 0;
            case Languages.FRENCH:
                return 1;
        }
    }

    public getAllLanguages() {
        return this.languages.map(lang => {
            return {
                ...lang,
                id: this.getLanguageId(lang.displayName)
            }
        })
    }

    public getLanguageName(languageId: number): string {
        switch (languageId) {
            case 0 :
                return Languages.ENGLISH;
            case 1:
                return Languages.FRENCH;
        }
    }
}
