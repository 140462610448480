<div class="m-subheader" style="padding-top:1%; padding-bottom: 1%;">
    <div class="row align-items-center">
        <div class="mr-auto col-auto">
            <h5 class="m-subheader__title">{{"Notes" | localize}}</h5>
            <h6 style="font-weight:normal;">{{"NotesDescription" | localize}}</h6>
        </div>
        <div class="col text-end mt-3 mt-md-0">
            <a href="javascript:;" (click)="addNotes()" class="btn btn-primary">
                <i class="fa fa-plus"></i>
                {{'NewNote' | localize}}
            </a>
        </div>
    </div>
</div>
<div class="form-group" *ngIf="isGranted('Pages.Management.Requests.PrivateNotes')">
    <div class="form-group col-md-6">
        <label for="showPrivateNote" class="m-checkbox">
            <input id="showPrivateNote" type="checkbox" name="showPrivateNote" [checked]="showPrivateNote"
                (change)="changeCheckbox()">
            {{"ShowPrivateNote" | localize}}
            <span></span>
        </label>
    </div>
</div>
<div>
    <grid-pagination class="grid-pagination" (sizeValue)="onPageSizeChanged($event)"></grid-pagination>
    <ag-grid-angular localizeText #agGrid class="ag-theme-material  gardium-small-grid"
        (gridReady)="onGridReady($event)" (selectionChanged)="onSelectionChanged()"
        [paginationNumberFormatter]="paginationNumberFormatter" [paginationPageSize]="paginationPageSize"
        [pagination]="true" [rowSelection]="rowSelection" [rowData]="rowData" [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef">
    </ag-grid-angular>
</div>
<notes-add #notesAddModal [requestId]="requestId" [requestStatus]="requestStatus" (modalSave)="refresh()"></notes-add>
<notes-details #notesDetailsModal></notes-details>
