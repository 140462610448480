<div bsModal #notesAddModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="notesAddModal"
    aria-hidden="true" [config]="{backdrop: 'static', keyboard: !saving}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form [formGroup]="formGroup" (ngSubmit)="save()" autocomplete="off" novalidate>
                <div class="card-body">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            <h5>{{"Notes" | localize}}</h5>
                        </h4>
                    </div>
                    <div class="m-content">
                        <div class="form-group">
                            <div class="form-group">
                                <label for="description">{{"Description" | localize}}</label>
                                <textarea class="form-control" id="description" formControlName="description"
                                    type="text" name="description" rows="5"></textarea>
                                <validation-messages [formCtrl]="formGroup.get('description')"></validation-messages>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6"
                                     *ngIf="isGranted('Pages.Management.Requests.PrivateNotes')">
                                    <label for="isPrivateNote" class="m-checkbox">
                                        <input id="isPrivateNote" type="checkbox" name="isPrivateNote"
                                               [checked]="isPrivateNote" (change)="changeIsPrivate()">
                                        {{"IsPrivateNote" | localize}}
                                        <span></span>
                                    </label>
                                </div>
                                <div class="form-group col-md-6"
                                     *ngIf="isGranted('Pages.Management.Requests.PpoNotes') && !isAgentPpo">
                                    <label for="isPpoNote" class="m-checkbox">
                                        <input id="isPpoNote" type="checkbox" name="isPpoNote"
                                               [checked]="isPpoNote" (change)="changeIsPpo()">
                                        {{"IsPpoNote" | localize}}
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button [disabled]="saving" type="button" class="btn btn-default"
                            (click)="close()">{{"Cancel" | localize}}</button>
                        <button type="submit" class="btn btn-primary" [disabled]="!formGroup.valid"
                            [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"><i class="fa fa-save"></i>
                            <span>{{"Save" | localize}}</span></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
