
<div class="d-flex flex-row-reverse mb-4">
    <button *ngIf="'Pages.Administration.Users.Create' | permission" class="btn btn-primary" (click)="createUser()">
        <i class="fa fa-plus"></i> {{"CreateNewUser" | localize}}
    </button>
</div>

<div class="row align-items-center">
    <div class="col-xl-12">
        <div class="form-group align-items-center">
            <div class="input-group">
                <input [(ngModel)]="filterText" type="text" id="filterText" [placeholder]="l('Search')" autoFocus
                    class="form-control" (keydown.enter)="search()" />
                <span class="input-group-btn">
                    <button (click)="search()" class="btn btn-primary" type="submit">
                        <i class="flaticon-search-1" [attr.aria-label]="l('Search')">
                        </i>
                    </button>
                </span>
                <span class="input-group-btn" style="padding-left:5px;">
                    <button class="btn btn-secondary" (click)="refresh()"><i class="la la-refresh"></i>
                        {{'Refresh' | localize}}</button>
                </span>
            </div>
        </div>
    </div>
</div>

<div class="action-container">
    <div class="input-group-btn">
        <button class="btn btn-outline-secondary" 
                [disabled]="!hasSelection()"
                (click)="deleteSelection()"
                *ngIf="canEditUsers()">
            <i class="la la-user-times"></i>
            {{ 'DeleteSelection' | localize }}
        </button>
    </div>
    <grid-pagination class="grid-pagination" (sizeValue)="onPageSizeChanged($event)">
    </grid-pagination>
</div>

<ag-grid-angular #agGrid 
                 class="ag-theme-material gardium-grid"    
                 domLayout="autoHeight" 
                 localizeText 
                 [defaultColDef]="defaultColDef" 
                 [columnDefs]="columnDefs"
                 [pagination]="true" 
                 [paginationNumberFormatter]="paginationNumberFormatter" 
                 [paginationPageSize]="paginationPageSize"
                 [rowData]="userList" 
                 [rowSelection]="rowSelection" 
                 [updateList]="'organizationVerifications'" 
                 (gridReady)="onGridReady($event)"
                 (cellClicked)="onCellClicked($event)">
</ag-grid-angular>

<create-or-edit-user-modal #createOrEditUserModal (modalSave)="getUserlist()"></create-or-edit-user-modal>
