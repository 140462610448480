import { ColDef, GridApi } from '@ag-grid-enterprise/all-modules';
import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CandidateListOutput } from '@shared/models/candidat/candidateListOutput';
import { CandidatInput } from '@shared/models/candidat/candidatInput';
import { candidatLanguage } from '@shared/models/candidat/candidatLanguage';
import { CandidatOutput } from '@shared/models/candidat/candidatOutput';
import { candidatstatus } from '@shared/models/candidat/candidatStatus';
import { RequestOutput } from '@shared/models/request/requestOutput';
import { CRUDAction } from '@shared/models/shared/CRUDAction';
import { CandidatsService } from '@shared/services/candidats.service';
import { emailAddressValidator } from '@shared/utils/validation/email-validator.directive';

import { RequiredFieldsForCodes } from '@shared/utils/verificationsEnumAndFunctions';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { WizzardRequestCreatorComponent } from '../wizzard-request/wizzard-request.component';

@Component({
    selector: 'request-wizard-candidate-create',
    templateUrl: './request-wizard-candidate-create.component.html',
    styleUrls: [ './request-wizard-candidate-create.component.scss' ]
})
export class RequestWizardCandidateCreateComponent extends AppComponentBase implements OnInit {
    @Input() public request: RequestOutput;
    @Output() public requestChange: EventEmitter<RequestOutput> = new EventEmitter<RequestOutput>();
    @Input() public createCandidate: boolean;
    @Output() public createCandidateChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() public formSearch: UntypedFormGroup;
    @Output() public formSearchChange: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
    @Input() public candidateform: UntypedFormGroup;
    @Input() public resetDriverLicenseRequired: BehaviorSubject<boolean>;
    loading: boolean;
    public defaultColDef;
    public columnDefs: ColDef[];
    public paginationNumberFormatter;
    public paginationPageSize = 5;
    public isCandidatCreated = false;
    public selectedCandidate: CandidatOutput = null;
    public candidates: CandidateListOutput[] = [];
    public searchDone = false;
    public isDriverLicenseRequired = false;
    public isEmailRequired = false;
    //Langue francais par defaut
    public languageSelected: candidatLanguage = candidatLanguage.French;
    private gridApi: GridApi;
    constructor(
        private candidateService: CandidatsService,
        private _appLocalizationService: AppLocalizationService,
        private toastr: ToastrService,
        @Inject(WizzardRequestCreatorComponent)
        public requestWizard: WizzardRequestCreatorComponent,
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.setValidators();
        });

        this.resetDriverLicenseRequired?.subscribe((event) => {
            if (event) {
                this.setValidators();
            }
        });
    }

    setDriverLicenseValidators() {
        if (this.isDriverLicenseRequired) {
            this.candidateform.controls[ 'driverLicenceNumber' ].setValidators(Validators.required);
            this.candidateform.controls[ 'driverLicenceNumber' ].markAsTouched();
        }
    }

    setEmailValidators() {
        if (this.isEmailRequired) {
            this.candidateform.controls[ 'email' ].setValidators([ Validators.required, emailAddressValidator ]);
            this.candidateform.controls[ 'email' ].markAsTouched();
            this.candidateform.controls[ 'email' ].updateValueAndValidity();
        }
    }

    onCandidatesChange(event) {
        this.candidates = event;
    }

    returnToSelectCandidate() {
        this.createCandidate = false;
        this.createCandidateChange.emit(this.createCandidate);
    }

    addCandidate() {
        if (this.loading) {
            return;
        }

        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }

        this.loading = true;

        this.candidateform.controls[ 'firstName' ] = this.formSearch.controls[ 'firstName' ];
        this.candidateform.controls[ 'lastName' ] = this.formSearch.controls[ 'lastName' ];
        this.candidateform.controls[ 'sex' ] = this.formSearch.controls[ 'sex' ];
        this.candidateform.controls[ 'birthdate' ] = this.formSearch.controls[ 'birthdate' ];
        this.candidateform.controls[ 'language' ].setValue(this.languageSelected);
        const candidate: CandidatInput = CandidatInput.fromFormControl('', this.candidateform, CRUDAction.Create);

        candidate.id = 0;
        candidate.status = candidatstatus.Actif;
        candidate.organization = this.request.organization;
        candidate.creationTime = new Date();

        this.isCandidatCreated = true;
        this.candidateService.createCandidat(candidate).subscribe({
            next: (value) => {
                if (value.result.publicId !== null) {
                    this.selectedCandidate = CandidatOutput.FromInput(candidate);
                    this.selectedCandidate.id = value.result.id;
                    this.selectedCandidate.publicId = value.result.publicId;
                    this.request.candidat = this.selectedCandidate;
                    this.requestChange.emit(this.request);
                    this.showSuccess();
                    this.save();
                } else {
                    this.isCandidatCreated = false;
                    this.showErrorMessage();
                    this.loading = false;
                }
                return value.result;
            },
            error: (err) => {
                this.loading = false;
                this.notify.error('Server error: ' + err.error.error.message);
            }
        });
    }

    isFormInvalid(): boolean {
        return this.candidateform.invalid;
    }

    showForNotValidMessage() {
        const title = this._appLocalizationService.l('VerifyAllControlsTitle');
        const message = this._appLocalizationService.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }

    markAllControlsAsTouch() {
        this.candidateform.controls[ 'firstName' ].markAsTouched();
        this.candidateform.controls[ 'lastName' ].markAsTouched();
        this.candidateform.controls[ 'sex' ].markAsTouched();
        this.candidateform.controls[ 'birthdate' ].markAsTouched();
        this.candidateform.controls[ 'otherNames' ].markAsTouched();
        this.candidateform.controls[ 'maidenName' ].markAsTouched();
        this.candidateform.controls[ 'birthPlace' ].markAsTouched();
        this.candidateform.controls[ 'socialInsuranceNumber' ].markAsTouched();
        this.candidateform.controls[ 'driverLicenceNumber' ].markAsTouched();
        this.candidateform.controls[ 'phoneNumber' ].markAsTouched();
        this.candidateform.controls[ 'email' ].markAsTouched();
        this.candidateform.controls[ 'language' ].markAsTouched();
    }

    showSuccess() {
        const title = this._appLocalizationService.l('CandidatCreate');
        const successMessage = this._appLocalizationService.l('CandidatCreated');
        this.toastr.success(successMessage, title);
    }

    showErrorMessage() {
        const title = this._appLocalizationService.l('CandidatCreateIssue');
        const errorMessage = this._appLocalizationService.l('CandidatCreateTryAgainLater');
        this.toastr.error(errorMessage, title);
    }

    cancel() {
        this.formSearchChange.emit(this.formSearch);
        this.createCandidate = false;
        this.createCandidateChange.emit(this.createCandidate);
    }

    setCompleteRequestVerifications() {
        if (this.requestWizard.verifications === null) {
            return;
        }
        const updatedRequestVerification = [ ...this.request.requestVerifications ].map((rv) => {
            const verification = this.requestWizard.verifications.find((verif) => verif.id === rv.verificationId);
            return {
                ...rv,
                verification: { ...verification },
                verificationPrice: verification?.price || 0
            };
        });
        this.request.requestVerifications = updatedRequestVerification;
    }

    isRequiredCodesIncluded(requiredCodes: string[]): boolean {
        this.setCompleteRequestVerifications();
        return this.request.requestVerifications.filter((requestVerif) => requestVerif.quantity > 0 && requiredCodes.includes(requestVerif.verification.code)).length > 0;
    }

    driverLicenseRequired() {
        return this.isRequiredCodesIncluded(RequiredFieldsForCodes.getDriverLicenseRequiredCodes());
    }

    emailRequired() {
        return this.isRequiredCodesIncluded(RequiredFieldsForCodes.getEmailRequiredCodes());
    }

    onGridReady(params) {
        this.gridApi = params.api;
        if (screen.availWidth > 414) {
            this.gridApi.sizeColumnsToFit();
        }
    }

    save() {
        this.requestChange.emit(this.request);
        this.requestWizard.save(false);
    }

    private setValidators() {
        this.isDriverLicenseRequired = this.driverLicenseRequired();
        this.isEmailRequired = this.emailRequired();
        this.setDriverLicenseValidators();
        this.setEmailValidators();
    }
}
