import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment as env } from 'environments/environment';
import { ProfessionnalAccreditationOutput } from '@shared/models/CandidatDetails/professionnal-accreditations/professionnalAccreditationOutput';
import { ProfessionnalAccreditationInput } from '@shared/models/CandidatDetails/professionnal-accreditations/professionnalAccreditationInput';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })

  export class ProfessionnalAccreditationService {
    private http: HttpClient;
    private baseUrl: string;

    constructor(@Inject(HttpClient) http: HttpClient) {
      this.http = http;
      this.baseUrl = env.api;
    }

    private getUrl(url: string): string {
        let url_ = this.baseUrl + url;
        url_ = url_.replace(/[?&]$/, '');
        return url_;
      }

      addProfessionnalAccreditation(accreditationInput: ProfessionnalAccreditationInput): Observable<any> {
        let url_ = this.getUrl('/api/professionnalAccreditations/AddProfessionnalAccreditation');
        return this.http.post<ProfessionnalAccreditationOutput>(url_, accreditationInput);
      }

      getProfessionnalAccreditationsByRequest(requestPublicId: string): Observable<any> {
        let url_ = this.getUrl(`/api/professionnalAccreditations/GetProfessionnalAccreditationsByRequest/${requestPublicId}`);
        return this.http.get<any>(url_);
      }

      getProfessionnalAccreditationsByCandidat(candidatPublicId: string): Observable<any> {
        let url_ = this.getUrl(`/api/professionnalAccreditations/GetProfessionnalAccreditationsByCandidat/${candidatPublicId}`);
        return this.http.get<any>(url_);
      }

      updateProfessionnalAccreditation(accreditationInput: ProfessionnalAccreditationInput): Observable<any> {
        let url_ = this.getUrl('/api/professionnalAccreditations/UpdateProfessionnalAccreditation');
        return this.http.put<any>(url_, accreditationInput);
      }

      deleteProfessionnalAccreditation(professionnalAccreditationInputPublicId: string): Observable<any> {
        let url_ = this.getUrl('/api/professionnalAccreditations/DeleteProfessionnalAccreditation');
        let params = new HttpParams().set('professionnalAccreditationInputPublicId', professionnalAccreditationInputPublicId);
        return this.http.post<any>(url_, null, { params });
      }

}
