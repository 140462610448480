import {
    Component,
    OnInit,
    ViewChild,
    Input,
    Injector,
    EventEmitter,
    Output,
} from '@angular/core';
import { ProfessionnalReferenceAddComponent } from '../professionnal-reference-add/professionnal-reference-add.component';
import { AgGridAngular } from '@ag-grid-community/angular';
import { ProfessionnalReferencesOutput } from '@shared/models/CandidatDetails/professionnalReferences/professionnalReferencesOutput';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GridBtnUpdateComponent } from '@app/shared/grid/grid-btn-update/grid-btn-update.component';
import { GridBtnDeleteComponent } from '@app/shared/grid/grid-btn-delete/grid-btn-delete.component';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { AppConsts } from '@shared/AppConsts';
import { ProfessionnalReferenceUpdateComponent } from '../professionnal-reference-update/professionnal-reference-update.component';
import { LazyLoadEvent } from 'primeng/api';
import { ProfessionnalReferenceService } from '@shared/services/professionnalReference.service';
import { InformationSourceDTO } from '@shared/models/request/informationSourceDTO';
import { InvoiceStatusEnum } from '@shared/models/InvoiceElements/invoice/invoiceStatus';

@Component({
    selector: 'professionnal-reference-list',
    templateUrl: './professionnal-reference-list.component.html',
    styleUrls: ['./professionnal-reference-list.component.scss'],
    providers: [DatePipe],
})
export class ProfessionnalReferenceListComponent
    extends AppComponentBase
    implements OnInit {
    @ViewChild('agGrid') agGrid: AgGridAngular;
    @ViewChild('professionnalRefenceAddModal') professionnalRefenceAddModal: ProfessionnalReferenceAddComponent;
    @Input() requestId: string;
    @Input() candidateId: number;
    @Input() candidatePublicId: string;
    @Input() requestOpen: boolean;
    @Input() informationSource: InformationSourceDTO =
        InformationSourceDTO.Candidate;
    @Output() modalRefresh: EventEmitter<any> = new EventEmitter<any>();
    @Input() invoiceStatus: InvoiceStatusEnum;
    @Input() isAllowedToUpdateRequest: boolean;

    public defaultColDef;
    paginationNumberFormatter;
    paginationPageSize = 20;

    rowData: ProfessionnalReferencesOutput[] = [];
    columnDefs: any;
    publicId: string;
    selectedProfessionnalReference: ProfessionnalReferencesOutput;
    bsModalRef: BsModalRef;
    rowSelection: string;
    allReadOnly: boolean;
    invoiceSent: boolean;

    constructor(
        private injector: Injector,
        private modalService: BsModalService,
        private datePipe: DatePipe,
        private _appLocalizationService: AppLocalizationService,
        private _route: ActivatedRoute,
        private service: ProfessionnalReferenceService,
        private toastr: ToastrService
    ) {
        super(injector);
        this.rowSelection = 'single';
        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };
    }

    async ngOnInit() {
        if (this.invoiceStatus === InvoiceStatusEnum.InvoiceSent) {
            this.invoiceSent = true;
        }
        await this.initializeRowData();

        this.columnDefs = [
            {
                headerName: this._appLocalizationService.l('NameTitle'),
                width: 250,
                valueGetter: this.concatNameTitle,
                sortable: true,
                filter: true,
            },
            {
                headerName: this._appLocalizationService.l('Company'),
                width: 180,
                field: 'company',
                sortable: true,
                filter: true,
            },
            {
                headerName: this._appLocalizationService.l('ProfessionnalFrom'),
                field: 'from',
                valueFormatter: this.dateFormater.bind(this),
                sortable: true,
                filter: true,
            },
            {
                headerName: this._appLocalizationService.l('ProfessionnalTo'),
                field: 'to',
                valueFormatter: this.dateFormater.bind(this),
                sortable: true,
                filter: true,
            },
            {
                headerName: this._appLocalizationService.l('PhoneNumber'),
                width: 250,
                valueGetter: this.concatTelephonePoste,
                sortable: true,
                filter: true,
            },
            {
                headerName: this._appLocalizationService.l('EmailAddress'),
                width: 250,
                field: 'email',
                sortable: true,
                filter: true,
            },
            {
                headerName: '',
                width: 110,
                cellRendererFramework: this.getEditBtnIfNotReadOnly(),
                cellRendererParams: {
                    action: this.updateProfessionnalReference,
                },
            },
            {
                headerName: '',
                width: 110,
                cellRendererFramework: this.getDeleteBtnIfNotReadOnly(),
                cellRendererParams: {
                    action: this.deleteProfessionnalReference,
                },
            },
        ];

        this.defaultColDef = { resizable: true, sortable: true, filter: true };
    }

    getEditBtnIfNotReadOnly() {
        const btn = GridBtnUpdateComponent;
        if (this.allReadOnly) {
            return null;
        } else if (this.isAllowedToUpdateRequest) {
            return btn;
        } else {
            return null;
        }
    }

    onPageSizeChanged(e) {
        this.agGrid.api.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    getDeleteBtnIfNotReadOnly() {
        const btn = GridBtnDeleteComponent;
        if (this.allReadOnly) {
            return null;
        } else if (this.invoiceSent) {
            return null;
        } else {
            return btn;
        }
    }

    concatNameTitle(params) {
        let name = params.data.name;
        if (params.data.title != null) {
            name += ', ' + params.data.title;
        }
        return name;
    }

    concatTelephonePoste(params) {
        let prefix = ' + 1 ';
        if (params.data.phoneExtension === null) {
            params.data.phoneExtension = '';
        }
        if (
            params.data.phoneNumber === null ||
            params.data.phoneNumber === ''
        ) {
            params.data.phoneNumber = '';
            prefix = '';
        }
        const phoneExtension =
            params.data.phoneExtension !== ''
                ? '#' + params.data.phoneExtension
                : '';
        return prefix + params.data.phoneNumber + ' ' + phoneExtension;
    }

    dateFormater(params: ValueFormatterParams) {
        return this.datePipe.transform(
            params.value,
            AppConsts.dateTimeFormatOnlyDate
        );
    }

    async initializeRowData() {
        await this.service
            .getProfessionnalReferencesByCandidat(this.candidatePublicId)
            .toPromise()
            .then((response) => {
                this.rowData = response.result;
                this.allReadOnly = false;
            });
    }

    addProfessionnalReference() {
        this.professionnalRefenceAddModal.show();
    }

    updateProfessionnalReference = async (element: ProfessionnalReferencesOutput) => {
        this.selectedProfessionnalReference = element;
        this.bsModalRef = this.modalService.show(
            ProfessionnalReferenceUpdateComponent,
            {
                initialState: {
                    selectedProfessionnalReference:
                        this.selectedProfessionnalReference,
                },
                backdrop: 'static',
            }
        );
        this.bsModalRef.content.modalSave.subscribe((result) => {
            this.refresh();
        });
    };

    deleteProfessionnalReference = async (element: ProfessionnalReferencesOutput) => {
        this.service
            .deleteProfessionnalReference(element.publicId)
            .subscribe((response) => {
                const refPers = response.result;
                this.initializeRowData();
                this.modalRefresh.emit(null);
                if (refPers.id != null) {
                    this.showSuccess();
                } else {
                    this.showErrorMessage();
                }
            });
    };

    showSuccess() {
        const title = this._appLocalizationService.l(
            'ProfessionnalReferenceDelete'
        );
        const successMessage = this._appLocalizationService.l(
            'ProfessionnalReferenceDeleted'
        );
        this.toastr.success(successMessage, title);
    }

    showErrorMessage() {
        const title = this._appLocalizationService.l(
            'ProfessionnalReferenceDeleteIssue'
        );
        const errorMessage = this._appLocalizationService.l(
            'ProfessionnalReferenceDeleteTryAgainLater'
        );
        this.toastr.error(errorMessage, title);
    }

    refresh(event?: LazyLoadEvent) {
        this.initializeRowData();
        this.modalRefresh.emit(null);
    }
}
