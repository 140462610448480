export function formatString() {
    if (arguments.length < 1) {
        return null;
    }

    let str = arguments[0];

    for (let i = 1; i < arguments.length; i++) {
        let placeHolder = '{' + (i - 1) + '}';
        str = replaceAll(str, placeHolder, arguments[i]);
    }

    return str;
}

export function replaceAll(str, search, replacement) {
    let fix = search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    return str.replace(new RegExp(fix, 'g'), replacement);
}

export function truncateStringWithPostfix(str, maxLength, postfix: string = '...') {
    if (!str || !str.length || str.length <= maxLength) {
        return str;
    }

    if (maxLength <= postfix.length) {
        return postfix.substr(0, maxLength);
    }

    return str.substr(0, maxLength - postfix.length) + postfix;
}
