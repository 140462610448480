import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { AppComponentBase } from '@shared/common/app-component-base';
import { RequestStatus } from '@shared/models/request/requestStatus';
import { SendingMethodType } from '@shared/models/request/sendingMethodType';

@Component({
    selector: 'grid-btn-cancel',
    templateUrl: './grid-btn-cancel.component.html',
    styleUrls: ['./grid-btn-cancel.component.scss']
})
export class GridBtnCancelComponent extends AppComponentBase implements OnInit, ICellRendererAngularComp {
    params: any;
    showButton = false;

    ngOnInit() {
        if (!this.params.data) {
            this.showButton = false;
        }
        if (
            (this.params.data.status === RequestStatus.New && this.params.data.sendingMethod !== SendingMethodType.EzSign) ||
            this.params.data.status === RequestStatus.InProgressClient ||
            this.params.data.status === RequestStatus.InProgressCustomerEzSign
        ) {
            this.showButton = true;
        }
    }

    refresh(params: any): boolean {
        return false;
    }

    agInit(params): void {
        this.params = params;
    }

    onClick(): void {
        this.params.action(this.params.data);
    }
}
