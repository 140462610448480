export interface FormFilterValue {
    name: string;
    type: 'number' | 'string' | 'boolean';
    value: unknown;
}
export interface FormFilters {
    name: string;
    values: FormFilterValue[] | string[];
}
export enum FormFilterNames {
    Advanced = 'advanced',
    Global = 'global'
}
export enum FormFilterValueTypes {
    Status = 'status',
    Language = 'language'
}
