<div class="m-portlet__body" style="padding-top:1%; padding-bottom: 1%;">
    <div class="m-subheader">
        <div class="row align-items-center">
            <div class="col text-end mt-3 mt-md-0">
                <a href="javascript:;" (click)="addPersonnalReferences()" class="btn btn-primary"
                    *ngIf="requestOpen && !invoiceSent">
                    <i class="fa fa-plus"></i>
                    {{'New' | localize}}
                </a>
            </div>
        </div>
    </div>
    <div class="grid-pagination-space">
        <grid-pagination class="grid-pagination" (sizeValue)="onPageSizeChanged($event)"></grid-pagination>
        <ag-grid-angular localizeText #agGrid class="ag-theme-material  gardium-small-grid" [rowData]="rowData"
            [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowSelection]="rowSelection"
            [paginationNumberFormatter]="paginationNumberFormatter" [paginationPageSize]="paginationPageSize"
            [pagination]="true"></ag-grid-angular>
    </div>
    <personnal-references-add #personnalRefencesAddModal (modalSave)="refresh()" [candidateId]="candidateId"
        [requestId]="requestId">
    </personnal-references-add>
</div>