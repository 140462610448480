import Swal, { SweetAlertOptions } from 'sweetalert2'
import LocalizationService from '@shared/services/localization.service';
import { Injectable } from '@angular/core';

@Injectable()
export class DialogService {
    private _localizationService: LocalizationService;

    constructor(localizationService: LocalizationService) {
        this._localizationService = localizationService;
    }

    public confirm(message: string, title?: string, callback?: Function, options?: (SweetAlertOptions & { isHtml?: boolean })) {
        options = options || {};
        options.title = title ?? options.title;
        options.icon = 'warning';

        options.showCancelButton = true;

        if (options.isHtml) {
            options.html = message;
        } else {
            options.text = message;
        }
        const { isHtml, ...optionsSafe } = options;
        return Swal.fire(optionsSafe).then(function(result) {
            callback && callback(result.value, result);
        });
    }

    error(message: string, title?: string, options?: (SweetAlertOptions & { isHtml?: boolean })) {
        return this.showMessage('error', message, title, options);
    }

    info(message: string, title?: string, options?: (SweetAlertOptions & { isHtml?: boolean })) {
        return this.showMessage('info', message, title, options);
    }

    success(message: string, title?: string, options?: (SweetAlertOptions & { isHtml?: boolean })) {
        return this.showMessage('success', message, title, options);
    }

    warn(message: string, title?: string, options?: (SweetAlertOptions & { isHtml?: boolean })) {
        return this.showMessage('warning', message, title, options);
    }

    private showMessage(type, message: string, title?: string, options?: (SweetAlertOptions & { isHtml?: boolean })) {

        if (!title) {
            title = message;
            message = undefined;
        }

        options = options || {};
        options.title = title;
        options.icon = type;
        options.confirmButtonText = options.confirmButtonText || this._localizationService.localize('Ok', 'PreEmploi');

        if (options.isHtml) {
            options.html = message;
        } else {
            options.text = message;
        }

        const { isHtml, ...optionsSafe } = options;
        return Swal.fire(optionsSafe);
    }

}
