<div bsModal #accreditationAddModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="accreditationAddModal" aria-hidden="true" [config]="{backdrop: 'static', keyboard: !saving}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <form [formGroup]="formGroup" (ngSubmit)="save()" autocomplete="off" novalidate>
        <div class="card-body">
          <div class="modal-header">
            <h4 class="modal-title">
              <h5>{{"ProfessionnalAccreditations" | localize}}</h5>
            </h4>
          </div>
          <div class="m-content">
            <div class="form-group">
              <label for="year">{{"Year" | localize}} <span class="asterix">*</span></label>
              <input id="year" formControlName="year" type="number" name="year" class="form-control" maxlength="256"
                min="0">
              <validation-messages [formCtrl]="formGroup.get('year')"></validation-messages>
            </div>
            <div class="form-group">
              <label for="location">{{"Location" | localize}} <span class="asterix">*</span></label>
              <input id="location" formControlName="location" type="text" name="location" class="form-control"
                maxlength="256">
              <validation-messages [formCtrl]="formGroup.get('location')"></validation-messages>
            </div>
            <div class="form-group">
              <label for="institution">{{"Institution" | localize}} <span class="asterix">*</span></label>
              <input id="institution" formControlName="institution" type="text" name="institution" class="form-control"
                maxlength="256">
              <validation-messages [formCtrl]="formGroup.get('institution')"></validation-messages>
            </div>
            <div class="form-group">
              <label for="title">{{"Title" | localize}} <span class="asterix">*</span></label>
              <input id="title" formControlName="title" type="text" name="title" class="form-control" maxlength="256">
              <validation-messages [formCtrl]="formGroup.get('title')"></validation-messages>
            </div>
          </div>
          <div class="modal-footer">
            <button [disabled]="saving" type="button" class="btn btn-default"
              (click)="close()">{{"Cancel" | localize}}</button>
            <button type="submit" class="btn btn-primary" [disabled]="!formGroup.valid" [buttonBusy]="saving"
              [busyText]="l('SavingWithThreeDot')"><i class="fa fa-save"></i>
              <span>{{"Save" | localize}}</span></button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>