import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as VerificationsActions from '../../actions/verifications/actions';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { VerificationService } from '@shared/services/verification.service';
import { VerificationListOutput } from '@shared/models/verification/verificationListOutput';

@Injectable()
export class VerificationsEffects {

    public getAll$ = createEffect(() => this.actions$.pipe(
        ofType(VerificationsActions.VERIFICATIONS_ACTION_GET_ALL),
        exhaustMap(() => this.service.getVerificationsList().pipe(
            map((result: VerificationListOutput[]) => VerificationsActions.verificationsGetAllSuccess({ payload: result })),
            catchError(() => of(VerificationsActions.verificationsUpdateRequestEvent({
                value: {
                    action: VerificationsActions.VERIFICATIONS_ACTION_GET_ALL,
                    result: 'fail'
                } })))
        )
        ),
    ));

    public getById$ = createEffect(() => this.actions$.pipe(
        ofType(VerificationsActions.VERIFICATIONS_ACTION_GET_BY_ID),
        exhaustMap(({id}: {id: string}) => this.service.getVerificationByPublicId(id).pipe(
            map((result: VerificationListOutput) => VerificationsActions.verificationsGetByIdSuccess({ payload: result })),
            catchError(() => of(VerificationsActions.verificationsUpdateRequestEvent({
                value: {
                    action: VerificationsActions.VERIFICATIONS_ACTION_SELECT,
                    result: 'fail'
                } })))
        ))
    ));

    constructor(private actions$: Actions, private service: VerificationService) { }
}
