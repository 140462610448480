import { Component, ViewChild, Injector } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { WizzardRequestsComponent } from '../wizzard-requests/wizzard-requests.component';
import { WizzardOrganizationsComponent } from '../wizzard-organizations/wizzard-organizations.component';
import { AppComponentBase } from '@shared/common/app-component-base';
import { InvoiceService } from '@shared/services/invoice.service';
import { InvoiceOutput } from '@shared/models/InvoiceElements/invoice/invoiceOutput';
import { WizardComponent } from 'angular-archwizard';
import { Router } from '@angular/router';

@Component({
    selector: 'app-wizzard-intro',
    templateUrl: './wizzard-intro.component.html',
    animations: [appModuleAnimation()]
})
export class WizzardIntroComponent extends AppComponentBase {
    @ViewChild('organizations') organizations: WizzardOrganizationsComponent;
    @ViewChild('requests') requests: WizzardRequestsComponent;
    @ViewChild(WizardComponent) public wizard: WizardComponent;

    numberOfOrganizationSelected: number;
    numberOfRequestSelected: number;
    invoiceList: InvoiceOutput[] = [];
    invoicesToSend: string[] = [];

    constructor(private injector: Injector, private service: InvoiceService, private _router: Router) {
        super(injector);
    }

    requestsPublicId: string[] = [];
    invoiceDate = '';

    getRequestsByOrganization() {
        this.organizations.getSelectedOrganizations();
        this.requests.selectedOrganizations = this.organizations.organizationOutputList;
        this.requests.getRequestListForInvoices();
    }

    getSelectedRequests() {
        this.requests.getSelectedRequests().forEach((element) => {
            this.requestsPublicId.push(element.publicId);
        });
        this.wizard.goToNextStep();
    }

    returnToInvoiceList() {
        this._router.navigate(['/invoice-list']);
    }

    returnToInvoiceStatus() {
        this._router.navigate(['/status-invoice']);
    }

    onOrganizationsSelected(value: number) {
        this.numberOfOrganizationSelected = value;
    }

    onRequestsSelected(value: number) {
        this.numberOfRequestSelected = value;
    }

    onInvoiceDateSelected(value: string) {
        this.invoiceDate = value;
    }

    onInvoiceSelected(value: string[]) {
        this.invoicesToSend = value;
    }

    async generateInvoicesFromRequestList() {
        try {
            await this.service.asyncCreateInvoiceCalculationRequest(this.requestsPublicId, this.invoiceDate);
            this.eventService.trigger('app.invoices.generateInvoices');
            this.wizard.goToNextStep();
        } catch (error) {
            this.notify.error('server error');
        }
    }

    returnToRequestList() {
        this._router.navigate(['/invoice-list']);
    }
}
