<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid">

    <sub-header [title]="'Candidate' | localize" [description]="'CandidateUpdate' | localize">
      <div role="actions">
        <button (click)="cancelUpdate()" class="btn btn-secondary">
          <i class="fas fa-times"></i>
          {{'Cancel' | localize}}</button>
        <button class="btn btn-primary" (click)="save()" [disabled]="isCandidatUpdated" data-test-id="candidat-update-save-btn">
          <i class="la la-floppy-o"></i>
          {{"Save" | localize}}
        </button>
      </div>
    </sub-header>
    <div [class]="containerClass">
      <div class="row" class="breadCrumbContainer">
        <div class="col pl-0 pr-0">
            <p-breadcrumb [model]="items"></p-breadcrumb>
        </div>
    </div>
      <form [formGroup]="updateForm" autocomplete="off">
        <div class="card card-custom mb-10">
          <div class="card-body">
            <div class="form-group">
              <label for="organization">{{"Organization" | localize}} <span class="asterix">*</span></label>
              <input id="organization" formControlName="organization" type="text" name="organization"
                class="form-control" maxlength="256">
            </div>
          </div>
        </div>
        <div class="card card-custom mb-10">
          <div class="card-body">
            <div class="form-group">
              <label for="status">{{"Status" | localize}} <span class="asterix">*</span></label>
              <select id="status" formControlName="status" type="text" name="status" class="form-control"
                maxlength="256">
                <option value="0">{{"Actif" | localize}}</option>
                <option value="1">{{"Inactif" | localize}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="card card-custom mb-10">
          <div class="card-body">
            <div class="candidatDetails">
              <h5>{{"CandidatDetails" | localize}}</h5>
              <div class="row">
                <div class="form-group col-md-3">
                  <label for="lastName">{{"LastName" | localize}} <span class="asterix">*</span></label>
                  <input id="lastName" formControlName="lastName" type="text" name="lastName" class="form-control"
                    maxlength="256" appTextTransform="upper" data-test-id="candidat-update-lastName">
                  <validation-messages [formCtrl]="updateForm.get('lastName')"></validation-messages>
                </div>
                <div class="form-group col-md-3">
                  <label for="firstName">{{"FirstName" | localize}} <span class="asterix">*</span></label>
                  <input id="firstName" appTextTransform="title"  formControlName="firstName" type="text" name="firstName" class="form-control"
                    maxlength="256">
                  <validation-messages [formCtrl]="updateForm.get('firstName')"></validation-messages>
                </div>
                <div class="form-group col-md-3">
                  <label for="birthdate">{{"Birthdate" | localize}} {{'FormatDate' | localize}}<span class="asterix">
                      *</span></label>
                  <input id="birthdate" formControlName="birthdate" type="text" name="birthdate" class="form-control"
                    bsDatepicker [maxDate]="maxDate"
                    [bsConfig]="{ dateInputFormat: 'YYYY/MM/DD', containerClass: 'theme-red',  adaptivePosition: true }">
                  <validation-messages [formCtrl]="updateForm.get('birthdate')"></validation-messages>
                </div>
                <div class="form-group col-md-3">
                  <label for="sex">{{"Sex" | localize}} <span class="asterix">*</span></label>
                  <select id="sex" formControlName="sex" type="sex" name="sex" class="form-control">
                    <option value='0'>{{"Male" | localize}}</option>
                    <option value='1'>{{"Female" | localize}}</option>
                    <option value='2'>{{"Other" | localize}}</option>
                  </select>
                  <validation-messages [formCtrl]="updateForm.get('sex')"></validation-messages>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-3">
                  <label for="otherNames">{{"OtherNames" | localize}}</label>
                  <input id="otherNames" formControlName="otherNames" type="text" name="otherNames" class="form-control"
                    maxlength="256">
                </div>
                <div class="form-group col-md-3">
                  <label for="maidenName">{{"MaidenName" | localize}}</label>
                  <input id="maidenName" formControlName="maidenName" type="text" name="maidenName" class="form-control"
                    maxlength="256">
                </div>
                <div class="form-group col-md-3">
                  <label for="birthPlace">{{"BirthPlace" | localize}}</label>
                  <input id="birthPlace" formControlName="birthPlace" type="text" name="birthPlace" class="form-control"
                    maxlength="256" value="text">
                </div>
                <div class="form-group col-md-3">
                  <label for="language">{{"Language" | localize}} <span class="asterix">*</span></label>
                  <select id="language" formControlName="language" type="language" name="language" class="form-control">
                    <option value='0'>{{"French" | localize}}</option>
                    <option value='1'>{{"English" | localize}}</option>
                  </select>
                  <validation-messages [formCtrl]="updateForm.get('sex')"></validation-messages>
                </div>
              </div>
              <div class="form-group">
                <label for="email">{{"Email" | localize}}</label>
                <input id="email" formControlName="email" type="text" name="email" class="form-control" maxlength="256">
                <validation-messages [formCtrl]="updateForm.get('email')"></validation-messages>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="phoneNumber">{{"PhoneNumber" | localize }}</label>
                  <input id="phoneNumber" formControlName="phoneNumber" type="text" name="phoneNumber"
                    Class="form-control" maxlength="256" placeholder="+1(999) 999-9999" mask="(000) 000-0000"
                    prefix="+ 1 " [dropSpecialCharacters]="false"
                    [specialCharacters]="[ '+', '1', '(' , ')' , ' ', '-' ]" [showMaskTyped]="true" />
                  <validation-messages [formCtrl]="updateForm.get('phoneNumber')">
                  </validation-messages>
                </div>
                <div class="form-group col-md-6">
                  <label for="phoneExtension">{{"PhoneNumberExtension" | localize}}</label>
                  <input id="phoneExtension" formControlName="phoneExtension" type="number" name="phoneExtension"
                    class="form-control" maxlength="256" min="0">
                  <validation-messages [formCtrl]="updateForm.get('phoneExtension')"></validation-messages>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="socialInsuranceNumber">{{"SocialInsuranceNumber" | localize}}</label>
                  <input id="socialInsuranceNumber" formControlName="socialInsuranceNumber" type="text"
                    name="socialInsuranceNumber" class="form-control" maxlength="250">
                  <validation-messages [formCtrl]="updateForm.get('socialInsuranceNumber')">
                  </validation-messages>
                </div>
                <div class="form-group col-md-6">
                  <label for="driverLicenceNumber">{{"DriverLicenceNumber" | localize}}</label>
                  <input id="driverLicenceNumber" formControlName="driverLicenceNumber" type="text"
                    name="driverLicenceNumber" class="form-control" maxlength="250">
                  <validation-messages [formCtrl]="updateForm.get('driverLicenceNumber')">
                  </validation-messages>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>