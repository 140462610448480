import { Component, OnInit, Input, EventEmitter, Injector } from '@angular/core';
import { FilesService } from '@shared/services/files.service';
import { FilesOutput } from '@shared/models/files/filesOutput';
import { GridApi, ColDef } from '@ag-grid-enterprise/all-modules';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'app-files-selection-modal',
    templateUrl: './files-selection-modal.component.html',
    styleUrls: ['./files-selection-modal.component.scss'],
})
export class FilesSelectionModalComponent
    extends AppComponentBase
    implements OnInit {
    @Input() public onSave: EventEmitter<FilesOutput[]> = new EventEmitter();
    @Input() public onClose: EventEmitter<void> = new EventEmitter();

    public filterText: string;
    public files: FilesOutput[];
    paginationPageSize = 20;

    private gridApi: GridApi;
    public columnDefs: ColDef[] = [
        { headerName: 'Name', field: 'filesName', checkboxSelection: true },
    ];
    public groupColumnDef: ColDef = {
        headerName: 'Name',
        field: 'filesName',
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: { checkbox: true },
    };

    public rowSelection = 'multiple';

    constructor(private injector: Injector, private fileService: FilesService) {
        super(injector);
    }

    async ngOnInit() {
        this.files = await this.fileService
            .getFiles(true)
            .toPromise()
            .then((data) => data.result);
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    save() {
        this.onSave.emit(this.gridApi.getSelectedRows());
    }

    close() {
        this.onClose.emit();
    }

    search() {
        this.gridApi.setQuickFilter(this.filterText);
    }

    refresh() {
        this.gridApi.setQuickFilter('');
    }
}
