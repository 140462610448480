import { Component, OnInit, ViewChild, Injector, Output, EventEmitter, HostListener } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AgGridAngular } from '@ag-grid-community/angular';
import { organizationOutput } from '@shared/models/organization/organizationOutput';
import { OrganizationService } from '@shared/services/organization.service';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { RowSelectedEvent } from '@ag-grid-enterprise/all-modules';
import { Extensions } from '@shared/utils/extensions';

@Component({
    selector: 'wizzard-organizations',
    templateUrl: './wizzard-organizations.component.html'
})
export class WizzardOrganizationsComponent extends AppComponentBase implements OnInit {
    @ViewChild('agGrid') agGrid: AgGridAngular;
    @Output() organizationsSelected = new EventEmitter<number>();

    private numberOfOrganizationSelected = 0;

    private gridApi;
    public defaultColDef;
    public columnDefs;
    paginationNumberFormatter;
    paginationPageSize = 20;

    filterText = '';
    rowData: organizationOutput[] = [];
    selectedOrganization: organizationOutput;
    rowSelection: string;
    rowMultiSelectWithClick: boolean;
    organizationOutputList: organizationOutput[];

    constructor(private injector: Injector, private service: OrganizationService, private _appLocalizationService: AppLocalizationService) {
        super(injector);

        this.rowSelection = 'multiple';
        this.rowMultiSelectWithClick = true;

        this.columnDefs = [
            {
                headerName: this._appLocalizationService.l(''),
                checkboxSelection: true,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true
            },
            {
                headerName: this._appLocalizationService.l('OrganizationName'),
                width: 230,
                field: 'organizationName',
                getQuickFilterText: Extensions.replaceSpecialCharactersParams.bind(this),
                comparator: Extensions.customComparator
            },
            {
                headerName: this._appLocalizationService.l('ClientId'),
                width: 130,
                field: 'clientId'
            },
            {
                headerName: this._appLocalizationService.l('FirstContact'),
                field: 'firstContact',
                getQuickFilterText: Extensions.replaceSpecialCharactersParams.bind(this)
            },
            {
                headerName: this._appLocalizationService.l('FirstContactEmail'),
                width: 250,
                field: 'firstContactEmail'
            }
        ];

        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };
    }

    ngOnInit() {
        this.service
            .getOrganizationsList()
            .toPromise()
            .then((value) => {
                this.rowData = value.result;
            });

        this.defaultColDef = { resizable: true, sortable: true, filter: true };
        this.organizationsSelected.emit(this.numberOfOrganizationSelected);
    }

    onPageSizeChanged(e) {
        this.gridApi.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    onGridReady(params) {
        this.gridApi = params.api;
        if (screen.availWidth > 414 && this.gridApi.width > 0) {
            this.gridApi.sizeColumnsToFit();
        }
    }

    refresh() {
        this.filterText = '';
        this.agGrid.api.setQuickFilter('');
    }

    search() {
        const filterWithoutAccents = Extensions.replaceAccentedCharacters(this.filterText);
        this.agGrid.api.setQuickFilter(filterWithoutAccents);
    }

    onOrganizationSelected(event: RowSelectedEvent) {
        if (event.node.isSelected()) {
            this.numberOfOrganizationSelected++;
        } else {
            this.numberOfOrganizationSelected--;
        }

        this.organizationsSelected.emit(this.numberOfOrganizationSelected);
    }

    getSelectedOrganizations() {
        this.organizationOutputList = [];

        this.gridApi.forEachNode((rowNode) => {
            if (rowNode.isSelected() === true) {
                this.organizationOutputList.push({
                    id: rowNode.data.id.result,
                    usersId: rowNode.data.usersId,
                    publicId: rowNode.data.publicId,
                    clientId: rowNode.data.clientId,
                    organizationName: rowNode.data.organizationName,
                    purchaseOrder: rowNode.data.purchaseOrder,
                    firstContact: rowNode.data.firstContact,
                    firstContactEmail: rowNode.data.firstContactEmail,
                    phoneNumber: rowNode.data.phoneNumber,
                    phoneExtension: rowNode.data.phoneExtension,
                    language: rowNode.data.language,
                    notes: rowNode.data.notes,
                    status: rowNode.data.status,
                    result: rowNode.data.result,
                    address: rowNode.data.address,
                    creationTime: rowNode.data.creationTime,
                    lastModificationTime: rowNode.data.lastModificationTime,
                    organizationParent: rowNode.data.organizationParent,
                    organizationParentId: rowNode.data.organizationParentId,
                    billingInfo: rowNode.data.billingInfo,
                    idSage: rowNode.data.idSage,
                    idAccountantCAR: rowNode.data.idAccountantCAR,
                    organizationCreditCard: rowNode.data.organizationCreditCard,
                    hasActiveClientUser: rowNode.data.hasActiveClientUser,
                    volumeOfRequestPerYear: rowNode.data.volumeOfRequestPerYear,
                    preAuthorizedPayment: rowNode.data.preAuthorizedPayment,
                    lineOfBusiness: rowNode.data.lineOfBusiness,
                    provenance: null,
                    firstContactJob: null
                });
            }
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.gridApi) {
            if (screen.availWidth > 414 && this.gridApi.width > 0) {
                this.gridApi.sizeColumnsToFit();
            }
        }
    }
}
