import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { OnInit, Component, Injector } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OrganizationVerificationOutput } from '@shared/models/organizationVerificationCost/orgVerificationOutput';
import { VerificationService } from '@shared/services/verification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { OrganizationVerificationInput } from '@shared/models/organizationVerificationCost/orgVerificationInput';
import { OrganizationVerificationCostService } from '@shared/services/organizationVerificationCost.service';
import { ToastrService } from 'ngx-toastr';
import { VerificationOutput } from '@shared/models/verification/verificationOutput';
import { OrganizationService } from '@shared/services/organization.service';
import { organizationOutput } from '@shared/models/organization/organizationOutput';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-organization-verification-update',
    templateUrl: './organization-verification-update.component.html',
    styleUrls: ['./organization-verification-update.component.scss'],
    animations: [appModuleAnimation()],
})
export class OrganizationVerificationUpdateComponent extends AppComponentBase implements OnInit {
    organizationPublicId: string;
    organization: organizationOutput;
    verificationPublicId: string;
    verification: VerificationOutput;
    organizationVerification: OrganizationVerificationOutput;
    fixBasePriceCheck = false;
    isOrganizationVerifUpdated = false;
    items: MenuItem[];

    constructor(
        injector: Injector,
        private service: OrganizationVerificationCostService,
        private organizationService: OrganizationService,
        private verificationService: VerificationService,
        private toastr: ToastrService,
        private _appLocalizationService: AppLocalizationService,
        private _router: Router,
        private _route: ActivatedRoute
    ) {
        super(injector);
    }

    updateVerificationForm = new UntypedFormGroup({
        code: new UntypedFormControl({ value: '', disabled: true }),
        type: new UntypedFormControl({ value: '', disabled: true }),
        basePrice: new UntypedFormControl({ value: '', disabled: true }),
        adjustment: new UntypedFormControl('', Validators.required),
        total: new UntypedFormControl({ value: '', disabled: true }),
        fixBasePrice: new UntypedFormControl(''),
    });

    async ngOnInit() {
        if (this._route.snapshot.paramMap.get('organizationId') !== '' && this._route.snapshot.paramMap.get('verificationId') !== '') {
            this.organizationPublicId = this._route.snapshot.paramMap.get('organizationId');
            this.verificationPublicId = this._route.snapshot.paramMap.get('verificationId');

            this.items = [
                {
                    label: this._appLocalizationService.l('Organizations'),
                    routerLink: ['/organizations'],
                },
                {
                    label: this._appLocalizationService.l('OrganizationDetails'),
                    routerLink: [`/organizations-details/${this.organizationPublicId}`],
                },
                { label: this._appLocalizationService.l('VerificationUpdate') },
            ];

            await this.verificationService
                .getVerificationByPublicId(this.verificationPublicId)
                .toPromise()
                .then((response) => {
                    this.verification = response.result;
                    this.updateVerificationForm.patchValue(this.verification);
                });

            await this.organizationService
                .getOrganizationsByPublicId(this.organizationPublicId)
                .toPromise()
                .then((response) => {
                    this.organization = response.result;
                });

            await this.service
                .getVerificationOrganizationCostByPublicId(this.organizationPublicId, this.verificationPublicId)
                .toPromise()
                .then((response) => {
                    this.organizationVerification = response.result;
                    this.updateVerificationForm.patchValue(this.organizationVerification);

                    this.updateVerificationForm.controls['basePrice'].setValue(this.organizationVerification.basePrice);
                    this.fixBasePriceCheck = this.organizationVerification.fixBasePrice;

                    this.calculateTotal();
                });
        }
    }

    calculateTotal() {
        const basePrice = this.updateVerificationForm.controls['basePrice'].value;
        const adjustment = this.updateVerificationForm.controls['adjustment'].value;

        const total = basePrice + adjustment;

        this.updateVerificationForm.controls['total'].setValue(Number(total).toFixed(2) + ' $');
    }

    isFormInvalid(): boolean {
        return this.updateVerificationForm.invalid;
    }

    markAllControlsAsTouch() {
        this.updateVerificationForm.controls['adjustment'].markAsTouched();
    }

    showForNotValidMessage() {
        const title = this._appLocalizationService.l('VerifyAllControlsTitle');
        const message = this._appLocalizationService.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }

    showSuccess(title: string, successMessage: string) {
        title = this._appLocalizationService.l(title);
        successMessage = this._appLocalizationService.l(successMessage);
        this.toastr.success(successMessage, title);
    }

    showErrorMessage(title: string, errorMessage: string) {
        title = this._appLocalizationService.l(title);
        errorMessage = this._appLocalizationService.l(errorMessage);
        this.toastr.error(errorMessage, title);
    }

    returnToList() {
        this._router.navigate(['/organizations-details', this.organizationPublicId]);
    }

    onUpdate() {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }

        const organizationVerification: OrganizationVerificationInput = {
            id: this.organizationVerification.id,
            organizationId: this.organizationVerification.organizationId,
            verificationId: this.organizationVerification.verificationId,
            adjustment: this.updateVerificationForm.controls['adjustment'].value,
            basePrice: this.verification.price,
            fixBasePrice: this.fixBasePriceCheck,
        };
        this.isOrganizationVerifUpdated = true;

        this.service
            .updateOrganizationVerification(organizationVerification)
            .toPromise()
            .then(
                (response) => {
                    this.showSuccess('VerificationUpdateTitle', 'VerificationUpdated');
                    this.returnToList();
                },
                (error) => {
                    this.showErrorMessage('VerificationUpdateIssue', 'VerificationUpdateTryAgainLater');
                }
            )
            .finally(() => {
                this.isOrganizationVerifUpdated = false;
            });
    }

    onDelete() {
        const organizationVerification: OrganizationVerificationInput = {
            id: this.organizationVerification.id,
            fixBasePrice: this.organizationVerification.fixBasePrice,
            organizationId: this.organizationVerification.organizationId,
            verificationId: this.organizationVerification.verificationId,
            adjustment: this.organizationVerification.adjustment,
            basePrice: this.verification.price,
        };

        this.message.confirm(this.l('VerificationDeleteWarningMessage'), this.l('AreYouSure'), (isConfirmed) => {
            if (isConfirmed) {
                try {
                    this.service.deleteOrganizationVerification(organizationVerification).subscribe((response) => {
                        this.showSuccess('VerificationDeleteTitle', 'VerificationDeleted');
                        this.returnToList();
                    });
                } catch (e) {
                    this.showErrorMessage('VerificationDeleteIssue', 'VerificationDeleteTryAgainLater');
                }
            }
        }, {
            confirmButtonText: this.l('Yes'),
            cancelButtonText: this.l('No')        
        });
    }
}
