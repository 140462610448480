<div bsModal #academicRecordAddModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="academicRecordAddModal" aria-hidden="true" [config]="{backdrop: 'static', keyboard: !saving}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <form [formGroup]="formGroup" (ngSubmit)="save()" autocomplete="off" novalidate>
        <div class="card-body">
          <div class="modal-header">
            <h4 class="modal-title">
              <h5>{{"AcademicRecord" | localize}}</h5>
            </h4>
          </div>
          <div class="m-content">
            <div class="form-group">
              <label for="institution">{{"Institution" | localize}} <span class="asterix">*</span></label>
              <input id="institution" formControlName="institution" type="text" name="institution" class="form-control"
                maxlength="256">
              <validation-messages [formCtrl]="formGroup.get('institution')"></validation-messages>
            </div>
            <div class="form-group">
              <label for="location">{{"Location" | localize}} <span class="asterix">*</span></label>
              <input id="location" formControlName="location" type="text" name="location" class="form-control"
                maxlength="256">
              <validation-messages [formCtrl]="formGroup.get('location')"></validation-messages>
            </div>
            <div class="form-group">
              <label for="diploma">{{"Diploma" | localize}} <span class="asterix">*</span></label>
              <input id="diploma" formControlName="diploma" type="text" name="diploma" class="form-control"
                maxlength="256">
              <validation-messages [formCtrl]="formGroup.get('diploma')"></validation-messages>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label for="year">{{"Year" | localize}} <span class="asterix">*</span></label>
                <input id="year" formControlName="year" type="number" name="year" class="form-control" maxlength="256"
                  min="0">
                <validation-messages [formCtrl]="formGroup.get('year')"></validation-messages>
              </div>
              <div class="form-group col-md-6">
                <label for="permanentCode">{{"PermanentCode" | localize}} </label>
                <input id="permanentCode" formControlName="permanentCode" type="text" name="permanentCode"
                  class="form-control" maxlength="256">
                <validation-messages [formCtrl]="formGroup.get('permanentCode')"></validation-messages>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label for="phoneNumber">{{"PhoneNumber" | localize }} <span class="asterix"></span></label>
                <input id="phoneNumber" formControlName="phoneNumber" type="text" name="phoneNumber"
                  Class="form-control" maxlength="256" placeholder="+1(999) 999-9999" mask="(000) 000-0000"
                  prefix="+ 1 " [dropSpecialCharacters]="false" [specialCharacters]="[ '+', '1', '(' , ')' , ' ', '-' ]"
                  [showMaskTyped]="true" />
                <validation-messages [formCtrl]="formGroup.get('phoneNumber')">
                </validation-messages>
              </div>
              <div class="form-group col-md-6">
                <label for="phoneExtension">{{"PhoneNumberExtension" | localize}}</label>
                <input id="phoneExtension" formControlName="phoneExtension" type="number" min="0" name="phoneExtension"
                  class="form-control" maxlength="256">
                <validation-messages [formCtrl]="formGroup.get('phoneExtension')"></validation-messages>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button [disabled]="saving" type="button" class="btn btn-default"
              (click)="close()">{{"Cancel" | localize}}</button>
            <button type="submit" class="btn btn-primary" [disabled]="!formGroup.valid" [buttonBusy]="saving"
              [busyText]="l('SavingWithThreeDot')"><i class="fa fa-save"></i>
              <span>{{"Save" | localize}}</span></button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>