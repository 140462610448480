import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment as env } from 'environments/environment';
import { Observable } from 'rxjs';
import { FAQDescriptionInput } from '@shared/models/faq/faqDescriptionInput';
import { FAQCategoryInput } from '@shared/models/faq/faqCategoryInput';
import { FAQCategoryOutput } from '@shared/models/faq/faqCategoryOutput';

@Injectable()
export class FaqService {
    private http: HttpClient;
    private baseUrl: string;
    private baseRoute: string = '/api/faq';

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = env.api;
    }

    private getUrl(url: string): string {
        let url_ = this.baseUrl + url;
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    }

    getFAQDashboard(lang?: number): Observable<any> {
        const apiRoute = this.baseRoute + '/dashboard';
        const routeWithLang = lang == null ? apiRoute : apiRoute+'?languageId='+lang.toString(); 
        const url_ = this.getUrl(routeWithLang);
        return this.http.get<any>(url_);
    }
    
    getAllFAQDescriptions(lang?: number): Observable<any> {
        const route = lang == null ? this.baseRoute : this.baseRoute+'?languageId='+lang.toString(); 
        const url_ = this.getUrl(route);
        return this.http.get<any>(url_);
    }

    createFAQDescription(descriptionInput: FAQDescriptionInput): Observable<any> {
        const url_ = this.getUrl(this.baseRoute);
        return this.http.post<any[]>(url_, descriptionInput);
    }

    updateFAQDescription(descriptionInput: FAQDescriptionInput): Observable<any> {
        const url_ = this.getUrl(this.baseRoute);
        return this.http.put<any>(url_, descriptionInput);
    }

    deleteFAQDescription(guid: string): Observable<any> {
        const url_ = this.getUrl(this.baseRoute + `/${guid}`);
        return this.http.delete<any>(url_);
    }

    createFAQCategory(categoryInput: FAQCategoryInput): Observable<any> {
        const url_ = this.getUrl(this.baseRoute + "/category");
        return this.http.post<any[]>(url_, categoryInput);
    }

    updateFAQCategory(categoryInput: FAQCategoryInput): Observable<any> {
        const url_ = this.getUrl(this.baseRoute + "/category");
        return this.http.put<any>(url_, categoryInput);
    }

    deleteFAQCategory(guid: string): Observable<any> {
        const url_ = this.getUrl(this.baseRoute + `/category/${guid}`);
        return this.http.delete<any>(url_);
    }

    // This function takes all the Categories and build a dictionnary of <languageId (string): list of FAQCategoryOutput>
    // This makes it easier to switch from language to language without changing the entire page
    // It also sets the current active list to be the one in the prefered language of the user 
    splitFaqListPerLanguage(list: FAQCategoryOutput[]): Map<number, FAQCategoryOutput[]> {
        const faqDictionary: Map<number, FAQCategoryOutput[]> = new Map();
        const languageIds = list.reduce((unique, item) => {
            return unique.includes(item.languageId) ? unique : [...unique, item.languageId];
        }, []);
        languageIds.forEach(languageId => {
            const categoriesInLanguage = list.filter((category: FAQCategoryOutput) => category.languageId === languageId);
            faqDictionary.set(languageId, categoriesInLanguage);
        });
        return faqDictionary;
    }
}
