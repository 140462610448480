export class InvoiceHeader {
    organizationName: string;
    civicNo: string;
    street: string;
    app: string;
    city: string;
    state: string;
    postalCode: string;
    contactName: string;
    invoiceEmail: string;
    invoiceNumber: string;
    invoiceDate: Date;
    invoiceDateString: string;
    clientName: string;
    purchaseOrder: string;
    monthName: string;
    clientId: string;
}
