import { Directive} from '@angular/core';
import { AgGridAngular } from '@ag-grid-community/angular';
import { Status } from '@shared/models/shared/status';


@Directive({
    selector: '[StyleRow]'
})

export class GridStyleRowInactifDirective {
    constructor(private agGrid: AgGridAngular) {
        const inactifStatus = Status;
        this.agGrid.gridOptions.getRowStyle = function(params) {
            if (params.data.status === inactifStatus.Inactif) {
                return { background: '#f3f3f3' };
            }
        };
    }
}
