import { Injector, Component, ViewEncapsulation, Inject, Input, OnInit } from '@angular/core';
import { environment as env } from 'environments/environment';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DOCUMENT } from '@angular/common';

@Component({
    templateUrl: './default-logo.component.html',
    selector: 'default-logo',
    encapsulation: ViewEncapsulation.None,
})
export class DefaultLogoComponent extends AppComponentBase implements OnInit {
    @Input() customHrefClass = '';
    @Input() skin = null;
    defaultLogo = '';
    remoteServiceBaseUrl: string = env.api;

    constructor(injector: Injector, @Inject(DOCUMENT) private document: Document) {
        super(injector);
    }

    ngOnInit(): void {
        this.setLogoUrl();
    }

    onResize() {
        this.setLogoUrl();
    }

    setLogoUrl(): void {
        this.defaultLogo = '/assets/common/images/app-logo-on-dark.svg';
    }
}
