<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid">

        <sub-header [title]="'Organizations' | localize" [description]="'OrganizationUpdate' | localize">
            <div role="actions">
                <button (click)="returnToOrganizationDetails()" class="btn btn-secondary">
                    <i class="fas fa-times"></i>
                    {{'Cancel' | localize}}</button>
                <button class="btn btn-danger organizationsUpdate-btn" (click)="delete()"
                    data-test-id="org-details-delete-btn"
                    *ngIf="!waitingForApprobation && isGranted('Pages.Management.Organizations.Delete')"><i
                        class="fas fa-trash-alt"></i>
                    {{"Delete" | localize}}</button>
                <button class="btn btn-primary" (click)="update()" [disabled]="isOrganizationUpdated"
                    data-test-id="org-update-save-btn"><i class="la la-floppy-o"></i>
                    {{"Save" | localize}}</button>
            </div>
        </sub-header>

        <div [class]="containerClass">

            <div class="row" class="breadCrumbContainer">
                <div class="col pl-0 pr-0">
                    <p-breadcrumb [model]="items"></p-breadcrumb>
                </div>
            </div>

            <form [formGroup]="formGroup" autocomplete="off">
                <tabset>
                    <tab heading="{{'OrganizationDetails' | localize}}">
                        <ng-template tabHeading>
                            <span class="invalidTab" *ngIf="isFirstTabInvalid()">*</span>
                        </ng-template>
                        <div class="card card-custom">
                            <div class="card-body">
                                <div class="row">
                                    <div class="form-group col-md-12">
                                        <label for="logo" class="mb-5">{{"OrganizationLogo" | localize}}</label><br />
                                        <div class="logo-container rounded img-thumbnail p-2" *ngIf="organizationLogo != '' && !imageChangedEvent">
                                            <img id="organizationLogo" class="symbol-label" [src]="organizationLogo" alt="logo"/>
                                            <button (click)="deleteLogo()" class="delete-logo-button">&#x2716;</button>
                                        </div>
                                        <div class="logo-container" *ngIf="imageChangedEvent">
                                            <image-cropper [containWithinAspectRatio]="false"
                                                [maintainAspectRatio]="false" [imageChangedEvent]="imageChangedEvent"
                                                format="png" (imageCropped)="imageCroppedFile($event)"></image-cropper>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-md-12">
                                            <input type="file" accept=".jpeg,.jpg,.png"
                                                (change)="fileChangeEvent($event)" formControlName="organizationLogo" />
                                            <span class="help-block m-b-none">{{"ProfilePicture_Change_Info" |
                                                localize:maxPictureBytesUserFriendlyValue}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label for="organizationName">{{"OrganizationName" | localize}} <span
                                                class="asterix">*</span></label>
                                        <input id="organizationName" formControlName="organizationName" type="text"
                                            name="organizationName" class="form-control" maxlength="256"
                                            data-test-id="org-update-name">
                                        <validation-messages [formCtrl]="formGroup.get('organizationName')">
                                        </validation-messages>
                                    </div>
                                    <div class="form-group col-md-6 edition-list">
                                        <label for="lineOfBusiness">{{"BusinessActivity" | localize}} <span
                                                class="asterix">*</span></label>
                                        <select name="lineOfBusiness" class="form-control" id="lineOfBusiness"
                                            formControlName="lineOfBusiness">
                                            <option *ngFor="
                                                    let lineOfBusiness of lineOfBusinessList
                                                " [ngValue]="lineOfBusiness.value">
                                                {{ lineOfBusiness.key }}
                                            </option>
                                        </select>
                                        <validation-messages
                                            [formCtrl]="formGroup.get('lineOfBusiness')"></validation-messages>
                                    </div>
                                    <div class="form-group col-md-6 edition-list"
                                        *ngIf="isGranted('Pages.Management.Organizations.CanSeePrivateInformation')">
                                        <label>{{"HearAboutGardiumLabel" | localize}}</label>
                                        <select class="form-select" formControlName="howYouHear">
                                            <option *ngFor="let answer of provenanceList" [value]="answer.key">
                                                {{ answer.value }}
                                            </option>
                                        </select>
                                        <div *ngIf="isAnswerOther">
                                            <label>{{"OtherAnswer" | localize}}</label>
                                            <input type="text" id="howYouHearInput" class="form-control"
                                                formControlName="otherAnswer" />
                                            <validation-messages [formCtrl]="formGroup.get('otherAnswer')">
                                            </validation-messages>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-4" *ngIf="!(waitingForApprobation || declined)">
                                        <div class="form-group">
                                            <label for="status">{{"Status" | localize}} <span
                                                    class="asterix">*</span></label>
                                            <select id="status" formControlName="status" type="text" name="status"
                                                class="form-control" maxlength="256">
                                                <option value="0">{{"Actif" | localize}}</option>
                                                <option value="1">{{"Inactif" | localize}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4" *ngIf="waitingForApprobation || declined">
                                        <div class="form-group">
                                            <label for="status">{{"Status" | localize}}</label>
                                            <select id="status" formControlName="status" type="text" name="status"
                                                class="form-control" maxlength="256">
                                                <option value="0">{{"Actif" | localize}}</option>
                                                <option value="1">{{"Inactif" | localize}}</option>
                                                <option value="2">{{"WaitingForApprobation" | localize}}</option>
                                                <option value="3">{{"Declined" | localize}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="clientId">{{"ClientId" | localize}} <span
                                                class="asterix">*</span></label>
                                        <input id="clientId" formControlName="clientId" type="text" name="clientId"
                                            class="form-control" maxlength="256">
                                        <validation-messages [formCtrl]="formGroup.get('clientId')">
                                        </validation-messages>
                                    </div>

                                    <div class="form-group col-md-4">
                                        <label for="volumeOfRequestPerYear">{{"VolumeOfRequestPerYear" |
                                            localize}}</label>
                                        <select id="volumeOfRequestPerYear" formControlName="volumeOfRequestPerYear"
                                            name="volumeOfRequestPerYear" class="form-control"
                                            data-test-id="volume-of-request-per-year-select">
                                            <option
                                                *ngFor="let item of organizationRequestVolumeRanges | enumToArray | slice:0:-1"
                                                [value]='item.index'>{{item.name}}</option>
                                        </select>
                                        <validation-messages [formCtrl]="formGroup.get('volumeOfRequestPerYear')">
                                        </validation-messages>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-md-4">
                                        <label for="purchaseOrder">{{"PurchaseOrder" | localize}}</label>
                                        <input id="purchaseOrder" formControlName="purchaseOrder" type="text"
                                            name="purchaseOrder" class="form-control" maxlength="256">
                                        <validation-messages [formCtrl]="formGroup.get('purchaseOrder')">
                                        </validation-messages>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="idSage"
                                            *ngIf="isGranted('Pages.Management.Organizations.CanSeePrivateInformation')">{{"IdSage"
                                            | localize}}</label>
                                        <input id="idSage" formControlName="idSage" type="text" name="idSage"
                                            class="form-control" maxlength="256"
                                            *ngIf="isGranted('Pages.Management.Organizations.CanSeePrivateInformation')">
                                        <validation-messages [formCtrl]="formGroup.get('idSage')">
                                        </validation-messages>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="idAccountantCAR"
                                            *ngIf="isGranted('Pages.Management.Organizations.CanSeePrivateInformation')">{{"IdAccountantCAR"
                                            | localize}}</label>
                                        <input id="idAccountantCAR" formControlName="idAccountantCAR" type="text"
                                            name="idAccountantCAR" class="form-control" maxlength="256"
                                            *ngIf="isGranted('Pages.Management.Organizations.CanSeePrivateInformation')">
                                        <validation-messages [formCtrl]="formGroup.get('idAccountantCAR')">
                                        </validation-messages>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label for="firstContact">{{"FirstContact" | localize}} <span
                                                class="asterix">*</span></label>
                                        <input id="firstContact" formControlName="firstContact" type="text"
                                            name="firstContact" class="form-control" maxlength="256" #saveContacts>
                                        <validation-messages [formCtrl]="formGroup.get('firstContact')">
                                        </validation-messages>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="jobTitle">{{"JobTitleLabel" | localize}}</label>
                                        <input type="text" name="jobTitle" class="form-control"
                                            formControlName="jobTitle" />
                                        <validation-messages [formCtrl]="formGroup.get('jobTitle')">
                                        </validation-messages>
                                    </div>
                                </div>
                                <label for="firstContactEmail">{{"FirstContactEmail" | localize}} <span
                                        class="asterix">*</span></label>
                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <input id="firstContactEmail" formControlName="firstContactEmail" type="text"
                                            name="firstContactEmail" class="form-control" maxlength="256" #saveEmails>
                                        <validation-messages [formCtrl]="formGroup.get('firstContactEmail')">
                                        </validation-messages>
                                    </div>
                                    <div class="form-group col-xl-6"
                                        *ngIf="isGranted('Pages.Management.Organizations.BillingInfoAccess')">
                                        <input class="btn btn-secondary form-control" type="button"
                                            style="border-radius: .25rem;"
                                            [disabled]="this.formGroup.controls['firstContactEmail'].invalid || this.formGroup.controls['firstContact'].invalid"
                                            (click)="copyContactInformationToBillingInfo()" id="btnSaveEmail"
                                            value="{{'TooltipOrganization' | localize}}">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label for="phoneNumber">{{"PhoneNumber" | localize }} <span
                                                class="asterix">*</span></label>
                                        <input id="phoneNumber" formControlName="phoneNumber" type="text"
                                            name="phoneNumber" Class="form-control" maxlength="256"
                                            placeholder="+1(999) 999-9999" mask="(000) 000-0000" prefix="+ 1 "
                                            [dropSpecialCharacters]="false"
                                            [specialCharacters]="[ '+', '1', '(' , ')' , ' ', '-' ]"
                                            [showMaskTyped]="true" />
                                        <validation-messages [formCtrl]="formGroup.get('phoneNumber')">
                                        </validation-messages>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="phoneExtension">{{"PhoneNumberExtension" | localize}}</label>
                                        <input id="phoneExtension" formControlName="phoneExtension" type="number"
                                            name="phoneExtension" class="form-control" maxlength="256" min="0">
                                        <validation-messages [formCtrl]="formGroup.get('phoneExtension')">
                                        </validation-messages>
                                    </div>
                                </div>
                                <div class="form-group edition-list">
                                    <label for="language">{{"Language" | localize}} <span
                                            class="asterix">*</span></label>
                                    <select name="language" class="form-control" id="language"
                                        formControlName="language">
                                        <option value="">{{"Select" | localize}}</option>
                                        <option value="english">{{"English" | localize}}</option>
                                        <option value="french">{{"French" | localize}}</option>
                                    </select>
                                    <validation-messages [formCtrl]="formGroup.get('language')">
                                    </validation-messages>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-12"
                                        *ngIf="isGranted('Pages.Management.Organizations.CanSeePrivateInformation')">
                                        <label for="notes">{{"Notes" | localize}}</label>
                                        <textarea class="form-control" rows="5" id="notes" formControlName="notes"
                                            name="notes"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab>
                    <tab heading="{{'AddressTitle' | localize}}"
                        *ngIf="isGranted('Pages.Management.Organizations.AddresseInfoAccess')">
                        <ng-template tabHeading>
                            <span class="invalidTab" *ngIf="isSecondTabInvalid()">*</span>
                        </ng-template>
                        <div class="card card-custom">
                            <div class="card-body">
                                <app-address-fields [formGroup]="addressFormGroup"></app-address-fields>
                                <div class="row">
                                    <div class="form-group col-md-6"
                                        *ngIf="isGranted('Pages.Management.Organizations.BillingInfoAccess')">
                                        <div class="card card-custom pt-0">
                                            <div class="card-body pt-0">

                                                <input class="btn btn-secondary form-control" type="button"
                                                    [disabled]="isSecondTabInvalid()" style="border-radius: .25rem;"
                                                    (click)="copyAddressAsBillingAddress()" id="btnCopyAddress"
                                                    value="{{'CopyAddressAsBillingAddress' | localize}}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab>
                    <tab heading="{{'BillingInfoTitle' | localize}}"
                        *ngIf="isGranted('Pages.Management.Organizations.BillingInfoAccess')">
                        <ng-template tabHeading>
                            <span class="invalidTab" *ngIf="isThirdTabInvalid()">*</span>
                        </ng-template>
                        <div class="card card-custom">
                            <div class="card-body">
                                <div class="row">
                                    <div class="form-group edition-list  col-md-4">
                                        <label for="billingOutputType">{{"BillingOutputType" | localize}} <span
                                                class="asterix">*</span></label>
                                        <select name="billingOutputType" class="form-control" id="billingOutputType"
                                            formControlName="billingOutputType">
                                            <option value="">{{"Select" | localize}}</option>
                                            <option value='0'>{{"Email" | localize}}</option>
                                        </select>
                                        <validation-messages [formCtrl]="formGroup.get('billingOutputType')">
                                        </validation-messages>
                                    </div>
                                    <div class="form-group edition-list  col-md-4">
                                        <label for="billingPeriod">{{"BillingPeriod" | localize}} <span
                                                class="asterix">*</span></label>
                                        <select name="billingPeriod" class="form-control" id="billingPeriod"
                                            formControlName="billingPeriod">
                                            <option value="">{{"Select" | localize}}</option>
                                            <option value='0'>{{"Unit" | localize}}</option>
                                            <option value='1'>{{"Monthly" | localize}}</option>
                                        </select>
                                        <validation-messages [formCtrl]="formGroup.get('billingPeriod')">
                                        </validation-messages>
                                    </div>
                                    <div class="form-group edition-list  col-md-4">
                                        <label for="billingMethod">{{"BillingMethod" | localize}} <span
                                                class="asterix">*
                                            </span><i class="la la-exclamation-circle" data-toggle="tooltip"
                                                title="{{'TooltipBadCreditCard' | localize}}"
                                                id='tooltipInfo'></i></label>
                                        <select name="billingMethod" class="form-control" id="billingMethod"
                                            formControlName="billingMethod"
                                            (change)="billingMethodChange($event.target.value)">
                                            <option value="">{{"Select" | localize}}</option>
                                            <option value='0'>{{"DirectDeposit" | localize}}</option>
                                            <option value='1'>{{"CreditCard" | localize}}</option>
                                            <option value='2'>{{"Check" | localize}}</option>
                                        </select>
                                        <validation-messages [formCtrl]="formGroup.get('billingMethod')">
                                        </validation-messages>
                                    </div>
                                </div>
                                <div class="row" *ngIf="hasCreditCard">
                                    <div class="form-group col-md-12" style="left: 17px;">
                                        <input class="form-check-input" type="checkbox" id="checkCreditCardUpdate"
                                            [checked]="isChecked" (change)='checkValue($event)' [disabled]="isChecked">
                                        {{'CheckCreditCardUpdate'| localize}}
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="creditCardNumber">{{"CreditCardNumber" | localize}} <span
                                                class="asterix">*</span></label>
                                        <div *ngIf="isChecked">
                                            <input id="creditCardNumber" formControlName="creditCardNumber" type="text"
                                                name="creditCardNumber" class="form-control" maxlength="16">
                                            <validation-messages [formCtrl]="formGroup.get('creditCardNumber')">
                                            </validation-messages>
                                        </div>
                                        <div div *ngIf="!isChecked">
                                            <input id="creditCardNumber" formControlName="creditCardNumber" type="text"
                                                name="creditCardNumber" class="form-control" maxlength="16">
                                            <validation-messages [formCtrl]="formGroup.get('creditCardNumber')">
                                            </validation-messages>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="expdate">{{"Expdate" | localize}} <span
                                                class="asterix">*</span></label>
                                        <input id="expdate" formControlName="expdate" type="text" name="expdate"
                                            class="form-control" maxlength="4"
                                            placeholder="{{'PlaceholderExpDate' | localize}}">
                                        <validation-messages [formCtrl]="formGroup.get('expdate')">
                                        </validation-messages>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-4">
                                        <label for="billingContact">{{"BillingContact" | localize}} <span
                                                class="asterix">*</span></label>
                                        <input id="billingContact" formControlName="billingContact" type="text"
                                            name="billingContact" class="form-control" maxlength="256">
                                        <validation-messages [formCtrl]="formGroup.get('billingContact')">
                                        </validation-messages>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="billingEmail">{{"BillingEmail" | localize}} <span class="asterix">*
                                            </span> <i class="fas fa-info-circle" data-toggle="tooltip"
                                                title="{{'TooltipOrganizationInfo' | localize}}"
                                                id='tooltipInfo'></i></label>
                                        <input id="billingEmail" formControlName="billingEmail" type="text"
                                            name="billingEmail" class="form-control" maxlength="256">
                                        <validation-messages [formCtrl]="formGroup.get('billingEmail')">
                                        </validation-messages>
                                    </div>
                                    <div class="form-group col-md-4" *ngIf="hasCreditCard">
                                        <label for="preAuthorizedPayment">{{"PreAuthorizedPayment" |
                                            localize}}<span class="asterix">*</span><i class="la la-exclamation-circle"
                                                data-toggle="tooltip"
                                                title="{{'TooltipPreAuthorizedPayment' | localize}}"
                                                id='tooltipInfo'></i></label>
                                        <select id="preAuthorizedPayment" formControlName="preAuthorizedPayment"
                                            type="text" name="preAuthorizedPayment" class="form-control"
                                            maxlength="256">
                                            <option value="">{{"Select" | localize}}</option>
                                            <option value="0">{{"No" | localize}}</option>
                                            <option value="1">{{"Yes" | localize}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class='formSeparation'>
                                    <h4>{{"BillingAddress" | localize}}</h4>
                                    <app-address-fields [formGroup]="billingAddressFormGroup"></app-address-fields>
                                </div>
                            </div>
                        </div>
                    </tab>
                </tabset>
            </form>
        </div>

    </div>
</div>
