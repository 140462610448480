import { ChangeDetectorRef, ChangeDetectionStrategy, Component, EventEmitter, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { RequestInput } from '@shared/models/request/requestInput';
import { RequestVerificationInput } from '@shared/models/requestVerification/requestVerificationInput';
import { RequestVerificationOutput } from '@shared/models/requestVerification/requestVerificationOutput';
import { country } from '@shared/models/shared/country';
import { VerificationFileDTO } from '@shared/models/verification/verificationFileDTO';
import { SelectedVerification, VerificationOutput } from '@shared/models/verification/verificationOutput';
import { CountryService } from '@shared/services/country.service';
import { RequestService } from '@shared/services/requests.service';
import { verificationsCodes } from '@shared/utils/verificationsEnumAndFunctions';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { BehaviorSubject, Subject } from 'rxjs';
import { WizzardRequestCreatorComponent } from '../wizzard-request/wizzard-request.component';

@Component({
    selector: 'request-wizard-verifications',
    templateUrl: './request-wizard-verifications.component.html',
    styleUrls: [ './request-wizard-verifications.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [appModuleAnimation()],
})
export class RequestWizardVerificationsComponent extends AppComponentBase implements OnInit {
    @Input() public request: RequestInput;
    @Output()
    public requestChange: EventEmitter<RequestInput> = new EventEmitter<RequestInput>();
    @Input() public verifications: VerificationOutput[] = [];
    @Input() isDriverLicenseRequired = false;
    @Input() public verificationsChanged: BehaviorSubject<boolean>;
    @Input() beforeRequestSaved: Subject<null>;

    isDJIselected = false;
    idDJI: number;
    isADDselected = false;
    idADD: number;
    verificationsNotSelected = false;
    selectedVerifications: BehaviorSubject<SelectedVerification[]> = new BehaviorSubject([]);
    deletedVerifications: SelectedVerification[] = [];

    countryList: country[] = [];
    noResultCountry = false;
    selectedCountry: country;

    formGroup = new UntypedFormGroup({
        verifications: new UntypedFormControl('', Validators.required),
        customPriceDji: new UntypedFormControl(''),
        customPriceAdd: new UntypedFormControl(''),
        country: new UntypedFormControl(''),
        customfield: new UntypedFormControl(''),
    });

    constructor(
        private injector: Injector,
        private _router: Router,
        private countryService: CountryService,
        @Inject(WizzardRequestCreatorComponent)
        public requestWizard: WizzardRequestCreatorComponent,
        private requestService: RequestService,
        private _changeDetector: ChangeDetectorRef

    ) {
        super(injector);
    }

    public get canDisplayExpressDescription(): boolean {
        return this.verifications.some((v) => !!v.canBeExpress);
    }

    async ngOnInit() {
        await this.initializeCountryList();
        this.initializeVerifications();

        this.beforeRequestSaved.subscribe(() => {
            this.setRequestVerifications();
        });

        this.verificationsChanged?.subscribe((event) => {
            if (event) {
                this.initializeVerifications();
            }
        });

        this.selectedVerifications.subscribe((selectedVerifications: SelectedVerification[]) => {
            if (!selectedVerifications?.length) {
                this.verifications = [ ...this.verifications ].map((x: VerificationOutput): SelectedVerification => ({
                    ...x,
                    checked: false,
                    isExpress: false
                }));
                return;
            }

            this.verifications = [ ...this.verifications ].map((x: VerificationOutput): SelectedVerification => {
                const index = selectedVerifications.findIndex(v => v.id === x.id);
                if (index < 0) {
                    return x as SelectedVerification;
                }
                return {
                    ...x,
                    checked: selectedVerifications[ index ].checked,
                    isExpress: selectedVerifications[ index ].isExpress
                } as SelectedVerification;
            });

            this._changeDetector.detectChanges();

        });
    }

    initializeVerifications() {
        if (this.requestWizard.isRequestCreated()) {
            this.initializeVerificationsIfRequest();
        }
    }

    async initializeVerificationsIfRequest() {
        const updatedRequestVerifications = [ ... this.request.requestVerifications ];
        this.verifications = [ ...this.verifications ].map((x: VerificationOutput): SelectedVerification => {
            const index = updatedRequestVerifications.findIndex(v => v.verificationId === x.id);
            if (index < 0) {
                return x as SelectedVerification;
            }
            return {
                ...x,
                checked: true,
                isExpress: updatedRequestVerifications[ index ].isExpress
            } as SelectedVerification;
        });
        this.selectedVerifications.next(updatedRequestVerifications.map((rv: RequestVerificationInput) => this.verifications.find((v) => rv.verificationId === v.id) as SelectedVerification));

        if (this.selectedVerifications.value.find((selectedVerif) => selectedVerif.code === verificationsCodes.DJI)) {
            const idDji = this.verifications.find((verif) => verif.code === verificationsCodes.DJI).id;
            const djiVerification = this.request.requestVerifications.find((verif) => verif.verificationId === idDji);

            if (djiVerification.countryId !== 0) {
                this.selectedCountry = this.countryList.find((c) => c.id === djiVerification.countryId);
                this.formGroup.controls['country'].setValue(this.selectedCountry?.countryName);
            }
            if (djiVerification.verificationCustomPrice != null) {
                this.formGroup.controls['customPriceDji'].setValue(djiVerification.verificationCustomPrice);
            }
        }

        if (this.selectedVerifications.value.find((selectedVerif) => selectedVerif.code === verificationsCodes.ADD)) {
            const idAdd = this.verifications.find((verif) => verif.code === verificationsCodes.ADD).id;
            const addVerification = this.request.requestVerifications.find((verif) => verif.verificationId === idAdd);

            if (addVerification.verificationCustomField !== '') {
                this.formGroup.controls['customfield'].setValue(addVerification.verificationCustomField);
            }
            if (addVerification.verificationCustomPrice != null) {
                this.formGroup.controls['customPriceAdd'].setValue(addVerification.verificationCustomPrice);
            }
        }
        this.requestWizard.disabledNavigation = false;
    }

    noVerificationIsSelected(): boolean {
        return this.selectedVerifications.value.length <= 0;
    }

    public changeVerificationExpress = (verification: VerificationOutput): void => {
        const updatedSelectedVerifications = [ ...this.selectedVerifications.value ];
        const index = updatedSelectedVerifications.findIndex((s) => s.id === verification.id);
        if (index < 0) {
            return;
        }
        const udpatedSelectedVerification = {
            ...updatedSelectedVerifications[ index ],
            isExpress: !updatedSelectedVerifications[ index ].isExpress
        };
        updatedSelectedVerifications[ index ] = udpatedSelectedVerification;
        this.selectedVerifications.next( [...updatedSelectedVerifications]);
    };


    setRequestVerifications() {
        const deleteVerif: RequestVerificationInput[] = this.deletedVerifications.map((deletedVerif) => {
            const updateItem = this.request.requestVerifications.find((requestVerif) => requestVerif.verificationId === deletedVerif.id);
            const index = this.request.requestVerifications.indexOf(updateItem);

            //With quantity 0, back end will know that requestVerification should be deleted
            if (updateItem !== undefined && index !== -1) {
                this.request.requestVerifications[index].quantity = 0;
                return this.request.requestVerifications[index];
            }
        });

        this.request.requestVerifications = [...this.selectedVerifications.value].map((selectedVerif) => {
            const requestVerif = this.request.requestVerifications.find((requestVerif) => requestVerif.verificationId === selectedVerif.id);
            const requestVerificationOutput = {
                id: requestVerif ? requestVerif.id : 0,
                verificationId: selectedVerif.id,
                quantity: 1,
                isExpress: selectedVerif.isExpress || false,
                expressPrice: selectedVerif.expressPrice || 0,
                verificationPrice: selectedVerif.price,
                creationDate: new Date(),
                requestId: this.request ? this.request.id : 0,
            } as RequestVerificationOutput;

            if (selectedVerif.code === verificationsCodes.DJI) {
                requestVerificationOutput.countryId = this.selectedCountry?.id;
                requestVerificationOutput.verificationCustomPrice = this.formGroup.controls['customPriceDji'].value;
            }
            if (selectedVerif.code === verificationsCodes.ADD) {
                requestVerificationOutput.verificationCustomPrice = this.formGroup.controls['customPriceAdd'].value;
                requestVerificationOutput.verificationCustomField = this.formGroup.controls['customfield'].value;
            }
            return requestVerificationOutput;
        });

        this.request.requestVerifications.push(...deleteVerif);
    }

    changeCheckbox(verification: SelectedVerification) {
        verification.checked = !verification.checked;
        if (verification.checked) {
            if (verification.code === verificationsCodes.DJI) {
                this.isDJIselected = true;
            }
            if (verification.code === verificationsCodes.ADD) {
                this.isADDselected = true;
            }

            this.addToSelectedVerifications(verification);
        } else {
            if (verification.code === verificationsCodes.DJI) {
                this.isDJIselected = false;
                this.selectedCountry = null;
                this.formGroup.controls['country'].setValue('');
                this.formGroup.controls['customPriceDji'].setValue('');
            }

            if (verification.code === verificationsCodes.ADD) {
                this.isADDselected = false;
                this.formGroup.controls['customPriceAdd'].setValue('');
                this.formGroup.controls['customfield'].setValue('');
            }

            const index: number = this.selectedVerifications.value.findIndex(x => x.id === verification.id);
            if (index !== -1) {
                this.deletedVerifications.push(verification);
                const updatedSelectedVerifications = [...this.selectedVerifications.value];
                updatedSelectedVerifications.splice(index, 1);
                this.selectedVerifications.next(updatedSelectedVerifications);
            }
        }
    }

    addToSelectedVerifications(verification: SelectedVerification) {
        // Add to list of selected verifications
        if (!this.selectedVerifications.value.find((selVerif) => selVerif.id === verification.id)) {
            this.selectedVerifications.next([...this.selectedVerifications.value, verification]);
        }

        // Remove from list of verifications to delete (if present)
        const index: number = this.deletedVerifications.indexOf(verification);
        if (index !== -1) {
            this.deletedVerifications.splice(index, 1);
        }
    }

    async initializeCountryList() {
        await this.countryService
            .getCountryList()
            .toPromise()
            .then((value) => {
                this.countryList = value.result as country[];
            });
    }

    typeaheadOnBlur(event: any): void {
        this.selectedCountry = event.item;
        this.formGroup.controls['country'].setValue(this.selectedCountry.countryName);
    }

    onSelectCountry(event: TypeaheadMatch): void {
        this.selectedCountry = event.item as country;
    }

    typeaheadNoResults(event: boolean): void {
        this.noResultCountry = event;
    }

    goToOrganizationVerificationsList() {
        this._router.navigate(['/organizations-details/' + this.request.organization.publicId]);
    }

    saveFiles() {
        const oldRequestFiles = this.request.files;
        const mandatoryFiles: string[] = [];

        //When user change organization in creation mode a request saved file could be removed
        if (this.request.id !== 0) {
            if (oldRequestFiles.length > 0) {
                this.requestService.deleteAllRequestMandatoryFiles(this.request.id).subscribe((response) => {});
            }
        }

        //We want to filter verification files that are mandatory
        const verificationFiles: VerificationFileDTO[] = [];
        this.verifications.filter((v) => v.checked).forEach((v) => verificationFiles.push(...v.verificationFiles.filter((vf) => vf.files != null)));

        const verificationFilesMandatory = verificationFiles.filter((f) => f.isMandatory);

        this.requestWizard.setMandatoryFilesNotOptional(verificationFilesMandatory);

        this.verifications
            .filter((v) => v.checked)
            .forEach((v) =>
                v.files.forEach((f) => {
                    mandatoryFiles.push(f.publicId);

                    if (this.request.id > 0) {
                        this.requestService.addRequestFileLink(f.publicId, this.request.id, v.id).subscribe((response) => {});
                    }
                })
            );
        this.request.files = mandatoryFiles;
    }

    async save() {
        this.setRequestVerifications();
        this.requestChange.emit(this.request);
        await this.requestWizard.save(false);
        this.saveFiles();
    }
}
