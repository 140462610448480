<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid">
        <sub-header [title]="'Requests' | localize" [description]="'ListRequest' | localize">
            <div role="actions">
                <a href="javascript:;" (click)="createRequest()" class="btn btn-primary" *ngIf="isGranted('Pages.Management.Requests.Create.Wizzard')">
                    <i class="fa fa-plus"></i>
                    {{ 'NewRequest' | localize }}
                </a>
            </div>
        </sub-header>

        <div [class]="containerClass">
            <div class="card card-custom listContainer">
                <div class="card-body">
                    <form class="m-form m-form--label-align-right">
                        <div class="col-xl-12">
                            <div class="form-group m-form__group align-items-center">
                                <div class="input-group">
                                    <input
                                        [(ngModel)]="filterText"
                                        name="filterText"
                                        id="filterText"
                                        autoFocus
                                        class="form-control m-input"
                                        [placeholder]="l('Search')"
                                        type="text"
                                        (keydown.enter)="search()"
                                    />
                                    <span class="input-group-btn">
                                        <button (click)="search()" class="btn btn-primary" type="submit">
                                            <i class="flaticon-search-1" [attr.aria-label]="l('Search')"></i>
                                        </button>
                                    </span>
                                    <span class="input-group-btn" style="padding-left: 5px">
                                        <button class="btn btn-secondary" (click)="refresh()">
                                            <i class="la la-refresh"></i>
                                            {{ 'Refresh' | localize }}
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="requestListAdvancedSearch">
                            <button
                                class="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseRequestAdvancedSearch"
                                aria-expanded="true"
                                aria-controls="collapseRequestAdvancedSearch"
                            >
                                {{ 'AdvancedSearch' | localize }}
                            </button>
                        </h2>
                        <div id="collapseRequestAdvancedSearch" class="accordion-collapse collapse show" aria-labelledby="requestListAdvancedSearch">
                            <div class="accordion-body align-left">
                                <form [formGroup]="advancedSearchFormGroup" autocomplete="off">
                                    <div class="row">
                                        <div class="form-group col-md-4">
                                            <label for="requestStatus">{{ 'Status' | localize }}</label>
                                            <ngx-select-dropdown
                                                [config]="config"
                                                [options]="dropdownOptions"
                                                class="multiselect"
                                                id="requestStatus"
                                                formControlName="requestStatus"
                                                [multiple]="true"
                                                (change)="onSelectChange($event)"
                                            ></ngx-select-dropdown>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <label for="startCreationTimeBetween">{{ 'CreationTimeBetween' | localize }}</label>
                                            <input
                                                id="startCreationTimeBetween"
                                                formControlName="startCreationTimeBetween"
                                                type="text"
                                                name="startCreationTimeBetween"
                                                class="form-control"
                                                bsDatepicker
                                                [bsConfig]="{ dateInputFormat: 'YYYY/MM/DD', containerClass: 'theme-red', adaptivePosition: true }"
                                                (bsValueChange)="onCreationDateStartValueChange($event)"
                                                data-test-id="request-list-advanced-search-start-creation-input"
                                            />
                                        </div>
                                        <div class="form-group col-md-2">
                                            <label for="endCreationTimeBetween">{{ 'And' | localize }}</label>
                                            <input
                                                id="endCreationTimeBetween"
                                                formControlName="endCreationTimeBetween"
                                                type="text"
                                                name="endCreationTimeBetween"
                                                class="form-control"
                                                bsDatepicker
                                                [bsConfig]="{ dateInputFormat: 'YYYY/MM/DD', containerClass: 'theme-red', adaptivePosition: true }"
                                                [minDate]="minDateCreationTime"
                                            />
                                        </div>
                                        <div class="assigned-checkbox form-group m-checkbox col-md-2" *ngIf="canSeeAssignments">
                                            <label for="onlyMyRequests" class="checkbox">
                                                <input
                                                    id="onlyMyRequests"
                                                    type="checkbox"
                                                    name="onlyMyRequests"
                                                    formControlName="onlyMyRequests"
                                                    (change)="setCheckValue($event)"
                                                />
                                                {{ 'RequestAssignedToMe' | localize }}
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group" style="text-align: right; width: 100%; margin-bottom: 0px">
                                            <button (click)="onAdvancedSearchPressed()" class="btn btn-primary" type="submit">
                                                <i class="flaticon-search-1" [attr.aria-label]="l('Search')" data-test-id="request-list-advanced-search-button"></i>
                                            </button>
                                            <button (click)="refreshAdvancedSearch()" class="btn btn-secondary" type="submit">
                                                <i
                                                    class="la la-refresh"
                                                    [attr.aria-label]="l('RefrfeshAdvancedSearch')"
                                                    data-test-id="request-list-refresh-advanced-search-button"
                                                ></i>
                                                {{ 'Refresh' | localize }}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card card-custom listContainer mt-6">
                <div class="card-body">
                    <header class="d-flex justify-content-between">
                        <h2>{{ 'ListRequest' | localize }}</h2>
                        <div class="d-flex justify-content-end">
                            <div class="input-group-btn">
                                <button class="btn btn-outline-secondary" (click)="resetFilters()">
                                    <i class="la la-refresh"></i>
                                    {{ 'grid.resetFilters' | localize }}
                                </button>
                                <button class="btn btn-outline-secondary" (click)="resetSort()">
                                    <i class="la la-refresh"></i>
                                    {{ 'grid.resetColumns' | localize }}
                                </button>
                            </div>
                        </div>
                    </header>
                    <request-table [paginationPageSize]="20" [includeLegend]="true"></request-table>
                </div>
            </div>
        </div>
    </div>
</div>
