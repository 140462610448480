import { Component, Input } from '@angular/core';
import { CandidatOutput } from '@shared/models/candidat/candidatOutput';
import { organizationOutput } from '@shared/models/organization/organizationOutput';

@Component({
    selector: 'app-report-page',
    templateUrl: './report-page.component.html',
    styleUrls: ['./report-page.component.scss']
})
export class ReportPageComponent {
    @Input() candidat: CandidatOutput;
    @Input() organization: organizationOutput;
    @Input() organizationLogo: string;
    @Input() lang: string;
}
