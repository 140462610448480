import { ValidatorFn, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export const expirationDateValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    if (control.value !== null) {
        const result = control.value;
        let year = result.substring(2, 4);
        year = parseInt(year);

        let month = result.substring(0, 2);
        month = parseInt(month);

        const currentDate = new Date();
        const currentYear = parseInt(currentDate.getFullYear().toString().substring(2, 4));
        const currentMonth = currentDate.getMonth() + 1;

        if (year === currentYear) {
            if (month > currentMonth && month < 13) {
                return null;
            } else {
                return { BadExpirationDate: true };
            }
        } else if (year > currentYear) {
            if (month > 0 && month < 13) {
                return null;
            } else {
                return { BadExpirationDate: true };
            }
        } else {
            return { BadExpirationDate: true };
        }
    }
};
