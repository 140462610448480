export enum Languages {
    ENGLISH = 'English',
    FRENCH = 'Français',
}

export enum LanguagesKeys {
    ENGLISH = 0,
    FRENCH = 1,
}

export enum LanguagesTranslations {
    ENGLISH = 'English',
    FRENCH = 'French',
}
