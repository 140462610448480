import { Component, Injector, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Router } from '@angular/router';
import { ListSearchComponent } from '../list-search/list-search.component';
import { FileHelper } from '@shared/helpers/FileHelper';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';

@Component({
    selector: 'organizations-list',
    templateUrl: './organizations-list.component.html',
    animations: [appModuleAnimation()]
})
export class OrganizationListComponent extends AppComponentBase {
    @ViewChild(ListSearchComponent) list: ListSearchComponent;
    context;
    frameworkComponents;
    canICreate;
    isExcelLoading = false;

    constructor(private injector: Injector, private _router: Router) {
        super(injector);
        this.context = { componentParent: this };

        const appAuthService = injector.get(AppAuthService);
        this.canICreate = appAuthService.hasPermission('Pages.Management.Organizations.Create');
    }

    createOrganization(): void {
        this._router.navigate(['/organizations-create']);
    }

    export() {
        this.isExcelLoading = true;
        this.list
            .export()
            .subscribe({
                next: (data) => {
                    FileHelper.downloadFile(data, 'organisations.xlsx');
                },
                error: (err) => {
                    this.notify.error('Server error: ' + err.error.error.message);
                }
            })
            .add(() => {
                this.isExcelLoading = false;
            });
    }
}
