<app-request-table-legend *ngIf="includeLegend"></app-request-table-legend>
<ag-grid-angular 
    #agGrid
    localizeText 
    class="ag-theme-material gardium-grid"
    [class.hide-pagination]="shouldHidePagination" 
    domLayout="autoHeight" 
    data-test-id="request-list-grid" 
    [paginationPageSize]="paginationPageSize"
    [pagination]="pagination"
    [paginationNumberFormatter]="paginationNumberFormatter"
    [gridOptions]="gridOptions"
    [columnDefs]="columnDefs" 
    [defaultColDef]="defaultColDef" 
    [updateList]="'requestList'" 
    [rowSelection]="rowSelection"
    (selectionChanged)="onSelectionChanged()" 
    (gridReady)="onGridReady($event)" 
    (sortChanged)="onSortChanged($event)"
    (filterChanged)="onFilterChanged($event)"
    (firstDataRendered)="onFirstDataRendered()"
    (columnMoved)="onColumnMoved()"
    (columnVisible)="onColumnVisibleChanged()">
</ag-grid-angular>