import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PersonnalReferencesOutput } from '@shared/models/CandidatDetails/personnalReferences/personnalReferencesOutput';
import { ProfessionnalReferencesInput } from '@shared/models/CandidatDetails/professionnalReferences/professionnalReferencesInput';
import { Observable } from 'rxjs';
import { environment as env } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})

export class ProfessionnalReferenceService {
    private http: HttpClient;
    private baseUrl: string;

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = env.api;
    }

    private getUrl(url: string): string {
        let url_ = this.baseUrl + url;
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    }

    addProfessionnalReference(professionnalReferenceInput: ProfessionnalReferencesInput): Observable<any> {
        let url_ = this.getUrl('/api/professionnalReferences/addProfessionnalReference');
        return this.http.post<PersonnalReferencesOutput>(url_, professionnalReferenceInput);
    }

    getProfessionnalReferencesByRequest(requestPublicId: string): Observable<any> {
        let url_ = this.getUrl(`/api/professionnalReferences/getProfessionnalReferencesByRequest/${requestPublicId}`);
        return this.http.get<any>(url_);
    }

    getProfessionnalReferencesByCandidat(candidatPublicId: string): Observable<any> {
        let url_ = this.getUrl(`/api/professionnalReferences/getProfessionnalReferencesByCandidat/${candidatPublicId}`);
        return this.http.get<any>(url_);
    }

    updateProfessionnalReferences(professionnalReferenceInput: ProfessionnalReferencesInput): Observable<any> {
        let url_ = this.getUrl('/api/professionnalReferences/updateProfessionnalReferences');
        return this.http.put<any>(url_, professionnalReferenceInput);
    }

    deleteProfessionnalReference(professionnalReferenceInputPublicId: string): Observable<any> {
        let url_ = this.getUrl('/api/professionnalReferences/deleteProfessionnalReference');
        let params = new HttpParams().set('professionnalReferenceInputPublicId', professionnalReferenceInputPublicId);
        return this.http.post<any>(url_, null, { params });
    }


}


