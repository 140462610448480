<div bsModal role="dialog" class="modal-lg">
    <form [formGroup]="formGroup" #formUpdate="ngForm" novalidate (ngSubmit)="save()" autocomplete="off">
        <div class="modal-header">
            <h4 class="modal-title pull-left">
                <h5>{{"EmailSend" | localize}}</h5>
            </h4>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="form-group col-md-12">
                    <label for="email">{{"EmailAddress" | localize}} <span class="asterix">*</span></label>
                    <input id="email" formControlName="email" type="text" name="email" class="form-control"
                        maxlength="256">
                    <validation-messages [formCtrl]="formGroup.get('email')"></validation-messages>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button [disabled]="saving" type="button" class="btn btn-default" (click)="bsModalRef.hide()">
                {{"Cancel" | localize}}
            </button>
            <button type="submit" class="btn btn-primary" [buttonBusy]="saving" [disabled]="!formUpdate.form.valid"
                [busyText]="l('SavingWithThreeDot')" (click)="save()"><i class="fa fa-save"></i>
                <span>{{"ConfirmAndSend" | localize}}</span>
            </button>
        </div>
    </form>
</div>