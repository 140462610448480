import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { EMPTY, from, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { IAjaxResponse, IErrorInfo } from '@shared/models/ajax-response';
import { DialogService } from '@shared/services/dialog-service';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { AppAuthService } from './app-auth.service';
import LocalizationService from '@shared/services/localization.service';
import { handleAuth0LoginRequiredError } from '@shared/helpers/AuthCheckerHelper';

@Injectable()
export class GardiumHttpInterceptor implements HttpInterceptor {

    constructor(
        private _authService: AppAuthService,
        private configuration: GardiumHttpConfiguration,
        private authProvider: AuthHttpInterceptor,
        private _localizationService: LocalizationService,
        private _dialogueService: DialogService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = req.clone({
            headers: req.headers.append("locale", this._authService.user?.locale ?? "fr")
        });
        return from(this._authService.initializeAsync()).pipe(
            switchMap((result) => {
                if (!result.continue) {
                    return new Observable<HttpEvent<any>>();
                }
                if (result.isAuthenticated) {
                    return this.catchErrors(this.authProvider.intercept(req, next));
                }
                return this.catchErrors(next.handle(req));
            })
        );
    }

    private catchErrors(o: Observable<HttpEvent<any>>) {
        return o.pipe(
            catchError((error: Error) => {
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    return throwError(error);
                }
                else if (error.message === 'Login required') {
                    handleAuth0LoginRequiredError(this._localizationService, this._dialogueService, this._authService);
                    return EMPTY;
                }
                else {
                    return this.handleErrorResponse(error);
                }
            }),
            switchMap((e) => this.handleSuccessResponse(e))
        );
    }

    protected handleSuccessResponse(event: HttpEvent<any>): Observable<HttpEvent<any>> {
        let self = this;

        if (event instanceof HttpResponse) {
            if (event.body instanceof Blob && event.body.type && event.body.type.indexOf('application/json') >= 0) {
                return self.configuration.blobToText(event.body).pipe(
                    map(
                        json => {
                            const responseBody = json === 'null' ? {} : JSON.parse(json);

                            const modifiedResponse = self.configuration.handleResponse(event.clone({
                                body: responseBody
                            }));

                            return modifiedResponse.clone({
                                body: new Blob([JSON.stringify(modifiedResponse.body)], { type: 'application/json' })
                            });
                        })
                );
            }
        }
        return of(event);
    }

    protected handleErrorResponse(error: any): Observable<never> {
        if (!(error.error instanceof Blob)) {
            return throwError(error);
        }

        return this.configuration.blobToText(error.error).pipe(
            switchMap((json) => {
                const errorBody = (json === '' || json === 'null') ? {} : JSON.parse(json);
                const errorResponse = new HttpResponse({
                    headers: error.headers,
                    status: error.status,
                    body: errorBody
                });

                const ajaxResponse = this.configuration.getAbpAjaxResponseOrNull(errorResponse);

                if (ajaxResponse != null) {
                    this.configuration.handleAbpResponse(errorResponse, ajaxResponse);
                } else {
                    this.configuration.handleNonAbpErrorResponse(errorResponse);
                }

                return throwError(error);
            })
        );
    }
}


@Injectable()
export class GardiumHttpConfiguration {

    constructor(
        private _messageService: DialogService) {
    }

    defaultError = <IErrorInfo>{
        message: 'An error has occurred!',
        details: 'Error details were not sent by server.'
    };

    defaultError401 = <IErrorInfo>{
        message: 'You are not authenticated!',
        details: 'You should be authenticated (sign in) in order to perform this operation.'
    };

    defaultError403 = <IErrorInfo>{
        message: 'You are not authorized!',
        details: 'You are not allowed to perform this operation.'
    };

    defaultError404 = <IErrorInfo>{
        message: 'Resource not found!',
        details: 'The resource requested could not be found on the server.'
    };

    showError(error: IErrorInfo): any {
        if (error.details) {
            return this._messageService.error(error.details, error.message || this.defaultError.message);
        } else {
            return this._messageService.error(error.message || this.defaultError.message);
        }
    }

    handleTargetUrl(targetUrl: string): void {
        if (!targetUrl) {
            location.href = '/';
        } else {
            location.href = targetUrl;
        }
    }

    handleUnAuthorizedRequest(messagePromise: any, targetUrl?: string) {
        const self = this;

        if (messagePromise) {
            messagePromise.done(() => {
                this.handleTargetUrl(targetUrl || '/');
            });
        } else {
            self.handleTargetUrl(targetUrl || '/');
        }
    }

    handleNonAbpErrorResponse(response: HttpResponse<any>) {
        const self = this;

        switch (response.status) {
            case 401:
                self.handleUnAuthorizedRequest(
                    self.showError(self.defaultError401),
                    '/'
                );
                break;
            case 403:
                self.showError(self.defaultError403);
                break;
            case 404:
                self.showError(self.defaultError404);
                break;
            default:
                self.showError(self.defaultError);
                break;
        }
    }

    handleAbpResponse(response: HttpResponse<any>, ajaxResponse: IAjaxResponse): HttpResponse<any> {
        let newResponse: HttpResponse<any>;

        if (ajaxResponse.success) {

            newResponse = response.clone({
                body: ajaxResponse.result
            });

            if (ajaxResponse.targetUrl) {
                this.handleTargetUrl(ajaxResponse.targetUrl);;
            }
        } else {

            newResponse = response.clone({
                body: ajaxResponse.result
            });

            if (!ajaxResponse.error) {
                ajaxResponse.error = this.defaultError;
            }

            this.showError(ajaxResponse.error);

            if (response.status === 401) {
                this.handleUnAuthorizedRequest(null, ajaxResponse.targetUrl);
            }
        }

        return newResponse;
    }

    getAbpAjaxResponseOrNull(response: HttpResponse<any>): IAjaxResponse | null {
        if (!response || !response.headers) {
            return null;
        }

        let contentType = response.headers.get('Content-Type');
        if (!contentType) {
            console.warn('Content-Type is not sent!');
            return null;
        }

        if (contentType.indexOf("application/json") < 0) {
            console.warn('Content-Type is not application/json: ' + contentType);
            return null;
        }

        let responseObj = JSON.parse(JSON.stringify(response.body));
        if (!responseObj.__abp) {
            return null;
        }

        return responseObj as IAjaxResponse;
    }

    handleResponse(response: HttpResponse<any>): HttpResponse<any> {
        let ajaxResponse = this.getAbpAjaxResponseOrNull(response);
        if (ajaxResponse == null) {
            return response;
        }

        return this.handleAbpResponse(response, ajaxResponse);
    }

    blobToText(blob: any): Observable<string> {
        return new Observable<string>((observer: any) => {
            if (!blob) {
                observer.next("");
                observer.complete();
            } else {
                let reader = new FileReader();
                reader.onload = function () {
                    observer.next(this.result);
                    observer.complete();
                }
                reader.readAsText(blob);
            }
        });
    }
}
